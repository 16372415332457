const rules = {
  description: [
    {
      required: true,
      message: 'Please enter Description.',
    },
  ],
  minAge: [],
  maxAge: [],
  startAt: [
    {
      required: true,
      message: 'Please select Date.',
    },
  ],
  endAt: [],
  minAmount: [
    {
      required: true,
      message: 'Please enter Min Amount.',
    },
  ],
  minUnit: [
    {
      required: true,
      message: 'Please enter Min Unit.',
    },
  ],
  maxCount: [
    {
      required: true,
      message: 'Please enter Max Count.',
    },
  ],
  maxAmountOnce: [
    {
      required: true,
      message: 'Please enter Max Amount per once.',
    },
  ],
  maxAmountDay: [
    {
      required: true,
      message: 'Please enter Max Amount per day.',
    },
  ],
  maxAmountMonth: [
    {
      required: true,
      message: 'Please enter Max Amount per month.',
    },
  ],
};

export default rules;
