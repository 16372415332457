import React from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

/**
 * Get columns with custom options
 * @param {Object} options
 * @param {Function} onTitleClick Callback function that invokes when clicking title
 */
export function getColumns(options) {
  const columns = [
    {
      title: 'Order',
      dataIndex: 'sortedIndex',
      align: 'right',
    },
    {
      title: 'Required',
      dataIndex: 'optional',
      render: val => (val ? 'Optional' : 'Required'),
      width: 100,
    },
    {
      title: 'Need Reagree',
      dataIndex: 'reagree',
      render: val => (val ? 'Y' : '-'),
      width: 100,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      render(val) {
        return <span>{val ? startCase(val) : '-'}</span>;
      },
      width: 100,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render(val, others) {
        return <LinkText onClick={options.onTitleClick.bind(null, others)}>{val}</LinkText>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      render(val) {
        return (
          <span>
            <Badge status={val === true ? 'success' : 'error'} />
            <span>{val === true ? 'Active' : 'Inactive'}</span>
          </span>
        );
      },
      align: 'center',
    },
    {
      title: 'Issued',
      render(others) {
        const { id } = others;
        const issueDate = formatDate(id.split('_')[1], 'YYYY-MM-DD');
        const isIssued = moment().isSameOrAfter(issueDate);
        return (
          <span>
            <Badge status={isIssued ? 'success' : 'warning'} />
            <span>{isIssued ? 'Yes' : 'No'}</span>
          </span>
        );
      },
    },
    {
      title: 'Issue Date',
      render(others) {
        const { id } = others;
        return <span>{formatDate(id.split('_')[1], 'YYYY-MM-DD')}</span>;
      },
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      render(val) {
        return <span>{formatDate(val)}</span>;
      },
    },
    {
      title: 'Author',
      dataIndex: 'author',
    },
  ];

  return columns;
}

export default {
  getColumns,
};
