import React, { Component, Fragment } from 'react';
import { func, arrayOf, instanceOf, bool, any } from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Divider, Alert } from 'antd';
import autobind from 'autobind-decorator';
import { getAgreements } from 'redux/modules/agreement/actions';
import { getColumns } from './AgreementListMetadata';
import styles from './AgreementList.scss';

const mapStateToProps = state => ({
  data: state.agreement.data,
  error: state.agreement.error,
  loading: state.agreement.loading,
});

const mapDispatchToProps = {
  getAgreements,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class AgreementList extends Component {
  static propTypes = {
    getAgreements: func.isRequired,
    onCreate: func.isRequired,
    onOpen: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    this.props.getAgreements();
  }

  shouldComponentUpdate(nextProps) {
    return this.props.loading !== nextProps.loading;
  }

  render() {
    const { data, error, loading } = this.props;
    const errorMessage = error ? error.message : 'Unknown Error';
    const columns = getColumns({
      onTitleClick: this.props.onOpen,
    });

    return (
      <div className={styles.agreementList}>
        <div className={styles.top}>
          <Button type="primary" icon="plus-circle" onClick={this.props.onCreate}>
            New
          </Button>
        </div>

        {/* On Error while getting data via XHR, Display <Alert />  */}
        {error && (
          <Fragment>
            <Divider style={{ background: 'none', margin: '10px 0' }} />
            <Alert type="warning" showIcon closable message={`Failed to load page: ${errorMessage}`} />
            <Divider style={{ background: 'none', margin: '10px 0' }} />
          </Fragment>
        )}

        <Table
          className={styles.agreementTable}
          rowKey={(record, idx) => `${record.key}_${record.issueDate}_${idx}`}
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
          scroll={{ x: '100%' }}
        />
      </div>
    );
  }
}

export default AgreementList;
