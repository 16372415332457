import moment from 'moment';
import { REGEX_GRAB_ENGLISH_AND_NUMBER_AND_CHAR } from 'constant';

// TODO: Someone check the information about these functions correct or not,
// I just wrote based on how it looks, so maybe some data might be wrong.
/**
 * Add '0' character in front of number less than 10
 * @param {number} val
 * @return {string}
 */
export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val.toString();
}

/**
 * Need explanation about this!
 * @param {string} _str
 * @return {string}
 */
export function uncommify(_str) {
  if (_str === undefined || _str === null) return '';

  // string으로 변환 후 comma 제거
  const str = _str.toString().replace(/,/g, '');
  return str;
}

/**
 * Need explanation about this!
 * @param {any} _no
 * @return {any}
 */
export function commify(_no) {
  if (_no === undefined || _no === null) return null;

  // comma가 있으면 우선 제거
  let no = uncommify(_no);
  const reg = /(^[+-]?\d+)(\d{3})/; // 정규식

  while (reg.test(no)) {
    no = no.replace(reg, '$1,$2');
  }

  return no;
}

/**
 * Get Time Distance from now.
 * @param {string} type - Type of time distance
 * @return {Moment}
 */
// TODO: It could better to define the actual type for "types", not string.
// TODO: Seems like this function could be sliently failed.
// What if type is not one of today|week|month|year, it just returns empty string. Should it be thrown exception, maybe?
export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  if (type === 'year') {
    const year = now.getFullYear();

    return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
  }

  return '';
}

/**
 * Remove none english/number/special characters
 * @param {string} password
 * @return {string}
 */
export function sanitizePassword(password) {
  return password.replace(REGEX_GRAB_ENGLISH_AND_NUMBER_AND_CHAR, '');
}
