import gql from 'graphql-tag';

const GET_ACC_MENU_LIST = gql`
  fragment MenuRecursive on Menu {
    ...MenuFields
    children {
      ...MenuFields
      children {
        ...MenuFields
        children {
          ...MenuFields
        }
      }
    }
  }

  fragment MenuFields on Menu {
    id
    parentId
    path
    icon
    title
    name
    leftMenu
    isLock
    code
    sort
  }

  query getAccessMenus {
    getAccessMenus {
      ...MenuRecursive
    }
  }
`;

export default GET_ACC_MENU_LIST;
