import React from 'react';
import { func } from 'prop-types';
import { Row, Col } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { autoRefreshIntervalGroup } from '../DashboardMetadata';
import styles from './AutoRefreshControl.scss';

const BlockLinkText = props => <LinkText {...props} style={{ display: 'block', marginBottom: 10, fontSize: 14 }} />;

const AutoRefreshControl = ({ onSetRefreshInterval }) => (
  <div>
    <div className={styles.title}>Refresh Intervals</div>

    <Row>
      <Col span={6} className={styles.intervalGroup}>
        <BlockLinkText onClick={onSetRefreshInterval.bind(null, null)}>Off</BlockLinkText>
      </Col>
      <Col span={6} className={styles.intervalGroup}>
        {autoRefreshIntervalGroup[0].map(mode => (
          <BlockLinkText key={mode} onClick={onSetRefreshInterval.bind(null, mode)}>
            {startCase(mode)}
          </BlockLinkText>
        ))}
      </Col>
      <Col span={6} className={styles.intervalGroup}>
        {autoRefreshIntervalGroup[1].map(mode => (
          <BlockLinkText key={mode} onClick={onSetRefreshInterval.bind(null, mode)}>
            {startCase(mode)}
          </BlockLinkText>
        ))}
      </Col>
      <Col span={6} className={styles.intervalGroup}>
        {autoRefreshIntervalGroup[2].map(mode => (
          <BlockLinkText key={mode} onClick={onSetRefreshInterval.bind(null, mode)}>
            {startCase(mode)}
          </BlockLinkText>
        ))}
      </Col>
    </Row>
  </div>
);

AutoRefreshControl.propTypes = {
  onSetRefreshInterval: func.isRequired,
};

export default AutoRefreshControl;
