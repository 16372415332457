import gql from 'graphql-tag';

export const exchangePropertyRuleAdd = gql`
  mutation exchangePropertyRuleAdd($ruleCodeType: ExchangeRuleCodeType!, $code: String!) {
    exchangePropertyRuleAdd(ruleCodeType: $ruleCodeType, code: $code)
  }
`;

export const exchangePropertyRuleUpdate = gql`
  mutation exchangePropertyRuleUpdate($id: ID!, $ruleCodeType: ExchangeRuleCodeType!, $code: String!) {
    exchangePropertyRuleUpdate(id: $id, ruleCodeType: $ruleCodeType, code: $code)
  }
`;

export const exchangePropertyRuleDel = gql`
  mutation exchangePropertyRuleDel($id: ID!) {
    exchangePropertyRuleDel(id: $id)
  }
`;

export default {
  exchangePropertyRuleAdd,
  exchangePropertyRuleUpdate,
  exchangePropertyRuleDel,
};
