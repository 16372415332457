import gql from 'graphql-tag';

export const promotionAddMutation = gql`
  mutation promotionAdd(
    $title: String!
    $description: String!
    $code: String
    $status: String!
    $policyType: String!
    $benefitType: String!
    $issueType: String
    $startAt: Date!
    $endAt: Date!
    # $couponStartDate: Date
    # $couponDueDate: Date!
    # $isSelectable: Boolean
    $ttl: Int
    $ttlType: String
    $discount: String!
    $maxDiscountAmount: Int
    $priceMin: Int
    $priceMax: Int
    $maxPerPolicy: Int
    $maxPerUser: Int
    $isMerchantLifetimePromotion: Boolean
    $isUsableOnFirstPurchase: Boolean
    $isBulkIssuable: Boolean
    $isUsableOnAutoCharge: Boolean
    $discountSplitConditions: [DiscountSplitConditionInput]
    $merchantIds: [ID]
    $subMerchantIds: [Int]
    $pid: [String]
  ) {
    promotionAdd(
      title: $title
      description: $description
      code: $code
      status: $status
      policyType: $policyType
      benefitType: $benefitType
      issueType: $issueType
      startAt: $startAt
      endAt: $endAt
      # couponStartDate: $couponStartDate
      # couponDueDate: $couponDueDate
      # isSelectable: $isSelectable
      ttl: $ttl
      ttlType: $ttlType
      discount: $discount
      maxDiscountAmount: $maxDiscountAmount
      priceMin: $priceMin
      priceMax: $priceMax
      maxPerPolicy: $maxPerPolicy
      maxPerUser: $maxPerUser
      isMerchantLifetimePromotion: $isMerchantLifetimePromotion
      isUsableOnFirstPurchase: $isUsableOnFirstPurchase
      isBulkIssuable: $isBulkIssuable
      isUsableOnAutoCharge: $isUsableOnAutoCharge
      discountSplitConditions: $discountSplitConditions
      merchantIds: $merchantIds
      subMerchantIds: $subMerchantIds
      pid: $pid
    )
  }
`;

export const promotionUpdateMutation = gql`
  mutation promotionUpdate(
    $id: ID!
    $title: String!
    $description: String!
    $code: String
    $status: String!
    $policyType: String!
    $benefitType: String!
    $issueType: String
    $startAt: Date!
    $endAt: Date!
    # $couponStartDate: Date
    # $couponDueDate: Date!
    # $isSelectable: Boolean
    $ttl: Int
    $ttlType: String
    $discount: String!
    $maxDiscountAmount: Int
    $priceMin: Int
    $priceMax: Int
    $maxPerPolicy: Int
    $maxPerUser: Int
    $isMerchantLifetimePromotion: Boolean
    $isUsableOnFirstPurchase: Boolean
    $isBulkIssuable: Boolean
    $isUsableOnAutoCharge: Boolean
    $discountSplitConditions: [DiscountSplitConditionInput]
    $merchantIds: [ID]
    $subMerchantIds: [Int]
    $pid: [String]
  ) {
    promotionUpdate(
      id: $id
      title: $title
      description: $description
      code: $code
      status: $status
      policyType: $policyType
      benefitType: $benefitType
      issueType: $issueType
      startAt: $startAt
      endAt: $endAt
      # couponStartDate: $couponStartDate
      # couponDueDate: $couponDueDate
      # isSelectable: $isSelectable
      ttl: $ttl
      ttlType: $ttlType
      discount: $discount
      maxDiscountAmount: $maxDiscountAmount
      priceMin: $priceMin
      priceMax: $priceMax
      maxPerPolicy: $maxPerPolicy
      maxPerUser: $maxPerUser
      isMerchantLifetimePromotion: $isMerchantLifetimePromotion
      isUsableOnFirstPurchase: $isUsableOnFirstPurchase
      isBulkIssuable: $isBulkIssuable
      isUsableOnAutoCharge: $isUsableOnAutoCharge
      discountSplitConditions: $discountSplitConditions
      merchantIds: $merchantIds
      subMerchantIds: $subMerchantIds
      pid: $pid
    )
  }
`;

export default {
  promotionAddMutation,
  promotionUpdateMutation,
};
