import React, { Component } from 'react';
import { Icon, Form, Input, Button, Row, Col, Upload } from 'antd';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './BoostForm.scss';

import { formItemLayout } from './layout';

class BoostIdArrayForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  state = {
    searchFilter: '',
    error: null,
  };

  updateSearchFilter(e) {
    this.setState({ searchFilter: e.target.value });
  }

  filteredItems() {
    const { searchFilter } = this.state;
    const { form, name } = this.props;
    const list = form.getFieldValue(name);

    if (!searchFilter) {
      return list;
    }

    return list.filter(item => item.userId.indexOf(searchFilter) >= 0);
  }

  async importCSV({ file }) {
    Papa.parse(file, {
      complete: result => {
        result.data.forEach(row => {
          row.forEach(userId => {
            this.addItem(userId);
          });
        });
      },
    });
  }

  add = () => {
    const { form, name } = this.props;
    const value = form.getFieldValue(`${name}-add-input`);
    this.addItem(value);
  };

  addItem = value => {
    const { form, name } = this.props;
    const list = form.getFieldValue(name);
    if (!value) {
      this.setState({
        error: 'Please input value',
      });
      return;
    }
    if (!value || list.find(item => item.userId === value)) {
      // already exists
      this.setState({
        error: 'ID already exists',
      });
      return;
    }

    // UUID check
    if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)) {
      this.setState({
        error: 'Invalid ID',
      });
      return;
    }

    form.setFieldsValue({
      [name]: list.concat([{ userId: value, status: 'new' }]),
    });
    form.resetFields([`${name}-add-input`]);
  };

  remove = target => {
    console.log(target);

    const { form, name } = this.props;
    const items = form.getFieldValue(name);

    form.setFieldsValue({
      [name]: items.filter(item => item.userId !== target.userId),
    });
  };

  render() {
    const { form, name, label, initial } = this.props;
    const { error } = this.state;
    form.getFieldDecorator(name, { initialValue: initial });
    const formFileList = form.getFieldValue(`${name}-upload`);
    const cx = classNames.bind(styles);
    const { getFieldDecorator } = form;

    let hasError = null;
    if (error) {
      hasError = {
        validateStatus: 'error',
        help: error,
      };
      this.search.input.scrollIntoView({
        behavior: 'smooth',
      });
    }

    const items = this.filteredItems();

    return (
      <Form.Item label={label} {...formItemLayout}>
        <Row type="flex" justify="start" style={{ marginBottom: 24 }}>
          <Col span={18}>
            <Input
              placeholder="Search Keyword"
              ref={node => (this.search = node)}
              suffix={<Icon type="search" />}
              onChange={e => this.updateSearchFilter(e)}
              autoComplete="off"
            />
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Upload
              multiple={false}
              fileList={formFileList}
              onChange={({ file, fileList }) => this.importCSV({ file, fileList })}
              onRemove={() => this.onRemove()}
              beforeUpload={() => false}
              accept=".csv"
            >
              <Button block>
                <Icon type="import" /> CSV
              </Button>
            </Upload>
          </Col>
        </Row>
        <Form.Item className={cx(['listSelect'])}>
          {items.length === 0 && (
            <div className={styles.listItem}>
              <div className={styles.listItemEmpty}>No Data</div>
            </div>
          )}

          {items.length > 0 &&
            items.map(item => {
              let style = null;
              let postfix = null;

              if (item.status === 'new') {
                style = { color: 'navy' };
                postfix = ' (New)';
              }

              if (item.status === 'error') {
                style = { color: 'red' };
                postfix = ' (Error)';
              }

              if (item.status === 'issued') {
                style = { color: 'silver' };
                postfix = ' (Issued)';
              }

              return (
                <Row key={`${name}-list-item-${item.userId}`} className={styles.listItem}>
                  <Col style={style} span={20}>
                    {item.userId}
                    {postfix}
                  </Col>
                  <Col span={3} style={{ textAlign: 'right' }}>
                    <Button shape="circle" icon="delete" onClick={() => this.remove(item)} />
                  </Col>
                  <Col span={1} />
                </Row>
              );
            })}
        </Form.Item>
        <Row type="flex" justify="start" style={{ marginBottom: 24 }}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <span>{items.length} user(s)</span>
          </Col>
        </Row>
        <Row type="flex" justify="start" style={{ marginBottom: 24 }}>
          <Col span={22}>
            <Form.Item {...hasError}>
              {getFieldDecorator(`${name}-add-input`, { initialValue: '' })(
                <Input placeholder="Add User ID" name={`${name}-add-input`} autoComplete="off" />
              )}
            </Form.Item>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button block icon="plus" onClick={() => this.add()} />
          </Col>
        </Row>
      </Form.Item>
    );
  }
}

export default BoostIdArrayForm;
