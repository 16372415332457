const editorConfig = {
  reagreeText: {
    initialEditType: 'wysiwyg',
    style: { marginBottom: 10 },
    hideModeSwitch: true,
    placeholder: '(Optional)',
    height: 200,
    usageStatistics: false,
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'indent',
      'outdent',
      'divider',
      'table',
    ],
  },
  description: {
    initialEditType: 'wysiwyg',
    style: { marginBottom: 10 },
    hideModeSwitch: true,
    placeholder: 'Agreement Description',
    height: 500,
    usageStatistics: false,
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'indent',
      'outdent',
      'divider',
      'table',
    ],
  },
};

export default editorConfig;
