import React from 'react';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

export function getColumns(options = {}) {
  const { userId, onPaymentIdClick, onUserIdClick } = options;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: function renderId(val) {
        return <LinkText onClick={onPaymentIdClick.bind(null, val)}>{val.substr(0, 5)}...</LinkText>;
      },
      width: '10%',
    },
    {
      title: 'Checkout Amt.',
      dataIndex: 'checkoutAmount',
      align: 'right',
      render: function renderCheckoutAmount(val) {
        return <span>{commify(val || 0)}</span>;
      },
      width: '15%',
    },
    {
      title: 'Billing Amt.',
      dataIndex: 'billingAmount',
      align: 'right',
      render: function renderBillingAmount(val) {
        return <span>{commify(val || 0)}</span>;
      },
      width: '15%',
    },
    {
      title: 'Cashback Amt.',
      dataIndex: 'cashbackAmount',
      align: 'right',
      render: function renderBillingAmount(val) {
        return <span>{commify(val || 0)}</span>;
      },
      width: '15%',
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render: function renderDate(val) {
        return <span>{formatDate(val)}</span>;
      },
      width: '20%',
    },
    {
      title: 'User',
      dataIndex: 'user.id',
      render: function renderId(val) {
        return userId !== val && <LinkText onClick={onUserIdClick.bind(null, val)}>{val.substr(0, 5)}...</LinkText>;
      },
      width: '10%',
    },
  ];

  return columns;
}

export default {
  getColumns,
};
