import React, { Component } from 'react';
import { Icon, Form, Alert, Divider, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getBoostCategories } from 'redux/modules/boostCategory/actions';
import ListSelect from 'components/ListSelect';
import { required } from 'utils/formValidator';

import { formItemLayout } from '../Forms/layout';
import styles from './BoostPromotion.scss';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating, error, loading } = state.boostCategory;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
    error,
    loading,
  };
};

const mapDispatchToProps = {
  getBoostCategories,
};

@connect(mapStateToProps, mapDispatchToProps)
class BoostPromotionCategoryForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    getBoostCategories: PropTypes.func.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
    isRequired: PropTypes.bool,
  };

  static defaultProps = {
    error: null,
    isRequired: false,
  };

  state = {
    boostCategoryFilter: '',
  };

  componentDidMount() {
    this.props.getBoostCategories(1, 1000);
  }

  key = 'boostCategoryIds';

  handleSelectBoostCategory(selectedBoostCategories) {
    const { form } = this.props;

    form.setFieldsValue({
      [this.key]: selectedBoostCategories,
    });
  }

  updateBoostCategoryFilter(e) {
    this.setState({ boostCategoryFilter: e.target.value });
  }

  filteredBoostCategories(selectedOnly) {
    const { data, form } = this.props;
    const { boostCategoryFilter } = this.state;

    const dataMap = data.map(boostCategory => ({ name: boostCategory.name, value: boostCategory.id }));

    if (selectedOnly) {
      const selectedCategory = form.getFieldValue(this.key);
      return dataMap.filter(category => selectedCategory.includes(category.value));
    }

    if (!boostCategoryFilter) {
      return dataMap;
    }

    return dataMap.filter(boostCategory => boostCategory.name.indexOf(boostCategoryFilter) >= 0);
  }

  render() {
    const { form, error, loading, initial, isRequired } = this.props;
    form.getFieldDecorator(this.key, { initialValue: initial, rules: isRequired && [required()] });

    const selectedBoostCategories = form.getFieldValue(this.key);

    let hasError = null;
    if (form.getFieldError(this.key)) {
      hasError = new Error('Boost Category must be selected.');
      this.search.input.scrollIntoView({
        behavior: 'smooth',
      });
    }

    return (
      <Form.Item label="Boost Categories" {...formItemLayout} style={{ marginBottom: 20 }} required={isRequired}>
        {error && (
          <>
            <Alert message="Oops!" description="Failed to load Boost Categories." type="warning" showIcon />
            <Divider />
            <Button icon="redo" onClick={() => this.getBoostCategories(1, 1000)}>
              Try Again
            </Button>
          </>
        )}

        {!error && (
          <>
            <Form.Item>
              <Input
                placeholder="Search Keyword"
                ref={node => (this.search = node)}
                suffix={<Icon type="search" />}
                onChange={e => this.updateBoostCategoryFilter(e)}
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item label="Selected Categories">
              {loading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                error={hasError}
                items={this.filteredBoostCategories(true)}
                onClick={e => this.handleSelectBoostCategory(e)}
                value={selectedBoostCategories}
                multiSelect
                small
              />
            </Form.Item>
            <Form.Item label="Category List">
              {loading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                error={hasError}
                items={this.filteredBoostCategories()}
                onClick={e => this.handleSelectBoostCategory(e)}
                value={selectedBoostCategories}
                multiSelect
              />
            </Form.Item>
          </>
        )}
      </Form.Item>
    );
  }
}

export default BoostPromotionCategoryForm;
