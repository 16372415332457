import gql from 'graphql-tag';

const GET_GROUPS = gql`
  query getGroups {
    getGroups {
      id
      name
      code
      description
    }
  }
`;

export default GET_GROUPS;
