import React from 'react';
import LinkText from 'components/LinkText';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    render: function renderImage(val) {
      return <img width={48} height={48} src={val} alt="category" />;
    },
    width: '20%',
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    render: function renderPriority(val) {
      return <span>{val}</span>;
    },
    width: '10%',
  },
];

export default {
  columns,
};
