import React from 'react';
import { Input, Form, Col } from 'antd';
import PropTypes from 'prop-types';

import {
  isPercentDiscountType,
  isGreaterDiscountAmountThan,
  isLessDiscountAmountThan,
  required,
} from 'utils/formValidator';

import { formItemLayout } from './layout';

function BoostRangePromotionForm(props) {
  const { form, initial, label, placeholder, name, require, disabled } = props;
  const { getFieldDecorator } = form;
  const maxKey = `${name}Max`;
  const minKey = `${name}Min`;
  const rule = require ? required() : [];

  return (
    <Form.Item label={label} {...formItemLayout} required={require}>
      <Col span={11}>
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(maxKey, {
            initialValue: initial && initial[0] ? initial[0] : null,
            rules: [isPercentDiscountType(), isGreaterDiscountAmountThan(form.getFieldValue(minKey)), rule],
          })(<Input placeholder={placeholder[0]} disabled={disabled} />)}
        </Form.Item>
      </Col>
      <Col span={2}>
        <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
      </Col>
      <Col span={11}>
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(minKey, {
            initialValue: initial && initial[1] ? initial[1] : null,
            rules: [isPercentDiscountType(), isLessDiscountAmountThan(form.getFieldValue(maxKey)), rule],
          })(<Input placeholder={placeholder[1]} disabled={disabled} />)}
        </Form.Item>
      </Col>
    </Form.Item>
  );
}

BoostRangePromotionForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.arrayOf(PropTypes.string).isRequired,
  initial: PropTypes.arrayOf(PropTypes.string).isRequired,
  require: PropTypes.bool,
  disabled: PropTypes.bool,
};

BoostRangePromotionForm.defaultProps = {
  require: false,
  disabled: false,
};

export default BoostRangePromotionForm;
