import gql from 'graphql-tag';

export const settlementPaymentListQuery = gql`
  query settlementPaymentList($filter: String, $skip: Int, $pageSize: Int) {
    settlementPaymentList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      total
      list {
        id
        status
        idempotencyKey
        currency
        checkoutAmount
        billingAmount
        description
        returnUrl
        createdAt
        updatedAt
        merchant {
          name
          displayName
        }
      }
    }
  }
`;

export default {
  settlementPaymentListQuery,
};
