import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Modal, notification } from 'antd';
import CommonForm from 'schema/CommonForm';

@autobind
class DFUserAccountModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    handFormSubmit: PropTypes.func.isRequired,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    uiSchema: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  onCancel() {
    this.props.onCancel();
  }

  onOk() {
    this.editForm.handleSubmit();
  }

  validate(data) {
    const { loginId, password, permittedMerchantUserIds } = data;

    // loginId
    if (loginId) {
      const regex = /[^a-z\d]/;
      if (regex.test(loginId)) {
        return { success: false, message: 'ID 입력 오류' };
      }
    }

    // password
    if (password) {
      const regex = /[^a-zA-Z\d~!@#\$%\^&*()\-_+=,.\/<>?;:\[\]\{\}\\]/;
      if (regex.test(password)) {
        return { success: false, message: '비밀번호 입력 오류' };
      }
    }

    // permittedMerchantUserIds
    if (permittedMerchantUserIds) {
      const regex = /[^a-zA-Z\d,\s]/;
      if (regex.test(permittedMerchantUserIds)) {
        return { success: false, message: '가맹점 사용자 ID 입력 오류' };
      }
    }

    return { success: true };
  }

  handFormSubmit(data) {
    const { success, message } = this.validate(data);
    if (!success) {
      notification.error({
        placement: 'bottomLeft bottomRight',
        message,
      });
      return;
    }

    this.props.handFormSubmit(data);
  }

  render() {
    return (
      <Modal
        visible={this.props.visible}
        cancelText="Close"
        okText="Save"
        title={this.props.title}
        onCancel={this.onCancel}
        onOk={this.onOk}
        destroyOnClose
      >
        <CommonForm
          ref={instance => {
            this.editForm = instance;
          }}
          schema={this.props.schema}
          uiSchema={this.props.uiSchema}
          formData={this.props.formData}
          handleSubmit={this.handFormSubmit}
        />
      </Modal>
    );
  }
}

export default DFUserAccountModal;
