import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, message } from 'antd';
import styles from './CopyableText.scss';

/**
 * Display Text that can copy on click
 */
class CopyableText extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    tooltip: null,
    children: null,
  };

  /**
   * Copy a specified text to clipboard
   * @param {string} text - Text to copy
   */
  copyText(text) {
    const inputEl = document.createElement('input');
    document.body.appendChild(inputEl);
    inputEl.value = text;
    inputEl.select();
    document.execCommand('copy', false);
    inputEl.remove();

    message.success('Copied.');
  }

  renderWithTooltip() {
    const { value, tooltip, children } = this.props;

    return (
      <Tooltip title={`${tooltip} (Click to copy)`}>
        <span onClick={this.copyText.bind(this, value)} className={styles.clickToCopyText}>
          {children}
        </span>
      </Tooltip>
    );
  }

  renderWithoutTooltip() {
    const { value, children } = this.props;

    return (
      <span onClick={this.copyText.bind(this, value)} className={styles.clickToCopyText}>
        {children}
      </span>
    );
  }

  render() {
    const { tooltip } = this.props;

    return tooltip ? this.renderWithTooltip() : this.renderWithoutTooltip();
  }
}

export default CopyableText;
