import apolloClient from 'helpers/apolloClient';
import { apiKeyAddMutation, apiKeyUpdateMutation } from './mutations';

export const ADD_API_KEY = 'apiKey/ADD';
export const ADD_API_KEY_SUCCESS = 'apiKey/ADD_SUCCESS';
export const ADD_API_KEY_FAILURE = 'apiKey/ADD_FAILURE';

export const UPDATE_API_KEY = 'apiKey/UPDATE';
export const UPDATE_API_KEY_SUCCESS = 'apiKey/UPDATE_SUCCESS';
export const UPDATE_API_KEY_FAILURE = 'apiKey/UPDATE_FAILURE';

export function addApiKey(apiKey) {
  return {
    types: [ADD_API_KEY, ADD_API_KEY_SUCCESS, ADD_API_KEY_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: apiKeyAddMutation,
        variables: apiKey,
      });

      return result.data.addApiKey;
    },
  };
}

export function updateApiKey(apiKey) {
  return {
    types: [UPDATE_API_KEY, UPDATE_API_KEY_SUCCESS, UPDATE_API_KEY_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: apiKeyUpdateMutation,
        variables: apiKey,
      });

      return result.data.updateApiKey;
    },
  };
}
