import React, { Component } from 'react';
import { DatePicker, Form, Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { required, isAfter } from 'utils/formValidator';

import { formItemLayout } from './layout';

class BoostPeriodForm extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    require: PropTypes.bool,
    readOnly: PropTypes.bool,
    additionalDisable: PropTypes.func,
  };

  static defaultProps = {
    require: false,
    readOnly: false,
    additionalDisable: undefined,
  };

  input = {
    startAt: null,
    endAt: null,
  };

  render() {
    const { form, label, name, initial, require, readOnly, additionalDisable } = this.props;
    const { getFieldDecorator } = form;
    const [startAt, endAt] = initial;

    const startAtKey = `${name}StartAt`;
    const endAtKey = `${name}EndAt`;
    const rule = require ? required() : [];

    return (
      <Form.Item label={label} {...formItemLayout} required={require}>
        <Col span={11}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(startAtKey, {
              initialValue: startAt && moment(startAt),
              rules: [rule],
            })(
              <DatePicker
                disabledDate={current => {
                  if (current < moment().subtract(1, 'day').endOf('day')) {
                    return true;
                  }
                  if (additionalDisable && additionalDisable(current)) {
                    return true;
                  }

                  return false;
                }}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ minWidth: 0 }}
                disabled={readOnly}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={2}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
        </Col>
        <Col span={11}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(endAtKey, {
              initialValue: endAt && moment(endAt),
              rules: [rule, isAfter(this.props.form.getFieldsValue()[startAtKey])],
            })(
              <DatePicker
                style={{ minWidth: 0 }}
                disabledDate={current => {
                  if (current < moment().subtract(1, 'day').endOf('day')) {
                    return true;
                  }
                  if (additionalDisable && additionalDisable(current)) {
                    return true;
                  }

                  return false;
                }}
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                disabled={readOnly}
              />
            )}
          </Form.Item>
        </Col>
      </Form.Item>
    );
  }
}

export default BoostPeriodForm;
