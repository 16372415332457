import { Alert, Button, Col, message, Row, Table } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import apolloClient from 'helpers/apolloClient';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphQueryToState, queryDefault } from 'utils/graphToStateUtil';
import PromotionIssueFormModal from '../Modals/PromotionIssueFormModal';
import { addPromotionAirdropMutation, updatePromotionAirdropMutation } from '../PromotionMutations';
import { promotionAirdropListQuery } from '../PromotionQueries';
import { getAirdropColumns } from './metadata';

const mapDispatchToProps = {
  push,
};

@connect(null, mapDispatchToProps)
@autobind
class PromotionAirdropUsers extends Component {
  static propTypes = {
    targetId: PropTypes.string,
  };

  static defaultProps = {
    targetId: undefined,
  };

  state = {
    ...queryDefault,
    addUserModalVisible: false,
  };

  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount() {}

  airdropQuery = null;

  async refresh() {
    const { targetId } = this.props;
    this.getAirdropItem(targetId);
  }

  async getAirdropItem(targetId) {
    if (!targetId || targetId === 'add') {
      return;
    }
    try {
      await graphQueryToState({
        onSetState: args => this.setState(args),
        key: 'promotionAirdropList',
        query: promotionAirdropListQuery,
        variables: {
          filter: JSON.stringify({ policyId: targetId }),
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  showAddUserModal() {
    this.setState({
      addUserModalVisible: true,
    });
  }

  hideModal() {
    this.setState({
      addUserModalVisible: false,
    });
  }

  async cancelAirdrop(id) {
    try {
      await apolloClient.mutate({
        mutation: updatePromotionAirdropMutation,
        variables: { id, status: 'canceled' },
      });

      message.success('Promotion airdrop canceled');
      await this.getAirdropItem(this.props.targetId);
    } catch (error) {
      message.error('Cancel failed.\n', error.message);
    }
  }

  async addAirdrop(formFields) {
    try {
      await apolloClient.mutate({
        mutation: addPromotionAirdropMutation,
        variables: formFields,
      });

      message.success('Success.');
      await this.getAirdropItem(this.props.targetId);
    } catch (error) {
      message.error('Failed.\n', error.message);
    }
  }

  render() {
    const { data, error, loading, addUserModalVisible } = this.state;

    if (error) {
      return <Alert type="error" message={error.message} />;
    }

    if (this.props.targetId === 'add') {
      return <Alert description="프로모션을 생성한 후 대상 유저를 등록 해 주세요" type="warning" showIcon />;
    }

    if (!loading && !data?.list) {
      return <Alert type="error" message="No Data" />;
    }

    const airdropColumns = getAirdropColumns({
      cancelAirdrop: this.cancelAirdrop,
    });

    return (
      !loading && (
        <>
          <Row type="flex" gutter={12}>
            <Col>
              <Button type="primary" icon="plus" ghost onClick={this.showAddUserModal}>
                Add
              </Button>
            </Col>
          </Row>
          <Row>
            {error ? (
              <Alert message="Oops!" description="Failed to load Airdrop list." type="warning" showIcon />
            ) : (
              data?.list.length > 0 && (
                <Table rowKey="id" dataSource={data?.list} columns={airdropColumns} pagination={false} />
              )
            )}
          </Row>
          {addUserModalVisible && (
            <PromotionIssueFormModal
              onOk={this.addAirdrop}
              onCancel={this.hideModal}
              visible={addUserModalVisible}
              promotionId={this.props.targetId}
              issueType="airdrop"
            />
          )}
        </>
      )
    );
  }
}

export default PromotionAirdropUsers;
