import apolloClient from 'helpers/apolloClient';
import { boltDrawPromotionListQuery } from './queries';
import { boltDrawPromotionAddMutation, boltDrawPromotionUpdateMutation } from './mutations';

export const FETCH_BOLTDRAW_PROMOTION = 'boltDrawPromotion/FETCH';
export const FETCH_BOLTDRAW_PROMOTION_SUCCESS = 'boltDrawPromotion/FETCH_SUCCESS';
export const FETCH_BOLTDRAW_PROMOTION_FAILURE = 'boltDrawPromotion/FETCH_FAILURE';

export const ADD_BOLTDRAW_PROMOTION = 'boltDrawPromotion/ADD';
export const ADD_BOLTDRAW_PROMOTION_SUCCESS = 'boltDrawPromotion/ADD_SUCCESS';
export const ADD_BOLTDRAW_PROMOTION_FAILURE = 'boltDrawPromotion/ADD_FAILURE';

export const UPDATE_BOLTDRAW_PROMOTION = 'boltDrawPromotion/UPDATE';
export const UPDATE_BOLTDRAW_PROMOTION_SUCCESS = 'boltDrawPromotion/UPDATE_SUCCESS';
export const UPDATE_BOLTDRAW_PROMOTION_FAILURE = 'boltDrawPromotion/UPDATE_FAILURE';

export const SET_FILTER = 'boltDrawPromotion/SET_FILTER';
export const RESET_FILTER = 'boltDrawPromotion/RESET_FILTER';

export const SET_CURRENT_PAGE = 'boltDrawPromotion/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'boltDrawPromotion/SET_PAGE_SIZE';

export function getBoltDrawPromotionList(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BOLTDRAW_PROMOTION, FETCH_BOLTDRAW_PROMOTION_SUCCESS, FETCH_BOLTDRAW_PROMOTION_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: boltDrawPromotionListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.boltDrawPromotionList;
    },
  };
}

export function addBoltDrawPromotion(boltDrawPromotion) {
  return {
    types: [ADD_BOLTDRAW_PROMOTION, ADD_BOLTDRAW_PROMOTION_SUCCESS, ADD_BOLTDRAW_PROMOTION_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boltDrawPromotionAddMutation,
        variables: boltDrawPromotion,
      });

      return result.data.boltDrawPromotionAdd;
    },
  };
}

export function updateBoltDrawPromotion(boltDrawPromotion) {
  return {
    types: [UPDATE_BOLTDRAW_PROMOTION, UPDATE_BOLTDRAW_PROMOTION_SUCCESS, UPDATE_BOLTDRAW_PROMOTION_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: boltDrawPromotionUpdateMutation,
        variables: boltDrawPromotion,
      });

      return boltDrawPromotion;
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
