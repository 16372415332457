import React from 'react';
import LinkText from 'components/LinkText';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import moment from 'moment';
import { formatDate } from 'utils/format';

const getStatus = status =>
  ({
    active: 'success',
    inactive: 'default',
  }[status]);

export const columns = [
  {
    title: 'Title',
    dataIndex: 'title',
    render: function renderTitle(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '20%',
  },
  {
    title: 'Merchant',
    align: 'center',
    dataIndex: 'merchant',
  },
  {
    title: 'Start At',
    dataIndex: 'startAt',
    align: 'center',
    render(val, fullValue) {
      const color = moment(Date.now()).isBetween(fullValue.startAt, fullValue.endAt) ? 'black' : 'red';
      return <span style={{ color }}>{formatDate(val, 'YYYY-MM-DD')}</span>;
    },
  },
  {
    title: 'End At',
    dataIndex: 'endAt',
    align: 'center',
    render(val, fullValue) {
      const color = moment(Date.now()).isBetween(fullValue.startAt, fullValue.endAt) ? 'black' : 'red';
      return <span style={{ color }}>{formatDate(val, 'YYYY-MM-DD')}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} text={startCase(val)} />
        </span>
      );
    },
  },
  {
    title: 'Order',
    align: 'center',
    width: '10%',
    dataIndex: 'sortedIndex',
  },
];

export default {
  columns,
};
