import gql from 'graphql-tag';

export const getMyAdminQuery = gql`
  query getMyAdmin {
    getMyAdmin {
      id
      uid
      phone
      fullname
      otp
      groups {
        id
        name
        code
        description
        actions {
          id
          name
          code
          description
        }
      }
    }
  }
`;

export default {
  getMyAdminQuery,
};
