import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';
import classNames from 'classnames';
import styles from '../Policy.scss';

const PolicyFormItem = ({ children, ...props }) => (
  <Form.Item {...props} className={classNames(props.className, styles.formControl)}>
    {children}
  </Form.Item>
);

PolicyFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

PolicyFormItem.defaultProps = {
  className: null,
};

export default PolicyFormItem;
