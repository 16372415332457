import React, { Component, Fragment } from 'react';
import { func, string, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import autobind from 'autobind-decorator';
import { Table, Card, Divider, Badge, Alert, Button, message, Tabs, Descriptions, Typography } from 'antd';
import 'ant-design-pro/dist/ant-design-pro.css';
import { addTab, focusTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import LinkText from 'components/LinkText';
import CopyableText from 'components/CopyableText';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';
import { hasGroups, isAdmin, isCustomerService } from 'utils/permission';
import { ellipsis } from 'utils/text';
import { PromotionBenefitType } from 'containers/Promotion';
import { paymentQuery } from './PaymentQueries';
import { cancelPGPaymentMutation, cancelPaymentMutation, updateCashReceiptMutation } from './PaymentMutations';
import TransactionLog from './TransactionLog/TransactionLog';
import { showArrayResultModal } from './Modals';
import PaymentCancelModal from './Modals/PaymentCancelModal';
import BoostReportModal from './Modals/BoostReportModal';
import styles from './Payment.scss';

const { TabPane } = Tabs;
const { Title } = Typography;

const env = process.env.NODE_CONFIG_ENV || 'staging';
const ALICE_ADMIN_DOMAIN = env === 'prod' ? 'alice.chai.finance' : `alice-${env}.chai.finance`;

const mapStateToProps = state => ({
  tabList: state.tabs.payment.list,
});

const mapDispatchToProps = {
  addTab,
  focusTab,
  push,
};

function getBenefitAmount(checkoutAmount, billingAmount, benefitType, benefit, maxDiscountAmount) {
  const isRate = typeof benefit === 'string' && benefit.indexOf('%') > -1;
  const rate = isRate ? Number(benefit.replace('%', '')) : 0;
  const amount = isRate ? 0 : Number(benefit);

  let discountAmount = 0;
  let cashbackAmount = 0;

  if (benefitType === PromotionBenefitType.DISCOUNT) {
    // 할인 - checkoutAmount 기준으로 할인금액 계산, 소숫점 버림
    discountAmount = Math.floor(checkoutAmount * Number(rate) * 0.01) + amount;
    discountAmount = Math.min(checkoutAmount, discountAmount);
  } else if (benefitType === PromotionBenefitType.CASHBACK) {
    // 캐시백 - billingAmount 기준으로 할인금액 계산, 소숫점 버림
    cashbackAmount = Math.floor(billingAmount * Number(rate) * 0.01) + amount;
    cashbackAmount = Math.min(billingAmount, cashbackAmount);
  }

  // 최대 한도가 있을 경우 적용
  if (maxDiscountAmount) {
    discountAmount = Math.min(maxDiscountAmount, discountAmount);
    cashbackAmount = Math.min(maxDiscountAmount, cashbackAmount);
  }

  return { discountAmount, cashbackAmount };
}

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
@autobind
class PaymentDetail extends Component {
  static propTypes = {
    addTab: func.isRequired,
    focusTab: func.isRequired,
    push: func.isRequired,
    id: string.isRequired,
    location: objectOf(any).isRequired,
  };

  state = {
    data: {},
    error: null,
    loading: false,
    // Modal Control
    paymentCancelModalVisible: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: paymentQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.payment) {
        throw new Error('Invalid Payment ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.payment,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Payment: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  openUserTab(id, extra = {}) {
    const tabData = new TabData({
      key: id,
      title: `${id.substr(0, 5)}...`,
      closable: true,
      data: {
        id,
      },
      componentType: 'UserDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;
    const { pathname } = this.props.location;
    const tabKey = pathname.split('/')[1];

    this.props.addTab(tabKey, tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab(tabKey, id);
      this.props.push(`/${tabKey}/${id}`);
    }
  }

  showPaymentAPIKeyInputModal() {
    this.setState({
      paymentCancelModalVisible: true,
    });
  }

  hidePaymentAPIKeyInputModal() {
    this.setState({
      paymentCancelModalVisible: false,
    });
  }

  async cancelPayment(formData) {
    try {
      const { publicAPIKey, cancelAmount } = formData;
      const { id } = this.state.data;

      await apolloClient.mutate({
        mutation: cancelPaymentMutation,
        variables: {
          id,
          cancelAmount,
          publicAPIKey,
        },
      });

      message.success('Cancelled.');

      this.getData();
      this.hidePaymentAPIKeyInputModal();
    } catch (error) {
      message.error(`Failed to get cancel payment: ${error.message}`);
    }
  }

  async requestPGPaymentCancel() {
    try {
      const { id } = this.props;

      await apolloClient.mutate({
        mutation: cancelPGPaymentMutation,
        variables: {
          id,
        },
      });

      message.success('Canceled.');
    } catch (error) {
      message.error(`Failed to cancel payment in PG Server: ${error.message}`);
    }
  }

  /**
   * Get Status text for Badge Component
   * @param {string} status - Payment Status
   * @return {string}
   */
  getStatus(status) {
    return {
      waiting: 'default',
      prepared: 'processing',
      approved: 'processing',
      canceled: 'error',
      user_canceled: 'error',
      confirmed: 'success',
      partial_canceled: 'success',
      failed: 'error',
      timeout: 'warning',
      churn: 'warning',
    }[status];
  }

  /**
   * Get Status text of User Status for Badge
   * @param {string} status
   * @return {string}
   */
  getUserStatus(status) {
    return {
      active: 'success',
      inactive: 'default',
      identified: 'processing',
      unidentified: 'warning',
    }[status];
  }

  getCouponStatus(status) {
    return {
      enabled: 'success',
      disabled: 'error',
      used: 'success',
      refunded: 'error',
    }[status];
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  renderModals() {
    const { paymentCancelModalVisible, data } = this.state;
    const { checkoutAmount } = data;

    return (
      <Fragment>
        <PaymentCancelModal
          onOk={this.cancelPayment}
          onCancel={this.hidePaymentAPIKeyInputModal}
          visible={paymentCancelModalVisible}
          maxAmount={checkoutAmount || 0}
        />
      </Fragment>
    );
  }

  renderMoney(amount) {
    const { merchant } = this.state.data;

    // TODO: 싱가포르 테스트용 예외처리
    if (merchant && merchant.id === 'ab69839f-072c-4eba-a5ad-6aafbb4b05ce') {
      return `${commify(amount)} SGD`;
    }
    return `${commify(amount)} KRW`;
  }

  async cancelReceipt() {
    try {
      await apolloClient.mutate({
        mutation: updateCashReceiptMutation,
        variables: {
          id: this.props.id,
          isCancel: true,
        },
      });

      message.success('Successfully updated cash receipt');

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  async updateReceipt(value) {
    try {
      await apolloClient.mutate({
        mutation: updateCashReceiptMutation,
        variables: {
          id: this.props.id,
          ...value,
        },
      });

      message.success('Successfully updated cash receipt');

      this.getData();
    } catch (error) {
      message.error(error.message);
    }
  }

  renderSummary() {
    const { id } = this.props;
    const { data } = this.state;
    const { description, idempotencyKey, cashbackStatus } = data;
    const { status, createdAt, updatedAt } = data;
    const { merchant } = data;
    const { data: detailData = {} } = data;
    const isPartialCanceled = status === 'confirmed' && data.canceledAmount > 0;
    const displayStatus = isPartialCanceled ? 'partial_canceled' : status;
    const chargeData = get(detailData, 'charge', null);
    const chargeAccount = get(chargeData, 'account', {});
    const cashReceipt = get(detailData, 'cashReceipt', {});

    const hasPaymentCancelPermission = hasGroups(['admin', 'merchant']);

    return (
      <Fragment>
        <Title level={4}>Summary</Title>
        <Descriptions size="middle" bordered column={{ xs: 1, sm: 1, md: 2, xl: 3 }}>
          <Descriptions.Item label="Description">{description}</Descriptions.Item>
          <Descriptions.Item label="Merchant">{merchant ? merchant.displayName : '-'}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge status={this.getStatus(displayStatus)} text={startCase(displayStatus)} />
          </Descriptions.Item>

          <Descriptions.Item label="Payment ID">
            <CopyableText tooltip={id} value={id}>
              {ellipsis(id, 20)}
            </CopyableText>
          </Descriptions.Item>
          <Descriptions.Item label="Created At">{formatDate(createdAt)}</Descriptions.Item>
          <Descriptions.Item label="Updated At">{formatDate(updatedAt)}</Descriptions.Item>

          <Descriptions.Item label="Idempotency Key">
            {idempotencyKey && (
              <CopyableText tooltip={idempotencyKey} value={idempotencyKey}>
                {ellipsis(idempotencyKey, 20)}
              </CopyableText>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Name">{chargeAccount.bankName || '-'}</Descriptions.Item>
          <Descriptions.Item label="Account Number">{chargeAccount.accountNumber || '-'}</Descriptions.Item>

          <Descriptions.Item label="Data">
            <div
              className={styles.pgResultContainer}
              onClick={showArrayResultModal.bind(null, {
                title: 'Payment Data',
                data: detailData,
              })}
            >
              <LinkText>Detail</LinkText>
              <Button size="small" shape="circle" icon="right" style={{ marginLeft: 10 }} />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Cash Receipt">
            <div
              className={styles.pgResultContainer}
              onClick={showArrayResultModal.bind(null, {
                title: 'Cash Receipt',
                data: cashReceipt,
                editReceipt: detailData.isCashReceipt && true,
                updateReceipt: this.updateReceipt,
                cancelReceipt: this.cancelReceipt,
              })}
            >
              <LinkText>Detail</LinkText>
              <Button size="small" shape="circle" icon="right" style={{ marginLeft: 10 }} />
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Cashback Status">{cashbackStatus}</Descriptions.Item>

          {status === 'confirmed' && hasPaymentCancelPermission && (
            <Descriptions.Item label="Payment Cancel">
              <Button type="danger" ghost onClick={this.showPaymentAPIKeyInputModal}>
                Cancel
              </Button>
            </Descriptions.Item>
          )}

          {status === 'failed' && (
            <Descriptions.Item label="PG Payment Cancel">
              <Button type="danger" ghost onClick={this.requestPGPaymentCancel}>
                Cancel
              </Button>
            </Descriptions.Item>
          )}
        </Descriptions>
      </Fragment>
    );
  }

  renderUser() {
    const { user, data } = this.state.data;

    if (!user) {
      return null;
    }

    const autoCharge = get(data, 'user.autoCharge');
    const balance = get(data, 'user.balance');
    const canOpenUserDetail = isAdmin() || isCustomerService();

    return (
      <div style={{ marginTop: 50 }}>
        <Title level={4}>User</Title>
        <Descriptions size="middle" bordered column={{ xs: 1, sm: 2, lg: 3 }}>
          <Descriptions.Item label="User ID">
            {canOpenUserDetail && (
              <LinkText onClick={this.openUserTab.bind(null, `CS-${user.id}`)}>{ellipsis(user.id, 25)}</LinkText>
            )}
            {!canOpenUserDetail && (
              <CopyableText tooltip={user.id} value={user.id}>
                {ellipsis(user.id, 25)}
              </CopyableText>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Name">{user.fullname}</Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge status={this.getUserStatus(user.status)} text={startCase(user.status)} />
          </Descriptions.Item>
          <Descriptions.Item label="Phone">{user.phone}</Descriptions.Item>
          <Descriptions.Item label="Auto Charge">{autoCharge ? 'On' : 'Off'}</Descriptions.Item>
          <Descriptions.Item label="Balance">{balance && `${commify(balance)} KRW`}</Descriptions.Item>
        </Descriptions>
      </div>
    );
  }

  renderReceipt() {
    const { data } = this.state;
    const { discountAmount, checkoutAmount, billingAmount, cashbackAmount, pointAmount, cashAmount } = data;
    const {
      canceledAmount,
      canceledDiscountAmount,
      canceledBillingAmount,
      canceledCashbackAmount,
      canceledPointAmount,
      canceledCashAmount,
    } = data;
    const initialData = get(data, 'data.initial', {});

    const baseOption = { align: 'right', width: '12.5%', render: value => commify(value || 0) };
    const receiptColumns = [
      { title: '', dataIndex: 'status', align: 'center' },
      { title: 'Checkout', dataIndex: 'checkout', ...baseOption },
      { title: 'Billing', dataIndex: 'billing', ...baseOption },
      { title: 'Point', dataIndex: 'point', ...baseOption },
      { title: 'Cash', dataIndex: 'cash', ...baseOption },
      { title: 'Charging', dataIndex: 'charging', ...baseOption },
      { title: 'Discount', dataIndex: 'discount', ...baseOption },
      { title: 'Cashback', dataIndex: 'cashback', ...baseOption },
    ];
    const receiptData = [
      {
        key: 'initial',
        status: 'Initial',
        checkout: initialData.checkoutAmount,
        discount: initialData.discountAmount,
        billing: initialData.billingAmount,
        point: initialData.pointAmount,
        cash: initialData.cashAmount,
        cashback: initialData.cashbackAmount,
        charging: initialData.chargingAmount,
      },
      {
        key: 'canceled',
        status: 'Canceled',
        checkout: -canceledAmount,
        discount: -canceledDiscountAmount,
        billing: -canceledBillingAmount,
        point: -canceledPointAmount,
        cash: -canceledCashAmount,
        cashback: -canceledCashbackAmount,
        charging: '-',
      },
      {
        key: 'final',
        status: 'Final',
        checkout: checkoutAmount,
        discount: discountAmount,
        billing: billingAmount,
        point: pointAmount,
        cash: cashAmount,
        cashback: cashbackAmount,
        charging: '-',
      },
    ];

    return (
      <div style={{ marginTop: 50 }}>
        <Title level={4}>Receipt</Title>
        <Table
          columns={receiptColumns}
          dataSource={receiptData}
          pagination={false}
          size="middle"
          bordered
          scroll={{ x: true }}
        />
      </div>
    );
  }

  renderBoost() {
    const { data } = this.state;
    const { usedBoost } = data;

    if (!usedBoost) {
      return null;
    }

    const boostColumns = [
      { title: 'ID', dataIndex: 'id', align: 'center', width: '5%' },
      {
        title: 'Campaign ID',
        dataIndex: 'campaignId',
        align: 'center',
        width: '5%',
        render: (value, others) => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${ALICE_ADMIN_DOMAIN}/boost/${others.brandId}/campaign/${value}`}
          >
            {value}
          </a>
        ),
      },
      { title: 'Title', dataIndex: 'title', align: 'center', width: '12.5%' },
      { title: 'Subtitle', dataIndex: 'subtitle', align: 'center', width: '12.5%' },
    ];

    const boostData = [
      {
        key: usedBoost.id,
        id: usedBoost.id,
        campaignId: usedBoost.boostCampaignId,
        title: usedBoost.boostPromotion.subTitle,
        subtitle: usedBoost.boostPromotion.title,
        brandId: usedBoost.boostPromotion.brandId,
      },
    ];

    return (
      <div style={{ marginTop: 50 }}>
        <Title level={4}>Boost</Title>
        <Table
          columns={boostColumns}
          dataSource={boostData}
          pagination={false}
          size="middle"
          bordered
          scroll={{ x: true }}
        />
      </div>
    );
  }

  renderPromotion(getInitialPromotion = false) {
    const { data } = this.state;
    const paymentData = getInitialPromotion ? get(data, 'data.initial', {}) : data;
    const { checkoutAmount, billingAmount } = paymentData;
    const coupon = get(paymentData, 'coupon', null);
    const cashbacks = get(paymentData, 'cashbacks', []);
    const promotionColumns = [
      { title: 'Type', dataIndex: 'type', align: 'center' },
      { title: 'Title', dataIndex: 'title', align: 'center' },
      { title: 'Description', dataIndex: 'description', align: 'center', render: value => ellipsis(value, 30) },
      { title: 'Price Range', dataIndex: 'priceRange', align: 'center' },
      { title: 'Max', dataIndex: 'maxDiscountAmount', align: 'right', render: value => commify(value) },
      { title: 'Discount', dataIndex: 'discount', align: 'right' },
      { title: 'Cashback', dataIndex: 'cashback', align: 'right' },
    ];
    const promotionData = [];
    const total = { discountAmount: 0, cashbackAmount: 0 };

    if (coupon) {
      // initial data에는 id: couponId, benefilType: type
      promotionData.push({
        key: coupon.id || coupon.couponId,
        type: 'Coupon',
        title: coupon.title,
        description: coupon.description,
        priceRange: `${commify(coupon.priceMin || 0)} ~ ${commify(coupon.priceMax || 0)}`,
        maxDiscountAmount: coupon.maxDiscountAmount,
        benefitType: coupon.benefitType || coupon.type,
        benefit: coupon.discount,
      });
    }

    cashbacks.forEach(cashback => {
      // initial data에는 id: promotionId, benefilType: type
      promotionData.push({
        key: cashback.id || cashback.promotionId,
        type: 'Cashback',
        title: cashback.title,
        description: cashback.description,
        priceRange: `${commify(cashback.priceMin || 0)} ~ ${commify(cashback.priceMax || 0)}`,
        maxDiscountAmount: cashback.maxDiscountAmount,
        benefitType: cashback.benefitType || cashback.type,
        benefit: cashback.discount,
      });
    });

    // 프로모션별 discount, cashback 금액 계산
    promotionData.forEach(promotion => {
      const benefit = getBenefitAmount(
        checkoutAmount,
        billingAmount,
        promotion.benefitType,
        promotion.benefit,
        promotion.maxDiscountAmount
      );
      total.discountAmount += benefit.discountAmount;
      total.cashbackAmount += benefit.cashbackAmount;

      promotion.discount =
        promotion.benefit.match(/%/) && benefit.discountAmount
          ? `(${promotion.benefit}) ${benefit.discountAmount}`
          : commify(+benefit.discountAmount);
      promotion.cashback =
        promotion.benefit.match(/%/) && benefit.cashbackAmount
          ? `(${promotion.benefit}) ${benefit.cashbackAmount}`
          : commify(+benefit.cashbackAmount);
    });

    // total
    promotionData.push({
      key: 'promotion_total',
      type: 'Total',
      discount: commify(total.discountAmount),
      cashback: commify(total.cashbackAmount),
    });

    return (
      <div style={{ marginTop: 20 }}>
        <h3>{getInitialPromotion ? '- Initial Promotion' : '- Final Promotion'}</h3>
        <Table
          columns={promotionColumns}
          dataSource={promotionData}
          pagination={false}
          size="middle"
          bordered
          scroll={{ x: true }}
        />
      </div>
    );
  }

  renderTransactionLog() {
    const { data } = this.state;
    const { merchant } = data;
    const transactions = get(data, 'transactions', []);
    const userTransactions = transactions.filter(tx => tx.ledger.user);
    const merchantTransactions = transactions.filter(tx => tx.ledger.merchant);
    // TODO: 싱가포르 테스트용 예외처리
    if (merchant && merchant.id === 'ab69839f-072c-4eba-a5ad-6aafbb4b05ce') {
      userTransactions.forEach(tr => (tr.currency = 'SGD'));
      merchantTransactions.forEach(tr => (tr.currency = 'SGD'));
    }
    return (
      <div className={styles.transactionLogContainer} style={{ marginTop: 50 }}>
        <Title level={4}>Transaction Log</Title>
        <Tabs animated={false}>
          <TabPane tab="User" key="user_transactions">
            <TransactionLog dataSource={userTransactions} />
          </TabPane>
          <TabPane tab="Merchant" key="merchant_transactions">
            <TransactionLog dataSource={merchantTransactions} renderOwner />
          </TabPane>
        </Tabs>
      </div>
    );
  }

  renderReport() {
    const { reportedBoost } = this.state.data;

    return (
      <BoostReportModal
        id={this.props.id}
        getData={this.getData}
        status={reportedBoost.status}
        rejectReason={reportedBoost.rejectReason}
      />
    );
  }

  render() {
    const { error, loading } = this.state;
    const { reportedBoost } = this.state.data;

    if (!loading && error) {
      return this.renderError();
    }
    if (loading) {
      return null;
    }

    return (
      <Fragment>
        {this.renderModals()}

        <Card bordered={false}>
          {reportedBoost && this.renderReport()}
          {this.renderSummary()}
          {this.renderUser()}
          {this.renderReceipt()}
          <div style={{ marginTop: 50 }}>
            <Title level={4}>Promotion</Title>
            {this.renderPromotion(true)}
            {this.renderPromotion()}
          </div>
          {this.renderBoost()}
          {this.renderTransactionLog()}
        </Card>
      </Fragment>
    );
  }
}

export default PaymentDetail;
