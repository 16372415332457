import moment from 'moment';
import React, { Component } from 'react';
import { Icon, message } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import AutoLogoutLink, { LOGOUT_TIME, WARN_TIME } from 'helpers/apolloClient/links/autoLogoutLink';
import { extendSessionMutation } from './LogoutTimerMutations';
import styles from './LogoutTimer.scss';

@autobind
class LogoutTimer extends Component {
  state = {
    remainSeconds: LOGOUT_TIME / 1000,
  };

  componentWillMount() {
    this.intervalHandler = setInterval(this.updateTime, 1000);
    this.updateTime();
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandler);
    this.intervalHandler = null;
  }

  intervalHandler = null;

  updateTime() {
    const { lastRequestTime } = AutoLogoutLink;
    const expireTime = moment(lastRequestTime).add(LOGOUT_TIME, 'ms');
    const now = moment();
    const remainSeconds = moment.duration(expireTime.diff(now)).asSeconds();

    this.setState({
      remainSeconds: remainSeconds < 0 ? 0 : remainSeconds,
    });
  }

  async extendSession() {
    try {
      await apolloClient.mutate({ mutation: extendSessionMutation });
      message.success('Extended.');
      this.updateTime();
    } catch (error) {
      message.error(`Failed to extend session: ${error.message}`);
      console.log(error);
    }
  }

  render() {
    const { remainSeconds } = this.state;
    const formattedRemainTime = moment.utc(remainSeconds * 1000).format('mm:ss');
    const shouldLogoutSoon = remainSeconds <= (LOGOUT_TIME - WARN_TIME) / 1000;

    return (
      <div className={styles.logoutTimer}>
        <span className={shouldLogoutSoon ? styles.warning : ''}>
          <Icon type="clock-circle" className={styles.clockIcon} />
          <span>Logout: {formattedRemainTime}</span>
        </span>
        <span className={styles.extendText} onClick={this.extendSession}>
          [Extend]
        </span>
      </div>
    );
  }
}

export default LogoutTimer;
