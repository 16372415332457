import React from 'react';
import { oneOfType, number, string } from 'prop-types';
import styles from './TextChart.scss';

const TextChart = ({ label, value }) => (
  <div className={styles.textChart}>
    <div className={styles.value}>{value}</div>
    <div className={styles.label}>{label}</div>
  </div>
);

TextChart.propTypes = {
  label: string.isRequired,
  value: oneOfType([number, string]).isRequired,
};

export default TextChart;
