import React, { Component } from 'react';
import { number, func, objectOf, any } from 'prop-types';
import { Icon, Input } from 'antd';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import styles from './InfinitePagination.scss';

@autobind
class InfinitePagination extends Component {
  static propTypes = {
    current: number.isRequired,
    onChange: func.isRequired,
    style: objectOf(any).isRequired,
  };

  setPage(page, ev) {
    if (ev) {
      ev.preventDefault();
    }

    this.props.onChange(page);
  }

  setPrevPage(ev) {
    ev.preventDefault();

    const { current } = this.props;

    if (current === 1) return;

    this.props.onChange(current - 1);
  }

  setNextPage(ev) {
    ev.preventDefault();

    const { current } = this.props;
    this.props.onChange(current + 1);
  }

  getListElements() {
    const { current } = this.props;
    const listElements = [];
    let start = 1;

    if (current >= 3) {
      start = current - 2;
    }

    // Using <a> doesn't trigger onClick quite often.
    for (let i = start; i < start + 5; i += 1) {
      const isActive = i === current;

      listElements.push(
        <li
          key={i}
          className={cx(
            'ant-pagination-item',
            `ant-pagination-item-${i}`,
            isActive ? 'ant-pagination-item-active' : ''
          )}
        >
          <div
            className={cx(styles.paginationItem, isActive && styles.paginationItemActive)}
            onClick={this.setPage.bind(null, i)}
          >
            {i}
          </div>
        </li>
      );
    }

    return listElements;
  }

  handleJumperKeydown(ev) {
    const { keyCode } = ev;

    if (keyCode === 13) {
      ev.preventDefault();
      const { value } = ev.target;
      const page = parseInt(value, 10);

      if (Number.isNaN(Number(page))) {
        ev.target.value = '';
      } else {
        this.setPage(page);
      }
    }
  }

  render() {
    const { style } = this.props;

    return (
      <div className={styles.infinitePagination}>
        <ul className="ant-pagination" style={style}>
          <li className="ant-pagination-item">
            <div onClick={this.setPrevPage}>
              <Icon type="left" />
            </div>
          </li>

          {this.getListElements()}

          <li className="ant-pagination-item">
            <div onClick={this.setNextPage}>
              <Icon type="right" />
            </div>
          </li>

          <li className={styles.inlineBlock} style={{ marginRight: 10 }}>
            <div>Goto:</div>
          </li>

          <li className={styles.inlineBlock}>
            <Input className={styles.jumper} onKeyDown={this.handleJumperKeydown} />
          </li>
        </ul>
      </div>
    );
  }
}

export default InfinitePagination;
