import React from 'react';
import { formatDate } from 'utils/format';

export const columns = [
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderType(val) {
      return <span>{val}</span>;
    },
    width: '15%',
  },
  {
    title: 'Invitee Phone',
    dataIndex: 'inviteePhone',
    render: function renderPhone(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Invitee User ID',
    dataIndex: 'inviteeUserId',
    render: function renderId(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '25%',
  },
];

export default {
  columns,
};
