import apolloClient from 'helpers/apolloClient';
import { userListQuery } from './queries';
import { userUpdateMutation } from './mutations';

export const FETCH_USER = 'user/FETCH';
export const FETCH_USER_SUCCESS = 'user/FETCH_SUCCESS';
export const FETCH_USER_FAILURE = 'user/FETCH_FAILURE';

export const ADD_USER = 'user/ADD';
export const ADD_USER_SUCCESS = 'user/ADD_SUCCESS';
export const ADD_USER_FAILURE = 'user/ADD_FAILURE';

export const UPDATE_USER = 'user/UPDATE';
export const UPDATE_USER_SUCCESS = 'user/UPDATE_SUCCESS';
export const UPDATE_USER_FAILURE = 'user/UPDATE_FAILURE';

export const SET_FILTER = 'user/SET_FILTER';
export const RESET_FILTER = 'user/RESET_FILTER';

export const SET_CURRENT_PAGE = 'user/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'user/SET_PAGE_SIZE';

export const RESET = 'user/RESET';

export function getUsers(variables) {
  return {
    types: [FETCH_USER, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: userListQuery,
        variables,
      });

      return result.data.userList;
    },
  };
}

export function updateUser(user) {
  return {
    types: [UPDATE_USER, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: userUpdateMutation,
        variables: user,
      });

      return user;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}
