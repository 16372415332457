import React from 'react';
import { Input, Form } from 'antd';
import { required } from 'utils/formValidator';
import PropTypes from 'prop-types';

import { formItemLayout } from './layout';

function BoostTextForm(props) {
  const { form, initial, label, name, require, rule, onChange, multiline, maxLength, readOnly, disabled } = props;
  const { getFieldDecorator } = form;
  const rules = [{ whitespace: true }];
  const InputComponent = multiline ? Input.TextArea : Input;

  if (require) {
    rules.push(required());
  }

  if (rule) {
    rules.push(rule);
  }

  return (
    <Form.Item label={label} {...formItemLayout}>
      {getFieldDecorator(name, { initialValue: initial, rules })(
        <InputComponent
          rows={4}
          placeholder={label}
          onChange={e => onChange(e)}
          autoComplete="off"
          readOnly={readOnly}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}
    </Form.Item>
  );
}

BoostTextForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  require: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initial: PropTypes.string,
  rule: PropTypes.func,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
};

BoostTextForm.defaultProps = {
  require: false,
  rule: null,
  initial: '',
  multiline: false,
  readOnly: false,
  disabled: false,
  onChange: () => {},
  maxLength: null,
};

export default BoostTextForm;
