import React from 'react';
import startCase from 'lodash/startCase';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderId(val) {
      return <span>{val}</span>;
    },
    width: '30%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderType(val) {
      return <span>{startCase(val)}</span>;
    },
    width: '15%',
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    render: function renderAmount(val) {
      const symbol = val >= 0 ? '+' : '-';
      const color = symbol === '+' ? '#0eb53d' : '#e02323';
      return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
    },
    width: '15%',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    render: function renderCurrency(val) {
      return <span>{val.toUpperCase()}</span>;
    },
    width: '15%',
  },
  {
    title: 'Account',
    dataIndex: 'data',
    render: function renderCurrency(val) {
      const { bankName = '', accountNumber = '' } = val.account || {};
      return <span>{`${bankName} ${accountNumber}`}</span>;
    },
    width: '15%',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '30%',
  },
];

export default {
  columns,
};
