import React, { PureComponent } from 'react';
import { func, objectOf, any, number } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col, Input } from 'antd';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, reset, getB2BUsers } from 'redux/modules/b2bUser/actions';
import { isAdmin, isB2BMerchant } from 'utils/permission';
import rules from './DFUserFilterFormValidationRules';
import styles from './DFUser.scss';

const { Item } = Form;

const mapStateToProps = state => {
  const { pageSize, filter } = state.b2bUser;

  return {
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter,
  reset,
  getB2BUsers,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class DFUserFilterForm extends PureComponent {
  static propTypes = {
    setFilter: func.isRequired,
    resetFilter: func.isRequired,
    reset: func.isRequired,
    getB2BUsers: func.isRequired,
    form: objectOf(any).isRequired,
    filter: objectOf(any),
    pageSize: number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getB2BUsers(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();

    if (isAdmin() || isB2BMerchant()) {
      this.props.getB2BUsers(1, pageSize, null);
    } else {
      this.props.reset();
    }

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { loginId, depositCode } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col {...col4Props}>
            <Item label="ID">
              {getFieldDecorator('loginId', {
                rules: rules.loginId,
                initialValue: loginId,
              })(<Input placeholder="ID ..." autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* DepositCode */}
          <Col {...col4Props}>
            <Item label="Deposit Code">
              {getFieldDecorator('depositCode', {
                rules: rules.depositCode,
                initialValue: depositCode,
              })(<Input placeholder="Deposit Code ..." autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...col4Props}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col4Props}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default DFUserFilterForm;
