import apolloClient from './';

/**
 * Cancelable apolloClient.query()
 * @param {Object} queryOpts - Query Options
 */
export default function cancelableQuery(queryOpts) {
  let isCanceled = false;

  const request = apolloClient.query(queryOpts);
  const canceledError = new Error('Request canceled');
  canceledError.isCanceled = true;

  const wrappedPromise = new Promise((resolve, reject) => {
    request
      .then(val => {
        if (isCanceled) {
          reject(canceledError);
          return;
        }

        resolve(val);
      })
      .catch(error => {
        if (isCanceled) {
          reject(canceledError);
          return;
        }

        reject(error);
      });
  });

  wrappedPromise.cancel = () => (isCanceled = true);
  return wrappedPromise;
}
