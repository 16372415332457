import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import startCase from 'lodash/startCase';
import { Button } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import { formatDate } from 'utils/format';
import ContractDivider from './ContractDivider';
import styles from '../Merchant.scss';

const { Description } = DescriptionList;

@autobind
class ContractDisplay extends Component {
  static propTypes = {
    hideEdit: PropTypes.bool,
    switchMode: PropTypes.func,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    termStartAt: PropTypes.string.isRequired,
    termEndAt: PropTypes.string.isRequired,
    settlementCycle: PropTypes.number.isRequired,
    settlementProvideCycle: PropTypes.string.isRequired,
    autoExtension: PropTypes.string.isRequired,
    fee: PropTypes.string.isRequired,
    feeType: PropTypes.string.isRequired,
    includedVat: PropTypes.bool.isRequired,
    updatedAt: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    showDivider: PropTypes.bool,
    idx: PropTypes.number,
  };

  static defaultProps = {
    hideEdit: false,
    switchMode: () => {},
    showTitle: false,
    showDivider: false,
    idx: 0,
  };

  switchToUpdate() {
    const { switchMode } = this.props;
    switchMode(this.props.id, 'UPDATE');
  }

  render() {
    const { showTitle, showDivider, idx, hideEdit } = this.props;
    const { id, type, termStartAt, termEndAt } = this.props;
    const { settlementCycle, autoExtension, fee, feeType, updatedAt, settlementProvideCycle, includedVat } = this.props;
    const contractId = `CT-${id}`;

    return (
      <DescriptionList
        className={styles.leftAlignedDescriptionList}
        size="large"
        title={showTitle ? 'Contract' : undefined}
        style={{ marginBottom: 32 }}
      >
        {showDivider && <ContractDivider isActiveContract={idx === 0} number={idx + 1} />}

        {!hideEdit && (
          <Button icon="edit" style={{ position: 'absolute', top: 0, right: 0 }} onClick={this.switchToUpdate}>
            Edit
          </Button>
        )}

        <Description term="Contract ID">{contractId}</Description>
        <Description term="Type">{startCase(type)}</Description>
        <Description term="Term">
          {formatDate(termStartAt, 'YYYY-MM-DD')} ~ {formatDate(termEndAt, 'YYYY-MM-DD')}
        </Description>
        <Description term="Settlement Cycle">{startCase(settlementCycle)} Days</Description>
        <Description term="Settlement Provide Cycle">{startCase(settlementProvideCycle)}</Description>
        <Description term="Auto Extention">{startCase(autoExtension)}</Description>
        <Description term="Fee">{fee}</Description>
        <Description term="FeeType">{startCase(feeType)}</Description>
        <Description term="Included VAT">{startCase(String(includedVat))}</Description>
        <Description term="Last Update">{formatDate(updatedAt)}</Description>
      </DescriptionList>
    );
  }
}

export default ContractDisplay;
