import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import config from 'config';
import errorHandleLink from './links/errorHandleLink';
import autoLogoutLink from './links/autoLogoutLink';
import introspectionQueryResultData from './fragmentTypes';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const GRAPHQL_ENDPOINT = `${config.apiScheme}://${config.graphUrl}`;
const GRAPHQL_SUBSCRIPTIONS_ENDPOINT = `${config.graphqlWSScheme}://${config.graphUrl}`;
const CARD_GRAPHQL_ENDPOINT = `${config.apiScheme}://${config.cardGraphUrl}`;

// Create a http link:
const httpLink = createHttpLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const cardLink = createHttpLink({
  uri: CARD_GRAPHQL_ENDPOINT,
  credentials: 'include',
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: GRAPHQL_SUBSCRIPTIONS_ENDPOINT,
  options: {
    reconnect: true,
  },
});

// Create an upload link
const isFile = value =>
  (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob);
const isUpload = ({ variables }) => Object.values(variables).some(isFile);
const isCardGraph = op => op.getContext().cardGraph === true;
const uploadLink = createUploadLink({
  uri: GRAPHQL_ENDPOINT,
  credentials: 'include',
});

const terminatingLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  // if variable contains file or blob, use uploadLink instead, will send as multipart form data.
  split(isCardGraph, cardLink, split(isUpload, uploadLink, httpLink))
);

const link = ApolloLink.from([errorHandleLink, autoLogoutLink, terminatingLink]);

const cache = new InMemoryCache({ fragmentMatcher });

const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default apolloClient;
