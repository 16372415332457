import gql from 'graphql-tag';

export const promotionAirdropListQuery = gql`
  query promotionAirdropList($filter: String) {
    promotionAirdropList(filter: $filter) {
      list {
        id
        manager
        status
        issueAt
        data
      }
    }
  }
`;

export const couponListQuery = gql`
  query couponList($filter: String) {
    couponList(filter: $filter) {
      total
    }
  }
`;

export const merchantListQuery = gql`
  query merchantList($skip: Int, $pageSize: Int) {
    merchantList(skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        displayName
      }
    }
  }
`;

export const subMerchantListQuery = gql`
  query subMerchantList($skip: Int, $pageSize: Int) {
    subMerchantList(skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        businessNumber
        merchant {
          id
          displayName
          data
        }
      }
    }
  }
`;

export const promotionQuery = gql`
  query promotion($id: ID!) {
    promotion(id: $id) {
      id
      createdAt
      updatedAt
      title
      description
      code
      status
      policyType
      benefitType
      issueType
      startAt
      endAt
      couponStartDate
      couponDueDate
      isSelectable
      ttl
      ttlType
      used
      discount
      maxDiscountAmount
      priceMin
      priceMax
      maxPerPolicy
      maxPerUser
      isMerchantLifetimePromotion
      isUsableOnFirstPurchase
      isBulkIssuable
      isUsableOnAutoCharge
      pid
      discountSplitConditions {
        priceMin
        priceMax
        merchantRatio
      }
      merchants {
        id
        name
        displayName
      }
      subMerchants {
        subMerchant {
          id
          name
        }
      }
      couponCounts {
        enabled
        disabled
        used
        issued
        notSelectable
      }
    }
  }
`;

export default {
  couponListQuery,
  merchantListQuery,
  subMerchantListQuery,
  promotionQuery,
};
