import BoostExclusionRuleContainer from './BoostExclusionRuleContainer';

export const BoostExclusionType = {
  PAYMENT_CAN_NOT_USE_BOOST: 'paymentCanNotUseBoost',
  PAYMENT_CAN_NOT_USE_BOOST_WHITELIST: 'paymentCanNotUseBoostWhitelist', // 결제 시 부스트 적용 불가 화이트 리스트
  CANCEL_RESTORE_BOOST: 'cancelRestoreBoost',
};

export const BoostExclusionTypeText = {
  [BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST]: '결제 시 부스트 적용 불가',
  [BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST_WHITELIST]: '결제 시 부스트 적용 불가 화이트리스트',
  [BoostExclusionType.CANCEL_RESTORE_BOOST]: '취소 시 부스트 반환',
};

export default BoostExclusionRuleContainer;
