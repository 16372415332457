import gql from 'graphql-tag';

export const boltPromotionListQuery = gql`
  query boltPromotionListQuery($filter: String, $skip: Int, $pageSize: Int) {
    boltPolicyList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        type
        title
        description
        status
        startAt
        endAt
        boltPolicyMerchant {
          merchant {
            name
          }
        }
      }
      total
    }
  }
`;

export const boltPromotionQuery = gql`
  query boltPromotionQuery($id: ID!) {
    boltPolicy(id: $id) {
      id
      createdAt
      updatedAt
      title
      description
      status
      startAt
      endAt
      type
      receiverType
      airdropUsers {
        userId
        status
      }
      issueCountRule {
        type
        count
        range {
          min
          max
          count
        }
      }
      periodCapsPerUser {
        type
        count
        range {
          period
          count
        }
      }
      expirationRule {
        type
        absoluteTime
        relativeTime
      }
      boltPolicyMerchant {
        merchantId
      }
    }
  }
`;

export default {
  boltPromotionQuery,
  boltPromotionListQuery,
};
