import gql from 'graphql-tag';

export const bannerGroupAddMutation = gql`
  mutation bannerGroupAdd($name: String!) {
    bannerGroupAdd(name: $name)
  }
`;

export const bannerGroupUpdateMutation = gql`
  mutation bannerGroupUpdate($id: ID!, $name: String!) {
    bannerGroupUpdate(id: $id, name: $name)
  }
`;

export const bannerGroupRemoveMutation = gql`
  mutation bannerGroupRemove($id: ID!) {
    bannerGroupRemove(id: $id)
  }
`;

export default {
  bannerGroupAddMutation,
  bannerGroupUpdateMutation,
  bannerGroupRemoveMutation,
};
