import gql from 'graphql-tag';

export const boltDrawPromotionQuery = gql`
  query boltDrawPromotion($id: Int!) {
    boltDrawPromotion(id: $id) {
      id
      status
      title
      description
      important
      visibleFrom
      visibleTo
      enterableFrom
      enterableTo
      winnerCount
      bettingTimeLimitMin
      bettingCountLimit
      totalBoltCount
      createdAt
      updatedAt
    }
  }
`;

export const boltDrawPromotionListQuery = gql`
  query boltDrawPromotionList($filter: String, $skip: Int, $pageSize: Int) {
    boltDrawPromotionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        status
        title
        visibleFrom
        visibleTo
        enterableFrom
        enterableTo
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const boltDrawPromotionImageListQuery = gql`
  query boltDrawPromotionImageList($boltDrawPromotionId: Int!, $type: String) {
    boltDrawPromotionImageList(boltDrawPromotionId: $boltDrawPromotionId, type: $type) {
      list {
        id
        type
        url
        boltDrawPromotionId
        priority
        createdAt
        updatedAt
      }
      total
    }
  }
`;
