const rules = {
  description: [
    {
      required: true,
      message: 'Please enter Description.',
    },
  ],
  startAt: [
    {
      required: true,
      message: 'Please select Date.',
    },
  ],
  endAt: [],
  minAmount: [
    {
      required: true,
      message: 'Please enter Amount.',
    },
  ],
  maxAmount: [
    {
      required: true,
      message: 'Please enter Amount.',
    },
  ],
  maxCountPerDay: [
    {
      required: true,
      message: 'Please enter Max Count.',
    },
  ],
  fee: [
    {
      required: true,
      message: 'Please enter Fee.',
    },
    {
      type: 'string',
      pattern: /^\d+$|^\d+(\.\d)?%$/,
      message: 'Fee must be valid format like 1%, 1.5%, 500',
    },
    {
      message: 'Fee must be equal or bigger than 0.',
      validator: (rule, value, callback) => {
        if (+value < 0) {
          return callback(true);
        }

        return callback();
      },
    },
    {
      message: 'Fee must be equal or smaller than 100%',
      validator: (rule, value, callback) => {
        const regex = /^((\d+|\d+\.\d+)%)$/;

        if (!regex.test(value)) {
          return callback();
        }

        const parsedValue = parseFloat(value);

        if (parsedValue <= 100) {
          return callback();
        }

        return callback(true);
      },
    },
  ],
};

export default rules;
