import React from 'react';
import PropTypes from 'prop-types';
import { Table, Divider, notification, Switch } from 'antd';
import apolloClient from 'helpers/apolloClient';
import gql from 'graphql-tag';
import SearchForm from 'schema/SearchForm';
import schema from 'schema/user';
import store from 'store';

const isInGroup = (groups, targetId) => groups.map(g => g.id).indexOf(targetId) !== -1;
class editGroupUserModalContent extends React.PureComponent {
  static propTypes = {
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  state = {
    tableFilter: {
      name: '',
      email: '',
      groupId: this.props.formData.id,
    },
    tablePagedList: [],
    tablePagination: {
      current: 1,
      pageSize: 10,
      showTotal: total => `Total ${total} item(s)`,
    },
    tableSorter: {
      field: '',
      order: '',
    },
    tableLoading: false,
  };

  componentDidMount() {
    this.refresh();
  }

  columns = [
    {
      title: 'Fullname',
      dataIndex: 'fullname',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: true,
    },
    {
      title: 'In/Out',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        const active = isInGroup(record.groups, this.state.tableFilter.groupId);
        const onClick = active ? () => this.modifyRoleUser(record, 0) : () => this.modifyRoleUser(record, 1);

        return <Switch onClick={onClick} checked={active} />;
      },
    },
  ];

  handleSearch = filter => {
    filter.groupId = this.props.formData.id;
    const pager = { ...this.state.tablePagination };
    pager.current = 1;
    this.setState({
      tableFilter: filter,
      tablePagination: pager,
    });
    const query = {
      skip: 0,
      pageSize: this.state.tablePagination.pageSize,
      sortBy: this.state.tableSorter.field,
      descending: this.state.tableSorter.order === 'descend',
      filter,
    };
    this.fetch(query);
  };

  handleReset = () => {
    this.setState({
      tableFilter: {
        groupId: this.props.formData.id,
      },
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { current: newPage, pageSize } = pagination;

    const pager = { ...this.state.tablePagination };
    pager.current = newPage;
    pager.pageSize = pageSize;

    this.setState(
      {
        tablePagination: pager,
        tableSorter: {
          field: sorter.field,
          order: sorter.order,
        },
      },
      this.fetch
    );
  };

  modifyRoleUser = async (record, operation) => {
    const groupId = this.props.formData.id;
    const adminId = record.id;

    const UPDATE_GROUP_USER = gql`
      mutation updateGroupAdmin {
        updateGroupAdmin(groupId: "${groupId}", adminId: "${adminId}", operation: ${+operation}) {
          id, name, code, description
        }
      }`;

    const result = await apolloClient.mutate({
      mutation: UPDATE_GROUP_USER,
    });

    // After update, mutation will return new group if operation was 'add'.
    // Save into store so that other components relies on Group will update properly!
    const adminInfo = store.get('admin');
    const { groups } = adminInfo;

    // Add new Group
    if (operation === 1) {
      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Added',
      });

      // Add group to store
      const newGroup = result.data.updateGroupAdmin;
      groups.push(newGroup);
    } else {
      // Remove specified Group
      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Removed',
      });

      // Remove group from store
      for (let i = 0; i < groups.length; i += 1) {
        if (groups[i].id === groupId) {
          groups.splice(i, 1);
          break;
        }
      }
    }

    // Update store. Note that it must be updated if it is admin itself.
    if (adminInfo.id === adminId) {
      store.set('admin', {
        ...adminInfo,
        groups,
      });
    }

    this.refresh();
  };

  refresh = () => {
    const query = {
      skip: this.state.tablePagination.current,
      pageSize: this.state.tablePagination.pageSize,
      sortBy: this.state.tableSorter.field,
      descending: this.state.tableSorter.order === 'descend',
      filter: this.state.tableFilter,
    };
    this.fetch(query);
  };

  fetch = async query => {
    this.setState({ tableLoading: true });

    const GET_ADMINS = gql`
      query getAdmins($filter: String, $skip: Int, $pageSize: Int) {
        getAdmins(filter: $filter, skip: $skip, pageSize: $pageSize) {
          total
          list {
            id
            phone
            email
            fullname
            groups {
              id
            }
          }
        }
      }
    `;

    const { current, pageSize } = this.state.tablePagination;

    const { data } = await apolloClient.query({
      query: GET_ADMINS,
      variables: {
        skip: (current - 1) * 10,
        pageSize,
        filter: JSON.stringify(query.filter),
      },
    });
    const { getAdmins } = data;

    const pagination = { ...this.state.tablePagination };
    pagination.total = getAdmins.total;
    this.setState({
      tableLoading: false,
      tablePagedList: getAdmins.list,
      tablePagination: pagination,
    });
  };

  render() {
    return (
      <div>
        <SearchForm
          schema={schema.searchSchema}
          uiSchema={schema.searchUiSchema}
          handleSubmit={this.handleSearch}
          handleReset={this.handleReset}
        />
        <Divider />
        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.tablePagedList}
          pagination={this.state.tablePagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
          scroll={{ x: 768 }}
          bordered
        />
      </div>
    );
  }
}

export default editGroupUserModalContent;
