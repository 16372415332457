import Menu from './Menu';
import Action from './Action';
import Group from './Group';
import Admin from './Admins';

export default {
  Menu,
  Action,
  Group,
  Admin,
};
