export default {
  loginId: {
    'ui:title': 'ID',
    'ui:description': 'ID는 12자리 이상 20자리 이하로 설정하세요 (허용문자 : 영문소문자, 숫자)',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'ID 입력',
      maxLength: 20,
    },
    'ui:rules': [
      { required: true, message: 'Please enter the ID.' },
      { min: 12, message: 'ID는 12자리 이상으로 설정하세요' },
      { max: 20, message: 'ID는 20자리 이하로 설정하세요' },
    ],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  password: {
    'ui:title': '비밀번호',
    'ui:description': '비밀번호는 12자리 이상 20자리 이하로 설정하세요 (영문 대/소문자(구분됨), 특수문자)',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '비밀번호 입력',
      maxLength: 20,
    },
    'ui:rules': [
      { required: true, message: 'Please enter the password.' },
      { min: 12, message: '비밀번호는 12자리 이상으로 설정하세요' },
      { max: 20, message: '비밀번호는 20자리 이하로 설정하세요' },
    ],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  merchantId: {
    'ui:title': '가맹점',
    'ui:description': '',
    'ui:widget': 'select',
    'ui:options': {
      placeholder: '가맹점 선택',
      fieldNames: { label: 'name', value: 'id' },
      options: [],
    },
    'ui:rules': [{ required: true, message: 'Please select merchant' }],
    'ui:remoteConfig': {
      apiKey: 'MERCHANT_LIST',
      hand: data => data.map(d => ({ id: d.id, code: d.displayName })),
    },
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  permittedMerchantUserIds: {
    'ui:title': '가맹점 사용자 ID',
    'ui:description': '공백, 개행 또는 쉼표(,)로 ID 구분',
    'ui:widget': 'input.textarea',
    'ui:options': {
      placeholder: 'ID 입력',
      style: { width: 400, height: 200 },
      autosize: { minRows: 2, maxRows: 6 },
    },
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
};
