import React, { PureComponent } from 'react';
import { bool, func, objectOf, any } from 'prop-types';
import { Modal, notification } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import CommonForm from 'schema/CommonForm';
import schema from 'schema/adminGroup';
import { saveAdminMutation } from '../AdminMutations';

@autobind
class EditAdminModal extends PureComponent {
  static propTypes = {
    data: objectOf(any),
    visible: bool.isRequired,
    onSubmit: func.isRequired,
    onClose: func.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  handleSubmit() {
    this.editAdminForm.handleSubmit();
  }

  async saveAdmin(formData) {
    const { data } = this.props;
    const { uid, password, fullname = '', phone = '', email = '', status, merchants } = formData;

    try {
      await apolloClient.mutate({
        mutation: saveAdminMutation,
        variables: {
          id: data ? data.id : '',
          uid,
          password,
          fullname,
          phone,
          email,
          status,
          merchantIds: merchants,
        },
      });

      this.props.onClose();
      this.props.onSubmit();

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: data ? 'Saved' : 'Created',
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Get formatted data for CommonForm
   * @return {Object} Returns null if there's no data.
   */
  getFormattedData() {
    const { data } = this.props;
    let formattedData = null;

    if (data) {
      const { uid, password, fullname, phone, email, status, merchants } = data;

      formattedData = {
        uid,
        password,
        fullname,
        phone,
        email,
        status,
        merchants: merchants.map(merchant => merchant.id),
      };
    }

    return formattedData;
  }

  render() {
    const { visible, onClose } = this.props;
    const data = this.getFormattedData();

    return (
      <Modal
        visible={visible}
        cancelText="Close"
        okText="Save"
        title={data ? 'Edit Admin' : 'Add Admin'}
        onCancel={onClose}
        onOk={this.handleSubmit}
        destroyOnClose
      >
        <CommonForm
          ref={instance => {
            this.editAdminForm = instance;
          }}
          schema={schema.editSchema}
          uiSchema={schema.editUiSchema}
          formData={data || {}}
          handleSubmit={this.saveAdmin}
        />
      </Modal>
    );
  }
}

export default EditAdminModal;
