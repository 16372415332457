import React from 'react';
import { Tooltip } from 'antd';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

/**
 * Get type text for Badge Component
 * @param {string} type - Payment type
 * @return string
 */
function getType(type) {
  return {
    charge_in: '충전',
    refund_in: '환불',
    payment_out: '결제',
    withdrawal_out: '차이머니 환급',
    transfer_in: '승계 받음',
    transfer_out: '승계 보냄',
  }[type];
}

export const columns = [
  {
    title: 'Card ID',
    dataIndex: 'metroCardId',
    render: function renderId(val) {
      return <span>{val}</span>;
    },
    width: '5%',
  },
  {
    title: '금액',
    dataIndex: 'amount',
    align: 'right',
    render: function renderAmount(val) {
      const symbol = val >= 0 ? '+' : '-';
      const color = symbol === '+' ? '#0eb53d' : '#e02323';
      return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
    },
    width: '10%',
  },
  {
    title: '사용후 잔액',
    dataIndex: 'balance',
    align: 'right',
    render: function renderBalance(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '10%',
  },
  {
    title: '사용분류',
    dataIndex: 'type',
    align: 'right',
    render: function renderType(val) {
      return <span>{getType(val)}</span>;
    },
    width: '10%',
  },
  {
    title: '사용내용',
    dataIndex: 'data',
    align: 'right',
    render: function renderDescription(val) {
      return (
        <Tooltip
          title={
            val.busNumber ? `${val.busNumber} / ${val.busStopName} / 환승: ${val.isTransfer} / 인원: ${val.person}` : ''
          }
        >
          <span>{val.description}</span>
        </Tooltip>
      );
    },
    width: '20%',
  },
  {
    title: '사용일자',
    dataIndex: 'usedAt',
    align: 'right',
    render: function renderUsedAt(val) {
      return <span>{val ? formatDate(val, 'YY-MM-DD HH:mm') : '-'}</span>;
    },
    width: '15%',
  },
  {
    title: '접수일자',
    dataIndex: 'createdAt',
    align: 'right',
    render: function renderCreatedAt(val) {
      return <span>{val ? formatDate(val, 'YY-MM-DD HH:mm') : '-'}</span>;
    },
    width: '15%',
  },
];

export default {
  columns,
};
