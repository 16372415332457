import gql from 'graphql-tag';

export const cardProductAddMutation = gql`
  mutation cardProductAdd(
    $issuer: String!
    $resourceType: String!
    $title: String!
    $subTitle: String!
    $titleDescription: String
    $emphasizeText: String
    $emphasizeColor1: String
    $emphasizeColor2: String
    $description: String!
    $descriptionImageUrls: [String]
    $status: String
    $maxMonthlyBenefitAmount: Int
    $issueBoltPrice: Int
    $reissueBoltPrice: Int
    $annualFee: Int!
    $isDomestic: Boolean!
    $logoImageUrl: String
    $benefitImageUrls: [String]
    $productCode: String!
    $brandCode: String!
    $type: String!
    $issueTarget: String!
    $shareableUrl: String
    $externalIssueUrl: String
    $sortedIndex: Int!
    $maxEarlyBirdCount: Int
  ) {
    cardProductAdd(
      issuer: $issuer
      resourceType: $resourceType
      title: $title
      subTitle: $subTitle
      titleDescription: $titleDescription
      emphasizeText: $emphasizeText
      emphasizeColor1: $emphasizeColor1
      emphasizeColor2: $emphasizeColor2
      description: $description
      descriptionImageUrls: $descriptionImageUrls
      status: $status
      maxMonthlyBenefitAmount: $maxMonthlyBenefitAmount
      issueBoltPrice: $issueBoltPrice
      reissueBoltPrice: $reissueBoltPrice
      annualFee: $annualFee
      isDomestic: $isDomestic
      logoImageUrl: $logoImageUrl
      benefitImageUrls: $benefitImageUrls
      productCode: $productCode
      brandCode: $brandCode
      type: $type
      issueTarget: $issueTarget
      shareableUrl: $shareableUrl
      externalIssueUrl: $externalIssueUrl
      sortedIndex: $sortedIndex
      maxEarlyBirdCount: $maxEarlyBirdCount
    )
  }
`;

export const cardProductUpdateMutation = gql`
  mutation cardProductUpdate(
    $id: Int!
    $issuer: String!
    $resourceType: String!
    $title: String!
    $subTitle: String!
    $titleDescription: String
    $emphasizeText: String
    $emphasizeColor1: String
    $emphasizeColor2: String
    $description: String!
    $descriptionImageUrls: [String]
    $status: String
    $maxMonthlyBenefitAmount: Int
    $issueBoltPrice: Int
    $reissueBoltPrice: Int
    $annualFee: Int!
    $isDomestic: Boolean!
    $logoImageUrl: String
    $benefitImageUrls: [String]
    $productCode: String
    $brandCode: String
    $type: String
    $issueTarget: String
    $shareableUrl: String
    $externalIssueUrl: String
    $sortedIndex: Int
    $maxEarlyBirdCount: Int
  ) {
    cardProductUpdate(
      id: $id
      issuer: $issuer
      resourceType: $resourceType
      title: $title
      subTitle: $subTitle
      titleDescription: $titleDescription
      emphasizeText: $emphasizeText
      emphasizeColor1: $emphasizeColor1
      emphasizeColor2: $emphasizeColor2
      description: $description
      descriptionImageUrls: $descriptionImageUrls
      status: $status
      maxMonthlyBenefitAmount: $maxMonthlyBenefitAmount
      issueBoltPrice: $issueBoltPrice
      reissueBoltPrice: $reissueBoltPrice
      annualFee: $annualFee
      isDomestic: $isDomestic
      logoImageUrl: $logoImageUrl
      benefitImageUrls: $benefitImageUrls
      productCode: $productCode
      brandCode: $brandCode
      type: $type
      issueTarget: $issueTarget
      shareableUrl: $shareableUrl
      externalIssueUrl: $externalIssueUrl
      sortedIndex: $sortedIndex
      maxEarlyBirdCount: $maxEarlyBirdCount
    )
  }
`;

export default {
  cardProductAddMutation,
  cardProductUpdateMutation,
};
