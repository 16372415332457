import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert } from 'antd';

import cancelableQuery from 'helpers/apolloClient/cancelableQuery';

import { brandQuery } from 'redux/modules/brand/queries';

import { formItemLayoutWithoutLabel } from './layout';
import BoostNumberForm from './BoostNumberForm';

const mapStateToProps = state => {
  const { data, updating } = state.brand;

  return {
    data,
    updating,
  };
};

@Form.create()
@connect(mapStateToProps)
@autobind
class BoostBrandInputForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    initial: PropTypes.number,
  };

  static defaultProps = {
    id: null,
    initial: null,
  };

  state = {
    // Brand XHR Status
    data: null,
    error: null,
    loading: false,
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.initial !== this.props.initial) {
      this.getData(nextProps.initial);
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  async getData(id) {
    if (!id) {
      return;
    }

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: brandQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.brand;

      if (data) {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Brand: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  handleChange(nextId) {
    const { data } = this.state;
    if (!data) {
      return this.getData(nextId);
    }
    if (nextId && nextId !== data.id) {
      return this.getData(nextId);
    }
    return true;
  }

  renderError() {
    return (
      <Form.Item {...formItemLayoutWithoutLabel}>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </Form.Item>
    );
  }

  renderBrand() {
    const { data } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Form.Item {...formItemLayoutWithoutLabel}>
        <div style={{ backgroundColor: data.color, height: 250, width: 200, padding: 20 }}>
          <div
            style={{
              backgroundImage: `url(${data.logoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              height: 100,
            }}
          />
          <div
            style={{
              backgroundImage: `url(${data.typoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              marginTop: 15,
              height: 100,
            }}
          />
        </div>
      </Form.Item>
    );
  }

  render() {
    const { form, initial, name, label } = this.props;
    const { error, loading } = this.state;

    return (
      <>
        <BoostNumberForm
          label={label}
          form={form}
          name={name}
          initial={initial}
          onChange={e => this.handleChange(e)}
          require
        />
        {!loading && error ? this.renderError() : this.renderBrand()}
      </>
    );
  }
}

export default BoostBrandInputForm;
