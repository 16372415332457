import gql from 'graphql-tag';

export const policyPGFeeAddMutation = gql`
  mutation policyPGFeeAdd(
    $description: String!
    $startAt: Date!
    $endAt: Date
    $name: String!
    $type: String!
    $fee: String!
  ) {
    policyPGFeeAdd(description: $description, startAt: $startAt, endAt: $endAt, name: $name, type: $type, fee: $fee)
  }
`;

export const policyPGFeeUpdateMutation = gql`
  mutation policyPGFeeUpdate(
    $id: ID!
    $description: String!
    $startAt: Date!
    $endAt: Date
    $name: String!
    $type: String!
    $fee: String!
  ) {
    policyPGFeeUpdate(
      id: $id
      description: $description
      startAt: $startAt
      endAt: $endAt
      name: $name
      type: $type
      fee: $fee
    )
  }
`;

export const policyPaymentLimitAddMutation = gql`
  mutation policyPaymentLimitAdd(
    $description: String!
    $constraint: String
    $minAge: Int
    $maxAge: Int
    $bank: String
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $minUnit: Float!
    $maxCount: Int!
    $maxAmountOnce: Float!
    $maxAmountDay: Float!
    $maxAmountMonth: Float!
  ) {
    policyPaymentLimitAdd(
      description: $description
      constraint: $constraint
      minAge: $minAge
      maxAge: $maxAge
      bank: $bank
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      minUnit: $minUnit
      maxCount: $maxCount
      maxAmountOnce: $maxAmountOnce
      maxAmountDay: $maxAmountDay
      maxAmountMonth: $maxAmountMonth
    )
  }
`;

export const policyPaymentLimitUpdateMutation = gql`
  mutation policyPaymentLimitUpdate(
    $id: ID!
    $description: String!
    $constraint: String
    $minAge: Int
    $maxAge: Int
    $bank: String
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $minUnit: Float!
    $maxCount: Int!
    $maxAmountOnce: Float!
    $maxAmountDay: Float!
    $maxAmountMonth: Float!
  ) {
    policyPaymentLimitUpdate(
      id: $id
      description: $description
      constraint: $constraint
      minAge: $minAge
      maxAge: $maxAge
      bank: $bank
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      minUnit: $minUnit
      maxCount: $maxCount
      maxAmountOnce: $maxAmountOnce
      maxAmountDay: $maxAmountDay
      maxAmountMonth: $maxAmountMonth
    )
  }
`;

export const policyChargeLimitAddMutation = gql`
  mutation policyChargeLimitAdd(
    $description: String!
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $minUnit: Float!
    $maxCount: Int!
    $maxAmountOnce: Float!
    $maxAmountDay: Float!
    $maxAmountMonth: Float!
    $maxBalance: Float!
  ) {
    policyChargeLimitAdd(
      description: $description
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      minUnit: $minUnit
      maxCount: $maxCount
      maxAmountOnce: $maxAmountOnce
      maxAmountDay: $maxAmountDay
      maxAmountMonth: $maxAmountMonth
      maxBalance: $maxBalance
    )
  }
`;

export const policyChargeLimitUpdateMutation = gql`
  mutation policyChargeLimitUpdate(
    $id: ID!
    $description: String!
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $minUnit: Float!
    $maxCount: Int!
    $maxAmountOnce: Float!
    $maxAmountDay: Float!
    $maxAmountMonth: Float!
    $maxBalance: Float!
  ) {
    policyChargeLimitUpdate(
      id: $id
      description: $description
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      minUnit: $minUnit
      maxCount: $maxCount
      maxAmountOnce: $maxAmountOnce
      maxAmountDay: $maxAmountDay
      maxAmountMonth: $maxAmountMonth
      maxBalance: $maxBalance
    )
  }
`;

export const policyWithdrawalLimitAddMutation = gql`
  mutation policyWithdrawalLimitAdd(
    $description: String!
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $maxAmount: Float!
    $maxCountPerDay: Int!
    $fee: String!
  ) {
    policyWithdrawalLimitAdd(
      description: $description
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      maxAmount: $maxAmount
      maxCountPerDay: $maxCountPerDay
      fee: $fee
    )
  }
`;

export const policyWithdrawalLimitUpdateMutation = gql`
  mutation policyWithdrawalLimitUpdate(
    $id: ID!
    $description: String!
    $startAt: Date!
    $endAt: Date
    $minAmount: Float!
    $maxAmount: Float!
    $maxCountPerDay: Int!
    $fee: String!
  ) {
    policyWithdrawalLimitUpdate(
      id: $id
      description: $description
      startAt: $startAt
      endAt: $endAt
      minAmount: $minAmount
      maxAmount: $maxAmount
      maxCountPerDay: $maxCountPerDay
      fee: $fee
    )
  }
`;

export const policyRemoveMutation = gql`
  mutation policyRemove($id: ID!) {
    policyRemove(id: $id)
  }
`;

export default {
  policyPGFeeAddMutation,
  policyPGFeeUpdateMutation,
  policyPaymentLimitAddMutation,
  policyPaymentLimitUpdateMutation,
  policyChargeLimitAddMutation,
  policyChargeLimitUpdateMutation,
  policyWithdrawalLimitAddMutation,
  policyWithdrawalLimitUpdateMutation,
  policyRemoveMutation,
};
