import React from 'react';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const env = process.env.NODE_CONFIG_ENV || 'staging';
const ALICE_ADMIN_DOMAIN = env === 'prod' ? 'alice.chai.finance' : `alice-${env}.chai.finance`;

export const columns = [
  {
    title: '강화 시각',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '10%',
  },
  {
    title: '시도한 강화',
    dataIndex: 'boostUpPolicyId',
    align: 'right',
    width: '8%',
    render: function renderPolicyId(val) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://${ALICE_ADMIN_DOMAIN}/boost/up/${val}`}>
          {val}
        </a>
      );
    },
  },
  {
    title: '강화 시점',
    dataIndex: 'boostUpMoment',
    align: 'center',
    width: '12%',
    render: function renderBoostUpMoment(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: '소진된 번개',
    dataIndex: 'boltPrice',
    width: '8%',
    align: 'right',
    render: function renderBoltPrice(val) {
      return <span style={{ color: 'red' }}>{val}</span>;
    },
  },
  {
    title: '강화 결과', // miss, win-{level}, jackpot
    dataIndex: 'resultType',
    align: 'right',
    width: '10%',
    render: function renderResultType(val, data) {
      return <span>{val === 'win' ? `${val}-${data.level}` : val}</span>;
    },
  },
  {
    title: '강화 시도 캠페인',
    dataIndex: 'campaignId',
    width: '10%',
    align: 'right',
    render: function renderCampaignId(val) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://${ALICE_ADMIN_DOMAIN}/boost/1/campaign/${val}`}>
          {val}
        </a>
      );
    },
  },
  {
    title: '강화 후 할인율',
    dataIndex: 'totalDiscountRate',
    width: '11%',
    align: 'right',
    render: function renderSchemeAfterBoostUp(val) {
      return <span>{`${commify(val || 0)}%`}</span>;
    },
  },
  {
    title: '강화 후 캐시백 금액',
    dataIndex: 'totalCashbackAmount',
    width: '11%',
    align: 'right',
    render: function renderSchemeAfterBoostUp(val) {
      return <span>{commify(val || 0)}</span>;
    },
  },
];

export default {
  columns,
};
