import gql from 'graphql-tag';

export const bannerAddMutation = gql`
  mutation bannerAdd(
    $title: String!
    $subTitle: String
    $landingUrl: String!
    $sortedIndex: Int!
    $isActive: Boolean!
    $startAt: Date
    $endAt: Date
    $androidImageFile: FileUpload!
    $iosImageFile: FileUpload!
    $groupId: ID!
  ) {
    bannerAdd(
      title: $title
      subTitle: $subTitle
      landingUrl: $landingUrl
      sortedIndex: $sortedIndex
      isActive: $isActive
      startAt: $startAt
      endAt: $endAt
      androidImageFile: $androidImageFile
      iosImageFile: $iosImageFile
      groupId: $groupId
    )
  }
`;

export const bannerUpdateMutation = gql`
  mutation bannerUpdate(
    $id: ID!
    $title: String!
    $subTitle: String
    $landingUrl: String!
    $sortedIndex: Int!
    $isActive: Boolean!
    $startAt: Date
    $endAt: Date
    $androidImageFile: FileUpload
    $iosImageFile: FileUpload
  ) {
    bannerUpdate(
      id: $id
      title: $title
      subTitle: $subTitle
      landingUrl: $landingUrl
      sortedIndex: $sortedIndex
      isActive: $isActive
      startAt: $startAt
      endAt: $endAt
      androidImageFile: $androidImageFile
      iosImageFile: $iosImageFile
    )
  }
`;

export const bannerSortedIndexUpdate = gql`
  mutation bannerSortedIndexUpdate($sortingList: [BannerSortingList]!) {
    bannerSortedIndexUpdate(sortingList: $sortingList)
  }
`;

export const bannerRemoveMutation = gql`
  mutation bannerRemove($id: ID, $idList: [ID]) {
    bannerRemove(id: $id, idList: $idList)
  }
`;

export default {
  bannerAddMutation,
  bannerUpdateMutation,
  bannerRemoveMutation,
};
