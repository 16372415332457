import gql from 'graphql-tag';

export const settlementDetailQuery = gql`
  query settlementList($filter: String) {
    settlementList(filter: $filter) {
      id
      status
      settlementDate
      referenceDate
      plusAmount
      minusAmount
      feeAmount
      feeTaxAmount
      transactionCount
      refundCount
    }
  }
`;

export default {
  settlementDetailQuery,
};
