import gql from 'graphql-tag';

export const noticeAddMutation = gql`
  mutation noticeAdd(
    $title: String!
    $content: String!
    $status: String!
    $fixed: Boolean!
    $uploadedFiles: [String]
    $removedFiles: [String]
  ) {
    noticeAdd(
      title: $title
      content: $content
      status: $status
      fixed: $fixed
      uploadedFiles: $uploadedFiles
      removedFiles: $removedFiles
    )
  }
`;

export const noticeUpdateMutation = gql`
  mutation noticeUpdate(
    $id: ID!
    $title: String!
    $content: String!
    $status: String!
    $fixed: Boolean!
    $uploadedFiles: [String]
    $removedFiles: [String]
  ) {
    noticeUpdate(
      id: $id
      title: $title
      content: $content
      status: $status
      fixed: $fixed
      uploadedFiles: $uploadedFiles
      removedFiles: $removedFiles
    )
  }
`;

export const noticeRemoveMutation = gql`
  mutation noticeRemove($id: ID!) {
    noticeRemove(id: $id)
  }
`;
