import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider, Button, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';

import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';
import DataViewer from 'components/DataViewer';

import { ellipsis } from 'utils/text';

import { TabData } from 'containers/Layout';
import {
  setPageSize,
  setCurrentPage,
  getPromotions,
  toggleCopyItem,
  clearCopyItems,
} from 'redux/modules/promotion/actions';
import { addTab, focusTab } from 'redux/modules/tabs';

import PromotionFilterForm from './Forms/PromotionFilterForm';
import PromotionCopyModal from './PromotionCopyModal';
import { getColumns } from './PromotionMetadata';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter, copyItems } = state.promotion;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    copyItems,
  };
};

const mapDispatchToProps = {
  setPageSize,
  setCurrentPage,
  getPromotions,
  push,
  addTab,
  focusTab,
  toggleCopyItem,
  clearCopyItems,
};

@autobind
@connect(mapStateToProps, mapDispatchToProps)
class PromotionListContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getPromotions: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    copyItems: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleCopyItem: PropTypes.func.isRequired,
    clearCopyItems: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  state = {
    copyModalVisible: false,
  };

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getPromotions(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getPromotions(newPage, pageSize, filter);
  }

  handleCellClick = (record, extra) => {
    const { id, title } = record;
    const { index } = extra;

    if (index === 1) {
      this.openDataTab(id, { ...extra, title });
    }
  };

  /**
   * Opening tab for display Promotion
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openDataTab(id, extra = {}) {
    const title = extra.title ? ellipsis(extra.title, 8) : ellipsis(id, 5);
    const tabData = new TabData({
      key: `${id}`,
      title,
      closable: true,
      data: {
        id,
      },
      componentType: 'PromotionForm',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('promotion', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('promotion', id.toString());
      this.props.push(`/promotion/policy/${id}`);
    }
  }

  handleCopyCheck = promotionId => {
    this.props.toggleCopyItem(promotionId);
  };

  handleCopyModal = () => {
    this.setState({
      copyModalVisible: true,
    });
  };

  closeCopyModal = confirmed => {
    if (confirmed) {
      this.changePage(1);
    }
    this.setState({
      copyModalVisible: false,
    });
  };

  createNewPromotion() {
    const newTab = new TabData({
      key: 'add',
      title: 'New Promotion',
      closable: true,
      data: {
        id: 'add',
      },
      componentType: 'PromotionForm',
    });

    this.props.addTab('promotion', newTab);
    this.props.focusTab('promotion', newTab.key);
    this.props.push('/promotion/policy/add');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  render = () => {
    const { totalCount, pageSize, currentPage, data, error, loading, copyItems } = this.props;
    const { copyModalVisible } = this.state;

    return (
      <>
        <PromotionFilterForm />
        <Divider />

        <Row type="flex" align="middle" gutter={12}>
          <Col>
            <Button icon="plus-circle" type="primary" onClick={this.createNewPromotion.bind(this)}>
              New
            </Button>
          </Col>
          <Divider type="vertical" />
          <Col>
            <Button icon="copy" onClick={this.handleCopyModal.bind(this)} disabled={!copyItems.length}>
              {copyItems.length ? `Copy ${copyItems.length} item(s)` : 'Copy'}
            </Button>
          </Col>
          <Col>
            <Button icon="delete" onClick={this.props.clearCopyItems} disabled={!copyItems.length}>
              Clear
            </Button>
          </Col>
        </Row>

        <DataViewer
          rowKey="id"
          columns={getColumns(this.handleCopyCheck, copyItems)}
          data={data}
          error={error}
          loading={loading}
          onCellClick={this.handleCellClick.bind(this)}
        />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage.bind(this)}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
        <PromotionCopyModal visible={copyModalVisible} onClose={this.closeCopyModal} />
      </>
    );
  };
}

export default PromotionListContainer;
