import gql from 'graphql-tag';

export const saveActionMutation = gql`
  mutation saveAction($id: ID!, $name: String!, $code: String!, $menuId: ID, $description: String!, $groupIds: [ID!]!) {
    saveAction(id: $id, name: $name, code: $code, menuId: $menuId, groupIds: $groupIds, description: $description) {
      name
      code
    }
  }
`;

export const removeActionsMutation = gql`
  mutation removeActions($ids: [ID]!) {
    removeActions(ids: $ids)
  }
`;

export default {
  saveActionMutation,
  removeActionsMutation,
};
