import addSchema from './addSchema';
import addUiSchema from './addUiSchema';
import editSchema from './editSchema';
import editUiSchema from './editUiSchema';
import editPointSchema from './editPointSchema';
import editPointUiSchema from './editPointUiSchema';

export default {
  addSchema,
  addUiSchema,
  editSchema,
  editUiSchema,
  editPointSchema,
  editPointUiSchema,
};
