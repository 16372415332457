export default {
  loginId: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Login ID...',
    },
    'ui:title': 'Login ID',
    'ui:description': '',
  },
  fullname: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Username...',
    },
    'ui:title': 'Username',
    'ui:description': '',
  },
  phone: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '010-0000-0000',
    },
    'ui:title': 'Phone',
    'ui:description': '',
  },
  merchantName: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Merchant Name...',
    },
    'ui:title': 'Merchant Name',
    'ui:description': '',
  },
};
