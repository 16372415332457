import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import { confirm, cancel } from 'redux/modules/popup';

const styles = require('../Popup.scss');

const defaultPayload = {
  message: '계속 진행하시겠습니까?',
  confirmText: '확인',
  cancelText: '취소',
};

@connect(
  state => ({
    isShowing: state.popup.isShowing && state.popup.name === 'confirmation',
    payload: state.popup.payload || defaultPayload,
  }),
  {
    confirm,
    cancel,
  }
)
class ConfirmationPopup extends Component {
  static propTypes = {
    isShowing: PropTypes.bool.isRequired,
    payload: PropTypes.shape({
      message: PropTypes.string,
      confirmText: PropTypes.string,
      cancelText: PropTypes.string,
    }).isRequired,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return this.props.isShowing !== nextProps.isShowing || this.props.payload !== nextProps.payload;
  }

  @autobind
  handleCancel(event) {
    event.stopPropagation();
    this.props.cancel();
  }

  @autobind
  handleConfirm(event) {
    event.stopPropagation();
    this.props.confirm();
  }

  render() {
    if (!this.props.isShowing || !this.props.payload) return null;

    return (
      <div
        className={cx({
          [styles.popup_container_on]: this.props.isShowing,
          [styles.popup_container_off]: !this.props.isShowing,
        })}
        onClick={this.closeDeleteMenu}
      >
        <div className={styles.popup} onClick={this.handleClickPopup}>
          <div className={styles.inner}>
            <div className={styles.message}>{this.props.payload.message}</div>
            <div className={styles.buttons}>
              <button className={cx(styles.btn, styles.btn_cancel)} onClick={this.handleCancel}>
                {this.props.payload.cancelText}
              </button>
              <button type="submit" className={cx(styles.btn, styles.color6)} onClick={this.handleConfirm}>
                {this.props.payload.confirmText}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationPopup;
