import gql from 'graphql-tag';

export const merchantAddMutation = gql`
  mutation merchantAdd(
    $name: String!
    $groupName: String
    $displayName: String!
    $shortName: String!
    $globalName: String
    $status: String!
    $contractStatus: String
    $countryCode: String!
    $vertical: String!
    $website: String!
    $managerName: String!
    $managerEmail: String!
    $managerPhone: String!
    $businessNumber: String
    $address: String
    $representativeName: String
    $customerServicePhone: String
    $bankCode: String
    $bankName: String
    $bankAccountNum: String
    $bankHolder: String
    $logoUrl: String
    $pgMerchantCode: String
    $linkMethod: String
    $restrictedPid: JSON
    $isPointRestricted: Boolean
    $paymentLimits: JSON
    $maxMonthlyCashbackAmount: Int
    $reserveAmount: Int
    $businessCategory: String!
    $businessType: String!
    $settlementEmail: String!
    $settlementSubEmail: String
    $funnel: String
  ) {
    merchantAdd(
      name: $name
      groupName: $groupName
      displayName: $displayName
      shortName: $shortName
      globalName: $globalName
      status: $status
      contractStatus: $contractStatus
      countryCode: $countryCode
      vertical: $vertical
      website: $website
      managerName: $managerName
      managerEmail: $managerEmail
      managerPhone: $managerPhone
      businessNumber: $businessNumber
      address: $address
      representativeName: $representativeName
      customerServicePhone: $customerServicePhone
      bankCode: $bankCode
      bankName: $bankName
      bankAccountNum: $bankAccountNum
      bankHolder: $bankHolder
      logoUrl: $logoUrl
      pgMerchantCode: $pgMerchantCode
      linkMethod: $linkMethod
      restrictedPid: $restrictedPid
      isPointRestricted: $isPointRestricted
      paymentLimits: $paymentLimits
      maxMonthlyCashbackAmount: $maxMonthlyCashbackAmount
      reserveAmount: $reserveAmount
      businessCategory: $businessCategory
      businessType: $businessType
      settlementEmail: $settlementEmail
      settlementSubEmail: $settlementSubEmail
      funnel: $funnel
    ) {
      merchantId
      publicKey
      privateKey
    }
  }
`;

export const merchantUpdateMutation = gql`
  mutation merchantUpdate(
    $id: ID!
    $name: String
    $groupName: String
    $displayName: String
    $shortName: String
    $globalName: String
    $status: String
    $contractStatus: String
    $countryCode: String
    $vertical: String
    $website: String
    $managerName: String
    $managerEmail: String
    $managerPhone: String
    $businessNumber: String
    $address: String
    $representativeName: String
    $customerServicePhone: String
    $bankCode: String
    $bankName: String
    $bankAccountNum: String
    $bankHolder: String
    $logoUrl: String
    $pgMerchantCode: String
    $linkMethod: String
    $restrictedPid: JSON
    $isPointRestricted: Boolean
    $paymentLimits: JSON
    $maxMonthlyCashbackAmount: Int
    $reserveAmount: Int
    $businessCategory: String!
    $businessType: String!
    $settlementEmail: String!
    $settlementSubEmail: String
    $funnel: String
  ) {
    merchantUpdate(
      id: $id
      name: $name
      groupName: $groupName
      displayName: $displayName
      shortName: $shortName
      globalName: $globalName
      status: $status
      contractStatus: $contractStatus
      countryCode: $countryCode
      vertical: $vertical
      website: $website
      managerName: $managerName
      managerEmail: $managerEmail
      managerPhone: $managerPhone
      businessNumber: $businessNumber
      address: $address
      representativeName: $representativeName
      customerServicePhone: $customerServicePhone
      bankCode: $bankCode
      bankName: $bankName
      bankAccountNum: $bankAccountNum
      bankHolder: $bankHolder
      logoUrl: $logoUrl
      pgMerchantCode: $pgMerchantCode
      linkMethod: $linkMethod
      restrictedPid: $restrictedPid
      isPointRestricted: $isPointRestricted
      paymentLimits: $paymentLimits
      maxMonthlyCashbackAmount: $maxMonthlyCashbackAmount
      reserveAmount: $reserveAmount
      businessCategory: $businessCategory
      businessType: $businessType
      settlementEmail: $settlementEmail
      settlementSubEmail: $settlementSubEmail
      funnel: $funnel
    )
  }
`;

export default {
  merchantAddMutation,
  merchantUpdateMutation,
};
