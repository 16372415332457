export default {
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Name...',
    },
    'ui:title': 'Group Name',
    'ui:description': '',
  },
  code: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Group Code...',
    },
    'ui:title': 'Group Code',
    'ui:description': '',
  },
};
