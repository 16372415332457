import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Alert, Divider } from 'antd';
import StandardTable from 'components/StandardTable';

const CMDKeyCode = {
  Firefox: 224, // FF
  Webkit: 91, // Safari, Chrome, Opera 2013+
};

@autobind
class DataViewer extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    data: PropTypes.arrayOf(PropTypes.any),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([PropTypes.instanceOf(Error), PropTypes.objectOf(PropTypes.any)]),
    selectRows: PropTypes.bool,
    rowKey: PropTypes.string,
    onCellClick: PropTypes.func,
    selectedRowHandler: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    error: null,
    selectRows: false,
    rowKey: 'id',
    onCellClick: () => {},
    selectedRowHandler: undefined,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
    };

    this.pressedCmd = false;
    this.pressedCtrl = false;

    // Handle Ctrl/Cmd pressing to implement Ctrl + Click or Cmd + Click
    // This function will dynamically attached or detached from document's onkeydown event.
    this.keyDownEventListener = e => {
      if (e.keyCode === CMDKeyCode.Firefox || e.keyCode === CMDKeyCode.Webkit) {
        this.pressedCmd = true;
      }
      if (e.ctrlKey) {
        this.pressedCtrl = true;
      }
    };

    this.keyUpEventListener = e => {
      if (e.keyCode === CMDKeyCode.Firefox || e.keyCode === CMDKeyCode.Webkit) {
        this.pressedCmd = false;
      }

      if (!e.ctrlKey) {
        this.pressedCtrl = false;
      }
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyDownEventListener);
    document.addEventListener('keyup', this.keyUpEventListener);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDownEventListener);
    document.removeEventListener('keyup', this.keyUpEventListener);
  }

  /**
   * Inject Cell related callback props into columns
   * This makes your code can seperate handling cell related jobs from own class, not column definition code.
   * @return {Object} connectedColumns
   */
  getConnectedColumns() {
    const { columns } = this.props;

    for (let i = 0; i < columns.length; i += 1) {
      const column = columns[i];
      column.onCell = record => ({
        onClick: () => {
          this.handleCellClick(i, record);
        },
      });
    }

    return columns;
  }

  /**
   * Handle cell click to open tab only in first cell.
   * @param {number} index
   * @param {Object} record
   */
  handleCellClick(index, record) {
    this.props.onCellClick(record, {
      pressedCmd: this.pressedCmd,
      pressedCtrl: this.pressedCtrl,
      index,
    });
  }

  /**
   * Handle row selection from checkbox
   */
  handleSelectRows(rows) {
    this.setState({
      selectedRows: rows,
    });
  }

  render() {
    const { data } = this.props;
    const { selectRows, selectedRowHandler, loading, error, rowKey } = this.props;
    const { selectedRows } = this.state;
    const errorMessage = error ? error.message : 'Unknown Error';

    return (
      <Fragment>
        {/* On Error while getting data via XHR, Display <Alert />  */}
        {error && (
          <Fragment>
            <Divider style={{ background: 'none', margin: '10px 0' }} />
            <Alert type="warning" closable message={`Failed to load page: ${errorMessage}.`} />
            <Divider style={{ background: 'none', margin: '10px 0' }} />
          </Fragment>
        )}

        <StandardTable
          rowKey={rowKey}
          loading={loading}
          selectRows={selectRows}
          selectedRows={selectedRows}
          data={{
            list: data,
            pagination: false,
          }}
          columns={this.getConnectedColumns()}
          onSelectRow={this.handleSelectRows}
          selectedRowHandler={selectedRowHandler}
        />

        <Divider style={{ background: 'none' }} />
      </Fragment>
    );
  }
}

export default DataViewer;
