import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Card, Button } from 'antd';
import { push } from 'connected-react-router';
import { addTab, focusTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import ContractDisplay from './ContractDisplay';
import NoContract from './NoContract';

@connect(null, {
  push,
  addTab,
  focusTab,
})
@autobind
class ActiveContract extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    merchantId: PropTypes.string.isRequired,
    contract: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    contract: null,
  };

  /**
   * Open new Tab has ContractContainer
   */
  openContractTab() {
    const { merchantId } = this.props;
    const newTab = new TabData({
      key: `${merchantId}/contract`,
      componentType: 'ContractContainer',
      data: {
        merchantId,
      },
      title: `Contracts (${merchantId.substr(0, 5)}...)`,
      closable: true,
    });

    this.props.addTab('merchant', newTab);
    this.props.focusTab('merchant', newTab.key);
    this.props.push(`/merchant/${merchantId}/contract`);
  }

  /**
   * Open new Tab has ContractContainer and create new contract
   */
  openContractTabAndCreateContract() {
    const { merchantId } = this.props;
    const newTab = new TabData({
      key: `${merchantId}/contract`,
      componentType: 'ContractContainer',
      data: {
        merchantId,
        createNewContract: true,
      },
      title: `Contracts (${merchantId.substr(0, 5)}...)`,
      closable: true,
    });

    this.props.addTab('merchant', newTab);
    this.props.focusTab('merchant', newTab.key);
    this.props.push(`/merchant/${merchantId}/contract`);
  }

  render() {
    const { merchantId, contract } = this.props;
    const styles = {
      createButton: { position: 'absolute', top: 15, right: 10 },
      showButton: { position: 'absolute', top: 15, right: 10 },
    };

    return (
      <Card bordered={false}>
        {!contract && (
          <Button
            style={styles.createButton}
            onClick={this.openContractTabAndCreateContract}
            type="primary"
            icon="file-add"
          >
            Create
          </Button>
        )}

        {contract && (
          <Button style={styles.showButton} onClick={this.openContractTab} icon="bars">
            Show All
          </Button>
        )}

        {/* If there's no contract, inform to user */}
        {!contract && <NoContract />}

        {contract && <ContractDisplay showTitle {...contract} merchantId={merchantId} hideEdit />}
      </Card>
    );
  }
}

export default ActiveContract;
