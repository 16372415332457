export default {
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '',
    },
    'ui:rules': [
      { required: true, message: 'username is required' },
      { max: 25, message: 'maximum is 25' },
    ],
    'ui:title': 'Username',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },

  trueName: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '',
    },
    'ui:rules': [
      { required: true, message: 'True name is required' },
      { max: 25, message: 'maximum is 25' },
    ],
    'ui:title': 'Fullname',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },

  email: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '',
    },
    'ui:rules': [
      { type: 'email', message: 'The input is not valid E-mail!' },
      { max: 25, message: 'maximum is 25' },
    ],
    'ui:title': 'Email',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },

  phone: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '',
    },
    'ui:rules': [{ max: 25, message: 'maximum is 25' }],
    'ui:title': 'Phone',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
};
