import { Button, Divider, Form, message, Pagination, Popconfirm, Row } from 'antd';
import autobind from 'autobind-decorator';
import DataViewer from 'components/DataViewer';
import PageSizeDropdown from 'components/PageSizeDropdown';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addCopyItem,
  clearCopyItem,
  copyBoosts,
  getBoostPromotionList,
  removeCopyItem,
  setCurrentPage,
  setPageSize,
  updateBoostPromotion,
} from 'redux/modules/monohaBoostPromotion/actions';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import MonohaBoostPromotionFilterForm from './MonohaBoostPromotionFilterForm';
import { getColumns } from './MonohaBoostPromotionMetadata';

const mapStateToProps = state => {
  const {
    loading,
    copyLoading,
    error,
    data,
    currentPage,
    totalCount,
    pageSize,
    filter,
    copyItems,
  } = state.boostPromotion;

  return {
    data,
    error,
    loading,
    copyLoading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    copyItems,
  };
};

const mapDispatchToProps = {
  addTab,
  push,
  focusTab,
  moveTab,
  getBoostPromotionList,
  setPageSize,
  setCurrentPage,
  addCopyItem,
  removeCopyItem,
  copyBoosts,
  clearCopyItem,
  updateBoostPromotion,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class MonohaBoostPromotionList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    getBoostPromotionList: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    updateBoostPromotion: PropTypes.func.isRequired,
    openDataTab: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  copyColumnIdx = 1;

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getBoostPromotionList(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getBoostPromotionList(newPage, pageSize, filter);
  }

  handleCellClick(record, extra) {
    const { id, title } = record;
    const { index } = extra;

    if (index === 0) {
      this.props.openDataTab(id, { ...extra, title });
    }
  }

  async updateMonoha(selectedRowKeys, needTotalList, cleanSelectedKeys, status) {
    try {
      const ids = selectedRowKeys.map(Number);
      await this.props.updateBoostPromotion(ids, status);

      message.success(`${selectedRowKeys.length} updated.`);
    } catch (error) {
      message.error('Update Fail\n', error.message);
    }

    cleanSelectedKeys();

    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoostPromotionList(currentPage, pageSize, filter);
  }

  renderSelectedRowHandler(selectedRowKeys, needTotalList, cleanSelectedKeys) {
    return (
      <>
        <div>
          <span style={{ color: '#1890FF' }}>{selectedRowKeys.length} Target(s) </span>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => this.updateMonoha(selectedRowKeys, needTotalList, cleanSelectedKeys, 'enabled')}
          >
            <Button type="primary" disabled={selectedRowKeys.length === 0} icon="plus" />
          </Popconfirm>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => this.updateMonoha(selectedRowKeys, needTotalList, cleanSelectedKeys, 'disabled')}
          >
            <Button type="primary" disabled={selectedRowKeys.length === 0} icon="minus" />
          </Popconfirm>
        </div>
      </>
    );
  }

  render() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <>
        <MonohaBoostPromotionFilterForm />
        <Divider />

        {/* Add User Button */}
        <Row type="flex" gutter={12}>
          <div>
            <div>
              <div style={{ position: 'relative', marginBottom: 15 }}>
                <div>
                  <span style={{ fontSize: 14, marginRight: 15 }}>Total {totalCount}</span>
                  <PageSizeDropdown icon="down" currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
                </div>
              </div>
            </div>

            {error && this.renderError()}

            <DataViewer
              selectRows
              columns={getColumns()}
              data={data}
              loading={loading}
              onCellClick={this.handleCellClick}
              selectedRowHandler={this.renderSelectedRowHandler}
            />

            <Pagination
              total={totalCount}
              pageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={this.changePage}
              style={{
                textAlign: 'center',
              }}
            />
          </div>
        </Row>
      </>
    );
  }
}

export default MonohaBoostPromotionList;
