import gql from 'graphql-tag';

export const merchantListQuery = gql`
  query merchantList($skip: Int, $pageSize: Int) {
    merchantList(skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        displayName
      }
    }
  }
`;

export const luckyPromotionQuery = gql`
  query luckyPromotion($id: ID!) {
    luckyPromotion(id: $id) {
      id
      createdAt
      updatedAt
      startAt
      endAt
      title
      description
      status
      priceMin
      priceMax
      amountCap
      countCap
      expirationMinute
      filter {
        isFirstForLifetime
        isFirstForMerchant
        totalPaymentCount
        totalMerchantPaymentCount
      }
      periodCapsPerUser {
        period
        count
      }
      winPromotion {
        title
        discount
        maxDiscountAmount
      }
      losePromotion {
        title
        discount
        maxDiscountAmount
      }
      expirationMinute
      merchants {
        id
        name
        displayName
      }
      probabilities {
        min
        max
        probability
      }
    }
  }
`;

export default {
  merchantListQuery,
  luckyPromotionQuery,
};
