import React, { PureComponent } from 'react';
import { string, func } from 'prop-types';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { message, Alert, Button, Divider } from 'antd';
import autobind from 'autobind-decorator';
import MerchantForm from './MerchantForm';
import { merchantQuery } from './MerchantQueries';

@autobind
class MerchantDetail extends PureComponent {
  static propTypes = {
    activeTabKey: string.isRequired,
    setTabData: func.isRequired,
    getTabData: func.isRequired,
    id: string,
    mode: string,
  };

  static defaultProps = {
    id: null,
    mode: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    this.getMerchant();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getMerchant();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  async getMerchant() {
    const { id, mode } = this.props;

    if (mode === 'ADD') return;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: merchantQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.merchant) {
        throw new Error('Invalid Merchant ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.merchant,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Merchant: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getMerchant}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { setTabData, getTabData } = this.props;
    const { data, error, loading } = this.state;

    if (!loading && error) {
      return this.renderError();
    }

    return <MerchantForm {...data} setTabData={setTabData} getTabData={getTabData} onSubmit={this.getMerchant} />;
  }
}

export default MerchantDetail;
