import React, { useEffect, useState } from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { Form, message, Divider } from 'antd';

import apolloClient from 'helpers/apolloClient';
import { boltDrawPromotionImageListQuery } from 'redux/modules/boltDrawPromotion/queries';
import { boltDrawPromotionUpsertImageMutation } from 'redux/modules/boltDrawPromotion/mutations';

import styles from '../BoltDrawPromotion.scss';
import BoltDrawPromotionImageUploadForm from '../BoltDrawPromotionImageUploadForm';
import SquareImageList from './SquareImageList';
import { BoltDrawPromotionImageType } from './const';

const propTypes = {
  boltDrawPromotionId: PropTypes.number.isRequired,
  form: objectOf(PropTypes.any).isRequired,
};

const Images = ({ boltDrawPromotionId, form }) => {
  const [data, setData] = useState([]);

  async function getImages() {
    try {
      const result = await apolloClient.query({
        query: boltDrawPromotionImageListQuery,
        variables: { boltDrawPromotionId },
      });

      const images = result.data.boltDrawPromotionImageList.list;

      const imageData = {
        titleImage: images.find(image => image.type === BoltDrawPromotionImageType.TITLE),
        comingSoonImage: images.find(image => image.type === BoltDrawPromotionImageType.CARD_COMING_SOON),
        openedImage: images.find(image => image.type === BoltDrawPromotionImageType.CARD_OPENED),
        drawCompletedImage: images.find(image => image.type === BoltDrawPromotionImageType.CARD_DRAW_COMPLETED),
        fullImage: images.find(image => image.type === BoltDrawPromotionImageType.DETAIL_FULL),
      };

      setData(imageData);
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get BoltDraw Promotion Image: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  async function upsertImage(imageName) {
    const formFields = form.getFieldsValue();
    const { url } = formFields[imageName][0];

    let type;
    if (imageName === 'titleImageUrl') {
      type = BoltDrawPromotionImageType.TITLE;
    } else if (imageName === 'comingSoonImageUrl') {
      type = BoltDrawPromotionImageType.CARD_COMING_SOON;
    } else if (imageName === 'openedImageUrl') {
      type = BoltDrawPromotionImageType.CARD_OPENED;
    } else if (imageName === 'drawCompletedImageUrl') {
      type = BoltDrawPromotionImageType.CARD_DRAW_COMPLETED;
    } else if (imageName === 'fullImageUrl') {
      type = BoltDrawPromotionImageType.DETAIL_FULL;
    }

    try {
      await apolloClient.mutate({
        mutation: boltDrawPromotionUpsertImageMutation,
        variables: { boltDrawPromotionId, url, type },
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to Add(Update) Image: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  function makeImageUrlInitial(url) {
    if (!url) {
      return [];
    }

    return [
      {
        uid: -1,
        name: url,
        status: 'done',
        url,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      await getImages();
    })();
  }, [boltDrawPromotionId]);

  return (
    <>
      <Divider style={{ marginBottom: 32, marginLeft: 32, marginRight: 32 }} />
      <Form className={styles.form} colon={false}>
        <h3 style={{ margin: 20 }}>번개드로우 이미지</h3>
        <BoltDrawPromotionImageUploadForm
          form={form}
          name="comingSoonImageUrl"
          label="Coming Soon 이미지"
          initial={makeImageUrlInitial(data.comingSoonImage?.url)}
          upsertImage={upsertImage}
          require
        />
        <BoltDrawPromotionImageUploadForm
          form={form}
          name="openedImageUrl"
          label="진행중 이미지"
          initial={makeImageUrlInitial(data.openedImage?.url)}
          upsertImage={upsertImage}
          require
        />
        <BoltDrawPromotionImageUploadForm
          form={form}
          name="titleImageUrl"
          label="당첨 확인(참여자) 이미지"
          initial={makeImageUrlInitial(data.titleImage?.url)}
          upsertImage={upsertImage}
          require
        />
        <BoltDrawPromotionImageUploadForm
          form={form}
          name="drawCompletedImageUrl"
          label="추첨 완료(미참여자) 이미지"
          initial={makeImageUrlInitial(data.drawCompletedImage?.url)}
          upsertImage={upsertImage}
          require
        />
        <BoltDrawPromotionImageUploadForm
          form={form}
          name="fullImageUrl"
          label="Full 이미지"
          initial={makeImageUrlInitial(data.fullImage?.url)}
          upsertImage={upsertImage}
          require
        />
        <SquareImageList boltDrawPromotionId={boltDrawPromotionId} />
      </Form>
    </>
  );
};

Images.propTypes = propTypes;

export default Form.create()(Images);
