import gql from 'graphql-tag';

export const saveMenuMutation = gql`
  mutation saveMenu(
    $id: ID!
    $parentId: String
    $path: String
    $icon: String
    $title: String!
    $name: String!
    $leftMenu: Boolean
    $isLock: Boolean
    $sort: Int
  ) {
    saveMenu(
      id: $id
      parentId: $parentId
      path: $path
      icon: $icon
      title: $title
      name: $name
      leftMenu: $leftMenu
      isLock: $isLock
      sort: $sort
    ) {
      id
    }
  }
`;

export const removeMenuMutation = gql`
  mutation removeMenu($id: ID) {
    removeMenu(id: $id)
  }
`;

export default {
  saveMenuMutation,
  removeMenuMutation,
};
