import React, { Component, Fragment } from 'react';
import { func, objectOf, arrayOf, instanceOf, bool, number, string, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Divider } from 'antd';
import autobind from 'autobind-decorator';
import find from 'lodash/find';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setPageSize, setCurrentPage, getPayments } from 'redux/modules/payment/actions';
import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import { isAdmin, isCustomerService, isMerchant } from 'utils/permission';
import { ellipsis } from 'utils/text';

import UserDetail from 'containers/User/UserDetail';
import Pagination from 'components/InfinitePagination';
import { getColumns } from './PaymentContainerMetadata';
import PaymentDetail from './PaymentDetail';
import PaymentFilterForm from './PaymentFilterForm';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.payment;
  const { activeKey, list } = state.tabs.payment;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getPayments,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PaymentContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
    setPageSize: func.isRequired,
    setCurrentPage: func.isRequired,
    getPayments: func.isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    moveTab: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
    currentPage: number.isRequired,
    totalCount: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    activeKey: string.isRequired,
    list: arrayOf(any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  constructor(props) {
    super(props);

    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('payment', listTab);
    }

    this.props.moveTab('payment', 'ListTab', 0);

    const { pathname } = this.props.location;

    const match = pathname.match(/\/payment\/(.+)/);

    if (match) {
      const id = match[1];
      const hasTab = find(this.props.list, { key: id });

      if (!hasTab) {
        if (/^CS.+/.test(id)) {
          this.openUserTab(id);
        } else {
          this.openPaymentTab(id);
        }
      }

      this.props.focusTab('payment', id);
    } else {
      this.props.focusTab('payment', 'ListTab');
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/payment') {
        this.props.focusTab('payment', 'ListTab');
      } else {
        const match = pathname.match(/\/payment\/(.+)/);

        if (match) {
          const id = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: id });

          if (!hasTab) {
            if (/^CS.+/.test(id)) {
              this.openUserTab(id);
            } else {
              this.openPaymentTab(id);
            }
          }

          this.props.focusTab('payment', id);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/payment') {
        this.props.focusTab('payment', 'ListTab');
      }
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    if (isCustomerService() && !this.props.filter) return;

    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;
    this.props.getPayments(currentPage, newPageSize, filter);
  }

  /**
   * Opening tab for display Payment
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openPaymentTab(id, extra = {}) {
    const tabData = new TabData({
      key: id,
      title: `Payment (${ellipsis(id, 5)})`,
      closable: true,
      data: {
        id,
      },
      componentType: 'PaymentDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('payment', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('payment', id);
      this.props.push(`/payment/${id}`);
    }
  }

  /**
   * Opening tab for display User Detail
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openUserTab(id, extra = {}) {
    const tabData = new TabData({
      key: id,
      title: `${ellipsis(id, 5)}`,
      closable: true,
      data: {
        id,
      },
      componentType: 'UserDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('payment', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('payment', id);
      this.props.push(`/payment/${id}`);
    }
  }

  changePage(newPage) {
    if (!this.props.filter) {
      return;
    }

    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;
    this.props.getPayments(newPage, pageSize, filter);
  }

  getListTab() {
    const { pageSize, currentPage, data, error, loading, filter } = this.props;
    const columns = getColumns({
      renderUserLink: isAdmin() || isCustomerService(),
      onClickID: this.openPaymentTab,
      onClickUser: id => this.openUserTab(`CS-${id}`),
    });

    const shouldRenderDataViewer = isAdmin() || (isCustomerService() && filter) || (isMerchant() && filter);

    return (
      <Fragment>
        <PaymentFilterForm />
        <Divider />

        {shouldRenderDataViewer && <DataViewer columns={columns} data={data} error={error} loading={loading} />}

        <Pagination
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      if (this.props.filter) {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getPayments(currentPage, pageSize, filter);
      }

      this.props.push('/payment');
    } else {
      this.props.push(`/payment/${activeKey}`);
    }
  }

  render() {
    return (
      <TabViewer
        tabKey="payment"
        components={{
          ListTab: this.getListTab(),
          PaymentDetail,
          UserDetail,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default PaymentContainer;
