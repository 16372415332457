import React, { Component } from 'react';
import apolloClient from 'helpers/apolloClient';
import { func, string, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { Divider, Card, message, Row, Menu, Icon, Dropdown, Select } from 'antd';
import autobind from 'autobind-decorator';
import axios from 'axios';
import moment from 'moment';
import { get } from 'lodash';
import { arrayToCSV, arrayToExcel, statementHeader, settlementHeader } from 'utils/excelConverter';
import { updateSettlement } from 'redux/modules/settlement/actions';
import { getSettlementDetail } from 'redux/modules/settlementDetail/actions';
import SettlementSummary from './SettlementSummary';
import { merchantSettlementKeyQuery } from './SettlementQueries';
// import SettlementPaymentFilterForm from './SettlementPaymentFilterForm';
// import SettlementPaymentList from './SettlementPaymentList';
const isProduction = process.env.NODE_CONFIG_ENV === 'prod';

const { Option } = Select;

const mapStateToProps = state => {
  const { loading, error, data } = state.settlementDetail;

  return {
    data,
    error,
    loading,
  };
};

const mapDispatchToProps = {
  getSettlementDetail,
  updateSettlement,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementDetail extends Component {
  static propTypes = {
    getSettlementDetail: func.isRequired,
    updateSettlement: func.isRequired,
    merchantId: string.isRequired,
    date: string.isRequired,
    data: arrayOf(any).isRequired,
  };

  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      downloadKind: 'settlement',
      settlementKey: null,
      merchantName: '-',
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getSettlementDetail();
    this.getMerchant();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getSettlementDetail() {
    const { merchantId, date } = this.props;

    this.props.getSettlementDetail({
      merchantId,
      from: date,
      to: date,
      isSettlementDate: true,
    });
  }

  async doneSettlement() {
    const { merchantId } = this.props;

    try {
      await this.props.updateSettlement(merchantId);
      message.success('Settlement Updated.');
      this.getSettlementDetail();
    } catch (error) {
      message.error(`Failed to update settlement: ${error.message}`);
      console.log(error);
    }
  }

  async getMerchant() {
    const { merchantId } = this.props;

    const result = await apolloClient.query({
      query: merchantSettlementKeyQuery,
      variables: {
        id: merchantId,
      },
    });
    if (this._isMounted) {
      this.setState({
        settlementKey: get(result, 'data.merchant.settlementKey'),
        merchantName: get(result, 'data.merchant.displayName'),
      });
    }
  }

  async download(exportExcel) {
    const { data: settlementData } = this.props;
    const { downloadKind, settlementKey } = this.state;
    let { date: settlementDate } = this.props;
    let items = [];
    const env = isProduction ? 'prod' : 'staging';
    let arr = [];
    let blobData;

    if (downloadKind === 'statement') {
      items.push(moment(settlementDate));
    } else {
      items = settlementData.map(n => moment(n.referenceDate));
    }

    settlementDate = settlementDate.replace(/[^0-9]/g, '');
    // 정산 파일명을 publicAPIKey로 생성 전후 분기

    for (let i = 0; i < items.length; i += 1) {
      const targetDate = items[i];
      const downloadUrl = `https://settlement.chai.finance/${env}/${settlementKey}${
        downloadKind === 'statement' ? '/b2b' : ''
      }/${targetDate.format('YYYYMMDD')}.txt`;

      const { data } = await axios
        .get(downloadUrl)
        .catch(error => message.error(`Failed to download settlement list: ${error.message}`, 5));

      if (data) {
        const rows = data
          .split('\n')
          .map(n => {
            const text = n.trim();
            return text ? text.split('|') : null;
          })
          .filter(m => m);

        arr = arr.concat(rows);
      }
    }

    let blobType = 'text/plain';

    if (downloadKind === 'statement') {
      arr.unshift(statementHeader);
    } else {
      arr.map(row => {
        // row[9] 이상은 나중에 추가된 항목이라서 이전에 생성된 정산파일이면 보정가능한 부분은 보정처리
        if (!row[9]) {
          row[9] = settlementDate;
        }

        if (!row[10]) {
          row[10] = row[5] === 'C' ? Number(row[6]) * -1 : row[6];
        }

        return row;
      });
      arr.unshift(settlementHeader);
    }

    if (exportExcel) {
      blobData = arrayToExcel(arr);
      blobType = 'application/vnd.ms-excel;charset=euc-kr';
    } else {
      blobData = arrayToCSV(arr);
    }

    const blob = new Blob([blobData], { type: blobType });
    const downloadElem = window.document.createElement('a');

    downloadElem.href = window.URL.createObjectURL(blob);
    downloadElem.download = `${items[0].format('YYYY-MM-DD')}.${exportExcel ? 'xls' : 'csv'}`;

    document.body.appendChild(downloadElem);
    downloadElem.click();
    document.body.removeChild(downloadElem);

    return true;
  }

  changeDownloadKind(downloadKind) {
    this.setState({ downloadKind });
  }

  render() {
    // const { merchantId, date, data = {} } = this.props;
    const { data = [] } = this.props;
    const {
      settlementDate,
      status,
      plusAmount = 0,
      minusAmount = 0,
      feeAmount,
      feeTaxAmount,
      referenceDate,
    } = data.reduce(
      (x, y) => ({
        settlementDate: y.settlementDate,
        status: y.status,
        plusAmount: x.plusAmount + y.plusAmount,
        minusAmount: x.minusAmount + y.minusAmount,
        feeAmount: x.feeAmount + y.feeAmount,
        feeTaxAmount: x.feeTaxAmount + y.feeTaxAmount,
        referenceDate:
          ((x.referenceDate && `${x.referenceDate},`) || '') + moment(y.referenceDate).format('YYYY-MM-DD'),
      }),
      {
        plusAmount: 0,
        minusAmount: 0,
        feeAmount: 0,
        feeTaxAmount: 0,
      }
    );
    const { merchantName = '-' } = this.state;
    const settlementAmount = plusAmount + minusAmount - feeAmount - feeTaxAmount;

    const downloadType = (
      <Menu>
        <Menu.Item onClick={this.download.bind(this, false)}>
          <Icon type="download" />
          CSV
        </Menu.Item>
        <Menu.Item onClick={this.download.bind(this, true)}>
          <Icon type="download" />
          Excel
        </Menu.Item>
      </Menu>
    );

    return (
      <Card bordered={false}>
        <SettlementSummary
          merchantName={merchantName}
          settlementDate={settlementDate}
          referenceDate={referenceDate}
          status={status}
          amount={plusAmount + minusAmount}
          feeAmount={feeAmount}
          feeTaxAmount={feeTaxAmount}
          settlementAmount={settlementAmount}
          onClickedDone={this.doneSettlement}
        />
        <Divider style={{ margin: '20px 0 40px 0' }} />

        <Row justify="center" type="flex">
          <Select value={this.state.downloadKind} style={{ width: 120 }} onChange={this.changeDownloadKind}>
            <Option value="settlement">거래내역</Option>
            {merchantName.indexOf('신세계면세점') >= 0 && <Option value="statement">입,출금 내역</Option>}
          </Select>
          <Dropdown.Button overlay={downloadType} icon={<Icon type="download" />} type="primary">
            Download to
          </Dropdown.Button>
        </Row>

        {/* <SettlementPaymentFilterForm /> */}
        {/* <SettlementPaymentList merchantId={merchantId} date={date} referenceDate={referenceDate} /> */}
      </Card>
    );
  }
}

export default SettlementDetail;
