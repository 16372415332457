import React, { PureComponent } from 'react';
import { bool, element, string, func } from 'prop-types';
import { Modal, Button, Table, Divider, notification, Switch } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import SearchForm from 'schema/SearchForm';
import schema from 'schema/group';
import store from 'store';
import { getGroupsQuery } from '../AdminQueries';
import { updateAdminGroupMutation } from '../AdminMutations';

const isInGroup = (admins, targetId) => admins.map(u => u.id).indexOf(targetId) !== -1;
const UpdateAdminGroupType = {
  ADD: 0,
  REMOVE: 1,
};

@autobind
class EditAdminGroupModal extends PureComponent {
  static propTypes = {
    visible: bool.isRequired,
    title: element.isRequired,
    onClose: func.isRequired,
    adminId: string,
  };

  static defaultProps = {
    adminId: null,
  };

  state = {
    filter: {
      name: '',
      code: '',
      adminId: '',
    },
    data: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      showTotal: total => `Total ${total} items`,
    },
    sorter: {
      field: '',
      order: '',
    },
  };

  componentDidMount() {
    this.refresh();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.refresh();
    }
  }

  columns = [
    {
      title: 'Group Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Group Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'In/Out',
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (text, record) => {
        const isActive = isInGroup(record.admins, this.props.adminId);
        const updateType = isActive ? UpdateAdminGroupType.REMOVE : UpdateAdminGroupType.ADD;

        return <Switch onClick={this.updateAdminGroup.bind(this, record, updateType)} checked={isActive} />;
      },
    },
  ];

  search(filter) {
    const { adminId } = this.props;
    filter.adminId = adminId;

    const { pagination } = this.state;
    pagination.current = 1;

    this.setState({
      filter,
      pagination,
    });

    const { pageSize } = pagination;
    const { sorter } = this.state;

    const query = {
      skip: 0,
      pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter,
    };

    this.fetch(query);
  }

  resetSearch() {
    const { adminId } = this.props;
    this.setState(
      {
        filter: {
          adminId,
        },
      },
      () => {
        const { pagination, sorter, filter } = this.state;

        this.fetch({
          skip: 0,
          pageSize: pagination.pageSize,
          sortBy: sorter.field,
          descending: sorter.order === 'descend',
          filter,
        });
      }
    );
  }

  handleTableChange(newPagination, filters, sorter) {
    this.setState({
      pagination: newPagination,
      sorter: {
        field: sorter.field,
        order: sorter.order,
      },
    });

    const query = {
      skip: newPagination.current,
      pageSize: newPagination.pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter: this.state.filter,
    };

    this.fetch(query);
  }

  /**
   * Update Admin Group
   * @param {GroupEntity} record
   * @param {UpdateAdminType} operation
   */
  async updateAdminGroup(record, operation) {
    const groupId = record.id;
    const { adminId } = this.props;

    const result = await apolloClient.mutate({
      mutation: updateAdminGroupMutation,
      variables: {
        groupId,
        adminId,
        operation,
      },
    });

    notification.success({
      placement: 'bottomLeft bottomRight',
      message: operation === UpdateAdminGroupType.ADD ? 'Added' : 'Removed',
    });

    const { groups: newGroups } = result.data.updateAdminGroup;

    // After update, mutation will return new group if operation was 'add'.
    // Save into store so that other components relies on Group will update properly!
    const adminInfo = store.get('admin');

    // Update store. Note that it must be updated if it is admin itself.
    if (adminInfo.id === adminId) {
      store.set('admin', {
        ...adminInfo,
        groups: newGroups,
      });
    }

    this.refresh();
  }

  refresh() {
    const { pagination, sorter, filter } = this.state;

    const query = {
      skip: pagination.current,
      pageSize: pagination.pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter,
    };

    this.fetch(query);
  }

  async fetch(query) {
    // Don't request if modal is not visible
    if (!this.props.visible) return;

    this.setState({ loading: true });

    const { filter, skip, pageSize } = query;
    const { data } = await apolloClient.query({
      query: getGroupsQuery,
      variables: {
        filter: JSON.stringify(filter),
        skip,
        pageSize,
      },
    });
    const { getGroups: groups } = data;

    const { pagination } = this.state;
    pagination.total = data.totalCount;

    this.setState({
      loading: false,
      data: groups,
      pagination,
    });
  }

  render() {
    const { visible, title, onClose } = this.props;

    return (
      <Modal
        visible={visible}
        width={1000}
        title={title}
        onCancel={onClose}
        footer={[
          <Button key="back" onClick={onClose}>
            Close
          </Button>,
        ]}
        destroyOnClose
      >
        <SearchForm
          schema={schema.searchSchema}
          uiSchema={schema.searchUiSchema}
          handleSubmit={this.search}
          handleReset={this.resetSearch}
        />
        <Divider />
        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          scroll={{ x: 768 }}
          bordered
        />
      </Modal>
    );
  }
}

export default EditAdminGroupModal;
