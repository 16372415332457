import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';

/**
 * SearchButton Component
 * @prop {string} [iconType='search']
 * @prop {string} [text='Search']
 */
const SearchButton = ({ iconType, text }) => (
  <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
    <Icon type={iconType} />
    <span>{text}</span>
  </Button>
);
SearchButton.propTypes = {
  iconType: PropTypes.string,
  text: PropTypes.string,
};
SearchButton.defaultProps = {
  iconType: 'search',
  text: 'Search',
};

export default SearchButton;
