const rules = {
  publicAPIKey: [
    {
      required: true,
      message: 'Please enter the public api key.',
    },
  ],
  cancelAmount: [
    {
      required: true,
      message: 'Please enter the cancel amount.',
    },
  ],
};

export default rules;
