import React from 'react';
import PropTypes from 'prop-types';
import apolloClient from 'helpers/apolloClient';
import gql from 'graphql-tag';
import { Modal, Tree } from 'antd';

const { TreeNode } = Tree;

class EditModal extends React.PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    handFormSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  state = {
    menuActionList: [],
  };
  async componentWillReceiveProps(nextProps) {
    if (!nextProps.visible) {
      return;
    }
    const groupId = nextProps.formData.id;
    const { data: menuData } = await apolloClient.query({
      query: gql`
        query getMenuActions {
          getMenuActions {
            id
            parentId
            name
            title
            code
            actions {
              id
              code
            }
          }
        }
      `,
    });
    const { getMenuActions: menuActions } = menuData;

    const { data: groupData } = await apolloClient.query({
      query: gql`
        query getGroupActions {
          getGroupActions(id: "${groupId}") {
            id
            code
            name
            actions {
              id
              code
            }
          }
        }`,
    });
    const { getGroupActions: groupActions } = groupData;

    const menuActionList = this.buildMenuListAndActions(menuActions);
    const groupActionsStr = groupActions.actions.map(a => a.id);

    this.defaultCheckKeys = groupActionsStr;
    this.checkedKeys = groupActionsStr;
    this.setState({
      menuActionList,
    });
  }
  defaultCheckKeys = [];
  checkedKeys = [];
  onCancel = () => {
    this.props.onCancel();
    this.setState({
      menuActionList: [],
    });
    this.defaultCheckKeys = [];
    this.checkedKeys = [];
  };
  onOk = async () => {
    const data = {
      groupId: this.props.formData.id,
      actionIds: this.checkedKeys,
    };
    await this.props.handFormSubmit(data);
    this.setState({
      menuActionList: [],
    });
    this.defaultCheckKeys = [];
    this.checkedKeys = [];
  };
  buildMenuListAndActions = menuList => {
    const fn = list => {
      for (let i = 0; i < list.length; i += 1) {
        const menu = list[i];

        const children = menuList.filter(m => m.parentId === menu.id);
        const permissionChildren = menu.actions.map(s => {
          s.isPermissionChild = true;
          return s;
        });
        if (children && children.length > 0) {
          fn(children);
        }
        menu.children = [...children, ...permissionChildren];
      }
    };
    const topMenus = menuList.filter(m => !m.parentId);

    for (let i = 0; i < topMenus.length; i += 1) {
      const menu = topMenus[i];

      const children = menuList.filter(m => m.parentId === menu.id);
      const permissionChildren = menu.actions.map(s => {
        s.isPermissionChild = true;
        return s;
      });
      if (children && children.length > 0) {
        fn(children);
      }
      menu.children = [...children, ...permissionChildren];
    }

    return topMenus;
  };
  onCheck = checkedKeys => {
    this.checkedKeys = checkedKeys.filter(s => s.indexOf('menu') < 0);
  };
  renderTreeNode = menuActionList => {
    const list = [];

    for (let i = 0; i < menuActionList.length; i += 1) {
      const item = menuActionList[i];

      if (item.children && item.children.length > 0) {
        if (item.isPermissionChild) {
          list.push(
            <TreeNode className="permission-tree-node" title={item.code} key={item.id}>
              {this.renderTreeNode(item.children)}
            </TreeNode>
          );
        } else {
          list.push(
            <TreeNode
              className="clear-both"
              title={<span style={{ color: 'rgb(181, 185, 189)' }}>{item.name}</span>}
              key={`menu${item.id}`}
            >
              {this.renderTreeNode(item.children)}
            </TreeNode>
          );
        }
      } else {
        /* eslint-disable */
        if (item.isPermissionChild) {
          list.push(<TreeNode className="permission-tree-node" title={item.code} key={item.id} />);
        } else {
          list.push(
            <TreeNode
              className="clear-both"
              title={<span style={{ color: 'rgb(181, 185, 189)' }}>{item.name}</span>}
              key={'menu' + item.id}
            />
          );
        }
        /* eslint-enable */
      }
    }

    return list;
  };
  renderTree = () => (
    <Tree
      checkable
      multiple
      defaultExpandAll
      defaultCheckedKeys={this.defaultCheckKeys}
      onCheck={this.onCheck}
      selectable={false}
    >
      {this.renderTreeNode(this.state.menuActionList)}
    </Tree>
  );
  render() {
    return (
      <Modal
        width={800}
        visible={this.props.visible}
        cancelText="Close"
        okText="Save"
        title={this.props.title}
        onCancel={this.onCancel}
        onOk={this.onOk}
        destroyOnClose
      >
        {this.state.menuActionList.length > 0 ? this.renderTree() : null}
      </Modal>
    );
  }
}

export default EditModal;
