import React from 'react';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

/**
 * Get columns with custom options
 * @param {Object} options
 * @param {Function} onTitleClick Callback function that invokes when clicking title
 */
export function getColumns(options) {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'right',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render(val, others) {
        return <LinkText onClick={options.onTitleClick.bind(null, others)}>{val}</LinkText>;
      },
      width: '30%',
    },
    {
      title: 'Display',
      dataIndex: 'status',
      render(val) {
        return <span>{val === 'enabled' ? 'Yes' : '-'}</span>;
      },
      align: 'center',
    },
    {
      title: 'Fixed',
      dataIndex: 'fixed',
      render(val) {
        return <span>{val ? 'Yes' : '-'}</span>;
      },
      align: 'center',
    },
    {
      title: 'Last Modified',
      dataIndex: 'updatedAt',
      render(val) {
        return <span>{formatDate(val)}</span>;
      },
    },
    {
      title: 'Author',
      dataIndex: 'author',
    },
  ];

  return columns;
}

export default {
  getColumns,
};
