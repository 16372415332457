import React, { Component, Fragment } from 'react';
import { func, string, bool, number, objectOf, arrayOf, instanceOf, any } from 'prop-types';
import { Button, Table, Pagination, Divider, Alert } from 'antd';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { setPageSize, setCurrentPage, getBanners, setFilter, setSortedIndex } from 'redux/modules/banner/actions';
import PageSizeDropdown from 'components/PageSizeDropdown';
import { getColumns } from './BannerListMetadata';
import styles from './BannerList.scss';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.banner;

  return {
    loading,
    error,
    data,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  setPageSize,
  setCurrentPage,
  setFilter,
  setSortedIndex,
  getBanners,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BannerList extends Component {
  static propTypes = {
    setPageSize: func.isRequired,
    setCurrentPage: func.isRequired,
    setFilter: func.isRequired,
    setSortedIndex: func.isRequired,
    getBanners: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
    currentPage: number.isRequired,
    totalCount: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    groupId: string.isRequired,
    onCreateBannerButtonClick: func.isRequired,
    onRemoveBannerButtonClick: func.isRequired,
    onSaveButtonClick: func.isRequired,
    onBannerClick: func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  state = {
    selectedBannerIdList: [],
  };

  componentDidMount() {
    const { currentPage, pageSize, groupId } = this.props;

    this.props.setFilter({ groupId });
    this.props.getBanners(currentPage, pageSize, { groupId });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.groupId !== nextProps.groupId ||
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.state.selectedBannerIdList.length !== nextState.selectedBannerIdList.length
    );
  }

  updateSelectedBannerIdList(id) {
    const { selectedBannerIdList } = this.state;
    const idx = selectedBannerIdList.indexOf(id);

    if (idx < 0) {
      this.setState({
        selectedBannerIdList: [...selectedBannerIdList, id],
      });
    } else {
      const newList = [...selectedBannerIdList];
      newList.splice(idx, 1);
      this.setState({
        selectedBannerIdList: newList,
      });
    }
  }

  updateSortedIndex(id, newSortedIndex) {
    this.props.setSortedIndex(id, newSortedIndex);
  }

  handleRemoveButtonClick() {
    const { selectedBannerIdList } = this.state;
    this.props.onRemoveBannerButtonClick(selectedBannerIdList);
  }

  handleSavePriorityButton() {
    const { data } = this.props;
    const sortingList = data.map(banner => ({
      id: banner.id,
      sortedIndex: banner.sortedIndex,
    }));

    this.props.onSaveButtonClick(sortingList);
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;
    this.props.getBanners(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getBanners(newPage, pageSize, filter);
  }

  renderError() {
    const { error } = this.props;
    const errorMessage = error ? error.message : 'Unknown Error';

    return (
      <Fragment>
        <Divider style={{ background: 'none', margin: '10px 0' }} />
        <Alert type="warning" closable message={`Failed to load banners: ${errorMessage}.`} />
        <Divider style={{ background: 'none', margin: '10px 0' }} />
      </Fragment>
    );
  }

  render() {
    const { currentPage, pageSize, totalCount, data, error, loading } = this.props;
    const { onCreateBannerButtonClick } = this.props;
    const { selectedBannerIdList } = this.state;
    const columns = getColumns({
      onTitleClick: this.props.onBannerClick,
      onCheckboxChange: this.updateSelectedBannerIdList,
      onSortedIndexChange: this.updateSortedIndex,
      selectedBannerIdList,
    });

    return (
      <div>
        <div>
          <div style={{ position: 'relative', marginBottom: 15 }}>
            <div>
              <span style={{ fontSize: 14, marginRight: 15 }}>Total {totalCount}</span>
              <PageSizeDropdown icon="down" currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
            </div>
            <Button
              icon="plus-circle"
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={onCreateBannerButtonClick}
            >
              Add Banner
            </Button>
          </div>
        </div>

        {error && this.renderError()}

        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
          loading={loading}
          scroll={{ x: '100%' }}
        />

        <div className={styles.listBottom}>
          <Button
            type="danger"
            ghost
            style={{ position: 'absolute', top: 0, left: 0 }}
            onClick={this.handleRemoveButtonClick}
            disabled={totalCount < 1}
          >
            Remove
          </Button>

          <Pagination
            total={totalCount}
            pageSize={pageSize}
            defaultCurrent={1}
            current={currentPage}
            onChange={this.changePage}
            style={{
              textAlign: 'center',
            }}
          />

          <Button
            type="primary"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={this.handleSavePriorityButton}
            disabled={totalCount < 1}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default BannerList;
