import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Pagination, Divider, Button, Row, Col, Popconfirm, Tooltip, Icon, message } from 'antd';

import { TabViewer, TabData } from 'containers/Layout';
import DataViewer from 'components/DataViewer';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';
import { push } from 'connected-react-router';
import { addTab, moveTab, focusTab } from 'redux/modules/tabs';
import { ellipsis } from 'utils/text';
import find from 'lodash/find';

import apolloClient from 'helpers/apolloClient';
import { getTags, setCurrentPage, setPageSize } from 'redux/modules/tag/actions';
import { deleteTagMutation } from 'redux/modules/tag/mutations';

import TagFilterForm from './Form/TagFilterForm';
import columns from './TagMetadata';
import TagForm from './Form/TagForm';
import styles from './Tag.scss';

const mapStateToProps = state => {
  const { loading, data, currentPage, totalCount, pageSize, filter } = state.tag;
  const { activeKey, list } = state.tabs.tag;

  return {
    loading,
    activeKey,
    list,
    currentPage,
    totalCount,
    pageSize,
    data,
    filter,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  moveTab,
  getTags,
  setCurrentPage,
  setPageSize,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class TagContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    addTab: PropTypes.func.isRequired,
    moveTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    list: PropTypes.arrayOf(PropTypes.any).isRequired,

    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,

    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,

    setCurrentPage: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,

    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),

    getTags: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filter: null,
    error: null,
  };

  state = {};

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }
  componentDidMount() {
    const { list } = this.props;
    let hasListTab = false;

    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('tag', listTab);
    }

    this.props.moveTab('tag', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/tag\/(.+)/);

    if (match) {
      const tagName = match[1];
      const hasTab = find(this.props.list, { key: tagName });

      if (!hasTab) {
        this.openDataTab(tagName);
      }

      this.props.focusTab('tag', tagName);
    } else {
      this.props.focusTab('tag', 'ListTab');

      const { currentPage, pageSize, filter } = this.props;
      this.props.getTags(currentPage, pageSize, filter);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }
  unsubscribe = null;

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/tag') {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getTags(currentPage, pageSize, filter);
        this.props.focusTab('tag', 'ListTab');
      } else {
        const match = pathname.match(/\/tag\/(.+)/);

        if (match) {
          const tagName = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: tagName });

          if (!hasTab) {
            this.openDataTab(tagName);
          }

          this.props.focusTab('tag', tagName);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/tag') {
        this.props.focusTab('tag', 'ListTab');
      }
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getTags(currentPage, newPageSize, filter);
  }

  handleCellClick(record, extra) {
    const { name } = record;
    const { index } = extra;

    if (index === 1) {
      this.openDataTab(name);
    }
  }

  /**
   * Opening tab for display Promotion
   * @param {string} tagName
   */
  openDataTab(tagName) {
    const title = tagName === 'add' ? 'New Tag' : ellipsis(tagName, 8);
    const tabData = new TabData({
      key: `${tagName}`,
      title,
      closable: true,
      data: {
        tagName,
        isNew: tagName === 'add',
      },
      componentType: 'TagForm',
    });

    this.props.addTab('tag', tabData);
    this.props.focusTab('tag', tagName);
    this.props.push(`/tag/${tagName}`);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;
    this.props.getTags(newPage, pageSize, filter);
  }

  async delTag(tagId) {
    try {
      await apolloClient.mutate({
        mutation: deleteTagMutation,
        variables: { tagId: Number(tagId) },
      });

      message.success(`Tag Id: ${tagId} deleted.`);
    } catch (error) {
      message.error('Failed to delete Tag.\n', error.message);
    }
    const { currentPage, pageSize, filter } = this.props;
    this.props.getTags(currentPage, pageSize, filter);
  }

  addTag() {
    this.openDataTab('add');
  }

  getColumns() {
    return [
      ...columns,
      {
        title: 'Tools',
        fixed: 'right',
        width: 200,
        render: record => (
          <div>
            <Popconfirm title="Are you sure?" onConfirm={() => this.delTag(record.id)}>
              <Tooltip title="Delete Tag">
                <Icon className={styles.toolIcon} type="delete" />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ];
  }

  /**
   * Get List Tab
   * @return {ReactElement}
   */
  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <Fragment>
        <TagFilterForm />
        <div className={styles.top}>
          <Row type="flex" gutter={12}>
            <Col>
              <Button icon="plus-circle" type="primary" onClick={this.addTag}>
                New
              </Button>
            </Col>
          </Row>
        </div>
        <Divider />
        <DataViewer
          columns={this.getColumns()}
          data={data}
          error={error}
          loading={loading}
          onCellClick={this.handleCellClick}
        />
        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />
        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          <Divider type="vertical" style={{ background: 'none' }} />
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getTags(currentPage, pageSize, filter);
      this.props.push('/tag');
    } else {
      this.props.push(`/tag/${activeKey}`);
    }
  }

  render() {
    return (
      <div>
        <TabViewer
          tabKey="tag"
          components={{
            ListTab: this.getListTab(),
            TagForm,
          }}
          onTabChanged={this.handleTabChange}
        />
      </div>
    );
  }
}

export default TagContainer;
