const rules = {
  name: [
    {
      required: true,
      message: 'Please enter merchant display name.',
    },
  ],
  displayName: [
    {
      required: true,
      message: 'Please enter merchant admin name.',
    },
  ],
  shortName: [
    {
      required: true,
      message: 'Please enter short name.',
    },
  ],
  status: [
    {
      required: true,
    },
  ],
  countryCode: [
    {
      required: true,
    },
  ],
  vertical: [
    {
      required: true,
    },
  ],
  logoUrl: [
    {
      type: 'url',
      message: 'Logo URL must be valid URL format.',
    },
  ],
  website: [
    {
      required: true,
      message: 'Please enter website.',
    },
    {
      type: 'url',
      message: 'Website must be valid URL format.',
    },
  ],
  managerName: [
    {
      required: true,
      message: 'Please enter Manager Name.',
    },
  ],
  managerEmail: [
    {
      required: true,
      message: 'Please enter email.',
    },
    {
      type: 'email',
      message: 'Manager Email must be valid Email format.',
    },
  ],
  managerPhone: [
    {
      required: true,
      message: 'Please enter Manager Phone.',
    },
    {
      type: 'string',
      pattern: /^[0-9]{3}-?([0-9]{3}|[0-9]{4})-?[0-9]{4}$/,
      message: 'Manager Phone Number must be valid format like 010-123-5678 or 01012345678',
    },
  ],
  customerServicePhone: [
    {
      type: 'string',
      pattern: /^([0-9]){2,4}-?(([0-9]){3,4})(-?([0-9]){4})?$/,
      message: 'Customer Service Phone Number must be valid format like 010-123-5678 or 010-1234-5678, or 1544-1234.',
    },
  ],
  linkMethod: [
    {
      required: true,
    },
  ],
  businessCategory: [
    {
      required: true,
    },
  ],
  businessType: [
    {
      required: true,
    },
  ],
  settlementEmail: [
    {
      required: true,
    },
    {
      type: 'email',
      message: 'Manager Email must be valid Email format.',
    },
  ],
};

export default rules;
