import gql from 'graphql-tag';

export const i18nSaveMutation = gql`
  mutation i18nSave($tableName: String!, $targetId: String!, $locale: String!, $columnName: String!, $text: String!) {
    i18nSave(tableName: $tableName, targetId: $targetId, locale: $locale, columnName: $columnName, text: $text)
  }
`;

export const i18nUploadImageMutation = gql`
  mutation i18nUploadImage($imageFile: FileUpload!) {
    i18nUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export default {
  i18nSaveMutation,
  i18nUploadImageMutation,
};
