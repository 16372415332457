/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '20%',
  },
  {
    title: 'Rule Type',
    dataIndex: 'ruleCodeType',
    align: 'center',
    width: '20%',
    render: data => {
      switch (data) {
        case 'businessCode':
          return 'Business Code';
        case 'businessName':
          return 'Business Name';
        case 'merchantName':
          return 'Merchant Name';
        case 'merchantId':
          return 'Merchant Id';
        default:
          return data;
      }
    },
  },
  {
    title: 'Rule Content',
    dataIndex: 'code',
    align: 'center',
    render: (data, row) => {
      if (row.ruleCodeType === 'merchantId') {
        return <Link to={`/merchant/${data}`}>{data}</Link>;
      }
      return data;
    },
  },
  {
    title: 'Edit',
    dataIndex: 'id',
    align: 'center',
    key: 'edit',
    width: 80,
    render: id => (
      <Link to={`/operation/exchangePaymentRule/${id}`}>
        <Button shape="circle" icon="edit" />
      </Link>
    ),
  },
];

export default columns;
