export default {
  $id: 'user-edit-schema',
  title: 'user-edit-schema',
  description: 'user-edit-schema.',
  type: 'object',
  required: [],
  properties: {
    name: {
      type: 'string',
      title: 'Username',
    },

    trueName: {
      type: 'string',
      title: 'True Name',
    },

    email: {
      type: 'string',
      title: 'Email',
    },

    phone: {
      type: 'string',
      title: 'Phone',
    },
  },
};
