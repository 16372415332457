import { Button, Col, Dropdown, Form, Icon, Menu, message, Row, Select } from 'antd';
import autobind from 'autobind-decorator';
import axios from 'axios';
import { push } from 'connected-react-router';
import apolloClient from 'helpers/apolloClient';
import get from 'lodash/get';
import moment from 'moment';
import { any, func, instanceOf, objectOf, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getMerchants } from 'redux/modules/merchant/actions';
import { getSettlements } from 'redux/modules/settlement/actions';
import { setDate, setMerchantId, setMerchantName } from 'redux/modules/settlementDetail/actions';
import { addTab, focusTab } from 'redux/modules/tabs';
import {
  arrayToCSV,
  arrayToExcel,
  cashReceiptHeader,
  monthlyTotalHeader,
  settlementHeader,
  taxInvoiceHeader,
} from 'utils/excelConverter';
import { isAdmin } from 'utils/permission';
import styles from './Settlement.scss';
import SettlementCalendarContent from './SettlementCalendarContent';
import { getTaxInvoiceAllQuery, merchantSettlementKeyQuery } from './SettlementQueries';

const env = process.env.NODE_CONFIG_ENV || 'staging';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = state => {
  const { merchant } = state;
  const { settlement } = state;
  const { merchantId, merchantName, date } = state.settlementDetail;

  return {
    merchant,
    settlement,
    merchantId,
    merchantName,
    date,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  getSettlements,
  getMerchants,
  setMerchantId,
  setMerchantName,
  setDate,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementCalendar extends Component {
  static propTypes = {
    getSettlements: func.isRequired,
    getMerchants: func.isRequired,
    setMerchantId: func.isRequired,
    setMerchantName: func.isRequired,
    setDate: func.isRequired,
    merchant: objectOf(any).isRequired,
    settlement: objectOf(any).isRequired,
    merchantId: string.isRequired,
    merchantName: string.isRequired,
    date: instanceOf(moment).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      downloadKind: 'taxInvoice',
      loading: false,
      mode: 'calendar',
      settlementKey: undefined,
      settlementPidMap: undefined,
      settlementPidData: undefined,
      selectedPid: undefined,
    };
  }

  componentDidMount() {
    this.props.getMerchants(1, 1000);
    if (this.props.merchantId) {
      this.setMerchant('setMerchant', this.props.merchantId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.merchant.data !== this.props.merchant.data) {
      if (this.props.merchant.data.length === 1) {
        this.setMerchant(this.props.merchant.data[0].id);
      }
    }
  }

  async getSettlements(merchantId, date) {
    if (!merchantId) {
      return;
    }

    const { selectedPid, settlementKey } = this.state;
    const actualFromDate = moment(date).startOf('month').subtract(1, 'month');
    const actualToDate = moment(date).endOf('month');

    await this.props.getSettlements({
      // Sometimes holiday was end of previous month.
      // So bring extra 2 weeks before and end of the month
      from: actualFromDate.format('YYYY-MM-DD'),
      to: actualToDate.format('YYYY-MM-DD'),
      merchantId: merchantId === 'all' ? undefined : merchantId,
    });

    this.getPidSettlements(settlementKey, selectedPid);
  }

  async getPidSettlements(settlementKey, selectedPid) {
    const { settlement } = this.props;
    const settlementData = settlement?.data;

    const targetDates = Object.values(settlementData)
      .reduce((acc, item) => {
        // settlement pid 데이터 생성 시점
        if (new Date(item.referenceDate).getTime() <= new Date('2021-07-22').getTime()) {
          return acc;
        }
        if (!acc.includes(item.referenceDate)) {
          return [...acc, item.referenceDate];
        }
        return acc;
      }, [])
      .map(date => moment(date).format('YYYYMMDD'));

    if (!settlementKey || !selectedPid || !targetDates.length) {
      return [];
    }

    const datas = [];
    for (let i = 0; i < targetDates.length; i += 1) {
      try {
        const dateString = targetDates[i];
        const { data } = await axios.get(
          `https://settlement.chai.finance/${env}/${settlementKey}/summary/${dateString}.txt`
        );
        if (data) {
          if (data?.pids) {
            const pidKeys = Object.keys(data?.pids);
            for (let j = 0; j < pidKeys.length; j += 1) {
              const pidItem = data?.pids[pidKeys[j]];
              data.pids[pidKeys[j]] = {
                ...pidItem,
                minusAmount: -Math.abs(pidItem?.minusAmount) || 0,
                transactionCount: pidItem.paymentCount,
              };
            }
          }
          datas.push(data);
        }
        // eslint-disable-next-line no-empty
      } catch {}
    }

    return this.setState({
      settlementPidData: datas,
    });
  }

  /**
   * Move to previous month
   */
  setPreviousMonth() {
    const { merchantId, date } = this.props;
    date.subtract(1, 'month');
    this.props.setDate(date);
    this.getSettlements(merchantId, date);
  }

  /**
   * Move to next month
   */
  setNextMonth() {
    const { merchantId, date } = this.props;
    date.add(1, 'month');
    this.props.setDate(date);
    this.getSettlements(merchantId, date);
  }

  async setMerchant(merchantId) {
    let targetMerchantName = 'all';

    if (merchantId !== 'all') {
      const { data: merchantList } = this.props.merchant;

      for (let i = 0; i < merchantList.length; i += 1) {
        if (merchantList[i].id === merchantId) {
          targetMerchantName = merchantList[i].displayName;
          break;
        }
      }

      if (!targetMerchantName) {
        message.warning('Invalid merchant selected.');
        return;
      }
    }

    this.props.setMerchantId(merchantId);
    this.props.setMerchantName(targetMerchantName);

    const { date } = this.props;
    const { selectedPid } = this.state;
    this.getSettlements(merchantId, date);
    const key = await this.getSettlementKey(merchantId);
    const pidMap = await this.getSettlementPidMap(key);
    this.setState({
      settlementKey: key,
      settlementPidMap: pidMap,
      selectedPid,
    });
    this.setPid(undefined);
  }

  setPid(pid) {
    const { merchantId, date } = this.props;
    const selectedPid = pid === 'unseleced' ? undefined : pid;
    if (selectedPid) {
      this.changeDownloadKind('taxInvoice');
    }
    this.setState(
      {
        selectedPid: pid === 'unseleced' ? undefined : pid,
      },
      () => this.getSettlements(merchantId, date)
    );
  }

  async getSettlementKey(merchantId) {
    const result = await apolloClient.query({
      query: merchantSettlementKeyQuery,
      variables: {
        id: merchantId,
      },
    });

    return get(result, 'data.merchant.settlementKey');
  }

  async getSettlementPidMap(settlementKey) {
    try {
      if (!settlementKey) {
        return [];
      }
      const { data } = await axios.get(`https://settlement.chai.finance/${env}/${settlementKey}/pid_map.txt`);
      return Object.keys(data).map(key => ({ key, title: data[key] }));
    } catch {
      // message.error(`Failed to Get pid_map: /${env}/${settlementKey}/pid_map.txt, ${e.message}`);
    }
    return [];
  }

  async downloadTaxInvoiceAll() {
    const { date } = this.props;
    const result = await apolloClient.query({
      query: getTaxInvoiceAllQuery,
      variables: {
        date,
      },
    });

    const invoiceList = result?.data?.getTaxInvoice || [];
    const arr = [];
    for (let i = 0; i < invoiceList.length; i += 1) {
      const invoice = invoiceList[i];
      arr.push([
        invoice.endOfMonth,
        invoice.businessNumber,
        invoice.name,
        invoice.representativeName,
        invoice.address,
        invoice.businessType,
        invoice.businessCategory,
        invoice.settlementEmail,
        invoice.settlementSubEmail,
        invoice.feeAmount,
        invoice.feeTaxAmount,
      ]);
    }

    const blob = new Blob([arrayToExcel(arr)], { type: 'application/vnd.ms-excel;charset=euc-kr' });
    const downloadElem = window.document.createElement('a');

    downloadElem.href = window.URL.createObjectURL(blob);
    downloadElem.download = `${date.month() + 1}월 세금계산서.xls`;

    document.body.appendChild(downloadElem);
    downloadElem.click();
    document.body.removeChild(downloadElem);
  }

  makeTaxInvoiceData(settlementItems, date, merchantId, merchantData) {
    const arr = [];
    let plusAmount = 0;
    let minusAmount = 0;
    let feeAmount = 0;
    let transactionCount = 0;
    let refundCount = 0;
    const excelHeader = [...taxInvoiceHeader];

    const merchantNames = {};
    for (let i = 0; i < merchantData.length; i += 1) {
      const merchant = merchantData[i];
      merchantNames[merchant.id] = merchant.displayName;
    }

    if (merchantId === 'all') {
      excelHeader.unshift('가맹점');
      settlementItems.sort((prev, next) => {
        const sortIdx = prev.merchantId.localeCompare(next.merchantId);
        if (sortIdx !== 0) {
          return sortIdx;
        }

        return prev.referenceDate.localeCompare(next.referenceDate);
      });
    }

    arr.push(excelHeader);

    for (let i = 0; i < settlementItems.length; i += 1) {
      const settlement = settlementItems[i];
      const row = [];

      if (date.isSame(settlement.referenceDate, 'month')) {
        plusAmount += settlement.plusAmount || 0;
        minusAmount += settlement.minusAmount || 0;
        feeAmount += settlement.feeAmount || 0;
        transactionCount += settlement.transactionCount || 0;
        refundCount += settlement.refundCount || 0;

        if (merchantId === 'all') {
          row.push(merchantNames[settlement.merchantId]);
        }

        row.push(moment(settlement.referenceDate).format('YYYY-MM-DD'));
        row.push(moment(settlement.settlementDate).format('YYYY-MM-DD'));
        row.push(settlement.plusAmount + settlement.minusAmount);
        row.push(settlement.feeAmount);
        row.push(settlement.feeTaxAmount);
        row.push(settlement.feeAmount + settlement.feeTaxAmount);
        row.push(settlement.plusAmount + settlement.minusAmount - settlement.feeAmount - settlement.feeTaxAmount);

        arr.push(row);
      }
    }

    arr.push(monthlyTotalHeader);
    arr.push([
      plusAmount + minusAmount,
      transactionCount,
      minusAmount,
      refundCount,
      feeAmount,
      transactionCount + refundCount,
    ]);

    return arr;
  }

  async makeSettlementDetailData(settlementItems, date, merchantId) {
    const { selectedPid } = this.state;
    let arr = [settlementHeader];
    const settlementKey = await this.getSettlementKey(merchantId);

    for (let i = 0; i < settlementItems.length; i += 1) {
      const settlement = settlementItems[i];
      const referenceDate = moment(settlement.referenceDate);
      if (date.isSame(referenceDate, 'month')) {
        const dateString = referenceDate.format('YYYYMMDD');
        const downloadUrl = `https://settlement.chai.finance/${env}/${settlementKey}/${
          selectedPid ? `${selectedPid}/` : ''
        }${dateString}.txt`;

        const { data } = await axios
          .get(downloadUrl)
          .catch(error => message.error(`Failed to download settlement list: ${error.message}`, 5));

        if (data) {
          const parsedArray = data
            .split('\n')
            .map(n => {
              const text = n.trim();
              return text ? text.split('|') : null;
            })
            .filter(Boolean);

          parsedArray.map(row => {
            // row[9] 이상은 나중에 추가된 항목이라서 이전에 생성된 정산파일이면 보정가능한 부분은 보정처리
            if (!row[9]) {
              row[9] = settlement.settlementDate;
            }

            if (!row[10]) {
              row[10] = row[5] === 'C' ? Number(row[6]) * -1 : row[6];
            }

            return row;
          });

          arr = arr.concat(parsedArray);
        }
      }
    }

    return arr;
  }

  async makeCashReceiptData(settlementItems, date, merchantId) {
    let arr = [cashReceiptHeader];
    const settlementKey = await this.getSettlementKey(merchantId);
    const lastDate = moment(date).endOf('month').date();

    for (let i = 1; i <= lastDate; i += 1) {
      const dateString = moment(date).date(i).format('YYYYMMDD');
      const downloadUrl = `https://settlement.chai.finance/${env}/${settlementKey}/cashReceipt/${dateString}.txt`;

      const result = await axios.get(downloadUrl).catch(() => {});

      if (result?.data) {
        const { data } = result;
        const parsedArray = data
          .split('\n')
          .map(n => {
            const text = n.trim();
            return text ? text.split('|') : null;
          })
          .filter(Boolean);

        arr = arr.concat(parsedArray);
      }
    }

    return arr;
  }

  async download(exportExcel) {
    const { merchantId, merchantName, date } = this.props;
    const { downloadKind } = this.state;
    const { data: merchantData } = this.props.merchant;
    const { selectedPid, settlementPidData } = this.state;

    if (merchantId === 'all' && downloadKind !== 'taxInvoice') {
      message.warning('No Mechant selected.');
      return;
    }

    const settlementItems =
      get(this.props, 'settlement.data')
        .map(settlementItem => this.getSettlementWithPid(settlementItem, selectedPid, settlementPidData))
        .filter(Boolean) || [];
    let arr;

    if (downloadKind === 'taxInvoice') {
      arr = this.makeTaxInvoiceData(settlementItems, date, merchantId, merchantData);
    } else if (downloadKind === 'detail') {
      this.setState({ loading: true });

      arr = await this.makeSettlementDetailData(settlementItems, date, merchantId).catch(error =>
        message.error(`Failed to download settlement list: ${error.message}`, 5));

      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });

      arr = await this.makeCashReceiptData(settlementItems, date, merchantId).catch(error =>
        message.error(`Failed to download cashReceipt list: ${error.message}`, 5));

      this.setState({ loading: false });
    }

    if (!arr || !arr.length) {
      message.warning('There is no content.');
      return;
    }

    let kindName;
    switch (downloadKind) {
      case 'detail':
        kindName = '(상세)';
        break;
      case 'cashReceipt':
        kindName = '(현금영수증)';
        break;
      default:
        kindName = '';
        break;
    }

    if (selectedPid) {
      kindName += `_${selectedPid}`;
    }

    const filename = `${merchantName}_${date.format('MM')}월자료${kindName}.${exportExcel ? 'xls' : 'csv'}`;
    let data;
    let blobType = 'text/plain';

    if (exportExcel) {
      data = arrayToExcel(arr);
      blobType = 'application/vnd.ms-excel;charset=euc-kr';
    } else {
      data = arrayToCSV(arr);
    }

    const blob = new Blob([data], { type: blobType });
    const downloadElem = window.document.createElement('a');

    downloadElem.href = window.URL.createObjectURL(blob);
    downloadElem.download = filename;

    document.body.appendChild(downloadElem);
    downloadElem.click();
    document.body.removeChild(downloadElem);
  }

  getSettlementWithPid(settlementData, selectedPid, settlementPidData) {
    if (!selectedPid) {
      return settlementData;
    }

    const targetDate = settlementData.referenceDate;
    const pidTargetData = settlementPidData.find(
      pidData => pidData.referenceDate === moment(targetDate).format('YYYYMMDD') && pidData?.pids[selectedPid]
    );

    if (pidTargetData) {
      return {
        ...settlementData,
        ...pidTargetData.pids[selectedPid],
      };
    }

    // pidData 없는 경우
    return null;
  }
  changeDownloadKind(downloadKind) {
    this.setState({ downloadKind });
  }

  changeMode() {
    this.setState({ mode: this.state.mode === 'calendar' ? 'table' : 'calendar' });
  }

  render() {
    const { data: merchantData } = this.props.merchant;
    const { merchantId, date, settlement } = this.props;
    const { mode, settlementKey, settlementPidMap, selectedPid, settlementPidData } = this.state;
    const hasPid = settlementKey && settlementPidMap?.length > 0;

    return (
      <div className={styles.calendarContainer}>
        {/* Merchant Select Form */}
        <Form className={styles.calendarMerchantSelect}>
          <Row gutter={12}>
            <Col xs={12}>
              <Item label="Merchant">
                <Select
                  showSearch
                  value={merchantId}
                  onChange={this.setMerchant}
                  filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
                >
                  {merchantData.length !== 1 && !isAdmin() && <Option value="all">All</Option>}
                  {merchantData.map(merchant => (
                    <Option key={merchant.id} value={merchant.id}>
                      {merchant.displayName}
                    </Option>
                  ))}
                </Select>
              </Item>
            </Col>
            {hasPid && (
              <Col xs={12}>
                <Item label="PID">
                  <Select
                    showSearch
                    value={selectedPid}
                    onChange={this.setPid}
                    filterOption={(input, option) => option.props.children.indexOf(input) >= 0}
                  >
                    <Option key="unseleced">(선택 해제)</Option>
                    {settlementPidMap?.map(pid => (
                      <Option key={pid.key}>{pid.title}</Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            )}
          </Row>
        </Form>

        {/* Month Title */}
        <div className={styles.calendarTitle}>
          <Icon className={styles.calendarPreviousButton} type="left-circle" onClick={this.setPreviousMonth} />
          <div>
            {date.format('MMMM')}, {date.year()}
          </div>
          <Icon className={styles.calendarNextButton} type="right-circle" onClick={this.setNextMonth} />
        </div>

        {/* Calendar / Table */}
        <SettlementCalendarContent
          mode={mode}
          date={date}
          settlement={settlement}
          selectedPid={selectedPid}
          settlementPidData={settlementPidData}
          getSettlements={this.getSettlements}
        />

        <div className={styles.downloadButtonContainer} style={{ float: 'right' }}>
          <Button onClick={this.changeMode} style={{ marginRight: 20 }}>
            {this.state.mode === 'calendar' ? '상세보기' : '달력보기'}
          </Button>
          {isAdmin() && !selectedPid && (
            <Button onClick={this.downloadTaxInvoiceAll} style={{ marginRight: 20 }}>
              세금계산서 다운로드
            </Button>
          )}
          <Select value={this.state.downloadKind} onChange={this.changeDownloadKind}>
            <Option value="taxInvoice">일별 합산내역</Option>
            <Option value="detail">상세거래 내역</Option>
            {!selectedPid && <Option value="cashReceipt">현금영수증 발행내역</Option>}
          </Select>
          <Dropdown.Button
            overlay={
              <Menu>
                <Menu.Item onClick={this.download.bind(this, false)} key="csv_download">
                  <Icon type="download" />
                  CSV
                </Menu.Item>
                <Menu.Item onClick={this.download.bind(this, true)} key="excel_download">
                  <Icon type="download" />
                  Excel
                </Menu.Item>
              </Menu>
            }
            icon={<Icon type={this.state.loading ? 'loading' : 'download'} />}
            type="primary"
            disabled={!!this.state.loading}
          >
            Download to
          </Dropdown.Button>
        </div>
      </div>
    );
  }
}

export default SettlementCalendar;
