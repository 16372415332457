import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, Divider, Button, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import DataViewer from 'components/DataViewer';
import { getBoltDrawPromotionList, setPageSize, setCurrentPage } from 'redux/modules/boltDrawPromotion/actions';

import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { columns } from './BoltDrawPromotionMetadata';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.boltDrawPromotion;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  addTab,
  push,
  focusTab,
  moveTab,
  getBoltDrawPromotionList,
  setPageSize,
  setCurrentPage,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoltDrawPromotionList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    getBoltDrawPromotionList: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    openDataTab: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getBoltDrawPromotionList(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getBoltDrawPromotionList(newPage, pageSize, filter);
  }

  createNewBoltDrawPromotion() {
    const newTab = new TabData({
      key: 'add',
      title: 'New BoltDraw Promotion',
      closable: true,
      data: {
        id: 'add',
      },
      componentType: 'BoltDrawPromotionForm',
    });

    this.props.addTab('boltDrawPromotion', newTab);
    this.props.focusTab('boltDrawPromotion', newTab.key);
    this.props.push('/boost/boltDrawPromotion/add');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  handleCellClick(record, extra) {
    const { id, name } = record;
    const { index } = extra;

    if (index === 0) {
      this.props.openDataTab(id, { ...extra, title: name });
    }
  }

  render() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <>
        <Divider />

        <Row type="flex" gutter={12}>
          <Col>
            <Button icon="plus-circle" type="primary" onClick={this.createNewBoltDrawPromotion}>
              New
            </Button>
          </Col>
        </Row>

        <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={this.handleCellClick} />

        <PageDisplay totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAligh: 'right' }}>
          <PageSizeDropdown onPageSizeChange={this.updatePageSize} currentPageSize={pageSize} />
        </div>
      </>
    );
  }
}

export default BoltDrawPromotionList;
