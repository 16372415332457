import React from 'react';
import { formatDate } from 'utils/format';

export const columns = [
  {
    title: 'Date',
    dataIndex: 'datetime',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '20%',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    render: function renderType(val, others) {
      return (
        <span>
          {val} ({others.subTitle})
        </span>
      );
    },
    width: '30%',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: function renderType(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Count',
    dataIndex: 'count',
    render: function renderType(val) {
      if (val < 0) {
        return <span style={{ color: 'navy' }}>{val}</span>;
      }
      return <span style={{ color: 'red' }}>{val}</span>;
    },
    width: '10%',
  },
  {
    title: 'Balance',
    dataIndex: 'availableBolt',
    render: function renderType(val) {
      return <span>{val}</span>;
    },
    width: '10%',
  },
];

export default {
  columns,
};
