import apolloClient from 'helpers/apolloClient';
import { boostExclusionRuleListQuery } from './queries';
import { boostExclusionRuleAddMutation, boostExclusionRuleUpdateMutation } from './mutations';

export const FETCH_BOOST_EXCLUSION_RULE = 'boostExclusionRule/FETCH';
export const FETCH_BOOST_EXCLUSION_RULE_SUCCESS = 'boostExclusionRule/FETCH_SUCCESS';
export const FETCH_BOOST_EXCLUSION_RULE_FAILURE = 'boostExclusionRule/FETCH_FAILURE';

export const ADD_BOOST_EXCLUSION_RULE = 'boostExclusionRule/ADD';
export const ADD_BOOST_EXCLUSION_RULE_SUCCESS = 'boostExclusionRule/ADD_SUCCESS';
export const ADD_BOOST_EXCLUSION_RULE_FAILURE = 'boostExclusionRule/ADD_FAILURE';

export const UPDATE_BOOST_EXCLUSION_RULE = 'boostExclusionRule/UPDATE';
export const UPDATE_BOOST_EXCLUSION_RULE_SUCCESS = 'boostExclusionRule/UPDATE_SUCCESS';
export const UPDATE_BOOST_EXCLUSION_RULE_FAILURE = 'boostExclusionRule/UPDATE_FAILURE';

export const SET_FILTER = 'boostExclusionRule/SET_FILTER';
export const RESET_FILTER = 'boostExclusionRule/RESET_FILTER';

export const SET_CURRENT_PAGE = 'boostExclusionRule/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'boostExclusionRule/SET_PAGE_SIZE';

export function getBoostExclusionRules(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BOOST_EXCLUSION_RULE, FETCH_BOOST_EXCLUSION_RULE_SUCCESS, FETCH_BOOST_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: boostExclusionRuleListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.boostExclusionRuleList;
    },
  };
}

export function addBoostExclusionRule(boostExclusionRule) {
  return {
    types: [ADD_BOOST_EXCLUSION_RULE, ADD_BOOST_EXCLUSION_RULE_SUCCESS, ADD_BOOST_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boostExclusionRuleAddMutation,
        variables: boostExclusionRule,
      });

      return result.data.boostExclusionRuleAdd;
    },
  };
}

export function updateBoostExclusionRule(boostExclusionRule) {
  return {
    types: [UPDATE_BOOST_EXCLUSION_RULE, UPDATE_BOOST_EXCLUSION_RULE_SUCCESS, UPDATE_BOOST_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boostExclusionRuleUpdateMutation,
        variables: boostExclusionRule,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
