import React from 'react';
import PropTypes from 'prop-types';
import styles from './DataDisplay.scss';

const DataDisplay = ({ label, value }) => {
  let ValueComponent = null;

  // React doesn't allow to rendering component with small start case letter
  if (typeof value === 'function') {
    ValueComponent = value;
  }

  return (
    <div className={styles.dataDisplay}>
      <span className="label">{label}</span>
      <span className="value">{ValueComponent ? <ValueComponent /> : value}</span>
    </div>
  );
};
DataDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.func, // Stateless
  ]).isRequired,
};

export default DataDisplay;
