import gql from 'graphql-tag';

export const boltDrawPromotionAddMutation = gql`
  mutation boltDrawPromotionAdd(
    $status: String!
    $title: String!
    $description: String!
    $important: String!
    $visibleFrom: Date!
    $visibleTo: Date!
    $enterableFrom: Date!
    $enterableTo: Date!
    $winnerCount: Int!
    $bettingTimeLimitMin: Int!
    $bettingCountLimit: Int!
  ) {
    boltDrawPromotionAdd(
      status: $status
      title: $title
      description: $description
      important: $important
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      enterableFrom: $enterableFrom
      enterableTo: $enterableTo
      winnerCount: $winnerCount
      bettingTimeLimitMin: $bettingTimeLimitMin
      bettingCountLimit: $bettingCountLimit
    )
  }
`;

export const boltDrawPromotionUpdateMutation = gql`
  mutation boltDrawPromotionUpdate(
    $id: Int!
    $status: String
    $title: String
    $description: String
    $important: String
    $visibleFrom: Date
    $visibleTo: Date
    $enterableFrom: Date
    $enterableTo: Date
    $winnerCount: Int
    $bettingTimeLimitMin: Int
    $bettingCountLimit: Int
  ) {
    boltDrawPromotionUpdate(
      id: $id
      status: $status
      title: $title
      description: $description
      important: $important
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      enterableFrom: $enterableFrom
      enterableTo: $enterableTo
      winnerCount: $winnerCount
      bettingTimeLimitMin: $bettingTimeLimitMin
      bettingCountLimit: $bettingCountLimit
    )
  }
`;

export const boltDrawPromotionUploadImageMutation = gql`
  mutation boltDrawPromotionUploadImage($imageFile: FileUpload!) {
    boltDrawPromotionUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export const boltDrawPromotionSwapImagePriorityMutation = gql`
  mutation boltDrawPromotionSwapImagePriority($imageId: Int!, $imageIdToSwap: Int!) {
    boltDrawPromotionSwapImagePriority(imageId: $imageId, imageIdToSwap: $imageIdToSwap)
  }
`;

export const boltDrawPromotionAddImageMutation = gql`
  mutation boltDrawPromotionAddImage($boltDrawPromotionId: Int!, $url: String!, $type: String!) {
    boltDrawPromotionAddImage(boltDrawPromotionId: $boltDrawPromotionId, url: $url, type: $type)
  }
`;

export const boltDrawPromotionUpsertImageMutation = gql`
  mutation boltDrawPromotionUpsertImage($boltDrawPromotionId: Int!, $url: String!, $type: String!) {
    boltDrawPromotionUpsertImage(boltDrawPromotionId: $boltDrawPromotionId, url: $url, type: $type)
  }
`;

export const boltDrawPromotionDeleteImageMutation = gql`
  mutation boltDrawPromotionDeleteImage($id: Int!) {
    boltDrawPromotionDeleteImage(id: $id)
  }
`;
