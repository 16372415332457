/**
 * Get Editor Config with custom options
 * @param {Object} options
 * @param {Object} options.hooks Hooks
 */

export default function getEditorConfig(options) {
  const editorConfig = {
    initialEditType: 'wysiwyg',
    style: { marginBottom: 10 },
    hideModeSwitch: true,
    placeholder: 'Notice',
    height: 500,
    usageStatistics: false,
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'indent',
      'outdent',
      'divider',
      'table',
      'image',
      'link',
    ],
  };

  if (options.hooks) {
    editorConfig.hooks = options.hooks;
  }

  return editorConfig;
}
