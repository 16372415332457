import gql from 'graphql-tag';

export const businessCodeListQuery = gql`
  mutation selectCommonCodeInfoList($typeCode: String, $groupCode: String, $codeName: String) {
    selectCommonCodeInfoList(typeCode: $typeCode, groupCode: $groupCode, codeName: $codeName) {
      codeName
      codeId
    }
  }
`;

export default {
  businessCodeListQuery,
};
