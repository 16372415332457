import gql from 'graphql-tag';

export const merchantQuery = gql`
  query merchant($id: ID!) {
    merchant(id: $id) {
      id
      name
      groupName
      displayName
      shortName
      globalName
      countryCode
      totalTxVol
      totalBenefit
      share
      aov
      status
      contractStatus
      createdAt
      updatedAt
      vertical
      logoUrl
      website
      managerName
      managerEmail
      managerPhone
      businessNumber
      address
      representativeName
      customerServicePhone
      bankCode
      bankName
      bankAccountNum
      bankHolder
      contracts {
        id
        autoExtension
        type
        termStartAt
        termEndAt
        settlementCycle
        settlementProvideCycle
        updatedAt
        fee
        feeType
        includedVat
      }
      publicAPIKey
      privateAPIKey
      restrictedPid
      paymentLimits
      data
      pgMerchantCode
      linkMethod
      apiKeys {
        id
        type
        expireAt
      }
      settlementKey
      reserveAmount
      businessCategory
      businessType
      settlementEmail
      settlementSubEmail
      funnel
    }
  }
`;

export const existAdminIdsQuery = gql`
  query existAdminIds($adminIds: [String!]) {
    existAdminIds(adminIds: $adminIds)
  }
`;

export default {
  merchantQuery,
  existAdminIdsQuery,
};
