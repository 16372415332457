import React, { Component, Fragment } from 'react';
import { string, number, bool, func, objectOf, any } from 'prop-types';
import moment from 'moment';
import { Form, Divider, DatePicker, Select, Alert, Button, Input, InputNumber, Checkbox } from 'antd';
import autobind from 'autobind-decorator';
import 'ant-design-pro/dist/ant-design-pro.css';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import apolloClient from 'helpers/apolloClient';
import { formatDate } from 'utils/format';
import ContractDivider from './ContractDivider';
import { contractAddMutation, contractUpdateMutation } from './ContractMutations';
import rules from './ContactFormValidationRules';
import styles from './Contract.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const Description = props => <DescriptionList.Description {...props} className={styles.contractFormDescription} />;

@Form.create()
@autobind
class ContractForm extends Component {
  static propTypes = {
    merchantId: string.isRequired,
    switchMode: func.isRequired,
    form: objectOf(any),
    mode: string.isRequired,
    id: string,
    type: string,
    termStartAt: string,
    termEndAt: string,
    settlementCycle: number,
    settlementProvideCycle: string,
    autoExtension: string,
    fee: string,
    feeType: string,
    updatedAt: string,
    showDivider: bool,
    includedVat: bool,
    idx: number,
    onSubmit: func,
    onRemoveNewContract: func.isRequired,
  };

  static defaultProps = {
    form: {},
    id: '',
    type: '',
    termStartAt: '',
    termEndAt: '',
    settlementCycle: 14,
    settlementProvideCycle: 'one_day',
    autoExtension: '',
    fee: '',
    feeType: '',
    updatedAt: '',
    showDivider: false,
    includedVat: false,
    idx: 0,
    onSubmit: () => {},
  };

  state = {
    error: null,
  };

  input = {
    fee: null,
  };

  /**
   * Handle Form Submit
   * @param {Object} formFields
   */
  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['fee'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return this.setState({
            error: err[field].errors[0],
          });
        }
      }
    }

    this.setState({
      error: null,
    });

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    formFields.id = this.props.id;
    formFields.merchantId = this.props.merchantId;

    const [termStartAt, termEndAt] = formFields.contractTerm;

    formFields.termStartAt = termStartAt;
    formFields.termEndAt = termEndAt;

    if (this.props.mode === 'ADD') {
      return this.addContract(formFields);
    }

    return this.updateContract(formFields);
  }

  /**
   * Add New Contract
   * @async
   * @param {Object} formFields
   */
  async addContract(formFields) {
    await apolloClient.mutate({
      mutation: contractAddMutation,
      variables: formFields,
    });

    this.props.onSubmit();
  }

  /**
   * Update Contract with specified ID
   * @async
   * @param {Object} formFields
   */
  async updateContract(formFields) {
    await apolloClient.mutate({
      mutation: contractUpdateMutation,
      variables: formFields,
    });

    this.props.switchMode(this.props.id, 'DISPLAY');
    this.props.onSubmit();
  }

  switchToDisplay() {
    const { switchMode } = this.props;
    switchMode(this.props.id, 'DISPLAY');
  }

  render() {
    const { form, mode } = this.props;
    const { getFieldDecorator } = form;
    const { error } = this.state;
    const { showDivider, idx } = this.props;
    const { id, type, termStartAt, termEndAt } = this.props;
    const { settlementCycle, autoExtension, fee, feeType, updatedAt, settlementProvideCycle, includedVat } = this.props;
    const { onRemoveNewContract } = this.props;
    const contractId = `CT-${id}`;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.contractForm}>
        <DescriptionList size="large" title="Contract" style={{ marginBottom: 32 }}>
          {showDivider && <ContractDivider isActiveContract={idx === 0} number={idx + 1} />}

          {error && (
            <Fragment>
              <Alert message={error.message} type="warning" showIcon />
              <Divider />
            </Fragment>
          )}

          {mode === 'UPDATE' && <Description term="Contract ID">{contractId}</Description>}

          <Description term="Type">
            {getFieldDecorator('type', {
              initialValue: type || 'standard',
              rules: rules.type,
            })(
              <Select>
                <Option value="standard">Standard</Option>
              </Select>
            )}
          </Description>

          <Description term="Term">
            {getFieldDecorator('contractTerm', {
              initialValue: [moment(termStartAt || Date.now()), moment(termEndAt || moment().add('365', 'days'))],
              rules: rules.contractTerm,
            })(<RangePicker />)}
          </Description>

          <Description term="Settlement Cycle">
            {getFieldDecorator('settlementCycle', {
              initialValue: settlementCycle || 14,
              rules: rules.settlementCycle,
            })(<InputNumber ref={node => (this.input.settlementCycle = node)} />)}
          </Description>

          <Description term="Settlement Provide Cycle">
            {getFieldDecorator('settlementProvideCycle', {
              initialValue: settlementProvideCycle || 'one_day',
              rules: rules.settlementProvideCycle,
            })(
              <Select>
                <Option value="one_day">One Day</Option>
                <Option value="one_week">One Week</Option>
                <Option value="one_month">One Month</Option>
              </Select>
            )}
          </Description>

          <Description term="Auto Extention">
            {getFieldDecorator('autoExtension', {
              initialValue: autoExtension || 'one_year',
              rules: rules.autoExtension,
            })(
              <Select>
                <Option value="none">None</Option>
                <Option value="one_year">One Year</Option>
                <Option value="two_years">Two Years</Option>
                <Option value="three_years">Three Years</Option>
              </Select>
            )}
          </Description>

          <Description term="Fee">
            {getFieldDecorator('fee', {
              initialValue: fee || '1.5%',
              rules: rules.fee,
            })(<Input ref={node => (this.input.fee = node)} />)}
          </Description>
          {mode === 'ADD' && (
            <Description term="feeType">
              {getFieldDecorator('feeType', {
                initialValue: feeType || 'daily',
              })(
                <Select>
                  <Option value="daily">daily</Option>
                  <Option value="transaction">transaction</Option>
                </Select>
              )}
            </Description>
          )}
          {mode === 'ADD' && (
            <Description term="Included VAT">
              {getFieldDecorator('includedVat', {
                initialValue: includedVat,
              })(<Checkbox>Included VAT</Checkbox>)}
            </Description>
          )}

          {mode !== 'ADD' && <Description term="Last Update">{formatDate(updatedAt)}</Description>}
          {mode !== 'ADD' && <Description term="feeType">{feeType}</Description>}
          {mode !== 'ADD' && <Description term="Included VAT">{String(includedVat)}</Description>}

          <Divider style={{ backgroundColor: 'transparent' }} />

          <div className={styles.buttonsContainer}>
            {/* Create New Contract */}
            {mode === 'ADD' && (
              <Fragment>
                <Button icon="close" onClick={onRemoveNewContract}>
                  Cancel
                </Button>

                <Divider type="vertical" style={{ backgroundColor: 'white' }} />

                <Button icon="plus-circle" type="primary" htmlType="submit">
                  Create
                </Button>
              </Fragment>
            )}

            {/* Update Contract */}
            {mode === 'UPDATE' && (
              <Fragment>
                <Button icon="close" onClick={this.switchToDisplay}>
                  Cancel
                </Button>

                <Divider type="vertical" style={{ backgroundColor: 'white' }} />

                <Button icon="check" type="primary" htmlType="submit">
                  Save
                </Button>
              </Fragment>
            )}
          </div>
        </DescriptionList>
      </Form>
    );
  }
}

export default ContractForm;
