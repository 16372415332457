import PolicyContainer from './PolicyContainer';

export const PolicyType = {
  PG_FEE: 'pg_fee',
  PAYMENT: 'payment',
  CHARGE: 'charge',
  WITHDRAWAL: 'withdrawal',
};

export const PolicyTypeText = {
  [PolicyType.PG_FEE]: 'PG Fee',
  [PolicyType.PAYMENT]: 'Payment',
  [PolicyType.CHARGE]: 'Charge',
  [PolicyType.WITHDRAWAL]: ' Withdrawal',
};

export default PolicyContainer;
