export const rowProps = {
  type: 'flex',
  justify: 'center',
  gutter: 12,
};

export const col3Props = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 8,
};

export const col4Props = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 5,
};

export const col5Props = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: 8,
  xl: 4,
};

export const col2RightProps = {
  xs: 24,
  sm: 12,
  md: 12,
  lg: { span: 4, push: 6 },
};
