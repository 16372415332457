import gql from 'graphql-tag';

export const policyQuery = gql`
  query policy($id: ID!) {
    policy(id: $id) {
      id
      createdAt
      updatedAt
      status
      type
      description
      startAt
      endAt
      data {
        ... on PolicyPGFee {
          name
          type
          fee
        }
        ... on PolicyPayment {
          constraint
          minAge
          maxAge
          bank
          minAmount
          minUnit
          maxCount
          maxAmountOnce
          maxAmountDay
          maxAmountMonth
        }
        ... on PolicyCharge {
          minAmount
          minUnit
          maxCount
          maxAmountOnce
          maxAmountDay
          maxAmountMonth
          maxBalance
        }
        ... on PolicyWithdrawal {
          fee
          minAmount
          maxAmount
          maxCountPerDay
        }
      }
    }
  }
`;

export default {
  policyQuery,
};
