import { hasConsecutiveCharacters } from 'utils/validation';
import { REGEX_CONSECUTIVE_NUMBER, REGEX_REPEATED_CHAR } from 'constant';

export default {
  currentPassword: [
    {
      type: 'string',
      required: true,
      message: 'Please enter the current password.',
    },
  ],
  newPassword: [
    {
      type: 'string',
      required: true,
      message: 'Please enter the new password.',
    },
    {
      length: 8,
      message: 'New password at least eight characters long.',
    },
    {
      validator(rule, value, callback) {
        if (!value) {
          return callback();
        }

        if (REGEX_CONSECUTIVE_NUMBER.test(value)) {
          return callback(true);
        }

        return callback();
      },
      message: 'New pasword must not have 4 consecutive numbers or characters.',
    },
    {
      validator(rule, value, callback) {
        if (!value) {
          return callback();
        }

        if (hasConsecutiveCharacters(value)) {
          return callback(true);
        }

        return callback();
      },
      message: 'New pasword must not have 4 consecutive numbers or characters.',
    },
    {
      validator(rule, value, callback) {
        if (!value) {
          return callback();
        }

        if (REGEX_REPEATED_CHAR.test(value)) {
          return callback(true);
        }

        return callback();
      },
      message: 'New pasword must not have 4 repeated characters.',
    },
  ],
  newPasswordConfirm: [
    {
      type: 'string',
      required: true,
      message: 'Please enter the new password again.',
    },
  ],
};
