import React, { Component, Fragment } from 'react';
import { func, objectOf, arrayOf, instanceOf, bool, number, string, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider, Button, notification, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import config from 'config';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import {
  setPageSize,
  setCurrentPage,
  getB2BUsers,
  addB2BUser,
  updateB2BUser,
  updateB2BUserBalance,
} from 'redux/modules/b2bUser/actions';
import { getMerchants } from 'redux/modules/merchant/actions';

import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { isAdmin, isCustomerService, isB2BMerchant } from 'utils/permission';
import { commify, uncommify } from 'utils/stringUtil';
import schema from 'schema/dfUser';

import DFUserFilterForm from './DFUserFilterForm';

import { getColumns } from './DFUserContainerMetadata';

import styles from './DFUser.scss';
import DFUserAccountModal from './Modals/DFUserAccountModal';
import DFUserPointModal from './Modals/DFUserPointModal';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.b2bUser;
  const { activeKey, list } = state.tabs.dfUser;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getMerchants,
  getB2BUsers,
  addB2BUser,
  updateB2BUser,
  updateB2BUserBalance,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class DFUserContainer extends Component {
  static propTypes = {
    history: objectOf(any).isRequired,
    setPageSize: func.isRequired,
    setCurrentPage: func.isRequired,
    getMerchants: func.isRequired,
    getB2BUsers: func.isRequired,
    addB2BUser: func.isRequired,
    updateB2BUser: func.isRequired,
    updateB2BUserBalance: func.isRequired,
    addTab: func.isRequired,
    data: arrayOf(any).isRequired, // B2B User List
    error: instanceOf(Error),
    loading: bool.isRequired,
    focusTab: func.isRequired,
    currentPage: number.isRequired,
    totalCount: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    activeKey: string.isRequired,
    list: arrayOf(any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  state = {
    dfUserAccountModalVisible: false,
    dfUserPointModalVisible: false,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('dfUser', listTab);
    }

    this.props.focusTab('dfUser', 'ListTab');

    if (isAdmin()) {
      // admin - get merchant list
      this.props.getMerchants(1, 100);
    }

    if (isAdmin() || isB2BMerchant()) {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getB2BUsers(currentPage, pageSize, filter);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey ||
      this.state.dfUserAccountModalVisible !== nextState.dfUserAccountModalVisible ||
      this.state.dfUserPointModalVisible !== nextState.dfUserPointModalVisible
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change
  editFormData = {};

  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/dfuser') {
        const { currentPage, pageSize, filter } = this.props;

        if (isAdmin() || isB2BMerchant()) {
          this.props.getB2BUsers(currentPage, pageSize, filter);
          this.props.focusTab('dfUser', 'ListTab');
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/dfuser') {
        this.props.focusTab('dfUser', 'ListTab');
      }
    }
  }

  updatePageSize(menuInfo) {
    if (isCustomerService() && !this.props.filter) return;
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getB2BUsers(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    if (!(isAdmin() || isB2BMerchant()) && !this.props.filter) return;

    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getB2BUsers(newPage, pageSize, filter);
  }

  refresh() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getB2BUsers(currentPage, pageSize, filter);
  }

  downloadCSV() {
    window.open(`${config.apiScheme}://${config.graphUrl}/b2busers`);
  }

  handleCellClick(record) {
    this.editFormData = record;
  }

  openDFUserAccountModal() {
    this.setState({ dfUserAccountModalVisible: true });
  }

  openDFUserPointModal() {
    this.setState({ dfUserPointModalVisible: true });
  }

  modalOnCancel = () => {
    this.editFormData = {};
    this.setState({
      dfUserAccountModalVisible: false,
      dfUserPointModalVisible: false,
    });
  };

  getFormattedData(rawData) {
    const { id, loginId, password, user, data, depositCode, transactionKey } = rawData;
    const { permittedMerchantUserIds } = data || {};
    const { status, ledger } = user || {};
    const { cash } = ledger || {};

    return {
      id,
      loginId,
      password,
      status: status || 'inactive',
      balance: commify(cash || 0),
      depositCode,
      transactionKey,
      permittedMerchantUserIds: (permittedMerchantUserIds || []).join('\n'),
    };
  }

  getAmount(amount) {
    return amount && Number(uncommify(amount));
  }

  getConvertedData(data) {
    // User
    // Merchant User Ids
    const permittedMerchantUserIds =
      data.permittedMerchantUserIds &&
      data.permittedMerchantUserIds
        .split(/[\s]*,[\s]*|[\s]+/)
        .filter(Boolean)
        .map(i => i.toLowerCase());

    // User Balance
    // amount (입금/회수할 포인트)
    const isWithdraw = data.type === 'withdraw';
    const amount = this.getAmount(data.amount);

    // balance (User의 현재 balance)
    const balance = this.getAmount(data.balnace);

    // transaction amount (입금 금액)
    const remittanceAmount = data.remittanceAmount && uncommify(data.remittanceAmount);

    // exchange rate (환율)
    const exchangeRate = data.exchangeRate && uncommify(data.exchangeRate);

    // transaction user name (입금자명)
    const name = data.name && data.name.trim().replace(/\s+/g, ' ');

    return {
      ...data,
      balance,
      remittanceAmount,
      name,
      exchangeRate,
      permittedMerchantUserIds,
      amount: amount && (isWithdraw ? -amount : amount),
    };
  }

  async saveUser(data) {
    const { id } = this.editFormData;
    const formData = this.getConvertedData({ id, ...data });

    try {
      if (formData.id) {
        // Update User
        await this.props.updateB2BUser(formData);
      } else {
        // Add User
        await this.props.addB2BUser(formData);
      }

      this.setState({ dfUserAccountModalVisible: false });
      this.refresh();

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Success',
      });
    } catch (e) {
      console.log(e);
    }
  }

  async updateUserBalance(data) {
    const { id } = this.editFormData;
    const formData = this.getConvertedData({ id, ...data });

    try {
      await this.props.updateB2BUserBalance(formData);

      this.setState({ dfUserPointModalVisible: false });
      this.refresh();

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Success',
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Get List Tab
   * @return {ReactElement}
   */
  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading, filter } = this.props;
    const shouldRenderDataViewer = isAdmin() || isB2BMerchant() || (isCustomerService() && filter);
    const formattedData = data.map(d => this.getFormattedData(d));

    const columns = getColumns({
      isAdmin: isAdmin(),
      openDFUserAccountModal: this.openDFUserAccountModal,
      openDFUserPointModal: this.openDFUserPointModal,
    });

    return (
      <Fragment>
        <DFUserFilterForm />

        <Divider />

        {/* Add User Button */}
        <div className={styles.top}>
          <Row type="flex" gutter={12}>
            <Col>
              <Button type="primary" icon="plus-circle" onClick={this.openDFUserAccountModal}>
                Add User
              </Button>
            </Col>
            <Col>
              <Button type="button" icon="download" onClick={this.downloadCSV}>
                Download
              </Button>
            </Col>
          </Row>
        </div>

        {shouldRenderDataViewer && (
          <DataViewer
            columns={columns}
            data={formattedData}
            error={error}
            loading={loading}
            onCellClick={this.handleCellClick}
          />
        )}

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          showTotal={this.getTotalText}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          <Divider type="vertical" style={{ background: 'none' }} />

          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div>
        <TabViewer tabKey="dfUser" components={{ ListTab: this.getListTab() }} />
        {/* Add or Update User Modal */}
        <DFUserAccountModal
          visible={this.state.dfUserAccountModalVisible}
          title={this.editFormData.id ? 'User Info' : 'Add User'}
          onCancel={this.modalOnCancel}
          destroyOnClose
          schema={this.editFormData.id ? schema.editSchema : schema.addSchema}
          uiSchema={this.editFormData.id ? schema.editUiSchema : schema.addUiSchema}
          formData={this.editFormData}
          handFormSubmit={this.saveUser}
        />

        {/* Update Point Modal */}
        <DFUserPointModal
          visible={this.state.dfUserPointModalVisible}
          title="Point Info"
          onCancel={this.modalOnCancel}
          destroyOnClose
          schema={schema.editPointSchema}
          uiSchema={schema.editPointUiSchema}
          formData={this.editFormData}
          handFormSubmit={this.updateUserBalance}
        />
      </div>
    );
  }
}

export default DFUserContainer;
