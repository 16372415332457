import gql from 'graphql-tag';

export const updatePasswordMutation = gql`
  mutation updatePassword($password: String!, $newPassword: String!) {
    updatePassword(password: $password, newPassword: $newPassword)
  }
`;

export const registerOTPMutation = gql`
  mutation registerOTP($confirmCode: String!) {
    registerOTP(confirmCode: $confirmCode)
  }
`;

export const requestPhoneOTPMutation = gql`
  mutation requestPhoneOTP {
    requestPhoneOTP
  }
`;

export const unRegisterOTPMutation = gql`
  mutation unRegisterOTP($confirmCode: String!) {
    unRegisterOTP(confirmCode: $confirmCode)
  }
`;

export default {
  updatePasswordMutation,
  registerOTPMutation,
  requestPhoneOTPMutation,
  unRegisterOTPMutation,
};
