import React from 'react';
import PropTypes from 'prop-types';

const PageDisplay = ({ currentPage, totalCount, pageSize }) => (
  <div style={{ position: 'absolute', bottom: 25, left: 20, fontSize: 12, color: '#bbb' }}>
    <span>
      {currentPage} / {parseInt((totalCount - 1) / pageSize, 10) + 1}
    </span>
  </div>
);

PageDisplay.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

export default PageDisplay;
