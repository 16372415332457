import { Button, Col, DatePicker, Divider, Form, Input, message, Pagination, Row } from 'antd';
import autobind from 'autobind-decorator';
import DataViewer from 'components/DataViewer';
import PageDisplay from 'components/PageDisplay';
import PageSizeDropdown from 'components/PageSizeDropdown';
import { push } from 'connected-react-router';
import { TabData } from 'containers/Layout';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  addCopyItem,
  clearCopyItem,
  copyBoosts,
  getBoostPromotionList,
  removeCopyItem,
  setCurrentPage,
  setPageSize,
} from 'redux/modules/boostPromotion/actions';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { isAdmin } from 'utils/permission';
import BoostPromotionFilterForm from './BoostPromotionFilterForm';
import { getColumns, getCopyColumns } from './BoostPromotionMetadata';

const mapStateToProps = state => {
  const {
    loading,
    copyLoading,
    error,
    data,
    currentPage,
    totalCount,
    pageSize,
    filter,
    copyItems,
  } = state.boostPromotion;

  return {
    data,
    error,
    loading,
    copyLoading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    copyItems,
  };
};

const mapDispatchToProps = {
  addTab,
  push,
  focusTab,
  moveTab,
  getBoostPromotionList,
  setPageSize,
  setCurrentPage,
  addCopyItem,
  removeCopyItem,
  copyBoosts,
  clearCopyItem,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoostPromotionList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool.isRequired,
    copyLoading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    getBoostPromotionList: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,

    addCopyItem: PropTypes.func.isRequired,
    removeCopyItem: PropTypes.func.isRequired,
    copyBoosts: PropTypes.func.isRequired,
    clearCopyItem: PropTypes.func.isRequired,

    copyItems: PropTypes.arrayOf(PropTypes.any).isRequired,

    openDataTab: PropTypes.func.isRequired,

    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  copyColumnIdx = 1;

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getBoostPromotionList(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getBoostPromotionList(newPage, pageSize, filter);
  }

  createNewBoostPromotion() {
    const newTab = new TabData({
      key: 'add',
      title: 'New Boost Policy',
      closable: true,
      data: {
        id: 'add',
      },
      componentType: 'BoostPromotionForm',
    });

    this.props.addTab('boostPromotion', newTab);
    this.props.focusTab('boostPromotion', newTab.key);
    this.props.push('/boost/policy/add');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  handleCellClick(record, extra) {
    const { id, title } = record;
    const { index } = extra;

    if (index === 0) {
      this.props.openDataTab(id, { ...extra, title });
    }
  }

  async boostCopySubmit() {
    const { copyItems, form } = this.props;
    if (copyItems.length === 0) {
      return;
    }
    await this.props.copyBoosts(
      copyItems.map(row => Number(row.id)),
      {
        targetDate: form.getFieldValue('boostCopyDate'),
        replaceTextFrom: form.getFieldValue('boostCopyTextFrom'),
        replaceTextTo: form.getFieldValue('boostCopyTextTo'),
      }
    );
    message.success('Copy Success');
    this.props.clearCopyItem();

    const { currentPage, filter, pageSize } = this.props;
    // refresh list
    this.props.getBoostPromotionList(currentPage, pageSize, filter);
  }

  render() {
    const { totalCount, pageSize, currentPage, data, error, loading, copyItems, copyLoading, form } = this.props;
    const { getFieldDecorator } = form;

    // 비 어드민 유저
    if (!isAdmin()) {
      return (
        <>
          <BoostPromotionFilterForm />
          <Divider />
          <DataViewer
            columns={getColumns(this.props.addCopyItem, isAdmin())}
            data={data}
            error={error}
            loading={loading}
            onCellClick={this.handleCellClick}
          />

          <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

          <Pagination
            total={totalCount}
            pageSize={pageSize}
            defaultCurrent={1}
            current={currentPage}
            onChange={this.changePage}
            style={{
              width: '100%',
              textAlign: 'center',
              marginBottom: 20,
            }}
          />
          <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
            {/* Dropdown for change page size */}
            <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
          </div>
        </>
      );
    }

    return (
      <>
        <BoostPromotionFilterForm />
        <Divider />

        {/* Add User Button */}
        <Row type="flex" gutter={12}>
          <Col span={19}>
            <Button icon="plus-circle" type="primary" onClick={this.createNewBoostPromotion}>
              New
            </Button>
          </Col>
          <Col span={5}>
            <Form>
              <Row>
                <Button type="primary" onClick={this.boostCopySubmit} loading={copyLoading} disabled={copyLoading}>
                  Copy
                </Button>
              </Row>
              <Row>
                <Form.Item labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }} label="Target Date">
                  {getFieldDecorator('boostCopyDate', {
                    initialValue: moment()
                      .startOf('day')
                      .add(1, 'days'),
                  })(
                    <DatePicker
                      disabledDate={current =>
                        current <
                        moment()
                          .subtract(1, 'day')
                          .endOf('day')
                      }
                      showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                      format="YYYY-MM-DD HH:mm:ss"
                      style={{ minWidth: 0 }}
                    />
                  )}
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ sm: 8 }} wrapperCol={{ sm: 16 }} label="Title replace">
                  {getFieldDecorator('boostCopyTextFrom', {
                    initialValue: '',
                  })(<Input placeholder="From" />)}
                  {getFieldDecorator('boostCopyTextTo', {
                    initialValue: '',
                  })(<Input placeholder="Replace" />)}
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={19}>
            <DataViewer
              columns={getColumns(this.props.addCopyItem, isAdmin())}
              data={data}
              error={error}
              loading={loading}
              onCellClick={this.handleCellClick}
            />

            <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

            <Pagination
              total={totalCount}
              pageSize={pageSize}
              defaultCurrent={1}
              current={currentPage}
              onChange={this.changePage}
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: 20,
              }}
            />
            <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
              {/* Dropdown for change page size */}
              <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
            </div>
          </Col>
          <Col span={5}>
            <DataViewer
              rowKey="rowKey"
              columns={getCopyColumns(this.props.removeCopyItem)}
              data={copyItems}
              error={error}
              loading={loading}
              onCellClick={this.handleCellClick}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default BoostPromotionList;
