import apolloClient from 'helpers/apolloClient';
import { settlementUpdateMutation } from './mutations';
import { settlementListQuery } from './queries';

export const FETCH_SETTLEMENT = 'settlement/FETCH';
export const FETCH_SETTLEMENT_SUCCESS = 'settlement/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_FAILURE = 'settlement/FETCH_FAILURE';

export const UPDATE_SETTLEMENT = 'settlement/UPDATE';
export const UPDATE_SETTLEMENT_SUCCESS = 'settlement/UPDATE_SUCCESS';
export const UPDATE_SETTLEMENT_FAILURE = 'settlement/UPDATE_FAILURE';

export const SET_FILTER = 'settlement/SET_FILTER';
export const RESET_FILTER = 'settlement/RESET_FILTER';

export const SET_CURRENT_PAGE = 'settlement/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'settlement/SET_PAGE_SIZE';

export function getSettlements(filter) {
  return {
    types: [FETCH_SETTLEMENT, FETCH_SETTLEMENT_SUCCESS, FETCH_SETTLEMENT_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: settlementListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
        },
      });
      return result.data.settlementList;
    },
  };
}

export function updateSettlement(merchantId) {
  return {
    types: [UPDATE_SETTLEMENT, UPDATE_SETTLEMENT_SUCCESS, UPDATE_SETTLEMENT_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: settlementUpdateMutation,
        variables: {
          merchantId,
        },
      });

      return merchantId;
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
