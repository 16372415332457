import React, { Component } from 'react';
import { func, number, string, bool, objectOf, any } from 'prop-types';
import { Modal, Form, InputNumber, Row, Col, Alert } from 'antd';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import get from 'lodash/get';
import { commify } from 'utils/stringUtil';
import styles from './PromotionSaveConfirmModal.scss';

@Form.create()
@autobind
class PromotionSaveConfirmModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    visible: bool.isRequired,
    discount: number,
    discountType: string.isRequired,
    startAt: string,
    priceMin: number,
    priceMax: number,
    onOk: func.isRequired,
    onCancel: func.isRequired,
  };

  static defaultProps = {
    startAt: '',
    priceMin: 0,
    priceMax: 0,
    discount: 0,
  };

  input = {
    discountRate: null,
  };

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['discountRate'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    await this.props.onOk();
    this.cleanupBeforeClose();
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();
  }

  getDiscountRate() {
    const { priceMin, discount, discountType } = this.props;

    if (!priceMin) {
      return 0;
    }

    if (discountType === 'percent') {
      return discount;
    }

    return Math.floor((discount / priceMin) * 100);
  }

  render() {
    const { form, visible, discount, startAt, priceMin, priceMax } = this.props;
    const { getFieldDecorator, getFieldError } = form;
    const discountRate = this.getDiscountRate();
    const leftSize = 9;
    const rightSize = 15;
    const discountRateError = get(getFieldError('discountRate'), '[0]', null);

    return (
      <Modal
        title="Save Promotion"
        onOk={this.handleSubmit}
        onCancel={this.cleanupBeforeClose}
        visible={visible}
        okText="Save"
        cancelText="Cancel"
      >
        <Form className={styles.form} onSubmit={this.handleSubmit}>
          <Row>
            <Col span={leftSize}>
              <span className={styles.label}>StartAt</span>
            </Col>
            <Col span={rightSize} className={styles.redText}>
              {startAt}
            </Col>
          </Row>
          <Row>
            <Col span={leftSize}>
              <span className={styles.label}>Min. Price</span>
            </Col>
            <Col span={rightSize}>{commify(priceMin) || 'Not set'}</Col>
          </Row>
          <Row>
            <Col span={leftSize}>
              <span className={styles.label}>Max. Price</span>
            </Col>
            <Col span={rightSize}>{commify(priceMax) || 'Not set'}</Col>
          </Row>
          <Row>
            <Col span={leftSize}>
              <span className={styles.label}>Discount Amount</span>
            </Col>
            <Col span={rightSize}>{commify(discount)}</Col>
          </Row>

          <Row>
            <Col span={leftSize}>
              <span className={styles.label}>Discount Rate</span>
            </Col>
            <Col span={rightSize} className={styles.redText}>
              {discountRate}%
            </Col>
          </Row>

          <Alert message="To save, please enter same discount rate below." type="info" showIcon />

          <Row>
            <Col span={leftSize}>
              <span className={cx(styles.label, styles.discountRateLabel)}>Confirm Discount Rate</span>
            </Col>
            <Col span={rightSize}>
              <div>
                {getFieldDecorator('discountRate', {
                  rules: [
                    {
                      required: true,
                      message: 'Please enter discount rate.',
                    },
                    {
                      validator: (rule, value, callback) => {
                        if (value !== this.getDiscountRate()) {
                          return callback(true);
                        }

                        return callback();
                      },
                      message: 'Discount Rate is not matched',
                    },
                  ],
                })(
                  <InputNumber
                    className={discountRateError && styles.inputError}
                    ref={node => (this.input.discountRate = node)}
                  />
                )}
                <span style={{ marginLeft: 10 }}>%</span>
              </div>
              {discountRateError && <div className={styles.error}>{discountRateError}</div>}
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default PromotionSaveConfirmModal;
