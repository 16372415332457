import gql from 'graphql-tag';

export const merchantSettlementKeyQuery = gql`
  query merchant($id: ID!) {
    merchant(id: $id) {
      settlementKey
      displayName
    }
  }
`;

export const settlementWithBankInfoQuery = gql`
  query settlementWithBankInfo($date: Date!) {
    settlementWithBankInfo(date: $date) {
      merchantId
      bankName
      bankAccount
      bankHolder
      amount
      feeAndTaxAmount
      displayName
      reserveAmount
      balance
    }
  }
`;

export const getTaxInvoiceAllQuery = gql`
  query getTaxInvoice($date: Date!) {
    getTaxInvoice(date: $date) {
      feeAmount
      feeTaxAmount
      endOfMonth
      name
      businessNumber
      businessCategory
      businessType
      representativeName
      address
      settlementEmail
      settlementSubEmail
    }
  }
`;

export default {
  merchantSettlementKeyQuery,
  settlementWithBankInfoQuery,
  getTaxInvoiceAllQuery,
};
