export default {
  $id: 'dfuser-add-schema',
  title: 'dfuser-add-schema',
  description: 'dfuser-add-schema.',
  type: 'object',
  required: ['loginId', 'password', 'merchantId'],
  properties: {
    id: {
      type: 'string',
    },
    loginId: {
      type: 'string',
      title: 'User ID',
      maxLength: 20,
      minLength: 12,
    },
    password: {
      type: 'string',
      title: 'Password',
      maxLength: 20,
      minLength: 12,
    },
    permittedMerchantUserIds: {
      type: 'string',
      title: 'Merchant User ID',
    },
  },
};
