import gql from 'graphql-tag';

export const exchangePropertyRuleListQuery = gql`
  query exchangePropertyRuleList($skip: Int, $pageSize: Int, $filter: String) {
    exchangePropertyRuleList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        ruleCodeType
        code
      }
      total
    }
  }
`;

export const exchangePropertyRuleQuery = gql`
  query exchangePropertyRule($id: ID!) {
    exchangePropertyRule(id: $id) {
      id
      ruleCodeType
      code
    }
  }
`;

export default {
  exchangePropertyRuleQuery,
  exchangePropertyRuleListQuery,
};
