import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Form, Alert, Divider, Input, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { getGroupMerchant } from 'redux/modules/groupMerchant/actions';
import { getBusinessCode } from 'redux/modules/businessCode/actions';
import ListSelect from 'components/ListSelect';
import uniqBy from 'lodash/uniqBy';
import { BoostArrayForm } from '../Forms';

import { formItemLayout } from '../Forms/layout';
import styles from './BoostPromotion.scss';

export const MERCHANT_ID_PREFIX = {
  cardGroupMerchant: 'CDGM',
  cardBusinessCode: 'CDBC',
  cardMerchantName: 'CDMN',
};

const mapStateToProps = state => {
  const { data: groupMerchantData, error: groupMerchantError, loading: groupMerchantLoading } = state.groupMerchant;
  const { data: businessCodeData, error: businessCodeError, loading: businessCodeLoading } = state.businessCode;

  return {
    businessCodeData,
    businessCodeError,
    businessCodeLoading,
    groupMerchantData,
    groupMerchantError,
    groupMerchantLoading,
  };
};

const mapDispatchToProps = {
  getGroupMerchant,
  getBusinessCode,
};

@connect(mapStateToProps, mapDispatchToProps)
class BoostPromotionMerchantForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    businessCodeData: PropTypes.arrayOf(PropTypes.any).isRequired,
    businessCodeError: PropTypes.string,
    businessCodeLoading: PropTypes.bool.isRequired,
    groupMerchantData: PropTypes.arrayOf(PropTypes.any).isRequired,
    groupMerchantError: PropTypes.string,
    groupMerchantLoading: PropTypes.bool.isRequired,
    getGroupMerchant: PropTypes.func.isRequired,
    getBusinessCode: PropTypes.func.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
  };

  static defaultProps = {
    businessCodeError: null,
    groupMerchantError: null,
  };

  state = {
    merchantFilter: '',
    businessCodeFilter: '',
  };

  componentDidMount() {
    const { groupMerchantLoading, groupMerchantData, businessCodeLoading, businessCodeData } = this.props;

    if (!groupMerchantLoading && !groupMerchantData.length) {
      this.props.getGroupMerchant();
    }
    if (!businessCodeLoading && !businessCodeData.length) {
      this.props.getBusinessCode();
    }
  }

  handleSelectMerchant(selectedMerchants) {
    const { form, name } = this.props;
    const targetMerchantIds = form.getFieldValue(name);
    const otherIds = targetMerchantIds.filter(
      merchantId => typeof merchantId === 'string' && !merchantId.startsWith(MERCHANT_ID_PREFIX.cardGroupMerchant)
    );

    form.setFieldsValue({
      [name]: selectedMerchants.concat(otherIds).sort(),
    });
  }

  handleSelectBusinessCode(selectedBusinessCodes) {
    const { form, name } = this.props;
    const targetMerchantIds = form.getFieldValue(name);
    const otherIds = targetMerchantIds.filter(
      merchantId => typeof merchantId === 'string' && !merchantId.startsWith(MERCHANT_ID_PREFIX.cardBusinessCode)
    );

    form.setFieldsValue({
      [name]: selectedBusinessCodes.concat(otherIds).sort(),
    });
  }

  handleSelectcardMerchantNames() {
    const { form, name } = this.props;
    const targetMerchantIds = form.getFieldValue(name);
    const otherIds = targetMerchantIds.filter(
      merchantId => typeof merchantId === 'string' && !merchantId.startsWith(MERCHANT_ID_PREFIX.cardMerchantName)
    );
    const merchantNames = form.getFieldValue('cardMerchantNames');

    form.setFieldsValue({
      [name]: merchantNames
        .map(merchant => `${MERCHANT_ID_PREFIX.cardMerchantName}${merchant.val}`)
        .concat(otherIds)
        .sort(),
    });
  }

  updateMerchantFilter(e) {
    this.setState({ merchantFilter: e.target.value });
  }

  updateBusinessCodeFilter(e) {
    this.setState({ businessCodeFilter: e.target.value });
  }

  filteredMerchants(selectedOnly) {
    const { groupMerchantData, form, name } = this.props;
    const { merchantFilter } = this.state;

    const dataMap = uniqBy(groupMerchantData, 'groupMerchantNo').map(merchant => ({
      name: merchant.groupMerchantName.replace(/\[.*\]/g, ''),
      value: `CDGM${merchant.groupMerchantNo}`,
    }));

    if (selectedOnly) {
      const selectedIds = form.getFieldValue(name);
      return dataMap.filter(merchant => selectedIds.includes(merchant.value));
    }

    if (!merchantFilter) {
      return dataMap;
    }

    return dataMap.filter(merchant => merchant.name.indexOf(merchantFilter) >= 0);
  }

  filteredBusinessCodes(selectedOnly) {
    const { businessCodeData, form, name } = this.props;
    const { businessCodeFilter } = this.state;

    const dataMap = businessCodeData.map(businessCode => ({
      name: `${businessCode.codeName} (${businessCode.codeId})`,
      value: `${MERCHANT_ID_PREFIX.cardBusinessCode}${businessCode.codeId}`,
    }));

    if (selectedOnly) {
      const selectedIds = form.getFieldValue(name);
      return dataMap.filter(merchant => selectedIds.includes(merchant.value));
    }

    if (!businessCodeFilter) {
      return dataMap;
    }

    return dataMap.filter(businessCode => businessCode.name.indexOf(businessCodeFilter) >= 0);
  }

  render() {
    const { form } = this.props;
    const {
      groupMerchantError,
      groupMerchantLoading,
      businessCodeError,
      businessCodeLoading,
      initial,
      name,
    } = this.props;

    form.getFieldDecorator(name, { initialValue: initial });

    const targetMerchantIds = form.getFieldValue(name);
    const cardMerchantNames = targetMerchantIds
      ? targetMerchantIds
        .filter(merchantId => typeof merchantId === 'string')
        .filter(merchantId => merchantId.startsWith(MERCHANT_ID_PREFIX.cardMerchantName))
        .map(merchantName => ({ val: merchantName.substr(MERCHANT_ID_PREFIX.cardMerchantName.length) }))
      : [];

    const selectedMerchants = targetMerchantIds.filter(
      merchantId => typeof merchantId === 'string' && merchantId.startsWith(MERCHANT_ID_PREFIX.cardGroupMerchant)
    );

    const selectedBusinessCodes = targetMerchantIds.filter(
      merchantId => typeof merchantId === 'string' && merchantId.startsWith(MERCHANT_ID_PREFIX.cardBusinessCode)
    );

    let hasError = null;
    if (form.getFieldError(name)) {
      hasError = new Error('Merchant must be selected.');
      this.search.input.scrollIntoView({
        behavior: 'smooth',
      });
    }

    return (
      <>
        {groupMerchantError && (
          <>
            <Alert message="Oops!" description="Failed to load Merchants." type="warning" showIcon />

            <Divider />

            <Button icon="redo" onClick={() => this.props.getGroupMerchant()}>
              Try Again
            </Button>
          </>
        )}
        {!groupMerchantError && (
          <Form.Item label="Target Group Merchants" {...formItemLayout} style={{ marginBottom: 20 }}>
            <Row gutter={4}>
              <Col span={22}>
                <Form.Item>
                  <Input
                    placeholder="Search Keyword"
                    ref={node => (this.search = node)}
                    suffix={<Icon type="search" />}
                    onChange={e => this.updateMerchantFilter(e)}
                    onPressEnter={e => e.preventDefault()}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button icon="redo" block onClick={() => this.props.getGroupMerchant()} />
              </Col>
            </Row>
            <Form.Item label="Selected Merchants">
              {groupMerchantLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                error={hasError}
                items={this.filteredMerchants(true)}
                onClick={e => this.handleSelectMerchant(e)}
                value={selectedMerchants}
                multiSelect
                small
              />
            </Form.Item>
            <Form.Item label="Merchant List">
              {groupMerchantLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                error={hasError}
                items={this.filteredMerchants()}
                onClick={e => this.handleSelectMerchant(e)}
                value={selectedMerchants}
                multiSelect
              />
            </Form.Item>
          </Form.Item>
        )}
        {businessCodeError && (
          <>
            <Alert message="Oops!" description="Failed to load BusinessCode." type="warning" showIcon />

            <Divider />

            <Button icon="redo" onClick={() => this.props.getBusinessCode()}>
              Try Again
            </Button>
          </>
        )}
        {!businessCodeError && (
          <Form.Item label="Target Business Code" {...formItemLayout} style={{ marginBottom: 20 }}>
            <Row gutter={4}>
              <Col span={22}>
                <Form.Item>
                  <Input
                    placeholder="Search Keyword"
                    suffix={<Icon type="search" />}
                    onPressEnter={e => e.preventDefault()}
                    onChange={e => this.updateBusinessCodeFilter(e)}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Button icon="redo" block onClick={() => this.props.getBusinessCode()} />
              </Col>
            </Row>
            <Form.Item label="Selected Business Code">
              {businessCodeLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                items={this.filteredBusinessCodes(true)}
                onClick={e => this.handleSelectBusinessCode(e)}
                value={selectedBusinessCodes}
                multiSelect
                small
              />
            </Form.Item>
            <Form.Item label="Business Code List">
              {businessCodeLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
              <ListSelect
                items={this.filteredBusinessCodes()}
                onClick={e => this.handleSelectBusinessCode(e)}
                value={selectedBusinessCodes}
                multiSelect
              />
            </Form.Item>
          </Form.Item>
        )}
        <BoostArrayForm
          form={form}
          name="cardMerchantNames"
          label="Target Merchant Names"
          initial={cardMerchantNames}
          adder={[{ key: 'val', title: 'Merchant Names', label: '' }]}
          onChange={() => this.handleSelectcardMerchantNames()}
        />
      </>
    );
  }
}

export default BoostPromotionMerchantForm;
