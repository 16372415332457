import apolloClient from 'helpers/apolloClient';
import { boltExclusionRuleListQuery } from './queries';
import { boltExclusionRuleAddMutation, boltExclusionRuleUpdateMutation } from './mutations';

export const FETCH_BOLT_EXCLUSION_RULE = 'boltExclusionRule/FETCH';
export const FETCH_BOLT_EXCLUSION_RULE_SUCCESS = 'boltExclusionRule/FETCH_SUCCESS';
export const FETCH_BOLT_EXCLUSION_RULE_FAILURE = 'boltExclusionRule/FETCH_FAILURE';

export const ADD_BOLT_EXCLUSION_RULE = 'boltExclusionRule/ADD';
export const ADD_BOLT_EXCLUSION_RULE_SUCCESS = 'boltExclusionRule/ADD_SUCCESS';
export const ADD_BOLT_EXCLUSION_RULE_FAILURE = 'boltExclusionRule/ADD_FAILURE';

export const UPDATE_BOLT_EXCLUSION_RULE = 'boltExclusionRule/UPDATE';
export const UPDATE_BOLT_EXCLUSION_RULE_SUCCESS = 'boltExclusionRule/UPDATE_SUCCESS';
export const UPDATE_BOLT_EXCLUSION_RULE_FAILURE = 'boltExclusionRule/UPDATE_FAILURE';

export const SET_FILTER = 'boltExclusionRule/SET_FILTER';
export const RESET_FILTER = 'boltExclusionRule/RESET_FILTER';

export const SET_CURRENT_PAGE = 'boltExclusionRule/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'boltExclusionRule/SET_PAGE_SIZE';

export function getBoltExclusionRules(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BOLT_EXCLUSION_RULE, FETCH_BOLT_EXCLUSION_RULE_SUCCESS, FETCH_BOLT_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: boltExclusionRuleListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.boltExclusionRuleList;
    },
  };
}

export function addBoltExclusionRule(boltExclusionRule) {
  return {
    types: [ADD_BOLT_EXCLUSION_RULE, ADD_BOLT_EXCLUSION_RULE_SUCCESS, ADD_BOLT_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boltExclusionRuleAddMutation,
        variables: boltExclusionRule,
      });

      return result.data.boltExclusionRuleAdd;
    },
  };
}

export function updateBoltExclusionRule(boltExclusionRule) {
  return {
    types: [UPDATE_BOLT_EXCLUSION_RULE, UPDATE_BOLT_EXCLUSION_RULE_SUCCESS, UPDATE_BOLT_EXCLUSION_RULE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boltExclusionRuleUpdateMutation,
        variables: boltExclusionRule,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
