const rules = {
  id: [
    {
      required: true,
      message: 'Please enter the ID.',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please enter the password.',
    },
  ],
  code: [
    {
      required: true,
      message: 'Please enter the pin number.',
    },
  ],
};

export default rules;
