import gql from 'graphql-tag';

export const couponUpdateMutation = gql`
  mutation couponUpdate($id: ID!, $status: String) {
    couponUpdate(id: $id, status: $status)
  }
`;

export default {
  couponUpdateMutation,
};
