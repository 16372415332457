import apolloClient from 'helpers/apolloClient';
import { merchantListQuery } from './queries';
import { merchantAddMutation, merchantUpdateMutation } from './mutations';

export const FETCH_MERCHANT = 'merchant/FETCH';
export const FETCH_MERCHANT_SUCCESS = 'merchant/FETCH_SUCCESS';
export const FETCH_MERCHANT_FAILURE = 'merchant/FETCH_FAILURE';

export const ADD_MERCHANT = 'merchant/ADD';
export const ADD_MERCHANT_SUCCESS = 'merchant/ADD_SUCCESS';
export const ADD_MERCHANT_FAILURE = 'merchant/ADD_FAILURE';

export const UPDATE_MERCHANT = 'merchant/UPDATE';
export const UPDATE_MERCHANT_SUCCESS = 'merchant/UPDATE_SUCCESS';
export const UPDATE_MERCHANT_FAILURE = 'merchant/UPDATE_FAILURE';

export const SET_FILTER = 'merchant/SET_FILTER';
export const RESET_FILTER = 'merchant/RESET_FILTER';

export const SET_CURRENT_PAGE = 'merchant/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'merchant/SET_PAGE_SIZE';

export function getMerchants(currentPage, pageSize, filter) {
  return {
    types: [FETCH_MERCHANT, FETCH_MERCHANT_SUCCESS, FETCH_MERCHANT_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: merchantListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.merchantList;
    },
  };
}

export function addMerchant(merchant) {
  return {
    types: [ADD_MERCHANT, ADD_MERCHANT_SUCCESS, ADD_MERCHANT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: merchantAddMutation,
        variables: merchant,
      });

      return result.data.merchantAdd;
    },
  };
}

export function updateMerchant(merchant) {
  return {
    types: [UPDATE_MERCHANT, UPDATE_MERCHANT_SUCCESS, UPDATE_MERCHANT_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: merchantUpdateMutation,
        variables: merchant,
      });

      return merchant;
    },
  };
}

/*
 * Set filter for getting Merchants
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
