import gql from 'graphql-tag';

export const bannerQuery = gql`
  query bannerQuery($id: ID!) {
    banner(id: $id) {
      id
      title
      subTitle
      androidImageUrl
      iosImageUrl
      landingUrl
      isActive
      startAt
      endAt
      sortedIndex
      createdAt
    }
  }
`;

export default {};
