import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'antd';

import { getColumns } from './ContentListColumn';
import apolloClient from '../../../../helpers/apolloClient';
import { brandContentListQuery } from '../../../../redux/modules/brand/queries';
import { brandDeleteContent, brandSwapContentPriority } from '../../../../redux/modules/brand/mutations';

import ContentAddModal from './ContentAddModal';
import ContentEditModal from './ContentEditModal';

const propTypes = {
  brandId: PropTypes.number.isRequired,
};

const Contents = ({ brandId }) => {
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [clickedRow, setClickedRow] = useState(undefined);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getContentList() {
    setLoading(true);

    try {
      const result = await apolloClient.query({
        query: brandContentListQuery,
        variables: { id: brandId },
      });

      // noinspection JSUnresolvedVariable
      setList(result.data.brandContentList.list);
    } finally {
      setLoading(false);
    }
  }

  function swapList(index, indexToSwap) {
    setList(array => {
      const data = [...array];
      const temp = data[index];
      data[index] = data[indexToSwap];
      data[indexToSwap] = temp;

      return data;
    });
  }

  async function swapContentPriority(index, indexToSwap) {
    setLoading(true);

    try {
      const contentId = list[index].id;
      const contentIdToSwap = list[indexToSwap].id;

      await apolloClient.mutate({
        mutation: brandSwapContentPriority,
        variables: { contentId, contentIdToSwap },
      });

      swapList(index, indexToSwap);
    } finally {
      setLoading(false);
    }
  }

  async function deleteContent(index) {
    setLoading(true);

    try {
      const contentId = list[index].id;

      await apolloClient.mutate({
        mutation: brandDeleteContent,
        variables: { contentId },
      });

      await getContentList();
    } finally {
      setLoading(false);
    }
  }

  async function onAddClose(isAdded) {
    setVisibleAdd(false);

    if (isAdded) {
      await getContentList();
    }
  }

  async function onEditClose(isEditted) {
    setVisibleEdit(false);
    setClickedRow(undefined);

    if (isEditted) {
      await getContentList();
    }
  }

  async function onDownClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);
    const size = list.length;

    if (index >= 0 && index < size - 1) {
      const indexToSwap = index + 1;
      await swapContentPriority(index, indexToSwap);
    }
  }

  async function onUpClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);

    if (index > 0) {
      const indexToSwap = index - 1;
      await swapContentPriority(index, indexToSwap);
    }
  }

  async function onDeleteClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);

    if (index >= 0) {
      await deleteContent(index);
    }
  }

  useEffect(() => {
    if (visibleAdd) return;

    (async () => {
      await getContentList();
    })();
  }, [brandId, visibleAdd]);

  return (
    <Row style={{ marginLeft: 32, marginRight: 32 }}>
      <h3 style={{ marginBottom: 20 }}>브랜드 컨텐츠 목록</h3>

      <Button icon="plus" style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setVisibleAdd(true)}>
        컨텐츠 추가
      </Button>

      <Table
        rowKey={record => record.id}
        columns={getColumns(onDownClicked, onUpClicked, onDeleteClicked)}
        dataSource={list}
        pagination={{ defaultPageSize: 10 }}
        loading={loading}
        onRow={record => ({
          onClick: () => {
            setClickedRow(record);
            setVisibleEdit(true);
          },
        })}
      />

      <ContentAddModal brandId={brandId} visibleAdd={visibleAdd} onClose={onAddClose} />
      <ContentEditModal onClose={onEditClose} visibleEdit={visibleEdit} clickedRow={clickedRow} />
    </Row>
  );
};

Contents.propTypes = propTypes;

export default Contents;
