import { ApolloLink } from 'apollo-link';
import store from 'store';
import { message } from 'antd';
import { push } from 'client';

export const WARN_TIME = 1500000; // 25 minutes
export const LOGOUT_TIME = 1800000; // 30 minutes
const LOGOUT_PADDING = 300000; // 5 minutes

/**
 * Auto Logout Link
 * Each XHR call, start timer that auto logout after 30 minutes.
 */
class AutoLogoutLink extends ApolloLink {
  lastRequestTime = null;
  notifyTimer = null;
  logoutTimer = null;

  request(operation, forward) {
    if (!store.get('admin')) {
      return forward(operation);
    }

    if (this.logoutTimer) {
      clearTimeout(this.logoutTimer);
    }
    if (this.notifyTimer) {
      clearTimeout(this.notifyTimer);
    }

    this.lastRequestTime = new Date();
    this.logoutTimer = setTimeout(this.logout, LOGOUT_TIME + LOGOUT_PADDING);
    this.notifyTimer = setTimeout(this.warnLogout, WARN_TIME);

    return forward(operation);
  }

  warnLogout() {
    // Skip if already logged out
    if (!store.get('admin')) {
      return;
    }

    message.warning('No recent activity found in 25 minutes. Will auto logged out after 5 minutes.', 20);
  }

  async logout() {
    // Skip if already logged out
    if (!store.get('admin')) return;

    push('/login');

    message.info("There's no activity in recent 30 minutes, logged out automatically.", 20);
  }
}

export default new AutoLogoutLink();
