import gql from 'graphql-tag';

export const boostPromotionAddMutation = gql`
  mutation boostPromotionAdd(
    $type: String!
    $title: String!
    $subTitle: String!
    $description: String! # 유의 사항
    $important: String # 핵심 유의 사항
    $usableLocationDescription: String # 사용 가능 위치 설명
    $tutorialType: String
    $status: String!
    $taglines: [String]
    $brandId: Int!
    $buyableFrom: Date! # 구매 기간: 시작
    $buyableTo: Date! # 구매 기간: 끝
    $usableFrom: Date! # 사용 기간: 시작
    $usableTo: Date! # 사용 기간: 끝
    $visibleFrom: Date # 노출 기간: 시작
    $visibleTo: Date # 노출 기간: 끝
    $boltPrice: Int!
    $amountCap: Int
    $countCap: Int
    $landingUrl: String
    $fallbackUrl: String
    $iosAppId: String
    $androidPackage: String
    $benefitType: String # 적립 타입
    $discount: String # 숫자 혹은 10%
    $maxDiscountAmount: Int # 최대 할인 금액
    $priceMin: Int # 결제 금액: 최소
    $priceMax: Int # 결제 금액: 최대
    $targetMerchantIds: JSON!
    $existingPolicyId: String
    $promotionType: String
    $discountAmountMax: String # 숫자 혹은 10%
    $discountAmountMin: String # 숫자 혹은 10%
    $countCapPerUser: Int
    $subPromotions: [SubPromotionInput]
    $benefitDescription: String
    $boostMerchantIds: [String] # 간편결제 맵팽
    $boostCategoryIds: [Int]
  ) {
    boostPromotionAdd(
      type: $type
      title: $title
      subTitle: $subTitle
      description: $description
      important: $important
      usableLocationDescription: $usableLocationDescription
      tutorialType: $tutorialType
      status: $status
      taglines: $taglines
      brandId: $brandId
      buyableFrom: $buyableFrom
      buyableTo: $buyableTo
      usableFrom: $usableFrom
      usableTo: $usableTo
      visibleFrom: $visibleFrom
      visibleTo: $visibleTo
      boltPrice: $boltPrice
      amountCap: $amountCap
      countCap: $countCap
      landingUrl: $landingUrl
      fallbackUrl: $fallbackUrl
      iosAppId: $iosAppId
      androidPackage: $androidPackage
      benefitType: $benefitType
      discount: $discount
      maxDiscountAmount: $maxDiscountAmount
      priceMin: $priceMin
      priceMax: $priceMax
      targetMerchantIds: $targetMerchantIds
      existingPolicyId: $existingPolicyId
      promotionType: $promotionType
      discountAmountMax: $discountAmountMax
      discountAmountMin: $discountAmountMin
      countCapPerUser: $countCapPerUser
      subPromotions: $subPromotions
      benefitDescription: $benefitDescription
      boostMerchantIds: $boostMerchantIds
      boostCategoryIds: $boostCategoryIds
    )
  }
`;

export const monohaBoostPromotionUpdateMutation = gql`
  mutation monohaBoostPromotionUpdate($ids: [Int], $status: String) {
    monohaBoostPromotionUpdate(ids: $ids, status: $status)
  }
`;

export const boostPromotionCopyMutation = gql`
  mutation boostPromotionCopy($ids: [Int]!, $targetDate: Date, $replaceTextFrom: String, $replaceTextTo: String) {
    boostPromotionCopy(
      ids: $ids
      targetDate: $targetDate
      replaceTextFrom: $replaceTextFrom
      replaceTextTo: $replaceTextTo
    )
  }
`;

export default {
  boostPromotionAddMutation,
  monohaBoostPromotionUpdateMutation,
  boostPromotionCopyMutation,
};
