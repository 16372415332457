import TabViewer from './TabViewer';

export class TabData {
  constructor(options) {
    if (!options) {
      throw new Error('requires option object');
    }

    const { key, data, componentType, closable, title, icon } = options;

    if (typeof key === 'undefined') {
      throw new Error('key must be specified');
    } else if (typeof componentType === 'undefined') {
      throw new Error('componentType must be specified');
    }

    this.key = key;
    this.data = data;
    this.componentType = componentType;
    this.closable = !!closable;
    this.title = title || key;
    this.icon = icon;
  }
}

export default TabViewer;

// To debug purpose, export TabData class
window.TabData = TabData;
