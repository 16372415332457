import gql from 'graphql-tag';

export const couponListQuery = gql`
  query couponList($filter: String, $skip: Int, $pageSize: Int) {
    couponList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        description
        discount
        status
        issueType
        benefitType
        startAt
        endAt
      }
      total
    }
  }
`;

export default {
  couponListQuery,
};
