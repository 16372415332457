import gql from 'graphql-tag';

export const bannerListQuery = gql`
  query bannerList($filter: String, $skip: Int, $pageSize: Int) {
    bannerList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        subTitle
        androidImageUrl
        iosImageUrl
        landingUrl
        isActive
        startAt
        endAt
        sortedIndex
        createdAt
      }
      total
    }
  }
`;

export default {
  bannerListQuery,
};
