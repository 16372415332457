const editorConfig = {
  content: {
    initialEditType: 'wysiwyg',
    style: { marginBottom: 10 },
    hideModeSwitch: true,
    placeholder: 'Faq',
    height: 500,
    usageStatistics: false,
    toolbarItems: [
      'heading',
      'bold',
      'italic',
      'hr',
      'quote',
      'divider',
      'ul',
      'ol',
      'indent',
      'outdent',
      'divider',
      'table',
    ],
    exts: ['colorSyntax'],
  },
};

export default editorConfig;
