import ListSelect from 'components/ListSelect';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import apolloClient from 'helpers/apolloClient';

import React, { Component, Fragment } from 'react';
import { bool, func, any, number, objectOf, arrayOf } from 'prop-types';
import { Modal, Form, Row, Col, Icon, message, Input, Alert, Divider, Button } from 'antd';
import autobind from 'autobind-decorator';
import { subMerchantListQuery } from '../PromotionQueries';
import { subMerchantMutation } from '../PromotionMutations';
import styles from '../Promotion.scss';

const { Item } = Form;

const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 8,
  },
  wrapperCol: {
    xs: 24,
    sm: 16,
  },
  style: {
    marginBottom: 20,
    marginLeft: 0,
    marginRight: 0,
  },
};

@Form.create()
@autobind
class SubMerchantModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
    selectedSubMerchants: arrayOf(number).isRequired,
  };

  static defaultProps = {};

  state = {
    initLoaded: false,
    loading: true,
    onEdit: false,
    subMerchantFilter: '',
    merchants: [],
    subMerchants: [],
    selectedMerchants: [],
    selectedSubMerchants: [],
    editModalVisible: false,
    editModalTarget: null,
    editModalName: null,
    editModalCashback: null,
  };

  componentDidUpdate(prevProps) {
    // 모달 열릴 때 하위 가맹점 선택 리스트 한번 업데이트
    if (this.props.visible && !prevProps.visible) {
      if (!this.state.initLoaded) {
        // Load SubMerchants
        this.getSubMerchants();
      } else {
        this.updateSelectedSubMerchants();
      }
    }
  }

  updateSelectedSubMerchants(prev) {
    const { selectedSubMerchants } = this.props;
    const { subMerchants } = this.state;

    this.setState({
      selectedSubMerchants,
      selectedMerchants:
        prev ||
        this.getMerchantListFromSubMerchant(
          subMerchants.filter(subMerchant => selectedSubMerchants.includes(subMerchant.id))
        ).map(merchant => merchant.id),
    });
  }

  input = {
    search: null,
  };

  cleanupBeforeClose() {
    this.setState({ subMerchantFilter: '', selectedSubMerchants: [] });
    this.props.onCancel();
  }

  getMerchantListFromSubMerchant(subMerchantList) {
    return subMerchantList.reduce((prev, subMerchant) => {
      if (!prev.find(merchant => merchant.id === subMerchant.merchant.id)) {
        prev.push({
          id: subMerchant.merchant.id,
          displayName: subMerchant.merchant.displayName,
        });
      }
      return prev;
    }, []);
  }

  async getSubMerchants(prev) {
    this.setState({ loading: true, initLoaded: true });

    try {
      this.subMerchantQuery = cancelableQuery({
        query: subMerchantListQuery,
        variables: {
          skip: 0,
          pageSize: 9999999,
        },
      });

      const result = await this.subMerchantQuery;

      const merchants = this.getMerchantListFromSubMerchant(result.data.subMerchantList.list).sort(
        (merchantA, merchantB) => {
          if (merchantA.displayName > merchantB.displayName) {
            return 1;
          }
          if (merchantA.displayName < merchantB.displayName) {
            return -1;
          }
          return 0;
        }
      );

      this.setState({ loading: false, subMerchants: result.data.subMerchantList.list, merchants });
      this.updateSelectedSubMerchants(prev);
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Merchants');
      this.setState({ subMerchantError: error, loading: false });
      throw error;
    }
  }

  async handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    const { selectedSubMerchants, subMerchants } = this.state;

    form.getFieldsValue();
    await this.props.onOk({
      ids: selectedSubMerchants,
      names: subMerchants
        .filter(subMerchant => selectedSubMerchants.includes(subMerchant.id))
        .map(subMerchant => subMerchant.name),
    });
    this.cleanupBeforeClose();
  }

  getMerchants() {
    return this.state.merchants;
  }

  getFilteredSubMerchants() {
    const { subMerchants, selectedMerchants, subMerchantFilter, merchants } = this.state;
    return subMerchants
      .filter(subMerchant => selectedMerchants.includes(subMerchant.merchant.id))
      .filter(subMerchant => subMerchant.name.indexOf(subMerchantFilter) >= 0)
      .sort((subMerchantA, subMerchantB) => {
        if (subMerchantA.name > subMerchantB.name) {
          return 1;
        }
        if (subMerchantA.name < subMerchantB.name) {
          return -1;
        }
        return 0;
      })
      .map(subMerchant => ({
        ...subMerchant,
        merchantName: merchants.find(merchant => merchant.id === subMerchant.merchant.id).displayName,
      }));
  }

  updateSubMerchantFilter(e) {
    this.setState({ subMerchantFilter: e.target.value });
  }

  handleSelectMerchant(selectedMerchants) {
    const { selectedSubMerchants, subMerchants } = this.state;

    this.setState({
      selectedMerchants,
      selectedSubMerchants: selectedSubMerchants.filter(subMerchantId => {
        const targetSubMerchant = subMerchants.find(subMerchant => subMerchant.id === subMerchantId);
        if (targetSubMerchant) {
          return selectedMerchants.includes(targetSubMerchant.merchant.id);
        }
        return false;
      }),
    });
  }

  handleSelectSubMerchant(selectedSubMerchants, selected) {
    if (this.state.onEdit) {
      const editModalTarget = this.getFilteredSubMerchants().find(subMerchant => subMerchant.id === selected);
      const { name } = editModalTarget;
      const cashback = editModalTarget.merchant?.data?.maxSubMerchantMonthlyCashbackAmount?.[editModalTarget.id] || 0;
      this.props.form.setFieldsValue({
        name,
        cashback,
      });

      if (editModalTarget) {
        this.setState({
          editModalVisible: true,
          editModalTarget,
          editModalName: name,
          editModalCashback: cashback,
        });
      }
    } else {
      this.setState({ selectedSubMerchants });
    }
  }

  closeEditModal() {
    this.setState({ editModalVisible: false });
  }

  async submitEditModal() {
    const { form } = this.props;
    const { editModalTarget } = this.state;
    const { name, cashback } = form.getFieldsValue();

    const result = await apolloClient.mutate({
      mutation: subMerchantMutation,
      variables: {
        id: editModalTarget.id,
        name,
        merchantId: editModalTarget.merchant.id,
        maxMonthlyCashbackAmount: Number(cashback),
      },
    });

    // 서브머천트 갱신
    if (result) {
      this.getSubMerchants(this.state.selectedMerchants);
      this.setState({ editModalVisible: false });
    }
  }

  toggleEdit() {
    this.setState({ onEdit: !this.state.onEdit });
  }

  render() {
    const { visible, form } = this.props;
    const { loading, onEdit, editModalVisible, editModalTarget, editModalName, editModalCashback } = this.state;
    const { selectedSubMerchants, selectedMerchants, subMerchantError, subMerchantFilter } = this.state;
    const { getFieldDecorator } = form;

    return (
      <>
        <Modal
          title="SubMerchant Edit"
          visible={editModalVisible}
          onCancel={this.closeEditModal}
          onOk={this.submitEditModal}
        >
          <DescriptionList size="large" title="Merchant Info" style={{ marginBottom: 20 }} />
          <Form.Item label="Name" {...formItemLayout} labelAlign="left">
            <Input disabled value={editModalTarget?.merchantName} />
          </Form.Item>
          <Form.Item label="Max Monthly Cashback" {...formItemLayout} labelAlign="left">
            <Input disabled placeholder={0} value={editModalTarget?.merchant?.data?.maxMonthlyCashbackAmount} />
          </Form.Item>
          <Divider />
          <DescriptionList size="large" title="SubMerchant Info" style={{ marginBottom: 20 }} />
          <Form.Item label="ID" {...formItemLayout} labelAlign="left">
            <Input disabled value={editModalTarget?.id} />
          </Form.Item>
          <Form.Item label="BusinessNumber" {...formItemLayout} labelAlign="left">
            <Input disabled value={editModalTarget?.businessNumber} />
          </Form.Item>
          <Form.Item label="Name" {...formItemLayout} labelAlign="left">
            {getFieldDecorator('name', {
              initialValue: editModalName,
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Max Monthly Cashback" {...formItemLayout} labelAlign="left">
            {getFieldDecorator('cashback', {
              initialValue: editModalCashback,
            })(<Input />)}
          </Form.Item>
        </Modal>
        <Modal
          title="SubMerchants"
          width={800}
          onCancel={this.cleanupBeforeClose}
          footer={[
            <Button key="Edit" type={onEdit ? 'primary' : 'default'} onClick={this.toggleEdit}>
              Edit
            </Button>,
            <Button key="Cancel" onClick={this.cleanupBeforeClose}>
              Cancel
            </Button>,
            <Button key="OK" type="primary" onClick={this.handleSubmit}>
              Submit
            </Button>,
          ]}
          visible={visible}
        >
          <Row type="flex" justify="start" gutter={16}>
            <Col span={12}>
              <Item>
                <span>
                  <b>Merchants</b>
                </span>
              </Item>
            </Col>
            <Col span={12}>
              <Form onSubmit={this.handleSearchSubmit}>
                <Item>
                  <Input
                    ref={node => (this.input.search = node)}
                    placeholder="Search Keyword"
                    suffix={<Icon type="search" />}
                    value={subMerchantFilter}
                    onChange={this.updateSubMerchantFilter}
                    autoComplete="off"
                  />
                </Item>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="start" gutter={16}>
            <Col span={12}>
              {!subMerchantError && (
                <Fragment>
                  <Item>
                    <ListSelect
                      items={this.getMerchants().map(merchant => ({
                        name: merchant.displayName,
                        value: merchant.id,
                      }))}
                      onClick={this.handleSelectMerchant}
                      value={selectedMerchants}
                    />
                  </Item>
                </Fragment>
              )}
            </Col>
            <Col span={12}>
              {subMerchantError && (
                <Fragment>
                  <Alert message="Oops!" description="Failed to load SubMerchants." type="warning" showIcon />

                  <Divider />

                  <Button icon="redo" onClick={this.getSubMerchants}>
                    Try Again
                  </Button>
                </Fragment>
              )}
              {!subMerchantError && (
                <Fragment>
                  <Item>
                    {loading && <Icon className={styles.loadingSpinner} type="loading" spin />}
                    {!subMerchantError && (
                      <ListSelect
                        items={this.getFilteredSubMerchants().map(subMerchant => ({
                          name: onEdit ? `${subMerchant.name} 📝` : subMerchant.name,
                          value: subMerchant.id,
                          title: subMerchant.merchantName,
                        }))}
                        onClick={this.handleSelectSubMerchant}
                        value={selectedSubMerchants}
                        multiSelect
                      />
                    )}
                  </Item>
                </Fragment>
              )}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

export default SubMerchantModal;
