import React, { Component } from 'react';
import { arrayOf, any, bool } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import styles from './Top20ProductsChart.scss';

const mapStateToProps = state => ({
  isMobile: state.global.isMobile,
});

@connect(mapStateToProps)
@autobind
class CustomLegend extends Component {
  static propTypes = {
    isMobile: bool.isRequired,
    payload: arrayOf(any),
  };
  static defaultProps = {
    payload: [],
  };

  state = {
    showFullname: false,
    productName: false,
    fullnameX: 0,
    fullnameY: 0,
  };

  handleMouseMove(productName, { clientX, clientY }) {
    this.setState({ productName, fullnameX: clientX, fullnameY: clientY });
  }

  showFullname() {
    this.setState({
      showFullname: true,
      fullnameX: 0,
      fullnameY: 0,
    });
  }

  hideFullname() {
    this.setState({
      showFullname: false,
    });
  }

  render() {
    const { payload, isMobile } = this.props;
    const { showFullname, productName, fullnameX, fullnameY } = this.state;

    return (
      <ul>
        {payload.map(entry => (
          <li
            key={entry.payload.key}
            className={cx([
              styles.legendItem,
              'recharts-legend-item legend-item-0',
              isMobile && styles.mobileLegendItem,
            ])}
            onMouseEnter={this.showFullname}
            onMouseLeave={this.hideFullname}
            onMouseMove={this.handleMouseMove.bind(null, entry.payload.key)}
          >
            <svg
              className={cx(['recharts-surface', styles.legendIcon])}
              width="14"
              height="14"
              viewBox="0 0 32 32"
              version="1.1"
            >
              <path stroke="none" fill={entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
            </svg>
            <span className="recharts-legend-item-text">
              {isMobile ? entry.payload.key : `${entry.payload.key.substr(0, 15)}}...`}
            </span>

            {showFullname && productName === entry.payload.key && (
              <div className={styles.hoverText} style={{ top: fullnameY, left: fullnameX }}>
                {productName}
              </div>
            )}
          </li>
        ))}
      </ul>
    );
  }
}

export default CustomLegend;
