import React from 'react';
import { Modal, Tooltip, Card, Button, Input, Select, Form } from 'antd';
import DataDisplay from 'components/DataDisplay';

const { Option } = Select;

export function showPGResultModal(txData, others) {
  Modal.info({
    content: (
      <div>
        <div style={{ margin: '10px 0 20px 0' }}>
          <DataDisplay
            label="Payment ID:"
            value={() => (
              <Tooltip title={txData.paymentId}>
                <span>{txData.paymentId.substr(0, 20)}...</span>
              </Tooltip>
            )}
          />
        </div>

        <Card>
          <pre style={{ fontSize: 12 }}>{JSON.stringify(txData.pgResult || {}, null, 2)}</pre>
        </Card>
      </div>
    ),
    okText: 'Close',
    title: `PG Result of ${others.id}`,
    width: 600,
    maskClosable: true,
  });
}

function handelSubmit(updateReceipt, e) {
  e.preventDefault();
  updateReceipt(this.props.form.getFieldsValue());

  return false;
}

function cancelConfirm(cb) {
  Modal.confirm({
    title: 'Do you want to cancel these items?',
    content: 'If you cancel, you cannot issue the receipt again.',
    onOk() {
      cb();
    },
    onCancel() {},
  });
}

function createReceiptForm(cashReceiptData) {
  const { type = 'consumer_number', number = '0100001234' } = cashReceiptData;
  const customForm = Form.create({ name: 'updateCashReceiptForm' })(
    class receiptForm extends React.Component {
      render() {
        const { form, cancelReceipt, updateReceipt } = this.props || {};

        if (!form || !cancelReceipt || !updateReceipt) {
          return false;
        }

        const { getFieldDecorator } = form;

        return (
          <Form style={{ marginTop: '10px' }} onSubmit={handelSubmit.bind(this, updateReceipt)}>
            {getFieldDecorator('receiptType', { initialValue: type })(
              <Select name="receiptType" style={{ width: '30%' }}>
                <Option value="consumer_number">개인 - 휴대폰 번호</Option>
                <Option value="consumer_card">개인 - 현금영수증 카드번호</Option>
                <Option value="business_number">사업자 - 사업자등록 번호</Option>
                <Option value="business_card">사업자 - 현금영수증 카드번호</Option>
              </Select>
            )}
            {getFieldDecorator('receiptNumber', { initialValue: number })(
              <Input
                name="receiptNumber"
                style={{ width: '30%', marginRight: '10px' }}
                placeholder="input receipt number"
              />
            )}
            <Button type="danger" onClick={cancelConfirm.bind(null, cancelReceipt)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form>
        );
      }
    }
  );

  return customForm;
}
/**
 * Open Modal for display Array
 * @param {Object} param
 * @param {string} param.title
 * @param {Object} param.data
 */
export function showArrayResultModal(param) {
  const { title, data, editReceipt, cancelReceipt, updateReceipt } = param;
  let CustomFooter;

  if (editReceipt) {
    CustomFooter = createReceiptForm(data);
  }

  Modal.info({
    content: (
      <div>
        <Card>
          <pre style={{ fontSize: 12 }}>{JSON.stringify(data || [], null, 2)}</pre>
        </Card>
        {editReceipt && <CustomFooter cancelReceipt={cancelReceipt} updateReceipt={updateReceipt} />}
      </div>
    ),
    okText: 'Close',
    title,
    width: 600,
    maskClosable: true,
  });
}

export const BoostReportRejectReason = {
  EXCEED_CASHBACK_LIMIT: 'exceed_cashback_limit',
  NOT_BOOST_MERCHANT: 'not_boost_merchant',
  EXCHANGE_PROPERTY: 'exchange_property',
  ONLINE_ONLY: 'online_only',
  OFFLINE_ONLY: 'offline_only',
  NOT_FOR_PAY_SERVICE: 'not_for_pay_service',
  EXCEED_COUNT_CAP_PER_USER: 'exceed_count_cap_per_user',
  ETC: 'etc',
};

export const BoostReportRejectReasonText = {
  [BoostReportRejectReason.EXCEED_CASHBACK_LIMIT]: '월 한도 10만원 초과',
  [BoostReportRejectReason.NOT_BOOST_MERCHANT]: '다른 가맹점 선택',
  [BoostReportRejectReason.EXCHANGE_PROPERTY]: '환금성 상품',
  [BoostReportRejectReason.ONLINE_ONLY]: '온라인 전용',
  [BoostReportRejectReason.OFFLINE_ONLY]: '오프라인 전용',
  [BoostReportRejectReason.NOT_FOR_PAY_SERVICE]: '네이버페이, 카카오페이 불가',
  [BoostReportRejectReason.EXCEED_COUNT_CAP_PER_USER]: '구매 횟수 제한',
  [BoostReportRejectReason.ETC]: '기타',
};
