import gql from 'graphql-tag';

export const boostCategoryAddMutation = gql`
  mutation boostCategoryAdd($name: String!, $imageUrl: String!, $priority: Int!) {
    boostCategoryAdd(name: $name, imageUrl: $imageUrl, priority: $priority)
  }
`;

export const boostCategoryUpdateMutation = gql`
  mutation boostCategoryUpdate($id: Int!, $name: String, $imageUrl: String, $priority: Int) {
    boostCategoryUpdate(id: $id, name: $name, imageUrl: $imageUrl, priority: $priority)
  }
`;

export const boostCategorySwapMutation = gql`
  mutation boostCategorySwap($firstId: Int!, $secondId: Int!) {
    boostCategorySwap(firstId: $firstId, secondId: $secondId)
  }
`;

export const boostCategoryUploadImageMutation = gql`
  mutation boostCategoryUploadImage($imageFile: FileUpload!) {
    boostCategoryUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export default {
  boostCategoryAddMutation,
  boostCategoryUpdateMutation,
  boostCategorySwapMutation,
  boostCategoryUploadImageMutation,
};
