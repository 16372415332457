export default {
  $id: 'admin-edit-schema',
  title: 'admin-edit-schema',
  description: 'admin-edit-schema.',
  type: 'object',
  required: ['fullname'],
  properties: {
    id: {
      type: 'string',
    },
    fullname: {
      type: 'string',
      title: 'Admin Name',
      maxLength: 25,
      minLength: 1,
    },
    phone: {
      type: 'string',
      title: 'Admin Code',
      maxLength: 25,
      minLength: 1,
    },
    email: {
      type: 'string',
      title: 'Description',
      maxLength: 300,
    },
  },
};
