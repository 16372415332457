import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { message } from 'antd';
import { addAgreement, updateAgreement, forkAgreement } from 'redux/modules/agreement/actions';
import AgreementList from './List/AgreementList';
import AgreementForm from './Form/AgreementForm';
import { AgreementMode } from './AgreementConstants';
import styles from './Agreement.scss';

const mapDispatchToProps = {
  push,
  addAgreement,
  updateAgreement,
  forkAgreement,
};

@connect(null, mapDispatchToProps)
@autobind
class AgreementContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
    addAgreement: func.isRequired,
    updateAgreement: func.isRequired,
    forkAgreement: func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      mode: null,
      id: null,
    };

    this.setChildComponent();
  }

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/operation/terms') {
        this.setState({ showList: true });
      } else {
        const match = pathname.match(/\/operation\/terms\/(.+)/);

        if (match) {
          const id = match[1];

          if (id === 'add') {
            this.createNewAgreement();
          } else {
            this.openAgreement({ id });
          }
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/operation/terms') {
        this.setState({ showList: true });
      }
    }
  }

  setChildComponent() {
    const { pathname } = this.props.location;

    const match = pathname.match(/\/operation\/terms\/(.+)/);

    if (match) {
      const id = match[1];

      // ESLint doesn't allow changing state but this function invokes from constructor, so it's fine.
      /* eslint-disable */
      this.state = {
        ...this.state,
        showList: false,
        mode: id === 'add' ? AgreementMode.ADD : AgreementMode.UPDATE,
        id,
      };
      /* eslint-enable */
    } else {
      /* eslint-disable */
      this.state = {
        showList: true,
      };
      /* eslint-enable */
    }
  }

  createNewAgreement() {
    this.setState({
      showList: false,
      mode: AgreementMode.ADD,
      id: null,
    });
  }

  createNewAgreementWithPush() {
    this.createNewAgreement();
    this.props.push('/operation/terms/add');
  }

  openAgreement(agreement) {
    this.setState({
      showList: false,
      mode: AgreementMode.UPDATE,
      id: agreement.id,
    });
  }

  openAgreementWithPush(agreement) {
    this.openAgreement(agreement);
    this.props.push(`/operation/terms/${agreement.id}`);
  }

  switchToList() {
    this.setState({
      showList: true,
    });

    this.props.push('/operation/terms');
  }

  async handleSubmit(form) {
    try {
      const { mode } = this.state;

      if (mode === AgreementMode.ADD) {
        await this.props.addAgreement(form);
      } else {
        await this.props.updateAgreement(form);
      }

      this.setState({
        showList: true,
      });

      this.props.push('/operation/terms');
    } catch (err) {
      message.error(`Failed to save agreement: ${err.message}`);
      console.log(err);
    }
  }

  async handleFork(form) {
    try {
      await this.props.forkAgreement(form);

      this.setState({
        showList: true,
      });

      this.props.push('/operation/terms');
    } catch (err) {
      message.error(`Failed to fork agreement: ${err.message}`);
      console.log(err);
    }
  }

  render() {
    const { showList, mode, id } = this.state;

    return (
      <div className={styles.agreementContainer}>
        {showList && <AgreementList onCreate={this.createNewAgreementWithPush} onOpen={this.openAgreementWithPush} />}

        {!showList && (
          <AgreementForm
            onSubmit={this.handleSubmit}
            onCancel={this.switchToList}
            onFork={this.handleFork}
            mode={mode}
            id={id}
          />
        )}
      </div>
    );
  }
}

export default AgreementContainer;
