import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Table } from 'antd';

@autobind
class InfiniteScrollableTable extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    onFetch: PropTypes.func.isRequired,
    rowKey: PropTypes.string,
  };

  static defaultProps = {
    rowKey: 'id',
  };

  componentDidMount() {
    this.setupScroll();
  }

  lastProps = null;

  setupScroll() {
    const { containerEl } = this;
    const tableEl = containerEl.getElementsByClassName('ant-table-body')[0];

    tableEl.addEventListener('scroll', ev => {
      const maxScroll = ev.target.scrollHeight - ev.target.clientHeight;
      const currentScroll = ev.target.scrollTop;
      const { loading, ...props } = this.props;

      if (!this.lastProps && currentScroll === maxScroll) {
        this.lastProps = {
          loading,
          ...props,
        };
        this.props.onFetch();
      }
    });
  }

  render() {
    const { loading, rowKey, ...props } = this.props;

    if (this.lastProps && !loading) {
      this.lastProps = null;
    }

    return (
      <div ref={node => (this.containerEl = node)}>
        <Table
          rowKey={rowKey}
          pagination={false}
          scroll={{ y: 500, x: '100%' }}
          {...(this.lastProps || {
            loading,
            ...props,
          })}
        />
      </div>
    );
  }
}

export default InfiniteScrollableTable;
