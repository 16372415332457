import gql from 'graphql-tag';

export const settlementPreviewQuery = gql`
  query settlementPreview {
    settlementPreview {
      merchantId
      name
      displayName
      amount
      feeAmount
      balance
      bankCode
      bankAccount
      bankHolder
    }
  }
`;

export const settlementTransferQuery = gql`
  query settlementTransfer($filter: String) {
    settlementTransfer(filter: $filter) {
      id
      createdAt
      finishedAt
      amount
      status
      author
      settlementAdjustment {
        amount
        memo
        author
      }
      merchantId
      merchant {
        name
      }
    }
  }
`;

export default {
  settlementPreviewQuery,
  settlementTransferQuery,
};
