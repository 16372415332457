/* eslint-disable */
export const REGEX_PASSWORD = /^(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()\-\+=\,\.?'"{}|<>:;/\[\]_\\]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/;
export const REGEX_GRAB_ENGLISH_AND_NUMBER_AND_CHAR = /[^?=.*[~`!@#$%\^&*()\-\+=\,\.?'"{}|<>:;/\[\]_\\a-zA-Z0-9]/g;
/* eslint-enable */

export const REGEX_CONSECUTIVE_NUMBER = /(0123)|(1234)|(2345)|(3456)|(4567)|(5678)|(6789)|(7890)/;

export const REGEX_REPEATED_CHAR = /(\w|[~`!@#$%\^&*()\-\+=\,\.?'"{}|<>:;/\[\]_\\])\1{3,}/;

export default {
  REGEX_PASSWORD,
  REGEX_GRAB_ENGLISH_AND_NUMBER_AND_CHAR,
  REGEX_CONSECUTIVE_NUMBER,
  REGEX_REPEATED_CHAR,
};
