import { Row } from 'antd';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PromotionAirdropList from './PromotionAirdropList';
import PromotionAirdropItem from './PromotionAirdropItem';

export const listPathName = '/promotion/airdrop';

@autobind
class PromotionAirdropContainer extends Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {};

  state = {
    targetId: undefined,
  };

  componentDidMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);

    this.handleUrlChange();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  listChild = undefined; // 리스트
  itemChild = undefined; // 내용

  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (['POP', 'PUSH'].includes(history.action)) {
      if (pathname === listPathName) {
        this.setState({
          targetId: undefined,
        });
      } else {
        const match = pathname.match(/\/airdrop\/(.+)/);
        const targetId = match?.[1];
        this.setState({
          targetId,
        });
        if (this.itemChild) {
          this.itemChild.getAirdropItem(targetId);
        }
      }
    }
  }

  onCreateNew() {
    this.setState({
      targetId: 'add',
    });
  }

  render() {
    const { targetId } = this.state;
    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          padding: 10,
          position: 'relative',
        }}
      >
        <Row gutter={2}>
          {targetId ? (
            <PromotionAirdropItem targetId={targetId} setRef={ref => (this.itemChild = ref)} />
          ) : (
            <PromotionAirdropList onCreateNew={this.onCreateNew} setRef={ref => (this.listChild = ref)} />
          )}
        </Row>
      </div>
    );
  }
}

export default PromotionAirdropContainer;
