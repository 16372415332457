import { createStore as _createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import createMiddleware from './middleware/clientMiddleware';
import createReducers from './reducer';
import { isMobile } from '../utils/mobileBrowser';

export function inject(store, reducers) {
  Object.entries(reducers).forEach(([name, reducer]) => {
    if (store.asyncReducers[name]) return;
    store.asyncReducers[name] = reducer.__esModule ? reducer.default : reducer;
  });

  store.replaceReducer(createReducers(store.asyncReducers));
}

function getNoopReducers(reducers, data) {
  if (!data) return {};
  return Object.keys(data).reduce(
    (prev, next) => (reducers[next] ? prev : { ...prev, [next]: (state = {}) => state }),
    {}
  );
}

export default function createStore({ history, data, helpers }) {
  const middleware = [createMiddleware(helpers), routerMiddleware(history)];
  const enhancers = [applyMiddleware(...middleware)];

  if (__CLIENT__ && __DEVTOOLS__ && isMobile() === false) {
    const { persistState } = require('redux-devtools');

    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      enhancers.push(
        window.__REDUX_DEVTOOLS_EXTENSION__({
          // actionsBlacklist: []
        })
      );
    }

    enhancers.push(persistState(window.location.href.match(/[?&]debug_session=([^&]+)\b/)));
  }

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['tabs'],
    stateReconciler: autoMergeLevel2,
  };

  const finalCreateStore = compose(...enhancers)(_createStore);
  const reducers = createReducers();
  const noopReducers = getNoopReducers(reducers, data);
  const store = finalCreateStore(
    connectRouter(history)(persistReducer(persistConfig, combineReducers({ ...noopReducers, ...reducers }))),
    data
  );

  store.asyncReducers = {};
  store.inject = inject.bind(null, store);

  if (__DEVELOPMENT__ && module.hot) {
    module.hot.accept('./reducer', () => {
      let reducer = require('./reducer');
      reducer = combineReducers((reducer.__esModule ? reducer.default : reducer)(store.asyncReducers));
      store.replaceReducer(reducer);
    });
  }

  store.persistor = persistStore(store);

  return store;
}
