import gql from 'graphql-tag';

export const transactionListQuery = gql`
  query transactionList($filter: String, $skip: Int, $pageSize: Int) {
    transactionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      total
      list {
        id
        createdAt
        type
        currency
        amount
        delta
        balance
        ledger {
          id
          user {
            id
            fullname
          }
          merchant {
            id
            name
            displayName
          }
        }
      }
    }
  }
`;

export default {
  transactionListQuery,
};
