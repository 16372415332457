import React, { Component, Fragment } from 'react';
import { func, string, number, objectOf, any, bool } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { get } from 'lodash';
import {
  Form,
  Button,
  Divider,
  Input,
  InputNumber,
  Select,
  Alert,
  DatePicker,
  message,
  Col,
  Icon,
  Checkbox,
} from 'antd';
import { addLuckyPromotion, updateLuckyPromotion, getLuckyPromotions } from 'redux/modules/luckyPromotion/actions';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import ListSelect from 'components/ListSelect';
import { commify } from 'utils/stringUtil';
import { required, isAfter, isInteger, isBetween, isFloat } from 'utils/formValidator';
import { luckyPromotionQuery, merchantListQuery } from '../LuckyPromotionQueries';
import { formItemLayout, formItemLayoutWithoutLabel, formItemDividerLayout } from './LuckyPromotionFormItemLayout';
import Item from './LuckyPromotionFormItem';
import styles from '../LuckyPromotion.scss';

const { TextArea } = Input;
const { Option } = Select;

const mapStateToProps = state => {
  const { currentPage, pageSize, filter, updating } = state.luckyPromotion;

  return {
    tabList: state.tabs.promotion.list,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addLuckyPromotion,
  updateLuckyPromotion,
  getLuckyPromotions,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class LuckyPromotionForm extends Component {
  static propTypes = {
    push: func.isRequired,
    activeTabKey: string.isRequired,
    currentPage: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    updating: bool.isRequired,
    form: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    addLuckyPromotion: func.isRequired,
    updateLuckyPromotion: func.isRequired,
    getLuckyPromotions: func.isRequired,
    id: string,
    onSubmit: func,
  };

  static defaultProps = {
    filter: null,
    id: null,
    onSubmit: () => {},
  };

  state = {
    // Promotion XHR Status
    data: null,
    error: null,
    loading: false,
    // Merchant XHR Status
    merchants: [],
    merchantError: null,
    merchantLoading: false,
    merchantFilter: '',
    // Others
    validationErrors: [],
    selectedMerchants: [],
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }

    // Load Merchants
    this.getMerchants();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
      this.getMerchants();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
    if (this.merchantQuery) {
      this.merchantQuery.cancel();
    }
  }

  input = {
    title: null,
    description: null,
    priceMin: null,
    priceMax: null,
    searchMerchant: null,
    startAt: null,
    endAt: null,
  };

  query = null;
  merchantQuery = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: luckyPromotionQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.luckyPromotion;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Promotion ID');
      } else {
        this.setState({
          loading: false,
          data,
          selectedMerchants: data.merchants ? data.merchants.map(merchant => merchant.id) : [],
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Lucky Promotion: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  async getMerchants() {
    this.setState({ merchantLoading: true });

    try {
      this.merchantQuery = cancelableQuery({
        query: merchantListQuery,
        variables: {
          skip: 0,
          pageSize: 9999999,
        },
      });

      const result = await this.merchantQuery;

      this.setState({ merchantLoading: false, merchants: result.data.merchantList.list });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Merchants');
      this.setState({ merchantError: error, merchantLoading: false });
      throw error;
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('luckyPromotion', id ? id.toString() : 'add');
    this.props.push('/promotion/lucky');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    this.setState({
      validationErrors: [],
    });

    const validationErrors = [];

    if (err) {
      const fieldsToCheck = ['title', 'description', 'expirationMinute', 'amountCap', 'countCap', 'startAt', 'endAt'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }
          return;
        }
      }
      return;
    }

    if (this.state.selectedMerchants.length === 0) {
      this.input.searchMerchant.input.scrollIntoView({
        behavior: 'smooth',
      });

      validationErrors.push({
        field: 'merchants',
        error: new Error('Merchant must be selected.'),
      });

      this.setState({ validationErrors });

      return;
    }

    this.submitLuckyPromotion();
  }

  async submitLuckyPromotion() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();

    formFields.id = this.props.id;
    formFields.merchantIds = this.state.selectedMerchants;
    formFields.probabilities =
      formFields.probabilities &&
      formFields.probabilities.map(probability => ({
        min: probability.min,
        max: probability.max,
        probability: probability.probability / 100,
      }));

    formFields.winPromotion.discount = formFields.winPromotion.discount && `${formFields.winPromotion.discount}%`;

    formFields.losePromotion.discount = formFields.losePromotion.discount && `${formFields.losePromotion.discount}%`;

    if (formFields.id === 'add') {
      return this.addLuckyPromotion(formFields);
    }

    return this.updateLuckyPromotion(formFields);
  }

  async addLuckyPromotion(luckyPromotion) {
    delete luckyPromotion.id;
    await this.props.addLuckyPromotion(luckyPromotion);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('luckyPromotion', 'add');
    this.props.push('/promotion/lucky');
    this.refreshLuckyPromotions();
  }

  async updateLuckyPromotion(luckyPromotion) {
    await this.props.updateLuckyPromotion(luckyPromotion);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('luckyPromotion', luckyPromotion.id.toString());
    this.props.push('/promotion/lucky');
    this.refreshLuckyPromotions();
  }

  refreshLuckyPromotions() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getLuckyPromotions(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  handleSelectMerchant(selectedMerchants) {
    this.setState({ selectedMerchants });
  }

  updateMerchantFilter(e) {
    this.setState({ merchantFilter: e.target.value });
  }

  /**
   * Get filtered Merchants
   * @return {MerchantEntity[]} filteredMerchants
   */
  filterMerchants() {
    const { merchants, merchantFilter } = this.state;
    return merchants.filter(merchant => new RegExp(merchantFilter, 'i').test(merchant.name));
  }

  getValidationError(targetField) {
    const { validationErrors } = this.state;
    const result = validationErrors.filter(val => val.field === targetField);

    return result.length === 0 ? null : result[0].error;
  }

  addProbabilities() {
    const data = this.state.data || {};
    data.probabilities = [...(data.probabilities || []), { priceMin: 0, priceMax: 10000000, probability: 100 }];

    this.setState({ data });
  }

  addPeriodCapCondition() {
    const data = this.state.data || {};
    data.periodCapsPerUser = [...(data.periodCapsPerUser || []), { count: 0, period: 0 }];

    this.setState({ data });
  }

  removeProbabilities(index) {
    const data = this.state.data || {};
    if (!data.probabilities || data.probabilities.length <= index) {
      return;
    }
    data.probabilities.splice(index, 1);
    if (data.probabilities.length < 1) {
      delete data.probabilities;
    }
    this.setState({ data });
  }

  removePeriodCapsPerUser(index) {
    const data = this.state.data || {};
    if (!data.periodCapsPerUser || data.periodCapsPerUser.length <= index) {
      return;
    }
    data.periodCapsPerUser.splice(index, 1);
    if (data.periodCapsPerUser.length < 1) {
      delete data.periodCapsPerUser;
    }
    this.setState({ data });
  }

  renderPromotionOption() {
    const data = this.state.data || {};
    const { getFieldDecorator } = this.props.form;
    const { title, description, status, expirationMinute } = data;

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout} style={{ margin: 0 }}>
          <Divider>Promotion option</Divider>
        </Form.Item>

        {/* 프로모션 이름 */}
        <Item label="Lucky Promotion Title" {...formItemLayout}>
          {getFieldDecorator('title', { initialValue: title, rules: [required()] })(
            <Input placeholder="Lucky Promotion Title" ref={node => (this.input.title = node)} autoComplete="off" />
          )}
        </Item>

        {/* 프로모션 설명 */}
        <Item label="Description" {...formItemLayout}>
          {getFieldDecorator('description', { initialValue: description, rules: [required()] })(
            <TextArea
              placeholder="Brief description about lucky promotion"
              ref={node => (this.input.description = node)}
              autoComplete="off"
              autosize={{ minRows: 2, maxRows: 6 }}
            />
          )}
        </Item>

        {/* status */}
        <Item label="Status" {...formItemLayout} style={{ marginBottom: 10 }} className={styles.formControlDisplay}>
          {getFieldDecorator('status', { initialValue: status || 'enabled' })(
            <Select>
              <Option value="enabled">Enabled</Option>
              <Option value="disabled">Disabled</Option>
            </Select>
          )}
        </Item>

        <Item label="Lucky Expiration Minute" {...formItemLayout}>
          {getFieldDecorator('expirationMinute', {
            initialValue: expirationMinute,
            rules: [required()],
          })(<InputNumber placeholder="Expiration Minute" min={0} style={{ width: 150 }} />)}
        </Item>
      </Fragment>
    );
  }

  renderTargetUserOption() {
    const data = this.state.data || {};
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { merchantLoading, merchantError, selectedMerchants } = this.state;
    const filteredMerchants = this.filterMerchants();
    const { startAt, endAt, priceMin, priceMax } = data;

    const isFirstForLifetime = get(data, 'filter.isFirstForLifetime', null);
    const isFirstForMerchant = get(data, 'filter.isFirstForMerchant', null);
    const totalPaymentCount = get(data, 'filter.totalPaymentCount', null);
    const totalMerchantPaymentCount = get(data, 'filter.totalMerchantPaymentCount', null);

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Target User option</Divider>
        </Form.Item>

        {/* 프로모션 기간 */}
        <Item label="Period" {...formItemLayout} required>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('startAt', {
                initialValue: startAt && moment(startAt),
                rules: [required()],
              })(
                <DatePicker
                  ref={node => (this.input.startAt = node)}
                  disabledDate={current =>
                    current <
                    moment()
                      .subtract(1, 'day')
                      .endOf('day')
                  }
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ minWidth: 0 }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
          </Col>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('endAt', {
                initialValue: endAt && moment(endAt),
                rules: [isAfter(this.props.form.getFieldsValue().startAt)],
              })(
                <DatePicker
                  style={{ minWidth: 0 }}
                  ref={node => (this.input.endAt = node)}
                  disabledDate={current =>
                    current <
                    moment()
                      .subtract(1, 'day')
                      .endOf('day')
                  }
                  showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                  format="YYYY-MM-DD HH:mm:ss"
                />
              )}
            </Form.Item>
          </Col>
        </Item>

        {/* 적용 가격범위 */}
        <Item label="Price Range" {...formItemLayout}>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('priceMin', {
                initialValue: priceMin ? +priceMin : null,
              })(
                <InputNumber
                  placeholder="Min. Price"
                  ref={node => (this.input.priceMin = node)}
                  min={0}
                  max={10000000 - 1}
                  formatter={value => commify(value)}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
          </Col>
          <Col span={11}>
            <Form.Item style={{ marginBottom: 0 }}>
              {getFieldDecorator('priceMax', {
                initialValue: priceMax ? +priceMax : null,
                rules: [],
              })(
                <InputNumber
                  placeholder="Max. Price"
                  ref={node => (this.input.priceMax = node)}
                  min={0}
                  max={10000000}
                  formatter={value => commify(value)}
                />
              )}
            </Form.Item>
          </Col>
        </Item>

        <Item label="isFirstForLifetime" {...formItemLayout}>
          {getFieldDecorator('isFirstForLifetime', {
            initialValue: isFirstForLifetime,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Item>

        <Item label="isFirstForMerchant" {...formItemLayout}>
          {getFieldDecorator('isFirstForMerchant', {
            initialValue: isFirstForMerchant,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Item>

        <Item label="totalPaymentCount" {...formItemLayout}>
          {getFieldDecorator('totalPaymentCount', {
            initialValue: totalPaymentCount,
          })(<InputNumber placeholder="Total Payment Count" min={0} style={{ width: 150 }} />)}
        </Item>

        <Item label="totalMerchantPaymentCount" {...formItemLayout}>
          {getFieldDecorator('totalMerchantPaymentCount', {
            initialValue: totalMerchantPaymentCount,
          })(<InputNumber placeholder="Total Merchant PaymentCount" min={0} style={{ width: 150 }} />)}
        </Item>

        {/* 대상 가맹점 */}
        <Item label="Target Merchants" {...formItemLayout} style={{ marginBottom: 20 }} required>
          {merchantError && (
            <Fragment>
              <Alert message="Oops!" description="Failed to load Merchants." type="warning" showIcon />

              <Divider />

              <Button icon="redo" onClick={this.getMerchants}>
                Try Again
              </Button>
            </Fragment>
          )}

          {!merchantError && (
            <Fragment>
              <Item>
                <Input
                  ref={node => (this.input.searchMerchant = node)}
                  placeholder="Search Keyword"
                  suffix={<Icon type="search" />}
                  onChange={this.updateMerchantFilter}
                  autoComplete="off"
                />
              </Item>
              <Item>
                {merchantLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
                <ListSelect
                  error={this.getValidationError('merchants')}
                  items={filteredMerchants.map(merchant => ({
                    name: merchant.displayName || merchant.name,
                    value: merchant.id,
                  }))}
                  onClick={this.handleSelectMerchant}
                  value={selectedMerchants}
                  multiSelect
                />
              </Item>
            </Fragment>
          )}
        </Item>
      </Fragment>
    );
  }

  renderCapOption() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const data = this.state.data || {};
    const { amountCap, countCap } = data;
    const periodCapsPerUser = data.periodCapsPerUser || [];

    // 결제 금액대별 가맹점 할인분담금
    const renderPeriodCapsPerUser = periodCapsPerUser.map((condition, index) => (
      <Item
        key={`condition-${index}`} // eslint-disable-line
        label={index === 0 && 'Period Cap'}
        {...(index === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
      >
        <Col span={3}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>Max</span>
        </Col>
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`periodCapsPerUser[${index}].count`, {
              initialValue: periodCapsPerUser[index].count,
              rules: [required(), isInteger(), isBetween(1, 10000000)],
            })(<InputNumber placeholder="# of luckies" min={0} max={10000000} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}> in </span>
        </Col>
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`periodCapsPerUser[${index}].period`, {
              initialValue: periodCapsPerUser[index].period,
              rules: [required(), isInteger(), isBetween(1, 10000000)],
            })(<InputNumber placeholder="day count" min={0} max={10000000} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}> days</span>
        </Col>
        <Col span={1} />
        <Col span={1} offset={1}>
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={this.removePeriodCapsPerUser.bind(this, index)}
          />
        </Col>
      </Item>
    ));

    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Promotion Cap option</Divider>
        </Form.Item>

        {/* 금액 예산 */}
        <Item label="Total Amount Cap" {...formItemLayout} required>
          {getFieldDecorator('amountCap', { initialValue: amountCap, rules: [required()] })(<InputNumber min={1} />)}
        </Item>

        {/* 당첨 대상자 수 */}
        <Item label="Total User Cap" {...formItemLayout} required>
          {getFieldDecorator('countCap', { initialValue: countCap, rules: [required()] })(<InputNumber min={1} />)}
        </Item>

        {/* 일자 별 최대 획득 */}
        {renderPeriodCapsPerUser}
        <Form.Item
          label={periodCapsPerUser.length === 0 && 'Period Cap'}
          {...(periodCapsPerUser.length === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        >
          <Button type="dashed" onClick={this.addPeriodCapCondition} style={{ width: '100%' }}>
            <Icon type="plus" /> Add condition
          </Button>
        </Form.Item>
      </Fragment>
    );
  }

  renderBenefitOption() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const data = this.state.data || {};
    const probabilities = data.probabilities || [];
    const winPromotion = get(data, 'winPromotion', null);
    const losePromotion = get(data, 'losePromotion', null);

    // 당첨 확률
    const renderProbabilities = probabilities.map((probability, index) => (
      <Item
        key={`probability-${index}`} // eslint-disable-line
        label={index === 0 && 'Probability'}
        {...(index === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        required
      >
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`probabilities[${index}].min`, {
              initialValue: +probability.min === 0 ? 0 : probability.min || null,
              rules: [isInteger()],
            })(<InputNumber placeholder="Min" min={0} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
        </Col>
        <Col span={7}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`probabilities[${index}].max`, {
              initialValue: +probability.max === 0 ? 0 : probability.max || null,
              rules: [isInteger()],
            })(<InputNumber placeholder="Max" min={0} formatter={value => commify(value)} />)}
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`probabilities[${index}].probability`, {
              initialValue: probability.probability ? +probability.probability * 100 : null,
              rules: [required(), isFloat(), isBetween(0, 100)],
            })(<InputNumber placeholder="100" min={0} max={100} />)}
          </Form.Item>
        </Col>
        <Col span={1}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>%</span>
        </Col>
        <Col span={1} offset={1}>
          <Icon
            className="dynamic-delete-button"
            type="minus-circle-o"
            onClick={this.removeProbabilities.bind(this, index)}
          />
        </Col>
      </Item>
    ));
    return (
      <Fragment>
        <Form.Item {...formItemDividerLayout}>
          <Divider>Benefit option</Divider>
        </Form.Item>

        {/* 이길 경우 프로모션 제목 */}
        <Item label="Win Promotion Title" {...formItemLayout}>
          {getFieldDecorator('winPromotion.title', {
            initialValue: winPromotion ? winPromotion.title : '',
            rules: [required()],
          })(<Input placeholder="Win Promotion Title" autoComplete="off" />)}
        </Item>
        {/* rules: [isInteger(), isBetween(0, 100)]
            })(<InputNumber placeholder="Min" min={0} max={100} formatter={value => commify(value)} />)} */}

        {/* 이길 경우 프로모션 benefit % */}
        <Item label="Win Promotion discount" {...formItemLayout}>
          <Col span={6}>
            {getFieldDecorator('winPromotion.discount', {
              initialValue: winPromotion ? +winPromotion.discount.replace('%', '') : null,
              rules: [required(), isInteger(), isBetween(0, 100)],
            })(<InputNumber min={0} max={100} />)}
          </Col>
          <Col span={2}> %</Col>
        </Item>

        <Item label="Win Benefit Cap" {...formItemLayout}>
          {getFieldDecorator('winPromotion.maxDiscountAmount', {
            initialValue: winPromotion ? winPromotion.maxDiscountAmount : null,
            rules: [required()],
          })(<InputNumber min={0} />)}
        </Item>

        {/* 질 경우 프로모션 제목 */}
        <Item label="Lose Promotion Title" {...formItemLayout}>
          {getFieldDecorator('losePromotion.title', {
            initialValue: losePromotion ? losePromotion.title : '',
            rules: [required()],
          })(<Input placeholder="Lose Promotion Title" autoComplete="off" />)}
        </Item>

        {/* 질 경우 프로모션 benefit */}
        <Item label="Lose Promotion Benefit" {...formItemLayout}>
          <Col span={6}>
            {getFieldDecorator('losePromotion.discount', {
              initialValue: losePromotion ? +losePromotion.discount.replace('%', '') : null,
              rules: [required(), isInteger(), isBetween(0, 100)],
            })(<InputNumber min={0} max={100} />)}
          </Col>
          <Col span={2}> %</Col>
        </Item>

        <Item label="Lose Benefit Cap" {...formItemLayout}>
          {getFieldDecorator('losePromotion.maxDiscountAmount', {
            initialValue: losePromotion ? losePromotion.maxDiscountAmount : null,
            rules: [required()],
          })(<InputNumber min={0} />)}
        </Item>

        {/* 일자 별 최대 획득 가능한 lucky 갯수 */}
        {renderProbabilities}
        <Form.Item
          label={probabilities.length === 0 && 'Probability'}
          {...(probabilities.length === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        >
          <Button type="dashed" onClick={this.addProbabilities} style={{ width: '100%' }}>
            <Icon type="plus" /> Add condition
          </Button>
        </Form.Item>
      </Fragment>
    );
  }

  render() {
    const { updating } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} className={styles.promotionForm} colon={false}>
          {this.renderPromotionOption()}
          {this.renderTargetUserOption()}
          {this.renderCapOption()}
          {this.renderBenefitOption()}

          <div className={styles.formButtonContainer}>
            <Button type="ghost" onClick={this.closeTab}>
              Cancel
            </Button>

            <Divider type="vertical" style={{ background: '#fff' }} />

            <Button type="primary" htmlType="submit" disabled={updating}>
              {id ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
      </Fragment>
    );
  }
}

export default LuckyPromotionForm;
