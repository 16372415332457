import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col } from 'antd';
import { rowProps, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { getBoostCategories, setFilter, resetFilter } from 'redux/modules/boostCategory/actions';
import { defaultFilter } from 'redux/modules/boostCategory/reducer';
import styles from './BoostCategory.scss';

const { Item } = Form;

const mapStateToProps = state => {
  const { pageSize, filter } = state.boostCategory;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getBoostCategories,
})
@autobind
class BoostCategoryFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getBoostCategories: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getBoostCategories(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getBoostCategories(1, pageSize, {
      ...defaultFilter,
    });

    this.props.form.resetFields();
  }

  render() {
    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          <Col {...col2RightProps}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default BoostCategoryFilterForm;
