import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';

const columns = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: function renderTitle(val, row) {
      return <Link to={`/promotion/airdrop/${row.id}`}>{val}</Link>;
    },
    width: '30%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Amount',
    dataIndex: 'discount',
    align: 'right',
    width: '20%',
    render(val) {
      return <span>{val && val.indexOf('%') > -1 ? val : `${commify(val)} 원`}</span>;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    width: '20%',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
  },
];

function getAirdropColumns(options = {}) {
  const { cancelAirdrop } = options;
  const cols = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Manager',
      dataIndex: 'manager',
      key: 'manager',
      width: '20%',
    },
    {
      title: 'Issue At',
      dataIndex: 'issueAt',
      key: 'issueAt',
      width: '25%',
      render(val) {
        return <span>{formatDate(val, 'YYYY-MM-DD HH:00')}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
    },
    {
      title: 'File',
      dataIndex: 'data',
      key: 'data',
      width: '30%',
      render(val) {
        const { userIdFilePath, resultFilePath } = val;
        const CHAI_STATIC_URL = 'https://static.chai.finance';
        return (
          <div>
            <a href={`${CHAI_STATIC_URL}/${userIdFilePath}`}>Target User</a>
            <br />
            <a href={`${CHAI_STATIC_URL}/${resultFilePath}`}>Result</a>
          </div>
        );
      },
    },
    {
      title: '',
      align: 'center',
      width: '15%',
      render(val, others) {
        const { id, status } = others;
        return (
          status === 'scheduled' && (
            <Button type="danger" ghost onClick={cancelAirdrop.bind(null, id)}>
              Cancel
            </Button>
          )
        );
      },
    },
  ];
  return cols;
}

export default {
  getAirdropColumns,
  columns,
};
