import { getContext } from 'recompose';
import PropTypes from 'prop-types';
import Socket from './socket';

export const socketPropType = PropTypes.instanceOf(Socket);
export const apiClientPropType = PropTypes.func;

const withNetwork = getContext({
  socket: socketPropType,
  apiClient: apiClientPropType,
});

export default withNetwork;
