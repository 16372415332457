export default {
  $id: 'action-edit-schema',
  title: 'action-edit-schema',
  description: 'action-edit-schema.',
  type: 'object',
  required: ['name', 'code'],
  properties: {
    id: {
      type: 'string',
    },
    menuId: {
      type: 'string',
      title: 'Menu Id',
      description: 'menu',
    },
    menu: {
      type: 'string',
      title: 'menu name',
    },
    groupIds: {
      type: 'string',
      title: 'Group Id',
      description: 'group',
    },
    name: {
      type: 'string',
      title: 'action',
      maxLength: 25,
      minLength: 1,
    },
    code: {
      type: 'string',
      title: 'action code',
      maxLength: 25,
      minLength: 1,
    },
    description: {
      type: 'string',
      title: 'action description',
      maxLength: 300,
    },
  },
};
