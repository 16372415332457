import React from 'react';
import { bool, arrayOf, any, number } from 'prop-types';
import get from 'lodash/get';
import { commify } from 'utils/stringUtil';
import styles from './Top20ProductsChart.scss';

const CustomTooltip = ({ active, payload, transactionVolume }) => {
  const name = get(payload[0], 'payload.key', '');
  const value = get(payload[0], 'value', 0);
  const percentage = (value / transactionVolume) * 100;

  if (active) {
    return (
      <div className={styles.tooltip}>
        <div>{name}</div>
        <div>
          {commify(value)} ({percentage.toFixed(2)}%)
        </div>
      </div>
    );
  }

  return null;
};
CustomTooltip.propTypes = {
  active: bool,
  payload: arrayOf(any),
  transactionVolume: number,
};
CustomTooltip.defaultProps = {
  active: false,
  payload: [],
  transactionVolume: 0,
};

export default CustomTooltip;
