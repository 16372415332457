import gql from 'graphql-tag';

export const merchantListQuery = gql`
  query merchantList($filter: String, $skip: Int, $pageSize: Int) {
    merchantList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        status
        name
        displayName
        countryCode
        vertical
        website
        managerName
        managerEmail
        managerPhone
        totalTxVol
        totalBenefit
        share
        aov
        customerServicePhone
        pgMerchantCode
        linkMethod
        funnel
        contracts {
          id
          updatedAt
          type
          settlementCycle
          autoExtension
          fee
          termStartAt
          termEndAt
          documentUrl
        }
      }
      total
    }
  }
`;

export default {
  merchantListQuery,
};
