import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Form, Input, Row, Col, Button, Upload, Icon, Popover, message } from 'antd';
import autobind from 'autobind-decorator';
import config from 'config';
import { Editor, Viewer } from '@toast-ui/react-editor';

import apolloClient from 'helpers/apolloClient';
import { hideI18nModal } from 'redux/modules/i18n/actions';
import { i18nListQuery, translationQuery } from 'redux/modules/i18n/queries';
import { i18nSaveMutation, i18nUploadImageMutation } from 'redux/modules/i18n/mutations';

const defaultEditorConfig = {
  initialEditType: 'wysiwyg',
  hideModeSwitch: true,
  height: 200,
  usageStatistics: false,
  toolbarItems: [
    'heading',
    'bold',
    'italic',
    'hr',
    'quote',
    'divider',
    'ul',
    'ol',
    'indent',
    'outdent',
    'divider',
    'table',
  ],
};

const mapStateToProps = state => state.i18n;

const mapDispatchToProps = {
  hideI18nModal,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class I18nModal extends Component {
  static propTypes = {
    hideI18nModal: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    visibleModal: PropTypes.bool.isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    tableName: PropTypes.string,
    targetId: PropTypes.string,
    columnName: PropTypes.string,
    currentValue: PropTypes.string,
    editorConfig: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    type: null,
    title: null,
    tableName: null,
    targetId: null,
    columnName: null,
    currentValue: null,
    editorConfig: null,
  };

  constructor(props) {
    super(props);

    config.supportLocales.forEach(locale => {
      this[`${locale}_ref`] = createRef();
    });
  }

  state = {
    data: [],
    checkDeleteFiles: [],
  };

  async componentWillReceiveProps(nextProps) {
    if (nextProps.visibleModal && this.props.visibleModal !== nextProps.visibleModal) {
      this.props.form.resetFields();

      const data = await this.getI18nList(nextProps.tableName, nextProps.targetId, nextProps.columnName);

      if (nextProps.type === 'editor') {
        config.supportLocales.forEach(locale => {
          const findData = data.find(i18n => i18n.locale === locale);
          this[`${locale}_ref`].current.getInstance().setHtml(findData && findData.text);
        });
      }

      this.setState({ data, checkDeleteFiles: [] });
    }
  }

  async getI18nList(tableName, targetId, columnName) {
    const { data } = await apolloClient.query({
      query: i18nListQuery,
      variables: {
        tableName,
        targetId,
        columnName,
      },
    });

    return data.i18nList;
  }

  async translate(query, locale) {
    const { data } = await apolloClient.query({
      query: translationQuery,
      variables: {
        query,
        locale,
        format: this.props.type === 'editor' ? 'html' : 'text',
      },
    });

    if (this.props.type === 'editor') {
      if (this[`${locale}_ref`]) {
        this[`${locale}_ref`].current.getInstance().setHtml(data.translation);
      }
    } else {
      this.props.form.setFieldsValue({
        [locale]: data.translation,
      });
    }
  }

  async uploadImage(blob, callback) {
    let filePath = '';
    let fileUrl = '';
    try {
      const result = await apolloClient.mutate({
        mutation: i18nUploadImageMutation,
        variables: {
          imageFile: blob,
        },
      });

      const { i18nUploadImage: uploadedFile } = result.data;
      filePath = `${uploadedFile.path}/${uploadedFile.filename}`;
      fileUrl = `https://static.chai.finance/${filePath}`;

      if (callback) {
        callback(fileUrl, fileUrl);
      }
    } catch (error) {
      message.error(`Failed to upload image file: ${error.message}`);
      console.log(error);
    }

    return filePath;
  }

  async saveI18n(i18n) {
    const { data } = await apolloClient.mutate({
      mutation: i18nSaveMutation,
      variables: i18n,
    });

    return data.i18nSave;
  }

  hideModal() {
    this.props.hideI18nModal();

    const newState = config.supportLocales.reduce(
      (acc, locale) => ({ ...acc, [`${locale}_ref`]: null, [`${locale}_file`]: null }),
      {}
    );

    this.setState(newState);
  }

  async handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const formFields = this.props.form.getFieldsValue();

    await Promise.all(
      config.supportLocales.map(async locale => {
        let text = formFields[locale] || '';
        if (this.props.type === 'editor') {
          text = this[`${locale}_ref`].current.getInstance().getHtml();
        }

        if (this.props.type === 'file') {
          if (this.state[`${locale}_file`]) {
            text = await this.uploadImage(this.state[`${locale}_file`]);
          } else if (this.state.checkDeleteFiles.includes(locale)) {
            text = '';
          } else {
            return false;
          }
        }

        return this.saveI18n({
          tableName: this.props.tableName,
          targetId: this.props.targetId,
          locale,
          columnName: this.props.columnName,
          text,
        });
      })
    );

    this.hideModal();
  }

  checkDeleteFile(locale) {
    this.setState({
      data: this.state.data.filter(i18n => i18n.locale !== locale),
      checkDeleteFiles: [...this.state.checkDeleteFiles, locale],
    });
  }

  renderImage(url, deleteLocale) {
    if (!url) {
      return <></>;
    }

    return (
      <div style={{ marginBottom: 5 }}>
        <span style={{ position: 'relative', display: 'inline-block' }}>
          {deleteLocale && (
            <Button
              icon="delete"
              size="small"
              onClick={() => this.checkDeleteFile(deleteLocale)}
              style={{ position: 'absolute', top: 2, right: 2 }}
            />
          )}
          <Popover content={<img src={`https://static.chai.finance/${url}`} style={{ maxHeight: 400 }} alt="" />}>
            <img alt="" src={`https://static.chai.finance/${url}`} style={{ maxHeight: 100 }} />
          </Popover>
        </span>
      </div>
    );
  }

  renderInput(id, options, props = {}) {
    let { type } = this.props;
    if (id === 'default') {
      if (type === 'file') {
        type = 'image';
      }
      if (type === 'editor') {
        type = 'viewer';
      }
    }

    const editorConfig = {
      ...(this.props.editorConfig || defaultEditorConfig),
      height: 200,
      placeholder: '',
      hooks: {
        addImageBlobHook: this.uploadImage,
      },
    };

    switch (type) {
      case 'textarea':
        return this.props.form.getFieldDecorator(id, options)(<Input.TextArea {...props} />);

      case 'file':
        return (
          <>
            {this.renderImage(options.initialValue, id)}
            <Upload
              onChange={({ file }) => file.status !== 'removed' && this.setState({ [`${id}_file`]: file })}
              onRemove={() => this.setState({ [`${id}_file`]: null })}
              beforeUpload={() => false}
              // fileList={androidImageFile && [androidImageFile]}
              accept=".jpg,.png"
            >
              <Button>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </>
        );

      case 'image':
        return this.renderImage(this.props.currentValue);

      case 'editor':
        return <Editor ref={this[`${id}_ref`]} {...editorConfig} {...options} {...props} />;

      case 'viewer':
        return (
          <div style={{ height: 200, padding: 10, border: '1px solid #e5e5e5', overflow: 'auto' }}>
            <Viewer {...options} />
          </div>
        );

      default:
        return this.props.form.getFieldDecorator(id, options)(<Input {...props} />);
    }
  }

  render() {
    const { title, currentValue, visibleModal } = this.props;
    const { data } = this.state;

    return (
      <Modal title={title} okText="Save" onOk={this.handleSubmit} onCancel={this.hideModal} visible={visibleModal}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Default" style={{ marginBottom: 10 }}>
            {this.renderInput(
              'default',
              { initialValue: currentValue },
              { placeholder: 'Default value', disabled: true }
            )}
          </Form.Item>

          {config.supportLocales.map(locale => {
            const findData = data.find(i18n => i18n.locale === locale);

            return (
              <div key={locale} style={{ marginBottom: 10 }}>
                <Row type="flex" align="middle" justify="space-between" style={{ marginBottom: 5 }}>
                  <Col>{locale}:</Col>
                  {this.props.type !== 'file' && (
                    <Col>
                      <Button size="small" icon="retweet" onClick={() => this.translate(currentValue, locale)} />
                    </Col>
                  )}
                </Row>
                {this.renderInput(locale, { initialValue: findData && findData.text })}
              </div>
            );
          })}
        </Form>
      </Modal>
    );
  }
}

export default I18nModal;
