import { message } from 'antd';
import { onError } from 'apollo-link-error';
import gql from 'graphql-tag';
import store from 'store';
import get from 'lodash/get';
import { push } from 'client';
import { ClientErrorCodes } from 'error';
import apolloClient from '../apolloClient';

const logoutMutation = gql`
  mutation logout {
    logout
  }
`;

const errorHandleLink = onError(info => {
  const { networkError, graphQLErrors } = info;

  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const { extensions, message: msg } = graphQLErrors[i];

      if (extensions.exception) {
        const { exception } = extensions;
        message.error(`${msg || exception.message} (${exception.name}: ${exception.type} ${exception.code})`);
      } else {
        message.error(msg);
      }
    }
  } else if (networkError) {
    if (networkError instanceof Error) {
      message.error(networkError.message);
      console.log(networkError);
      return;
    }

    const { errors } = networkError.result;

    for (let i = 0; i < errors.length; i += 1) {
      message.error(errors[i].message);
    }
  }

  // Force to logout on unauthorized request
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i += 1) {
      const code = get(graphQLErrors[i], 'extensions.exception.code');

      if (
        store.get('admin') &&
        (code === ClientErrorCodes.INACITVE_ADMIN || code === ClientErrorCodes.SESSION_EXPIRED)
      ) {
        const msg =
          code === ClientErrorCodes.INACITVE_ADMIN
            ? 'You were logged out because your account is blocked.'
            : "You were logged out because you don't have any recent activity.";
        store.remove('admin');

        apolloClient
          .mutate({ mutation: logoutMutation })
          .then(() => {
            push('/login');
            message.info(msg);
          })
          .catch(err => console.log('Failed to logout', err));
        break;
      }
    }
  }
});

export default errorHandleLink;
