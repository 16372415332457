import gql from 'graphql-tag';

export const luckyPromotionAddMutation = gql`
  mutation luckyPromotionAdd(
    $title: String!
    $description: String!
    $status: String!
    $startAt: Date!
    $endAt: Date!
    $priceMin: Int
    $priceMax: Int
    $amountCap: Int
    $countCap: Int
    $expirationMinute: Int!
    $isFirstForLifetime: Boolean
    $isFirstForMerchant: Boolean
    $totalPaymentCount: Int
    $totalMerchantPaymentCount: Int
    $periodCapsPerUser: [PeriodCapInput]
    $probabilities: [ProbabilityInput]
    $winPromotion: PromotionInput
    $losePromotion: PromotionInput
    $merchantIds: [ID]!
  ) {
    luckyPromotionAdd(
      title: $title
      description: $description
      status: $status
      startAt: $startAt
      endAt: $endAt
      priceMin: $priceMin
      priceMax: $priceMax
      amountCap: $amountCap
      countCap: $countCap
      expirationMinute: $expirationMinute
      isFirstForLifetime: $isFirstForLifetime
      isFirstForMerchant: $isFirstForMerchant
      totalPaymentCount: $totalPaymentCount
      totalMerchantPaymentCount: $totalMerchantPaymentCount
      periodCapsPerUser: $periodCapsPerUser
      probabilities: $probabilities
      winPromotion: $winPromotion
      losePromotion: $losePromotion
      merchantIds: $merchantIds
    )
  }
`;

export const luckyPromotionUpdateMutation = gql`
  mutation luckyPromotionUpdate(
    $id: ID!
    $title: String!
    $description: String!
    $status: String!
    $startAt: Date!
    $endAt: Date!
    $priceMin: Int
    $priceMax: Int
    $amountCap: Int
    $countCap: Int
    $expirationMinute: Int
    $isFirstForLifetime: Boolean
    $isFirstForMerchant: Boolean
    $totalPaymentCount: Int
    $totalMerchantPaymentCount: Int
    $periodCapsPerUser: [PeriodCapInput]
    $probabilities: [ProbabilityInput]
    $winPromotion: PromotionInput
    $losePromotion: PromotionInput
    $merchantIds: [ID]!
  ) {
    luckyPromotionUpdate(
      id: $id
      title: $title
      description: $description
      status: $status
      startAt: $startAt
      endAt: $endAt
      priceMin: $priceMin
      priceMax: $priceMax
      amountCap: $amountCap
      countCap: $countCap
      expirationMinute: $expirationMinute
      isFirstForLifetime: $isFirstForLifetime
      isFirstForMerchant: $isFirstForMerchant
      totalPaymentCount: $totalPaymentCount
      totalMerchantPaymentCount: $totalMerchantPaymentCount
      periodCapsPerUser: $periodCapsPerUser
      probabilities: $probabilities
      winPromotion: $winPromotion
      losePromotion: $losePromotion
      merchantIds: $merchantIds
    )
  }
`;

export default {
  luckyPromotionAddMutation,
  luckyPromotionUpdateMutation,
};
