import apolloClient from 'helpers/apolloClient';
import { locationCollectHistoryListQuery } from './queries';

export const FETCH_LOCATION_COLLECT_HISTORY = 'locationCollectHistory/FETCH';
export const FETCH_LOCATION_COLLECT_HISTORY_SUCCESS = 'locationCollectHistory/FETCH_SUCCESS';
export const FETCH_LOCATION_COLLECT_HISTORY_FAILURE = 'locationCollectHistory/FETCH_FAILURE';

export const SET_FILTER = 'locationCollectHistory/SET_FILTER';
export const RESET_FILTER = 'locationCollectHistory/RESET_FILTER';

export const SET_CURRENT_PAGE = 'locationCollectHistory/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'locationCollectHistory/SET_PAGE_SIZE';

export function getLocationCollectHistoryList(currentPage, pageSize, filter) {
  return {
    types: [
      FETCH_LOCATION_COLLECT_HISTORY,
      FETCH_LOCATION_COLLECT_HISTORY_SUCCESS,
      FETCH_LOCATION_COLLECT_HISTORY_FAILURE,
    ],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: locationCollectHistoryListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.locationCollectHistoryList;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
