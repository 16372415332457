import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row, Col, Input, Button } from 'antd';
import autobind from 'autobind-decorator';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getMerchants } from 'redux/modules/merchant/actions';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import styles from './Merchant.scss';

const { Item } = Form;

const mapStateToProps = state => {
  const { pageSize, filter } = state.merchant;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getMerchants,
})
@autobind
class MerchantFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getMerchants: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getMerchants(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getMerchants(1, pageSize, null);

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { query } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col {...col4Props} sm={24} md={24}>
            <Item>
              {getFieldDecorator('query', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: query,
              })(
                <Input placeholder="ID or Merchant Name or Admin Name" autoComplete="off" style={{ width: '100%' }} />
              )}
            </Item>
          </Col>
          <Col {...col4Props} xl={3}>
            <Item>
              <SearchButton />
            </Item>
          </Col>
          <Col {...col4Props} xl={3}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default MerchantFilterForm;
