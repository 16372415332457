export const statementHeader = [
  '처리된 시간',
  '입금 시간',
  '입금자 이름',
  '입금(D)/출금(W)',
  '충전금액/환급금액',
  '입/출금 금액',
  '입/출금 통화',
];

export const settlementHeader = [
  '업체코드',
  '결제 시간',
  '결제 방식',
  '업체 주문번호',
  '차이 주문번호',
  '결제(P) / 취소(C)',
  '결제 금액 (절대값)',
  '원거래 일시',
  '결과 코드',
  '대금 지급일',
  '결제 금액',
  '프로모션 분담금',
  '부분취소 여부',
  '거래 아이디 (트랜잭션아이디)',
  '초기 머천트 캐시백',
  '머천트 부담 캐시백',
  '건별 수수료',
  '건별 수수료 부가세',
  '비과세 금액',
];

export const taxInvoiceHeader = ['결제일', '지급일', '결제금액', '수수료', '수수료VAT', '수수료합계', '입금예정액'];

export const cashReceiptHeader = [
  '결제 시간',
  '차이 주문번호',
  '업체 주문번호',
  '결제(P) / 취소(C) / 부분취소 (PC)',
  '발행금액',
  '승인번호',
  '원거래발생시간',
];

export const monthlyTotalHeader = [
  'Sales Amount(승인-취소)',
  'Sales Transactions(승인건수)',
  'Refund Amount(취소금액)',
  'Refund Transactions(취소건수)',
  'Billing Fees Amount(결제수수료 VAT 별도)',
  'Total Successful Transactions(인증성공건수)',
];

export const bulkCreateResultHeader = ['머천트 ID', '머천트 이름', 'publicKey', 'privateKey', '결과'];

export function arrayToCSV(arr) {
  let result = '';

  while (arr.length) {
    const row = arr.shift();

    result += `${row.join(',')}\n`;
  }

  return result;
}

export function arrayToExcel(arr) {
  const header = arr.shift();
  let result = '<html xmlns:x="urn:schemas-microsoft-com:office:excel">';
  result += '<head><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>';
  result += '<x:Name>Sheet1</x:Name>';
  result += '<x:WorksheetOptions><x:Panes></x:Panes></x:WorksheetOptions></x:ExcelWorksheet>';
  result += '</x:ExcelWorksheets></x:ExcelWorkbook></xml></head><body>';
  result += `<table><thead><tr><th>${header.join('</th><th>')}</th></tr></thead><tbody>`;

  while (arr.length) {
    const row = arr.shift();

    result += '<tr><td style="mso-number-format:\'@\'">';
    result += row.join('</td><td style="mso-number-format:\'@\'">');
    result += '</td></tr>';
  }

  result += '</tbody></table></body></html>';

  return result;
}

export function downloadExcelFile(blobData, fileName, fileType) {
  const blob = new Blob([blobData], { type: fileType });
  const downloadElem = window.document.createElement('a');

  downloadElem.href = window.URL.createObjectURL(blob);
  downloadElem.download = fileName;

  document.body.appendChild(downloadElem);
  downloadElem.click();
  document.body.removeChild(downloadElem);
}
