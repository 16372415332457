import moment from 'moment';
import React from 'react';
import { arrayOf, any, string } from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import startCase from 'lodash/startCase';
import styles from './Charts.scss';

const PaymentsChart = ({ title, data, timeFormat }) => (
  <div className={styles.chartWrapper}>
    <div className={styles.chartName}>{title}</div>

    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        className={styles.chart}
        width={400}
        height={200}
        data={data}
        margin={{
          top: 10,
          left: 10,
          bottom: 5,
          right: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tickFormatter={timeStr => moment(timeStr).format(timeFormat)} />
        <YAxis dateKey="count" type="number" allowDecimals={false} tickCount={10} />
        <Tooltip formatter={(value, name) => [value, startCase(name)]} />
        <Bar dataKey="prepared" stackId="a" fill="#3a4d8f" />
        <Bar dataKey="approved" stackId="a" fill="#78ced3" />
        <Bar dataKey="confirmed" stackId="a" fill="#d2dbe2" />
        <Bar dataKey="canceled" stackId="a" fill="#ffdb50" />
        <Bar dataKey="timeout" stackId="a" fill="#ff5d58" />
        <Bar dataKey="churn" stackId="a" fill="#ff5d58" />
        <Legend verticalAlign="bottom" margin={{ bottom: 10 }} formatter={value => startCase(value)} />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

PaymentsChart.propTypes = {
  title: string.isRequired,
  data: arrayOf(any).isRequired,
  timeFormat: string.isRequired,
};

export default PaymentsChart;
