// Modules
import auth from './modules/auth';
import alert from './modules/alert';
import popup from './modules/popup';
import global from './modules/global';
import user from './modules/user';
import b2bUser from './modules/b2bUser';
import payment from './modules/payment';
import transaction from './modules/transaction';
import settlement from './modules/settlement';
import merchant from './modules/merchant';
import policy from './modules/policy';
import promotion from './modules/promotion';
import promotionGroup from './modules/promotionGroup';
import luckyPromotion from './modules/luckyPromotion';
import tabs from './modules/tabs';
import settlementPayment from './modules/settlementPayment';
import settlementDetail from './modules/settlementDetail';
import settlementTable from './modules/settlementTable';
import agreement from './modules/agreement';
import bannerGroup from './modules/bannerGroup';
import banner from './modules/banner';
import notice from './modules/notice';
import faq from './modules/faq';
import i18n from './modules/i18n';
import boltPolicy from './modules/boltPolicy';
import boostPromotion from './modules/boostPromotion';
import boostCategory from './modules/boostCategory';
import boltDrawPromotion from './modules/boltDrawPromotion';
import boltExclusionRule from './modules/boltExclusionRule';
import boostExclusionRule from './modules/boostExclusionRule';
import monohaBoostPromotion from './modules/monohaBoostPromotion';
import brand from './modules/brand';
import bank from './modules/bank';
import cardProduct from './modules/cardProduct';
import groupMerchant from './modules/groupMerchant';
import businessCode from './modules/businessCode';
import tag from './modules/tag';
import locationCollectHistory from './modules/locationCollectHistory';

export default function createReducers(asyncReducers) {
  return {
    auth,
    alert,
    popup,
    user,
    b2bUser,
    global,
    payment,
    transaction,
    settlement,
    merchant,
    policy,
    promotion,
    promotionGroup,
    luckyPromotion,
    tabs,
    settlementPayment,
    settlementDetail,
    settlementTable,
    agreement,
    bannerGroup,
    banner,
    notice,
    faq,
    i18n,
    boltPolicy,
    boostPromotion,
    boostCategory,
    boltDrawPromotion,
    boltExclusionRule,
    boostExclusionRule,
    monohaBoostPromotion,
    brand,
    bank,
    cardProduct,
    groupMerchant,
    businessCode,
    tag,
    locationCollectHistory,
    ...asyncReducers,
  };
}
