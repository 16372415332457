import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

const getStatus = status =>
  ({
    active: 'success',
    ready: 'default',
    expired: 'error',
  }[status]);

export const columns = [
  {
    title: 'Bolt Policy ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Bolt Policy Name',
    dataIndex: 'title',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Period',
    dataIndex: 'startAt',
    render: function renderPeriod(startAt, others) {
      const { endAt } = others;
      const startDate = formatDate(startAt, 'YYYY-MM-DD');
      const endDate = endAt ? formatDate(endAt, 'YYYY-MM-DD') : '';

      return (
        <span>
          {startDate} ~ {endDate}
        </span>
      );
    },
    width: '20%',
  },
  {
    title: 'Target Merchants',
    dataIndex: 'boltPolicyMerchant',
    render: function renderMerchant(boltPolicyMerchant) {
      return (
        <span>{boltPolicyMerchant.length > 0 && boltPolicyMerchant.map(bpm => bpm.merchant.name).join(', ')}</span>
      );
    },
    width: '25%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </span>
      );
    },
    width: '10%',
    align: 'center',
  },
];

export default {
  columns,
};
