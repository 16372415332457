import gql from 'graphql-tag';

export const saveAdminMutation = gql`
  mutation saveAdmin(
    $id: ID
    $uid: String
    $password: String
    $fullname: String!
    $phone: String!
    $email: String!
    $status: String
    $merchantIds: [ID]
    $allowedIp: [String]
    $isMerchantGroup: Boolean
  ) {
    saveAdmin(
      id: $id
      uid: $uid
      password: $password
      fullname: $fullname
      phone: $phone
      email: $email
      status: $status
      merchantIds: $merchantIds
      allowedIp: $allowedIp
      isMerchantGroup: $isMerchantGroup
    ) {
      id
      fullname
    }
  }
`;

export const updateAdminGroupMutation = gql`
  mutation updateAdminGroup($groupId: ID!, $adminId: ID!, $operation: Int!) {
    updateAdminGroup(groupId: $groupId, adminId: $adminId, operation: $operation) {
      groups {
        id
        name
        code
        description
      }
    }
  }
`;

export const setAdminAllowedIpMutation = gql`
  mutation setAdminAllowedIp($id: ID!, $ipList: [String]!) {
    setAdminAllowedIp(id: $id, ipList: $ipList)
  }
`;

export default {
  saveAdminMutation,
  updateAdminGroupMutation,
  setAdminAllowedIpMutation,
};
