import gql from 'graphql-tag';

export const boltExclusionRuleListQuery = gql`
  query boltExclusionRuleListQuery($filter: String, $skip: Int, $pageSize: Int) {
    boltExclusionRuleList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        policyType
        receiverType
        codeType
        code
        period
        limit
        maxBoltCount
      }
      total
    }
  }
`;

export const boltExclusionRuleQuery = gql`
  query boltExclusionRuleQuery($id: ID!) {
    boltExclusionRule(id: $id) {
      id
      policyType
      receiverType
      codeType
      code
      period
      limit
      maxBoltCount
    }
  }
`;

export default {
  boltExclusionRuleQuery,
  boltExclusionRuleListQuery,
};
