import React from 'react';
import PropTypes from 'prop-types';
import apolloClient from 'helpers/apolloClient';
import gql from 'graphql-tag';
import { Modal, Tree } from 'antd';
import { GET_ALL_MENU_LIST } from 'gql';

const { TreeNode } = Tree;

class EditModal extends React.PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    handFormSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    title: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  state = {
    menuList: [],
  };
  async componentWillReceiveProps(nextProps) {
    if (!nextProps.visible) {
      return;
    }

    const groupId = nextProps.formData.id;

    const { data: menuData } = await apolloClient.query({
      query: GET_ALL_MENU_LIST,
    });

    const { getAllMenuList: allMenuList } = menuData;

    const { data: groupData } = await apolloClient.query({
      query: gql`
        query getGroupMenus {
          getGroupMenus(id: "${groupId}") {
            id
            code
            name
            menus {
              id
              code
            }
          }
        }`,
    });
    const { getGroupMenus: groupMenus } = groupData;

    const groupMenusStr = groupMenus.menus.map(m => m.id);

    this.defaultCheckKeys = groupMenusStr;
    this.checkedKeys = groupMenusStr;
    this.setState({
      menuList: allMenuList,
    });
  }
  defaultCheckKeys = [];
  checkedKeys = [];
  onCancel = () => {
    this.props.onCancel();
    this.setState({
      menuList: [],
    });
    this.defaultCheckKeys = [];
    this.checkedKeys = [];
  };
  onOk = async () => {
    const data = {
      groupId: this.props.formData.id,
      menuIds: this.checkedKeys,
    };
    await this.props.handFormSubmit(data);
    this.setState({
      menuList: [],
    });
    this.defaultCheckKeys = [];
    this.checkedKeys = [];
  };

  onCheck = (checkedKeys, e) => {
    this.checkedKeys = [...checkedKeys, ...e.halfCheckedKeys];
  };
  renderTreeNode = menuList => {
    const list = [];

    for (let i = 0; i < menuList.length; i += 1) {
      const item = menuList[i];

      if (item.children && item.children.length > 0) {
        list.push(
          <TreeNode className="clear-both" title={<span>{item.name}</span>} key={item.id}>
            {this.renderTreeNode(item.children)}
          </TreeNode>
        );
      } else {
        list.push(<TreeNode className="clear-both" title={<span>{item.name}</span>} key={item.id} />);
      }
    }

    return list;
  };
  renderTree = () => (
    <Tree
      checkable
      multiple
      defaultExpandAll
      defaultCheckedKeys={this.defaultCheckKeys}
      onCheck={this.onCheck}
      selectable={false}
    >
      {this.renderTreeNode(this.state.menuList)}
    </Tree>
  );
  render() {
    return (
      <Modal
        width={800}
        visible={this.props.visible}
        cancelText="Close"
        okText="Save"
        title={this.props.title}
        onCancel={this.onCancel}
        onOk={this.onOk}
        destroyOnClose
      >
        {this.state.menuList.length > 0 ? this.renderTree() : null}
      </Modal>
    );
  }
}

export default EditModal;
