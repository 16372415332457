import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { message, Alert } from 'antd';
import autobind from 'autobind-decorator';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';

import { boostUpUserHistoryQuery } from '../UserQueries';
import { columns } from './UserBoostUpListMetadata';

@autobind
class BoostUpList extends PureComponent {
  static propTypes = {
    userId: string.isRequired,
  };

  state = {
    data: [],
    error: null,
    loading: false,
    page: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.getData(page, pageSize);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   * @param {Number} page - Page to load
   * @param {Number} pageSize - Page size
   */
  async getData(page, pageSize) {
    try {
      this.setState({ loading: true, error: null });

      const { page: oldPage } = this.state;
      const { userId } = this.props;

      this.query = cancelableQuery({
        query: boostUpUserHistoryQuery,
        variables: {
          filter: JSON.stringify({
            userId: userId.replace('CS-', ''),
          }),
          skip: (page - 1) * pageSize,
          pageSize,
        },
      });

      const result = await this.query;
      const data = result.data.boostUpUserHistory.list;

      this.setState({
        loading: false,
        data: [...this.state.data, ...data],
        page: data.length === 0 ? oldPage : page, // Does not update page if there's no data
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get BoostUp User History');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  getNextPage() {
    const { page, pageSize } = this.state;
    this.getData(page + 1, pageSize);
  }

  render() {
    const { data, error, loading } = this.state;

    return (
      <>
        {error && (
          <Alert
            description="Failed to load BoostUp User History Data."
            type="warning"
            showIcon
            closable
            style={{ marginBottom: 10 }}
          />
        )}

        <InfiniteScrollableTable
          id="user-boostuphistory-list-table"
          columns={columns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </>
    );
  }
}

export default BoostUpList;
