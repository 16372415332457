import React from 'react';
import LinkText from 'components/LinkText';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Policy Type',
    dataIndex: 'policyType',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Receiver Type',
    dataIndex: 'receiverType',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Code Type',
    dataIndex: 'codeType',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Period',
    dataIndex: 'period',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Limit',
    dataIndex: 'limit',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
];

export default {
  columns,
};
