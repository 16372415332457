import { Button, Col, DatePicker, Form, Row, Alert, Select } from 'antd';
import autobind from 'autobind-decorator';
import { formItemLayout } from 'common/formlayout';
import { push } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { boostPromotionListQuery, listQueryDefault } from 'redux/modules/boostPromotion/queries';
import { col3Props, col5Props, rowProps } from 'ui/ResponsiveProps';
import { graphListQueryToState } from 'utils/graphToStateUtil';
import BoostPreviewItem from './BoostPreviewItem';

const { Item } = Form;
const { Option } = Select;

const mapDispatchToProps = {
  push,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class BoostPreviewContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    ...listQueryDefault,
  };

  componentDidMount() {
    this.getBoostList();
  }

  componentWillUnmount() {}

  async getBoostList() {
    const { form } = this.props;
    const { VisibleAt, status } = form.getFieldsValue();
    if (!VisibleAt || !VisibleAt.isValid()) {
      return;
    }

    this.props.push(`/boost/preview/${moment(VisibleAt).format('YYYY-MM-DD')}`);
    try {
      await graphListQueryToState({
        onSetState: args => this.setState(args),
        key: 'boostPromotionList',
        query: boostPromotionListQuery,
        currentPage: 1,
        pageSize: 1000,
        filter: {
          visibleFrom: VisibleAt,
          visibleTo: VisibleAt,
          status,
        },
        order: {
          visibleFrom: 'ASC',
          id: 'ASC',
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const {
      form,
      location: { pathname },
    } = this.props;
    const { data } = this.state;
    const { getFieldDecorator } = form;
    const match = pathname.match(/\/boost\/preview\/([^\\]+)\/([^\\]+)/);

    return (
      <>
        <Row {...rowProps}>
          {/* VisibleAt */}
          <Col {...col3Props}>
            <Item label="VisibleAt" {...formItemLayout}>
              {getFieldDecorator('VisibleAt', {
                initialValue: moment(match?.[1]),
              })(
                <DatePicker
                  showTime={{ defaultValue: moment('01:00:00', 'HH:mm:ss') }}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '100%' }}
                />
              )}
            </Item>
          </Col>

          <Col {...col5Props}>
            <Item label="Status" {...formItemLayout}>
              {getFieldDecorator('status', {
                initialValue: 'all',
              })(
                <Select placeholder="Status" style={{ width: '100%' }}>
                  <Option value="all">All</Option>
                  <Option value="enabled">Enabled</Option>
                  <Option value="disabled">Disabled</Option>
                </Select>
              )}
            </Item>
          </Col>

          <Col xs={2} style={{ lineHeight: '40px' }}>
            <Button color="primary" onClick={() => this.getBoostList()}>
              Search
            </Button>
          </Col>
        </Row>
        {data &&
          data.map(boostItem => <BoostPreviewItem key={`boost-preview-item-${boostItem.id}`} item={boostItem} />)}
        {data?.length === 0 && <Alert type="error" message="No Data" />}
      </>
    );
  }
}

export default BoostPreviewContainer;
