import React, { PureComponent, Fragment } from 'react';
import { func, objectOf, any, number, arrayOf, instanceOf } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Select, Row, Col, Input, Icon, Tooltip } from 'antd';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getPromotionGroups } from 'redux/modules/promotionGroup/actions';
import { getMerchants } from 'redux/modules/merchant/actions';
import rules from './PromotionGroupFilterFormValidationRules';
import styles from './PromotionGroup.scss';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = state => {
  const { pageSize, filter } = state.promotionGroup;
  const { data, error } = state.merchant;

  return {
    pageSize,
    filter,
    merchants: data,
    merchantsError: error,
  };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter,
  getPromotionGroups,
  getMerchants,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PromotionGroupFilterForm extends PureComponent {
  static propTypes = {
    setFilter: func.isRequired,
    resetFilter: func.isRequired,
    getMerchants: func.isRequired,
    getPromotionGroups: func.isRequired,
    form: objectOf(any).isRequired,
    filter: objectOf(any),
    pageSize: number.isRequired,
    merchants: arrayOf(any),
    merchantsError: instanceOf(Error),
  };

  static defaultProps = {
    filter: null,
    merchants: [],
    merchantsError: null,
  };

  componentDidMount() {
    this.props.getMerchants(1, 300);
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getPromotionGroups(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();

    this.props.getPromotionGroups(1, pageSize, null);

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { id, title, source } = filter;
    const { merchants, merchantsError } = this.props;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col {...col4Props}>
            <Item label="ID">
              {getFieldDecorator('id', {
                rules: rules.id,
                initialValue: id,
              })(<Input placeholder="ID ..." autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Title */}
          <Col {...col4Props}>
            <Item label="Title">
              {getFieldDecorator('title', {
                rules: rules.title,
                initialValue: title,
              })(<Input placeholder="Title ..." autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Source */}
          <Col {...col4Props}>
            {merchantsError && (
              <Fragment>
                <Tooltip title={merchantsError.message}>
                  <Icon type="warning" className={styles.merchantsLoadFailIcon} />
                </Tooltip>
                <Icon type="redo" className={styles.merchantsRefreshButton} onClick={this.getMerchants} />
              </Fragment>
            )}

            <Item label="Source">
              {getFieldDecorator('source', {
                initialValue: source || 'all',
              })(
                <Select showSearch placeholder="Merchant name" optionFilterProp="children">
                  <Option value="all">All</Option>
                  {merchants.map(merchant => (
                    <Option key={merchant.id} value={merchant.id}>
                      {merchant.displayName || merchant.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...col4Props}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col4Props}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default PromotionGroupFilterForm;
