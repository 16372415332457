import gql from 'graphql-tag';

export const faqListQuery = gql`
  query faqList($filter: String, $skip: Int, $pageSize: Int) {
    faqList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        category
        content
        author
        status
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export default {
  faqListQuery,
};
