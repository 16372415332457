import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { PolicyTypeText } from './';

const getStatus = status =>
  ({
    active: 'success',
    ready: 'default',
    expired: 'error',
  }[status]);

export const columns = [
  {
    title: 'Policy ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>PO-{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderType(val) {
      return <span>{PolicyTypeText[val]}</span>;
    },
    width: '10%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '25%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </span>
      );
    },
    width: '15%',
    align: 'center',
  },
  {
    title: 'Period',
    dataIndex: 'startAt',
    render: function renderPeriod(startAt, others) {
      const { endAt } = others;
      const startDate = formatDate(startAt, 'YYYY-MM-DD');
      const endDate = endAt ? formatDate(endAt, 'YYYY-MM-DD') : '';

      return (
        <span>
          {startDate} ~ {endDate}
        </span>
      );
    },
    width: '40%',
  },
];

export default {
  columns,
};
