import React, { Component, Fragment } from 'react';
import { func, arrayOf, instanceOf, bool, number, any } from 'prop-types';
import { connect } from 'react-redux';
import { Table, Button, Divider, Alert, Pagination } from 'antd';
import autobind from 'autobind-decorator';
import { setPageSize, setCurrentPage, getNotices } from 'redux/modules/notice/actions';
import PageSizeDropdown from 'components/PageSizeDropdown';
import { getColumns } from './NoticeListMetadata';
import styles from './NoticeList.scss';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.notice;

  return {
    loading,
    error,
    data,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  getNotices,
  setPageSize,
  setCurrentPage,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class NoticeList extends Component {
  static propTypes = {
    getNotices: func.isRequired,
    setPageSize: func.isRequired,
    setCurrentPage: func.isRequired,
    onCreate: func.isRequired,
    onOpen: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
    currentPage: number.isRequired,
    totalCount: number.isRequired,
    pageSize: number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    const { currentPage, pageSize } = this.props;
    this.props.getNotices(currentPage, pageSize);
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.loading !== nextProps.loading
    );
  }

  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage } = this.props;
    this.props.getNotices(currentPage, newPageSize);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize } = this.props;

    this.props.getNotices(newPage, pageSize);
  }

  render() {
    const { currentPage, pageSize, totalCount, data, error, loading } = this.props;
    const errorMessage = error ? error.message : 'Unknown Error';
    const columns = getColumns({
      onTitleClick: this.props.onOpen,
    });

    return (
      <div className={styles.noticeList}>
        {/* On Error while getting data via XHR, Display <Alert />  */}
        {error && (
          <Fragment>
            <Divider style={{ background: 'none', margin: '10px 0' }} />
            <Alert type="warning" showIcon closable message={`Failed to load page: ${errorMessage}`} />
            <Divider style={{ background: 'none', margin: '10px 0' }} />
          </Fragment>
        )}

        <Table
          rowKey="id"
          dataSource={data}
          columns={columns}
          pagination={false}
          loading={loading}
          scroll={{ x: '100%' }}
        />

        <div className={styles.listControl}>
          <Button type="primary" ghost className={styles.createButton} icon="plus-circle" onClick={this.props.onCreate}>
            Create
          </Button>

          <Pagination
            total={totalCount}
            pageSize={pageSize}
            defaultCurrent={1}
            current={currentPage}
            onChange={this.changePage}
            className={styles.pagination}
          />

          <div className={styles.pageSizeWrapper}>
            <span style={{ fontSize: 14, marginRight: 15 }}>Total {totalCount}</span>
            <PageSizeDropdown icon="down" currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
          </div>
        </div>
      </div>
    );
  }
}

export default NoticeList;
