import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateModule } from 'redux/modules/global';
import { getParentMenusByName } from 'utils/permission';
import MySider from 'components/MySider';

class SiderContainer extends React.PureComponent {
  static propTypes = {
    openAccessMenu: PropTypes.arrayOf(PropTypes.any).isRequired,
    menus: PropTypes.arrayOf(PropTypes.any),
    collapsed: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    menus: [],
  };

  state = {
    openKeys: [],
    selectedKey: '',
  };

  openMenu = v => {
    const parentKeys = getParentMenusByName(this.props.openAccessMenu, v[v.length - 1]).map(item => item.name);
    this.setState({
      openKeys: parentKeys,
    });
  };

  render() {
    return (
      <MySider
        collapsed={this.props.collapsed}
        menus={this.props.menus}
        openMenu={this.openMenu}
        selectedKey={this.state.selectedKey}
        openKeys={this.state.openKeys}
      />
    );
  }
}

const mapStateToProps = state => ({
  menus: state.global.accessMenu,
  openAccessMenu: state.global.openAccessMenu,
  accessMenu: state.global.accessMenu,
  collapsed: state.global.collapsed,
});

const mapDispatchToProps = dispatch => ({
  updateModule: module => {
    dispatch(updateModule(module));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(SiderContainer);
