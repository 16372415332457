import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { rowProps, col2RightProps } from 'ui/ResponsiveProps';
import searchFormSchemaUtil from './searchFormSchemaUtil';

class SearchForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    uiSchema: PropTypes.objectOf(PropTypes.any).isRequired,
    noCacheSchema: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    noCacheSchema: {},
  };

  state = {
    expand: true,
  };

  handleSubmit = e => {
    e.preventDefault();
    const obj = this.formRef.props.form.getFieldsValue();

    this.props.handleSubmit(obj);
  };

  handleReset = e => {
    e.preventDefault();
    this.formRef.props.form.resetFields();
    this.props.handleReset();
  };

  toggle = () => {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  };

  render() {
    const { schema, uiSchema, noCacheSchema } = this.props;

    const FormComponent = searchFormSchemaUtil.getForm(schema, uiSchema, noCacheSchema);

    // wrappedComponentRef
    // https://github.com/react-component/form#note-use-wrappedcomponentref-instead-of-withref-after-rc-form140
    return (
      <div>
        <Row>
          <FormComponent
            noCacheSchema={noCacheSchema}
            schema={schema}
            uiSchema={uiSchema}
            style={{ display: this.state.expand ? 'inline' : 'none' }}
            wrappedComponentRef={instance => {
              this.formRef = instance;
            }}
          />
          <Row {...rowProps}>
            <Col {...col2RightProps}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit} style={{ width: '100%' }}>
                Search
              </Button>
            </Col>

            <Col {...col2RightProps}>
              <Button style={{ width: '100%' }} onClick={this.handleReset}>
                Clear
              </Button>
            </Col>
          </Row>
        </Row>
      </div>
    );
  }
}

export default SearchForm;
