import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { formItemLayout } from './layout';

const { Option } = Select;

function BoostSelectForm(props) {
  const { form, initial, label, name, items, disabled, readOnly } = props;
  const { getFieldDecorator } = form;

  return (
    <Form.Item label={label} {...formItemLayout}>
      {getFieldDecorator(name, { initialValue: initial || 'enabled' })(
        <Select disabled={disabled || readOnly}>
          {items.map(item => (
            <Option key={item.value} value={item.value} className={item.className ? item.className : null}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

BoostSelectForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initial: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

BoostSelectForm.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default BoostSelectForm;
