export default function invertColor(hex) {
  const hexCode = hex.indexOf('#') === 0 ? hex.slice(1) : hex;

  if (hexCode.length !== 6) {
    throw new Error('Invalid HEX color.');
  }

  // invert color components
  const r = (255 - parseInt(hexCode.slice(0, 2), 16)).toString(16);
  const g = (255 - parseInt(hexCode.slice(2, 4), 16)).toString(16);
  const b = (255 - parseInt(hexCode.slice(4, 6), 16)).toString(16);

  // pad each with zeros and return
  return `#${r.padStart(2, '0')}${g.padStart(2, '0')}${b.padStart(2, '0')}`;
}
