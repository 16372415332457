import React from 'react';
import PropTypes from 'prop-types';
import commonFormSchemaUtil from './commonFormSchemaUtilPlus';

class CommonForm extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    uiSchema: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any),
    style: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    style: {},
    handleSubmit: () => {},
    formData: {},
  };
  handleSubmit = () => {
    this.formRef.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.handleSubmit(values);
      }
    });
  };
  handleReset = () => {
    this.formRef.props.form.resetFields();
  };
  render() {
    const { schema, uiSchema, formData, style } = this.props;
    const FormComponent = commonFormSchemaUtil.getForm(schema, uiSchema);

    return (
      <div style={style}>
        <FormComponent
          formData={formData}
          wrappedComponentRef={instance => {
            this.formRef = instance;
          }}
        />
      </div>
    );
  }
}
export default CommonForm;
