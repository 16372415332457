import React, { Component, Fragment } from 'react';
import { func, objectOf, arrayOf, instanceOf, bool, number, string, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider } from 'antd';
import autobind from 'autobind-decorator';
import find from 'lodash/find';
import { values } from 'lodash';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setPageSize, setCurrentPage, getUsers } from 'redux/modules/user/actions';

import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import csvExport from 'utils/csvExporter';
import { isAdmin, isCustomerService } from 'utils/permission';
import { ellipsis } from 'utils/text';

import UserFilterForm from './UserFilterForm';
import UserDetail from './UserDetail';
import PaymentDetail from '../Payment/PaymentDetail';
import TransactionDetail from '../Transaction/TransactionDetail';

import { columns } from './UserContainerMetadata';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.user;
  const { activeKey, list } = state.tabs.user;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getUsers,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class UserContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
    setPageSize: func.isRequired,
    setCurrentPage: func.isRequired,
    getUsers: func.isRequired,
    addTab: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
    focusTab: func.isRequired,
    moveTab: func.isRequired,
    currentPage: number.isRequired,
    totalCount: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    activeKey: string.isRequired,
    list: arrayOf(any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('user', listTab);
    }

    this.props.moveTab('user', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/user\/(.+)/);

    if (match) {
      const id = match[1];
      const hasTab = find(this.props.list, { key: id });

      if (!hasTab) {
        if (/^CS.+/.test(id)) {
          this.openUserTab(id);
        } else {
          this.openPaymentTab(id);
        }
      }

      this.props.focusTab('user', id);
    } else {
      this.props.focusTab('user', 'ListTab');
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname !== '/user') {
        const match = pathname.match(/\/user\/(.+)/);

        if (match) {
          const id = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: id });

          if (!hasTab) {
            if (/^CS.+/.test(id)) {
              this.openUserTab(id);
            } else {
              this.openPaymentTab(id);
            }
          }

          this.props.focusTab('user', id);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/user') {
        this.props.focusTab('user', 'ListTab');
      }
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    if (isCustomerService() && !this.props.filter) return;
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { filter } = this.props;

    this.props.getUsers(filter);
  }

  handleCellClick(record, extra) {
    const { id, fullname: title } = record;
    const { index } = extra;

    if (index === 0) {
      this.openUserTab(`CS-${id}`, { ...extra, title });
    }
  }

  /**
   * Opening tab for display User
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openUserTab(id, extra = {}) {
    const title = extra.title || id;
    const tabData = new TabData({
      key: `${id}`,
      title: ellipsis(title, 5),
      closable: true,
      data: {
        id,
      },
      componentType: 'UserDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('user', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('user', id.toString());
      this.props.push(`/user/${id}`);
    }
  }

  /**
   * Opening tab for display Payment
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openPaymentTab(id, extra = {}) {
    const tabData = new TabData({
      key: id,
      title: `Payment (${ellipsis(id, 5)})`,
      closable: true,
      data: {
        id,
      },
      componentType: 'PaymentDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('user', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('user', id);
      this.props.push(`/user/${id}`);
    }
  }

  changePage(newPage) {
    if (!isAdmin() && !this.props.filter) return;

    this.props.setCurrentPage(newPage);

    const { filter } = this.props;

    this.props.getUsers(filter);
  }

  /**
   * Download User Data
   */
  download() {
    const { data } = this.props;
    const fields = columns.map(column => column.title);

    if (data.length === 0) {
      return;
    }

    const extractedData = data.map(user => values(user));
    const { error } = csvExport('User', fields, extractedData);

    if (error) {
      alert(error);
    }
  }

  /**
   * Render selected rows handler
   * @param {string[]} selectedRowKeys
   * @param {any[]} needTotalList
   * @param {Function} cleanSelectedKeys
   */
  renderSelectedRowHandler(selectedRowKeys) {
    return (
      <Fragment>
        <div>
          Total Member(s) <span style={{ color: '#1890FF' }}>{selectedRowKeys.length}</span>
        </div>
      </Fragment>
    );
  }

  /**
   * Get List Tab
   * @return {ReactElement}
   */
  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading, filter } = this.props;
    const shouldRenderDataViewer = isAdmin() || (isCustomerService() && filter);

    return (
      <Fragment>
        <UserFilterForm />
        <Divider />

        {shouldRenderDataViewer && (
          <DataViewer
            columns={columns}
            data={data}
            error={error}
            loading={loading}
            onCellClick={this.handleCellClick}
            selectRows
            selectedRowHandler={this.renderSelectedRowHandler}
          />
        )}

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          showTotal={this.getTotalText}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* <Button icon="download" type="primary" onClick={this.download}>
            <span className={styles.onlyVisibleInWidescreen}>Download</span>
          </Button> */}

          <Divider type="vertical" style={{ background: 'none' }} />

          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      if (this.props.filter) {
        this.props.getUsers(this.props.filter);
      }

      this.props.push('/user');
    } else {
      this.props.push(`/user/${activeKey}`);
    }
  }

  render() {
    return (
      <TabViewer
        tabKey="user"
        components={{
          ListTab: this.getListTab(),
          UserDetail,
          PaymentDetail,
          TransactionDetail,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default UserContainer;
