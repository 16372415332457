import gql from 'graphql-tag';

export const transactionQuery = gql`
  query transaction($id: ID!) {
    transaction(id: $id) {
      id
      createdAt
      type
      currency
      amount
      delta
      balance
      data {
        ... on ChargeInData {
          paymentId
          pgResult
        }
        ... on PaymentOutData {
          paymentId
        }
      }
      ledger {
        id
        user {
          id
          fullname
          status
          gender
          phone
        }
        merchant {
          id
          name
          displayName
          status
        }
      }
    }
  }
`;

export default {
  transactionQuery,
};
