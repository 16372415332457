import React, { Component } from 'react';
import { func, bool, arrayOf, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Table, Modal, Form } from 'antd';

import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';
import LinkText from 'components/LinkText';

import { getSettlementTransfer } from 'redux/modules/settlementTable/actions';
import SettlementHistoryFilterForm from './SettlementHistoryFilterForm';

import styles from './SettlementHistory.scss';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const mapStateToProps = state => {
  const { historyData, historyLoading, filter } = state.settlementTable;

  return {
    historyData,
    historyLoading,
    filter,
  };
};

const mapDispatchToProps = {
  getSettlementTransfer,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementHistoryContainer extends Component {
  static propTypes = {
    getSettlementTransfer: func.isRequired,
    form: objectOf(any).isRequired,
    historyLoading: bool.isRequired,
    historyData: arrayOf(any).isRequired,
  };

  state = {
    adjModalVisible: false,
    adjModalRow: {},
    adjustments: {},
  };

  componentWillMount() {}

  componentDidMount() {
    this.props.getSettlementTransfer();
  }

  onOpenAdjustmentModal(row) {
    const { form } = this.props;
    const { adjustments } = this.state;

    if (adjustments[row.merchantId]) {
      form.setFieldsValue({
        modalAdjustmentAmount: adjustments[row.merchantId].amount,
        modalAdjustmentMemo: adjustments[row.merchantId].memo,
      });
    } else {
      form.setFieldsValue({
        modalAdjustmentAmount: 0,
        modalAdjustmentMemo: '',
      });
    }
    this.setState({ adjModalVisible: true, adjModalRow: row });
  }

  onCloseModal() {
    this.setState({ adjModalVisible: false });
  }

  render() {
    const { historyData, historyLoading } = this.props;
    const { adjModalVisible } = this.state;
    const { adjModalRow } = this.state;
    const { onOpenAdjustmentModal } = this;

    const columns = [
      {
        title: 'Merchant Name',
        dataIndex: 'merchant.name',
        width: '20%',
      },
      {
        title: 'CreatedAt',
        dataIndex: 'createdAt',
        width: '15%',
        render: date => formatDate(date),
      },
      {
        title: 'FinishedAt',
        dataIndex: 'finishedAt',
        width: '15%',
        render: date => (date && formatDate(date)) || '-',
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        width: '15%',
        render: value => commify(value),
      },
      {
        title: 'Adj.',
        dataIndex: 'adjustmentAmount',
        width: '15%',
        render(value, row) {
          const adj = row.settlementAdjustment;
          if (!adj?.amount) {
            return '-';
          }
          return (
            <LinkText onClick={() => onOpenAdjustmentModal(row)}>{adj?.amount ? commify(adj?.amount) : ''}</LinkText>
          );
        },
      },
      {
        title: 'Author',
        dataIndex: 'author',
        width: '20%',
      },
    ];

    return (
      <>
        <div>
          <SettlementHistoryFilterForm />
        </div>
        <div style={{ backgroundColor: '#FFFFFF', padding: 10 }}>
          <Table
            loading={historyLoading}
            rowKey="id"
            pagination={false}
            dataSource={historyData}
            columns={columns}
            scroll={{ x: '100%' }}
          />
          <Modal
            title="Adjustment"
            visible={adjModalVisible}
            onOk={this.onCloseModal}
            okText="Close"
            cancelText="Close"
            cancelButtonProps={{ style: { display: 'none' } }}
            width={480}
          >
            <Form className={styles.form}>
              <Form.Item label="Settlement Amount" {...formItemLayout} style={{ textAlign: 'right' }}>
                {commify(adjModalRow?.amount)}
              </Form.Item>
              <Form.Item label="Adjustment" {...formItemLayout} style={{ textAlign: 'right' }}>
                {commify(adjModalRow?.settlementAdjustment?.amount)}
              </Form.Item>
              <Form.Item label="Memo" {...formItemLayout} style={{ textAlign: 'right' }}>
                {commify(adjModalRow?.settlementAdjustment?.memo)}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}

export default SettlementHistoryContainer;
