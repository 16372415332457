import React from 'react';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: '타이틀',
    dataIndex: 'title',
    render: function renderTitle(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: '상태',
    dataIndex: 'status',
    render: function renderTitle(val) {
      return <span>{val}</span>;
    },
    width: '10%',
  },
  {
    title: '노출 기간',
    render: function renderPeriod(others) {
      const { visibleFrom, visibleTo } = others;

      return (
        <span>
          {formatDate(visibleFrom, 'YYYY-MM-DD HH:mm')} ~ {visibleTo && formatDate(visibleTo, 'YYYY-MM-DD HH:mm')}
        </span>
      );
    },
    width: '30%',
  },
  {
    title: '응모 기간',
    render: function renderPeriod(others) {
      const { enterableFrom, enterableTo } = others;

      return (
        <span>
          {formatDate(enterableFrom, 'YYYY-MM-DD HH:mm')} ~ {enterableTo && formatDate(enterableTo, 'YYYY-MM-DD HH:mm')}
        </span>
      );
    },
    width: '30%',
  },
];

export default {
  columns,
};
