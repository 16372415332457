import React from 'react';
import { arrayOf, oneOfType, element, string, number, func, any } from 'prop-types';
import cx from 'classnames';
import styles from './LinkText.less';

const LinkText = ({ children, onClick, className, ...rest }) => (
  <span className={cx([styles.linkText, className])} onClick={onClick} {...rest}>
    {children}
  </span>
);

LinkText.propTypes = {
  children: oneOfType([element, string, number, arrayOf(any)]),
  onClick: func,
  className: string,
};
LinkText.defaultProps = {
  children: null,
  onClick: () => {},
  className: null,
};

export default LinkText;
