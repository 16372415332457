import BoostTextForm from './BoostTextForm';
import BoostNumberForm from './BoostNumberForm';
import BoostTwoNumbersForm from './BoostTwoNumbersForm';
import BoostRangePromotionForm from './BoostRangePromotionForm';
import BoostArrayForm from './BoostArrayForm';
import BoostDateForm from './BoostDateForm';
import BoostPeriodForm from './BoostPeriodForm';
import BoostSelectForm from './BoostSelectForm';
import BoostDividerForm from './BoostDividerForm';
import BoostIdArrayForm from './BoostIdArrayForm';
import BoostMerchantForm from './BoostMerchantForm';
import BoostBrandInputForm from './BoostBrandInputForm';
import layout from './layout';

export default {
  BoostTextForm,
  BoostNumberForm,
  BoostTwoNumbersForm,
  BoostRangePromotionForm,
  BoostArrayForm,
  BoostPeriodForm,
  BoostDateForm,
  BoostSelectForm,
  BoostDividerForm,
  BoostIdArrayForm,
  BoostMerchantForm,
  BoostBrandInputForm,
  layout,
};
