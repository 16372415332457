import store from 'store';
import includes from 'lodash/includes';

export function getMenuByName(name, menulist) {
  let menu = {};
  function forFn(_name, _menulist) {
    /* eslint-disable */
    for (const item of _menulist) {
      /* eslint-enable */
      if (item.name === name) {
        menu = item;
      } else if (item.children && item.children.length > 0) {
        forFn(name, item.children);
      }
      if (menu.name) {
        break;
      }
    }
  }
  forFn(name, menulist);
  return menu;
}

export function getTreeEleByPropertyValue(value, property, list) {
  let ele = {};
  function forFn(_value, _property, _list) {
    /* eslint-disable */
    for (const item of _list) {
      /* eslint-enable */
      if (item[_property] === _value) {
        ele = item;
      } else if (item.children && item.children.length > 0) {
        forFn(_value, _property, item.children);
      }
      if (ele[_property]) {
        break;
      }
    }
  }
  forFn(value, property, list);
  return ele;
}

export function oneOf(ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  }
  return false;
}

export function getParentMenusByName(openAccessedMenu, name) {
  const temp = [];
  function forFn(_openAccesseMenu, _name) {
    /* eslint-disable */
    for (var item of _openAccesseMenu) {
      /* eslint-enable */
      if (item.name === _name && item.path !== '/') {
        temp.push(item);
        forFn(openAccessedMenu, item.parentName);
      }
    }
  }
  forFn(openAccessedMenu, name);
  temp.reverse();
  return temp;
}

export function getTreeEleWithParent(id, list) {
  const temp = [];
  function forFn(_id, _list) {
    /* eslint-disable */
    for (const item of _list) {
      /* eslint-enable */
      if (item.id === _id) {
        const newItem = { ...item };
        temp.push(newItem);
        forFn(item.parentId, _list);
      }
    }
  }
  forFn(id, list);
  temp.reverse();
  return temp;
}

export function openAccessedMenuFunc(accesseMenu) {
  const openAccesseMenuArr = [];
  function forFn(menulist, parentName) {
    /* eslint-disable */
    for (var item of menulist) {
      /* eslint-enable */
      item.parentName = parentName;
      openAccesseMenuArr.push(item);
      if (item.children && item.children.length > 0) {
        forFn(item.children, item.name);
      }
    }
  }
  forFn(accesseMenu, '');

  return openAccesseMenuArr;
}

export function openTreeData(data) {
  const openAccesseMenuArr = [];
  function forFn(_data) {
    /* eslint-disable */
    for (const item of _data) {
      /* eslint-enable */
      openAccesseMenuArr.push({ ...item });
      if (item.children && item.children.length > 0) {
        forFn(item.children);
      }
    }
  }
  forFn(data);
  return openAccesseMenuArr;
}

/**
 * Check current Admin belongs to Admin Group
 * @return {bool} Returns true if admin has Admin permission
 */
export function isAdmin() {
  const adminInfo = store.get('admin');

  if (!adminInfo) return false;

  const { groups } = adminInfo;
  return !!groups.find(group => group.code === 'admin');
}

/**
 * Check current Admin belongs to Customer Service Group
 * @return {bool} Returns true if admin has Customer Service permission
 */
export function isCustomerService() {
  const adminInfo = store.get('admin');

  if (!adminInfo) return false;

  const { groups } = adminInfo;
  return !!groups.find(group => group.code === 'customer_service');
}

/**
 * Check current Admin belongs to Merchant Group
 * @return {bool} Returns true if admin has Merchant permission
 */
export function isMerchant() {
  const adminInfo = store.get('admin');

  if (!adminInfo) return false;

  const { groups } = adminInfo;
  return !!groups.find(group => group.code === 'merchant');
}

/**
 * Check current Admin belongs to Merchant Group
 * @return {bool} Returns true if admin has Merchant permission
 */
export function isB2BMerchant() {
  const adminInfo = store.get('admin');

  if (!adminInfo) return false;

  const { groups } = adminInfo;
  return !!groups.find(group => group.code === 'b2b_merchant');
}

/**
 * Iterate list and check all values are in the list.
 * @param {any[]} list - List of something.
 * @param {any[]} values - Value to find
 * @return {boolean} - Return true if there is value in the list. Otherwise, return false.
 */
export function hasValues(list, values) {
  for (let i = 0; i < list.length; i += 1) {
    const listItem = list[i];
    // const isExists = values.includes(listItem);
    const isExists = includes(values, listItem);

    if (isExists) return true;
  }

  return false;
}

/**
 * Check current Admin has specified groups
 * @param {string[]} groups - Array of group names that requires to render
 * @return {boolean} - Return true if Admin has all groups or no groups specified. Otherwise, return false.
 */
export function hasGroups(groups) {
  if (!groups || groups.length === 0) return true;

  const adminInfo = store.get('admin');
  const ownGroups = adminInfo.groups.map(group => group.code);
  const requiredGroups = groups;

  return hasValues(requiredGroups, ownGroups);
}

/**
 * Check current Admin has specified permissions
 * @param {string[]} permissions - Array of permission names that requires to render
 * @return {boolean} - Return true if Admin has all permissions or no permission specified. Otherwise, return false.
 */
export function hasPermissions(permissions) {
  if (!permissions || permissions.length === 0) return true;

  const adminInfo = store.get('admin');
  const { groups } = adminInfo;
  let ownActions = [];

  for (let i = 0; i < groups.length; i += 1) {
    ownActions = [...ownActions, ...groups[i].actions];
  }

  // Rid off duplicates
  ownActions = [...new Set(ownActions)];
  ownActions = ownActions.map(action => action.code);

  const requiredPermissions = permissions;

  return hasValues(requiredPermissions, ownActions);
}
