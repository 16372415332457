import AgreementContainer from './AgreementContainer';

export const AgreementGroup = {
  REQUIRED: 'required',
  IDENTIFICATION: 'identification',
  ADVERTISEMENT: 'advertisement',
  CARD_TERMS: 'card-terms',
  OPENBANKING: 'openbanking',
  THIRDPARTY: 'thirdparty',
  E_COMMERCE: 'e-commerce',

};

export default AgreementContainer;
