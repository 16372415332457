import React from 'react';
import { Badge, Button } from 'antd';
import { UpCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons';

const getStatus = status =>
  ({
    publish: 'success',
    ingest: 'default',
    error: 'error',
    canceled: 'error',
    deleted: 'error',
  }[status]);

export function getColumns(onDownClicked, onUpClicked, onDeleteClicked) {
  return [
    {
      key: 'number',
      title: '번호',
      width: '5%',
      render: function render(nameColData, row, index) {
        return `${index + 1}`;
      },
    },
    {
      key: 'file',
      title: '파일',
      children: [
        {
          key: 'sourceFile',
          title: '원본 파일 이름',
          dataIndex: 'sourceFileName',
          width: '25%',
        },
        {
          key: 'bucketFile',
          title: '저장된 파일 이름',
          dataIndex: 'bucketFileName',
          width: '25%',
        },
      ],
    },
    {
      key: 'type',
      title: '타입',
      dataIndex: 'type',
      width: '5%',
      render: function renderType(val) {
        const message = val === 'video' ? '동영상' : '이미지';
        return <span>{message}</span>;
      },
    },
    {
      key: 'visible',
      title: '공개 여부',
      dataIndex: 'visible',
      align: 'center',
      width: '10%',
      render: function renderType(val) {
        const message = val === 'invisible' ? '숨김' : '공개';
        const color = val === 'invisible' ? '#e02323' : '#0eb53d';
        return <span style={{ color }}>{message}</span>;
      },
    },
    {
      key: 'status',
      title: '상태',
      dataIndex: 'status',
      align: 'center',
      width: '7%',
      render: function renderType(val) {
        let message = '처리중';
        if (val === 'publish') {
          message = '처리 완료';
        } else if (val === 'canceled') {
          message = '취소됨';
        } else if (val === 'error') {
          message = '오류';
        } else if (val === 'deleted') {
          message = '삭제됨';
        }

        return (
          <span>
            <Badge status={getStatus(val)} />
            <span>{message}</span>
          </span>
        );
      },
    },
    {
      key: 'priority',
      title: '우선 순위 조정',
      children: [
        {
          key: 'priority_up',
          title: '올리기',
          align: 'center',
          width: '5%',
          render: function render(nameColData, row) {
            return (
              <div>
                <UpCircleTwoTone
                  twoToneColor="#ff0000"
                  onClick={event => {
                    if (event) event.stopPropagation();
                    onUpClicked(row);
                  }}
                />
              </div>
            );
          },
        },
        {
          key: 'priority_down',
          title: '내리기',
          align: 'center',
          width: '5%',
          render: function render(nameColData, row) {
            return (
              <div>
                <DownCircleTwoTone
                  twoToneColor="#0000ff"
                  onClick={event => {
                    if (event) event.stopPropagation();
                    onDownClicked(row);
                  }}
                />
              </div>
            );
          },
        },
      ],
    },
    {
      key: 'delete',
      title: '',
      align: 'center',
      width: '10%',
      render: function render(nameColData, row) {
        return (
          <div>
            <Button
              type="danger"
              icon="delete"
              ghost
              onClick={event => {
                if (event) event.stopPropagation();
                onDeleteClicked(row);
              }}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];
}

export default {
  getColumns,
};
