import moment from 'moment';
import React from 'react';
import { func, arrayOf, oneOf, any, bool } from 'prop-types';
import { Tabs, Row, Col, Button, DatePicker } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { TimeRangeMode } from '../DashboardConstants';
import { quickModeGroup } from '../DashboardMetadata';

const BlockLinkText = props => <LinkText {...props} style={{ display: 'block', marginBottom: 10 }} />;

const { TabPane } = Tabs;

const TimeRangeControl = ({
  onSetTimeRangeQuickMode,
  onSetTimeRange,
  onSetStartDate,
  onSetEndDate,
  toggleStartDatePicker,
  toggleEndDatePicker,
  openStartDatePicker,
  openEndDatePicker,
  timeRangeMode,
  timeRange,
}) => (
  <Tabs defaultActiveKey={timeRangeMode} animated={false}>
    <TabPane tab="Quick" key="quick">
      <Row>
        <Col span={8}>
          {quickModeGroup[0].map(mode => (
            <BlockLinkText key={mode} onClick={onSetTimeRangeQuickMode.bind(null, mode)}>
              {startCase(mode)}
            </BlockLinkText>
          ))}
        </Col>
        <Col span={8}>
          {quickModeGroup[1].map(mode => (
            <BlockLinkText key={mode} onClick={onSetTimeRangeQuickMode.bind(null, mode)}>
              {startCase(mode)}
            </BlockLinkText>
          ))}
        </Col>
        <Col span={8}>
          {quickModeGroup[2].map(mode => (
            <BlockLinkText key={mode} onClick={onSetTimeRangeQuickMode.bind(null, mode)}>
              {startCase(mode)}
            </BlockLinkText>
          ))}
        </Col>
      </Row>
    </TabPane>
    <TabPane tab="Absolute" key="absolute">
      <div>
        <Row>
          <Col span={11}>
            <div style={{ marginBottom: 10 }}>From:</div>
            <DatePicker
              placeholder="Select Time"
              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
              onOpenChange={toggleStartDatePicker}
              onChange={onSetStartDate}
              open={openStartDatePicker}
              style={{ width: '100%' }}
              defaultValue={timeRange[0] && moment(timeRange[0]).local()}
            />
          </Col>
          <Col span={1} />
          <Col span={12}>
            <div style={{ marginBottom: 10 }}>To:</div>
            <DatePicker
              placeholder="Select Time"
              showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
              onOpenChange={toggleEndDatePicker}
              onChange={onSetEndDate}
              open={openEndDatePicker}
              style={{ width: '100%' }}
              defaultValue={timeRange[1] && moment(timeRange[1]).local()}
            />
          </Col>
        </Row>

        <div style={{ marginTop: 10, textAlign: 'right' }}>
          <Button onClick={onSetTimeRange} icon="search">
            Go
          </Button>
        </div>
      </div>
    </TabPane>
  </Tabs>
);

TimeRangeControl.propTypes = {
  onSetTimeRangeQuickMode: func.isRequired,
  onSetStartDate: func.isRequired,
  onSetEndDate: func.isRequired,
  toggleStartDatePicker: func.isRequired,
  toggleEndDatePicker: func.isRequired,
  openStartDatePicker: bool.isRequired,
  openEndDatePicker: bool.isRequired,
  timeRangeMode: oneOf([TimeRangeMode.QUICK, TimeRangeMode.ABSOLUTE]).isRequired,
  timeRange: arrayOf(any).isRequired,
  onSetTimeRange: func.isRequired,
};

export default TimeRangeControl;
