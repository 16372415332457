// Format Utilities
import moment from 'moment';

/**
 * Date Format Presets
 */
export const dateFormats = {
  default: 'YYYY-MM-DD HH:mm:ss',
};

/**
 * Convert Cellphone(cp) number to put '-'
 * @param {string|number} cp - Cellphone Number
 * @return {string} - If format is valid, returns converted phone number
 * @return {null}  - If format is invalid, returns null
 */
export function formatPhoneNumber(cp) {
  if (typeof cp === 'undefined' || !cp) return null;

  const strNum = typeof cp === 'number' ? cp.toString() : cp;
  const match = strNum.match(/^(\d{3})(\d{3,4})(\d{4})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return null;
}

/**
 * Convert date to specified format
 * @param {Moment|Date|number|string} val - Value of Date. Could be Date Object or string & number.
 * @param {string} format - Format of Date. Should be valid 'moment' format.
 * @return {string} - If value is valid date format, returns formatted text
 * @return {null} - If value is invalid, returns null
 */
export function formatDate(val, format = dateFormats.default) {
  if (val instanceof moment) {
    return val.format(format);
  } else if (val instanceof Date) {
    return moment(val).format(format);
  } else if (typeof val === 'number') {
    return moment(val).format(format);
  }

  // If val is not Moment/Date/number, could be stringified number of date or just date string.
  if (moment(val).isValid()) {
    return moment(val).format(format);
  } else if (moment(+val).isValid()) {
    return moment(+val).format(format);
  }

  throw new TypeError(`Invalid date format: ${val}`);
}

export default {
  dateFormats,
  formatPhoneNumber,
  formatDate,
};
