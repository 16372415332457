import {
  FETCH_B2B_USER,
  FETCH_B2B_USER_SUCCESS,
  FETCH_B2B_USER_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  RESET,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: null,
};

export default function B2BUserReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_B2B_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_B2B_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_B2B_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: null,
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case RESET: {
      return {
        ...state,
        error: null,
        data: [],
        currentPage: 1,
        totalCount: 1,
        pageSize: 10,
        filter: null,
      };
    }
    default:
      return state;
  }
}
