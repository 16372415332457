import gql from 'graphql-tag';

const selectPreApplicationMutation = gql`
  mutation selectPreApplication($userIds: [ID], $userIdFile: FileUpload, $invitationCount: Int) {
    selectPreApplication(userIds: $userIds, userIdFile: $userIdFile, invitationCount: $invitationCount) {
      success
      failedUserIds
    }
  }
`;

export default { selectPreApplicationMutation };
