import React, { Component } from 'react';
import { func, objectOf, arrayOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setCurrentPage } from 'redux/modules/settlementPayment/actions';
import { TabViewer, TabData } from 'containers/Layout';
import SettlementCalendar from './SettlementCalendar';
import SettlementDetail from './SettlementDetail';
import PaymentDetail from '../Payment/PaymentDetail';

const mapStateToProps = state => {
  const { activeKey, list } = state.tabs.settlement;

  return {
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  moveTab,
  setCurrentPage,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    moveTab: func.isRequired,
    setCurrentPage: func.isRequired,
    list: arrayOf(any).isRequired,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Create Calendar Tab only not exists
    const { list } = this.props;
    let hasCalendarTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'calendar') {
        hasCalendarTab = true;
        break;
      }
    }

    if (!hasCalendarTab) {
      const newTab = new TabData({ key: 'calendar', componentType: 'SettlementCalendar', title: 'Calendar' });
      this.props.addTab('settlement', newTab);
      this.props.focusTab('settlement', newTab.key);
    }

    this.props.moveTab('settlement', 'calendar', 0);

    // Check URL to switch to proper tab
    const { pathname } = this.props.location;
    const [merchantId, date, paymentId] = pathname.replace(/\/settlement(\/)/, '').split('/');

    if (!merchantId) {
      this.props.focusTab('settlement', 'calendar');
    } else if (!paymentId) {
      this.openPaymentListTab(merchantId, date);
    } else {
      this.openPaymentDetail(merchantId, date, paymentId);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/settlement') {
        this.props.focusTab('settlement', 'calendar');
      } else {
        const [merchantId, date, paymentId] = pathname.replace(/\/settlement(\/)/, '').split('/');

        if (!paymentId) {
          this.openPaymentListTab(merchantId, date);
        } else {
          this.openPaymentDetail(merchantId, date, paymentId);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/settlement') {
        this.props.focusTab('settlement', 'calendar');
      }
    }
  }

  handleTabChange(activeKey) {
    if (activeKey === 'calendar') {
      this.props.push('/settlement');
    } else {
      const [merchantId, date, paymentId] = activeKey.split('_');

      if (paymentId === 'listTab') {
        this.props.setCurrentPage(1);
        this.props.push(`/settlement/${merchantId}/${date}`);
      } else {
        this.props.push(`/settlement/${merchantId}/${date}/${paymentId}`);
      }
    }
  }

  /**
   * Open Payment List Tab
   * @param {string} merchantId
   * @param {string} date
   */
  openPaymentListTab(merchantId, date) {
    const title = `${merchantId.substr(0, 5)}... - ${date}`;

    const newTab = new TabData({
      key: `${merchantId}_${date}_listTab`,
      componentType: 'SettlementDetail',
      title: `${title} - ${date}`,
      closable: true,
      data: {
        date,
        merchantId,
      },
    });

    this.props.addTab('settlement', newTab);
    this.props.focusTab('settlement', newTab.key);
  }

  /**
   * Open Payment Detail Tab
   * @param {string} merchantId
   * @param {string} date
   * @param {string} paymentId
   */
  openPaymentDetail(merchantId, date, paymentId) {
    const tabData = new TabData({
      key: `${merchantId}_${date}_${paymentId}`,
      title: `Payment (${paymentId.substr(0, 5)}...)`,
      closable: true,
      data: {
        id: paymentId,
      },
      componentType: 'PaymentDetail',
    });

    this.props.addTab('settlement', tabData);
    this.props.focusTab('settlement', tabData.key);
  }

  render() {
    return (
      <TabViewer
        tabKey="settlement"
        components={{
          SettlementCalendar,
          SettlementDetail,
          PaymentDetail,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default SettlementContainer;
