import { formatDate } from 'utils/format';
import React from 'react';
import LinkText from 'components/LinkText';

/**
 * Get columns with custom options
 * @param {Object} options
 * @param {Function} onUserIdClick Callback function that invokes when clicking user id
 */
export function getColumns(options = {}) {
  const { onUserIdClick } = options;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render(val) {
        return <LinkText onClick={onUserIdClick.bind(null, val)}>{val.substr(0, 10)}...</LinkText>;
      },
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
    },
    {
      title: 'Join Date',
      dataIndex: 'createdAt',
      render(val) {
        return <span>{val ? formatDate(val) : '-'}</span>;
      },
    },
    {
      title: 'Leave Date',
      dataIndex: 'deletedAt',
      render(val) {
        return <span>{val ? formatDate(val) : '-'}</span>;
      },
    },
  ];

  return columns;
}

export default {
  getColumns,
};
