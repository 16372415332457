import gql from 'graphql-tag';

export const boltPolicyAddMutation = gql`
  mutation boltPolicyAdd(
    $title: String!
    $startAt: Date!
    $endAt: Date
    $type: String!
    $receiverType: String!
    $status: String!
    $description: String
    $issueCountRuleType: String!
    $issueCountRuleCount: Int
    $issueCountRuleRange: [IssueCountRuleRangeInput]
    $periodCapsPerUserRuleType: String!
    $periodCapsPerUserRuleCount: Int
    $periodCapsPerUserRuleRange: [PeriodCapsPerUserRuleRangeInput]
    $expirationRule: ExpirationRuleInput
    $merchantIds: [String]
    $airdropUserIds: [String]
  ) {
    boltPolicyAdd(
      title: $title
      startAt: $startAt
      endAt: $endAt
      type: $type
      receiverType: $receiverType
      status: $status
      description: $description
      issueCountRuleType: $issueCountRuleType
      issueCountRuleCount: $issueCountRuleCount
      issueCountRuleRange: $issueCountRuleRange
      periodCapsPerUserRuleType: $periodCapsPerUserRuleType
      periodCapsPerUserRuleCount: $periodCapsPerUserRuleCount
      periodCapsPerUserRuleRange: $periodCapsPerUserRuleRange
      expirationRule: $expirationRule
      merchantIds: $merchantIds
      airdropUserIds: $airdropUserIds
    )
  }
`;

export const boltPolicyUpdateMutation = gql`
  mutation boltPolicyUpdate(
    $id: ID!
    $title: String!
    $startAt: Date!
    $endAt: Date
    $type: String!
    $receiverType: String!
    $status: String!
    $description: String
    $issueCountRuleType: String!
    $issueCountRuleCount: Int
    $issueCountRuleRange: [IssueCountRuleRangeInput]
    $periodCapsPerUserRuleType: String!
    $periodCapsPerUserRuleCount: Int
    $periodCapsPerUserRuleRange: [PeriodCapsPerUserRuleRangeInput]
    $expirationRule: ExpirationRuleInput
    $merchantIds: [String]
    $airdropUserIds: [String]
  ) {
    boltPolicyUpdate(
      id: $id
      title: $title
      startAt: $startAt
      endAt: $endAt
      type: $type
      receiverType: $receiverType
      status: $status
      description: $description
      issueCountRuleType: $issueCountRuleType
      issueCountRuleCount: $issueCountRuleCount
      issueCountRuleRange: $issueCountRuleRange
      periodCapsPerUserRuleType: $periodCapsPerUserRuleType
      periodCapsPerUserRuleCount: $periodCapsPerUserRuleCount
      periodCapsPerUserRuleRange: $periodCapsPerUserRuleRange
      expirationRule: $expirationRule
      merchantIds: $merchantIds
      airdropUserIds: $airdropUserIds
    )
  }
`;

export default {
  boltPolicyAddMutation,
  boltPolicyUpdateMutation,
};
