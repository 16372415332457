import gql from 'graphql-tag';

export const merchantListQuery = gql`
  query merchantList($skip: Int, $pageSize: Int) {
    merchantList(skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
      }
    }
  }
`;

export const promotionListQuery = gql`
  query promotionList($filter: String, $skip: Int, $pageSize: Int) {
    promotionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        discount
        endAt
        merchants {
          id
          name
        }
      }
      total
    }
  }
`;

export const promotionGroupQuery = gql`
  query promotionGroup($id: ID!) {
    promotionGroup(id: $id) {
      id
      title
      subTitle
      detailTitle
      description
      landingUrl
      createdAt
      updatedAt
      startAt
      endAt
      sortedIndex
      status
      promotions
      merchant {
        id
        name
      }
    }
  }
`;

export default {
  merchantListQuery,
  promotionListQuery,
  promotionGroupQuery,
};
