// TODO: get data from DB
const bankNameByCode = {
  '088': '신한은행',
  '011': '농협',
  '004': '국민은행',
  '020': '우리은행',
  '003': '기업은행',
  '023': 'SC제일은행',
  '002': '산업은행',
  '032': '부산은행',
  '031': '대구은행',
  '039': '경남은행',
  '007': '수협',
  '037': '전북은행',
  '035': '제주은행',
  '045': '새마을금고',
  '048': '신협',
  '071': '우체국',
  '089': 'K뱅크',
  '034': '광주은행',
  '090': '카카오뱅크',
  '005': '하나은행',
  '027': '씨티은행',
  '050': '저축은행',
  '092': '토스뱅크',
  269: '한화투자증권',
};

const bankCodeByName = {
  신한은행: '088',
  농협: '011',
  국민은행: '004',
  우리은행: '020',
  기업은행: '003',
  SC제일은행: '023',
  산업은행: '002',
  부산은행: '032',
  대구은행: '031',
  경남은행: '039',
  수협: '007',
  전북은행: '037',
  제주은행: '035',
  새마을금고: '045',
  신협: '048',
  우체국: '071',
  K뱅크: '089',
  광주은행: '034',
  카카오뱅크: '090',
  하나은행: '005',
  씨티은행: '027',
  저축은행: '050',
  토스뱅크: '092',
  한화투자증권: '269',
};

function getBankName(code) {
  return bankNameByCode[code] || '';
}

function getBankCode(bankName) {
  return bankCodeByName[bankName] || '';
}

export default {
  getBankName,
  getBankCode,
};
