import React from 'react';
import { arrayOf, any, string, bool, number } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import styles from '../Charts.scss';
import localStyles from './Top20ProductsChart.scss';
import CustomLegend from './CustomLegend';
import CustomTooltip from './CustomTooltip';

const mapStateToProps = state => ({
  isMobile: state.global.isMobile,
});

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Top20Products = ({ title, data, transactionVolume, isMobile }) => {
  const InjectedToolTip = props => <CustomTooltip {...props} transactionVolume={transactionVolume} />;

  return (
    <div className={styles.chartWrapper}>
      <div className={styles.chartName}>{title}</div>

      <ResponsiveContainer width="100%" height={isMobile ? 500 : 250}>
        <PieChart className={cx([styles.chart, localStyles.top20Chart, isMobile && localStyles.isMobile])}>
          <Pie data={data} labelLine={false} innerRadius={70} outerRadius={100} fill="#8884d8" dataKey="value">
            {data.map((entry, index) => (
              <Cell key={entry} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>

          <Tooltip content={InjectedToolTip} />
          <Legend
            content={<CustomLegend />}
            align={isMobile ? 'center' : 'right'}
            verticalAlign="bottom"
            layout={isMobile ? 'horizontal' : 'vertical'}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

Top20Products.propTypes = {
  title: string.isRequired,
  transactionVolume: number.isRequired,
  data: arrayOf(any).isRequired,
  isMobile: bool.isRequired,
};

export default connect(mapStateToProps)(Top20Products);
