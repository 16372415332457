import gql from 'graphql-tag';

export const noticeUploadImageMutation = gql`
  mutation noticeUploadImage($imageFile: FileUpload!) {
    noticeUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export default {
  noticeUploadImageMutation,
};
