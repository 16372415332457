import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { hasGroups, hasPermissions } from 'utils/permission';

@autobind
class Require extends PureComponent {
  static propTypes = {
    groups: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element,
  };

  static defaultProps = {
    groups: null,
    permissions: null,
    children: null,
  };

  render() {
    const { groups, permissions } = this.props;
    const shouldRender = !!(hasGroups(groups) && hasPermissions(permissions));

    if (!shouldRender) return null;

    return this.props.children;
  }
}

export default Require;
