export default [
  {
    title: 'direction',
    icons: [
      { title: 'step-backward', icon: 'step-backward' },
      { title: 'step-forward', icon: 'step-forward' },
      { title: 'fast-backward', icon: 'fast-backward' },
      { title: 'fast-forward', icon: 'fast-forward' },
      { title: 'shrink', icon: 'shrink' },
      { title: 'arrows-alt', icon: 'arrows-alt' },
      { title: 'down', icon: 'down' },
      { title: 'up', icon: 'up' },
      { title: 'left', icon: 'left' },
      { title: 'right', icon: 'right' },
      { title: 'caret-up', icon: 'caret-up' },
      { title: 'caret-down', icon: 'caret-down' },
      { title: 'caret-left', icon: 'caret-left' },
      { title: 'caret-right', icon: 'caret-right' },
      { title: 'up-circle', icon: 'up-circle' },
      { title: 'down-circle', icon: 'down-circle' },
      { title: 'left-circle', icon: 'left-circle' },
      { title: 'right-circle', icon: 'right-circle' },
      { title: 'double-right', icon: 'double-right' },
      { title: 'double-left', icon: 'double-left' },
      { title: 'vertical-left', icon: 'vertical-left' },
      { title: 'vertical-right', icon: 'vertical-right' },
      { title: 'forward', icon: 'forward' },
      { title: 'backward', icon: 'backward' },
      { title: 'rollback', icon: 'rollback' },
      { title: 'enter', icon: 'enter' },
      { title: 'retweet', icon: 'retweet' },
      { title: 'swap', icon: 'swap' },
      { title: 'swap-left', icon: 'swap-left' },
      { title: 'swap-right', icon: 'swap-right' },
      { title: 'arrow-up', icon: 'arrow-up' },
      { title: 'arrow-down', icon: 'arrow-down' },
      { title: 'arrow-left', icon: 'arrow-left' },
      { title: 'arrow-right', icon: 'arrow-right' },
      { title: 'play-circle', icon: 'play-circle' },
      { title: 'up-square', icon: 'up-square' },
      { title: 'down-square', icon: 'down-square' },
      { title: 'left-square', icon: 'left-square' },
      { title: 'right-square', icon: 'right-square' },
      { title: 'login', icon: 'login' },
      { title: 'logout', icon: 'logout' },
      { title: 'menu-fold', icon: 'menu-fold' },
      { title: 'menu-unfold', icon: 'menu-unfold' },
      { title: 'border-bottom', icon: 'border-bottom' },
      { title: 'border-horizontal', icon: 'border-horizontal' },
      { title: 'border-inner', icon: 'border-inner' },
      { title: 'border-outter', icon: 'border-outter' },
      { title: 'border-left', icon: 'border-left' },
      { title: 'border-right', icon: 'border-right' },
      { title: 'border-top', icon: 'border-top' },
      { title: 'border-verticle', icon: 'border-verticle' },
      { title: 'pic-center', icon: 'pic-center' },
      { title: 'pic-left', icon: 'pic-left' },
      { title: 'pic-right', icon: 'pic-right' },
      { title: 'radius-bottomleft', icon: 'radius-bottomleft' },
      { title: 'radius-bottomright', icon: 'radius-bottomright' },
      { title: 'radius-upleft', icon: 'radius-upleft' },
      { title: 'fullscreen', icon: 'fullscreen' },
      { title: 'fullscreen-exit', icon: 'fullscreen-exit' },
    ],
  },
  {
    title: 'suggestion',
    icons: [
      { title: 'question', icon: 'question' },
      { title: 'question-circle', icon: 'question-circle' },
      { title: 'plus', icon: 'plus' },
      { title: 'plus-circle', icon: 'plus-circle' },
      { title: 'pause', icon: 'pause' },
      { title: 'pause-circle', icon: 'pause-circle' },
      { title: 'minus', icon: 'minus' },
      { title: 'minus-circle', icon: 'minus-circle' },
      { title: 'plus-square', icon: 'plus-square' },
      { title: 'minus-square', icon: 'minus-square' },
      { title: 'info', icon: 'info' },
      { title: 'info-circle', icon: 'info-circle' },
      { title: 'exclamation', icon: 'exclamation' },
      { title: 'exclamation-circle', icon: 'exclamation-circle' },
      { title: 'close', icon: 'close' },
      { title: 'close-circle', icon: 'close-circle' },
      { title: 'close-square', icon: 'close-square' },
      { title: 'check', icon: 'check' },
      { title: 'check-circle', icon: 'check-circle' },
      { title: 'check-square', icon: 'check-square' },
      { title: 'clock-circle', icon: 'clock-circle' },
      { title: 'warning', icon: 'warning' },
      { title: 'issues-close', icon: 'issues-close' },
      { title: 'stop', icon: 'stop' },
    ],
  },
  {
    title: 'edit',
    icons: [
      { title: 'edit', icon: 'edit' },
      { title: 'form', icon: 'form' },
      { title: 'copy', icon: 'copy' },
      { title: 'scissor', icon: 'scissor' },
      { title: 'delete', icon: 'delete' },
      { title: 'snippets', icon: 'snippets' },
      { title: 'diff', icon: 'diff' },
      { title: 'highlight', icon: 'highlight' },
      { title: 'align-center', icon: 'align-center' },
      { title: 'align-left', icon: 'align-left' },
      { title: 'align-right', icon: 'align-right' },
      { title: 'bg-colors', icon: 'bg-colors' },
      { title: 'bold', icon: 'bold' },
      { title: 'italic', icon: 'italic' },
      { title: 'underline', icon: 'underline' },
      { title: 'strikethrough', icon: 'strikethrough' },
      { title: 'redo', icon: 'redo' },
      { title: 'undo', icon: 'undo' },
      { title: 'zoom-in', icon: 'zoom-in' },
      { title: 'zoom-out', icon: 'zoom-out' },
      { title: 'font-colors', icon: 'font-colors' },
      { title: 'font-size', icon: 'font-size' },
      { title: 'line-height', icon: 'line-height' },
      { title: 'colum-height', icon: 'colum-height' },
      { title: 'colum-width', icon: 'colum-width' },
      { title: 'dash', icon: 'dash' },
      { title: 'small-dash', icon: 'small-dash' },
      { title: 'sort-ascending', icon: 'sort-ascending' },
      { title: 'sort-descending', icon: 'sort-descending' },
      { title: 'drag', icon: 'drag' },
      { title: 'ordered-list', icon: 'ordered-list' },
      { title: 'radius-setting', icon: 'radius-setting' },
    ],
  },
  {
    title: 'data',
    icons: [
      { title: 'area-chart', icon: 'area-chart' },
      { title: 'pie-chart', icon: 'pie-chart' },
      { title: 'bar-chart', icon: 'bar-chart' },
      { title: 'dot-chart', icon: 'dot-chart' },
      { title: 'line-chart', icon: 'line-chart' },
      { title: 'radar-chart', icon: 'radar-chart' },
      { title: 'heat-map', icon: 'heat-map' },
      { title: 'fall', icon: 'fall' },
      { title: 'rise', icon: 'rise' },
      { title: 'stock', icon: 'stock' },
      { title: 'box-plot', icon: 'box-plot' },
      { title: 'fund', icon: 'fund' },
      { title: 'sliders', icon: 'sliders' },
    ],
  },
  {
    title: 'other',
    icons: [
      { title: 'lock', icon: 'lock' },
      { title: 'unlock', icon: 'unlock' },
      { title: 'bars', icon: 'bars' },
      { title: 'book', icon: 'book' },
      { title: 'calendar', icon: 'calendar' },
      { title: 'cloud', icon: 'cloud' },
      { title: 'cloud-download', icon: 'cloud-download' },
      { title: 'code', icon: 'code' },
      { title: 'copy', icon: 'copy' },
      { title: 'credit-card', icon: 'credit-card' },
      { title: 'delete', icon: 'delete' },
      { title: 'desktop', icon: 'desktop' },
      { title: 'download', icon: 'download' },
      { title: 'ellipsis', icon: 'ellipsis' },
      { title: 'file', icon: 'file' },
      { title: 'file-text', icon: 'file-text' },
      { title: 'file-unknown', icon: 'file-unknown' },
      { title: 'file-pdf', icon: 'file-pdf' },
      { title: 'file-word', icon: 'file-word' },
      { title: 'file-excel', icon: 'file-excel' },
      { title: 'file-jpg', icon: 'file-jpg' },
      { title: 'file-ppt', icon: 'file-ppt' },
      { title: 'file-markdown', icon: 'file-markdown' },
      { title: 'file-add', icon: 'file-add' },
      { title: 'folder', icon: 'folder' },
      { title: 'folder-open', icon: 'folder-open' },
      { title: 'folder-add', icon: 'folder-add' },
      { title: 'hdd', icon: 'hdd' },
      { title: 'frown', icon: 'frown' },
      { title: 'meh', icon: 'meh' },
      { title: 'smile', icon: 'smile' },
      { title: 'inbox', icon: 'inbox' },
      { title: 'laptop', icon: 'laptop' },
      { title: 'appstore', icon: 'appstore' },
      { title: 'link', icon: 'link' },
      { title: 'mail', icon: 'mail' },
      { title: 'mobile', icon: 'mobile' },
      { title: 'notification', icon: 'notification' },
      { title: 'paper-clip', icon: 'paper-clip' },
      { title: 'picture', icon: 'picture' },
      { title: 'poweroff', icon: 'poweroff' },
      { title: 'reload', icon: 'reload' },
      { title: 'search', icon: 'search' },
      { title: 'setting', icon: 'setting' },
      { title: 'share-alt', icon: 'share-alt' },
      { title: 'shopping-cart', icon: 'shopping-cart' },
      { title: 'tablet', icon: 'tablet' },
      { title: 'tag', icon: 'tag' },
      { title: 'tags', icon: 'tags' },
      { title: 'to-top', icon: 'to-top' },
      { title: 'upload', icon: 'upload' },
      { title: 'user', icon: 'user' },
      { title: 'video-camera', icon: 'video-camera' },
      { title: 'home', icon: 'home' },
      { title: 'loading', icon: 'loading' },
      { title: 'loading-3-quarters', icon: 'loading-3-quarters' },
      { title: 'cloud-upload', icon: 'cloud-upload' },
      { title: 'star', icon: 'star' },
      { title: 'heart', icon: 'heart' },
      { title: 'environment', icon: 'environment' },
      { title: 'eye', icon: 'eye' },
      { title: 'eye-invisible', icon: 'eye-invisible' },
      { title: 'camera', icon: 'camera' },
      { title: 'save', icon: 'save' },
      { title: 'team', icon: 'team' },
      { title: 'solution', icon: 'solution' },
      { title: 'phone', icon: 'phone' },
      { title: 'filter', icon: 'filter' },
      { title: 'exception', icon: 'exception' },
      { title: 'export', icon: 'export' },
      { title: 'customer-service', icon: 'customer-service' },
      { title: 'qrcode', icon: 'qrcode' },
      { title: 'scan', icon: 'scan' },
      { title: 'like', icon: 'like' },
      { title: 'dislike', icon: 'dislike' },
      { title: 'message', icon: 'message' },
      { title: 'pay-circle', icon: 'pay-circle' },
      { title: 'calculator', icon: 'calculator' },
      { title: 'pushpin', icon: 'pushpin' },
      { title: 'bulb', icon: 'bulb' },
      { title: 'select', icon: 'select' },
      { title: 'switcher', icon: 'switcher' },
      { title: 'rocket', icon: 'rocket' },
      { title: 'bell', icon: 'bell' },
      { title: 'disconnect', icon: 'disconnect' },
      { title: 'database', icon: 'database' },
      { title: 'compass', icon: 'compass' },
      { title: 'barcode', icon: 'barcode' },
      { title: 'hourglass', icon: 'hourglass' },
      { title: 'key', icon: 'key' },
      { title: 'flag', icon: 'flag' },
      { title: 'layout', icon: 'layout' },
      { title: 'printer', icon: 'printer' },
      { title: 'sound', icon: 'sound' },
      { title: 'usb', icon: 'usb' },
      { title: 'skin', icon: 'skin' },
      { title: 'tool', icon: 'tool' },
      { title: 'sync', icon: 'sync' },
      { title: 'wifi', icon: 'wifi' },
      { title: 'car', icon: 'car' },
      { title: 'schedule', icon: 'schedule' },
      { title: 'user-add', icon: 'user-add' },
      { title: 'user-delete', icon: 'user-delete' },
      { title: 'usergroup-add', icon: 'usergroup-add' },
      { title: 'usergroup-delete', icon: 'usergroup-delete' },
      { title: 'man', icon: 'man' },
      { title: 'woman', icon: 'woman' },
      { title: 'shop', icon: 'shop' },
      { title: 'gift', icon: 'gift' },
      { title: 'idcard', icon: 'idcard' },
      { title: 'medicine-box', icon: 'medicine-box' },
      { title: 'red-envelope', icon: 'red-envelope' },
      { title: 'coffee', icon: 'coffee' },
      { title: 'copyright', icon: 'copyright' },
      { title: 'trademark', icon: 'trademark' },
      { title: 'safety', icon: 'safety' },
      { title: 'wallet', icon: 'wallet' },
      { title: 'bank', icon: 'bank' },
      { title: 'trophy', icon: 'trophy' },
      { title: 'contacts', icon: 'contacts' },
      { title: 'global', icon: 'global' },
      { title: 'shake', icon: 'shake' },
      { title: 'api', icon: 'api' },
      { title: 'fork', icon: 'fork' },
      { title: 'dashboard', icon: 'dashboard' },
      { title: 'table', icon: 'table' },
      { title: 'profile', icon: 'profile' },
      { title: 'alert', icon: 'alert' },
      { title: 'audit', icon: 'audit' },
      { title: 'batch-folding', icon: 'batch-folding' },
      { title: 'branches', icon: 'branches' },
      { title: 'build', icon: 'build' },
      { title: 'border', icon: 'border' },
      { title: 'crown', icon: 'crown' },
      { title: 'experiment', icon: 'experiment' },
      { title: 'fire', icon: 'fire' },
      { title: 'money-collect', icon: 'money-collect' },
      { title: 'property-safety', icon: 'property-safety' },
      { title: 'read', icon: 'read' },
      { title: 'reconciliation', icon: 'reconciliation' },
      { title: 'rest', icon: 'rest' },
      { title: 'security-scan', icon: 'security-scan' },
      { title: 'insurance', icon: 'insurance' },
      { title: 'interation', icon: 'interation' },
      { title: 'safety-certificate', icon: 'safety-certificate' },
      { title: 'project', icon: 'project' },
      { title: 'thunderbolt', icon: 'thunderbolt' },
      { title: 'block', icon: 'block' },
      { title: 'cluster', icon: 'cluster' },
      { title: 'deployment-unit', icon: 'deployment-unit' },
      { title: 'dollar', icon: 'dollar' },
      { title: 'euro', icon: 'euro' },
      { title: 'pound', icon: 'pound' },
      { title: 'file-done', icon: 'file-done' },
      { title: 'file-exclamation', icon: 'file-exclamation' },
      { title: 'file-protect', icon: 'file-protect' },
      { title: 'file-search', icon: 'file-search' },
      { title: 'file-sync', icon: 'file-sync' },
      { title: 'gateway', icon: 'gateway' },
      { title: 'gold', icon: 'gold' },
      { title: 'robot', icon: 'robot' },
      { title: 'shopping', icon: 'shopping' },
    ],
  },
  {
    title: 'logo',
    icons: [
      { title: 'android', icon: 'android' },
      { title: 'apple', icon: 'apple' },
      { title: 'windows', icon: 'windows' },
      { title: 'ie', icon: 'ie' },
      { title: 'chrome', icon: 'chrome' },
      { title: 'github', icon: 'github' },
      { title: 'aliwangwang', icon: 'aliwangwang' },
      { title: 'dingding', icon: 'dingding' },
      { title: 'weibo-square', icon: 'weibo-square' },
      { title: 'weibo-circle', icon: 'weibo-circle' },
      { title: 'taobao-circle', icon: 'taobao-circle' },
      { title: 'html5', icon: 'html5' },
      { title: 'weibo', icon: 'weibo' },
      { title: 'twitter', icon: 'twitter' },
      { title: 'wechat', icon: 'wechat' },
      { title: 'youtube', icon: 'youtube' },
      { title: 'alipay-circle', icon: 'alipay-circle' },
      { title: 'taobao', icon: 'taobao' },
      { title: 'skype', icon: 'skype' },
      { title: 'qq', icon: 'qq' },
      { title: 'medium-workmark', icon: 'medium-workmark' },
      { title: 'gitlab', icon: 'gitlab' },
      { title: 'medium', icon: 'medium' },
      { title: 'linkedin', icon: 'linkedin' },
      { title: 'google-plus', icon: 'google-plus' },
      { title: 'dropbox', icon: 'dropbox' },
      { title: 'facebook', icon: 'facebook' },
      { title: 'codepen', icon: 'codepen' },
      { title: 'code-sandbox', icon: 'code-sandbox' },
      { title: 'code-sandbox-circle', icon: 'code-sandbox-circle' },
      { title: 'amazon', icon: 'amazon' },
      { title: 'google', icon: 'google' },
      { title: 'codepen-circle', icon: 'codepen-circle' },
      { title: 'alipay', icon: 'alipay' },
      { title: 'ant-design', icon: 'ant-design' },
      { title: 'aliyun', icon: 'aliyun' },
      { title: 'zhihu', icon: 'zhihu' },
      { title: 'slack', icon: 'slack' },
      { title: 'slack-square', icon: 'slack-square' },
      { title: 'behance', icon: 'behance' },
      { title: 'behance-square', icon: 'behance-square' },
      { title: 'dribbble', icon: 'dribbble' },
      { title: 'dribbble-square', icon: 'dribbble-square' },
      { title: 'instagram', icon: 'instagram' },
      { title: 'yuque', icon: 'yuque' },
      { title: 'alibaba', icon: 'alibaba' },
      { title: 'yahoo', icon: 'yahoo' },
    ],
  },
];
