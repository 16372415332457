import React, { useState } from 'react';
import PropTypes, { func, bool, objectOf, number } from 'prop-types';
import { Modal, Form, Radio } from 'antd';

import ContentUploadForm from './ContentUploadForm';
import apolloClient from '../../../../helpers/apolloClient';
import { brandUploadContent } from '../../../../redux/modules/brand/mutations';

const propTypes = {
  brandId: number.isRequired,
  visibleAdd: bool.isRequired,
  onClose: func.isRequired,
  form: objectOf(PropTypes.any).isRequired,
};

const ContentAddModal = ({ brandId, visibleAdd, onClose, form }) => {
  const [uploading, setUploading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const { getFieldDecorator } = form;

  function handleClose(ev) {
    if (ev) {
      ev.preventDefault();
    }

    form.resetFields();

    onClose(isAdded);
  }

  async function handleSubmit(ev) {
    if (ev) ev.preventDefault();

    form.validateFields(async err => {
      if (err) {
        const fieldsToCheck = ['visible', 'selected_content'];

        // noinspection DuplicatedCode
        for (let i = 0; i < fieldsToCheck.length; i += 1) {
          const field = fieldsToCheck[i];

          if (err[field]) {
            return;
          }
        }
      }

      const formFields = form.getFieldsValue();
      const { visible } = formFields;
      const { file, sourceFileName } = formFields.selected_content[0];

      setUploading(true);

      try {
        await apolloClient.mutate({
          mutation: brandUploadContent,
          variables: {
            file,
            brandId,
            sourceFileName,
            visible,
          },
        });
      } finally {
        setIsAdded(true);
        handleClose();
      }

      setUploading(false);
    });
  }

  return (
    <Modal
      title="브랜드 미디어 컨텐츠 추가"
      onOk={handleSubmit}
      onCancel={handleClose}
      visible={visibleAdd}
      cancelButtonProps={{ disabled: uploading }}
      closable={!uploading}
      confirmLoading={uploading}
      maskClosable={false}
      destroyOnClose
    >
      <Form onSubmit={handleSubmit}>
        <Form.Item label="공개 여부">
          {getFieldDecorator('visible', {
            rules: [{ required: true, message: '공개 여부를 선택해주세요' }],
          })(
            <Radio.Group>
              <Radio.Button value="visible">공개</Radio.Button>
              <Radio.Button value="invisible">숨김</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        <ContentUploadForm
          form={form}
          brandId={brandId}
          name="selected_content"
          label="동영상(MP4) 혹은 이미지 (JPG/PNG)"
          require
        />
      </Form>
    </Modal>
  );
};

ContentAddModal.propTypes = propTypes;

export default Form.create()(ContentAddModal);
