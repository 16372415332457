import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Table, Alert, message, Button } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { TabData } from 'containers/Layout';
import { addTab, focusTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { userHistoryQuery } from '../UserQueries';
import { getColumns } from './UserHistoryModalMetadata';

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
};

@connect(null, mapDispatchToProps)
@autobind
class UserHistoryModal extends Component {
  static propTypes = {
    id: string.isRequired,
    visible: bool.isRequired,
    onCloseModal: func.isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    push: func.isRequired,
  };

  state = {
    data: [],
    error: null,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  async getData() {
    const { id } = this.props;
    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: userHistoryQuery,
        variables: {
          id: id.replace(/CS-/, ''),
        },
      });

      const result = await this.query;

      if (result.data.userHistory) {
        this.setState({
          data: result.data.userHistory,
        });
      }

      this.setState({ loading: false });
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get user history: ${error.message}`);
      console.log(error);

      this.setState({ error, loading: false });
      throw error;
    }
  }

  openUserTab(id) {
    const tabData = new TabData({
      key: `CS-${id}`,
      title: `CS-${id.substr(0, 5)}...`,
      closable: true,
      data: {
        id,
      },
      componentType: 'UserDetail',
    });

    this.props.addTab('user', tabData);
    this.props.focusTab('user', `CS-${id.toString()}`);
    this.props.push(`/user/CS-${id}`);
  }

  renderError() {
    return (
      <div style={{ marginBottom: 10 }}>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <div style={{ height: 20 }} />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { visible, onCloseModal } = this.props;
    const { data, error, loading } = this.state;
    const columns = getColumns({
      onUserIdClick: this.openUserTab,
    });

    return (
      <Modal
        title="User History"
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Close"
        width={800}
      >
        {error && this.renderError()}

        <Table
          rowKey="id"
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          scroll={{ x: '100%' }}
        />
      </Modal>
    );
  }
}

export default UserHistoryModal;
