export default {
  $id: 'dfuser-edit-point-schema',
  title: 'dfuser-edit-point-schema',
  description: 'dfuser-edit-point-schema.',
  type: 'object',
  required: ['type', 'amount', 'checkAmount'],
  properties: {
    id: {
      type: 'string',
    },
    loginId: {
      type: 'string',
      title: 'User ID',
    },
    balance: {
      type: 'string',
      title: 'Current Point',
    },
    amount: {
      type: 'string',
      title: 'Point',
    },
    checkAmount: {
      type: 'string',
      title: 'Check Point',
    },
  },
};
