import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';
import classNames from 'classnames';
import styles from '../Tag.scss';

const TagFormItem = ({ children, ...props }) => (
  <Form.Item {...props} className={classNames(props.className, styles.formControl)}>
    {children}
  </Form.Item>
);

TagFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

TagFormItem.defaultProps = {
  className: null,
};

export default TagFormItem;
