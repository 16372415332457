import React from 'react';
import { InputNumber, Form } from 'antd';
import PropTypes from 'prop-types';
import { commify } from 'utils/stringUtil';
import { required } from 'utils/formValidator';

import { formItemLayout } from './layout';

function BoostNumberForm(props) {
  const { form, initial = null, label, name, require, onChange, disabled, readOnly } = props;
  const { getFieldDecorator } = form;
  const rules = [];

  if (require) {
    rules.push(required());
  }

  return (
    <Form.Item label={label} {...formItemLayout} required={require}>
      {getFieldDecorator(name, {
        initialValue: initial,
        rules,
      })(
        <InputNumber
          placeholder={label}
          onChange={onChange}
          min={0}
          max={1000000000}
          formatter={value => commify(value)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </Form.Item>
  );
}

BoostNumberForm.propTypes = {
  require: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  initial: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

BoostNumberForm.defaultProps = {
  disabled: false,
  require: false,
  initial: 0,
  onChange: null,
  readOnly: false,
};

export default BoostNumberForm;
