import React, { Component } from 'react';
import { Form, Table, Button, Input, InputNumber, Icon, Col } from 'antd';
import PropTypes from 'prop-types';
import { commify } from 'utils/stringUtil';
import { required } from 'utils/formValidator';

import { formItemLayoutWithoutLabel, formItemLayout } from './layout';

const style = {
  placeholderRight: {
    '::WebkitInputPlaceholder': {
      textAlign: 'right',
    },
  },
};

class BoostArrayForm extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    adder: PropTypes.arrayOf(PropTypes.any).isRequired,
    require: PropTypes.bool,
    validator: PropTypes.func,
    onChange: PropTypes.func,
    validatorMessage: PropTypes.string,
  };

  static defaultProps = {
    label: ' ',
    require: false,
    validator: null,
    validatorMessage: '',
    onChange: null,
  };

  state = {
    error: null,
  };

  id = 0;

  remove = target => {
    const { form, name, onChange } = this.props;
    const items = form.getFieldValue(name);

    form.setFieldsValue({
      [name]: items.filter(item => item.id !== target.id),
    });

    if (onChange) {
      onChange();
    }
  };

  add = e => {
    const { form, name, adder, validator, validatorMessage, onChange } = this.props;
    const items = form.getFieldValue(name);
    let isInvalid = false;

    e.preventDefault();

    const item = adder.reduce((prev, col) => {
      const value = form.getFieldValue(`boost-array-adder-form-${name}-${col.key}`);
      if (validator && (!value || validator(value))) {
        isInvalid = true;
        this.setState({
          error: validatorMessage,
        });
      }

      if (!value) {
        isInvalid = true;
      }

      prev[col.key] = value;
      return prev;
    }, {});

    if (isInvalid) {
      return;
    }

    this.id += 1;
    item.id = this.id;

    form.resetFields(adder.map(input => `boost-array-adder-form-${name}-${input.key}`));

    items.push(item);
    form.setFieldsValue({
      [name]: items,
    });

    if (onChange) {
      onChange();
    }

    this.setState({ error: null });
  };

  renderFormItems() {
    const { form, name, adder } = this.props;
    const items = form.getFieldValue(name);
    if (!items) {
      return null;
    }

    return (
      <Form.Item {...formItemLayoutWithoutLabel}>
        <Table
          {...formItemLayoutWithoutLabel}
          columns={adder.map(col => ({ title: col.title, dataIndex: col.key, key: col.key }))}
          rowKey={record => record.id}
          dataSource={items}
          onRow={record => ({
            onClick: () => this.remove(record),
          })}
          pagination={false}
          style={{
            whiteSpace: 'pre-wrap',
          }}
          bordered
        />
      </Form.Item>
    );
  }

  renderInputComponent(props, k) {
    const { name } = props;
    const { type, key, label } = k;

    const defaultComponentProps = {
      name: `boost-array-adder-form-${name}-${key}`,
      style: style.placeholderRight,
      placeholder: label,
    };

    switch (type) {
      case 'number':
        return (
          <InputNumber {...defaultComponentProps} min={0} formatter={value => commify(value)} onPressEnter={this.add} />
        );
      case 'multiline':
        return <Input.TextArea {...defaultComponentProps} row={2} style={{ resize: 'none' }} />;
      default:
        return <Input {...defaultComponentProps} onPressEnter={this.add} />;
    }
  }

  renderAdder() {
    const { adder, name, label, form } = this.props;
    const { error } = this.state;
    const spanSize = Math.floor(24 / adder.length);
    const rules = [];
    if (require) {
      rules.push(required());
    }

    let hasError = null;
    if (error) {
      hasError = {
        validateStatus: 'error',
        help: error,
      };
    }

    return (
      <Form.Item {...formItemLayout} label={label} {...hasError}>
        {adder.map(k => (
          <Col span={spanSize} key={`boost-array-adder-col-${name}-${k.key}`}>
            <Form.Item style={{ marginBottom: 0 }}>
              {form.getFieldDecorator(`boost-array-adder-form-${name}-${k.key}`, { initialValue: null })(
                this.renderInputComponent(this.props, k)
              )}
            </Form.Item>
          </Col>
        ))}
      </Form.Item>
    );
  }

  render() {
    const { form, name, initial } = this.props;
    form.getFieldDecorator(name, { initialValue: initial.map(val => ({ ...val, id: (this.id += 1) })) });

    return (
      <>
        {this.renderAdder()}
        <Form.Item {...formItemLayoutWithoutLabel}>
          <Button type="dashed" onClick={this.add} style={{ width: '100%' }}>
            <Icon type="plus" /> Add
          </Button>
        </Form.Item>
        {this.renderFormItems()}
      </>
    );
  }
}

export default BoostArrayForm;
