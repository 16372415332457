module.exports = {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'PolicyData',
        possibleTypes: [
          {
            name: 'PolicyPGFee',
          },
          {
            name: 'PolicyPayment',
          },
          {
            name: 'PolicyCharge',
          },
          {
            name: 'PolicyWithdrawal',
          },
        ],
      },
    ],
  },
};
