import React, { Component, createRef } from 'react';
import { func, string, objectOf, oneOf, any } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import moment from 'moment';
import {
  Form,
  Button,
  Divider,
  Input,
  InputNumber,
  Alert,
  DatePicker,
  message,
  Row,
  Col,
  Checkbox,
  Select,
} from 'antd';
import { showI18nModal } from 'redux/modules/i18n/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import I18nModal from 'containers/I18n/Modal/I18nModal';
import store from 'store';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import { Editor, Viewer } from '@toast-ui/react-editor';
import { AgreementMode } from '../AgreementConstants';
import { agreementQuery } from '../AgreementQueries';
import { AgreementGroup } from '../';
import Item from './AgreementFormItem';
import rules from './AgreementFormValidationRules';
import editorConfig from './AgreementFormEditorConfig';
import styles from './AgreementForm.scss';

const { Option } = Select;

const mapDispatchToProps = {
  showI18nModal,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class AgreementForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    onCancel: func.isRequired,
    onFork: func.isRequired,
    form: objectOf(any).isRequired,
    id: string,
    mode: oneOf([AgreementMode.ADD, AgreementMode.UPDATE]).isRequired,
    showI18nModal: func.isRequired,
  };

  static defaultProps = {
    id: null,
  };

  state = {
    // XHR Status
    data: null,
    error: null,
    loading: false,
    // Others
    fork: false,
    reagree: false,
    // Internal Form States
    descriptionError: null,
  };

  componentDidMount() {
    if (this.props.mode !== AgreementMode.ADD) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  input = {
    termType: null,
    title: null,
  };

  query = null;
  reagreeRef = createRef();
  descriptionRef = createRef();

  async getData() {
    const { id } = this.props;
    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: agreementQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.agreement;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Agreement ID');
      } else {
        this.setState({
          loading: false,
          data,
          reagree: data.reagree,
        });

        const issueDate = data.id.split('_')[1];
        const isIssued = moment().isSameOrAfter(issueDate);

        if (this.reagreeRef.current && !this.reagreeRef.current.getInstance().isViewer) {
          this.reagreeRef.current.getInstance().setHtml(data.reagreeText);
        }

        if (this.descriptionRef.current) {
          if (!isIssued) {
            this.descriptionRef.current.getInstance().setHtml(data.description);
          }
        }
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Agreement: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  setFork(ev) {
    this.setState(
      {
        fork: ev.target.checked,
      },
      () => {
        const { data, fork } = this.state;

        if (fork) {
          this.descriptionRef.current.getInstance().setHtml(data.description);
        }
      }
    );
  }

  setReagree(ev) {
    this.setState({
      reagree: ev.target.checked,
    });
  }

  showI18nModal({ type, title, columnName, formFieldName, value }) {
    const formFields = this.props.form.getFieldsValue();

    this.props.showI18nModal({
      type: type || 'input',
      title,
      tableName: 'agreement',
      targetId: this.props.id,
      columnName,
      currentValue: value || formFields[formFieldName || columnName],
    });
  }

  // In fork mode, issue date must be later than today
  validateForkIssueDate(rule, value, callback) {
    if (!this.state.fork) return callback();

    const { id } = this.props;
    const issueDate = id.split('_')[1];

    if (value && value.isSameOrBefore(issueDate, 'days')) {
      return callback(true);
    }

    return callback();
  }

  // In update mode, but has issued before, issue date must be later than previous issue date
  validateUpdateIssueDate(rule, value, callback) {
    if (this.state.fork) return callback();

    const previousId = get(this.state, 'data.previousId', '');

    if (!previousId) {
      return callback();
    }

    const prevIssueDate = previousId.split('_')[1];

    if (value && value.isSameOrBefore(prevIssueDate, 'days')) {
      return callback(true);
    }

    return callback();
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    const { fork, reagree } = this.state;

    this.setState({ descriptionError: null });

    if (err) {
      const fieldsToCheck = ['termType', 'title', 'issueDate'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const instance = this.descriptionRef.current.getInstance();

    const { mode, id } = this.props;
    const issueDate = id ? id.split('_')[1] : null;
    const isIssued = moment().isSameOrAfter(issueDate);
    const isUpdatingBeforeIssued = mode === AgreementMode.UPDATE && !isIssued;
    const isUpdatingAfterIssued = mode === AgreementMode.UPDATE && isIssued;
    let description;

    // Check description only needed
    if (mode === AgreementMode.ADD || fork || isUpdatingBeforeIssued) {
      description = instance.getHtml();

      if (!description) {
        // Currently using instance.focus() throws an error. Might be issue of Toast UI Editor.
        this.setState({
          descriptionError: new Error('Please enter the description.'),
        });

        return;
      }
    }

    this.setState({
      descriptionError: null,
    });

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    formFields.id = this.props.id;

    if (reagree) {
      formFields.reagree = true;
      formFields.reagreeText = this.reagreeRef.current.getInstance().getHtml();

      // Fix "data-tomark-pass" issue
      formFields.reagreeText = formFields.reagreeText.replace(/(\s+)?data-tomark-pass(\s+)?/gm, '');

      // Fix <br=""> issue
      formFields.reagreeText = formFields.reagreeText.replace(/<br="">/gm, '');
    }

    if (description) {
      // Fix "data-tomark-pass" issue
      description = description.replace(/(\s+)?data-tomark-pass(\s+)?/gm, '');

      // Fix <br=""> issue
      description = description.replace(/<br="">/gm, '');
      formFields.description = description;
    }

    if (!fork && isUpdatingAfterIssued) {
      delete formFields.issueDate;
      delete formFields.title;
      delete formFields.optional;
      delete formFields.description;
      delete formFields.reagree;
      delete formFields.reagreeText;
    }

    if (fork) {
      this.props.onFork(formFields);
    } else {
      this.props.onSubmit(formFields);
    }
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { mode, form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading, fork, reagree, descriptionError } = this.state;
    const data = this.state.data || {};
    const { id, group, title, sortedIndex, optional, isActive, issuedCount, description } = data;
    const termType = id ? id.split('_')[0] : null;
    const issueDate = id ? id.split('_')[1] : null;
    const isIssued = moment().isSameOrAfter(issueDate);
    const isUpdatingBeforeIssued = mode === AgreementMode.UPDATE && !isIssued;
    const isUpdatingAfterIssued = mode === AgreementMode.UPDATE && isIssued;
    const isDescriptionEditable = !isIssued || (isIssued && fork);

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles.agreementForm}>
        <div className={styles.title}>
          <h1>{startCase(mode.toLowerCase())} Agreement</h1>
        </div>

        <Item label="Group" style={{ marginBottom: 10 }}>
          {getFieldDecorator('group', {
            initialValue: group || 'required',
          })(
            <Select style={{ width: 150 }}>
              {Object.keys(AgreementGroup).map(key => (
                <Option key={key} value={AgreementGroup[key]}>
                  {AgreementGroup[key]}
                </Option>
              ))}
            </Select>
          )}
        </Item>

        {/* Term Type */}
        {!termType && (
          <Item label="Term Type" style={{ marginBottom: 10 }}>
            {getFieldDecorator('termType', {
              initialValue: termType,
              rules: rules.termType,
            })(
              <Input
                placeholder="Term Type"
                ref={node => (this.input.termType = node)}
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>
        )}

        {termType && (
          <Item label="Term Type" style={{ marginBottom: 0 }}>
            <p style={{ margin: 0 }}>{termType}</p>
          </Item>
        )}

        {/* Issued Count */}
        {id && (
          <Item label="Issued" style={{ marginBottom: 0 }}>
            <p style={{ margin: 0 }}>{issuedCount}</p>
          </Item>
        )}

        {/* Title */}
        <Item label="Title">
          {getFieldDecorator('title', {
            initialValue: title,
            rules: rules.title,
          })(
            <Input
              placeholder="Agreement Title"
              ref={node => (this.input.title = node)}
              autoComplete="off"
              disabled={isIssued && !fork}
              style={{ width: 300 }}
            />
          )}
          {id && (
            <Button
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
              onClick={() => this.showI18nModal({ title: 'Title', columnName: 'title' })}
            >
              Translate
            </Button>
          )}
        </Item>

        {/* Sorted Index */}
        <Item label="Sorting Order">
          {getFieldDecorator('sortedIndex', {
            initialValue: sortedIndex || 1,
            rules: rules.sortedIndex,
          })(<InputNumber placeholder="Sorting Order" min={1} style={{ width: 150 }} />)}
        </Item>

        {/* Fork */}
        {isUpdatingAfterIssued && (
          <Item label="Reissue">
            <Checkbox checked={fork} onChange={this.setFork} />
          </Item>
        )}

        {/* Issue Date */}
        <Item label="Issue Date">
          {getFieldDecorator('issueDate', {
            initialValue: issueDate ? moment(issueDate) : null,
            rules: [...rules.issueDate],
          })(
            <DatePicker
              disabled={!fork && isUpdatingAfterIssued}
              disabledDate={current =>
                current <
                moment()
                  .subtract(1, 'day')
                  .endOf('day')
              }
            />
          )}
        </Item>

        {/* Reagree */}
        {((mode === AgreementMode.UPDATE && fork) || issuedCount >= 2) && (
          <Item label="Need reagree">
            <Checkbox disabled={!(fork || isUpdatingBeforeIssued)} checked={reagree} onChange={this.setReagree} />
          </Item>
        )}

        {/* Reagree Text */}
        {reagree && (fork || isUpdatingBeforeIssued) && (
          <Item label="Reagree Text">
            {id && (
              <Button
                onClick={() =>
                  this.showI18nModal({
                    type: 'editor',
                    title: 'Reagree Text',
                    columnName: 'reagree_text',
                    value: this.reagreeRef.current.getInstance().getHtml(),
                  })
                }
              >
                Translate
              </Button>
            )}
            <Editor {...editorConfig.reagreeText} ref={this.reagreeRef} />
          </Item>
        )}

        {reagree && !fork && isUpdatingAfterIssued && (
          <Item label="Reagree Text">
            <div style={{ border: '1px solid #eee', padding: '10px 20px' }}>
              {id && (
                <Button
                  onClick={() =>
                    this.showI18nModal({
                      type: 'editor',
                      title: 'Reagree Text',
                      columnName: 'reagree_text',
                      value: data.reagreeText,
                    })
                  }
                >
                  Translate
                </Button>
              )}
              <Viewer ref={this.reagreeRef} initialValue={data.reagreeText} />
            </div>
          </Item>
        )}

        {/* Optional */}
        <Item label="Optional">
          {getFieldDecorator('optional', {
            initialValue: typeof optional === 'boolean' ? optional : false,
            valuePropName: 'checked',
          })(<Checkbox disabled={isIssued && !fork} />)}
        </Item>

        {/* Is Active */}
        <Item label="Active">
          {getFieldDecorator('isActive', {
            initialValue: typeof isActive === 'boolean' ? isActive : true,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Item>

        {/* Author */}
        <Item label="Author">
          <Input value={store.get('admin').fullname} disabled autoComplete="off" style={{ width: 150 }} />
        </Item>

        {/* Description */}
        {!isDescriptionEditable && (
          <Item label="Description">
            {id && (
              <Button
                onClick={() =>
                  this.showI18nModal({
                    type: 'editor',
                    title: 'Description',
                    columnName: 'description',
                    value: description,
                  })
                }
              >
                Translate
              </Button>
            )}
            <Viewer ref={this.descriptionRef} initialValue={description} />
          </Item>
        )}

        {isDescriptionEditable && (
          <Item label="Description" required help={descriptionError && descriptionError.message} validateStatus="error">
            <div className={descriptionError && styles.descriptionError} style={{ marginBottom: 5 }}>
              {id && (
                <Button
                  onClick={() =>
                    this.showI18nModal({
                      type: 'editor',
                      title: 'Description',
                      columnName: 'description',
                      value: this.descriptionRef.current.getInstance().getHtml(),
                    })
                  }
                >
                  Translate
                </Button>
              )}
              <Editor {...editorConfig.description} ref={this.descriptionRef} />
            </div>
          </Item>
        )}

        <Row>
          <Col span={4} />
          <Col xs={24} sm={16} style={{ textAlign: 'right' }}>
            <Button style={{ marginRight: 10 }} onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button type="primary" icon="save" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

        <I18nModal />
      </Form>
    );
  }
}

export default AgreementForm;
