import React from 'react';
import { Badge, Button, Row, Col } from 'antd';
import startCase from 'lodash/startCase';
import styles from './DFUser.scss';

const getStatus = status =>
  ({
    active: 'success',
    inactive: 'default',
  }[status]);

export function getColumns(options = {}) {
  const { isAdmin, openDFUserAccountModal, openDFUserPointModal } = options;
  const cols = [
    {
      title: 'ID',
      dataIndex: 'loginId',
    },
    {
      title: 'Point',
      dataIndex: 'balance',
      align: 'right',
    },
    {
      title: 'Transaction Key',
      dataIndex: 'transactionKey',
      align: 'center',
    },
    {
      title: 'Deposit Code',
      dataIndex: 'depositCode',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: function renderStatus(val) {
        return (
          <span>
            <Badge status={getStatus(val)} text={startCase(val)} />
          </span>
        );
      },
    },
    {
      title: 'Tools',
      align: 'center',
      render() {
        return (
          <div className={styles.top}>
            <Row type="flex" justify="center" gutter={12}>
              <Col>
                <Button type="primary" icon="edit" ghost onClick={openDFUserAccountModal}>
                  Edit
                </Button>
              </Col>
              {isAdmin && (
                <Col>
                  <Button type="primary" icon="swap" onClick={openDFUserPointModal}>
                    Point
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        );
      },
    },
  ];
  return cols;
}

export default {
  getColumns,
};
