import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon, Typography } from 'antd';

import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import {
  addBoltDrawPromotion,
  updateBoltDrawPromotion,
  getBoltDrawPromotionList,
} from 'redux/modules/boltDrawPromotion/actions';
import { boltDrawPromotionQuery } from 'redux/modules/boltDrawPromotion/queries';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';

import { BoostTextForm, BoostNumberForm, BoostSelectForm, BoostPeriodForm } from '../Forms';
import styles from './BoltDrawPromotion.scss';
import Images from './Images/Images';

const { Text } = Typography;

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boltDrawPromotion;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBoltDrawPromotion,
  updateBoltDrawPromotion,
  getBoltDrawPromotionList,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoltDrawPromotionForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    getBoltDrawPromotionList: PropTypes.func.isRequired,
    addBoltDrawPromotion: PropTypes.func.isRequired,
    updateBoltDrawPromotion: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boltDrawPromotion', id ? id.toString() : 'add');
    this.props.push('/boost/boltDrawPromotion');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: boltDrawPromotionQuery,
        variables: {
          id: +id,
        },
      });

      const result = await this.query;
      const data = result.data.boltDrawPromotion;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid BoltDraw Promotion ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get BoltDraw Promotion: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  refreshBoltDrawPromotionList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoltDrawPromotionList(currentPage, pageSize, filter);
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = [
        'title',
        'description',
        'important',
        'visibleStartAt',
        'visibleEndAt',
        'enterableStartAt',
        'enterableEndAt',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field] && instance) {
          if (typeof instance.input !== 'undefined') {
            instance.input.focus();
          }
          return;
        }

        if (err[field]) {
          message.error(`${field}: ${err[field].errors[0].message}`);
          return;
        }
      }
      return;
    }

    if (form.getFieldValue('winnerCount') < 1) {
      const instance = form.getFieldInstance('winnerCount');
      instance.focus();
      message.error('Winner count must be greater than 0');
      return;
    }

    if (form.getFieldValue('bettingTimeLimitMin') < 1) {
      const instance = form.getFieldInstance('bettingTimeLimitMin');
      instance.focus();
      message.error('Cool time must be greater than 0');
      return;
    }

    if (form.getFieldValue('bettingCountLimit') < 1) {
      const instance = form.getFieldInstance('bettingCountLimit');
      instance.focus();
      message.error('Count limit must be greater than 0');
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.status = formFields.status;
    fields.title = formFields.title;
    fields.description = formFields.description;
    fields.important = formFields.important;
    fields.visibleFrom = formFields.visibleStartAt;
    fields.visibleTo = formFields.visibleEndAt;
    fields.enterableFrom = formFields.enterableStartAt;
    fields.enterableTo = formFields.enterableEndAt;
    fields.winnerCount = formFields.winnerCount;
    fields.bettingTimeLimitMin = formFields.bettingTimeLimitMin;
    fields.bettingCountLimit = formFields.bettingCountLimit;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoltDrawPromotion(input);

    this.props.removeAndFocusPreviousTab('boltDrawPromotion', 'add');
    this.props.push('/boost/boltDrawPromotion');
    this.refreshBoltDrawPromotionList();
  }

  async update(input) {
    await this.props.updateBoltDrawPromotion(input);

    this.props.removeAndFocusPreviousTab('boltDrawPromotion', input.id.toString());
    this.props.push('/boost/boltDrawPromotion');
    this.refreshBoltDrawPromotionList();
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />
        <Divider />
        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form, id: boltDrawPromotionId } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <>
        <div style={{ padding: '15px 30px', margin: 0 }}>
          <Text strong mark>
            유의 사항
            <br />
          </Text>
          <Text>
            1. 응모 종료일이 추첨일입니다.
            <br />
            2. 쿨타임은 분(min) 단위로 설정할 수 있습니다. (최소 1분 이상)
            <br />
            3. 번개드로우 프로모션 생성 후 이미지를 추가할 수 있습니다. 이미지 추가/삭제/수정 후에 따로 저장하지 않아도
            됩니다.
          </Text>
        </div>
        <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
          <BoostSelectForm
            form={form}
            name="status"
            label="상태"
            initial={data.status || 'enabled'}
            items={[
              { label: 'Enabled', value: 'enabled' },
              { label: 'Disabled', value: 'disabled' },
            ]}
          />
          <BoostTextForm form={form} name="title" label="타이틀" initial={data.title} require />
          <BoostTextForm
            form={form}
            name="description"
            label="상품 설명"
            initial={data.description}
            multiline
            require
          />
          <BoostTextForm form={form} name="important" label="유의사항" initial={data.important} multiline require />
          <BoostPeriodForm
            form={form}
            name="visible"
            label="노출 기간"
            initial={[data.visibleFrom, data.visibleTo]}
            require
          />
          <BoostPeriodForm
            form={form}
            name="enterable"
            label="응모 기간"
            initial={[data.enterableFrom, data.enterableTo]}
            require
          />
          <BoostNumberForm form={form} name="winnerCount" label="당첨 인원" initial={data.winnerCount} require />
          <BoostNumberForm
            form={form}
            name="bettingTimeLimitMin"
            label="쿨타임 (min)"
            initial={data.bettingTimeLimitMin}
            require
          />
          <BoostNumberForm
            form={form}
            name="bettingCountLimit"
            label="개수 제한"
            initial={data.bettingCountLimit}
            require
          />
          <div className={styles.formButtonContainer}>
            <Button type="ghost" onClick={this.closeTab}>
              Cancel
            </Button>
            <Divider type="vertical" style={{ background: '#fff' }} />
            <Button type="primary" htmlType="submit" disabled={updating}>
              {id ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
        {boltDrawPromotionId !== 'add' && <Images boltDrawPromotionId={boltDrawPromotionId} />}
      </>
    );
  }
}

export default BoltDrawPromotionForm;
