import gql from 'graphql-tag';

export const agreementAddMutation = gql`
  mutation agreementAdd(
    $group: String!
    $termType: String!
    $title: String!
    $sortedIndex: Int!
    $issueDate: Date!
    $optional: Boolean!
    $isActive: Boolean!
    $description: String!
  ) {
    agreementAdd(
      group: $group
      termType: $termType
      title: $title
      sortedIndex: $sortedIndex
      issueDate: $issueDate
      optional: $optional
      isActive: $isActive
      description: $description
    )
  }
`;

export const agreementUpdateMutation = gql`
  mutation agreementUpdate(
    $id: ID!
    $group: String!
    $title: String
    $sortedIndex: Int!
    $issueDate: Date
    $optional: Boolean
    $isActive: Boolean!
    $description: String
    $reagree: Boolean
    $reagreeText: String
  ) {
    agreementUpdate(
      id: $id
      group: $group
      title: $title
      sortedIndex: $sortedIndex
      issueDate: $issueDate
      optional: $optional
      isActive: $isActive
      description: $description
      reagree: $reagree
      reagreeText: $reagreeText
    )
  }
`;

export const agreementForkMutation = gql`
  mutation agreementFork(
    $id: ID!
    $group: String!
    $title: String!
    $sortedIndex: Int!
    $issueDate: Date!
    $optional: Boolean!
    $isActive: Boolean!
    $description: String!
    $reagree: Boolean
    $reagreeText: String
  ) {
    agreementFork(
      id: $id
      group: $group
      title: $title
      sortedIndex: $sortedIndex
      issueDate: $issueDate
      optional: $optional
      isActive: $isActive
      description: $description
      reagree: $reagree
      reagreeText: $reagreeText
    )
  }
`;

export default {
  agreementAddMutation,
  agreementUpdateMutation,
};
