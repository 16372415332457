import React, { PureComponent } from 'react';
import { func, objectOf, any, number } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, /* DatePicker, */ Row, Col, Input, message } from 'antd';
import { rowProps, col4Props, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, reset, getUsers } from 'redux/modules/user/actions';
import rules from './UserFilterFormValidationRules';
import styles from './User.scss';

const { Item } = Form;
// const { RangePicker } = DatePicker;

const mapStateToProps = state => {
  const { pageSize, filter } = state.user;

  return {
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter,
  reset,
  getUsers,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class UserFilterForm extends PureComponent {
  static propTypes = {
    setFilter: func.isRequired,
    resetFilter: func.isRequired,
    reset: func.isRequired,
    getUsers: func.isRequired,
    form: objectOf(any).isRequired,
    filter: objectOf(any),
    pageSize: number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const { id, sequenceId, fullname, phone } = formFields;

    // if (registerDateRange) {
    //   const [startDate, endDate] = registerDateRange;

    //   if (endDate.diff(startDate, 'months') >= 3) {
    //     message.warning("Can't search date range over 3 months.");
    //     return;
    //   }
    // }

    // 특정 admin 허용을 위해 fullname과 phone 둘다 있어야 하는 조건은 서버에서 체크한다
    if (!id && !sequenceId && !fullname && !phone) {
      message.warning('must search by id or sequence Id or user name and phone number together');
      return;
    }

    this.props.setFilter(formFields);
    this.props.getUsers(formFields);
  }

  resetForm() {
    this.props.resetFilter();
    this.props.reset();

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { id, sequenceId, fullname, phone } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col {...col4Props}>
            <Item label="ID">
              {getFieldDecorator('id', {
                rules: rules.id,
                initialValue: id,
              })(<Input placeholder="User ID..." autoComplete="off" />)}
            </Item>
          </Col>

          {/* ID */}
          <Col {...col4Props}>
            <Item label="SequenceID">
              {getFieldDecorator('sequenceId', {
                rules: rules.id,
                initialValue: sequenceId,
              })(<Input placeholder="Sequence ID..." autoComplete="off" />)}
            </Item>
          </Col>

          {/* Phone */}
          <Col {...col4Props}>
            <Item label="Phone">
              {getFieldDecorator('phone', {
                initialValue: phone,
              })(<Input placeholder="010-0000-0000" autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Fullname */}
          <Col {...col4Props}>
            <Item label="Name">
              {getFieldDecorator('fullname', {
                rules: rules.fullname,
                initialValue: fullname,
              })(<Input placeholder="홍길동" autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Register Date */}
          {/*
          <Col {...itemLongColResponsiveProps}>
            <Item label="Register Date">
              {getFieldDecorator('registerDateRange', {
                rules: rules.registerDateRange,
                initialValue: registerDateRange
              })(<RangePicker style={{ width: '100%' }} />)}
            </Item>
          </Col>
          */}
        </Row>

        <Row {...rowProps}>
          <Col {...col2RightProps}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default UserFilterForm;
