import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider, Button, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setPageSize, setCurrentPage, getLuckyPromotions } from 'redux/modules/luckyPromotion/actions';

import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { ellipsis } from 'utils/text';

import { columns } from './LuckyPromotionMetadata';
import LuckyPromotionFilterForm from './Forms/LuckyPromotionFilterForm';
import LuckyPromotionForm from './Forms/LuckyPromotionForm';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.luckyPromotion;
  const { activeKey, list } = state.tabs.luckyPromotion;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getLuckyPromotions,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class LuckyPromotionContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getLuckyPromotions: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    moveTab: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    activeKey: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('luckyPromotion', listTab);
    }

    this.props.moveTab('luckyPromotion', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/promotion\/lucky\/(.+)/);

    if (match) {
      const id = match[1];
      const hasTab = find(this.props.list, { key: id });

      if (!hasTab) {
        if (id === 'add') {
          this.createNewPromotion();
        } else {
          this.openDataTab(id);
        }
      }

      this.props.focusTab('luckyPromotion', id);
    } else {
      this.props.focusTab('luckyPromotion', 'ListTab');

      const { currentPage, pageSize, filter } = this.props;
      this.props.getLuckyPromotions(currentPage, pageSize, filter);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey ||
      !isEqual(this.props.data, nextProps.data)
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/promotion/lucky') {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getLuckyPromotions(currentPage, pageSize, filter);
        this.props.focusTab('luckyPromotion', 'ListTab');
      } else {
        const match = pathname.match(/\/promotion\/lucky\/(.+)/);

        if (match) {
          const id = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: id });

          if (!hasTab) {
            if (id === 'add') {
              this.createNewPromotion();
            } else {
              this.openDataTab(id);
            }
          }

          this.props.focusTab('luckyPromotion', id);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/promotion/lucky') {
        this.props.focusTab('luckyPromotion', 'ListTab');
      }
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getLuckyPromotions(currentPage, newPageSize, filter);
  }

  handleCellClick(record, extra) {
    const { id, title } = record;
    const { index } = extra;

    if (index === 0) {
      this.openDataTab(id, { ...extra, title });
    }
  }

  /**
   * Opening tab for display Promotion
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openDataTab(id, extra = {}) {
    const title = extra.title ? ellipsis(extra.title, 8) : ellipsis(id, 5);
    const tabData = new TabData({
      key: `${id}`,
      title,
      closable: true,
      data: {
        id,
      },
      componentType: 'LuckyPromotionForm',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('luckyPromotion', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('luckyPromotion', id.toString());
      this.props.push(`/promotion/lucky/${id}`);
    }
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getLuckyPromotions(newPage, pageSize, filter);
  }

  createNewPromotion() {
    const newTab = new TabData({
      key: 'add',
      title: 'New Promotion',
      closable: true,
      data: {
        id: 'add',
      },
      componentType: 'LuckyPromotionForm',
    });

    this.props.addTab('luckyPromotion', newTab);
    this.props.focusTab('luckyPromotion', newTab.key);
    this.props.push('/promotion/lucky/add');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  /**
   * Get List Tab
   * @return {ReactElement}
   */
  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <Fragment>
        <LuckyPromotionFilterForm />
        <Divider />

        {/* Add User Button */}
        <Row type="flex" gutter={12}>
          <Col>
            <Button icon="plus-circle" type="primary" onClick={this.createNewPromotion}>
              New
            </Button>
          </Col>
        </Row>

        <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={this.handleCellClick} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getLuckyPromotions(currentPage, pageSize, filter);
      this.props.push('/promotion/lucky');
    } else {
      this.props.push(`/promotion/lucky/${activeKey}`);
    }
  }

  render() {
    return (
      <Fragment>
        <TabViewer
          tabKey="luckyPromotion"
          components={{
            ListTab: this.getListTab(),
            LuckyPromotionForm,
          }}
          onTabChanged={this.handleTabChange}
        />
      </Fragment>
    );
  }
}

export default LuckyPromotionContainer;
