import gql from 'graphql-tag';

export const paymentQuery = gql`
  query payment($id: ID!) {
    payment(id: $id) {
      id
      status
      idempotencyKey
      currency
      checkoutAmount
      billingAmount
      pointAmount
      cashAmount
      cashbackAmount
      canceledAmount
      canceledBillingAmount
      canceledPointAmount
      canceledCashAmount
      canceledDiscountAmount
      canceledCashbackAmount
      description
      returnUrl
      createdAt
      updatedAt
      data
      type
      chargingAmount
      discountAmount
      raw
      cashbackStatus
      user {
        id
        fullname
        phone
        status
        birthday
        gender
        ledger {
          id
        }
      }
      merchant {
        id
        name
        displayName
      }
      transactions {
        id
        createdAt
        type
        currency
        amount
        delta
        balance
        data {
          ... on ChargeInData {
            paymentId
            pgResult
          }
          ... on PaymentOutData {
            paymentId
          }
        }
        ledger {
          id
          user {
            id
            fullname
            status
            gender
            phone
          }
          merchant {
            id
            name
            displayName
            status
          }
        }
      }
      cashbacks {
        id
        title
        description
        discount
        status
        policyType
        benefitType
        startAt
        endAt
        priceMin
        priceMax
        maxDiscountAmount
      }
      coupon {
        id
        title
        description
        discount
        status
        issueType
        benefitType
        startAt
        endAt
        priceMin
        priceMax
        maxDiscountAmount
      }
      usedBoost {
        id
        status
        boostCampaignId
        boostPromotion {
          title
          subTitle
          brandId
        }
      }
      reportedBoost {
        boostId
        status
        rejectReason
      }
    }
  }
`;

export default {
  paymentQuery,
};
