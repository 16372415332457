import gql from 'graphql-tag';

export const saveGroupMutation = gql`
  mutation saveGroup($id: ID, $name: String!, $code: String!, $description: String) {
    saveGroup(id: $id, name: $name, code: $code, description: $description) {
      name
      code
      description
    }
  }
`;

export const saveGroupActionsMutation = gql`
  mutation saveGroupActions($groupId: ID!, $actionIds: [ID]!) {
    saveGroupActions(groupId: $groupId, actionIds: $actionIds) {
      name
      code
    }
  }
`;

export const saveGroupMenusMutation = gql`
  mutation saveGroupMenus($groupId: ID!, $menuIds: [ID]!) {
    saveGroupMenus(groupId: $groupId, menuIds: $menuIds) {
      name
      code
    }
  }
`;

export const removeGroupsMutation = gql`
  mutation removeGroups($ids: [ID]!) {
    removeGroups(ids: $ids)
  }
`;
