import gql from 'graphql-tag';

export const getAdminsQuery = gql`
  query getAdmins($filter: String, $skip: Int, $pageSize: Int) {
    getAdmins(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        uid
        phone
        fullname
        email
        blockedReason
        blockedAt
        createdAt
        merchants {
          id
          name
          displayName
        }
        status
        lastLoginAt
        allowedIp
      }
      total
    }
  }
`;

export const getGroupsQuery = gql`
  query getGroups($filter: String, $skip: Int, $pageSize: Int) {
    getGroups(filter: $filter, skip: $skip, pageSize: $pageSize) {
      id
      name
      code
      description
      admins {
        id
      }
    }
  }
`;

export default {
  getAdminsQuery,
  getGroupsQuery,
};
