import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { push } from 'connected-react-router';

import { getLocationCollectHistoryList } from 'redux/modules/locationCollectHistory/actions';

import LocationCollectHistoryList from './LocationCollectHistoryList';

const mapStateToProps = state => {
  const { loading, data, currentPage, totalCount, pageSize, filter } = state.locationCollectHistory;

  return {
    data,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  push,
  getLocationCollectHistoryList,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class LocationCollectHistoryContainer extends Component {
  static propTypes = {
    getLocationCollectHistoryList: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    filter: null,
  };

  componentDidMount() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getLocationCollectHistoryList(currentPage, pageSize, filter);
  }

  render() {
    return (
      <div style={{ backgroundColor: 'rgb(255, 255, 255)', padding: 50, minHeight: 500 }}>
        <LocationCollectHistoryList />
      </div>
    );
  }
}

export default LocationCollectHistoryContainer;
