import gql from 'graphql-tag';

export const settlementUpdateMutation = gql`
  mutation settlementUpdate($merchantId: String!) {
    settlementUpdate(merchantId: $merchantId)
  }
`;

export default {
  settlementUpdateMutation,
};
