import gql from 'graphql-tag';

export const boostExclusionRuleListQuery = gql`
  query boostExclusionRuleListQuery($filter: String, $skip: Int, $pageSize: Int) {
    boostExclusionRuleList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        type
        codeType
        code
      }
      total
    }
  }
`;

export const boostExclusionRuleQuery = gql`
  query boostExclusionRuleQuery($id: ID!) {
    boostExclusionRule(id: $id) {
      id
      type
      codeType
      code
    }
  }
`;

export default {
  boostExclusionRuleQuery,
  boostExclusionRuleListQuery,
};
