import React, { Component } from 'react';
import { bool, func, any, objectOf, string } from 'prop-types';
import { Modal, Form, Upload, Button, Icon, message, Input, Select } from 'antd';
import autobind from 'autobind-decorator';
import { TagTargetType } from '..';
import styles from '../Tag.scss';

const { Item } = Form;
const { Option } = Select;

@Form.create()
@autobind
class TagUserMappingFormModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    tagName: string.isRequired,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  state = {
    targetIdFile: null,
    loading: false,
  };

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ loading: true });
    const { form } = this.props;
    form.validateFields(this.validateFormFields);
    this.setState({ loading: false });
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['policyId'];
      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];
        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }
          return;
        }
      }
    }

    const { form } = this.props;
    const { targetIdFile } = this.state;
    const formFields = form.getFieldsValue();

    if (!targetIdFile) {
      message.warning('Please enter the User IDs(CSV).');
      return;
    }

    formFields.targetIdFile = targetIdFile;

    await this.props.onOk(formFields);
    this.cleanupBeforeClose();
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();
    this.setState({
      targetIdFile: null,
    });
  }

  checkFileValid(file) {
    const { type, size } = file;

    if (type !== 'text/csv' && type !== 'text/plain') {
      throw new Error('Only CSV/TEXT file can be uploaded.');
    }

    if (size / 1024 / 1024 > 100) {
      throw new Error('Max file size is 100MB.');
    }
  }

  setTargetIdFile(e) {
    try {
      this.checkFileValid(e.file);

      this.setState({ targetIdFile: e.file });
    } catch (err) {
      message.warning(err.message);
    }
  }

  resetTargetIdFile() {
    this.setState({ targetIdFile: null });
  }

  render() {
    const { visible, tagName } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { targetIdFile, loading } = this.state;

    return (
      <Modal
        title="Tag Mapping"
        onOk={!loading && this.handleSubmit}
        onCancel={this.cleanupBeforeClose}
        visible={visible}
      >
        <Form onSubmit={this.handleSubmit}>
          <Item label="Tag Name" required>
            {getFieldDecorator('tagName', { initialValue: tagName })(<Input readOnly autoComplete="off" />)}
          </Item>

          <Item label="Tag Target Type" style={{ marginBottom: 10 }} className={styles.formControlDisplay} required>
            {getFieldDecorator('targetType', { initialValue: TagTargetType.USER })(
              <Select>
                <Option value={TagTargetType.USER}>{TagTargetType.USER}</Option>
                <Option value={TagTargetType.MERCHANT}>{TagTargetType.MERCHANT}</Option>
              </Select>
            )}
          </Item>

          <Item label="User Ids File" style={{ marginBottom: 0 }}>
            <Upload
              onChange={this.setTargetIdFile}
              onRemove={this.resetTargetIdFile}
              beforeUpload={() => false}
              fileList={targetIdFile && [targetIdFile]}
              accept=".csv,.txt"
            >
              <Button>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default TagUserMappingFormModal;
