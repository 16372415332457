import ChaiDateForm from './components/ChaiDateForm';
import ChaiDividerForm from './components/ChaiDividerForm';
import ChaiNumberForm from './components/ChaiNumberForm';
import ChaiSelectForm from './components/ChaiSelectForm';
import ChaiTextForm from './components/ChaiTextForm';

export default {
  ChaiTextForm,
  ChaiSelectForm,
  ChaiDividerForm,
  ChaiNumberForm,
  ChaiDateForm,
};
