import gql from 'graphql-tag';

export const brandListQuery = gql`
  query brandListQuery($filter: String, $skip: Int, $pageSize: Int) {
    brandList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        logoImageUrl
        typoImageUrl
        color
      }
      total
    }
  }
`;

export const brandQuery = gql`
  query brand($id: Int!) {
    brand(id: $id) {
      id
      name
      logoImageUrl
      typoImageUrl
      color
    }
  }
`;

export const brandContentListQuery = gql`
  query brandContentList($id: Int!) {
    brandContentList(id: $id) {
      list {
        id
        sourceFileName
        bucketFileName
        type
        status
        visible
        priority
        errorMessage
        urls {
          type
          url
        }
      }
      total
    }
  }
`;

export default {
  brandQuery,
  brandListQuery,
  brandContentListQuery,
};
