import gql from 'graphql-tag';

export const contractAddMutation = gql`
  mutation contractAdd(
    $merchantId: ID!
    $termStartAt: Date!
    $termEndAt: Date!
    $settlementCycle: Int!
    $settlementProvideCycle: String!
    $autoExtension: String!
    $type: String!
    $fee: String!
    $feeType: String!
    $includedVat: Boolean!
  ) {
    contractAdd(
      merchantId: $merchantId
      termStartAt: $termStartAt
      termEndAt: $termEndAt
      settlementCycle: $settlementCycle
      settlementProvideCycle: $settlementProvideCycle
      autoExtension: $autoExtension
      type: $type
      fee: $fee
      feeType: $feeType
      includedVat: $includedVat
    ) {
      id
      termStartAt
      termEndAt
      settlementCycle
      autoExtension
      type
      fee
      feeType
      updatedAt
      includedVat
    }
  }
`;

export const contractUpdateMutation = gql`
  mutation contractUpdate(
    $id: ID!
    $termStartAt: Date!
    $termEndAt: Date!
    $settlementCycle: Int!
    $settlementProvideCycle: String!
    $autoExtension: String!
    $type: String!
    $fee: String!
  ) {
    contractUpdate(
      id: $id
      termStartAt: $termStartAt
      termEndAt: $termEndAt
      settlementCycle: $settlementCycle
      settlementProvideCycle: $settlementProvideCycle
      autoExtension: $autoExtension
      type: $type
      fee: $fee
    )
  }
`;

export default {
  contractAddMutation,
  contractUpdateMutation,
};
