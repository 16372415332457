import React from 'react';
import { formatDate } from 'utils/format';

export const columns = [
  {
    title: 'User ID',
    dataIndex: 'userId',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '15%',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    render(val) {
      return <span>{formatDate(val, 'YYYY-MM-DD hh:mm:ss')}</span>;
    },
    width: '20%',
  },
];

export default {
  columns,
};
