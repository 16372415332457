import gql from 'graphql-tag';

export const luckyPromotionListQuery = gql`
  query luckyPromotionList($filter: String, $skip: Int, $pageSize: Int) {
    luckyPromotionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        title
        description
        status
        startAt
        endAt
        priceMin
        priceMax
        amountCap
        countCap
        merchants {
          id
          name
          displayName
        }
        winPromotion {
          title
          discount
          maxDiscountAmount
        }
        losePromotion {
          title
          discount
          maxDiscountAmount
        }
      }
      total
    }
  }
`;

export const dummyPromotionListQuery = gql`
  query dummyPromotionList {
    dummyPromotionList {
      id
      createdAt
      updatedAt
      title
      description
      status
      startAt
      endAt
      priceMin
      priceMax
      amountCap
      countCap
      merchants {
        id
        name
        displayName
      }
      total
    }
  }
`;

export default {
  luckyPromotionListQuery,
  dummyPromotionListQuery,
};
