export default {
  $id: 'action-search-schema',
  title: 'action-search-schema',
  description: 'action-search-schema.',
  type: 'object',
  required: [],
  properties: {
    menu: {
      type: 'string',
      title: 'Menu Name',
    },
    name: {
      type: 'string',
      title: 'Action Name',
    },
    code: {
      type: 'string',
      title: 'Action Code',
    },
  },
};
