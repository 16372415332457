import { Badge, Button, Calendar, message, Table } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { TabData } from 'containers/Layout';
import formatCurrency from 'format-currency';
import apolloClient from 'helpers/apolloClient';
import moment from 'moment';
import { any, arrayOf, func, instanceOf, objectOf, string } from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setDate } from 'redux/modules/settlementDetail/actions';
import { addTab, focusTab } from 'redux/modules/tabs';
import { arrayToExcel } from 'utils/excelConverter';
import { isAdmin } from 'utils/permission';
import { commify } from 'utils/stringUtil';
import { settlementWithBankInfoQuery } from './SettlementQueries';

const settlementTableColumns = [
  {
    title: '결제일자',
    dataIndex: 'referenceDate',
    key: 'referenceDate',
    render: date => (moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : date),
  },
  {
    title: '정산일자',
    dataIndex: 'settlementDate',
    key: 'settlementDate',
    render: date => (moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : date),
  },
  {
    title: '승인건수',
    dataIndex: 'transactionCount',
    key: 'transactionCount',
    render: val => commify(val),
  },
  {
    title: '승인금액',
    dataIndex: 'plusAmount',
    key: 'plusAmount',
    render: val => commify(val),
  },
  {
    title: '취소건수',
    dataIndex: 'refundCount',
    key: 'refundCount',
    render: val => commify(val),
  },
  {
    title: '취소금액',
    dataIndex: 'minusAmount',
    key: 'minusAmount',
    render: val => commify(val),
  },
  {
    title: '수수료',
    dataIndex: 'feeAmount',
    key: 'feeAmount',
    render: val => commify(val),
  },
  {
    title: '수수료 VAT',
    dataIndex: 'feeTaxAmount',
    key: 'feeTaxAmount',
    render: val => commify(val),
  },
  {
    title: '입금예정액',
    dataIndex: 'settlementAmount',
    key: 'settlementAmount',
    render: (val, row) => commify(row.plusAmount + row.minusAmount - row.feeAmount - row.feeTaxAmount),
  },
];

const mapStateToProps = state => {
  const { merchant } = state;
  const { settlement } = state;
  const { merchantId, merchantName, date } = state.settlementDetail;

  return {
    merchant,
    settlement,
    merchantId,
    merchantName,
    date,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  setDate,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementCalendarContent extends Component {
  static propTypes = {
    push: func.isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    setDate: func.isRequired,
    getSettlements: func.isRequired,
    settlement: objectOf(any).isRequired,
    merchantId: string.isRequired,
    settlementPidData: arrayOf(any),
    selectedPid: string,
    date: instanceOf(moment).isRequired,
    mode: string.isRequired,
  };

  static defaultProps = {
    selectedPid: undefined,
    settlementPidData: [],
  };

  downloadSettlementWithBankInfo(date) {
    return async function bankFormat(e) {
      e.stopPropagation();
      e.preventDefault();

      const response = await apolloClient.query({
        query: settlementWithBankInfoQuery,
        variables: {
          date: date.format('YYYY-MM-DD'),
        },
      });

      const settlementList = response.data.settlementWithBankInfo;
      const merchantMerge = {};

      for (let i = 0; i < settlementList.length; i += 1) {
        const settlement = settlementList[i];
        if (!merchantMerge[settlement.merchantId]) {
          merchantMerge[settlement.merchantId] = {
            ...settlement,
            amount: 0,
          };
        }

        merchantMerge[settlement.merchantId].amount += settlement.amount;
      }

      const merchantList = Object.values(merchantMerge);
      const arr = [];

      for (let i = 0; i < merchantList.length; i += 1) {
        const val = merchantList[i];
        // 잔액이 유보금만큼 남아있도록 차액 정산한다
        const settlementAmount = Math.min(
          val.amount - val.feeAndTaxAmount,
          val.balance - val.reserveAmount - val.feeAndTaxAmount
        );
        if (val.amount > 0) {
          arr.push([
            val.bankName,
            val.bankAccount,
            formatCurrency(settlementAmount, { minimumFractionDigits: 0 }),
            val.bankHolder,
            '',
            '',
            '(주)차이코퍼레이션',
            `${val.displayName} 정산`,
            formatCurrency(val.balance, { minimumFractionDigits: 0 }),
            formatCurrency(val.reserveAmount, { minimumFractionDigits: 0 }),
            formatCurrency(val.amount, { minimumFractionDigits: 0 }),
            formatCurrency(val.feeAndTaxAmount, { minimumFractionDigits: 0 }),
          ]);
        }
      }

      const blobData = arrayToExcel(arr);

      const blob = new Blob([blobData], { type: 'application/vnd.ms-excel;charset=euc-kr' });
      const downloadElem = window.document.createElement('a');

      downloadElem.href = window.URL.createObjectURL(blob);
      downloadElem.download = `${date.format('YYYY-MM-DD')}_입금.xls`;

      document.body.appendChild(downloadElem);
      downloadElem.click();
      document.body.removeChild(downloadElem);

      return false;
    };
  }

  getSettlementWithPid(settlementData, selectedPid, settlementPidData) {
    if (!selectedPid) {
      return settlementData;
    }

    const targetDate = settlementData.referenceDate;
    const pidTargetData = settlementPidData.find(
      pidData => pidData.referenceDate === moment(targetDate).format('YYYYMMDD') && pidData?.pids[selectedPid]
    );

    if (pidTargetData) {
      return {
        ...settlementData,
        ...pidTargetData.pids[selectedPid],
      };
    }

    // pidData 없는 경우
    return null;
  }

  getSettlementsByMonth(targetDate) {
    // 테이블 모드 렌더링
    const { selectedPid, settlementPidData } = this.props;
    const settlementData = this.props.settlement?.data;
    const thisMonthSettlement = settlementData
      .map(settlementItem => {
        if (!targetDate.isSame(settlementItem.referenceDate, 'month')) {
          return null;
        }
        if (selectedPid) {
          return this.getSettlementWithPid(settlementItem, selectedPid, settlementPidData);
        }

        return settlementItem;
      })
      .filter(Boolean);

    const summary = thisMonthSettlement.reduce(
      (prev = {}, curr) => ({
        referenceDate: 'total',
        settlementDate: '',
        transactionCount: (prev.transactionCount || 0) + (curr.transactionCount || 0),
        refundCount: (prev.refundCount || 0) + curr.refundCount,
        minusAmount: (prev.minusAmount || 0) + curr.minusAmount,
        plusAmount: (prev.plusAmount || 0) + curr.plusAmount,
        feeAmount: (prev.feeAmount || 0) + curr.feeAmount,
        feeTaxAmount: (prev.feeTaxAmount || 0) + curr.feeTaxAmount,
      }),
      undefined
    );

    return [...thisMonthSettlement, summary].filter(Boolean);
  }

  /**
   * Get Settlement by Date
   * @param {moment} targetDate
   * @return {SettlementEntity}
   */
  getSettlementByDate(targetDate) {
    const { data: settlementList } = this.props.settlement;

    for (let i = 0; i < settlementList.length; i += 1) {
      const settlementDate = moment(settlementList[i].settlementDate);

      if (settlementDate.isSame(targetDate, 'day')) {
        return settlementList[i];
      }
    }

    return null;
  }

  /**
   * Get all settlements by date
   * @param {moment} targetDate
   * @return {SettlementEntity[]}
   */
  getSettlementsByDate(targetDate) {
    const { data: settlementList } = this.props.settlement;
    const list = [];

    for (let i = 0; i < settlementList.length; i += 1) {
      const settlementDate = moment(settlementList[i].settlementDate);

      if (settlementDate.isSame(targetDate, 'day')) {
        list.push(settlementList[i]);
      }
    }

    return list;
  }

  /**
   * Open Settlement List
   * @param {moment} date
   */
  openPaymentListTab(date) {
    const { selectedPid } = this.props;
    if (selectedPid) {
      // pid 선택되있는 경우 상세보기 블록
      return;
    }

    const settlement = this.getSettlementByDate(date);

    if (!settlement) {
      message.warning('No Settlement Found.');
      return;
    }

    const { merchantId } = this.props;

    if (merchantId === 'all') {
      message.warning('No Mechant selected.');
      return;
    }

    const title = `${merchantId.substr(0, 5)}... - ${date.format('YYYY-MM-DD')}`;

    const newTab = new TabData({
      key: `${merchantId}_${date.format('YYYY-MM-DD')}_listTab`,
      componentType: 'SettlementDetail',
      title,
      closable: true,
      data: {
        date: date.format('YYYY-MM-DD'),
        merchantId,
      },
    });

    this.props.push(`/settlement/${merchantId}/${date.format('YYYY-MM-DD')}`);
    this.props.addTab('settlement', newTab);
    this.props.focusTab('settlement', newTab.key);
  }

  /**
   * Change Target Date
   * @param {moment} date
   */
  changeDate(date) {
    const { merchantId } = this.props;
    this.props.setDate(date);
    this.props.getSettlements(merchantId, date);
  }

  /**
   * Render each Date Cell
   * @param {moment} date
   */
  renderDateCell(date) {
    const { selectedPid, settlementPidData } = this.props;
    const targetSettlements = selectedPid
      ? [
          settlementPidData.find(
            pidData => pidData.pids[selectedPid] && pidData.settlementDate === date.format('YYYYMMDD')
          )?.pids[selectedPid],
      ].filter(Boolean)
      : this.getSettlementsByDate(date);

    if (targetSettlements.length <= 0) {
      return <div />;
    }

    let plusAmounts = 0;
    let minusAmounts = 0;
    let feeAmounts = 0;
    let feeTaxAmounts = 0;

    for (let i = 0; i < targetSettlements.length; i += 1) {
      plusAmounts += targetSettlements[i].plusAmount;
      minusAmounts += targetSettlements[i].minusAmount;
      feeAmounts += targetSettlements[i].feeAmount;
      feeTaxAmounts += targetSettlements[i].feeTaxAmount;
    }

    const settlementAmount = plusAmounts + minusAmounts - feeAmounts - feeTaxAmounts;

    return (
      <div>
        <Badge status="success" text={`${formatCurrency(settlementAmount, { minimumFractionDigits: 0 })} KRW`} />
        {isAdmin() && !selectedPid && (
          <Button
            type="primary"
            icon="download"
            size="small"
            onClick={this.downloadSettlementWithBankInfo(date)}
            style={{ marginLeft: 5 }}
          >
            excel
          </Button>
        )}
      </div>
    );
  }

  render() {
    const { mode, date } = this.props;
    // 캘린더 모드 렌더링
    if (mode === 'calendar') {
      return (
        <Calendar
          mode="month"
          value={date.startOf('day')}
          dateCellRender={this.renderDateCell}
          onSelect={this.openPaymentListTab}
          onPanelChange={this.changeDate}
        />
      );
    }

    // 테이블 모드 렌더링
    const dataSource = this.getSettlementsByMonth(date);
    return (
      <Table
        dataSource={dataSource}
        columns={settlementTableColumns}
        bordered
        rowKey="id"
        pagination={{
          current: 1,
          pageSize: 10000,
          hideOnSinglePage: true,
        }}
        style={{ paddingTop: 40, paddingBottom: 10 }}
      />
    );
  }
}

export default SettlementCalendarContent;
