import React, { PureComponent, Fragment } from 'react';
import { func, objectOf, arrayOf, instanceOf, any, number, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Form, Input, InputNumber, Select, Row, Col, Button, DatePicker, Icon, Tooltip, message } from 'antd';
import autobind from 'autobind-decorator';
import { rowProps, col4Props, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getPayments, reset } from 'redux/modules/payment/actions';
import { getMerchants } from 'redux/modules/merchant/actions';
import { isAdmin } from 'utils/permission';
import { commify } from 'utils/stringUtil';
import rules from './PaymentFilterFormValidationRules';
import styles from './Payment.scss';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

/**
 * PaymentFilterForm
 * @prop {Function} handleSubmit - Function to handle submitted Form
 * @prop {Function} onReset - Function prop have to invoke when resetting
 * @prop {Object} form - Injected Form features by Ant.Design
 */

const mapStateToProps = state => {
  const { pageSize, filter } = state.payment;
  const { data, loading, error } = state.merchant;

  return {
    pageSize,
    filter,
    merchants: data,
    merchantsLoading: loading,
    merchantsError: error,
  };
};

const mapDispatchToProps = {
  setFilter,
  resetFilter,
  reset,
  getPayments,
  getMerchants,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PaymentFilterForm extends PureComponent {
  static propTypes = {
    setFilter: func.isRequired,
    resetFilter: func.isRequired,
    reset: func.isRequired,
    getPayments: func.isRequired,
    getMerchants: func.isRequired,
    form: objectOf(any).isRequired,
    filter: objectOf(any),
    pageSize: number.isRequired,
    merchants: arrayOf(any),
    merchantsLoading: bool,
    merchantsError: instanceOf(Error),
  };

  static defaultProps = {
    filter: null,
    merchants: [],
    merchantsLoading: false,
    merchantsError: null,
  };

  componentDidMount() {
    this.props.getMerchants(1, 300);
  }

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { period, userName, userPhone, id, idempotencyKey, status, amountBegin, amountEnd } = formFields;

    if (period && period.length > 0) {
      const [startDate, endDate] = period;

      if (endDate.diff(startDate, 'months') >= 3) {
        message.warning("Can't search date range over 3 months.");
        return;
      }
    }

    if (
      !isAdmin() &&
      !period &&
      !userName &&
      !userPhone &&
      !id &&
      !idempotencyKey &&
      !amountBegin &&
      !amountEnd &&
      !status
    ) {
      message.warning("Can't search without any values except status and source.");
      return;
    }

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getPayments(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();

    if (isAdmin()) {
      this.props.getPayments(1, pageSize, null);
    } else {
      this.props.reset();
    }

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { period, source, userName, userPhone, id, idempotencyKey, status, amountBegin, amountEnd } = filter;
    const { merchants, merchantsError, merchantsLoading } = this.props;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col {...col4Props}>
            <Item label="ID">
              {getFieldDecorator('id', {
                rules: rules.id,
                initialValue: id,
              })(<Input placeholder="Payment ID..." autoComplete="off" />)}
            </Item>
          </Col>

          {/* Idempotency Key */}
          <Col {...col4Props}>
            <Item label="Idempotency Key (OrderNo)">
              {getFieldDecorator('idempotencyKey', {
                rules: rules.idempotencyKey,
                initialValue: idempotencyKey,
              })(<Input placeholder="Idempotency Key (Order No)..." autoComplete="off" />)}
            </Item>
          </Col>

          {/* Phone */}
          <Col {...col4Props}>
            <Item label="Phone">
              {getFieldDecorator('userPhone', {
                initialValue: userPhone,
              })(<Input placeholder="010-0000-0000" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Fullname */}
          <Col {...col4Props}>
            <Item label="Name">
              {getFieldDecorator('userName', {
                rules: rules.userName,
                initialValue: userName,
              })(<Input placeholder="User Name" autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Source */}
          <Col {...col4Props}>
            {merchantsLoading && <Icon className={styles.merchantsLoadSpinner} type="loading" spin />}

            {!merchantsLoading && merchantsError && (
              <Fragment>
                <Tooltip title={merchantsError.message}>
                  <Icon type="warning" className={styles.merchantsLoadFailIcon} />
                </Tooltip>
                <Icon type="redo" className={styles.merchantsRefreshButton} onClick={this.getMerchants} />
              </Fragment>
            )}

            <Item label="Source">
              {getFieldDecorator('source', {
                initialValue: source || 'all',
              })(
                <Select showSearch placeholder="Merchant name" optionFilterProp="children">
                  <Option value="all">All</Option>
                  {merchants.map(merchant => (
                    <Option key={merchant.id} value={merchant.id}>
                      {merchant.displayName || merchant.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>

          {/* Status */}
          <Col {...col4Props}>
            <Item label="Status">
              {getFieldDecorator('status', {
                initialValue: status || 'all',
              })(
                <Select>
                  <Option value="all">All</Option>
                  <Option value="waiting">Waiting</Option>
                  <Option value="prepared">Prepared</Option>
                  <Option value="approved">Approved</Option>
                  <Option value="canceled">Canceled</Option>
                  <Option value="user_canceled">User Canceled</Option>
                  <Option value="confirmed">Confirmed</Option>
                  <Option value="failed">Failed</Option>
                  <Option value="timeout">Timeout</Option>
                  <Option value="churn">Churn</Option>
                </Select>
              )}
            </Item>
          </Col>

          {/* Period */}
          <Col {...col4Props}>
            <Item label="Period">
              {getFieldDecorator('period', {
                rules: rules.period,
                initialValue: period,
              })(<RangePicker style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Amount */}
          <Col {...col4Props}>
            <Item label="Amount">
              <Col xs={10}>
                <Item>
                  {getFieldDecorator('amountBegin', {
                    rules: rules.amountBegin,
                    initialValue: amountBegin,
                  })(<InputNumber span={8} min={0} style={{ width: '100%' }} formatter={value => commify(value)} />)}
                </Item>
              </Col>
              <Col xs={4} style={{ textAlign: 'center' }}>
                ~
              </Col>
              <Col xs={10}>
                <Item>
                  {getFieldDecorator('amountEnd', {
                    rules: [
                      ...rules.amountEnd,
                      {
                        message: 'End Amount must be bigger than Begin Amount',
                        validator: (rule, value, callback) => {
                          const formFields = this.props.form.getFieldsValue();

                          if (!formFields.amountEnd && formFields.amountEnd !== 0) {
                            return callback();
                          }

                          if (formFields.amountEnd <= formFields.amountBegin) {
                            return callback(true);
                          }

                          return callback();
                        },
                      },
                    ],
                    initialValue: amountEnd,
                  })(<InputNumber span={8} min={0} style={{ width: '100%' }} formatter={value => commify(value)} />)}
                </Item>
              </Col>
            </Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...col2RightProps}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps}>
            <Item>
              <Button type="default" icon="undo" style={{ width: '100%' }} onClick={this.resetForm}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default PaymentFilterForm;
