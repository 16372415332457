import React from 'react';
import { func, objectOf, any } from 'prop-types';
import apolloClient from 'helpers/apolloClient';
import autobind from 'autobind-decorator';
import withNetwork, { apiClientPropType } from 'helpers/withNetwork';
import {
  Row,
  Col,
  Tree,
  Form,
  Input,
  Button,
  Switch,
  InputNumber,
  message,
  Tag,
  TreeSelect,
  Icon,
  Popconfirm,
} from 'antd';
import { GET_ALL_MENU_LIST } from 'gql';
import IconsTree from './IconsTree';
import { saveMenuMutation, removeMenuMutation } from './MenuMutations';

const { TreeNode } = Tree;
const FormItem = Form.Item;

@autobind
@withNetwork
@Form.create()
class Menu extends React.PureComponent {
  static propTypes = {
    apiClient: apiClientPropType.isRequired,
    form: objectOf(any).isRequired,
    setFieldsValue: func.isRequired,
    resetFields: func.isRequired,
    validateFieldsAndScroll: func.isRequired,
    getFieldDecorator: func.isRequired,
  };

  state = {
    menuList: [],
    tempMenu: {
      id: '',
      parentId: '',
    },
    selected: false,
    addChild: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { data } = await apolloClient.query({
      query: GET_ALL_MENU_LIST,
    });

    this.setState({
      menuList: data.getAllMenuList,
    });
  };

  onSelect = selectedKeys => {
    const { setFieldsValue, resetFields } = this.props.form;

    if (selectedKeys.length === 0) {
      resetFields();

      this.setState({
        selected: false,
        addChild: false,
        tempMenu: {
          id: '',
          parentId: '',
        },
      });

      return;
    }

    const id = selectedKeys[0];
    const menu = this.findMenuById(id);

    this.setState({
      selected: true,
      addChild: false,
      tempMenu: { ...menu },
    });

    setFieldsValue({
      name: menu.name,
      title: menu.title,
      path: menu.path,
      sort: menu.sort,
      leftMenu: menu.leftMenu,
      isLock: menu.isLock,
      icon: menu.icon,
    });
  };

  findMenuById = id => {
    let menu = {};

    function getMenu(menuList) {
      menuList.map(item => {
        if (item.id === id) {
          menu = { ...item };
          menu.children = null;
        } else if (item.children && item.children.length > 0) {
          getMenu(item.children);
        }
        return null;
      });
    }

    getMenu(this.state.menuList);
    return menu;
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        const data = { id: this.state.tempMenu.id, parentId: this.state.tempMenu.parentId, ...values };

        try {
          await apolloClient.mutate({
            mutation: saveMenuMutation,
            variables: data,
          });

          message.success('Saved.');
          this.loadData();
        } catch (saveErr) {
          message.warning('Failed to save menu.');
          console.log(saveErr);
        }
      }
    });
  };

  removeMenu = async () => {
    try {
      const { id } = this.state.tempMenu;

      await apolloClient.mutate({
        mutation: removeMenuMutation,
        variables: { id },
      });

      message.success('Deleted.');
      this.loadData();
    } catch (err) {
      message.error('Failed to delete menu.');
      console.log(err);
    }
  };

  addNewMenu = () => {
    this.setState({
      addChild: false,
      selected: false,
      tempMenu: {
        ...this.state.tempMenu,
        id: '',
        parentId: '',
      },
    });

    this.props.form.resetFields();
  };

  addSubMenu = () => {
    this.setState({
      addChild: true,
      selected: false,
      tempMenu: {
        ...this.state.tempMenu,
        id: '',
        parentId: this.state.tempMenu.id,
      },
    });

    this.props.form.resetFields();
  };

  renderMenu(menuList) {
    return menuList.map(menu => (
      <TreeNode title={menu.title} key={menu.id}>
        {menu.children && menu.children.length > 0 ? this.renderMenu(menu.children) : ''}
      </TreeNode>
    ));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { selected, menuList, addChild } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
      },
    };

    return (
      <div>
        <Row type="flex" justify="start">
          <Col xs={24} sm={24} md={12} lg={6} xl={6} style={{ backgroundColor: '#fafafa' }}>
            <div style={{ padding: 10 }}>
              <Button icon="plus" type="primary" size="small" onClick={this.addNewMenu} style={{ marginRight: 10 }}>
                New Menu
              </Button>
              {selected && (
                <Button icon="plus" size="small" onClick={this.addSubMenu}>
                  Sub Menu
                </Button>
              )}
            </div>
            <Tree onSelect={this.onSelect}>{this.renderMenu(menuList)}</Tree>
          </Col>
          <Col xs={24} sm={24} md={12} lg={9} xl={9}>
            <Form onSubmit={this.handleSubmit}>
              {addChild && (
                <div style={{ padding: 10, paddingLeft: 50 }}>
                  <span style={{ paddingRight: 5 }}>Add</span>
                  <Tag color="#108ee9">{this.state.tempMenu.title}</Tag>
                  <span>Sub menu</span>
                </div>
              )}

              <FormItem {...formItemLayout} hasFeedback label="Key">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Key required!',
                    },
                  ],
                })(<Input placeholder="Unique Key" />)}
              </FormItem>
              <FormItem {...formItemLayout} hasFeedback label="Title">
                {getFieldDecorator('title', {
                  rules: [
                    {
                      required: true,
                      message: 'Title required!',
                    },
                  ],
                })(<Input placeholder="Menu Title" />)}
              </FormItem>
              <FormItem {...formItemLayout} hasFeedback label="Path">
                {getFieldDecorator('path', {
                  rules: [
                    {
                      required: true,
                      message: 'Path required!',
                    },
                  ],
                })(<Input placeholder="Path ex) user" />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Sort">
                {getFieldDecorator('sort', { initialValue: 0 })(<InputNumber min={0} />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Display on Left">
                {getFieldDecorator('leftMenu', { valuePropName: 'checked' })(<Switch />)}
              </FormItem>
              <FormItem {...formItemLayout} label="Locked">
                {getFieldDecorator('isLock', { valuePropName: 'checked' })(<Switch />)}
              </FormItem>
              <FormItem {...formItemLayout} hasFeedback label="Icon">
                {getFieldDecorator('icon', { initialValue: '' })(
                  <TreeSelect
                    showSearch
                    style={{ width: 300 }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                  >
                    {IconsTree}
                  </TreeSelect>
                )}
              </FormItem>

              <FormItem {...tailFormItemLayout}>
                {selected && (
                  <Popconfirm
                    placement="bottomRight"
                    title="Are you sure?"
                    onConfirm={this.removeMenu}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger" style={{ marginRight: 10 }} ghost>
                      <Icon type="delete" />
                    </Button>
                  </Popconfirm>
                )}

                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Menu;
