import apolloClient from 'helpers/apolloClient';
import { faqListQuery } from './queries';
import { faqAddMutation, faqUpdateMutation, faqRemoveMutation } from './mutations';

export const FETCH_FAQ = 'faq/FETCH';
export const FETCH_FAQ_SUCCESS = 'faq/FETCH_SUCCESS';
export const FETCH_FAQ_FAILURE = 'faq/FETCH_FAILURE';

export const ADD_FAQ = 'faq/ADD';
export const ADD_FAQ_SUCCESS = 'faq/ADD_SUCCESS';
export const ADD_FAQ_FAILURE = 'faq/ADD_FAILURE';

export const UPDATE_FAQ = 'faq/UPDATE';
export const UPDATE_FAQ_SUCCESS = 'faq/UPDATE_SUCCESS';
export const UPDATE_FAQ_FAILURE = 'faq/UPDATE_FAILURE';

export const REMOVE_FAQ = 'faq/REMOVE';
export const REMOVE_FAQ_SUCCESS = 'faq/REMOVE_SUCCESS';
export const REMOVE_FAQ_FAILURE = 'faq/REMOVE_FAILURE';

export const SET_FILTER = 'faq/SET_FILTER';
export const RESET_FILTER = 'faq/RESET_FILTER';

export const SET_CURRENT_PAGE = 'faq/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'faq/SET_PAGE_SIZE';

export function getFaqs(currentPage, pageSize) {
  return {
    types: [FETCH_FAQ, FETCH_FAQ_SUCCESS, FETCH_FAQ_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: faqListQuery,
        variables: {
          skip,
          pageSize,
        },
      });

      return result.data.faqList;
    },
  };
}

export function addFaq(faq) {
  return {
    types: [ADD_FAQ, ADD_FAQ_SUCCESS, ADD_FAQ_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: faqAddMutation,
        variables: faq,
      });

      return result.data.faqAdd;
    },
  };
}

export function updateFaq(faq) {
  return {
    types: [UPDATE_FAQ, UPDATE_FAQ_SUCCESS, UPDATE_FAQ_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: faqUpdateMutation,
        variables: faq,
      });

      return faq;
    },
  };
}

export function removeFaq(id) {
  return {
    types: [REMOVE_FAQ, REMOVE_FAQ_SUCCESS, REMOVE_FAQ_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: faqRemoveMutation,
        variables: {
          id,
        },
      });

      return id;
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
