import React, { Component } from 'react';
import { func, string, bool, element, objectOf, arrayOf, oneOfType, any, shape } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Tabs, Icon, Button } from 'antd';
import { removeAndFocusPreviousTab, focusTab, setData, clearTabs } from 'redux/modules/tabs';

const { TabPane } = Tabs;

const style = {
  backgroundColor: '#FFFFFF',
  padding: 10,
};

const TabProp = shape({
  key: string.isRequired,
  data: objectOf(any),
  componentType: string,
  closable: bool,
  title: oneOfType([string, element]),
});

@autobind
class TabViewer extends Component {
  static propTypes = {
    focusTab: func.isRequired,
    setData: func.isRequired,
    clearTabs: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    tabKey: string,
    activeKey: string,
    tabList: arrayOf(TabProp),
    components: objectOf(any).isRequired,
    onTabChanged: func,
  };

  static defaultProps = {
    tabKey: '',
    activeKey: 'default',
    tabList: [],
    onTabChanged: () => {},
  };

  /**
   * Switch Tab
   * @param {string} activeKey - Tab key to switch
   */
  switchTab(activeKey) {
    const { tabKey } = this.props;
    this.props.onTabChanged(activeKey);
    this.props.focusTab(tabKey, activeKey);
  }

  /**
   * Set TabData
   * @param {string} targetKey
   * @param {Object} newData
   */
  setTabData(targetKey, newData) {
    const { tabKey } = this.props;
    this.props.setData(tabKey, targetKey, newData);
  }

  /**
   * Get TabData
   */
  getTabData(data) {
    return data;
  }

  /**
   * Handles tab close
   * @param {string} targetKey
   * @param {string} action
   */
  handleTabEdit(targetKey, action) {
    if (action === 'remove') {
      const previousTab = this.getPreviousTab();
      this.props.onTabChanged(previousTab.key);

      const { tabKey } = this.props;
      this.props.removeAndFocusPreviousTab(tabKey, targetKey);
    }
  }

  /**
   * Get key of previous tab from currently activated tab
   * @return {TabData}
   */
  getPreviousTab() {
    const { activeKey, tabList } = this.props;
    let previousTab;

    for (let i = 0; i < tabList.length; i += 1) {
      if (tabList[i].key === activeKey) {
        previousTab = tabList[i - 1];

        // Previous Tab could be null if it's first tab
        if (!previousTab) {
          const firstTab = tabList[0];
          previousTab = firstTab;
        }

        break;
      }
    }

    return previousTab;
  }

  render() {
    const { activeKey, tabList, tabKey } = this.props;
    const { components } = this.props;

    return (
      <div style={style}>
        <Tabs
          activeKey={activeKey}
          type="editable-card"
          onChange={this.switchTab}
          onEdit={this.handleTabEdit}
          tabBarExtraContent={<Button icon="delete" onClick={() => this.props.clearTabs(tabKey)} />}
          hideAdd
          animate
        >
          {tabList.map(tab => {
            if (!components[tab.componentType]) {
              throw new Error(`TabViewer.render(): Invalid Component Type ${tab.componentType}.`);
            }

            const title = tab.icon ? <Icon type={tab.icon} /> : tab.title;
            const boundSetTabData = this.setTabData.bind(this, tab.key);
            const boundGetTabData = this.getTabData.bind(this, tab.data);

            if (typeof components[tab.componentType] === 'function') {
              // React doesn't allow the component has small case of first letter
              const TabComponent = components[tab.componentType];

              return (
                <TabPane key={tab.key} tab={title} closable={tab.closable}>
                  <TabComponent
                    {...tab.data}
                    activeTabKey={activeKey}
                    switchTab={this.switchTab}
                    setTabData={boundSetTabData}
                    getTabData={boundGetTabData}
                  />
                </TabPane>
              );
            }

            // To Add props in React Element, need to clone
            const TabComponent = React.cloneElement(components[tab.componentType], {
              activeTabKey: activeKey,
              ...tab.data,
              switchTab: this.switchTab,
              setTabData: boundSetTabData,
              getTabData: boundGetTabData,
            });

            return (
              <TabPane key={tab.key} tab={title} closable={tab.closable}>
                {TabComponent}
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }
}

export default function ConnectedTabViewer(props) {
  const mapStateToProps = state => {
    const targetTab = state.tabs[props.tabKey] || {};

    return {
      activeKey: targetTab.activeKey || 'default',
      tabList: targetTab.list || [],
    };
  };

  const mapDispatchToProps = {
    focusTab,
    removeAndFocusPreviousTab,
    setData,
    clearTabs,
  };

  const Wrapper = connect(mapStateToProps, mapDispatchToProps)(TabViewer);

  return <Wrapper {...props} />;
}

ConnectedTabViewer.propTypes = {
  tabKey: string.isRequired,
};
