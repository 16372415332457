import apolloClient from 'helpers/apolloClient';
import { luckyPromotionListQuery } from './queries';
import { luckyPromotionAddMutation, luckyPromotionUpdateMutation } from './mutations';

export const FETCH_LUCKY_PROMOTION = 'luckyPromotion/FETCH';
export const FETCH_LUCKY_PROMOTION_SUCCESS = 'luckyPromotion/FETCH_SUCCESS';
export const FETCH_LUCKY_PROMOTION_FAILURE = 'luckyPromotion/FETCH_FAILURE';

export const ADD_LUCKY_PROMOTION = 'luckyPromotion/ADD';
export const ADD_LUCKY_PROMOTION_SUCCESS = 'luckyPromotion/ADD_SUCCESS';
export const ADD_LUCKY_PROMOTION_FAILURE = 'luckyPromotion/ADD_FAILURE';

export const UPDATE_LUCKY_PROMOTION = 'luckyPromotion/UPDATE';
export const UPDATE_LUCKY_PROMOTION_SUCCESS = 'luckyPromotion/UPDATE_SUCCESS';
export const UPDATE_LUCKY_PROMOTION_FAILURE = 'luckyPromotion/UPDATE_FAILURE';

export const SET_FILTER = 'luckyPromotion/SET_FILTER';
export const RESET_FILTER = 'luckyPromotion/RESET_FILTER';

export const SET_CURRENT_PAGE = 'luckyPromotion/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'luckyPromotion/SET_PAGE_SIZE';

export function getLuckyPromotions(currentPage, pageSize, filter) {
  return {
    types: [FETCH_LUCKY_PROMOTION, FETCH_LUCKY_PROMOTION_SUCCESS, FETCH_LUCKY_PROMOTION_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: luckyPromotionListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.luckyPromotionList;
    },
  };
}

export function addLuckyPromotion(luckyPromotion) {
  return {
    types: [ADD_LUCKY_PROMOTION, ADD_LUCKY_PROMOTION_SUCCESS, ADD_LUCKY_PROMOTION_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: luckyPromotionAddMutation,
        variables: luckyPromotion,
      });

      return result.data.luckyPromotionAdd;
    },
  };
}

export function updateLuckyPromotion(luckyPromotion) {
  return {
    types: [UPDATE_LUCKY_PROMOTION, UPDATE_LUCKY_PROMOTION_SUCCESS, UPDATE_LUCKY_PROMOTION_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: luckyPromotionUpdateMutation,
        variables: luckyPromotion,
      });

      return luckyPromotion;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
