import App from './App';
import BasicLayout from './BasicLayout';
import { Exception403, Exception404, Exception500 } from './Exception';
import TabViewer, { TabData } from './TabViewer';

export default {
  App,
  BasicLayout,
  TabViewer,
  TabData,
  Exception403,
  Exception404,
  Exception500,
};
