import apolloClient from 'helpers/apolloClient';
import { paymentListQuery } from './queries';

export const FETCH_PAYMENT = 'payment/FETCH';
export const FETCH_PAYMENT_SUCCESS = 'payment/FETCH_SUCCESS';
export const FETCH_PAYMENT_FAILURE = 'payment/FETCH_FAILURE';

export const SET_FILTER = 'payment/SET_FILTER';
export const RESET_FILTER = 'payment/RESET_FILTER';

export const SET_CURRENT_PAGE = 'payment/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'payment/SET_PAGE_SIZE';

export const RESET = 'payment/RESET';

export function getPayments(currentPage, pageSize, filter) {
  return {
    types: [FETCH_PAYMENT, FETCH_PAYMENT_SUCCESS, FETCH_PAYMENT_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: paymentListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.paymentList;
    },
  };
}

/*
 * Set filter for getting Payment
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}
