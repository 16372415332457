import { Col, Form, Row } from 'antd';
import autobind from 'autobind-decorator';
import { ChaiSelectForm, ChaiTextForm } from 'common';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { rowProps } from 'ui/ResponsiveProps';

@Form.create()
@autobind
class ExchangePaymentRuleFilterForm extends PureComponent {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.setRef(this);
  }

  componentWillUnmount() {
    this.props.setRef(undefined);
  }

  getFilter() {
    return this.props.form.getFieldsValue();
  }

  resetFilter() {
    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* Query */}
          <Col xs={12}>
            <ChaiSelectForm
              form={form}
              label="Rule Type"
              name="ruleCodeType"
              items={[
                { label: '(None)', value: undefined },
                { label: 'Business Code', value: 'businessCode' },
                { label: 'Business Name', value: 'businessName' },
                { label: 'Merchant Name', value: 'merchantName' },
                { label: 'Merchant Id', value: 'merchantId' },
              ]}
            />
          </Col>
          <Col xs={12}>
            <ChaiTextForm form={form} label="Rule Content" name="code" />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default ExchangePaymentRuleFilterForm;
