import React, { Component } from 'react';
import { arrayOf, objectOf, node, any } from 'prop-types';
import { Route } from 'react-router';
import { trigger } from 'redial';
import NProgress from 'nprogress';
import asyncMatchRoutes from 'utils/asyncMatchRoutes';
import withRouter, { historyPropType, locationPropType } from 'helpers/withRouter';

@withRouter
class ReduxAsyncConnect extends Component {
  static propTypes = {
    children: node.isRequired,
    history: historyPropType.isRequired,
    location: locationPropType.isRequired,
    routes: arrayOf(any).isRequired,
    store: objectOf(any).isRequired,
    helpers: objectOf(any).isRequired,
  };

  state = {
    previousLocation: null,
    location: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.location !== nextProps.location) {
      return {
        previousLocation: prevState.location,
        location: nextProps.location,
      };
    }

    return null;
  }

  componentDidMount() {
    NProgress.configure({ trickleSpeed: 200 });
  }

  async componentDidUpdate() {
    const { previousLocation, location } = this.state;

    if (previousLocation) {
      const { history, routes, store, helpers } = this.props;

      // save the location so we can render the old screen
      NProgress.start();

      // load data while the old screen remains
      const { components, match, params } = await asyncMatchRoutes(routes, location.pathname);
      const triggerLocals = {
        ...helpers,
        store,
        match,
        params,
        history,
        location,
      };

      await trigger('fetch', components, triggerLocals);

      if (__CLIENT__) {
        await trigger('defer', components, triggerLocals);
      }

      // clear previousLocation so the next screen renders
      setTimeout(() => {
        this.setState({ previousLocation: null });
        NProgress.done();
      }, 0);
    }
  }

  getChildren = () => this.props.children;

  render() {
    const { location } = this.props;
    const { previousLocation } = this.state;

    // use a controlled <Route> to trick all descendants into
    // rendering the old location
    return <Route location={previousLocation || location} render={this.getChildren} />;
  }
}

export default ReduxAsyncConnect;
