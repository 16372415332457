import React, { Component } from 'react';
import { func, string } from 'prop-types';
import { Form, Modal, Select, Alert, Button, message } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import { applyBoostReportMutation } from '../PaymentMutations';
import { BoostReportRejectReason, BoostReportRejectReasonText } from '.';

const { Option } = Select;

@Form.create()
@autobind
class BoostReportModal extends Component {
  static propTypes = {
    id: string.isRequired,
    getData: func.isRequired,
    status: string.isRequired,
    rejectReason: string.isRequired,
  };

  state = {
    rejectReason: null,
    isBoostReportApprove: false,
    boostReportModalVisible: false,
  };

  selectReason(rejectReason) {
    this.setState({ rejectReason });
  }

  boostReportApprove() {
    this.setState({
      boostReportModalVisible: true,
      isBoostReportApprove: true,
      rejectReason: null,
    });
  }

  boostReportReject() {
    this.setState({
      boostReportModalVisible: true,
      isBoostReportApprove: false,
    });
  }

  hideBoostReportModal() {
    this.setState({
      boostReportModalVisible: false,
    });
  }

  async applyBoostReport() {
    const { isBoostReportApprove, rejectReason } = this.state;
    try {
      await apolloClient.mutate({
        mutation: applyBoostReportMutation,
        variables: {
          id: this.props.id,
          isBoostReportApprove,
          rejectReason,
        },
      });

      message.success('Success');

      this.props.getData();
      this.hideBoostReportModal();
    } catch (error) {
      message.error(error.message);
    }
  }

  render() {
    const { status, rejectReason } = this.props;
    const { boostReportModalVisible, isBoostReportApprove } = this.state;

    const isApproved = status === 'approved';
    const title = `Boost Report ${isApproved ? 'Approved' : 'Rejected'}`;
    const type = isApproved ? 'success' : 'warning';
    const description = rejectReason ? `Reason: ${BoostReportRejectReasonText[rejectReason]}` : ' ';

    if (status !== 'reported') {
      return (
        <DescriptionList size="large" style={{ marginBottom: 32 }}>
          <Alert message={title} description={description} type={type} showIcon />
        </DescriptionList>
      );
    }

    return (
      <DescriptionList size="large" style={{ marginBottom: 32 }}>
        <Alert message="Boost Reported" description=" " type="info" showIcon />
        <div style={{ float: 'right', margin: '-50px 25px 0 0' }}>
          <Button type="primary" onClick={this.boostReportApprove} style={{ margin: '5px' }}>
            Approve
          </Button>
          <Button type="danger" onClick={this.boostReportReject} style={{ margin: '5px' }}>
            Reject
          </Button>
        </div>
        <Modal
          title="Boost Report"
          onOk={this.applyBoostReport}
          onCancel={this.hideBoostReportModal}
          visible={boostReportModalVisible}
          okText="OK"
          cancelText="Cancel"
        >
          {isBoostReportApprove ? (
            <Form>
              <Form.Item style={{ marginBottom: 0 }}>
                <h3>Are you sure?</h3>
              </Form.Item>
            </Form>
          ) : (
            <Form>
              <h3>Select Reject Reason</h3>
              <Select onChange={this.selectReason} placeholder="Select">
                {Object.values(BoostReportRejectReason).map(value => (
                  <Option key={value} value={value}>
                    {BoostReportRejectReasonText[value]}
                  </Option>
                ))}
              </Select>
            </Form>
          )}
        </Modal>
      </DescriptionList>
    );
  }
}

export default BoostReportModal;
