import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';

import ExchangePaymentRuleList from './ExchangePaymentRuleList';
import ExchangePaymentRuleEdit from './ExchangePaymentRuleEdit';

export const listPathName = '/operation/exchangePaymentRule';

@autobind
class ExchangePaymentRuleContainer extends Component {
  static propTypes = {
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {};

  state = {
    editModalVisible: false,
    targetId: null,
  };

  componentDidMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);

    this.handleUrlChange();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (['POP', 'PUSH'].includes(history.action)) {
      if (pathname === listPathName) {
        this.closeEditModal();
        if (this.listChild?.getRuleList) {
          this.listChild.refresh();
        }
      } else {
        this.openEditModal();
      }
    }
  }

  openEditModal() {
    const { history } = this.props;
    const { pathname } = history.location;
    const match = pathname.match(/\/exchangePaymentRule\/(.+)/);
    const targetId = match?.[1];

    this.setState({
      editModalVisible: true,
      targetId,
    });
  }

  closeEditModal() {
    this.setState({
      editModalVisible: false,
    });
  }

  onCreateNew() {
    this.setState({
      editModalVisible: true,
      targetId: null,
    });
  }

  render() {
    const { editModalVisible, targetId } = this.state;

    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          padding: 10,
          position: 'relative',
        }}
      >
        <ExchangePaymentRuleList onCreateNew={this.onCreateNew} setRef={ref => (this.listChild = ref)} />
        {editModalVisible && <ExchangePaymentRuleEdit visible={editModalVisible} targetId={targetId} />}
      </div>
    );
  }
}

export default ExchangePaymentRuleContainer;
