import React from 'react';
import { formatDate } from 'utils/format';
import LinkText from 'components/LinkText';

const env = process.env.NODE_CONFIG_ENV || 'staging';
const ALICE_ADMIN_DOMAIN = env === 'prod' ? 'alice.chai.finance' : `alice-${env}.chai.finance`;

export const columns = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '10%',
  },
  {
    title: 'Boost Promotion ID',
    dataIndex: 'boostPromotionId',
    width: '10%',
    render: function renderPromotionId(val) {
      return <LinkText>{val.substr(0, 10)}</LinkText>;
    },
  },
  {
    title: 'Boost Campaign ID',
    dataIndex: 'boostCampaignId',
    width: '10%',
    render: function renderCampaignId(val) {
      return (
        <a target="_blank" rel="noopener noreferrer" href={`https://${ALICE_ADMIN_DOMAIN}/boost/1/campaign/${val}`}>
          {val}
        </a>
      );
    },
  },
  {
    title: 'Usable Date',
    dataIndex: 'usableFrom',
    render: function renderType(val, others) {
      return (
        <span>
          {formatDate(val, 'YYYY-MM-DD HH:mm')} ~ {formatDate(others.usableTo, 'YYYY-MM-DD HH:mm')}
        </span>
      );
    },
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '20%',
  },
];

export default {
  columns,
};
