import gql from 'graphql-tag';

export const noticeQuery = gql`
  query notice($id: ID!) {
    notice(id: $id) {
      id
      title
      content
      author
      status
      fixed
      imageUrls
    }
  }
`;

export default {
  noticeQuery,
};
