import apolloClient from 'helpers/apolloClient';
import { policyListQuery } from './queries';
import {
  policyPGFeeAddMutation,
  policyPGFeeUpdateMutation,
  policyPaymentLimitAddMutation,
  policyPaymentLimitUpdateMutation,
  policyChargeLimitAddMutation,
  policyChargeLimitUpdateMutation,
  policyWithdrawalLimitAddMutation,
  policyWithdrawalLimitUpdateMutation,
  policyRemoveMutation,
} from './mutations';

export const FETCH_POLICY = 'policy/FETCH';
export const FETCH_POLICY_SUCCESS = 'policy/FETCH_SUCCESS';
export const FETCH_POLICY_FAILURE = 'policy/FETCH_FAILURE';

export const ADD_POLICY_PGFEE = 'policy/ADD_PGFEE';
export const ADD_POLICY_PGFEE_SUCCESS = 'policy/ADD_PGFEE_SUCCESS';
export const ADD_POLICY_PGFEE_FAILURE = 'policy/ADD_PGFEE_FAILURE';

export const UPDATE_POLICY_PGFEE = 'policy/UPDATE_PGFEE';
export const UPDATE_POLICY_PGFEE_SUCCESS = 'policy/UPDATE_PGFEE_SUCCESS';
export const UPDATE_POLICY_PGFEE_FAILURE = 'policy/UPDATE_PGFEE_FAILURE';

export const ADD_POLICY_PAYMENT = 'policy/ADD_PAYMENT';
export const ADD_POLICY_PAYMENT_SUCCESS = 'policy/ADD_PAYMENT_SUCCESS';
export const ADD_POLICY_PAYMENT_FAILURE = 'policy/ADD_PAYMENT_FAILURE';

export const UPDATE_POLICY_PAYMENT = 'policy/UPDATE_PAYMENT';
export const UPDATE_POLICY_PAYMENT_SUCCESS = 'policy/UPDATE_PAYMENT_SUCCESS';
export const UPDATE_POLICY_PAYMENT_FAILURE = 'policy/UPDATE_PAYMENT_FAILURE';

export const ADD_POLICY_CHARGE = 'policy/ADD_CHARGE';
export const ADD_POLICY_CHARGE_SUCCESS = 'policy/ADD_CHARGE_SUCCESS';
export const ADD_POLICY_CHARGE_FAILURE = 'policy/ADD_CHARGE_FAILURE';

export const UPDATE_POLICY_CHARGE = 'policy/UPDATE_CHARGE';
export const UPDATE_POLICY_CHARGE_SUCCESS = 'policy/UPDATE_CHARGE_SUCCESS';
export const UPDATE_POLICY_CHARGE_FAILURE = 'policy/UPDATE_CHARGE_FAILURE';

export const ADD_POLICY_WITHDRAWAL = 'policy/ADD_WITHDRAWAL';
export const ADD_POLICY_WITHDRAWAL_SUCCESS = 'policy/ADD_WITHDRAWAL_SUCCESS';
export const ADD_POLICY_WITHDRAWAL_FAILURE = 'policy/ADD_WITHDRAWAL_FAILURE';

export const UPDATE_POLICY_WITHDRAWAL = 'policy/UPDATE_WITHDRAWAL';
export const UPDATE_POLICY_WITHDRAWAL_SUCCESS = 'policy/UPDATE_WITHDRAWAL_SUCCESS';
export const UPDATE_POLICY_WITHDRAWAL_FAILURE = 'policy/UPDATE_WITHDRAWAL_FAILURE';

export const REMOVE_POLICY = 'policy/REMOVE';
export const REMOVE_POLICY_SUCCESS = 'policy/REMOVE_SUCCESS';
export const REMOVE_POLICY_FAILURE = 'policy/REMOVE_FAILURE';

export const SET_FILTER = 'policy/SET_FILTER';
export const RESET_FILTER = 'policy/RESET_FILTER';

export const SET_CURRENT_PAGE = 'policy/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'policy/SET_PAGE_SIZE';

export function getPolicies(currentPage, pageSize, filter) {
  return {
    types: [FETCH_POLICY, FETCH_POLICY_SUCCESS, FETCH_POLICY_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: policyListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.policyList;
    },
  };
}

export function addPGFeePolicy(policy) {
  return {
    types: [ADD_POLICY_PGFEE, ADD_POLICY_PGFEE_SUCCESS, ADD_POLICY_PGFEE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: policyPGFeeAddMutation,
        variables: policy,
      });

      return result.data.policyPGFeeAdd;
    },
  };
}

export function updatePGFeePolicy(policy) {
  return {
    types: [UPDATE_POLICY_PGFEE, UPDATE_POLICY_PGFEE_SUCCESS, UPDATE_POLICY_PGFEE_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: policyPGFeeUpdateMutation,
        variables: policy,
      });

      return policy;
    },
  };
}

export function addPaymentPolicy(policy) {
  return {
    types: [ADD_POLICY_PAYMENT, ADD_POLICY_PAYMENT_SUCCESS, ADD_POLICY_PAYMENT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: policyPaymentLimitAddMutation,
        variables: policy,
      });

      return result.data.policyPaymentAdd;
    },
  };
}

export function updatePaymentPolicy(policy) {
  return {
    types: [UPDATE_POLICY_PAYMENT, UPDATE_POLICY_PAYMENT_SUCCESS, UPDATE_POLICY_PAYMENT_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: policyPaymentLimitUpdateMutation,
        variables: policy,
      });

      return policy;
    },
  };
}

export function addChargePolicy(policy) {
  return {
    types: [ADD_POLICY_CHARGE, ADD_POLICY_CHARGE_SUCCESS, ADD_POLICY_CHARGE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: policyChargeLimitAddMutation,
        variables: policy,
      });

      return result.data.policyChargeLimitAdd;
    },
  };
}

export function updateChargePolicy(policy) {
  return {
    types: [UPDATE_POLICY_CHARGE, UPDATE_POLICY_CHARGE_SUCCESS, UPDATE_POLICY_CHARGE_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: policyChargeLimitUpdateMutation,
        variables: policy,
      });

      return policy;
    },
  };
}

export function addWithdrawalPolicy(policy) {
  return {
    types: [ADD_POLICY_WITHDRAWAL, ADD_POLICY_WITHDRAWAL_SUCCESS, ADD_POLICY_WITHDRAWAL_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: policyWithdrawalLimitAddMutation,
        variables: policy,
      });

      return result.data.policyWithdrawalLimitAdd;
    },
  };
}

export function updateWithdrawalPolicy(policy) {
  return {
    types: [UPDATE_POLICY_WITHDRAWAL, UPDATE_POLICY_WITHDRAWAL_SUCCESS, UPDATE_POLICY_WITHDRAWAL_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: policyWithdrawalLimitUpdateMutation,
        variables: policy,
      });

      return policy;
    },
  };
}

export function removePolicy(id) {
  return {
    types: [REMOVE_POLICY, REMOVE_POLICY_SUCCESS, REMOVE_POLICY_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: policyRemoveMutation,
        variables: { id },
      });
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
