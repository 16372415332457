const rules = {
  currentPassword: [
    {
      type: 'string',
      required: true,
      message: 'Please enter the current password.',
    },
  ],
  newPassword: [
    {
      required: true,
      message: 'Please enter the new password.',
    },
    {
      length: 8,
      message: 'New password at least eight characters long.',
    },
  ],
  newPasswordConfirm: [
    {
      required: true,
      message: 'Please enter the new password again.',
    },
  ],
};

export default rules;
