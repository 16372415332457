import React from 'react';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

export const taregtColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <span>{val}</span>;
    },
    width: '14%',
    align: 'center',
  },
  {
    title: 'Target Id',
    dataIndex: 'targetId',
    render: function renderTargetID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '20%',
  },
  {
    title: 'type',
    dataIndex: 'type',
    render: function renderType(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'CreatedAt',
    dataIndex: 'dateHistory.createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '18%',
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'dateHistory.updatedAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '18%',
  },
];

export default taregtColumns;
