import React from 'react';
import { Table, Popconfirm, Divider, Button, notification, Tag, Modal, Icon, Tooltip } from 'antd';
import uuidv4 from 'uuid/v4';
import apolloClient from 'helpers/apolloClient';
import SearchForm from 'schema/SearchForm';
import schema from 'schema/group';
import EditGroupModal from './editGroupModal';
import EditGroupActionsModal from './editGroupActionsModal';
import EditGroupMenusModal from './editGroupMenusModal';
import EditGroupUserModalContent from './editGroupUserModalContent';
import { getGroupsQuery } from './GroupQueries';
import {
  saveGroupMutation,
  saveGroupActionsMutation,
  saveGroupMenusMutation,
  removeGroupsMutation,
} from './GroupMutations';
import styles from './styles.scss';

class Group extends React.PureComponent {
  state = {
    tableFilter: {
      name: '',
      code: '',
    },
    tableSelectedRowKeys: [],
    tablePagedList: [],
    tablePagination: {
      current: 1,
      pageSize: 10,
      showTotal: total => `Total ${total} item(s)`,
    },
    tableSorter: {
      field: '',
      order: '',
    },
    tableLoading: false,
    editModalVisible: false,
    editActionsModalVisible: false,
    editMenusModalVisible: false,
  };

  componentDidMount() {
    this.refresh();
  }

  editFormData = {};

  handleSearch = filter => {
    const pager = { ...this.state.tablePagination };
    pager.current = 1;
    this.setState({
      tableFilter: filter,
      tablePagination: pager,
    });
    const query = {
      skip: 0,
      pageSize: this.state.tablePagination.pageSize,
      sortBy: this.state.tableSorter.field,
      descending: this.state.tableSorter.order === 'descend',
      filter,
    };
    this.fetch(query);
  };

  handleReset = () => {
    this.setState({
      tableFilter: {},
    });

    this.fetch({
      skip: this.state.tablePagination.current,
      pageSize: this.state.tablePagination.pageSize,
      filter: {},
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.tablePagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      tablePagination: pager,
      tableSorter: {
        field: sorter.field,
        order: sorter.order,
      },
    });
    const query = {
      skip: pager.current,
      pageSize: pager.pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter: this.state.tableFilter,
    };
    this.fetch(query);
  };

  editGroupActions = record => {
    this.editFormData = { ...record };

    this.setState({
      editActionsModalVisible: true,
    });
  };

  editGroupMenus = record => {
    this.editFormData = { ...record };

    this.setState({
      editMenusModalVisible: true,
    });
  };

  saveGroupActions = async data => {
    const formData = { ...data };
    const { groupId, actionIds } = formData;

    try {
      await apolloClient.mutate({
        mutation: saveGroupActionsMutation,
        variables: { groupId, actionIds },
      });

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Saved.',
      });

      this.setState({
        editActionsModalVisible: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  saveGroupMenus = async data => {
    const formData = { ...data };
    const { groupId, menuIds } = formData;

    try {
      await apolloClient.mutate({
        mutation: saveGroupMenusMutation,
        variables: { groupId, menuIds },
      });

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Saved.',
      });

      this.setState({
        editMenusModalVisible: false,
      });
    } catch (e) {
      console.log(e);
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ tableSelectedRowKeys: selectedRowKeys });
  };

  addGroup = () => {
    this.editFormData = {};
    this.setState({
      editModalVisible: true,
    });
  };

  batchDelGroup = async () => {
    try {
      const ids = this.state.tableSelectedRowKeys.map(s => s);
      await apolloClient.mutate({
        mutation: removeGroupsMutation,
        variables: { ids },
      });

      this.setState({
        tableSelectedRowKeys: [],
      });

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Removed',
      });
    } catch (e) {
      console.log(e);
    }
    this.refresh();
  };

  editGroup = record => {
    this.editFormData = { ...record };
    this.setState({
      editModalVisible: true,
    });
  };

  delGroup = async id => {
    try {
      const { removeGroups } = await apolloClient.mutate({
        mutation: removeGroupsMutation,
        variables: { ids: [id] },
      });

      if (removeGroups) {
        notification.success({
          placement: 'bottomLeft bottomRight',
          message: 'Removed.',
        });
      }
    } catch (e) {
      console.log(e);
    }
    this.refresh();
  };

  editModalOnCancel = () => {
    this.setState({
      editModalVisible: false,
      editActionsModalVisible: false,
      editMenusModalVisible: false,
      editAdminModalVisible: false,
    });
  };

  editGroupAdmin = record => {
    this.editFormData = { ...record };
    this.setState({
      editAdminModalVisible: true,
    });
  };

  saveGroup = async data => {
    const formData = { ...this.editFormData, ...data };
    try {
      const { id = uuidv4(), code, description = '', name } = formData;

      await apolloClient.mutate({
        mutation: saveGroupMutation,
        variables: {
          id,
          name,
          code,
          description,
        },
      });
      this.setState({
        editModalVisible: false,
      });
      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Save Success',
      });
    } catch (e) {
      console.log(e);
    }
    this.refresh();
  };

  refresh = () => {
    const query = {
      skip: this.state.tablePagination.current,
      pageSize: this.state.tablePagination.pageSize,
      sortBy: this.state.tableSorter.field,
      descending: this.state.tableSorter.order === 'descend',
      filter: this.state.tableFilter,
    };
    this.fetch(query);
  };

  fetch = async query => {
    this.setState({ tableLoading: true });

    const { filter, skip, pageSize } = query;
    const { data } = await apolloClient.query({
      query: getGroupsQuery,
      variables: {
        filter: JSON.stringify(filter),
        skip,
        pageSize,
      },
    });
    const { getGroups: groups } = data;

    const pagination = { ...this.state.tablePagination };
    pagination.total = data.totalCount;
    this.setState({
      tableLoading: false,
      tablePagedList: groups,
      tablePagination: pagination,
    });
  };

  columns = [
    {
      title: 'Group Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Tools',
      fixed: 'right',
      width: 200,
      render: (text, record) => (
        <div>
          <a onClick={() => this.editGroup(record)}>
            <Tooltip title="Edit Group">
              <Icon className={styles.toolIcon} type="edit" />
            </Tooltip>
          </a>
          <Divider type="vertical" />
          <a onClick={() => this.editGroupMenus(record)}>
            <Tooltip title="Menus of group">
              <Icon className={styles.toolIcon} type="ordered-list" />
            </Tooltip>
          </a>
          <Divider type="vertical" />
          <a onClick={() => this.editGroupActions(record)}>
            <Tooltip title="Actions of group">
              <Icon className={styles.toolIcon} type="api" />
            </Tooltip>
          </a>
          <Divider type="vertical" />
          <a onClick={() => this.editGroupAdmin(record)}>
            <Tooltip title="Admins of group">
              <Icon className={styles.toolIcon} type="usergroup-add" />
            </Tooltip>
          </a>
          <Divider type="vertical" />
          <Popconfirm title="Are you sure?" onConfirm={() => this.delGroup(record.id)}>
            <Tooltip title="Delete group">
              <Icon className={styles.toolIcon} type="delete" />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  render() {
    const { tableSelectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys: tableSelectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = tableSelectedRowKeys.length > 0;
    return (
      <div style={{ background: '#FFFFFF', padding: '10px' }}>
        <SearchForm
          schema={schema.searchSchema}
          uiSchema={schema.searchUiSchema}
          handleSubmit={this.handleSearch}
          handleReset={this.handleReset}
        />
        <Divider />
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" icon="plus-square-o" onClick={this.addGroup}>
            Add
          </Button>
          <Divider type="vertical" />
          <Popconfirm title="Are you sure?" onConfirm={this.batchDelGroup}>
            <Button type="danger" disabled={!hasSelected} icon="delete">
              Delete
            </Button>
          </Popconfirm>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.tablePagedList}
          pagination={this.state.tablePagination}
          loading={this.state.tableLoading}
          onChange={this.handleTableChange}
          scroll={{ x: 768 }}
          bordered
        />
        <EditGroupModal
          visible={this.state.editModalVisible}
          title={this.editFormData.id ? 'Edit Group' : 'Add Group'}
          onCancel={this.editModalOnCancel}
          destroyOnClose
          schema={schema.editSchema}
          uiSchema={schema.editUiSchema}
          formData={this.editFormData}
          handFormSubmit={this.saveGroup}
        />
        <EditGroupActionsModal
          visible={this.state.editActionsModalVisible}
          title={
            <span>
              Actions of group&nbsp;&nbsp;<Tag color="#2db7f5">{this.editFormData.name}</Tag>&nbsp;
            </span>
          }
          onCancel={this.editModalOnCancel}
          formData={this.editFormData}
          handFormSubmit={this.saveGroupActions}
        />
        <EditGroupMenusModal
          visible={this.state.editMenusModalVisible}
          title={
            <span>
              Menus of group&nbsp;&nbsp;<Tag color="#2db7f5">{this.editFormData.name}</Tag>&nbsp;
            </span>
          }
          onCancel={this.editModalOnCancel}
          formData={this.editFormData}
          handFormSubmit={this.saveGroupMenus}
        />
        <Modal
          visible={this.state.editAdminModalVisible}
          width={1000}
          title={
            <span>
              Admins of group&nbsp;&nbsp;<Tag color="#2db7f5">{this.editFormData.name}</Tag>&nbsp;
            </span>
          }
          onCancel={this.editModalOnCancel}
          footer={[]}
          destroyOnClose
        >
          <EditGroupUserModalContent formData={this.editFormData} />
        </Modal>
      </div>
    );
  }
}

export default Group;
