import apolloClient from 'helpers/apolloClient';
import { cardProductListQuery } from './queries';
import { cardProductAddMutation, cardProductUpdateMutation } from './mutations';

export const FETCH_CARD_PRODUCT = 'cardProduct/FETCH';
export const FETCH_CARD_PRODUCT_SUCCESS = 'cardProduct/FETCH_SUCCESS';
export const FETCH_CARD_PRODUCT_FAILURE = 'cardProduct/FETCH_FAILURE';

export const ADD_CARD_PRODUCT = 'cardProduct/ADD';
export const ADD_CARD_PRODUCT_SUCCESS = 'cardProduct/ADD_SUCCESS';
export const ADD_CARD_PRODUCT_FAILURE = 'cardProduct/ADD_FAILURE';

export const UPDATE_CARD_PRODUCT = 'cardProduct/UPDATE';
export const UPDATE_CARD_PRODUCT_SUCCESS = 'cardProduct/UPDATE_SUCCESS';
export const UPDATE_CARD_PRODUCT_FAILURE = 'cardProduct/UPDATE_FAILURE';

export const SET_FILTER = 'cardProduct/SET_FILTER';
export const RESET_FILTER = 'cardProduct/RESET_FILTER';

export const SET_CURRENT_PAGE = 'cardProduct/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'cardProduct/SET_PAGE_SIZE';

export function getCardProducts(currentPage, pageSize, filter) {
  return {
    types: [FETCH_CARD_PRODUCT, FETCH_CARD_PRODUCT_SUCCESS, FETCH_CARD_PRODUCT_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: cardProductListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.cardProductList;
    },
  };
}

export function addCardProduct(cardProduct) {
  return {
    types: [ADD_CARD_PRODUCT, ADD_CARD_PRODUCT_SUCCESS, ADD_CARD_PRODUCT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: cardProductAddMutation,
        variables: cardProduct,
      });

      return result.data.cardProductAdd;
    },
  };
}

export function updateCardProduct(cardProduct) {
  return {
    types: [UPDATE_CARD_PRODUCT, UPDATE_CARD_PRODUCT_SUCCESS, UPDATE_CARD_PRODUCT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: cardProductUpdateMutation,
        variables: cardProduct,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
