import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { Form, Icon, Input, Button, Alert } from 'antd';
import autobind from 'autobind-decorator';
import store from 'store';
import { REGEX_PASSWORD } from 'constant';
import { sanitizePassword } from 'utils/stringUtil';
import rules from './LoginPasswordUpdateFormValidationRules';
import styles from './LoginPasswordUpdateForm.scss';

const { Item } = Form;

@Form.create()
@autobind
class LoginPasswordUpdateForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    form: objectOf(any).isRequired,
  };

  componentDidMount() {
    this.input.currentPassword.focus();
  }

  input = {
    currentPassword: null,
    newPassword: null,
    newPasswordConfirm: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();
    this.props.form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      if (err.currentPassword) {
        this.input.currentPassword.focus();
      } else if (err.newPassword) {
        this.input.newPassword.focus();
      } else if (err.newPasswordConfirm) {
        this.input.newPasswordConfirm.focus();
      }

      return;
    }

    const { form } = this.props;
    this.props.onSubmit(form.getFieldsValue());
  }

  validatePassword(rule, value, callback) {
    const admin = store.get('admin');
    const { uid } = admin;

    if (value === uid) {
      callback("You can't use ID to password.");
      return;
    }

    // Pattern detects that has letter & digits with special characters at least once.
    if (value && !value.match(REGEX_PASSWORD)) {
      callback('The new password must contain alphabet, numbers and special characters.');
      return;
    }

    callback();
  }

  handlePasswordChange(inputName) {
    const { input } = this.input[inputName];
    const sanitizedPassword = sanitizePassword(input.value);

    input.value = sanitizedPassword;

    // Update ant design input as well
    this.props.form.setFieldsValue({
      [inputName]: sanitizedPassword,
    });
  }

  validatePasswordConfirm(rule, value, callback) {
    const { form } = this.props;

    if (value && form.getFieldValue('newPassword') !== value) {
      callback("Passwords doesn't match.");
    } else {
      callback();
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={styles.passwordUpdateFormContainer}>
        <Form onSubmit={this.handleSubmit} className={styles.passwordUpdateForm}>
          <h1>Update your Password!</h1>
          <p className={styles.infoText}>
            Your Account was blocked for security protection. To proceed, please update your password.
          </p>

          <div className={styles.passwordRules}>
            <Alert message="Password must contain alphabet, numbers and special characters." type="info" showIcon />
          </div>

          <Item>
            {getFieldDecorator('currentPassword', {
              rules: rules.currentPassword,
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Current Password"
                ref={node => (this.input.currentPassword = node)}
                onChange={this.handlePasswordChange.bind(null, 'currentPassword')}
              />
            )}
          </Item>

          <Item>
            {getFieldDecorator('newPassword', {
              rules: [
                ...rules.newPassword,
                {
                  validator: this.validatePassword,
                },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="New Password"
                ref={node => (this.input.newPassword = node)}
                onChange={this.handlePasswordChange.bind(null, 'newPassword')}
              />
            )}
          </Item>

          <Item>
            {getFieldDecorator('newPasswordConfirm', {
              rules: [
                ...rules.newPasswordConfirm,
                {
                  validator: this.validatePasswordConfirm,
                },
              ],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Retype New Password"
                ref={node => (this.input.newPasswordConfirm = node)}
                onChange={this.handlePasswordChange.bind(null, 'newPasswordConfirm')}
              />
            )}
          </Item>

          <Item style={{ textAlign: 'right' }}>
            <Button size="large" type="primary" htmlType="submit" className={styles.submitButton} ghost>
              Update
            </Button>
          </Item>
        </Form>
      </div>
    );
  }
}

export default LoginPasswordUpdateForm;
