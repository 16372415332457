import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import { withRouter } from 'react-router';
import { message, Alert } from 'antd';
import autobind from 'autobind-decorator';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';

import { metroHistoryQuery } from '../UserQueries';
import { columns } from './MetroHistoryListMetadata';

@withRouter
@autobind
class MetroHistoryList extends PureComponent {
  static propTypes = {
    userId: string.isRequired,
  };

  state = {
    data: [],
    error: null,
    loading: false,
    page: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.getData(page, pageSize);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   * @param {Number} page - Page to load
   * @param {Number} pageSize - Page size
   */
  async getData(page, pageSize) {
    try {
      this.setState({ loading: true, error: null });

      const { page: oldPage } = this.state;
      const { userId } = this.props;
      const queryOptions = {
        query: metroHistoryQuery,
        variables: {
          id: userId.replace('CS-', ''),
          skip: (page - 1) * pageSize,
          take: pageSize,
        },
      };

      this.query = cancelableQuery(queryOptions);

      const result = await this.query;
      const data = result.data.getMetroHistory.history;

      this.setState({
        loading: false,
        data: [...this.state.data, ...data],
        page: data.length === 0 ? oldPage : page, // Don't update page if there's no data
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get MetroHistory List');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  getNextPage() {
    const { page, pageSize } = this.state;
    this.getData(page + 1, pageSize);
  }

  render() {
    const { data, error, loading } = this.state;

    return (
      <div>
        {error && (
          <Alert
            description="Failed to load MetroHistory Data."
            type="warning"
            showIcon
            closable
            style={{
              marginBottom: 10,
            }}
          />
        )}

        <InfiniteScrollableTable
          id="user-metro-card-list-table"
          columns={columns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </div>
    );
  }
}

export default MetroHistoryList;
