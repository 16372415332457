const SET_MENU_COLLAPSED = 'global/SET_MENU_COLLAPSED';
const SPIN_LOADING = 'global/SPIN_LOADING';
const UPDATE_TOPATH = 'global/UPDATE_TOPATH';
const UPDATE_ACCESSMENU = 'global/UPDATE_ACCESSMENU';
const UPDATE_MODULE = 'global/UPDATE_MODULE';
const UPDATE_MENU_MAP_TO_ROUTER = 'global/UPDATE_MENU_MAP_TO_ROUTER';
const SET_IS_MOBILE = 'global/SET_IS_MOBILE';

const initialState = {
  collapsed: false,
  spinLoading: false,
  toPath: '/',
  accessMenu: [],
  openAccessMenu: [],
  menuMapToRouter: {},
  isMobile: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MENU_COLLAPSED:
      return {
        ...state,
        collapsed: action.collapsed,
      };

    case SPIN_LOADING:
      return { ...state, spinLoading: action.spinLoading };
    case UPDATE_TOPATH:
      return { ...state, toPath: action.toPath };
    case UPDATE_ACCESSMENU:
      return {
        ...state,
        accessMenu: action.accessMenu,
        openAccessMenu: action.openAccessMenu,
      };
    case UPDATE_MODULE:
      return {
        ...state,
      };

    case UPDATE_MENU_MAP_TO_ROUTER:
      return {
        ...state,
        menuMapToRouter: JSON.parse(action.data),
      };
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
}

export function setMenuCollapsed(collapsed) {
  return { type: SET_MENU_COLLAPSED, collapsed };
}

export const spinLoading = loading => ({ type: SPIN_LOADING, spinLoading: loading });

export const updateToPath = toPath => ({ type: UPDATE_TOPATH, toPath });

export const updateAccessMenu = data => ({ type: UPDATE_ACCESSMENU, ...data });

export const updateModule = module => ({ type: UPDATE_MODULE, ...module });

export const updateMenuMapToRouter = data => ({ type: UPDATE_MENU_MAP_TO_ROUTER, data });

export const setIsMobile = isMobile => ({ type: SET_IS_MOBILE, payload: isMobile });
