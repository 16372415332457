import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';
import styles from './AgreementForm.scss';

const itemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AgreementFormItem = ({ children, ...props }) => (
  <Form.Item {...props} {...itemLayout} className={props.className || styles.formControl} labelCol={{ span: 4 }}>
    {children}
  </Form.Item>
);

AgreementFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

AgreementFormItem.defaultProps = {
  className: null,
};

export default AgreementFormItem;
