import gql from 'graphql-tag';

export const settlementListQuery = gql`
  query settlementList($filter: String) {
    settlementList(filter: $filter) {
      id
      status
      settlementDate
      referenceDate
      plusAmount
      minusAmount
      feeAmount
      feeTaxAmount
      transactionCount
      refundCount
      merchantId
    }
  }
`;

export const dummySettlementListQuery = gql`
  query dummySettlementList {
    dummySettlementList {
      id
      createdAt
      plusAmount
      minusAmount
      feeAmount
      feeTaxAmount
      status
      settlementDate
      referenceDate
      history
      merchant {
        id
        name
        displayName
      }
      payments {
        id
      }
    }
  }
`;

export default {
  settlementListQuery,
  dummySettlementListQuery,
};
