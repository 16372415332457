import React, { Fragment } from 'react';
import { Badge, Checkbox } from 'antd';
import moment from 'moment';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';

const getStatus = status =>
  ({
    enabled: 'success',
    disabled: 'default',
  }[status]);

const getColumns = (onCopyCheck, copyTargets) => [
  {
    title: 'Copy',
    dataIndex: 'id',
    width: 80,
    render: function renderTitle(id, row) {
      const checked = !!copyTargets.find(item => item.id === id);
      return <Checkbox onChange={() => onCopyCheck(row)} defaultChecked={checked} />;
    },
  },
  {
    title: 'Name',
    dataIndex: 'title',
    render: function renderTitle(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '20%',
  },
  {
    title: 'Period',
    align: 'left',
    width: 250,
    render(val, fullValue) {
      const color = moment(Date.now()).isBetween(fullValue.startAt, fullValue.endAt) ? 'black' : 'red';
      return (
        <span style={{ color }}>
          {formatDate(fullValue.startAt, 'YYYY-MM-DD')} ~ {formatDate(fullValue.endAt, 'YYYY-MM-DD')}
        </span>
      );
    },
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    align: 'right',
    render(val) {
      return <span>{val && val.indexOf('%') > -1 ? val : `${commify(val)} 원`}</span>;
    },
  },
  {
    title: 'Benefit Type',
    dataIndex: 'benefitType',
    render(val) {
      return <span>{startCase(val)}</span>;
    },
  },
  {
    title: 'Issue Type',
    dataIndex: 'issueType',
    render(val) {
      return <span>{startCase(val)}</span>;
    },
  },
  {
    title: 'Used',
    dataIndex: 'used',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render(val) {
      return (
        <Fragment>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </Fragment>
      );
    },
  },
];

export default {
  getColumns,
};
