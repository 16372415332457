import React, { Component } from 'react';
import { func, any, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { addNotice, updateNotice, removeNotice } from 'redux/modules/notice/actions';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import NoticeList from './List/NoticeList';
import NoticeForm from './Form/NoticeForm';
import { NoticeMode } from './NoticeConstants';
import styles from './Notice.scss';

const mapDispatchToProps = {
  push,
  addNotice,
  updateNotice,
  removeNotice,
};

@connect(null, mapDispatchToProps)
@autobind
class NoticeContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    addNotice: func.isRequired,
    updateNotice: func.isRequired,
    removeNotice: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      mode: null,
      id: null,
    };

    this.setChildComponent();
  }

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/operation/notice') {
        this.setState({ showList: true });
      } else {
        const match = pathname.match(/\/operation\/notice\/(.+)/);

        if (match) {
          const id = match[1];

          if (id === 'add') {
            this.createNew();
          } else {
            this.open({ id });
          }
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/operation/notice') {
        this.setState({ showList: true });
      }
    }
  }

  setChildComponent() {
    const { pathname } = this.props.location;

    const match = pathname.match(/\/operation\/notice\/(.+)/);

    if (match) {
      const id = match[1];

      // ESLint doesn't allow changing state but this function invokes from constructor, so it's fine.
      /* eslint-disable */
      this.state = {
        ...this.state,
        showList: false,
        mode: id === 'add' ? NoticeMode.ADD : NoticeMode.UPDATE,
        id,
      };
      /* eslint-enable */
    } else {
      /* eslint-disable */
      this.state = {
        showList: true,
      };
      /* eslint-enable */
    }
  }

  createNew() {
    this.setState({
      showList: false,
      mode: NoticeMode.ADD,
      id: null,
    });
  }

  createNewWithPush() {
    this.createNew();
    this.props.push('/operation/notice/add');
  }

  open(notice) {
    this.setState({
      showList: false,
      mode: NoticeMode.UPDATE,
      id: notice.id,
    });
  }

  openWithPush(notice) {
    this.open(notice);
    this.props.push(`/operation/notice/${notice.id}`);
  }

  switchToList() {
    this.setState({
      showList: true,
    });

    this.props.push('/operation/notice');
  }

  async handleSubmit(form) {
    try {
      const { mode } = this.state;

      if (mode === NoticeMode.ADD) {
        await this.props.addNotice(form);
      } else {
        await this.props.updateNotice(form);
      }

      this.setState({
        showList: true,
      });

      this.props.push('/operation/notice');
    } catch (err) {
      message.error(`Failed to save notice: ${err.message}`);
      console.log(err);
    }
  }

  async handleRemoveNotice(id) {
    try {
      await this.props.removeNotice(id);
      message.success('Removed.');

      this.setState({ showList: true });
      this.switchToList();
    } catch (err) {
      message.error(`Failed to remove notice: ${err.message}`);
      console.log(err);
    }
  }

  render() {
    const { showList, mode, id } = this.state;

    return (
      <div className={styles.noticeContainer}>
        {showList && <NoticeList onCreate={this.createNewWithPush} onOpen={this.openWithPush} />}

        {!showList && (
          <NoticeForm
            onSubmit={this.handleSubmit}
            onCancel={this.switchToList}
            onRemove={this.handleRemoveNotice}
            mode={mode}
            id={id}
          />
        )}
      </div>
    );
  }
}

export default NoticeContainer;
