const rules = {
  policyId: [
    {
      required: true,
      message: 'Please enter Policy Id.',
    },
  ],
};

export default rules;
