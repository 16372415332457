import { Alert, Button, Col, Divider, Form, Icon, Input, InputNumber, message, notification } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { addDays, isAfter, startOfDay } from 'date-fns';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addBoostPromotion, getBoostPromotionList, updateBoostPromotion } from 'redux/modules/boostPromotion/actions';
import { boostPromotionQuery } from 'redux/modules/boostPromotion/queries';
import { addTab, focusTab, removeAndFocusPreviousTab, removeTab, updateTab } from 'redux/modules/tabs';
import { isBetween, isInteger, required } from 'utils/formValidator';
import { isAdmin } from 'utils/permission';
import { TagPromotionType } from '../../Tag';
import AddTagModal from '../../Tag/Modal/AddTagModal';
import TagModal from '../../Tag/Modal/TagModal';
import {
  BoostArrayForm,
  BoostBrandInputForm,
  BoostDividerForm,
  BoostMerchantForm,
  BoostNumberForm,
  BoostPeriodForm,
  BoostRangePromotionForm,
  BoostSelectForm,
  BoostTextForm,
  BoostTwoNumbersForm,
} from '../Forms';
import { formItemLayout, formItemLayoutWithoutLabel } from '../Forms/layout';
import styles from './BoostPromotion.scss';
import BoostPromotionCategoryForm from './BoostPromotionCategoryForm';
import BoostPromotionMerchantCombinationForm from './BoostPromotionMerchantCombinationForm';
import BoostPromotionMerchantForm from './BoostPromotionMerchantForm';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boostPromotion;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  getBoostPromotionList,
  addBoostPromotion,
  updateBoostPromotion,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoostPromotionForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    getBoostPromotionList: PropTypes.func.isRequired,
    addBoostPromotion: PropTypes.func.isRequired,
    updateBoostPromotion: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    // BoostPromotion XHR Status
    data: null,
    error: null,
    loading: false,
    displayTagsModal: false,
    displayAddTagModal: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boostPromotion', id ? id.toString() : 'add');
    this.props.push('/boost/policy');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id } = this.props;

    this.setState({
      loading: true,
      error: null,
    });

    try {
      this.query = cancelableQuery({
        query: boostPromotionQuery,
        variables: {
          id: +id,
        },
      });

      const result = await this.query;
      const data = result.data.boostPromotion;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Boost ID');
      } else {
        this.setState({
          loading: false,
          data,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Boost Policy: ${error.message}`);
      this.setState({
        error,
        loading: false,
      });
      throw error;
    }
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = [
        'type',
        'title',
        'subTitle',
        'description',
        'important',
        'usableLocationDescription',
        'boltPrice',
        'visibleStartAt',
        'visibleEndAt',
        'usableStartAt',
        'usableEndAt',
        'discount',
        'priceMin',
        'priceMax',
        'maxDiscountAmount',
        'targetMerchantIds',
        'brandId',
        'amountCap',
        'countCap',
        'landingUrl',
        'fallbackUrl',
        'iosAppId',
        'androidPackage',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];
        const instance = form.getFieldInstance(field);
        if (err[field]) {
          if (instance && typeof instance.input !== 'undefined') {
            instance.input.focus();
          } else if (instance && typeof instance.picker !== 'undefined') {
            instance.picker.input.focus();
          } else if (instance && typeof instance.inputNumberRef !== 'undefined') {
            instance.inputNumberRef.input.focus();
          }
          return;
        }
      }
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.type = formFields.type;
    fields.title = formFields.title;
    fields.subTitle = formFields.subTitle;
    fields.description = formFields.description;
    fields.important = formFields.important;
    fields.usableLocationDescription = formFields.usableLocationDescription;
    fields.benefitDescription = formFields.benefitDescription;
    fields.tutorialType = formFields.tutorialType;
    fields.buyableFrom = formFields.buyableStartAt;
    fields.buyableTo = formFields.buyableEndAt;
    fields.usableFrom = formFields.usableStartAt;
    fields.usableTo = formFields.usableEndAt;
    fields.visibleFrom = formFields.visibleStartAt;
    fields.visibleTo = formFields.visibleEndAt;
    fields.boltPrice = formFields.boltPrice;
    fields.amountCap = formFields.amountCap;
    fields.countCap = formFields.countCap;
    fields.landingUrl = formFields.landingUrl.trim();
    fields.fallbackUrl = formFields.fallbackUrl.trim();
    fields.iosAppId = formFields.iosAppId.trim();
    fields.androidPackage = formFields.androidPackage.trim();
    fields.discount = formFields.discount;
    fields.maxDiscountAmount = formFields.maxDiscountAmount;
    fields.priceMin = formFields.priceMin;
    fields.priceMax = formFields.priceMax;
    fields.countCapPerUser = formFields.countCapPerUser;
    fields.boostMerchantIds = formFields.merchantIds;
    fields.targetMerchantIds = formFields.targetMerchantIds.filter(item => typeof item === 'string');
    // 머천트 조합 사용 시
    if (formFields.merchantCombinations?.length > 0) {
      fields.targetMerchantIds = fields.targetMerchantIds.concat(formFields.merchantCombinations);
    }

    // 1depth 머천트 ID들을 2depth로 통일 ex) [a,b,[c,d]] -> [[a],[b],[c,d]]
    fields.targetMerchantIds = fields.targetMerchantIds.map(item => {
      if (typeof item === 'string') {
        return [item];
      }
      return item;
    });

    if (!fields.targetMerchantIds?.length) {
      notification.error({
        placement: 'bottomLeft bottomRight',
        message: 'Merchant must be selected.',
      });
      return;
    }

    fields.status = formFields.status;

    fields.brandId = formFields.brandId;
    fields.taglines = formFields.taglines.map(tag => tag.val);
    if (!fields.taglines.length) {
      message.warning('Please enter the TagLines.');
      return;
    }

    fields.promotionType = formFields.promotionType;
    if (fields.promotionType === 'range') {
      fields.discountAmountMax = formFields.promotionRangeMax;
      fields.discountAmountMin = formFields.promotionRangeMin;
    }

    fields.subPromotions = formFields.subPromotions;
    fields.boostCategoryIds = formFields.boostCategoryIds;
    fields.benefitType = 'cashback';

    if (fields.id === 'add') {
      await this.add(fields);
      return;
    }

    await this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoostPromotion(input);

    // this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('boostPromotion', 'add');
    this.props.push('/boost/policy');
    this.refreshBoostPromotionList();
  }

  async update(input) {
    await this.props.updateBoostPromotion(input);

    // this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('boostPromotion', input.id.toString());
    this.props.push('/boost/policy');
    this.refreshBoostPromotionList();
  }

  refreshBoostPromotionList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoostPromotionList(currentPage, pageSize, filter);
  }

  showTagsModal() {
    this.setState({
      displayTagsModal: true,
    });
  }

  showAddTagModal() {
    this.setState({
      displayAddTagModal: true,
    });
  }

  hideTagsModal() {
    this.setState({
      displayTagsModal: false,
    });
  }

  hideAddTagModal() {
    this.setState({
      displayAddTagModal: false,
    });
  }

  renderTagsModal() {
    const { id } = this.props;
    const { displayTagsModal } = this.state;

    return (
      <TagModal
        id={id.toString()}
        type={TagPromotionType.BOOST}
        visible={displayTagsModal}
        onCloseModal={this.hideTagsModal}
      />
    );
  }

  renderAddTagModal() {
    const { id } = this.props;
    const { displayAddTagModal } = this.state;

    return (
      <AddTagModal
        id={id.toString()}
        type={TagPromotionType.BOOST}
        visible={displayAddTagModal}
        refreshBoostPromotion={this.getData}
        onCloseModal={this.hideAddTagModal}
      />
    );
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />
        <Divider />
        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  getCashbackAmountForm(type, promotionType) {
    const { form } = this.props;
    const data = this.state.data || {};
    if (type === 'recurring') {
      return null;
    }

    if (promotionType === 'fixed' || !promotionType) {
      return (
        <BoostTextForm
          form={form}
          label="Cashback Amount"
          name="discount"
          initial={data.promotion ? data.promotion.discount : ''}
          require
        />
      );
    }

    if (promotionType === 'range') {
      return (
        <BoostRangePromotionForm
          form={form}
          label="Cashback Amount Range"
          name="promotionRange"
          initial={
            data.discountAmountMax && data.discountAmountMin ? [data.discountAmountMax, data.discountAmountMin] : []
          }
          placeholder={['DiscountStart', 'DiscountEnd']}
          require
          disabled={!!data.discountAmountMax}
        />
      );
    }
    return null;
  }

  addSubPromotion() {
    const data = this.state.data || {};
    data.subPromotions = [...(data.subPromotions || []), { count: 0, period: 0 }];

    this.setState({ data });
  }

  removeSubPromotion(index) {
    const data = this.state.data || {};
    if (!data.subPromotions || data.subPromotions.length <= index) {
      return;
    }
    data.subPromotions.splice(index, 1);
    if (data.subPromotions.length < 1) {
      delete data.subPromotions;
    }
    this.setState({ data });
  }

  renderCashbackSettings() {
    const { form } = this.props;
    const data = this.state.data || {};
    const type = form.getFieldValue('type') || data?.type;
    const { getFieldDecorator } = this.props.form;
    const promotionType = form.getFieldValue('promotionType') || data?.promotionType;
    const subPromotions = data.subPromotions || [];
    const isUpdate = !!data.id;

    const promotionTypeInitial =
      type === 'recurring' ? data.promotionType || 'sequence' : data.promotionType || 'fixed';
    const promotionTypeItems =
      type === 'recurring'
        ? [
          {
            label: '회차별',
            value: 'sequence',
          },
        ]
        : [
          {
            label: 'Range',
            value: 'range',
          },
          {
            label: 'Fixed',
            value: 'fixed',
          },
        ];

    const cashbackTypeSelectForm = (
      <BoostSelectForm
        form={form}
        label="Cashback Type"
        name="promotionType"
        initial={promotionTypeInitial}
        disabled={!!data.promotionType}
        items={promotionTypeItems}
      />
    );
    const cashbackAmountForm = this.getCashbackAmountForm(type, promotionType);
    const subPromotionForm = (
      <Form.Item
        label={subPromotions.length === 0 && 'Sub Promotion'}
        {...(subPromotions.length === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        required
      >
        {!isUpdate && (
          <Button type="dashed" onClick={this.addSubPromotion} style={{ width: '100%' }}>
            <Icon type="plus" /> Add condition
          </Button>
        )}
      </Form.Item>
    );

    const subPromotionListForm = subPromotions.map((condition, index) => (
      <Form.Item
        key={`condition-${index}`} // eslint-disable-line
        label={index === 0 && 'Sub Promotion'}
        {...(index === 0 ? formItemLayout : formItemLayoutWithoutLabel)}
        required
      >
        <Form.Item hidden>
          {getFieldDecorator(`subPromotions[${index}].promotionId`, {
            initialValue: subPromotions[index].promotionId,
          })(<Input />)}
        </Form.Item>
        <Form.Item hidden>
          {getFieldDecorator(`subPromotions[${index}].sequence`, {
            initialValue: subPromotions[index].sequence || index + 1,
          })(<Input />)}
        </Form.Item>
        <Col span={5}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
            {' '}
            {`Cashback${index + 1}`}{' '}
          </span>
        </Col>
        <Col span={4}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`subPromotions[${index}].discount`, {
              initialValue: subPromotions[index].discount,
              rules: [required()],
            })(<Input />)}
          </Form.Item>
        </Col>
        <Col span={2}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}> Max </span>
        </Col>
        <Col span={4}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`subPromotions[${index}].maxDiscountAmount`, {
              initialValue: subPromotions[index].maxDiscountAmount,
              rules: [required(), isInteger(), isBetween(1, 10000000)],
            })(<InputNumber min={0} max={10000000} />)}
          </Form.Item>
        </Col>
        <Col span={4}>
          <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}> BoltPrice </span>
        </Col>
        <Col span={3}>
          <Form.Item style={{ marginBottom: 0 }}>
            {getFieldDecorator(`subPromotions[${index}].boltPrice`, {
              initialValue: subPromotions[index].boltPrice,
              rules: [required(), isInteger(), isBetween(0, 10000000)],
            })(<InputNumber min={0} max={10000000} />)}
          </Form.Item>
        </Col>
        {!isUpdate && (
          <Col span={1} offset={1}>
            <Icon
              className="dynamic-delete-button"
              type="minus-circle-o"
              onClick={this.removeSubPromotion.bind(this, index)}
            />
          </Col>
        )}
      </Form.Item>
    ));

    return (
      <Fragment>
        <BoostDividerForm label="Cashback Settings" />
        {cashbackTypeSelectForm}
        {cashbackAmountForm}

        <BoostTwoNumbersForm
          form={form}
          label="Target Checkout Amount"
          name="price"
          initial={data.promotion ? [data.promotion.priceMin, data.promotion.priceMax] : []}
          placeholder={['Min', 'Max']}
          require
        />
        {type !== 'recurring' && (
          <BoostNumberForm
            form={form}
            label="Max Cashback Amount"
            name="maxDiscountAmount"
            initial={data.promotion ? data.promotion.maxDiscountAmount : null}
            require
          />
        )}
        {subPromotionListForm}
        {type === 'recurring' && subPromotionForm}
      </Fragment>
    );
  }

  render() {
    const { updating, form } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id, taglines } = data;

    const taglinesArray = taglines ? taglines.map(item => ({ val: item })) : [];
    // 1depth 머천트 ID 복구
    const targetMerchantIds = data.targetMerchantIds
      ? data.targetMerchantIds.filter(Boolean).map(item => {
        // *구버전 1depth 아이템 대응
        if (typeof item !== 'object') {
          return item;
        }
        if (item.length === 1) {
          return item[0];
        }
        return item;
      })
      : [];

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    const makeFormProps = (name, initial) => ({
      form,
      name,
      readOnly: !isAdmin(),
      initial: data[name] ?? initial,
    });

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
        {data.promotionId && (
          <Link to={`/promotion/policy/${data?.promotionId}`}>
            <BoostTextForm
              {...makeFormProps('promotionId')}
              label="Promotion Policy Id"
              initial={`${data.promotionId} (Click to move)`}
              disabled
            />
          </Link>
        )}
        <BoostSelectForm
          {...makeFormProps('type', 'standard')}
          label="Type"
          items={[
            {
              label: 'Standard',
              value: 'standard',
            },
            {
              label: 'FCFS',
              value: 'fcfs',
            },
            {
              label: 'TimeAttack',
              value: 'time_attack',
            },
            {
              label: '정기결제',
              value: 'recurring',
            },
          ]}
        />
        <BoostTextForm {...makeFormProps('subTitle')} label="Boost Policy SubTitle" require />
        <BoostTextForm {...makeFormProps('title')} label="Boost Policy Title" require />
        <BoostTextForm {...makeFormProps('description')} label="Description" multiline require />
        <BoostTextForm {...makeFormProps('important')} label="Important" multiline require />
        <BoostTextForm {...makeFormProps('usableLocationDescription')} label="Usable Location Description" require />
        <BoostTextForm {...makeFormProps('benefitDescription')} label="Benefit Description" />
        <BoostNumberForm {...makeFormProps('boltPrice')} label="Boost Price (Bolt cost)" require />
        <BoostSelectForm
          {...makeFormProps('tutorialType', 'none')}
          label="Tutorial Type"
          items={[
            {
              label: 'None',
              value: 'none',
            },
            {
              label: 'Offline',
              value: 'offline',
            },
          ]}
        />
        <BoostSelectForm
          {...makeFormProps('status', 'enabled')}
          label="Policy Status"
          items={[
            {
              label: 'Enabled',
              value: 'enabled',
            },
            {
              label: 'Disabled',
              value: 'disabled',
            },
          ]}
        />
        {/* Tag */}
        <Form.Item label="Tags" {...formItemLayout}>
          <Button className={styles.tagButton} icon="ordered-list" onClick={this.showTagsModal}>
            List
          </Button>
          {/* 신규 부스트이거나 어드민이 아닌 경우엔 태그추가 불가 */}
          {this.props.id !== 'add' && isAdmin() && (
            <Button className={styles.tagButton} icon="plus" onClick={this.showAddTagModal}>
              Add
            </Button>
          )}
        </Form.Item>

        <BoostDividerForm label="Periods" />
        <BoostPeriodForm
          {...makeFormProps('visible')}
          label="Visible"
          initial={[data.visibleFrom, data.visibleTo]}
          additionalDisable={current => isAfter(current, addDays(startOfDay(new Date()), 49))}
          require
        />
        <BoostPeriodForm
          {...makeFormProps('buyable')}
          label="Buyable"
          initial={[data.buyableFrom, data.buyableTo]}
          require
        />
        <BoostPeriodForm
          {...makeFormProps('usable')}
          label="Usable"
          initial={[data.usableFrom, data.usableTo]}
          require
        />
        {this.renderCashbackSettings()}
        {/* 어드민이 아닌 경우 머천트이하 내용을 숨긴다 */}
        {isAdmin() && (
          <>
            <BoostDividerForm label="Card Merchant" />
            <BoostPromotionMerchantForm form={form} name="targetMerchantIds" initial={targetMerchantIds} />

            <BoostDividerForm label="Card Merchant Combinations" />
            <BoostPromotionMerchantCombinationForm
              baseForm={form}
              name="merchantCombinations"
              initial={targetMerchantIds}
            />

            {/* 간편결제 Merchant 맵핑하기 */}
            <BoostDividerForm label="간편결제 Merchant" />
            <BoostMerchantForm
              form={form}
              name="boostMerchantIds"
              initial={data.boostMerchants ? data.boostMerchants.map(m => m.merchantId) : []}
            />

            <BoostDividerForm label="Brand" />
            <BoostBrandInputForm form={form} label="Brand Id" name="brandId" initial={data.brandId} require />

            <BoostDividerForm label="TagLine" />
            <BoostArrayForm
              {...makeFormProps('taglines')}
              initial={taglinesArray}
              validator={input => input.split(/\r\n|\r|\n/).length > 2}
              validatorMessage="Please Input Within 2 Lines"
              adder={[
                {
                  key: 'val',
                  type: 'multiline',
                  title: 'TagLines',
                  label: '',
                },
              ]}
            />

            <BoostDividerForm label="Category" />
            <BoostPromotionCategoryForm
              form={form}
              name="boostCategoryIds"
              initial={data.boostCategories ? data.boostCategories.map(boostCategory => boostCategory.id) : []}
            />

            <BoostDividerForm label="Cap" />
            <BoostNumberForm {...makeFormProps('amountCap')} label="Amount Cap" />
            <BoostNumberForm
              {...makeFormProps('amountCapCurrent')}
              label="Boost cashback used"
              initial={data.usedBoostAmountSum}
              disabled
            />
            <BoostNumberForm {...makeFormProps('countCap')} label="Count Cap" />
            <BoostNumberForm {...makeFormProps('countCapPerUser')} label="Count Cap Per User" />

            <BoostDividerForm label="App Landing" />
            <BoostTextForm {...makeFormProps('landingUrl')} label="Landing Url" />
            <BoostTextForm {...makeFormProps('fallbackUrl')} label="Fallback Url" />
            <BoostTextForm {...makeFormProps('iosAppId')} label="iOS App Id" />
            <BoostTextForm {...makeFormProps('androidPackage')} label="Android Package" />

            <div className={styles.formButtonContainer}>
              <Button type="ghost" onClick={this.closeTab}>
                Cancel
              </Button>
              <Divider
                type="vertical"
                style={{
                  background: '#fff',
                }}
              />
              <Button type="primary" htmlType="submit" disabled={updating}>
                {id ? 'Save' : 'Create'}
              </Button>
            </div>
          </>
        )}
        {this.renderTagsModal()}
        {this.renderAddTagModal()}
      </Form>
    );
  }
}

export default BoostPromotionForm;
