import gql from 'graphql-tag';

export const userQuery = gql`
  query maskedUser($id: ID!) {
    maskedUser(id: $id) {
      id
      status
      fullname
      phone
      birthday
      createdAt
      updatedAt
      deletedAt
      gender
      autoCharge
      ledger {
        id
        point
        cash
      }
      requiredActions {
        blocked
      }
      koreaSpec {
        id
        pgAccounts
        pgPrimaryAccount
      }
      monthlyCashback {
        monthlyCashbackAmount
        date
      }
      invitationCount
      isVictim
    }
  }
`;

export const unMaskedUserQuery = gql`
  query unMaskedUser($id: ID!) {
    unMaskedUser(id: $id) {
      id
      status
      fullname
      phone
      birthday
      createdAt
      updatedAt
      deletedAt
      gender
      ledger {
        id
        point
        cash
      }
      requiredActions {
        blocked
      }
      koreaSpec {
        id
        pgAccounts
        pgPrimaryAccount
      }
      monthlyCashback {
        monthlyCashbackAmount
        date
      }
      invitationCount
      isVictim
    }
  }
`;

export const userHistoryQuery = gql`
  query userHistory($id: ID!) {
    userHistory(id: $id) {
      id
      fullname
      phone
      createdAt
      deletedAt
    }
  }
`;

export const paymentListQuery = gql`
  query paymentList($filter: String, $skip: Int, $pageSize: Int) {
    paymentList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        status
        idempotencyKey
        currency
        checkoutAmount
        billingAmount
        cashbackAmount
        description
        returnUrl
        createdAt
        updatedAt
        merchant {
          id
          name
          displayName
        }
      }
      total
    }
  }
`;

export const topupListQuery = gql`
  query userTopupList($filter: String, $skip: Int, $pageSize: Int) {
    userTopupList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        type
        createdAt
        currency
        amount
        data
        status
      }
      total
    }
  }
`;

export const delayedCashbackListQuery = gql`
  query userDelayedCashbackList($filter: String, $skip: Int, $pageSize: Int) {
    userDelayedCashbackList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        status
        actionType
        targetDate
        billingDelta
        cashbackDelta
        isPublicTransportation
        boostId
        paymentId
        promotionId
        payment {
          description
        }
      }
      total
    }
  }
`;

export const contactInvitationListQuery = gql`
  query userContactInvitationList($filter: String, $skip: Int, $pageSize: Int) {
    userContactInvitationList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        type
        createdAt
        inviteePhone
        inviteeUserId
      }
      total
    }
  }
`;

export const transactionListQuery = gql`
  query userTransactionList($filter: String, $skip: Int, $pageSize: Int) {
    userTransactionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        type
        currency
        amount
        delta
        balance
        data {
          ... on ChargeInData {
            paymentId
            pgResult
          }
          ... on PaymentOutData {
            paymentId
          }
        }
      }
      total
    }
  }
`;

export const boltHistoryListQuery = gql`
  query boltHistoryList($filter: String, $skip: Int, $pageSize: Int) {
    boltHistoryList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        action
        title
        subTitle
        count
        datetime
        availableBolt
      }
      total
    }
  }
`;

export const boostListQuery = gql`
  query boostList($filter: String, $skip: Int, $pageSize: Int) {
    boostList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        usableFrom
        usableTo
        status
        boostPromotionId
        boostCampaignId
      }
      total
    }
  }
`;

export const boostUpUserHistoryQuery = gql`
  query boostUpUserHistory($filter: String!, $skip: Int, $pageSize: Int) {
    boostUpUserHistory(filter: $filter, skip: $skip, pageSize: $pageSize) {
      total
      userId
      list {
        totalCashbackAmount
        totalDiscountRate
        id
        resultType
        level
        boostUpMoment
        boltPrice
        cashbackAmount
        discountRate
        status
        campaignId
        boostId
        boostUpPolicyId
        createdAt
        updatedAt
      }
    }
  }
`;

export const getPGAccountsQuery = gql`
  query getPGAccounts($userId: ID!, $sequenceId: ID!) {
    getPGAccounts(userId: $userId, sequenceId: $sequenceId) {
      code
      message
      accounts {
        bankCode
        accountNumber
        createdAt
        isRepresentative
      }
    }
  }
`;

export const metroCardListQuery = gql`
  query metroCardList($id: ID!, $skip: Int, $take: Int) {
    getMetroCardList(id: $id, skip: $skip, take: $take) {
      cardList {
        id
        balance
        depositAmount
        status
        batchStatus
        activatedAt
        expectedAmount
      }
    }
  }
`;

export const metroHistoryQuery = gql`
  query metroHistory($id: ID!, $skip: Int, $take: Int) {
    getMetroHistory(id: $id, skip: $skip, take: $take) {
      history {
        id
        metroCardId
        amount
        balance
        type
        data {
          description
          useType
          isTransfer
          busNumber
          busStopName
          person
        }
        usedAt
        createdAt
      }
    }
  }
`;

export const voucherBalanceQuery = gql`
  query getVoucherBalance($id: ID!) {
    getVoucherBalance(id: $id) {
      code
      message
      amount
    }
  }
`;

export default {
  userQuery,
  unMaskedUserQuery,
  userHistoryQuery,
  paymentListQuery,
  topupListQuery,
  transactionListQuery,
  getPGAccountsQuery,
  metroCardListQuery,
  metroHistoryQuery,
  voucherBalanceQuery,
};
