export const contractRequire = [
  'contractType',
  'contractTermStartAt',
  'contractTermEndAt',
  'contractSettlementCycle',
  'contractSettlementProvideCycle',
  'contractAutoExtension',
  'contractFee',
  'contractFeeType',
  'contractIncludedVat',
];

export const adminAccountRequire = ['adminId', 'adminName', 'adminPhone', 'adminEmail', 'adminIP'];

export const merchantRequire = [
  'name',
  'displayName',
  'businessNumber',
  'address',
  'representativeName',
  'bankName',
  'bankAccountNum',
  'bankHolder',
  'shortName',
  'status',
  'countryCode',
  'vertical',
  'website',
  'managerName',
  'managerEmail',
  'managerPhone',
];

export const merchantExcelPickProps = [
  'MID',
  'name',
  'displayName',
  'shortName',
  'globalName',
  'businessNumber',
  'address',
  'representativeName',
  'website',
  'bankName',
  'bankAccountNum',
  'bankHolder',
  'managerName',
  'managerPhone',
  'managerEmail',
  'customerServicePhone',
  'settlementEmail',
  'status',
  'contractStatus',
  'countryCode',
  'vertical',
  'reserveAmount',
  'logoUrl',
  'funnel',
  'businessCategory',
  'businessType',
  'pgMerchantCode',
  'linkMethod',
  'restrictedPoint',
  'restrictedCoupon',
  'restrictedCashReceipt',
  'restrictedChaiCashback',
  'restrictedMerchantCashback',
  'restrictedVoicePhishingPayment',
  'isPointRestricted',
  'maxMonthlyCashbackAmount',
  'newComerLimitHour',
  'newComerLimitAmount',
  'dailyLimitAmount',
];
