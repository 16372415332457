import React, { PureComponent } from 'react';
import { func, objectOf, any, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { message, Alert } from 'antd';
import autobind from 'autobind-decorator';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';

import { paymentListQuery } from '../UserQueries';
import { columns } from './UserPaymentListMetadata';

const mapDispatchToProps = {
  addTab,
  focusTab,
  push,
};

@connect(null, mapDispatchToProps)
@withRouter
@autobind
class UserPaymentList extends PureComponent {
  static propTypes = {
    userId: string.isRequired,
    userCIByUserId: bool, // 해당 유저의 CI로 결제 검색하여 리스트 출력
    addTab: func.isRequired,
    focusTab: func.isRequired,
    push: func.isRequired,
    location: objectOf(any).isRequired,
  };

  static defaultProps = {
    userCIByUserId: false,
  };

  state = {
    data: [],
    error: null,
    loading: false,
    page: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.getData(page, pageSize);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   * @param {Number} page - Page to load
   * @param {Number} pageSize - Page size
   */
  async getData(page, pageSize) {
    try {
      this.setState({ loading: true, error: null });

      const { page: oldPage } = this.state;
      const { userId, userCIByUserId } = this.props;
      const queryOptions = {
        query: paymentListQuery,
        variables: {
          filter: JSON.stringify({
            userId: userId.replace('CS-', ''),
            userCIByUserId,
          }),
          skip: (page - 1) * pageSize,
          pageSize,
        },
      };

      this.query = cancelableQuery(queryOptions);

      const result = await this.query;
      const data = result.data.paymentList.list;

      this.setState({
        loading: false,
        data: [...this.state.data, ...data],
        page: data.length === 0 ? oldPage : page, // Don't update page if there's no data
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Payment List');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  /**
   * Inject Cell related callback props into columns
   * This makes your code can seperate handling cell related jobs from own class, not column definition code.
   * @return {Object} connectedColumns
   */
  getConnectedColumns() {
    const connectedColumns = [...columns];

    for (let i = 0; i < connectedColumns.length; i += 1) {
      const column = connectedColumns[i];
      column.onCell = record => ({
        onClick: () => {
          this.handleCellClick(i, record);
        },
      });
    }

    return connectedColumns;
  }

  /**
   * Handle cell click to open tab only in first cell.
   * @param {number} index
   * @param {Object} record
   */
  handleCellClick(index, record) {
    if (index === 0) {
      this.openDataTab(record);
    }
  }

  openDataTab(record) {
    const tabData = new TabData({
      key: record.id.toString(),
      title: `Payment (${record.id.substr(0, 8)}...)`,
      closable: true,
      data: {
        id: record.id,
      },
      componentType: 'PaymentDetail',
    });

    // Payment Tab can be opened in Payment page and User page
    // So check url to decide where to put in this tab
    const { pathname } = this.props.location;
    const tabKey = pathname.split('/')[1];

    this.props.addTab(tabKey, tabData);
    this.props.focusTab(tabKey, record.id.toString());
    this.props.push(`/${tabKey}/${record.id}`);
  }

  getNextPage() {
    const { page, pageSize } = this.state;
    this.getData(page + 1, pageSize);
  }

  render() {
    const connectedColumns = this.getConnectedColumns();
    const { data, error, loading } = this.state;

    return (
      <div>
        {error && (
          <Alert
            description="Failed to load Payment Data."
            type="warning"
            showIcon
            closable
            style={{
              marginBottom: 10,
            }}
          />
        )}

        <InfiniteScrollableTable
          id="user-payment-list-table"
          columns={connectedColumns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </div>
    );
  }
}

export default UserPaymentList;
