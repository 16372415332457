export default {
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Group Name',
      onBlur: e => {
        const { value } = e.target;
        console.log(value);
      },
    },
    'ui:rules': [
      { required: true, message: 'Please enter the group name.' },
      { max: 25, message: 'Group Name must be less than 25 characters.' },
    ],
    'ui:title': 'Group Name',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
    'ui:onBlur': e => {
      const { value } = e.target;
      console.log(value);
    },
  },
  code: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Group Code',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the group code.' },
      { max: 25, message: 'Group Code must be less than 25 characters.' },
    ],
    'ui:title': 'Group Code',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  description: {
    'ui:widget': 'input.textarea',
    'ui:options': {
      style: {
        width: 400,
        height: 200,
      },
      autosize: { minRows: 2, maxRows: 6 },
      placeholder: 'Group Description (Max 300 chars)',
    },
    'ui:rules': [{ max: 300, message: 'Description must be less than 300 characters.' }],
    'ui:title': 'Description',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
};
