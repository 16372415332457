import apolloClient from 'helpers/apolloClient';
import { agreementListQuery } from './queries';
import { agreementAddMutation, agreementUpdateMutation, agreementForkMutation } from './mutations';

export const FETCH_AGREEMENT = 'agreement/FETCH';
export const FETCH_AGREEMENT_SUCCESS = 'agreement/FETCH_SUCCESS';
export const FETCH_AGREEMENT_FAILURE = 'agreement/FETCH_FAILURE';

export const ADD_AGREEMENT = 'agreement/ADD';
export const ADD_AGREEMENT_SUCCESS = 'agreement/ADD_SUCCESS';
export const ADD_AGREEMENT_FAILURE = 'agreement/ADD_FAILURE';

export const UPDATE_AGREEMENT = 'agreement/UPDATE';
export const UPDATE_AGREEMENT_SUCCESS = 'agreement/UPDATE_SUCCESS';
export const UPDATE_AGREEMENT_FAILURE = 'agreement/UPDATE_FAILURE';

export const FORK_AGREEMENT = 'agreement/FORK';
export const FORK_AGREEMENT_SUCCESS = 'agreement/FORK_SUCCESS';
export const FORK_AGREEMENT_FAILURE = 'agreement/FORK_FAILURE';

export function getAgreements() {
  return {
    types: [FETCH_AGREEMENT, FETCH_AGREEMENT_SUCCESS, FETCH_AGREEMENT_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: agreementListQuery,
      });

      return result.data.agreementList;
    },
  };
}

export function addAgreement(agreement) {
  return {
    types: [ADD_AGREEMENT, ADD_AGREEMENT_SUCCESS, ADD_AGREEMENT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: agreementAddMutation,
        variables: agreement,
      });

      return result.data.agreementAdd;
    },
  };
}

export function updateAgreement(agreement) {
  return {
    types: [UPDATE_AGREEMENT, UPDATE_AGREEMENT_SUCCESS, UPDATE_AGREEMENT_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: agreementUpdateMutation,
        variables: agreement,
      });

      return agreement;
    },
  };
}

export function forkAgreement(agreement) {
  return {
    types: [FORK_AGREEMENT, FORK_AGREEMENT_SUCCESS, FORK_AGREEMENT_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: agreementForkMutation,
        variables: agreement,
      });

      return agreement;
    },
  };
}
