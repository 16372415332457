import Papa from 'papaparse';
import FileSaver from 'file-saver';
import moment from 'moment';

export default function csvExport(title = 'excel', fields, jsonData) {
  const dateString = moment().format('YYYY-MM-DD HH:mm:ss');
  const titleString = title + dateString;

  if (!jsonData || !fields) {
    return { error: { message: '저장 실패', err: new TypeError('parameters are required') } };
  }
  try {
    const csv = Papa.unparse({ fields, data: jsonData });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, `${titleString}.csv`);
  } catch (e) {
    console.error(e);
    return { error: { message: '저장 실패', err: e } };
  }
  return { error: null };
}
