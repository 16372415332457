import {
  FETCH_TAG,
  FETCH_TAG_SUCCESS,
  FETCH_TAG_FAILURE,
  ADD_TAG,
  ADD_TAG_SUCCESS,
  ADD_TAG_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
} from './actions';

export const defaultFilter = {};

const initialState = {
  loading: false,
  error: null,
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: {
    ...defaultFilter,
  },
  data: [],
};

export default function TagReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TAG:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_TAG:
      return {
        ...state,
        loading: true,
        updating: true,
        updateError: null,
      };
    case ADD_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        updating: false,
        updateError: null,
      };
    case ADD_TAG_FAILURE:
      return {
        ...state,
        loading: false,
        updating: false,
        updateError: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...defaultFilter },
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
}
