import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import logo from 'shared/logo-white.png';
import SiderMenu from './SiderMenu';
import styles from './MySider.less';

const { Sider } = Layout;

class MySider extends React.PureComponent {
  static propTypes = {
    collapsed: PropTypes.bool.isRequired,
    openKeys: PropTypes.arrayOf(PropTypes.any).isRequired,
    menus: PropTypes.arrayOf(PropTypes.any).isRequired,
    openMenu: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Sider
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={this.props.collapsed}
        collapsedWidth="0"
        className={styles.sider}
      >
        <div className={styles.logo} key="logo">
          <Link to="/">
            <img src={logo} alt="logo" className={styles.icon} />
            <h1>Chai Admin</h1>
          </Link>
        </div>
        <SiderMenu menus={this.props.menus} onOpenChange={this.props.openMenu} openKeys={this.props.openKeys} />
      </Sider>
    );
  }
}
export default MySider;
