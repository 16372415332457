import React from 'react';
import { Divider, Form } from 'antd';
import PropTypes from 'prop-types';

import { formItemDividerLayout } from './layout';

function BoostDividerForm(props) {
  const { label } = props;

  return (
    <Form.Item {...formItemDividerLayout}>
      <Divider>{label}</Divider>
    </Form.Item>
  );
}

BoostDividerForm.propTypes = {
  label: PropTypes.string.isRequired,
};

export default BoostDividerForm;
