import React from 'react';
import { InputNumber, Form } from 'antd';
import PropTypes from 'prop-types';
import { commify } from 'utils/stringUtil';
import { required as requiredRule } from 'utils/formValidator';

import { formItemLayout } from '../formlayout';

function ChaiNumberForm(props) {
  const { form, initial = null, label, name, required, onChange, disabled } = props;
  const { getFieldDecorator } = form;
  const rules = [];

  if (required) {
    rules.push(requiredRule());
  }

  return (
    <Form.Item label={label} {...formItemLayout} required={required}>
      {getFieldDecorator(name, {
        initialValue: initial,
        rules,
      })(
        <InputNumber
          style={{ width: '100%' }}
          placeholder={label}
          onChange={onChange}
          min={0}
          max={1000000000}
          formatter={value => commify(value)}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
}

ChaiNumberForm.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  initial: PropTypes.number,
  disabled: PropTypes.bool,
};

ChaiNumberForm.defaultProps = {
  disabled: false,
  required: false,
  initial: 0,
  onChange: null,
};

export default ChaiNumberForm;
