import gql from 'graphql-tag';

export const cardProductListQuery = gql`
  query cardProductListQuery($filter: String, $skip: Int, $pageSize: Int) {
    cardProductList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        type
        issueTarget
        issuer
        resourceType
        title
        status
        sortedIndex
      }
      total
    }
  }
`;

export const cardProductQuery = gql`
  query cardProductQuery($id: Int!) {
    cardProduct(id: $id) {
      id
      createdAt
      updatedAt
      issuer
      resourceType
      title
      subTitle
      titleDescription
      emphasizeText
      emphasizeColor1
      emphasizeColor2
      description
      descriptionImageUrls
      maxMonthlyBenefitAmount
      issueBoltPrice
      reissueBoltPrice
      annualFee
      isDomestic
      logoImageUrl
      benefitImageUrls
      productCode
      brandCode
      status
      type
      issueTarget
      shareableUrl
      externalIssueUrl
      sortedIndex
      maxEarlyBirdCount
    }
  }
`;

export default {
  cardProductQuery,
  cardProductListQuery,
};
