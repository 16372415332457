import apolloClient from 'helpers/apolloClient';
import { boostCategoryListQuery } from './queries';
import { boostCategoryAddMutation, boostCategoryUpdateMutation } from './mutations';

export const FETCH_BOOST_CATEGORY = 'boostCategory/FETCH';
export const FETCH_BOOST_CATEGORY_SUCCESS = 'boostCategory/FETCH_SUCCESS';
export const FETCH_BOOST_CATEGORY_FAILURE = 'boostCategory/FETCH_FAILURE';

export const ADD_BOOST_CATEGORY = 'boostCategory/ADD';
export const ADD_BOOST_CATEGORY_SUCCESS = 'boostCategory/ADD_SUCCESS';
export const ADD_BOOST_CATEGORY_FAILURE = 'boostCategory/ADD_FAILURE';

export const UPDATE_BOOST_CATEGORY = 'boostCategory/UPDATE';
export const UPDATE_BOOST_CATEGORY_SUCCESS = 'boostCategory/UPDATE_SUCCESS';
export const UPDATE_BOOST_CATEGORY_FAILURE = 'boostCategory/UPDATE_FAILURE';

export const SET_FILTER = 'boostCategory/SET_FILTER';
export const RESET_FILTER = 'boostCategory/RESET_FILTER';

export const SET_CURRENT_PAGE = 'boostCategory/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'boostCategory/SET_PAGE_SIZE';

export function getBoostCategories(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BOOST_CATEGORY, FETCH_BOOST_CATEGORY_SUCCESS, FETCH_BOOST_CATEGORY_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: boostCategoryListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.boostCategoryList;
    },
  };
}

export function addBoostCategory(boostCategory) {
  return {
    types: [ADD_BOOST_CATEGORY, ADD_BOOST_CATEGORY_SUCCESS, ADD_BOOST_CATEGORY_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boostCategoryAddMutation,
        variables: boostCategory,
      });

      return result.data.boostCategoryAdd;
    },
  };
}

export function updateBoostCategory(boostCategory) {
  return {
    types: [UPDATE_BOOST_CATEGORY, UPDATE_BOOST_CATEGORY_SUCCESS, UPDATE_BOOST_CATEGORY_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: boostCategoryUpdateMutation,
        variables: boostCategory,
      });

      return boostCategory;
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
