import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { message, Alert } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';

import { getColumns } from './UserCouponListMetadata';
import { couponListQuery } from './UserCouponListQueries';
import { couponUpdateMutation } from './UserCouponListMutations';

@autobind
class UserCouponList extends PureComponent {
  static propTypes = {
    userId: PropTypes.string.isRequired,
  };

  state = {
    data: [],
    error: null,
    loading: false,
    page: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.getData(page, pageSize);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   * @param {Number} page - Page to load
   * @param {Number} pageSize - Page size
   */
  async getData(page, pageSize) {
    try {
      this.setState({ loading: true, error: null });

      const { page: oldPage } = this.state;
      const { userId } = this.props;

      this.query = cancelableQuery({
        query: couponListQuery,
        variables: {
          filter: JSON.stringify({
            userId: userId.replace('CS-', ''),
          }),
          skip: (page - 1) * pageSize,
          pageSize,
        },
      });

      const result = await this.query;
      const data = result.data.couponList.list;

      this.setState({
        loading: false,
        data: [...this.state.data, ...data],
        page: data.length === 0 ? oldPage : page, // Don't update page if there's no data
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Coupon List');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  getNextPage() {
    const { page, pageSize } = this.state;
    this.getData(page + 1, pageSize);
  }

  async updateStatus(coupon, newStatus) {
    try {
      await apolloClient.mutate({
        mutation: couponUpdateMutation,
        variables: {
          id: coupon.id,
          status: newStatus,
        },
      });

      const newData = [...this.state.data];

      for (let i = 0; i < newData.length; i += 1) {
        if (newData[i].id === coupon.id) {
          newData[i].status = newStatus;
          break;
        }
      }

      this.setState({ data: newData });
      message.success('Updated.');
    } catch (error) {
      message.error('Failed to update coupon status');
      console.log(error);
    }
  }

  render() {
    const { data, error, loading } = this.state;
    const columns = getColumns({
      onStatusChanged: this.updateStatus,
    });

    return (
      <div>
        {error && (
          <Alert
            description="Failed to load Coupon Data."
            type="warning"
            showIcon
            closable
            style={{
              marginBottom: 10,
            }}
          />
        )}

        <InfiniteScrollableTable
          id="user-coupon-list-table"
          columns={columns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </div>
    );
  }
}

export default UserCouponList;
