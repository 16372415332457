import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { func, bool, objectOf, any } from 'prop-types';
import { Modal, Form, DatePicker, Alert, Divider } from 'antd';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { updateApiKey } from 'redux/modules/apiKey/actions';

const mapDispatchToProps = {
  updateApiKey,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class ApiKeyEditModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    apiKey: objectOf(any).isRequired,
    updateApiKey: func.isRequired,
    onClose: func.isRequired,
    visible: bool.isRequired,
  };

  static defaultProps = {};

  state = {
    error: null,
  };

  input = {
    expireAt: null,
  };

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    this.props.form.validateFields(async err => {
      if (err) {
        const fieldsToCheck = ['expireAt'];

        for (let i = 0; i < fieldsToCheck.length; i += 1) {
          const field = fieldsToCheck[i];

          if (err[field]) {
            if (typeof this.input[field] !== 'undefined') {
              this.input[field].focus();
            }

            return this.setState({
              error: err[field].errors[0],
            });
          }
        }
      }

      const formFields = this.props.form.getFieldsValue();
      const apiKey = await this.props.updateApiKey({
        id: this.props.apiKey.id,
        expireAt: moment(formFields.expireAt)
          .endOf('date')
          .toDate(),
      });

      this.props.onClose(apiKey);
    });
  }

  render() {
    const { visible, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title="Edit API Key"
        onOk={this.handleSubmit}
        onCancel={() => this.props.onClose()}
        visible={visible}
        destroyOnClose
      >
        {this.state.error && (
          <Fragment>
            <Alert message={this.state.error.message} type="warning" showIcon />
            <Divider />
          </Fragment>
        )}

        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Expire">
            {getFieldDecorator('expireAt', {
              initialValue: this.props.apiKey ? moment(this.props.apiKey.expireAt) : null,
              rules: [{ type: 'object', required: true, message: 'Please select expire' }],
            })(<DatePicker format="YYYY-MM-DD" ref={node => (this.input.expireAt = node)} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default ApiKeyEditModal;
