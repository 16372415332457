import gql from 'graphql-tag';

export const settlementUpdateMutation = gql`
  mutation settlementUpdate($merchantIds: [String]!, $adjustmentList: [Adjustment], $transferException: String) {
    settlementUpdate(merchantIds: $merchantIds, adjustmentList: $adjustmentList, transferException: $transferException)
  }
`;

export const merchantBalanceTransferMutation = gql`
  mutation merchantBalanceTransfer($fromMerchantId: String!, $toMerchantId: String!, $amount: Int!) {
    merchantBalanceTransfer(fromMerchantId: $fromMerchantId, toMerchantId: $toMerchantId, amount: $amount)
  }
`;

export default {
  settlementUpdateMutation,
  merchantBalanceTransferMutation,
};
