import React, { Component } from 'react';
import { func, bool, number, any, objectOf } from 'prop-types';
import { Form, Modal, Input, InputNumber } from 'antd';
import autobind from 'autobind-decorator';
import { commify } from 'utils/stringUtil';
import Item from './PaymentCancelFormItem';
import rules from './PaymentCancelFormValidationRules';

@Form.create()
@autobind
class PaymentCancelModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
    maxAmount: number.isRequired,
  };

  cancelLock = false;

  input = {
    cancelAmount: null,
    publicAPIKey: null,
  };

  handleSubmit(ev) {
    if (this.cancelLock) {
      return;
    }

    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['cancelAmount', 'publicAPIKey'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    this.cancelLock = true;
    await this.props.onOk(formFields);
    this.cancelLock = false;

    this.cleanupBeforeClose();
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();
  }

  render() {
    const { form, onCancel, visible, maxAmount } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Modal
        title="Payment Cancel"
        onOk={this.handleSubmit}
        onCancel={onCancel}
        visible={visible}
        okText="Proceed Cancel"
        okButtonProps={{
          ghost: true,
          type: 'danger',
        }}
        cancelText="Close"
      >
        <Form>
          {/* Public API Key */}
          <Item label="Public API Key" style={{ marginBottom: 10 }}>
            {getFieldDecorator('publicAPIKey', {
              rules: rules.publicAPIKey,
            })(
              <Input
                ref={node => (this.input.publicAPIKey = node)}
                placeholder="Public API Key"
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>

          {/* Cancel Amount */}
          <div style={{ position: 'relative' }}>
            <Item label="Cancel Amount" style={{ marginBottom: 5 }}>
              {getFieldDecorator('cancelAmount', {
                rules: rules.cancelAmount,
              })(
                <InputNumber
                  ref={node => (this.input.cancelAmount = node)}
                  placeholder="Cancel Amount"
                  style={{ width: 150 }}
                  min={1}
                  max={maxAmount}
                  formatter={value => commify(value)}
                />
              )}
            </Item>

            <div style={{ position: 'absolute', left: 300, top: 10 }}>KRW</div>
          </div>

          {/* Display Checkout Amount */}
          <Item label="Available Amount" style={{ marginBottom: 0 }}>
            <div>{commify(maxAmount)} KRW</div>
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default PaymentCancelModal;
