import React, { useState } from 'react';
import PropTypes, { func, bool, objectOf, number } from 'prop-types';
import { Modal, Form } from 'antd';

import apolloClient from 'helpers/apolloClient';
import { boltDrawPromotionAddImageMutation } from 'redux/modules/boltDrawPromotion/mutations';

import BoltDrawPromotionImageUploadForm from '../BoltDrawPromotionImageUploadForm';
import { BoltDrawPromotionImageType } from './const';

const propTypes = {
  boltDrawPromotionId: number.isRequired,
  visibleAdd: bool.isRequired,
  onClose: func.isRequired,
  form: objectOf(PropTypes.any).isRequired,
};

const SquareImageAddModal = ({ boltDrawPromotionId, visibleAdd, onClose, form }) => {
  const [uploading, setUploading] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  async function addSquareImage() {
    setUploading(true);

    const formFields = form.getFieldsValue();
    const { url } = formFields.squareImageUrl[0];
    const type = BoltDrawPromotionImageType.DETAIL_SQUARE;

    try {
      await apolloClient.mutate({
        mutation: boltDrawPromotionAddImageMutation,
        variables: { boltDrawPromotionId, url, type },
      });
    } finally {
      setUploading(false);
      setIsAdded(true);
      onClose(isAdded);
    }
  }

  function handleClose(ev) {
    if (ev) {
      ev.preventDefault();
    }

    form.resetFields();

    onClose(isAdded);
  }

  async function handleSubmit(ev) {
    if (ev) ev.preventDefault();

    form.validateFields(async () => {
      if (form.getFieldValue('squareImageUrl').length === 0) {
        const instance = form.getFieldInstance('squareImageUrl');
        instance.focus();
        return;
      }

      addSquareImage();
    });
  }

  return (
    <Modal
      title="정방형 이미지 추가"
      onOk={handleSubmit}
      onCancel={handleClose}
      visible={visibleAdd}
      cancelButtonProps={{ disabled: uploading }}
      closable={!uploading}
      confirmLoading={uploading}
      maskClosable={false}
      destroyOnClose
    >
      <Form onSubmit={handleSubmit}>
        <BoltDrawPromotionImageUploadForm form={form} name="squareImageUrl" label="Square Image" require />
      </Form>
    </Modal>
  );
};

SquareImageAddModal.propTypes = propTypes;

export default Form.create()(SquareImageAddModal);
