import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const style = {
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
  },
};

/**
 * Get Status text for Badge Component
 * @param {string} status - Payment Status
 * @return string
 */
function getStatus(status) {
  return {
    waiting: 'default',
    prepared: 'processing',
    approved: 'processing',
    user_canceled: 'error',
    canceled: 'error',
    confirmed: 'success',
    failed: 'error',
    timeout: 'warning',
    churn: 'warning',
  }[status];
}

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderId(val) {
      return <LinkText>{val.substr(0, 10)}...</LinkText>;
    },
    width: '15%',
  },
  {
    title: 'Checkout Amt.',
    dataIndex: 'checkoutAmount',
    align: 'right',
    render: function renderCheckoutAmount(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: 'Billing Amt.',
    dataIndex: 'billingAmount',
    align: 'right',
    render: function renderBillingAmount(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: 'Cashback Amt.',
    dataIndex: 'cashbackAmount',
    align: 'right',
    render: function renderBillingAmount(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return <Badge status={getStatus(val)} text={startCase(val)} style={{ width: '100%', ...style.textOverflow }} />;
    },
    width: '10%',
  },
  {
    title: 'Source',
    dataIndex: 'merchant',
    render: function renderLocation(merchant) {
      return <span>{merchant ? merchant.displayName : '-'}</span>;
    },
    width: '15%',
  },
];

export default {
  columns,
};
