import gql from 'graphql-tag';

export const getGroupsQuery = gql`
  query getGroups($filter: String, $skip: Int, $pageSize: Int) {
    getGroups(filter: $filter, skip: $skip, pageSize: $pageSize) {
      id
      name
      code
      description
    }
  }
`;

export default {
  getGroupsQuery,
};
