const rules = {
  title: [
    {
      required: true,
      message: 'Please enter the title.',
    },
  ],
  subTitle: [
    {
      required: true,
      message: 'Please enter the subtitle.',
    },
  ],
  detailTitle: [
    {
      required: true,
      message: 'Please enter the detail page title.',
    },
  ],
  description: [],
  startAt: [
    {
      required: true,
      message: 'Please select the date.',
    },
  ],
  endAt: [
    {
      required: true,
      message: 'Please select the date.',
    },
  ],
  merchant: [
    {
      required: true,
      message: 'Merchant must be selected.',
    },
  ],
  promotions: [],
};

export default rules;
