import {
  FETCH_PROMOTION,
  FETCH_PROMOTION_SUCCESS,
  FETCH_PROMOTION_FAILURE,
  ADD_PROMOTION,
  ADD_PROMOTION_SUCCESS,
  ADD_PROMOTION_FAILURE,
  UPDATE_PROMOTION,
  UPDATE_PROMOTION_SUCCESS,
  UPDATE_PROMOTION_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  TOGGLE_COPY_ITEM,
  CLEAR_COPY_ITEM,
} from './actions';

export const defaultFilter = {
  status: 'enabled',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  copyItems: [],
  filter: {
    ...defaultFilter,
  },
  updating: false,
  updateError: null,
};

export default function PromotionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROMOTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_PROMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_PROMOTION:
    case UPDATE_PROMOTION:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case ADD_PROMOTION_SUCCESS:
    case UPDATE_PROMOTION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: null,
      };
    case ADD_PROMOTION_FAILURE:
    case UPDATE_PROMOTION_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...defaultFilter },
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case TOGGLE_COPY_ITEM:
      if (state.copyItems.find(item => item.id === action.payload.id)) {
        return {
          ...state,
          copyItems: state.copyItems.filter(item => item.id !== action.payload.id),
        };
      }
      return {
        ...state,
        copyItems: state.copyItems.concat(action.payload),
      };
    case CLEAR_COPY_ITEM:
      return {
        ...state,
        copyItems: initialState.copyItems,
      };
    default:
      return state;
  }
}
