const rules = {
  mainText: [
    {
      required: true,
      message: 'Please enter the Main Text.',
    },
  ],
  subText: [
    {
      required: true,
      message: 'Please enter the Sub Text.',
    },
  ],
  sortedIndex: [
    {
      required: false,
    },
  ],
};

export default rules;
