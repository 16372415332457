const rules = {
  title: [
    {
      required: true,
      message: 'Please enter the title.',
    },
  ],
};

export default rules;
