export default {
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Action Name',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the action name.' },
      { max: 25, message: 'Action Name must be less than 25 characters.' },
    ],
    'ui:title': 'Action Name',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item config
  },
  code: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Action Code',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the action code.' },
      { max: 25, message: 'Action Code must be less than 25 characters.' },
    ],
    'ui:title': 'Action Code',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  description: {
    'ui:widget': 'input.textarea',
    'ui:options': {
      style: {
        width: 400,
        height: 200,
      },
      autosize: { minRows: 2, maxRows: 6 },
      placeholder: 'Action Description (Max 300 chars)',
    },
    'ui:rules': [{ max: 300, message: 'Description must be less than 300 characters.' }],
    'ui:title': 'Description',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  groupIds: {
    'ui:widget': 'select',
    'ui:options': {
      fieldNames: { label: 'name', value: 'id' },
      options: [],
      mode: 'multiple',
    },
    'ui:rules': [{ required: true, message: 'Please select action group.' }],
    'ui:remoteConfig': {
      apiKey: 'GET_GROUPS',
      hand: data => data,
    },
    'ui:title': 'Group',
    'ui:description': '',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  menuId: {
    'ui:widget': 'cascader',
    'ui:options': {
      fieldNames: { label: 'title', value: 'id', children: 'children' },
      options: [],
    },
    'ui:remoteConfig': {
      apiKey: 'GET_ALL_MENU_LIST',
      hand: data => data,
    },
    'ui:title': 'Menu',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      label: 'Menu',
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
};
