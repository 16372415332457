import gql from 'graphql-tag';

export const b2bUserAddMutation = gql`
  mutation b2bUserAdd(
    $merchantId: String!
    $loginId: String!
    $password: String!
    $permittedMerchantUserIds: [String]
  ) {
    b2bUserAdd(
      merchantId: $merchantId
      loginId: $loginId
      password: $password
      permittedMerchantUserIds: $permittedMerchantUserIds
    )
  }
`;

export const b2bUserUpdateMutation = gql`
  mutation b2bUserUpdate($id: ID!, $password: String, $status: String, $permittedMerchantUserIds: [String]) {
    b2bUserUpdate(id: $id, password: $password, status: $status, permittedMerchantUserIds: $permittedMerchantUserIds)
  }
`;

export const b2bUserBalanceUpdateMutation = gql`
  mutation b2bUserBalanceUpdate(
    $id: ID!
    $amount: BigInt!
    $date: Date!
    $name: String!
    $remittanceAmount: String!
    $currency: String!
    $exchangeRate: String!
  ) {
    b2bUserBalanceUpdate(
      id: $id
      amount: $amount
      date: $date
      name: $name
      remittanceAmount: $remittanceAmount
      currency: $currency
      exchangeRate: $exchangeRate
    )
  }
`;

export default {
  b2bUserAddMutation,
  b2bUserUpdateMutation,
};
