import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { Modal } from 'antd';
import autobind from 'autobind-decorator';
import TagList from '../List/TagList';

@autobind
class TagModal extends Component {
  static propTypes = {
    id: string.isRequired,
    type: string.isRequired,
    visible: bool.isRequired,
    onCloseModal: func.isRequired,
  };

  render() {
    const { visible, onCloseModal, id, type } = this.props;

    return (
      <Modal
        title="Tags"
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Close"
        width={980}
      >
        {id && <TagList id={id} type={type} />}
      </Modal>
    );
  }
}

export default TagModal;
