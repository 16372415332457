import moment from 'moment';
import {
  FETCH_SETTLEMENT_DETAIL,
  FETCH_SETTLEMENT_DETAIL_SUCCESS,
  FETCH_SETTLEMENT_DETAIL_FAILURE,
  SET_MERCHANT_ID,
  SET_MERCHANT_NAME,
  SET_DATE,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  merchantId: '',
  merchantName: '',
  date: moment(),
};

export default function SettlementDetailReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTLEMENT_DETAIL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SETTLEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result,
      };
    case FETCH_SETTLEMENT_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_MERCHANT_ID: {
      return {
        ...state,
        merchantId: action.payload,
      };
    }
    case SET_MERCHANT_NAME: {
      return {
        ...state,
        merchantName: action.payload,
      };
    }
    case SET_DATE: {
      return {
        ...state,
        date: action.payload,
      };
    }
    default:
      return state;
  }
}
