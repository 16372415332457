import gql from 'graphql-tag';

export const locationCollectHistoryListQuery = gql`
  query locationCollectHistoryListQuery($filter: String, $skip: Int, $pageSize: Int) {
    locationCollectHistoryList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        userId
        createdAt
        updatedAt
        platform
        description
      }
      total
    }
  }
`;

export default {
  locationCollectHistoryListQuery,
};
