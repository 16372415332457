import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { Modal, Input, Button, message, Table } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { getTagQuery } from 'redux/modules/tag/queries';
import { makeTagPolicyMappingMutation } from 'redux/modules/tag/mutations';
import { columns } from '../List/TagListMetadata';

@autobind
class AddTagModal extends Component {
  static propTypes = {
    id: string.isRequired,
    type: string.isRequired,
    visible: bool.isRequired,
    refreshBoostPromotion: func.isRequired,
    onCloseModal: func.isRequired,
  };

  state = {
    tagName: '',
    data: {},
  };

  input = {
    search: null,
  };

  query = null;

  updateTagName(e) {
    this.setState({ tagName: e.target.value });
  }

  async getTag() {
    const { tagName } = this.state;

    try {
      this.query = cancelableQuery({
        query: getTagQuery,
        variables: { tagName },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.getTag) {
        throw new Error('Invalid Tag Name');
      } else {
        this.setState({ data: result.data.getTag });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Tag: ${error.message}`);
      throw error;
    }
  }

  async addTag() {
    const { id, type } = this.props;
    const { data } = this.state;

    try {
      await apolloClient.mutate({
        mutation: makeTagPolicyMappingMutation,
        variables: {
          tagName: data.name,
          policyType: type,
          policyId: id,
        },
      });

      message.success('new Tag Mapping Success.');
      this.props.refreshBoostPromotion();
      this.props.onCloseModal();
    } catch (error) {
      message.error('Failed to Create Tag Mapping.\n', error.message);
    }
  }

  getColumns(option) {
    const { addTag } = option;
    return [
      ...columns,
      {
        title: 'Tools',
        align: 'center',
        render() {
          return (
            <Button type="primary" ghost onClick={addTag}>
              Add
            </Button>
          );
        },
      },
    ];
  }

  render() {
    const { visible, onCloseModal } = this.props;
    const { tagName, data } = this.state;
    const addTagColumns = this.getColumns({ addTag: this.addTag });

    return (
      <Modal
        title="Add Tag"
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Close"
        width={980}
      >
        <div style={{ margin: '15px 15px 30px' }}>
          <Input
            ref={node => (this.input.search = node)}
            placeholder="Tag Name"
            value={tagName}
            onChange={this.updateTagName}
            autoComplete="off"
            style={{ width: '85%' }}
          />
          <Button key="Search" style={{ width: '12%', marginLeft: '15px' }} type="primary" onClick={this.getTag}>
            Search
          </Button>
        </div>
        <Table
          columns={addTagColumns}
          rowKey={record => record.id}
          dataSource={data.id ? [data] : undefined}
          pagination={false}
        />
      </Modal>
    );
  }
}

export default AddTagModal;
