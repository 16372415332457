import React, { Component } from 'react';
import { Button, Form, Upload, Icon, message } from 'antd';
import PropTypes from 'prop-types';

import apolloClient from 'helpers/apolloClient';
import { bankUploadImage } from 'redux/modules/bank/mutations';
import { required } from 'utils/formValidator';

import { formItemLayout } from '../Boost/Forms/layout';

class BankImageUploadForm extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any),
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    originalLogoFormName: PropTypes.string,
    require: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    require: false,
    initial: null,
    originalLogoFormName: null,
  };

  async componentDidUpdate() {
    if (!this.props.originalLogoFormName) {
      return;
    }

    const { form, name, originalLogoFormName } = this.props;

    if (originalLogoFormName) {
      const formFileList = form.getFieldValue(name);
      const originalLogo = form.getFieldValue(originalLogoFormName);
      if (originalLogo && originalLogo[0] && formFileList && formFileList[0]) {
        if (formFileList[0].name !== originalLogo[0].name) {
          form.setFieldsValue({
            [name]: this.makeFileObjFromOrigial(),
          });
        }
      }
    }
  }

  idx = 0;

  async onChange({ file }) {
    const { name, form, originalLogoFormName } = this.props;

    try {
      let fileName;

      if (originalLogoFormName) {
        const originalLogo = form.getFieldValue(originalLogoFormName);
        if (!originalLogo || !originalLogo[0]) {
          message.error('Upload original logo first');
          return;
        }
        fileName = originalLogo[0].url.replace(/\.png$/, '_bw2').replace(/.*\//g, '');
      }

      const result = await apolloClient.mutate({
        mutation: bankUploadImage,
        variables: {
          imageFile: file,
          ...(fileName && { fileName }),
        },
      });

      const { bankUploadImage: uploadedFile } = result.data;

      if (!originalLogoFormName) {
        form.setFieldsValue({
          [name]: [this.makeFileObj(uploadedFile)],
        });
      } else {
        form.setFieldsValue({
          [name]: this.makeFileObjFromOrigial(),
        });
      }
    } catch (e) {
      message.error(e);
    }
  }

  makeFileObj(file) {
    return {
      uid: (this.idx += 1),
      name: file.filename,
      status: 'done',
      url: `https://static.chai.finance/${file.path}/${file.filename}`,
    };
  }

  makeFileObjFromOrigial() {
    const { form, originalLogoFormName } = this.props;
    const originalLogo = form.getFieldValue(originalLogoFormName);
    if (originalLogo && originalLogo[0]) {
      return [
        {
          ...originalLogo[0],
          uid: (this.idx += 1),
          url: originalLogo[0].url.replace(/\.png$/, '_bw2.png'),
        },
      ];
    }

    return [];
  }

  onRemove() {
    const { name, form } = this.props;
    form.setFieldsValue({
      [name]: [],
    });
  }

  render() {
    const { form, initial, name, label, require, originalLogoFormName } = this.props;

    form.getFieldDecorator(name, {
      initialValue: originalLogoFormName ? this.makeFileObjFromOrigial() : initial,
      rules: require ? [required()] : [],
    });

    const formFileList = form.getFieldValue(name);
    let hasError = null;
    if (form.getFieldError(name)) {
      hasError = {
        validateStatus: 'error',
        help: `${label} is required`,
      };
    }

    return (
      <Form.Item label={label} {...formItemLayout} style={{ marginBottom: 20 }} required {...hasError}>
        <Upload
          multiple={false}
          listType="picture-card"
          fileList={formFileList}
          onChange={({ file, fileList }) => this.onChange({ file, fileList })}
          onRemove={() => this.onRemove()}
          beforeUpload={() => false}
          // fileList={androidImageFile && [androidImageFile]}
          accept=".jpg,.png"
        >
          <Button>
            <Icon type="upload" /> Select File
          </Button>
        </Upload>
      </Form.Item>
    );
  }
}

export default BankImageUploadForm;
