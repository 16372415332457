import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';
import styles from './FaqForm.scss';

const itemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FaqFormItem = ({ children, ...props }) => (
  <Form.Item {...props} {...itemLayout} className={props.className || styles.formControl} labelCol={{ span: 2 }}>
    {children}
  </Form.Item>
);

FaqFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

FaqFormItem.defaultProps = {
  className: null,
};

export default FaqFormItem;
