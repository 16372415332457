import gql from 'graphql-tag';

export const paymentListQuery = gql`
  query paymentList($filter: String, $skip: Int, $pageSize: Int) {
    paymentList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      total
      list {
        id
        status
        idempotencyKey
        currency
        checkoutAmount
        billingAmount
        canceledAmount
        description
        returnUrl
        createdAt
        updatedAt
        merchant {
          id
          name
          displayName
        }
      }
    }
  }
`;

export default {
  paymentListQuery,
};
