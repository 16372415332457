import {
  FETCH_BOOST_EXCLUSION_RULE,
  FETCH_BOOST_EXCLUSION_RULE_SUCCESS,
  FETCH_BOOST_EXCLUSION_RULE_FAILURE,
  ADD_BOOST_EXCLUSION_RULE,
  ADD_BOOST_EXCLUSION_RULE_SUCCESS,
  ADD_BOOST_EXCLUSION_RULE_FAILURE,
  UPDATE_BOOST_EXCLUSION_RULE,
  UPDATE_BOOST_EXCLUSION_RULE_SUCCESS,
  UPDATE_BOOST_EXCLUSION_RULE_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
} from './actions';

export const defaultFilter = {};

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: {
    ...defaultFilter,
  },
  updating: false,
  updateError: null,
};

export default function BoostPolicyReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOOST_EXCLUSION_RULE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BOOST_EXCLUSION_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_BOOST_EXCLUSION_RULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_BOOST_EXCLUSION_RULE:
    case UPDATE_BOOST_EXCLUSION_RULE:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case ADD_BOOST_EXCLUSION_RULE_SUCCESS:
    case UPDATE_BOOST_EXCLUSION_RULE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: null,
      };
    case ADD_BOOST_EXCLUSION_RULE_FAILURE:
    case UPDATE_BOOST_EXCLUSION_RULE_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...defaultFilter },
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
}
