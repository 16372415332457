import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import { cancel } from 'redux/modules/popup';

const styles = require('./PopupWrapper.scss');

@connect(
  state => ({
    isShowing: state.popup.isShowing,
  }),
  {
    cancel,
  }
)
class PopupWrapper extends Component {
  static propTypes = {
    isShowing: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    cancel: PropTypes.func.isRequired,
  };

  shouldComponentUpdate(nextProps) {
    return this.props.isShowing !== nextProps.isShowing;
  }

  @autobind
  handleCancel(event) {
    event.stopPropagation();
    this.props.cancel();
  }

  render() {
    return (
      <div
        className={cx({
          [styles.popup_on]: this.props.isShowing,
          [styles.popup_off]: !this.props.isShowing,
        })}
        onClick={this.handleCancel}
      >
        <div className={styles.popup_wrapper}>{this.props.children}</div>
      </div>
    );
  }
}

export default PopupWrapper;
