// Text Utilities

/**
 * Returns text for displaying Status.
 * Returns capitalized first letter
 * @param {string} text
 * @return {string} String that capitalized first letter
 * @return {null} Failed. Maybe text is undefined or non string variable.
 */
export function capitalizeFirstLetter(text) {
  if (typeof text === 'undefined' || !text) return null;

  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function ellipsis(text, len, ellipsisText = '...') {
  if (text && text.length > len) {
    return `${text.substr(0, len)}${ellipsisText}`;
  }
  return text;
}

export default {
  capitalizeFirstLetter,
  ellipsis,
};
