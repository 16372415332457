import apolloClient from 'helpers/apolloClient';
import { getTagListQuery } from './queries';

export const FETCH_TAG = 'tag/FETCH';
export const FETCH_TAG_SUCCESS = 'tag/FETCH_SUCCESS';
export const FETCH_TAG_FAILURE = 'tag/FETCH_FAILURE';

export const SET_FILTER = 'tag/SET_FILTER';
export const RESET_FILTER = 'tag/RESET_FILTER';

export const SET_CURRENT_PAGE = 'tag/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'tag/SET_PAGE_SIZE';

export const SET_DETAIL_CURRENT_PAGE = 'tagDetail/SET_CURRENT_PAGE';
export const SET_DETAIL_PAGE_SIZE = 'tagDetail/SET_PAGE_SIZE';

/* filter:  tagName, targetId, targetType, policyId, policyType */
export function getTags(currentPage, pageSize, filter) {
  return {
    types: [FETCH_TAG, FETCH_TAG_SUCCESS, FETCH_TAG_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;
      const result = await apolloClient.query({
        query: getTagListQuery,
        variables: {
          skip,
          pageSize,
          filter: JSON.stringify({ tagName: filter.tagName }),
        },
      });

      return result.data.getTagList;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
