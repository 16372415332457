// 5xx Server Error
export const ServerErrorCodes = {
  // FAILED
  TRANSACTION_FAILED: 'S001',
  GET_TOKEN_FAILED: 'S002',
  CHECK_PIN_FAILED: 'S003',
  IDENTIFICATION_FAILED: 'S004',
  // NOT FOUND
  MERCHANT_NOT_FOUND: 'S101',
  ACCOUNT_NOT_FOUND: 'S102',
  LEDGER_NOT_FOUND: 'S103',
  CHARGING_AMOUNT_NOT_FOUND: 'S104',
  PG_SEQUENCE_ID_NOT_FOUND: 'S105',
  COUPON_NOT_FOUND: 'S106',
  // API
  API_CALL_ERROR: 'S201', // TODO check
  // SYSTEM ERROR
  DB_ERROR: 'S901',
  TIMEOUT: 'S902',
  MODULE_ERROR: 'S903',
};

// 4xx Client Error
export const ClientErrorCodes = {
  // INVALID
  DATA_NOT_PROVIDED: 'A001',
  INVALID_ID_OR_PASSWORD: 'A002',
  INVALID_OTP: 'A003',
  REQUEST_OTP_TOO_FAST: 'A004',

  // NOT FOUND
  ADMIN_NOT_FOUND: 'N001',
  GROUP_NOT_FOUND: 'N002',
  MERCHANT_NOT_FOUND: 'N003',

  // OTHERS
  INACITVE_ADMIN: 'S001',

  // EXCEEDED
  REQUEST_TIMEOUT: 'T001',
  OTP_REQUEST_RATE_LIMITED: 'T002',
  SESSION_EXPIRED: 'T003',

  // Permissions
  NOT_HAVE_ACTION: 'P001',
  NOT_IN_GROUP: 'P002',
};
