import gql from 'graphql-tag';

export const updatePromotionAirdropMutation = gql`
  mutation promotionAirdropUpdate($id: ID!, $status: String) {
    promotionAirdropUpdate(id: $id, status: $status)
  }
`;

export const addPromotionAirdropMutation = gql`
  mutation promotionAirdropAdd($promotionId: ID!, $issueAt: Date, $userIdFile: FileUpload) {
    promotionAirdropAdd(promotionId: $promotionId, issueAt: $issueAt, userIdFile: $userIdFile)
  }
`;

export const couponIssueMutation = gql`
  mutation couponIssue($promotionId: ID!, $userIds: [ID], $userIdFile: FileUpload) {
    couponIssue(promotionId: $promotionId, userIds: $userIds, userIdFile: $userIdFile) {
      failedIds
      errors
    }
  }
`;

export const couponDisableMutation = gql`
  mutation couponDisable($promotionId: ID!) {
    couponDisable(promotionId: $promotionId)
  }
`;

export const subMerchantMutation = gql`
  mutation subMerchantUpdate($id: Int!, $name: String!, $merchantId: ID, $maxMonthlyCashbackAmount: Int) {
    subMerchantUpdate(
      id: $id
      name: $name
      merchantId: $merchantId
      maxMonthlyCashbackAmount: $maxMonthlyCashbackAmount
    )
  }
`;

export const promotionCopyMutation = gql`
  mutation promotionCopyWithDate($targetDate: Date!, $targetIds: [ID]!) {
    promotionCopyWithDate(targetDate: $targetDate, targetIds: $targetIds)
  }
`;
