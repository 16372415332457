import {
  FETCH_SETTLEMENT_PREVIEW,
  FETCH_SETTLEMENT_PREVIEW_SUCCESS,
  FETCH_SETTLEMENT_PREVIEW_FAILURE,
  FETCH_SETTLEMENT_TRANSFER,
  FETCH_SETTLEMENT_TRANSFER_SUCCESS,
  FETCH_SETTLEMENT_TRANSFER_FAILURE,
  FETCH_SETTLEMENT_UPDATE,
  FETCH_SETTLEMENT_UPDATE_SUCCESS,
  FETCH_SETTLEMENT_UPDATE_FAILURE,
  SETTLEMENT_TRANSFER_SET_FILTER,
  SETTLEMENT_TRANSFER_RESET_FILTER,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  updating: false,
  updateError: null,
  data: [],
  historyLoading: false,
  historyError: null,
  historyData: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: null,
};

export const defaultFilter = {};

export default function SettlementTableReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTLEMENT_PREVIEW:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SETTLEMENT_PREVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result,
      };
    case FETCH_SETTLEMENT_PREVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case FETCH_SETTLEMENT_TRANSFER:
      return {
        ...state,
        historyLoading: true,
        historyError: null,
      };
    case FETCH_SETTLEMENT_TRANSFER_SUCCESS:
      return {
        ...state,
        historyLoading: false,
        historyError: null,
        historyData: action.result,
      };
    case FETCH_SETTLEMENT_TRANSFER_FAILURE:
      return {
        ...state,
        historyLoading: false,
        historyError: action.error,
      };
    case SETTLEMENT_TRANSFER_SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case SETTLEMENT_TRANSFER_RESET_FILTER:
      return {
        ...state,
        filter: null,
      };
    case FETCH_SETTLEMENT_UPDATE:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case FETCH_SETTLEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: null,
      };
    case FETCH_SETTLEMENT_UPDATE_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    default:
      return state;
  }
}
