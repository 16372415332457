import {
  FETCH_BOOST_PROMOTION,
  FETCH_BOOST_PROMOTION_SUCCESS,
  FETCH_BOOST_PROMOTION_FAILURE,
  ADD_BOOST_PROMOTION,
  ADD_BOOST_PROMOTION_SUCCESS,
  ADD_BOOST_PROMOTION_FAILURE,
  UPDATE_BOOST_PROMOTION,
  UPDATE_BOOST_PROMOTION_SUCCESS,
  UPDATE_BOOST_PROMOTION_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  ADD_COPY_ITEM,
  REMOVE_COPY_ITEM,
  CLEAR_COPY_ITEM,
  COPY_BOOST_PROMOTION,
  COPY_BOOST_PROMOTION_SUCCESS,
  COPY_BOOST_PROMOTION_FAILURE,
} from './actions';

export const defaultFilter = {};

const initialState = {
  loading: false,
  error: null,
  copyLoading: false,
  copyError: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: {
    ...defaultFilter,
  },
  updating: false,
  updateError: null,
  copyItems: [],
};

export default function BoostPromotionReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BOOST_PROMOTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BOOST_PROMOTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_BOOST_PROMOTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_BOOST_PROMOTION:
    case UPDATE_BOOST_PROMOTION:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case ADD_BOOST_PROMOTION_SUCCESS:
    case UPDATE_BOOST_PROMOTION_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: null,
      };
    case ADD_BOOST_PROMOTION_FAILURE:
    case UPDATE_BOOST_PROMOTION_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case COPY_BOOST_PROMOTION:
      return {
        ...state,
        copyLoading: true,
        copyError: null,
      };
    case COPY_BOOST_PROMOTION_SUCCESS:
      return {
        ...state,
        copyLoading: false,
        copyError: null,
      };
    case COPY_BOOST_PROMOTION_FAILURE:
      return {
        ...state,
        copyLoading: false,
        copyError: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...defaultFilter },
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    case ADD_COPY_ITEM:
      return {
        ...state,
        copyItems: state.copyItems.concat({
          ...action.payload,
          rowKey: `${action.payload.id}_${Date.now()}`,
        }),
      };

    case REMOVE_COPY_ITEM:
      return {
        ...state,
        copyItems: state.copyItems.filter(row => row.rowKey !== action.payload.rowKey),
      };

    case CLEAR_COPY_ITEM:
      return {
        ...state,
        copyItems: [],
      };

    default:
      return state;
  }
}
