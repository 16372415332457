import gql from 'graphql-tag';

export const bankListQuery = gql`
  query bankListQuery($filter: String, $skip: Int, $pageSize: Int) {
    bankList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        name
        shortName
        code
        logoUrl
        isActive
        sortedIndex
      }
      total
    }
  }
`;

export const bankQuery = gql`
  query bank($id: Int!) {
    bank(id: $id) {
      id
      name
      shortName
      code
      bankCode
      logoUrl
      isActive
      isRegistrable
      sortedIndex
    }
  }
`;

export default {
  bankQuery,
  bankListQuery,
};
