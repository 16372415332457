import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col } from 'antd';
import { rowProps, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getBrandList } from 'redux/modules/brand/actions';
import { defaultFilter } from 'redux/modules/brand/reducer';
import styles from './Brand.scss';

const { Item } = Form;

const mapStateToProps = state => {
  const { pageSize, filter } = state.brand;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getBrandList,
})
@autobind
class BrandFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getBrandList: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getBrandList(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getBrandList(1, pageSize, {
      ...defaultFilter,
    });

    this.props.form.resetFields();
  }

  render() {
    // const { form } = this.props;
    // const { getFieldDecorator } = form;
    // const filter = this.props.filter ? this.props.filter : {};
    // const { period, status, query } = filter;

    //   <Row {...rowProps}>
    //   Query
    //   <Col {...col5Props}>
    //     <Item label="Bolt Policy Name">
    //       {getFieldDecorator('query', {
    //         rules: [
    //           {
    //             type: 'string',
    //           },
    //         ],
    //         initialValue: query,
    //       })(<Input placeholder="Name" autoComplete="off" />)}
    //     </Item>
    //   </Col>
    //   {/* Period */}
    //   <Col {...col5Props}>
    //     <Item label="Period">
    //       {getFieldDecorator('period', {
    //         rules: [
    //           {
    //             type: 'array',
    //           },
    //         ],
    //         initialValue: period,
    //       })(<RangePicker style={{ width: '100%' }} />)}
    //     </Item>
    //   </Col>
    //   {/* Status */}
    //   <Col {...col5Props}>
    //     <Item label="Status">
    //       {getFieldDecorator('status', {
    //         rules: [
    //           {
    //             type: 'string',
    //           },
    //         ],
    //         initialValue: status || 'enabled',
    //       })(
    //         <Select>
    //           <Option value="all">All</Option>
    //           <Option value="enabled">Enabled</Option>
    //           <Option value="disabled">Disabled</Option>
    //         </Select>
    //       )}
    //     </Item>
    //   </Col>
    // </Row>

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          <Col {...col2RightProps}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default BrandFilterForm;
