const rules = {
  period: [
    {
      type: 'array',
    },
  ],
  userName: [
    {
      type: 'string',
    },
  ],
  id: [
    {
      type: 'string',
    },
  ],
  idempotencyKey: [
    {
      type: 'string',
    },
  ],
  amountBegin: [
    {
      type: 'number',
    },
  ],
  amountEnd: [
    {
      type: 'number',
    },
  ],
};

export default rules;
