import apolloClient from 'helpers/apolloClient';
import { groupMerchantListQuery } from './queries';

export const FETCH_GROUP_MERCHANT = 'groupMerchant/FETCH';
export const FETCH_GROUP_MERCHANT_SUCCESS = 'groupMerchant/FETCH_SUCCESS';
export const FETCH_GROUP_MERCHANT_FAILURE = 'groupMerchant/FETCH_FAILURE';

export function getGroupMerchant() {
  return {
    types: [FETCH_GROUP_MERCHANT, FETCH_GROUP_MERCHANT_SUCCESS, FETCH_GROUP_MERCHANT_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: groupMerchantListQuery,
        context: {
          cardGraph: true,
        },
        variables: {
          inputGroupMerchantNo: '',
          inputGroupMerchantName: '',
          inputMerchantNo: '',
          inputMerchantName: '',
          inputGubun: '',
          inputUseYn: '',
        },
      });

      return result.data.selectGroupMerchantMgmtList;
    },
  };
}
