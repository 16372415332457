import gql from 'graphql-tag';

export const faqQuery = gql`
  query faq($id: ID!) {
    faq(id: $id) {
      id
      category
      title
      content
      author
      status
    }
  }
`;

export default {
  faqQuery,
};
