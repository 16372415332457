import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { renderRoutes } from 'react-router-config';
import { provideHooks } from 'redial';
import ChaiHeader from 'components/ChaiHeader';
import ChaiFooter from 'components/ChaiFooter';
import { ContainerQuery } from 'react-container-query';
import { enquireScreen, unenquireScreen } from 'enquire-js';
import classNames from 'classnames';
import { Layout } from 'antd';
import { setMenuCollapsed, updateAccessMenu, updateMenuMapToRouter, setIsMobile } from 'redux/modules/global';
import gql from 'graphql-tag';
import apolloClient from 'helpers/apolloClient';
import { openAccessedMenuFunc } from 'utils/permission';
import { GET_ACC_MENU_LIST } from 'gql';
import Sider from './Sider';
import styles from './BasicLayout.less';

const { Content, Header, Footer } = Layout;

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
  },
};

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

@provideHooks({
  // fetch: async ({ store: { dispatch, getState } }) => {
  //   if (!isAuthLoaded(getState())) {
  //     await dispatch(loadAuth()).catch(() => null);
  //   }
  // }
})
@connect(
  state => ({
    collapsed: state.global.collapsed,
  }),
  // Map actions to props.
  {
    setMenuCollapsed,
    updateAccessMenu,
    updateMenuMapToRouter,
    setIsMobile,
  }
)
@autobind
class BasicLayout extends Component {
  static propTypes = {
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      action: PropTypes.string,
    }).isRequired,
    collapsed: PropTypes.bool.isRequired,
    setMenuCollapsed: PropTypes.func.isRequired,
    updateAccessMenu: PropTypes.func.isRequired,
    updateMenuMapToRouter: PropTypes.func.isRequired,
    setIsMobile: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isMobile,
    };
  }

  async componentDidMount() {
    this.enquireHandler = enquireScreen(mobile => {
      this.setState({
        isMobile: mobile,
      });
      this.props.setIsMobile(!!mobile);

      if (mobile) {
        this.props.setMenuCollapsed(true);
      }
    });

    const { data } = await apolloClient.query({
      query: GET_ACC_MENU_LIST,
    });

    const menuRes = data.getAccessMenus;
    const openAccessedMenu = openAccessedMenuFunc(menuRes);

    this.props.updateAccessMenu({
      accessMenu: menuRes,
      openAccessMenu: openAccessedMenu,
    });

    const {
      data: { getMenuMapToRouter: menuMapToRouter },
    } = await apolloClient.query({
      query: gql`
        query getMenuMapToRouter {
          getMenuMapToRouter
        }
      `,
    });

    this.props.updateMenuMapToRouter(menuMapToRouter);
  }

  componentWillUnmount() {
    unenquireScreen(this.enquireHandler);
  }

  handleMenuCollapse(collapsed) {
    this.props.setMenuCollapsed(collapsed);
  }

  render() {
    const { collapsed, route } = this.props;
    const layout = (
      <Layout>
        <Sider />
        <Layout>
          <Header style={{ padding: 0 }}>
            <ChaiHeader collapsed={collapsed} isMobile={this.state.isMobile} onCollapse={this.handleMenuCollapse} />
          </Header>
          <Content className={styles.content}>{renderRoutes(route.routes)}</Content>
          <Footer style={{ textAlign: 'center' }}>
            <ChaiFooter />
          </Footer>
        </Layout>
      </Layout>
    );

    return (
      <div>
        {!this.state.notSupported && (
          <div>
            <ContainerQuery query={query}>
              {params => <div className={classNames(params)}>{layout}</div>}
            </ContainerQuery>
          </div>
        )}
      </div>
    );
  }
}

export default BasicLayout;
