import React, { Component } from 'react';
import apolloClient from 'helpers/apolloClient';
import { func, bool, any, objectOf } from 'prop-types';
import { Form, Modal, Input, Radio, Select, message } from 'antd';
import autobind from 'autobind-decorator';
import Item from './ResetAdminFormItem';
import rules from './ResetAdminFormValidationRules';
import { requestResetMutation } from '../LoginMutations';

@Form.create()
@autobind
class ResetAdminModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  state = {
    inputLock: false,
    requestLock: false,
  };
  cancelLock = false;

  input = {
    uid: null,
    carrier: null,
    phone: null,
    birth: null,
    name: null,
    sex: null,
    foreigner: null,
    code: null,
  };

  handleSubmit(ev) {
    if (this.cancelLock) {
      return;
    }

    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateConfirmReset);
  }

  async validateConfirmReset(err) {
    const fieldsToCheck = ['uid', 'carrier', 'phone', 'birth', 'name', 'sex', 'foreigner', 'code'];
    const isValid = this.validateFormFields(err, fieldsToCheck);
    if (!isValid) {
      return;
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    this.cancelLock = true;
    const result = await this.props.onOk(formFields);
    this.cancelLock = false;

    if (result) {
      this.cleanupBeforeClose();
    }

    return result;
  }

  async validateRequestReset(err) {
    const fieldsToCheck = ['uid', 'carrier', 'phone', 'birth', 'name', 'sex', 'foreigner'];
    const isValid = this.validateFormFields(err, fieldsToCheck);
    if (!isValid) {
      return;
    }

    this.setState({
      inputLock: true,
    });

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    this.cancelLock = true;
    await this.getResetCode(formFields);
    this.cancelLock = false;
  }

  unlockRequestButton() {
    this.setState({
      requestLock: false,
    });
  }

  async getResetCode(variables) {
    try {
      this.setState({
        requestLock: true,
      });

      await apolloClient.mutate({
        mutation: requestResetMutation,
        variables,
      });

      message.success('Success');
      setTimeout(this.unlockRequestButton, 60000);
      return true;
    } catch (err) {
      this.setState({
        inputLock: false,
        requestLock: false,
      });
    }

    return false;
  }

  validateFormFields(err, fieldsToCheck) {
    if (err) {
      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined' && this.input[field] !== null) {
            this.input[field].focus();
          }

          return false;
        }
      }
    }

    return true;
  }

  async requestReset(ev) {
    if (this.cancelLock) {
      return;
    }

    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateRequestReset);
  }

  cleanupBeforeClose() {
    this.setState({
      inputLock: false,
    });
    this.props.form.resetFields();
    this.props.onCancel();
  }

  render() {
    const { form, visible } = this.props;
    const { getFieldDecorator } = form;
    const { inputLock, requestLock } = this.state;

    return (
      <Modal
        title="Reset Admin"
        onOk={this.handleSubmit}
        onCancel={this.cleanupBeforeClose}
        visible={visible}
        okText="Confirm"
        okButtonProps={{
          ghost: true,
        }}
        cancelText="Close"
      >
        <Form>
          <Item label="Login ID" style={{ marginBottom: 10 }}>
            {getFieldDecorator('uid', {
              rules: rules.uid,
            })(
              <Input
                ref={node => (this.input.uid = node)}
                placeholder="Please enter the Login ID"
                autoComplete="off"
                disabled={inputLock}
                style={{ width: 300 }}
              />
            )}
          </Item>

          <Item label="통신사" style={{ marginBottom: 10 }}>
            {getFieldDecorator('carrier', {
              rules: rules.carrier,
            })(
              <Select
                ref={node => (this.input.carrier = node)}
                autoComplete="off"
                disabled={inputLock}
                style={{ width: 300 }}
              >
                <option value="SKT">SKT</option>
                <option value="KTF">KT</option>
                <option value="LGT">LG</option>
                <option value="SKR">SK알뜰폰</option>
                <option value="KTR">KT알뜰폰</option>
                <option value="LGR">LG알뜰폰</option>
              </Select>
            )}
          </Item>

          <Item label="핸드폰 번호" style={{ marginBottom: 10 }}>
            {getFieldDecorator('phone', {
              rules: rules.phone,
            })(
              <Input
                ref={node => (this.input.phone = node)}
                placeholder="Please enter the phone number."
                autoComplete="off"
                disabled={inputLock}
                maxLength={11}
                style={{ width: 300 }}
              />
            )}
          </Item>

          <Item label="생년월일" style={{ marginBottom: 10 }}>
            {getFieldDecorator('birth', {
              rules: rules.birth,
            })(
              <Input
                ref={node => (this.input.birth = node)}
                placeholder="ex. 19900101"
                autoComplete="off"
                disabled={inputLock}
                maxLength={8}
                style={{ width: 300 }}
              />
            )}
          </Item>

          <Item label="이름" style={{ marginBottom: 10 }}>
            {getFieldDecorator('name', {
              rules: rules.name,
            })(
              <Input
                ref={node => (this.input.name = node)}
                placeholder="Please enter the your name"
                autoComplete="off"
                disabled={inputLock}
                style={{ width: 300 }}
              />
            )}
          </Item>

          <Item label="성별" style={{ marginBottom: 10 }}>
            {getFieldDecorator('sex', {
              rules: rules.sex,
              initialValue: 'M',
            })(
              <Radio.Group ref={node => (this.input.sex = node)} style={{ width: 300 }} disabled={inputLock}>
                <Radio.Button value="M">남자</Radio.Button>
                <Radio.Button value="F">여자</Radio.Button>
              </Radio.Group>
            )}
          </Item>

          <Item label="내국인/외국인" style={{ marginBottom: 10 }}>
            {getFieldDecorator('foreigner', {
              rules: rules.foreigner,
              initialValue: 'N',
            })(
              <Radio.Group ref={node => (this.input.foreigner = node)} style={{ width: 300 }} disabled={inputLock}>
                <Radio.Button value="N">내국인</Radio.Button>
                <Radio.Button value="Y">외국인</Radio.Button>
              </Radio.Group>
            )}
          </Item>

          <Item label="인증번호" style={{ marginBottom: 10 }}>
            {getFieldDecorator('code', {
              rules: rules.code,
            })(
              <Input.Search
                ref={node => (this.input.name = node)}
                placeholder="Please enter the Code"
                onSearch={this.requestReset}
                enterButton="인증번호 요청"
                loading={requestLock}
                style={{ width: 300 }}
              />
            )}
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default ResetAdminModal;
