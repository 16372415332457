import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { Form, Input, Button } from 'antd';
import autobind from 'autobind-decorator';
import store from 'store';
import { REGEX_PASSWORD } from 'constant';
import { sanitizePassword } from 'utils/stringUtil';
import { formItemLayout, tailFormItemLayout } from './ProfileFormLayout';
import rules from './ProfileFormValidationRules';
import styles from '../Profile.scss';

const { Item } = Form;

@Form.create()
@autobind
class ProfileForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    form: objectOf(any).isRequired,
  };

  input = {
    currentPassword: null,
    newPassword: null,
    newPasswordConfirm: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();
    this.props.form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      if (err.currentPassword) {
        this.input.currentPassword.focus();
      } else if (err.newPassword) {
        this.input.newPassword.focus();
      } else if (err.newPasswordConfirm) {
        this.input.newPasswordConfirm.focus();
      }

      return;
    }

    const { form } = this.props;

    this.submit(form.getFieldsValue());
  }

  async submit(formData) {
    try {
      await this.props.onSubmit(formData);

      // Reset fields after updated
      this.props.form.setFieldsValue({
        currentPassword: null,
        newPassword: null,
        newPasswordConfirm: null,
      });
    } catch (err) {
      this.input.newPasswordConfirm.focus();
    }
  }

  validatePassword(rule, value, callback) {
    const admin = store.get('admin');
    const { uid } = admin;

    if (value === uid) {
      callback("You can't use ID to password.");
      return;
    }

    // Pattern detects that has letter & digits with special characters at least once.
    if (value && !value.match(REGEX_PASSWORD)) {
      callback('The new password must contain alphabet, numbers and special characters.');
      return;
    }

    callback();
  }

  validatePasswordConfirm(rule, value, callback) {
    const { form } = this.props;

    if (value && form.getFieldValue('newPassword') !== value) {
      callback("Passwords doesn't match.");
    } else {
      callback();
    }
  }

  handlePasswordChange(inputName) {
    const { input } = this.input[inputName];
    const sanitizedPassword = sanitizePassword(input.value);

    input.value = sanitizedPassword;

    // Update ant design input as well
    this.props.form.setFieldsValue({
      [inputName]: sanitizedPassword,
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.profileForm}>
        <Item label="Current Password" {...formItemLayout}>
          {getFieldDecorator('currentPassword', {
            rules: rules.currentPassword,
          })(
            <Input
              ref={node => (this.input.currentPassword = node)}
              type="password"
              onChange={this.handlePasswordChange.bind(null, 'currentPassword')}
            />
          )}
        </Item>

        <Item label="New Password" {...formItemLayout}>
          {getFieldDecorator('newPassword', {
            rules: [
              ...rules.newPassword,
              {
                validator: this.validatePassword,
              },
            ],
          })(
            <Input
              ref={node => (this.input.newPassword = node)}
              type="password"
              onChange={this.handlePasswordChange.bind(null, 'newPassword')}
            />
          )}
        </Item>

        <Item label="Retype New Password" {...formItemLayout}>
          {getFieldDecorator('newPasswordConfirm', {
            rules: [
              ...rules.newPasswordConfirm,
              {
                validator: this.validatePasswordConfirm,
              },
            ],
          })(
            <Input
              ref={node => (this.input.newPasswordConfirm = node)}
              type="password"
              onChange={this.handlePasswordChange.bind(null, 'newPasswordConfirm')}
            />
          )}
        </Item>

        <Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Item>
      </Form>
    );
  }
}

export default ProfileForm;
