import React from 'react';
import LinkText from 'components/LinkText';

export const columns = [
  {
    title: 'Brand ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Brand Name',
    dataIndex: 'name',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Brand Color',
    dataIndex: 'color',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '20%',
  },
  {
    title: 'Preview',
    render: function renderPreview(datas) {
      return (
        <div style={{ backgroundColor: datas.color, height: 250, width: 200, padding: 20 }}>
          <div
            style={{
              backgroundImage: `url(${datas.logoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              height: 100,
            }}
          />
          <div
            style={{
              backgroundImage: `url(${datas.typoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              marginTop: 15,
              height: 100,
            }}
          />
        </div>
      );
    },
    width: '50%',
  },
];

export default {
  columns,
};
