import apolloClient from 'helpers/apolloClient';
import { businessCodeListQuery } from './queries';

export const FETCH_BUSINESS_CODE = 'businessCode/FETCH';
export const FETCH_BUSINESS_CODE_SUCCESS = 'businessCode/FETCH_SUCCESS';
export const FETCH_BUSINESS_CODE_FAILURE = 'businessCode/FETCH_FAILURE';

export function getBusinessCode() {
  return {
    types: [FETCH_BUSINESS_CODE, FETCH_BUSINESS_CODE_SUCCESS, FETCH_BUSINESS_CODE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: businessCodeListQuery,
        context: {
          cardGraph: true,
        },
        variables: { typeCode: 'MERCHANT', groupCode: 'BUSINESS', codeName: '' },
      });

      return result.data.selectCommonCodeInfoList;
    },
  };
}
