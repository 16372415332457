import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon } from 'antd';

import {
  addBoltExclusionRule,
  updateBoltExclusionRule,
  getBoltExclusionRules,
} from 'redux/modules/boltExclusionRule/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';

import { boltExclusionRuleQuery } from 'redux/modules/boltExclusionRule/queries';
import styles from './BoltExclusionRule.scss';
import { BoostSelectForm, BoostTextForm, BoostNumberForm } from '../Forms';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boltExclusionRule;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBoltExclusionRule,
  updateBoltExclusionRule,
  getBoltExclusionRules,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoltExclusionRuleForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    addBoltExclusionRule: PropTypes.func.isRequired,
    updateBoltExclusionRule: PropTypes.func.isRequired,
    getBoltExclusionRules: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    // BoltExclusionRule XHR Status
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boltExclusionRule', id ? id.toString() : 'add');
    this.props.push('/boost/bolt_exclusion_rule');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id, form } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: boltExclusionRuleQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.boltExclusionRule;

      form.setFieldsValue({
        type: data.type,
      });

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid BoltExclusionRule ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get BoltExclusionRule: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = ['limit'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field]) {
          if (instance && typeof instance.input !== 'undefined') {
            instance.input.focus();
          } else if (instance && typeof instance.picker !== 'undefined') {
            instance.picker.input.focus();
          } else if (instance && typeof instance.inputNumberRef !== 'undefined') {
            instance.inputNumberRef.input.focus();
          }
          return;
        }
      }
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.policyType = formFields.policyType;
    fields.receiverType = formFields.receiverType;
    fields.codeType = formFields.codeType;
    fields.code = formFields.code || '';
    fields.period = formFields.period;
    fields.limit = formFields.limit;
    fields.maxBoltCount = formFields.maxBoltCount || null;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoltExclusionRule(input);

    this.props.removeAndFocusPreviousTab('boltExclusionRule', 'add');
    this.props.push('/boost/bolt_exclusion_rule');
    this.refreshBoltExclusionRuleList();
  }

  async update(input) {
    await this.props.updateBoltExclusionRule(input);

    this.props.removeAndFocusPreviousTab('boltExclusionRule', input.id.toString());
    this.props.push('/boost/bolt_exclusion_rule');
    this.refreshBoltExclusionRuleList();
  }

  refreshBoltExclusionRuleList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoltExclusionRules(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    const codeType = form.getFieldValue('codeType') || data.codeType;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
        <BoostSelectForm
          form={form}
          label="Policy Type"
          name="policyType"
          initial={data.policyType ? data.policyType : 'payment'}
          items={[{ label: 'Payment', value: 'payment' }]}
        />
        <BoostSelectForm
          form={form}
          label="Receiver Type"
          name="receiverType"
          initial={data.receiverType ? data.receiverType : 'user'}
          items={[{ label: 'User', value: 'user' }]}
        />
        <BoostSelectForm
          form={form}
          label="Code Type"
          name="codeType"
          initial={data.codeType ? data.codeType : 'businessCode'}
          items={[
            { label: '차이 가맹점 ID', value: 'chaiMerchantId' },
            { label: '차이 가맹점 그룹', value: 'chaiMerchantGroupName' },
            { label: '업종코드', value: 'businessCode' },
            { label: '업종명', value: 'businessName' },
            { label: '가맹점명', value: 'cardMerchantName' },
            { label: '가맹점 코드', value: 'cardMerchantId' },
            { label: 'pid', value: 'pid' },
            { label: '동일 가맹점', value: 'sameMerchant' },
          ]}
        />
        {codeType !== 'sameMerchant' && <BoostTextForm form={form} label="Code" name="code" initial={data.code} />}
        <BoostSelectForm
          form={form}
          label="Period"
          name="period"
          initial={data.period ? data.period : 'lifetime'}
          items={[
            { label: '전체', value: 'lifetime' },
            { label: '1시간', value: 'hour' },
            { label: '일 (당일)', value: 'day' },
            { label: '주 (같은 주)', value: 'week' },
            { label: '월 (같은 달)', value: 'month' },
          ]}
        />
        <BoostNumberForm form={form} label="Limit" name="limit" initial={data.limit} require />
        <BoostNumberForm form={form} label="1회 최대 번개수" name="maxBoltCount" initial={data.maxBoltCount} />

        <div className={styles.formButtonContainer}>
          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />
          {id && (
            <Button type="danger" onClick={this.closeTab}>
              Deletes
            </Button>
          )}
          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit" disabled={updating}>
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default BoltExclusionRuleForm;
