import apolloClient from 'helpers/apolloClient';
import { noticeListQuery } from './queries';
import { noticeAddMutation, noticeUpdateMutation, noticeRemoveMutation } from './mutations';

export const FETCH_NOTICE = 'notice/FETCH';
export const FETCH_NOTICE_SUCCESS = 'notice/FETCH_SUCCESS';
export const FETCH_NOTICE_FAILURE = 'notice/FETCH_FAILURE';

export const ADD_NOTICE = 'notice/ADD';
export const ADD_NOTICE_SUCCESS = 'notice/ADD_SUCCESS';
export const ADD_NOTICE_FAILURE = 'notice/ADD_FAILURE';

export const UPDATE_NOTICE = 'notice/UPDATE';
export const UPDATE_NOTICE_SUCCESS = 'notice/UPDATE_SUCCESS';
export const UPDATE_NOTICE_FAILURE = 'notice/UPDATE_FAILURE';

export const REMOVE_NOTICE = 'notice/REMOVE';
export const REMOVE_NOTICE_SUCCESS = 'notice/REMOVE_SUCCESS';
export const REMOVE_NOTICE_FAILURE = 'notice/REMOVE_FAILURE';

export const SET_FILTER = 'notice/SET_FILTER';
export const RESET_FILTER = 'notice/RESET_FILTER';

export const SET_CURRENT_PAGE = 'notice/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'notice/SET_PAGE_SIZE';

export function getNotices(currentPage, pageSize) {
  return {
    types: [FETCH_NOTICE, FETCH_NOTICE_SUCCESS, FETCH_NOTICE_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: noticeListQuery,
        variables: {
          skip,
          pageSize,
        },
      });

      return result.data.noticeList;
    },
  };
}

export function addNotice(notice) {
  return {
    types: [ADD_NOTICE, ADD_NOTICE_SUCCESS, ADD_NOTICE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: noticeAddMutation,
        variables: notice,
      });

      return result.data.noticeAdd;
    },
  };
}

export function updateNotice(notice) {
  return {
    types: [UPDATE_NOTICE, UPDATE_NOTICE_SUCCESS, UPDATE_NOTICE_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: noticeUpdateMutation,
        variables: notice,
      });

      return notice;
    },
  };
}

export function removeNotice(id) {
  return {
    types: [REMOVE_NOTICE, REMOVE_NOTICE_SUCCESS, REMOVE_NOTICE_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: noticeRemoveMutation,
        variables: {
          id,
        },
      });

      return id;
    },
  };
}

export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
