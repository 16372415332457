/* eslint-disable */
export function addVisibilityChangeListener(onVisibilityChange) {
  var hidden = 'hidden';

  // Standards:
  if (typeof document.hidden !== undefined) document.addEventListener('visibilitychange', onchange);
  else if (typeof document.mozHidden !== undefined) {
    document.addEventListener('mozvisibilitychange', onchange);
    hidden = 'mozHidden';
  } else if (typeof document.webkitHidden !== undefined) {
    document.addEventListener('webkitvisibilitychange', onchange);
    hidden = 'webkitHidden';
  } else if (typeof document.msHidden !== undefined) {
    document.addEventListener('msvisibilitychange', onchange);
    hidden = 'msHidden';
  } else if (document.onfocusin)
    // IE 9 and lower:
    document.onfocusin = document.onfocusout = onchange;
  // All others:
  else window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;

  function onchange(evt) {
    var v = 'visible',
      h = 'hidden',
      evtMap = {
        focus: v,
        focusin: v,
        pageshow: v,
        blur: h,
        focusout: h,
        pagehide: h,
      };

    evt = evt || window.event;
    if (evt.type in evtMap) onVisibilityChange(evtMap[evt.type]);
    else onVisibilityChange(this[hidden] ? 'hidden' : 'visible');
  }

  // set the initial state (but only if browser supports the Page Visibility API)
  // if( document[hidden] !== undefined )
  //   onchange({type: document[hidden] ? "blur" : "focus"});
}

// window.requestAnimFrame = (callback) => {
//   const fn = () => { window.setTimeout(callback, 1000 / 60); };

//   return window.requestAnimationFrame ||
//   window.webkitRequestAnimationFrame ||
//   window.mozRequestAnimationFrame ||
//   window.oRequestAnimationFrame ||
//   window.msRequestAnimationFrame ||
//   fn;
// };
function easingPattern(type, time) {
  let timeCalc = time;
  if (type === 'easeInQuad') return timeCalc * timeCalc; // accelerating from zero velocity
  if (type === 'easeOutQuad') return timeCalc * (2 - timeCalc); // decelerating to zero velocity
  if (type === 'easeInOutQuad') return timeCalc < 0.5 ? 2 * timeCalc * timeCalc : -1 + (4 - 2 * timeCalc) * timeCalc; // acceleration until halfway, then deceleration
  if (type === 'easeInCubic') return timeCalc * timeCalc * timeCalc; // accelerating from zero velocity
  if (type === 'easeOutCubic') return --timeCalc * timeCalc * timeCalc + 1; // decelerating to zero velocity
  if (type === 'easeInOutCubic')
    return timeCalc < 0.5
      ? 4 * timeCalc * timeCalc * timeCalc
      : (timeCalc - 1) * (2 * timeCalc - 2) * (2 * timeCalc - 2) + 1; // acceleration until halfway, then deceleration
  if (type === 'easeInQuart') return timeCalc * timeCalc * timeCalc * timeCalc; // accelerating from zero velocity
  if (type === 'easeOutQuart') return 1 - --timeCalc * timeCalc * timeCalc * timeCalc; // decelerating to zero velocity
  if (type === 'easeInOutQuart')
    return timeCalc < 0.5
      ? 8 * timeCalc * timeCalc * timeCalc * timeCalc
      : 1 - 8 * --timeCalc * timeCalc * timeCalc * timeCalc; // acceleration until halfway, then deceleration
  if (type === 'easeInQuint') return timeCalc * timeCalc * timeCalc * timeCalc * timeCalc; // accelerating from zero velocity
  if (type === 'easeOutQuint') return 1 + --timeCalc * timeCalc * timeCalc * timeCalc * timeCalc; // decelerating to zero velocity
  if (type === 'easeInOutQuint')
    return timeCalc < 0.5
      ? 16 * timeCalc * timeCalc * timeCalc * timeCalc * timeCalc
      : 1 + 16 * --timeCalc * timeCalc * timeCalc * timeCalc * timeCalc; // acceleration until halfway, then deceleration
  return timeCalc; // no easing, no acceleration
}
/* eslint-enable */

export function setScrollAnim(element, easing, speed, pos = 0) {
  let timeLapsed = 0;
  const startLocation = element.scrollTop;
  const distance = pos - element.scrollTop;

  function step() {
    setTimeout(() => {
      timeLapsed += 15;
      const goal = Math.min(1, timeLapsed / parseInt(speed, 10));
      element.scrollTop = Math.max(Math.floor(startLocation + distance * easingPattern(easing, goal)), 0);

      if (goal < 1) requestAnimationFrame(step);
    }, 15);
  }
  requestAnimationFrame(step);
}

export function setScrollTop(top = 0, event) {
  if (event && typeof event.stopPropagation === 'function') event.stopPropagation();
  if (document.documentElement.scrollTop > 0) {
    document.documentElement.scrollTop = top;
  } else {
    document.body.scrollTop = top;
  }
}

export function slideElement(element, type, startY, endY, easing, speed, finishCallback) {
  const distance = endY - startY;
  // const startTime = new Date();
  let timeLapsed = 0;
  element.style[type] = `${startY}px`; // eslint-disable-line no-param-reassign

  function step() {
    setTimeout(() => {
      timeLapsed += 15;
      const goal = Math.min(1, timeLapsed / parseInt(speed, 10));
      // const goal = Math.min(1, ( ((new Date()) - startTime) / parseInt(speed, 10) ));
      element.style[type] = `${Math.floor(startY + distance * easingPattern(easing, goal))}px`;

      if (goal < 1) {
        requestAnimationFrame(step);
      } else if (finishCallback) {
        finishCallback();
      }
    }, 15);
  }
  requestAnimationFrame(step);
}

export function bindReady(handler) {
  let called = false;
  const ready = () => {
    if (called) return;
    called = true;
    handler();
  };

  if (document.addEventListener) {
    // native event
    document.addEventListener('DOMContentLoaded', ready, false);
  } else if (document.attachEvent) {
    // IE
    document.attachEvent('onreadystatechange', () => {
      if (document.readyState === 'complete') {
        ready();
      }
    });
  }

  // Old browsers
  if (window.addEventListener) window.addEventListener('load', ready, false);
  else if (window.attachEvent) window.attachEvent('onload', ready);
}

export function attachEvent(element, eventName, cb, isCapture) {
  if (window.addEventListener) {
    element.addEventListener(eventName, cb, isCapture);
  } else if (window.attachEvent) {
    element.attachEvent(`on${eventName}`, cb, isCapture);
  } else {
    element[`on${eventName}`] = cb;
  }
}

export function detachEvent(element, eventName, cb) {
  if (window.removeEventListener) {
    element.removeEventListener(eventName, cb);
  } else if (window.detachEvent) {
    element.detachEvent(`on${eventName}`, cb);
  } else {
    element[`on${eventName}`] = undefined;
  }
}

// function stopEvent(enableClasses) {
//   if (enableClasses.indexOf(event.target.className) > -1) {
//     return;
//   }

//   event.preventDefault();
// }

export function stopBodyScroll(stop, cb) {
  if (!cb) return;

  if (stop) {
    // document.body.addEventListener('touchmove', stopEvent.bind(null, enableClasses), false);
    document.body.addEventListener('touchmove', cb, false);
    document.body.addEventListener('scroll', cb, false);
    // document.body.style.overflow = 'hidden';
  } else {
    // document.body.removeEventListener('touchmove', stopEvent.bind(null, enableClasses), false);
    document.body.removeEventListener('touchmove', cb, false);
    document.body.removeEventListener('scroll', cb, false);
    // document.body.style.overflow = '';
  }
}

export function topPosition(domElt) {
  if (!domElt) {
    return 0;
  }
  return domElt.offsetTop + topPosition(domElt.offsetParent);
}

export function getScrollTop() {
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
  const scrollTop = window.pageYOffset || (isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop);
  return scrollTop;
}

export function getScrollSize() {
  return {
    width: Math.max(document.documentElement.scrollWidth, document.body.scrollWidth),
    height: Math.max(document.documentElement.scrollHeight, document.body.scrollHeight),
  };
}

export function getScreenSize() {
  return {
    width: Math.max(document.documentElement.offsetWidth, document.body.offsetWidth),
    height: Math.max(document.documentElement.offsetHeight, document.body.offsetHeight),
  };
}

export function setUserScalable(value) {
  const scalableContent = `user-scalable=${value}`;
  let viewport = document.querySelector('meta[name=viewport]');

  if (viewport) {
    const content = viewport.getAttribute('content');

    if (content) {
      const regExp = /user-scalable=([a-zA-Z0-9]+)/;
      const result = regExp.exec(content);

      if (result) {
        viewport.setAttribute('content', content.replace(regExp, scalableContent));
        return result[1];
      }
    }

    viewport.setAttribute('content', scalableContent);
    return '0';
  }

  viewport = document.createElement('meta');
  viewport.setAttribute('name', 'viewport');
  viewport.setAttribute('content', scalableContent);
  document.getElementsByTagName('head')[0].appendChild(viewport);
  return '0';
}

export function getWindowWidth() {
  return __SERVER__ ? 640 : window.innerWidth || document.documentElement.clientWidth;
}

export function getWindowHeight() {
  return __SERVER__ ? 1024 : window.innerHeight || document.documentElement.clientHeight;
}

export function fireEvent(obj, evt) {
  const fireOnThis = obj;

  if (document.createEvent) {
    const evObj = document.createEvent('MouseEvents');
    evObj.initEvent(evt, true, false);
    fireOnThis.dispatchEvent(evObj);
  } else if (document.createEventObject) {
    const evObj = document.createEventObject();
    fireOnThis.fireEvent(`on${evt}`, evObj);
  }
}

export function isBotBrowser() {
  return (
    window.navigator &&
    typeof window.navigator.userAgent === 'string' &&
    window.navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1
  );
}

export function getDecodePathname(pathname) {
  if (pathname === '/') return '/home';

  return decodeURI(pathname);
}

export function loadScript(parent, src, onLoad = null, onError = null) {
  const script = document.createElement('script');
  script.onload = onLoad;
  script.onerror = onError;
  script.onreadystatechange = () => {
    if (script.readyState === 'loaded' || script.readyState === 'complete') {
      script.onload();
    }
  };

  script.setAttribute('src', src);
  parent.appendChild(script);
  return script;
}

// /userinfo/2144/id => ['/userinfo','/useinfo/2144,'/userindo/2144/id']
export function urlToList(url) {
  const urllist = url.split('/').filter(i => i);
  return urllist.map((urlItem, index) => `/${urllist.slice(0, index + 1).join('/')}`);
}

// eslint-disable-next-line
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}
