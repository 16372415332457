import React from 'react';
import { Button } from 'antd';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import LinkText from 'components/LinkText';
import { showPGResultModal } from '../Modals';
import styles from './TransactionLog.scss';

/**
 * Get columns for Transaction Log table
 * @param {Object} options Options when getting columns.
 * @param {bool} options.renderOwner Sets true, render owner. Default is false.
 */
export function getColumns(options) {
  let columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render(val) {
        return <span>{startCase(val)}</span>;
      },
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      render(val) {
        return <span>{val.toUpperCase()}</span>;
      },
    },
    {
      title: 'Amount',
      dataIndex: 'delta',
      key: 'delta',
      render(val) {
        const symbol = val >= 0 ? '+' : '-';
        const color = symbol === '+' ? '#0eb53d' : '#e02323';
        return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render(val) {
        return <span>{formatDate(val)}</span>;
      },
      width: 250,
    },
    {
      title: 'PG Result',
      dataIndex: 'data',
      key: 'data',
      render(val, others) {
        return (
          <div className={styles.pgResultContainer} onClick={showPGResultModal.bind(null, val, others)}>
            <LinkText>{get(val, 'pgResult.message', '-')}</LinkText>
            <Button size="small" shape="circle" icon="right" style={{ marginLeft: 10 }} />
          </div>
        );
      },
      width: 150,
    },
  ];

  if (options.renderOwner) {
    const [firstElem, ...remainElems] = columns;

    columns = [
      firstElem,
      {
        title: 'Owner',
        render: function renderOwner(others) {
          const { ledger } = others;
          const merchant = get(ledger, 'merchant');
          const user = get(ledger, 'user');

          if (merchant) {
            return <span>{merchant.displayName}</span>;
          } else if (user) {
            return <span>{user.fullname}</span>;
          }

          return <span>Chai</span>;
        },
      },
      ...remainElems,
    ];
  }

  return columns;
}

export default {
  getColumns,
};
