import gql from 'graphql-tag';

export const b2bUserListQuery = gql`
  query b2bUserList($filter: String, $skip: Int, $pageSize: Int) {
    b2bUserList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        loginId
        depositCode
        transactionKey
        data {
          permittedMerchantUserIds
        }
        user {
          status
          ledger {
            point
            cash
          }
        }
      }
      total
    }
  }
`;

export default {
  b2bUserListQuery,
};
