import React, { Component } from 'react';
import { shape, string, objectOf, func, any } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import store from 'store';
import { Form, message } from 'antd';
import get from 'lodash/get';
import apolloClient from 'helpers/apolloClient';
import { GET_ACC_MENU_LIST } from 'gql';
import { updateAccessMenu, updateMenuMapToRouter } from 'redux/modules/global';
import { removeAll } from 'redux/modules/tabs';
import { resetFilter as resetMerchantFilter } from 'redux/modules/merchant/actions';
import { resetFilter as resetPaymentFilter, reset as resetPayment } from 'redux/modules/payment/actions';
import { resetFilter as resetPolicyFilter } from 'redux/modules/policy/actions';
import { resetFilter as resetPromotionFilter } from 'redux/modules/promotion/actions';
import { resetFilter as resetSettlementFilter } from 'redux/modules/settlement/actions';
import { resetFilter as resetSettlementPaymentFilter } from 'redux/modules/settlementPayment/actions';
import { resetFilter as resetTransactionFilter } from 'redux/modules/transaction/actions';
import { resetFilter as resetUserFilter, reset as resetUser } from 'redux/modules/user/actions';
import { openAccessedMenuFunc } from 'utils/permission';
import logo from 'shared/logo.png';
import { getMenuMapRouterQuery } from './LoginQueries';
import {
  requestMutation,
  confirmMutation,
  authMutation,
  updatePasswordMutation,
  confirmResetMutation,
} from './LoginMutations';
import LoginForm from './Forms/LoginForm';
import LoginPasswordUpdateForm from './Forms/LoginPasswordUpdateForm';
import ResetAdminModal from './Modals/ResetAdminModal';
import styles from './Login.less';

const mapStateToProps = state => ({
  user: state.auth.user,
});

const mapDispatchToProps = {
  push,
  updateAccessMenu,
  updateMenuMapToRouter,
  removeAll,
  resetMerchantFilter,
  resetPaymentFilter,
  resetPolicyFilter,
  resetPromotionFilter,
  resetSettlementFilter,
  resetSettlementPaymentFilter,
  resetTransactionFilter,
  resetUserFilter,
  resetPayment,
  resetUser,
};

@connect(mapStateToProps, mapDispatchToProps)
@Form.create()
@autobind
class LoginPage extends Component {
  static propTypes = {
    location: shape({
      pathname: string.isRequired,
      search: string,
      action: string,
    }).isRequired,
    push: func.isRequired,
    updateAccessMenu: func.isRequired,
    updateMenuMapToRouter: func.isRequired,
    removeAll: func.isRequired,
    resetMerchantFilter: func.isRequired,
    resetPaymentFilter: func.isRequired,
    resetPolicyFilter: func.isRequired,
    resetPromotionFilter: func.isRequired,
    resetSettlementFilter: func.isRequired,
    resetSettlementPaymentFilter: func.isRequired,
    resetTransactionFilter: func.isRequired,
    resetUserFilter: func.isRequired,
    resetPayment: func.isRequired,
    resetUser: func.isRequired,
    user: objectOf(any),
  };

  static defaultProps = {
    user: null,
  };

  state = {
    showPasswordResetForm: false,
    requestingOTP: false,
    resetAdminModalVisible: false,
    loginFailed: false,
  };

  componentDidMount() {
    if (this.props.user) {
      const params = new URLSearchParams(this.props.location.search);
      this.props.push(params.get('redirect'));
    }
  }

  previousAdminID = null;

  showResetAdminModal() {
    this.setState({
      resetAdminModalVisible: true,
    });
  }

  hideResetAdminModal() {
    this.setState({
      resetAdminModalVisible: false,
    });
  }
  /**
   * Request OTP
   * @param {string} id
   * @param {string} password
   * @return {Promise<string|Error>} - Returns code on success
   */
  async requestOTP(id, password) {
    try {
      this.setState({ requestingOTP: true });

      const {
        data: { request: code },
      } = await apolloClient.mutate({
        mutation: requestMutation,
        variables: {
          id,
          password,
        },
      });
      this.setState({ loginFailed: false });
      return code;
    } catch (err) {
      const errorCode = get(err.graphQLErrors[0], 'extensions.exception.code');
      this.setState({ loginFailed: true });
      // Throw error only there is no error code. Sentry will caught this error and will report.
      if (!errorCode) {
        throw err;
      }

      return null;
    } finally {
      this.setState({ requestingOTP: false });
    }
  }

  /**
   * Try Login
   * @param {string} id
   * @param {string} code
   * @return {Promise<void|Error>}
   */
  async login(id, password, code) {
    try {
      const urlParams = new URLSearchParams(this.props.location.search);

      const {
        data: { confirm },
      } = await apolloClient.mutate({
        mutation: confirmMutation,
        variables: {
          id,
          password,
          code,
        },
      });

      const publicKey = urlParams.get('publicKey');
      const {
        data: { auth },
        error,
      } = await apolloClient.mutate({
        mutation: authMutation,
        variables: {
          publicKey,
        },
      });

      if (confirm === 'ok' && !error) {
        store.set('admin', auth);
        store.set('lastLoggedInUserId', auth.id);
        this.setState({ loginFailed: false });
        this.previousAdminID = auth.id;

        const { passwordReset } = auth;

        if (passwordReset) {
          this.setState({ showPasswordResetForm: true });
        } else {
          this.loadResourcesAndRedirect(auth.token);
        }
      }
    } catch (err) {
      const errorCode = get(err.graphQLErrors[0], 'extensions.exception.code');
      this.setState({ loginFailed: true });
      // Throw error only there is no error code. Sentry will caught this error and will report.
      if (!errorCode) {
        throw err;
      }
    }
  }

  async loadResourcesAndRedirect(token) {
    const { data } = await apolloClient.query({
      query: GET_ACC_MENU_LIST,
    });
    const { getAccessMenus: menuRes } = data;
    const openAccessedMenu = openAccessedMenuFunc(menuRes);

    this.props.updateAccessMenu({
      accessMenu: menuRes.data,
      openAccessMenu: openAccessedMenu,
    });

    const {
      data: { getMenuMapToRouter: menuMapToRouter },
    } = await apolloClient.query({
      query: getMenuMapRouterQuery,
    });

    this.props.updateMenuMapToRouter(menuMapToRouter);

    const urlParams = new URLSearchParams(this.props.location.search);
    const redirectPath = urlParams.get('redirect');
    let shouldRedirect = true;

    const previousUserId = store.get('lastLoggedInUserId');

    // Remove All Tabs if another user logged in
    // Also set redirect path to first accessible menu
    if (previousUserId !== this.previousAdminID) {
      this.props.removeAll();
      shouldRedirect = false;

      this.resetFormFilters();
    }

    // If redirect path exists in URL params, move to there
    if (shouldRedirect && redirectPath) {
      // to other domain
      if (
        /^http(?:s)?:\/\/([\w\d\-]+\.)?chai\.finance/.test(redirectPath) ||
        /^http(?:s)?:\/\/([\w\d\-\.]+\.)?chaicloud\.io/.test(redirectPath)
      ) {
        if (token) {
          return window.location.replace(`${redirectPath}?token=${token}`);
        }
        return window.location.replace(redirectPath);
      }
      // to relative path
      if (/^\/[\w\d\-\/]+/.test(redirectPath)) {
        return this.props.push(redirectPath);
      }
    }

    return this.props.push('/');
  }

  resetFormFilters() {
    this.props.resetMerchantFilter();
    this.props.resetPaymentFilter();
    this.props.resetPolicyFilter();
    this.props.resetPromotionFilter();
    this.props.resetSettlementFilter();
    this.props.resetSettlementPaymentFilter();
    this.props.resetTransactionFilter();
    this.props.resetUserFilter();
    this.props.resetPayment();
    this.props.resetUser();
  }

  async confirmReset(variables) {
    try {
      await apolloClient.mutate({
        mutation: confirmResetMutation,
        variables,
      });

      message.success('Success');
      return true;
    } catch (err) {
      message.warning('Failed to reset Admin.');
    }

    return false;
  }

  /**
   * Update Password
   * @async
   * @param {Object} formData
   * @param {string} formData.currentPassword
   * @param {string} formData.newPassword
   */
  async updatePassword(formData) {
    try {
      const admin = store.get('admin');

      if (!admin) {
        return;
      }

      const { currentPassword: password, newPassword } = formData;

      await apolloClient.mutate({
        mutation: updatePasswordMutation,
        variables: {
          password,
          newPassword,
        },
      });

      message.success('Password updated.');
      this.loadResourcesAndRedirect();
    } catch (err) {
      message.error('Update failed.');
      console.log(err);
    }
  }

  renderLoginPasswordUpdateForm() {
    return <LoginPasswordUpdateForm onSubmit={this.updatePassword} />;
  }

  render() {
    const { showPasswordResetForm, requestingOTP, resetAdminModalVisible, loginFailed } = this.state;

    if (showPasswordResetForm) {
      return this.renderLoginPasswordUpdateForm();
    }

    return (
      <>
        <>
          <ResetAdminModal
            onOk={this.confirmReset}
            onCancel={this.hideResetAdminModal}
            visible={resetAdminModalVisible}
          />
        </>

        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.header}>
                <Link to="/" className={styles.logoContainer}>
                  <img alt="logo" className={styles.logo} src={logo} />
                  <span className={styles.title}>Chai Admin</span>
                </Link>
              </div>
            </div>
            <div className={styles.main}>
              <div className={styles.login}>
                <LoginForm onRequestOTP={this.requestOTP} onLogin={this.login} disableRequestOTP={requestingOTP} />
                <a href="#" onClick={this.showResetAdminModal} className={loginFailed ? styles.bounce : null}>
                  Can&apos;t log in?
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LoginPage;
