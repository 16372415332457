import gql from 'graphql-tag';

export const bankAddMutation = gql`
  mutation bankAdd(
    $name: String!
    $shortName: String!
    $logoUrl: String!
    $code: String!
    $bankCode: String!
    $isActive: Boolean!
    $isRegistrable: Boolean!
    $sortedIndex: Int!
  ) {
    bankAdd(
      name: $name
      shortName: $shortName
      code: $code
      bankCode: $bankCode
      logoUrl: $logoUrl
      isActive: $isActive
      isRegistrable: $isRegistrable
      sortedIndex: $sortedIndex
    )
  }
`;

export const bankUpdateMutation = gql`
  mutation bankUpdate(
    $id: Int!
    $name: String!
    $shortName: String!
    $code: String!
    $bankCode: String!
    $logoUrl: String!
    $isActive: Boolean!
    $isRegistrable: Boolean!
    $sortedIndex: Int!
  ) {
    bankUpdate(
      id: $id
      name: $name
      shortName: $shortName
      code: $code
      bankCode: $bankCode
      logoUrl: $logoUrl
      isActive: $isActive
      isRegistrable: $isRegistrable
      sortedIndex: $sortedIndex
    )
  }
`;

export const bankUploadImage = gql`
  mutation bankUploadImage($imageFile: FileUpload!, $fileName: String) {
    bankUploadImage(imageFile: $imageFile, fileName: $fileName) {
      filename
      path
    }
  }
`;

export default {
  bankAddMutation,
  bankUpdateMutation,
  bankUploadImage,
};
