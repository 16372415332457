import React from 'react';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';

const getStatus = status =>
  ({
    enabled: 'success',
    disabled: 'error',
  }[status]);

const columns = [
  {
    title: 'Tag ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <span>{val}</span>;
    },
    width: '15%',
    align: 'center',
  },
  {
    title: 'Tag Name',
    dataIndex: 'name',
    render: function renderName(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '30%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </span>
      );
    },
    width: '19%',
    align: 'center',
  },
  {
    title: 'CreatedAt',
    dataIndex: 'dateHistory.createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '18%',
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'dateHistory.updatedAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '18%',
  },
];

export default columns;
