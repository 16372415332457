import React, { Component } from 'react';
import { func, objectOf, any, string, bool } from 'prop-types';
import { Form, Input, Icon, Button, Row, Col, Alert, message } from 'antd';
import autobind from 'autobind-decorator';
import { sanitizePassword } from 'utils/stringUtil';
import rules from './LoginFormValidationRules';
import styles from './LoginForm.less';

const FormItem = Form.Item;

@Form.create()
@autobind
class LoginForm extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    error: string,
    onRequestOTP: func.isRequired,
    onLogin: func.isRequired,
    disableRequestOTP: bool.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    maskPassword: true,
  };

  componentDidMount() {
    this.input.id.focus();
  }

  input = {
    id: null,
    password: null,
    pinNumber: null,
  };

  /**
   * Prevent default submit behaviour of Web Form and do proper process.
   * @param {KeyboardEvent} ev
   */
  handleSubmit(ev) {
    ev.preventDefault();
  }

  /**
   * On pressing enter in ID field, move to password field
   * @param {KeyboardEvent} ev
   */
  handleIDKeyDown(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault();

      this.props.form.validateFields(['id'], { force: true }, err => {
        if (!err) {
          this.input.password.focus();
        }
      });
    }
  }

  /**
   * On pressing enter in Password field, request OTP
   * @param {KeyboardEvent} ev
   */
  handlePasswordKeydown(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      this.validateOTPRequest();
    }
  }

  /**
   * On key up, check password value and rid off none english/number/special chars
   */
  handlePasswordChange() {
    const password = this.input.password.input;
    const sanitizedPassword = sanitizePassword(password.value);

    password.value = sanitizedPassword;

    // Update ant design input as well
    this.props.form.setFieldsValue({
      password: sanitizedPassword,
    });
  }

  /**
   * Validate form data for OTP Request and send receive code from request
   */
  validateOTPRequest() {
    const { disableRequestOTP } = this.props;

    if (disableRequestOTP) {
      return;
    }

    const activeFields = ['id', 'password'];

    this.props.form.validateFields(activeFields, { force: true }, async (validationErrors, values) => {
      if (validationErrors && validationErrors.id) {
        this.input.id.focus();
        return;
      } else if (validationErrors && validationErrors.password) {
        this.input.password.focus();
        return;
      }

      const { id, password } = values;

      if (!id) {
        message.warning('Please enter the id.');
      } else if (!password) {
        message.warning('Please enter the id.');
      }

      const code = await this.props.onRequestOTP(id, password);

      if (code) {
        this.props.form.setFieldsValue({ code });
      }

      this.input.pinNumber.focus();
    });
  }

  /**
   * On pressing enter in Pin Code, try login
   * @param {KeyboardEvent} ev
   */
  handlePinCodeKeyDown(ev) {
    if (ev.keyCode === 13) {
      ev.preventDefault();
      this.validateLogin();
    }
  }

  /**
   * Validate form data for Login and send login request
   */
  validateLogin() {
    const activeFields = ['id', 'code', 'password'];

    this.props.form.validateFields(activeFields, { force: true }, async (validationErrors, values) => {
      if (validationErrors && validationErrors.id) {
        this.input.id.focus();
        return;
      } else if (validationErrors && validationErrors.id) {
        this.input.id.focus();
        return;
      }

      const { id, code, password } = values;

      if (!id) {
        message.warning('Please enter the id.');
        return;
      } else if (!code) {
        message.warning('Please enter the code.');
        return;
      }

      try {
        await this.props.onLogin(id, password, code);
      } catch (err) {
        console.error(err);
        this.input.pinNumber.focus();
      }
    });
  }

  toggleMaskPassword() {
    this.setState(
      {
        maskPassword: !this.state.maskPassword,
      },
      () => this.input.password.focus()
    );
  }

  renderMessage(content) {
    return <Alert style={{ marginBottom: 24 }} message={content} type="error" showIcon />;
  }

  render() {
    const { error, disableRequestOTP, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const { maskPassword } = this.state;
    const password = getFieldValue('password');
    const code = getFieldValue('code');

    return (
      <Form onSubmit={this.handleSubmit}>
        {error && this.renderMessage('Wrong ID or Password.')}
        <Row>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator('id', {
                rules: rules.id,
              })(
                <Input
                  autoComplete="off"
                  ref={node => (this.input.id = node)}
                  size="large"
                  prefix={<Icon type="user" className={styles.prefixIcon} />}
                  placeholder="ID"
                  onKeyDown={this.handleIDKeyDown}
                />
              )}
            </FormItem>
          </Col>
          <Col span={7} /> {/* Space between input and button */}
        </Row>
        <Row>
          <Col span={24}>
            <FormItem>
              {getFieldDecorator('password', {
                rules: rules.password,
              })(
                <Input
                  className={styles.passwordInput}
                  autoComplete="off"
                  type={maskPassword ? 'password' : 'text'}
                  ref={node => (this.input.password = node)}
                  size="large"
                  prefix={<Icon type="lock" className={styles.prefixIcon} />}
                  placeholder="Password"
                  onKeyDown={this.handlePasswordKeydown}
                  onChange={this.handlePasswordChange}
                />
              )}
            </FormItem>

            <Icon
              type={maskPassword ? 'eye' : 'eye-invisible'}
              className={styles.maskToggle}
              onClick={this.toggleMaskPassword}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Button size="large" type="primary" onClick={this.validateOTPRequest} disabled={disableRequestOTP}>
              Request
            </Button>
          </Col>
          <Col span={1} /> {/* Space between input and button */}
          <Col span={17}>
            <FormItem>
              {getFieldDecorator('code', {
                rules: rules.code,
              })(
                <Input
                  className={styles.passwordInput}
                  autoComplete="off"
                  ref={node => (this.input.pinNumber = node)}
                  size="large"
                  prefix={<Icon type="lock" className={styles.prefixIcon} />}
                  placeholder="Enter 6 Pin Numbers"
                  onKeyDown={this.handlePinCodeKeyDown}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <FormItem>
              <Button
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit"
                onClick={this.validateLogin}
                disabled={!password || !code}
              >
                Login
              </Button>
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default LoginForm;
