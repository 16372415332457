export default {
  uid: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Admin ID',
    },
    'ui:rules': [
      {
        required: true,
        message: 'Please enter the Admin ID.',
      },
      {
        validator(rule, value, callback) {
          if (!value) {
            return callback();
          }

          if (/\s/.test(value)) {
            return callback(true);
          }

          return callback();
        },
        message: "Admin ID can't have emtpy spaces.",
      },
    ],
    'ui:title': 'Admin ID',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    },
    'ui:shouldReadOnly': formData => !!formData.uid,
  },
  password: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'password',
      placeholder: 'Password',
    },
    'ui:rules': [{ required: true, message: 'Please enter the password.' }],
    'ui:title': 'Password',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    },
    'ui:shouldRender': formData => !formData.uid,
  },
  fullname: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'John Doe',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the name.' },
      { max: 25, message: 'Name must be less than 25 characters.' },
    ],
    'ui:title': 'Name',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  phone: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '010-0000-0000',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the phone number.' },
      { max: 25, message: 'Phone number must be less than 25 characters.' },
    ],
    'ui:title': 'Phone',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  email: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'john@example.com',
    },
    'ui:rules': [
      { required: true, message: 'Please enter the email address.' },
      { max: 50, message: 'Email address must be less than 50 characters.' },
    ],
    'ui:title': 'Email',
    'ui:description': '',
    'ui:formItemConfig': {
      hasFeedback: true,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    }, // Form.Item
  },
  status: {
    'ui:widget': 'select',
    'ui:options': {
      options: [
        { id: 'active', code: 'Active' },
        { id: 'inactive', code: 'Inactive' },
      ],
    },
    'ui:title': 'Status',
    'ui:description': '',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    },
    'ui:shouldRender': formData => !!formData.uid,
  },
  merchants: {
    'ui:widget': 'select',
    'ui:options': {
      fieldNames: { label: 'name', value: 'id' },
      placeholder: 'Select Merchant',
      options: [],
      mode: 'multiple',
    },
    'ui:rules': [{ required: true, message: 'Merchant' }],
    'ui:remoteConfig': {
      apiKey: 'MERCHANT_LIST',
      hand: data => {
        const newData = [...data.map(d => ({ id: d.id, code: d.displayName }))];
        return newData;
      },
    },
    'ui:title': 'Merchant',
    'ui:description': '',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
    },
  },
};
