// graphQL 쿼리를 state에 연결해주는 유틸
import apolloClient from 'helpers/apolloClient';

const listQueryDefault = {
  pageSize: 10,
  currentPage: 1,
  totalCount: 0,
  loading: false,
  error: null,
  data: null,
  filter: {},
  order: null,
};

const queryDefault = {
  loading: false,
  error: null,
  data: null,
};

// 리스트 쿼리에 사용
const graphListQueryToState = async ({ onSetState, currentPage, pageSize, filter = {}, query, key, order }) => {
  if (!key) throw new Error('graphListQueryToState: need key');
  if (!query) throw new Error('graphListQueryToState: need query');
  if (!onSetState) throw new Error('graphListQueryToState: need onSetState');

  const skip = (currentPage - 1) * pageSize;

  onSetState({
    loading: true,
    error: null,
    totalCount: 0,
  });

  try {
    const requestResult = await apolloClient.query({
      query,
      variables: {
        filter: JSON.stringify(filter || {}),
        skip,
        pageSize,
        ...(order && {
          order: JSON.stringify(order),
        }),
      },
    });
    const result = requestResult.data[key];
    const { list, total } = result;
    onSetState({
      loading: false,
      error: null,
      data: list,
      totalCount: total,
    });
    return { data: list, totalCount: total };
  } catch (e) {
    onSetState({
      loading: false,
      error: e,
      data: null,
      totalCount: 0,
    });
    return { error: e };
  }
};

// 개별 쿼리에 사용
const graphQueryToState = async ({ onSetState, query, key, id, variables }) => {
  if (!key) throw new Error('graphListQueryToState: need key');
  if (!query) throw new Error('graphListQueryToState: need query');
  if (!onSetState) throw new Error('graphListQueryToState: need onSetState');

  onSetState({
    loading: true,
    error: null,
    data: null,
  });

  const queryVariables = variables || { id };

  try {
    const requestResult = await apolloClient.query({
      query,
      variables: queryVariables,
    });
    const result = requestResult.data[key];
    onSetState({
      loading: false,
      error: null,
      data: result,
    });
    return result;
  } catch (e) {
    onSetState({
      loading: false,
      error: e,
      data: null,
    });
  }
  return null;
};

export default { listQueryDefault, queryDefault, graphListQueryToState, graphQueryToState };
