import gql from 'graphql-tag';

export const policyListQuery = gql`
  query policyList($filter: String, $skip: Int, $pageSize: Int) {
    policyList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        status
        type
        description
        startAt
        endAt
        data {
          ... on PolicyPGFee {
            name
            type
            fee
          }
          ... on PolicyPayment {
            constraint
            minAge
            maxAge
            bank
            minAmount
            minUnit
            maxCount
            maxAmountOnce
            maxAmountDay
            maxAmountMonth
          }
          ... on PolicyCharge {
            minAmount
            minUnit
            maxCount
            maxAmountOnce
            maxAmountDay
            maxAmountMonth
            maxBalance
          }
          ... on PolicyWithdrawal {
            fee
            minAmount
            maxAmount
            maxCountPerDay
          }
        }
      }
      total
    }
  }
`;

export const dummyPolicyListQuery = gql`
  query dummyPolicyList {
    dummyPolicyList {
      list {
        id
        createdAt
        updatedAt
        status
        type
        description
        startAt
        endAt
      }
      total
    }
  }
`;

export default {
  policyListQuery,
  dummyPolicyListQuery,
};
