import React from 'react';
import LinkText from 'components/LinkText';
import { BoostExclusionTypeText } from './';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderName(val) {
      return <span>{BoostExclusionTypeText[val]}</span>;
    },
    width: '25%',
  },
  {
    title: 'Code Type',
    dataIndex: 'codeType',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: 'Code',
    dataIndex: 'code',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
];

export default {
  columns,
};
