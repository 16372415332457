import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, arrayOf, instanceOf, bool, any, number, objectOf, shape } from 'prop-types';
import { Button, Tabs, Alert, Divider, message } from 'antd';
import autobind from 'autobind-decorator';
import { getBannerGroups, addBannerGroup, removeBannerGroup } from 'redux/modules/bannerGroup/actions';
import {
  getBanners,
  addBanner,
  updateBanner,
  updateBannerSortedIndex,
  removeBanner,
} from 'redux/modules/banner/actions';
import BannerList from './List/BannerList';
import CreateBannerGroupModal from './Modal/CreateBannerGroupModal';
import BannerFormModal from './Modal/BannerFormModal';
import styles from './Banner.scss';

const { TabPane } = Tabs;

const mapStateToProps = state => ({
  data: state.bannerGroup.data,
  error: state.bannerGroup.error,
  loading: state.bannerGroup.loading,
  banner: {
    currentPage: state.banner.currentPage,
    pageSize: state.banner.pageSize,
    filter: state.banner.filter,
  },
});

const mapDispatchToProps = {
  getBannerGroups,
  addBannerGroup,
  removeBannerGroup,
  getBanners,
  addBanner,
  updateBanner,
  updateBannerSortedIndex,
  removeBanner,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BannerContainer extends Component {
  static propTypes = {
    getBannerGroups: func.isRequired,
    addBannerGroup: func.isRequired,
    removeBannerGroup: func.isRequired,
    data: arrayOf(any).isRequired,
    error: instanceOf(Error),
    loading: bool.isRequired,
    getBanners: func.isRequired,
    addBanner: func.isRequired,
    updateBanner: func.isRequired,
    updateBannerSortedIndex: func.isRequired,
    removeBanner: func.isRequired,
    banner: shape({
      currentPage: number.isRequired,
      pageSize: number.isRequired,
      filter: objectOf(any),
    }).isRequired,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    bannerGroupModalVisible: false,
    bannerGroupName: '',
    bannerModalVisible: false,
    activeTabKey: null,
    selectedBannerId: null,
  };

  async componentWillMount() {
    await this.getData();
    const { data } = this.props;

    // Set first BannerGroup as default on first render!
    if (data.length > 0) {
      this.setState({
        activeTabKey: data[0].id,
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.loading !== nextProps.loading ||
      this.state.bannerGroupModalVisible !== nextState.bannerGroupModalVisible ||
      this.state.bannerModalVisible !== nextState.bannerModalVisible ||
      this.state.bannerGroupName !== nextState.bannerGroupName ||
      this.state.activeTabKey !== nextState.activeTabKey
    );
  }

  /**
   * Get Banner Groups
   * @return {Promise<BannerGroupEntity[] | Error>}
   */
  getData() {
    return this.props.getBannerGroups();
  }

  showCreateBannerGroupModal() {
    this.setState({ bannerGroupModalVisible: true, bannerGroupName: '' });
  }

  hideCreateBannerGroupModal() {
    this.setState({ bannerGroupModalVisible: false });
  }

  showBannerFormModal() {
    this.setState({ bannerModalVisible: true, selectedBannerId: null });
  }

  /**
   * Open BannerForm Modal with ID
   * @param {BannerEntity} banner
   */
  showBannerFormModalWithId(banner) {
    this.setState({ bannerModalVisible: true, selectedBannerId: banner.id });
  }

  hideBannerFormModal() {
    this.setState({ bannerModalVisible: false });
  }

  async createBannerGroup() {
    const { bannerGroupName } = this.state;

    if (!bannerGroupName) {
      return;
    }

    try {
      const result = await this.props.addBannerGroup({ name: bannerGroupName });
      this.hideCreateBannerGroupModal();
      this.setState({ activeTabKey: result });

      await this.getData();
    } catch (err) {
      message.error(`Failed to create banner group: ${err.message}`);
      console.log(err);
    }
  }

  async deleteBannerGroup() {
    const { activeTabKey: bannerGroupId } = this.state;

    if (!bannerGroupId) {
      return;
    }

    try {
      const prevIdx = this.props.data.findIndex(bannerGroup => bannerGroup.id === bannerGroupId);

      await this.props.removeBannerGroup({ id: bannerGroupId });
      await this.getData();

      const { data } = this.props;

      if (data.length > 0) {
        this.setState({
          activeTabKey: data[prevIdx > 0 ? prevIdx - 1 : 0].id,
        });
      }
    } catch (err) {
      message.error(`Failed to remove banner group: ${err.message}`);
      console.log(err);
    }
  }

  async saveBanner(formData) {
    if (!this.state.selectedBannerId) {
      return this.createBanner(formData);
    }

    return this.updateBanner(formData);
  }

  async createBanner(formData) {
    try {
      const { activeTabKey: groupId } = this.state;
      formData.groupId = groupId;

      await this.props.addBanner(formData);
      this.hideBannerFormModal();

      message.success('Created.');

      const { currentPage, pageSize, filter } = this.props.banner;
      this.props.getBanners(currentPage, pageSize, filter);
    } catch (err) {
      message.error(`Failed to create group: ${err.message}`);
      console.log(err);
    }
  }

  async updateBanner(formData) {
    try {
      const { selectedBannerId: id } = this.state;
      formData.id = id;

      await this.props.updateBanner(formData);
      this.hideBannerFormModal();

      message.success('Updated.');

      const { currentPage, pageSize, filter } = this.props.banner;
      await this.props.getBanners(currentPage, pageSize, filter);
      this.forceUpdate();
    } catch (err) {
      message.error(`Failed to update banner: ${err.message}`);
      console.log(err);
    }
  }

  async removeBanners(idList) {
    if (!idList || idList.length <= 0) {
      message.warning('No Banner selected!');
      return;
    }

    try {
      await this.props.removeBanner({ idList });
      message.success('Removed.');

      const { currentPage, pageSize, filter } = this.props.banner;
      await this.props.getBanners(currentPage, pageSize, filter);
    } catch (err) {
      message.error(`Failed to remove banners: ${err.message}`);
      console.log(err);
    }
  }

  async updateBannerPriorities(sortingList) {
    try {
      await this.props.updateBannerSortedIndex(sortingList);
      message.success('Updated.');

      const { currentPage, pageSize, filter } = this.props.banner;
      await this.props.getBanners(currentPage, pageSize, filter);
    } catch (err) {
      message.error(`Failed to update banners: ${err.message}`);
      console.log(err);
    }
  }

  handleBannerGroupNameChange(ev) {
    this.setState({
      bannerGroupName: ev.target.value,
    });
  }

  handleBannerGroupTabClick(activeTabKey) {
    this.setState({ activeTabKey });
  }

  // Check how it render
  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { data: bannerGroups, error } = this.props;
    const hasBannerGroup = bannerGroups.length > 0;
    const { activeTabKey, bannerGroupModalVisible, bannerGroupName, bannerModalVisible, selectedBannerId } = this.state;

    if (error) {
      return this.renderError();
    }

    return (
      <div className={styles.bannerContainer}>
        <div className={styles.bannerCategoriesWrapper}>
          {!hasBannerGroup && (
            <Alert
              message="No Banner Group found."
              description="Add new Banner Group to create new banner."
              type="info"
              showIcon
              style={{
                marginBottom: 15,
              }}
            />
          )}

          {hasBannerGroup && (
            <Tabs
              onTabClick={this.handleBannerGroupTabClick}
              activeKey={activeTabKey}
              tabPosition="left"
              tabBarStyle={{ width: 200 }}
              style={{ marginBottom: 10 }}
              destroyInactiveTabPane
            >
              {bannerGroups.map(bannerGroup => (
                <TabPane key={bannerGroup.id.toString()} tab={bannerGroup.name}>
                  <BannerList
                    groupId={bannerGroup.id.toString()}
                    onCreateBannerButtonClick={this.showBannerFormModal}
                    onBannerClick={this.showBannerFormModalWithId}
                    onRemoveBannerButtonClick={this.removeBanners}
                    onSaveButtonClick={this.updateBannerPriorities}
                  />
                </TabPane>
              ))}
            </Tabs>
          )}

          {!hasBannerGroup && (
            <div className={styles.categoryButtonContainer}>
              <Button icon="plus-circle" onClick={this.showCreateBannerGroupModal}>
                Create
              </Button>
            </div>
          )}

          {hasBannerGroup && (
            <div className={styles.categoryButtonContainer}>
              <Button icon="plus-circle" onClick={this.showCreateBannerGroupModal} />
            </div>
          )}

          {/* Modals */}
          <CreateBannerGroupModal
            onOk={this.createBannerGroup}
            onCancel={this.hideCreateBannerGroupModal}
            visible={bannerGroupModalVisible}
            onBannerGroupNameChange={this.handleBannerGroupNameChange}
            bannerGroupName={bannerGroupName}
          />

          <BannerFormModal
            onOk={this.saveBanner}
            onCancel={this.hideBannerFormModal}
            visible={bannerModalVisible}
            id={selectedBannerId}
          />
        </div>
      </div>
    );
  }
}

export default BannerContainer;
