import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Alert, Checkbox } from 'antd';
import autobind from 'autobind-decorator';
import styles from './ListSelect.scss';

@autobind
class ListSelect extends PureComponent {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string,
      })
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.number),
    ]),
    multiSelect: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
    error: PropTypes.instanceOf(Error),
  };

  static defaultProps = {
    value: null,
    multiSelect: false,
    small: false,
    onClick: () => {},
    error: null,
  };

  handleItemClick(checkedValue) {
    const { multiSelect, onClick } = this.props;

    if (!multiSelect) {
      onClick(checkedValue);
      return;
    }

    const { value } = this.props;
    const result = [...value];

    for (let i = 0; i < result.length; i += 1) {
      if (result[i] === checkedValue) {
        result.splice(i, 1);
        onClick(result, checkedValue);
        return;
      }
    }

    result.push(checkedValue);
    onClick(result, checkedValue);
  }

  isChecked(checkingValue) {
    const { value } = this.props;

    if (typeof value === 'string') {
      return checkingValue === value;
    }

    for (let i = 0; i < value.length; i += 1) {
      if (value[i] === checkingValue) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { items, error, small } = this.props;
    const cx = classNames.bind(styles);

    return (
      <div>
        {error && <Alert style={{ marginBottom: 10 }} message={error.message} type="warning" showIcon />}

        <div className={cx(['listSelect', small ? 'small' : '', { error }])}>
          {items.length === 0 && (
            <div className={styles.listItem}>
              <div className={styles.listItemEmpty}>No Data</div>
            </div>
          )}
          {items.length > 0 &&
            items.map(item => (
              <div
                key={item.value}
                className={styles.listItem}
                title={item.title}
                onClick={this.handleItemClick.bind(this, item.value)}
              >
                <div className={styles.listItemCheckbox}>
                  <Checkbox onChange={this.handleCheckbox} checked={this.isChecked(item.value)} />
                </div>
                <div>{item.name}</div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default ListSelect;
