import React, { Component } from 'react';
import { string, arrayOf, objectOf, any } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Menu, Icon } from 'antd';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;
const { Item } = Menu;

const renderMenuItem = (item, menuMapToRouter) => {
  const { name, title, icon, leftMenu } = item;
  const link = menuMapToRouter ? menuMapToRouter[name] : null;

  if (!leftMenu) {
    return null;
  }

  return (
    <Item key={name}>
      {link ? (
        <Link to={link}>
          <span>
            {icon && <Icon type={icon} style={{ color: '#fff' }} />}
            <span>{title}</span>
          </span>
        </Link>
      ) : (
        <span>
          {icon && <Icon type={icon} style={{ color: '#08c' }} />}
          <span>{title}</span>
        </span>
      )}
    </Item>
  );
};

const renderSubMenu = (item, menuMapToRouter) => {
  const { name, title, icon, children } = item;

  return (
    <SubMenu
      key={name}
      title={
        <span>
          {icon && <Icon type={icon} style={{ color: '#fff' }} />}
          <span>{title}</span>
        </span>
      }
    >
      {children &&
        children.map(child =>
          child.children && child.children.filter(s => s.leftMenu).length > 0
            ? renderSubMenu(child)
            : renderMenuItem(child, menuMapToRouter))}
    </SubMenu>
  );
};

renderSubMenu.defaultProps = {
  children: [],
};

const mapStateToProps = state => ({
  path: state.router.location.pathname,
  menuMapToRouter: state.global.menuMapToRouter,
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
@withRouter
@autobind
class SiderMenu extends Component {
  static propTypes = {
    path: string.isRequired,
    history: objectOf(any).isRequired,
    menus: arrayOf(any).isRequired,
    menuMapToRouter: objectOf(any).isRequired,
    staticContext: objectOf(any),
  };

  static defaultProps = {
    staticContext: null,
  };

  state = {
    defaultPath: [],
    selectedMenuKeys: [],
  };

  componentWillMount() {
    this.handleUrlChange();

    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleUrlChange() {
    const { history, menuMapToRouter } = this.props;
    const { pathname = '' } = history.location;

    // 1 depth menu ex) /user
    const menuPath = pathname.split('/').slice(0, 2).join('/');
    // 2 depth menu ex) /promotion/group
    const submenuPath = pathname.split('/').slice(0, 3).join('/');
    const selectedMenuKeys = Object.keys(menuMapToRouter).filter(key =>
      [menuPath, submenuPath].includes(menuMapToRouter[key]));

    this.setState({ selectedMenuKeys });
  }

  updateMenu() {
    this.handleUrlChange();
  }

  render() {
    const { menus, menuMapToRouter, staticContext, ...rest } = this.props;
    const { defaultPath, selectedMenuKeys } = this.state;
    const defaultOpenKeys = [defaultPath.length >= 2 ? defaultPath[1] : undefined];

    return (
      <div>
        {Object.keys(menuMapToRouter).length !== 0 && (
          <Menu
            {...rest}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={defaultPath}
            defaultOpenKeys={[...defaultOpenKeys]}
            onClick={this.updateMenu}
            selectedKeys={selectedMenuKeys}
          >
            {menus &&
              menus.map(item =>
                item.children && item.children.length
                  ? renderSubMenu(item, menuMapToRouter)
                  : renderMenuItem(item, menuMapToRouter))}
          </Menu>
        )}
      </div>
    );
  }
}

export default SiderMenu;
