import React from 'react';
import { Badge } from 'antd';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const style = {
  textOverflow: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
  },
};

/**
 * Get Status text for Badge Component
 * @param {string} status - Payment Status
 * @return string
 */
function getStatus(status) {
  return {
    active: '활성화',
    inactive: '비활성화',
    block: '사용정지',
    deleted: '해지',
    delete_request: '해지접수',
    issue: '발급',
    reissue: '재발급',
  }[status];
}

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderId(val) {
      return <span>{val}</span>;
    },
    width: '5%',
  },
  {
    title: '잔액',
    dataIndex: 'balance',
    align: 'right',
    render: function renderBalance(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: '예치금 기준금액',
    dataIndex: 'depositAmount',
    align: 'right',
    render: function renderDepositAmount(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: '충전 예정금액',
    dataIndex: 'expectedAmount',
    align: 'right',
    render: function renderExpectedAmount(val) {
      return <span>{commify(val || 0)}</span>;
    },
    width: '15%',
  },
  {
    title: '현재 상태',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return <Badge status={getStatus(val)} text={getStatus(val)} style={{ width: '100%', ...style.textOverflow }} />;
    },
    width: '15%',
  },
  {
    title: '변경 예정 상태',
    dataIndex: 'batchStatus',
    render: function renderStatus(val) {
      return <Badge status={getStatus(val)} text={getStatus(val)} style={{ width: '100%', ...style.textOverflow }} />;
    },
    width: '15%',
  },
  {
    title: '활성화된 날짜',
    dataIndex: 'activatedAt',
    render: function renderDate(val) {
      return <span>{val ? formatDate(val) : '-'}</span>;
    },
    width: '20%',
  },
];

export default {
  columns,
};
