import apolloClient from 'helpers/apolloClient';
import { boltPromotionListQuery } from './queries';
import { boltPolicyAddMutation, boltPolicyUpdateMutation } from './mutations';

export const FETCH_BOLT_POLICY = 'boltPromotion/FETCH';
export const FETCH_BOLT_POLICY_SUCCESS = 'boltPromotion/FETCH_SUCCESS';
export const FETCH_BOLT_POLICY_FAILURE = 'boltPromotion/FETCH_FAILURE';

export const ADD_BOLT_POLICY = 'boltPromotion/ADD';
export const ADD_BOLT_POLICY_SUCCESS = 'boltPromotion/ADD_SUCCESS';
export const ADD_BOLT_POLICY_FAILURE = 'boltPromotion/ADD_FAILURE';

export const UPDATE_BOLT_POLICY = 'boltPromotion/UPDATE';
export const UPDATE_BOLT_POLICY_SUCCESS = 'boltPromotion/UPDATE_SUCCESS';
export const UPDATE_BOLT_POLICY_FAILURE = 'boltPromotion/UPDATE_FAILURE';

export const SET_FILTER = 'boltPromotion/SET_FILTER';
export const RESET_FILTER = 'boltPromotion/RESET_FILTER';

export const SET_CURRENT_PAGE = 'boltPromotion/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'boltPromotion/SET_PAGE_SIZE';

export function getBoltPolicies(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BOLT_POLICY, FETCH_BOLT_POLICY_SUCCESS, FETCH_BOLT_POLICY_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: boltPromotionListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.boltPolicyList;
    },
  };
}

export function addBoltPolicy(boltPolicy) {
  return {
    types: [ADD_BOLT_POLICY, ADD_BOLT_POLICY_SUCCESS, ADD_BOLT_POLICY_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boltPolicyAddMutation,
        variables: boltPolicy,
      });

      return result.data.boltPolicyAdd;
    },
  };
}

export function updateBoltPolicy(boltPolicy) {
  return {
    types: [UPDATE_BOLT_POLICY, UPDATE_BOLT_POLICY_SUCCESS, UPDATE_BOLT_POLICY_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: boltPolicyUpdateMutation,
        variables: boltPolicy,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
