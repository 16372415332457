import React from 'react';
import { Button } from 'antd';

export function getColumns(options = {}) {
  const { deletePromotion, showI18nModal } = options;
  const cols = [
    {
      title: '',
      dataIndex: 'sortedIndex',
      key: 'sortedIndex',
      width: '10%',
    },
    {
      title: 'Main Text',
      dataIndex: 'mainText',
      key: 'mainText',
      width: '25%',
      render(value, record) {
        return (
          <>
            <div>{value}</div>
            <Button
              size="small"
              onClick={() =>
                showI18nModal({ title: 'Main Text', columnName: `promotions.${record.id}.mainText`, value })
              }
            >
              Translate
            </Button>
          </>
        );
      },
    },
    {
      title: 'Sub Text',
      dataIndex: 'subText',
      key: 'subText',
      width: '25%',
      render(value, record) {
        return (
          <>
            <div>{value}</div>
            <Button
              size="small"
              onClick={() => showI18nModal({ title: 'Sub Text', columnName: `promotions.${record.id}.subText`, value })}
            >
              Translate
            </Button>
          </>
        );
      },
    },
    {
      title: 'First Purchase',
      dataIndex: 'isUsableOnFirstPurchase',
      key: 'isUsableOnFirstPurchase',
      width: '20%',
    },
    {
      title: '',
      align: 'center',
      width: '15%',
      render(val) {
        return (
          <Button type="danger" ghost onClick={deletePromotion.bind(null, val.id)}>
            Delete
          </Button>
        );
      },
    },
  ];
  return cols;
}

export default {
  getColumns,
};
