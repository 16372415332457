import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as connectedReactRouter from 'connected-react-router';
import { message, Pagination, Divider } from 'antd';
import DataViewer from 'components/DataViewer';
import PageDisplay from 'components/PageDisplay';
import PageSizeDropdown from 'components/PageSizeDropdown';

import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { boostCategoryBoostListQuery } from 'redux/modules/boostCategory/queries';

import BoostCategoryBoostFilterForm from './BoostCategoryBoostFilterForm';
import { columns } from './BoostCategoryBoostMetadata';

const mapDispatchToProps = {
  push: connectedReactRouter.push,
};

const BoostCategoryBoostList = ({ categoryId, push }) => {
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [filter, setFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  let query = null;

  const getCategoryBoostList = useCallback(async () => {
    setError(null);
    setLoading(true);

    query = cancelableQuery({
      query: boostCategoryBoostListQuery,
      variables: {
        id: +categoryId,
        filter: JSON.stringify(filter || {}),
        skip: (currentPage - 1) * pageSize,
        pageSize,
      },
    });

    try {
      const result = await query;
      const { boostCategoryBoostList } = result.data;

      if (!boostCategoryBoostList) {
        throw new Error('Invalid Boost Category ID');
      } else {
        const { list, total } = boostCategoryBoostList;
        setData(list);
        setTotalCount(total);
        setLoading(false);
      }
    } catch (err) {
      if (err.isCanceled) return;

      message.error(`Failed to get Boost List: ${err.message}`);
      setError(err);
      setLoading(false);
      throw err;
    }
  }, [categoryId, filter, currentPage, pageSize]);

  useEffect(() => {
    getCategoryBoostList();

    return () => {
      if (query) {
        query.cancel();
      }
    };
  }, [categoryId, filter, currentPage, pageSize]);

  const handleSetFilter = filterData => {
    setFilter(filterData);
  };

  const handleResetFilter = () => {
    setFilter(null);
  };

  const handleCellClick = (record, extra) => {
    const { id } = record;
    const { index } = extra;

    if (index === 0) {
      push(`/boost/policy/${id}`);
    }
  };

  const changePage = newPage => {
    setCurrentPage(newPage);
  };

  const updatePageSize = menuInfo => {
    const newPageSize = +menuInfo.key;
    setPageSize(newPageSize);
  };

  return (
    <>
      <BoostCategoryBoostFilterForm setFilter={handleSetFilter} resetFilter={handleResetFilter} />
      <Divider />
      <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={handleCellClick} />
      <PageDisplay totalCount={totalCount} currentPage={currentPage} pageSize={pageSize} />
      <Pagination
        total={totalCount}
        pageSize={pageSize}
        defaultCurrent={1}
        current={currentPage}
        onChange={changePage}
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: 20,
        }}
      />
      <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
        <PageSizeDropdown onPageSizeChange={updatePageSize} currentPageSize={pageSize} />
      </div>
    </>
  );
};

BoostCategoryBoostList.propTypes = {
  categoryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  push: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(BoostCategoryBoostList);
