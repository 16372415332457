import gql from 'graphql-tag';

export const groupMerchantListQuery = gql`
  mutation selectGroupMerchantMgmtList(
    $inputGroupMerchantNo: String
    $inputGroupMerchantName: String
    $inputMerchantNo: String
    $inputGubun: String
    $inputCancelYn: String
  ) {
    selectGroupMerchantMgmtList(
      groupMerchantNo: $inputGroupMerchantNo
      groupMerchantName: $inputGroupMerchantName
      merchantNo: $inputMerchantNo
      gubun: $inputGubun
      cancelYn: $inputCancelYn
    ) {
      groupMerchantNo
      groupMerchantName
      # merchantNo
      # merchantName
      # receveSeq
      # receveBaseDate
      # registeredDate
      # canceledDate
      # firstOperateAdminId
      # firstOperateDetailDate
      # firstOperateTradeCode
      # useYn
      # gubun
      # merchantInfo {
      #   receveSeq
      #   receveBaseDate
      #   registeredDate
      #   merchantNo
      #   merchantBizNo
      #   merchantName
      #   merchantEngName
      #   officerName
      #   businessCode
      #   businessSubCode
      #   telNo1
      #   telNo2
      #   telNo3
      #   terminateDate
      #   salesDivision
      #   edcMerchantYn
      #   settlementProcessCycle
      #   feeRate
      #   cardFeeRate
      #   branchGiroNo
      #   tradeBlockYn
      #   accountBankCode
      #   tradeBlockStartDate
      #   tradeBlockEndDate
      #   installmentMonth
      #   paymentAcno
      #   merchantAddressDivisionCode
      #   merchantRoadAddress01
      #   merchantRoadAddress02
      #   merchantRoadAddressManagementNo
      #   merchantPostNo
      #   merchantEtcAddress
      #   remarkDescription
      #   firstOperateAdminId
      #   firstOperateDetailDate
      #   firstOperateTradeCode
      #   lastOperateAdminId
      #   lastOperateDetailDate
      #   lastOperateTradeCode
      # }
    }
  }
`;

export default {
  groupMerchantListQuery,
};
