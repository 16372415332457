import PromotionContainer from './PromotionContainer';

export const PromotionBenefitType = {
  DISCOUNT: 'discount',
  CASHBACK: 'cashback',
};

// benefit type === discount
export const DiscountPolicyType = {
  COUPON: 'coupon', // 일반 머천트에서 사용하는 쿠폰 정책
};

export const DiscountIssueType = {
  NONE: 'none',
  LOGIN: 'login',
  BANNER: 'banner',
  DOWNLOAD: 'download',
  PAYMENT: 'payment',
  ADMIN: 'admin',
  REGULAR: 'regular',
  LIFETIME: 'lifetime',
  CAMPAIGN: 'campaign',
};

// benefit type === cashback
export const CashbackPolicyType = {
  CHAI_CASHBACK: 'chai_cashback', // 차이에서 사용하는 캐시백 정책
  MERCHANT_CASHBACK: 'merchant_cashback', // 가맹점에서 사용하는 캐시백 정책
  USER_CASHBACK: 'user_cashback', // 사용자 기반 캐시백 정책
};

export const CashbackIssueType = {
  NONE: 'none',
  PAYMENT: 'payment',
  PUBLIC_TRANSPORTATION_PAYMENT: 'public_transportation_payment',
  LIFETIME: 'lifetime',
  BOOST: 'boost',
};

export const UserCashbackIssueType = {
  AIRDROP: 'airdrop',
};

export const TargetUserRuleType = {
  PAYMENT_AMOUNT: 'payment_amount',
  PAYMENT_COUNT: 'payment_count',
  CHARGE_AMOUNT: 'charge_amount',
  CHARGE_COUNT: 'charge_count',
  JOIN_DATE: 'join_date',
};

export const DiscountType = {
  KRW: 'krw',
  PERCENT: 'percent',
};

export const TtlType = {
  DAY: 'day',
  HOUR: 'hour',
};

export class TargetUserRule {
  constructor(args = {}) {
    const { id, type, lifeTime, startDate, endDate, operator, value } = args;

    if (!id) {
      throw new Error('ID must be specified.');
    }
    if (type && !this.validateType(type)) {
      throw new Error(`Invalid TargetUserRuleType: ${type}`);
    }

    this.id = id;
    this.type = type || TargetUserRuleType.PAYMENT_AMOUNT;
    this.lifeTime = !!lifeTime;
    this.startDate = startDate;
    this.endDate = endDate;
    this.operator = operator || '>=';
    this.value = value;
  }
  validateType(type) {
    return type === 'payment_amount' || type === 'payment_count' || type === 'charge_amount' || type === 'charge_count';
  }
}

export default PromotionContainer;
