import React from 'react';
import { TreeSelect, Icon } from 'antd';
import icons from 'ui/Icons';

const SelectTreeNode = TreeSelect.TreeNode;

const iconsTree = [];

for (let i = 0; i < icons.length; i += 1) {
  const item = icons[i];
  const children = [];

  for (let j = 0; j < item.icons.length; j += 1) {
    const child = item.icons[j];
    children.push(
      <SelectTreeNode
        value={child.icon}
        title={
          <span>
            <Icon type={child.icon} style={{ color: '#08c', marginRight: 5 }} />
            <span>{child.title}</span>
          </span>
        }
        key={child.icon}
      />
    );
  }

  iconsTree.push(
    <SelectTreeNode title={item.title} key={item.title}>
      {children}
    </SelectTreeNode>
  );
}

export default iconsTree;
