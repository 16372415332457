import {
  FETCH_SETTLEMENT_PAYMENT,
  FETCH_SETTLEMENT_PAYMENT_SUCCESS,
  FETCH_SETTLEMENT_PAYMENT_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: null,
};

export default function SettlementPaymentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTLEMENT_PAYMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SETTLEMENT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_SETTLEMENT_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: null,
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
}
