const BoltDrawPromotionImageType = {
  CARD_COMING_SOON: 'card_coming_soon', // 카드 - 예정
  CARD_OPENED: 'card_opened', // 카드 - 진행중
  CARD_DRAW_COMPLETED: 'card_draw_completed', // 카드 - 추첨 완료
  TITLE: 'title', // 카드 - 타이틀 이미지
  DETAIL_FULL: 'detail_full', // 상세 - 풀 이미지
  DETAIL_SQUARE: 'detail_square', // 상세 - 정방형 이미지
};

export default {
  BoltDrawPromotionImageType,
};
