const rules = {
  termType: [
    {
      required: true,
      message: 'Please enter the term type.',
    },
    {
      message: 'Term type only accept lower case alphabet, numbers and hypens(-).',
      validator: (rule, value, callback) => {
        if (value && !/^[a-z0-9]+(?:-+[a-z0-9]+)*$/.test(value)) {
          return callback(true);
        }

        return callback();
      },
    },
  ],
  title: [
    {
      required: true,
      message: 'Please enter the title.',
    },
  ],
  sortedIndex: [
    {
      required: true,
      message: 'Please enter the sorting order.',
    },
  ],
  issueDate: [
    {
      required: true,
      message: 'Please select the issue date.',
    },
  ],
};

export default rules;
