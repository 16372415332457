export const AutoRefreshInterval = {
  SECONDS_5: '5_seconds',
  SECONDS_10: '10_seconds',
  SECONDS_30: '30_seconds',
  SECONDS_45: '45_seconds',
  MINUTE_1: '1_minute',
  MINUTES_5: '5_minutes',
  MINUTES_15: '15_minutes',
  MINUTES_30: '30_minutes',
  HOUR_1: '1_hour',
  HOURS_2: '2_hours',
  HOURS_12: '12_hours',
  DAY_1: '1_day',
};

export const TimeRangeQuickMode = {
  TODAY: 'today',
  THIS_WEEK: 'this_week',
  THIS_MONTH: 'this_month',
  THIS_YEAR: 'this_year',
  TODAY_SO_FAR: 'today_so_far',
  WEEK_TO_DATE: 'week_to_date',
  MONTH_TO_DATE: 'month_to_date',
  YEAR_TO_DATE: 'year_to_date',
  LAST_15_MINUTES: 'last_15_minutes',
  LAST_30_MINUTES: 'last_30_minutes',
  LAST_1_HOUR: 'last_1_hour',
  LAST_4_HOURS: 'last_4_hours',
  LAST_12_HOURS: 'last_12_hours',
  LAST_24_HOURS: 'last_24_hours',
  LAST_7_DAYS: 'last_7_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_60_DAYS: 'last_60_days',
  LAST_90_DAYS: 'last_90_days',
  LAST_6_MONTHS: 'last_6_months',
  LAST_1_YEAR: 'last_1_year',
  LAST_2_YEARS: 'last_2_years',
  LAST_3_YEARS: 'last_3_years',
};

export const TimeFormat = {
  '30s': 'HH:mm:ss',
  '1m': 'HH:mm',
  '5m': 'HH:mm',
  '10m': 'HH:mm',
  '30m': 'HH:mm',
  '1h': 'MM-DD',
  '3h': 'MM-DD',
  '12h': 'MM-DD',
  '1d': 'MM-DD',
  '30d': 'MM-DD',
  '1w': 'MM-DD',
};

export const TimeRangeMode = {
  QUICK: 'quick',
  ABSOLUTE: 'absolute',
};
