import React from 'react';
import { Button } from 'antd';
import { UpCircleTwoTone, DownCircleTwoTone } from '@ant-design/icons';

export function getColumns(onDownClicked, onUpClicked, onDeleteClicked) {
  return [
    {
      title: 'Image',
      dataIndex: 'url',
      width: '20%',
      render: function render(val) {
        return <img src={val} alt="square" width="86" height="86" />;
      },
    },
    {
      title: 'URL',
      dataIndex: 'url',
      width: '30%',
      render: function render(val) {
        return <span>{val}</span>;
      },
    },
    {
      title: 'Up',
      key: 'priority_up',
      align: 'center',
      width: '10%',
      render: function render(nameColData, row) {
        return (
          <div>
            <UpCircleTwoTone
              twoToneColor="#ff0000"
              onClick={event => {
                if (event) event.stopPropagation();
                onUpClicked(row);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Down',
      key: 'priority_down',
      align: 'center',
      width: '10%',
      render: function render(nameColData, row) {
        return (
          <div>
            <DownCircleTwoTone
              twoToneColor="#0000ff"
              onClick={event => {
                if (event) event.stopPropagation();
                onDownClicked(row);
              }}
            />
          </div>
        );
      },
    },
    {
      title: '',
      key: 'delete',
      align: 'center',
      width: '30%',
      render: function render(nameColData, row) {
        return (
          <div>
            <Button
              type="danger"
              icon="delete"
              ghost
              onClick={event => {
                if (event) event.stopPropagation();
                onDeleteClicked(row);
              }}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];
}

export default {
  getColumns,
};
