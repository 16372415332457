export default {
  loginId: [
    {
      type: 'string',
    },
  ],
  depositCode: [
    {
      type: 'string',
    },
  ],
};
