import { AutoRefreshInterval, TimeRangeQuickMode } from './DashboardConstants';

export const autoRefreshIntervalGroup = [
  [
    AutoRefreshInterval.SECONDS_5,
    AutoRefreshInterval.SECONDS_10,
    AutoRefreshInterval.SECONDS_30,
    AutoRefreshInterval.SECONDS_45,
  ],
  [
    AutoRefreshInterval.MINUTE_1,
    AutoRefreshInterval.MINUTES_5,
    AutoRefreshInterval.MINUTES_15,
    AutoRefreshInterval.MINUTES_30,
  ],
  [AutoRefreshInterval.HOUR_1, AutoRefreshInterval.HOURS_2, AutoRefreshInterval.HOURS_12, AutoRefreshInterval.DAY_1],
];

export const quickModeGroup = [
  [TimeRangeQuickMode.TODAY, TimeRangeQuickMode.THIS_WEEK, TimeRangeQuickMode.THIS_MONTH, TimeRangeQuickMode.THIS_YEAR],
  [
    TimeRangeQuickMode.LAST_15_MINUTES,
    TimeRangeQuickMode.LAST_30_MINUTES,
    TimeRangeQuickMode.LAST_1_HOUR,
    TimeRangeQuickMode.LAST_4_HOURS,
    TimeRangeQuickMode.LAST_12_HOURS,
    TimeRangeQuickMode.LAST_24_HOURS,
    TimeRangeQuickMode.LAST_7_DAYS,
  ],
  [
    TimeRangeQuickMode.LAST_30_DAYS,
    TimeRangeQuickMode.LAST_60_DAYS,
    TimeRangeQuickMode.LAST_90_DAYS,
    TimeRangeQuickMode.LAST_6_MONTHS,
    TimeRangeQuickMode.LAST_1_YEAR,
    TimeRangeQuickMode.LAST_2_YEARS,
    TimeRangeQuickMode.LAST_3_YEARS,
  ],
];

export const chartTimeInterval = {
  registeredUsers: {
    [TimeRangeQuickMode.TODAY]: '30m',
    [TimeRangeQuickMode.THIS_WEEK]: '1h',
    [TimeRangeQuickMode.THIS_MONTH]: '3h',
    [TimeRangeQuickMode.THIS_YEAR]: '1d',
    [TimeRangeQuickMode.LAST_15_MINUTES]: '30s',
    [TimeRangeQuickMode.LAST_30_MINUTES]: '1m',
    [TimeRangeQuickMode.LAST_1_HOUR]: '30m',
    [TimeRangeQuickMode.LAST_4_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_12_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_24_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_7_DAYS]: '1h',
    [TimeRangeQuickMode.LAST_30_DAYS]: '3h',
    [TimeRangeQuickMode.LAST_60_DAYS]: '12h',
    [TimeRangeQuickMode.LAST_90_DAYS]: '12h',
    [TimeRangeQuickMode.LAST_6_MONTHS]: '1d',
    [TimeRangeQuickMode.LAST_1_YEAR]: '1d',
    [TimeRangeQuickMode.LAST_2_YEARS]: '1d',
    [TimeRangeQuickMode.LAST_3_YEARS]: '1d',
  },
  paymentCountByTimes: {
    [TimeRangeQuickMode.TODAY]: '30m',
    [TimeRangeQuickMode.THIS_WEEK]: '3h',
    [TimeRangeQuickMode.THIS_MONTH]: '12h',
    [TimeRangeQuickMode.THIS_YEAR]: '1w',
    [TimeRangeQuickMode.LAST_15_MINUTES]: '30s',
    [TimeRangeQuickMode.LAST_30_MINUTES]: '30s',
    [TimeRangeQuickMode.LAST_1_HOUR]: '1m',
    [TimeRangeQuickMode.LAST_4_HOURS]: '5m',
    [TimeRangeQuickMode.LAST_12_HOURS]: '10m',
    [TimeRangeQuickMode.LAST_24_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_7_DAYS]: '3h',
    [TimeRangeQuickMode.LAST_30_DAYS]: '12h',
    [TimeRangeQuickMode.LAST_60_DAYS]: '1d',
    [TimeRangeQuickMode.LAST_90_DAYS]: '1d',
    [TimeRangeQuickMode.LAST_6_MONTHS]: '1d',
    [TimeRangeQuickMode.LAST_1_YEAR]: '1d',
    [TimeRangeQuickMode.LAST_2_YEARS]: '1d',
    [TimeRangeQuickMode.LAST_3_YEARS]: '1w',
  },
  payments: {
    [TimeRangeQuickMode.TODAY]: '30m',
    [TimeRangeQuickMode.THIS_WEEK]: '1h',
    [TimeRangeQuickMode.THIS_MONTH]: '3h',
    [TimeRangeQuickMode.THIS_YEAR]: '1d',
    [TimeRangeQuickMode.LAST_15_MINUTES]: '30s',
    [TimeRangeQuickMode.LAST_30_MINUTES]: '1m',
    [TimeRangeQuickMode.LAST_1_HOUR]: '30m',
    [TimeRangeQuickMode.LAST_4_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_12_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_24_HOURS]: '30m',
    [TimeRangeQuickMode.LAST_7_DAYS]: '1h',
    [TimeRangeQuickMode.LAST_30_DAYS]: '3h',
    [TimeRangeQuickMode.LAST_60_DAYS]: '12h',
    [TimeRangeQuickMode.LAST_90_DAYS]: '12h',
    [TimeRangeQuickMode.LAST_6_MONTHS]: '1d',
    [TimeRangeQuickMode.LAST_1_YEAR]: '1d',
    [TimeRangeQuickMode.LAST_2_YEARS]: '1d',
    [TimeRangeQuickMode.LAST_3_YEARS]: '1d',
  },
};
