import React from 'react';
import { string, number } from 'prop-types';
import startCase from 'lodash/startCase';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';
import { Badge } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
// import { hasGroups } from 'utils/permission';
import styles from './Settlement.scss';

const { Description } = DescriptionList;

function getStatus(status) {
  return {
    published: 'blue',
    done: 'success',
  }[status];
}

const SettlementSummary = ({
  merchantName,
  settlementDate,
  referenceDate,
  status,
  amount,
  settlementAmount,
  feeAmount,
  feeTaxAmount,
}) => (
  <DescriptionList className={styles.leftAlignedDescriptionList} size="large" title="Settlement Summary">
    <Description term="Merchant Name">{merchantName === 'all' ? 'All Merchants' : merchantName}</Description>
    <Description term="SettlementDate">{settlementDate ? formatDate(settlementDate, 'YYYY-MM-DD') : '-'}</Description>
    <Description term="Status">
      <div className={styles.settlementStatusText}>
        <Badge status={getStatus(status)} />
        <span>{startCase(status)}</span>
      </div>
    </Description>
    <Description term="Amount">{amount ? commify(amount) : '-'} KRW</Description>
    <Description term="Fee Amount">{feeAmount ? commify(feeAmount) : '-'} KRW</Description>
    <Description term="Fee Tax Amount">{feeTaxAmount ? commify(feeTaxAmount) : '-'} KRW</Description>
    <Description term="Settlement Amount">{settlementAmount ? commify(settlementAmount) : '-'} KRW</Description>
    {/* 자동정산 페이지로 기능 이동 */}
    {/* {status === 'published' && hasGroups(['admin']) && (
      <Description term="Settlement">
        <Button icon="carry-out" type="primary" ghost onClick={onClickedDone}>
          Done
        </Button>
      </Description>
    )} */}
    <Description term="ReferenceDate">{referenceDate || '-'}</Description>
  </DescriptionList>
);

SettlementSummary.propTypes = {
  merchantName: string,
  settlementDate: string,
  referenceDate: string,
  status: string,
  amount: number,
  settlementAmount: number,
  feeAmount: number,
  feeTaxAmount: number,
  // onClickedDone: func.isRequired,
};

SettlementSummary.defaultProps = {
  merchantName: '-',
  settlementDate: null,
  referenceDate: '',
  status: '-',
  amount: 0,
  settlementAmount: 0,
  feeAmount: 0,
  feeTaxAmount: 0,
};

export default SettlementSummary;
