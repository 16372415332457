import React, { Component } from 'react';
import { Button, Form, Upload, Icon, message } from 'antd';
import PropTypes from 'prop-types';

import apolloClient from 'helpers/apolloClient';
import { boltDrawPromotionUploadImageMutation } from 'redux/modules/boltDrawPromotion/mutations';
import { required } from 'utils/formValidator';

import { formItemLayout } from '../Forms/layout';

class BoltDrawPromotionImageUploadForm extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any),
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    upsertImage: PropTypes.func,
    require: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    require: false,
    initial: null,
    upsertImage: undefined,
  };

  idx = 0;

  async onChange({ file }) {
    const { name, form, upsertImage } = this.props;
    try {
      const result = await apolloClient.mutate({
        mutation: boltDrawPromotionUploadImageMutation,
        variables: {
          imageFile: file,
        },
      });

      const { boltDrawPromotionUploadImage: uploadedFile } = result.data;

      form.setFieldsValue({
        [name]: [this.makeFileObj(uploadedFile)],
      });

      if (upsertImage) {
        upsertImage(name);
      }
    } catch (e) {
      message.error(e);
    }
  }

  makeFileObj(file) {
    return {
      uid: (this.idx += 1),
      name: file.filename,
      status: 'done',
      url: `https://static.chai.finance/${file.path}/${file.filename}`,
    };
  }

  onRemove() {
    const { name, form } = this.props;
    form.setFieldsValue({
      [name]: [],
    });
  }

  render() {
    const { form, initial, name, label, require } = this.props;

    form.getFieldDecorator(name, { initialValue: initial, rules: require ? [required()] : [] });
    const formFileList = form.getFieldValue(name);
    let hasError = null;
    if (form.getFieldError(name)) {
      hasError = {
        validateStatus: 'error',
        help: `${label} is required`,
      };
    }

    return (
      <Form.Item label={label} {...formItemLayout} style={{ marginBottom: 20 }} required {...hasError}>
        <Upload
          multiple={false}
          listType="picture-card"
          fileList={formFileList}
          onChange={({ file, fileList }) => this.onChange({ file, fileList })}
          onRemove={() => this.onRemove()}
          beforeUpload={() => false}
          accept=".jpg,.png"
        >
          <Button>
            <Icon type="upload" /> Select File
          </Button>
        </Upload>
      </Form.Item>
    );
  }
}

export default BoltDrawPromotionImageUploadForm;
