import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'antd';
import styles from './Exception.less';

const Exception = ({ className, linkElement = 'a', title, desc, img, actions, ...rest }) => {
  const clsString = classNames(styles.exception, className);
  return (
    <div className={clsString} {...rest}>
      <div className={styles.imgBlock}>
        <div className={styles.imgEle} style={{ backgroundImage: `url(${img})` }} />
      </div>
      <div className={styles.content}>
        <h1>{title}</h1>
        <div className={styles.desc}>{desc}</div>
        <div className={styles.actions}>
          {actions ||
            createElement(
              linkElement,
              {
                to: '/',
                href: '/',
              },
              <Button type="primary">홈으로</Button>
            )}
        </div>
      </div>
    </div>
  );
};

Exception.propTypes = {
  className: PropTypes.string,
  linkElement: PropTypes.func,
  title: PropTypes.string,
  desc: PropTypes.string,
  img: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.any),
};
Exception.defaultProps = {
  className: undefined,
  linkElement: undefined,
  title: undefined,
  desc: undefined,
  img: undefined,
  actions: undefined,
};

export default Exception;
