import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col, Input, DatePicker, Select } from 'antd';
import { rowProps, col4Props, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getBoostPromotionList } from 'redux/modules/boostPromotion/actions';
import { defaultFilter } from 'redux/modules/boostPromotion/reducer';
import styles from './BoostPromotion.scss';

const { Item } = Form;
const { Option } = Select;

const mapStateToProps = state => {
  const { pageSize, filter } = state.boostPromotion;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getBoostPromotionList,
})
@autobind
class BoostPromotionFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getBoostPromotionList: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getBoostPromotionList(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getBoostPromotionList(1, pageSize, {
      ...defaultFilter,
    });

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { visibleFrom, visibleTo, subTitle, status } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          <Col {...col4Props}>
            <Item label="Name">
              {getFieldDecorator('subTitle', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: subTitle,
              })(<Input placeholder="Name" autoComplete="off" />)}
            </Item>
          </Col>

          {/* VisibleFrom */}
          <Col {...col4Props}>
            <Item label="VisibleFrom">
              {getFieldDecorator('visibleFrom', {
                initialValue: visibleFrom,
              })(
                <DatePicker
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '100%' }}
                />
              )}
            </Item>
          </Col>

          {/* VisibleTo */}
          <Col {...col4Props}>
            <Item label="VisibleTo">
              {getFieldDecorator('visibleTo', {
                initialValue: visibleTo,
              })(
                <DatePicker
                  showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: '100%' }}
                />
              )}
            </Item>
          </Col>

          {/* Status */}
          <Col {...col4Props}>
            <Item label="Status">
              {getFieldDecorator('status', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: status || 'enabled',
              })(
                <Select>
                  <Option value="all">All</Option>
                  <Option value="enabled">Enabled</Option>
                  <Option value="disabled">Disabled</Option>
                  <Option value="sold_out">SoldOut</Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row {...rowProps}>
          <Col {...col2RightProps}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default BoostPromotionFilterForm;
