import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider, Button } from 'antd';
import find from 'lodash/find';
import autobind from 'autobind-decorator';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setPageSize, setCurrentPage, getPolicies } from 'redux/modules/policy/actions';

import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { columns } from './PolicyMetadata';
import PolicyTypeModal from './PolicyTypeModal';
import PolicyFilterForm from './Forms/PolicyFilterForm';
import PGFeeForm from './Forms/PGFeeForm';
import PaymentForm from './Forms/PaymentForm';
import ChargeForm from './Forms/ChargeForm';
import WithdrawalForm from './Forms/WithdrawalForm';
import { PolicyType, PolicyTypeText } from './';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.policy;
  const { activeKey, list } = state.tabs.policy;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getPolicies,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PolicyContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getPolicies: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    moveTab: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    activeKey: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  state = {
    showTypeSelect: false,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('policy', listTab);
    }

    this.props.moveTab('policy', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/policy\/([^\/]*)\/?(.+)?/);

    if (match) {
      const type = match[1];
      const id = match[2];
      const hasTab = find(this.props.list, { key: `${type}/${id}` });

      if (!hasTab) {
        if (id === 'add') {
          this.createNewPolicy(type);
          this.props.focusTab('policy', `${type}/add`);
        } else {
          this.openDataTab({ type, id });
          this.props.focusTab('policy', `${type}/${id}`);
        }
      }
    } else {
      this.props.focusTab('policy', 'ListTab');

      const { currentPage, pageSize, filter } = this.props;
      this.props.getPolicies(currentPage, pageSize, filter);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey ||
      this.state.showTypeSelect !== nextState.showTypeSelect
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/policy') {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getPolicies(currentPage, pageSize, filter);
        this.props.focusTab('policy', 'ListTab');
      } else {
        const match = pathname.match(/\/policy\/([^\/]*)\/?(.+)?/);

        if (match) {
          const type = match[1];
          const id = match[2];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: `${type}/${id}` });

          if (!hasTab) {
            if (id === 'add') {
              this.createNewPolicy(type);
            } else {
              this.openDataTab({ type, id });
            }
          }

          this.props.focusTab('policy', `${type}/${id}`);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/policy') {
        this.props.focusTab('policy', 'ListTab');
      }
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;
    this.props.getPolicies(currentPage, newPageSize, filter);
  }

  handleCellClick(record, extra) {
    const { index } = extra;

    if (index === 0) {
      this.openDataTab(record, extra);
    }
  }

  /**
   * Opening tab for display Policies
   * @param {Object} record
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openDataTab(record, extra = {}) {
    let componentType;
    const { type, id } = record;

    if (type === PolicyType.PG_FEE) {
      componentType = 'PGFeeForm';
    } else if (type === PolicyType.PAYMENT) {
      componentType = 'PaymentForm';
    } else if (type === PolicyType.CHARGE) {
      componentType = 'ChargeForm';
    } else if (type === PolicyType.WITHDRAWAL) {
      componentType = 'WithdrawalForm';
    } else {
      throw new Error(`PolicyContainer.openDataTab: Unknown Policy type ${type}`);
    }

    const tabData = new TabData({
      key: `${type}/${id}`,
      title: `${PolicyTypeText[type]} (PO-${id})`,
      closable: true,
      data: {
        id,
      },
      componentType,
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('policy', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('policy', `${type}/${id}`);
      this.props.push(`/policy/${type}/${id}`);
    }
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;
    this.props.getPolicies(newPage, pageSize, filter);
  }

  createNewPolicy(policy) {
    let componentType;

    if (policy === PolicyType.PG_FEE) {
      componentType = 'PGFeeForm';
    } else if (policy === PolicyType.PAYMENT) {
      componentType = 'PaymentForm';
    } else if (policy === PolicyType.CHARGE) {
      componentType = 'ChargeForm';
    } else if (policy === PolicyType.WITHDRAWAL) {
      componentType = 'WithdrawalForm';
    } else {
      throw new Error(`PolicyContainer.createNewPolicy: Invalid Policy ${policy}`);
    }

    const newTab = new TabData({
      key: `${policy}/add`,
      title: `New ${PolicyTypeText[policy]} Policy`,
      closable: true,
      componentType,
    });

    this.props.addTab('policy', newTab);
    this.props.focusTab('policy', newTab.key);
    this.props.push(`/policy/${policy}/add`);

    this.closeModal();
  }

  openModal() {
    this.setState({
      showTypeSelect: true,
    });
  }

  closeModal() {
    this.setState({
      showTypeSelect: false,
    });
  }

  /**
   * Get List Tab
   * @return {ReactElement}
   */
  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;
    const { showTypeSelect } = this.state;

    return (
      <Fragment>
        <PolicyTypeModal visible={showTypeSelect} onSelectedPolicy={this.createNewPolicy} onClose={this.closeModal} />

        <PolicyFilterForm />
        <Divider />

        <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={this.handleCellClick} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          <Button icon="plus-circle" type="primary" onClick={this.openModal}>
            New
          </Button>

          <Divider type="vertical" style={{ background: 'none' }} />

          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getPolicies(currentPage, pageSize, filter);
      this.props.push('/policy');
    } else {
      this.props.push(`/policy/${activeKey}`);
    }
  }

  render() {
    return (
      <TabViewer
        tabKey="policy"
        components={{
          ListTab: this.getListTab(),
          PGFeeForm,
          PaymentForm,
          ChargeForm,
          WithdrawalForm,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default PolicyContainer;
