import gql from 'graphql-tag';

export const userUpdateMutation = gql`
  mutation userUpdate($id: ID!, $status: String) {
    userUpdate(id: $id, status: $status)
  }
`;

export const userUnregisterMutation = gql`
  mutation userUnregister($id: ID!) {
    userUnregister(id: $id)
  }
`;

export const userWithdrawPointMutation = gql`
  mutation userWithdrawPoint($id: ID!, $accountKey: String!) {
    userWithdrawPoint(id: $id, accountKey: $accountKey)
  }
`;

export const revertWithdrawPointMutation = gql`
  mutation revertWithdrawPoint($topupId: ID!) {
    revertWithdrawPoint(topupId: $topupId)
  }
`;

export const deletePGAccountMutation = gql`
  mutation deletePGAccount(
    $userId: ID!
    $sequenceId: ID!
    $bankCode: String!
    $accountNumber: String!
    $createdAt: String!
  ) {
    deletePGAccount(
      userId: $userId
      sequenceId: $sequenceId
      bankCode: $bankCode
      accountNumber: $accountNumber
      createdAt: $createdAt
    )
  }
`;

export const clearVictimMutation = gql`
  mutation clearVictim($id: ID!) {
    clearVictim(id: $id)
  }
`;

export const userOffsetDisposalMutation = gql`
  mutation userOffsetDisposal($id: ID!) {
    userOffsetDisposal(id: $id)
  }
`;

export default {
  userUpdateMutation,
  userUnregisterMutation,
  deletePGAccountMutation,
  clearVictimMutation,
  userWithdrawPointMutation,
  revertWithdrawPointMutation,
  userOffsetDisposalMutation,
};
