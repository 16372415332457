import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon } from 'antd';

import {
  addBoostExclusionRule,
  updateBoostExclusionRule,
  getBoostExclusionRules,
} from 'redux/modules/boostExclusionRule/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';

import { boostExclusionRuleQuery } from 'redux/modules/boostExclusionRule/queries';
import { BoostExclusionType, BoostExclusionTypeText } from './';
import styles from './BoostExclusionRule.scss';
import { BoostSelectForm, BoostTextForm } from '../Forms';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boostExclusionRule;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBoostExclusionRule,
  updateBoostExclusionRule,
  getBoostExclusionRules,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoostExclusionRuleForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    addBoostExclusionRule: PropTypes.func.isRequired,
    updateBoostExclusionRule: PropTypes.func.isRequired,
    getBoostExclusionRules: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    // BoostExclusionRule XHR Status
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boostExclusionRule', id ? id.toString() : 'add');
    this.props.push('/boost/boost_exclusion_rule');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id, form } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: boostExclusionRuleQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.boostExclusionRule;

      form.setFieldsValue({
        type: data.type,
      });

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid BoostExclusionRule ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get BoostExclusionRule: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = ['code'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field]) {
          if (instance && typeof instance.input !== 'undefined') {
            instance.input.focus();
          } else if (instance && typeof instance.picker !== 'undefined') {
            instance.picker.input.focus();
          } else if (instance && typeof instance.inputNumberRef !== 'undefined') {
            instance.inputNumberRef.input.focus();
          }
          return;
        }
      }
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.policyType = formFields.policyType;
    fields.receiverType = formFields.receiverType;
    fields.type = formFields.type;
    fields.codeType = formFields.codeType;
    fields.code = formFields.code;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoostExclusionRule(input);

    this.props.removeAndFocusPreviousTab('boostExclusionRule', 'add');
    this.props.push('/boost/boost_exclusion_rule');
    this.refreshBoostExclusionRuleList();
  }

  async update(input) {
    await this.props.updateBoostExclusionRule(input);

    this.props.removeAndFocusPreviousTab('boostExclusionRule', input.id.toString());
    this.props.push('/boost/boost_exclusion_rule');
    this.refreshBoostExclusionRuleList();
  }

  refreshBoostExclusionRuleList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoostExclusionRules(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    const exclusiontType = form.getFieldValue('type') || data.type || BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST;
    const defaultCodeType =
      exclusiontType === BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST_WHITELIST ? 'brandId' : 'businessCode';

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
        <BoostSelectForm
          form={form}
          label="Type"
          name="type"
          initial={data.type ? data.type : BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST}
          items={Object.values(BoostExclusionType).map(value => ({
            label: BoostExclusionTypeText[value],
            value,
          }))}
        />
        <BoostSelectForm
          form={form}
          label="Code Type"
          name="codeType"
          initial={data.codeType ? data.codeType : defaultCodeType}
          items={
            exclusiontType === BoostExclusionType.PAYMENT_CAN_NOT_USE_BOOST_WHITELIST
              ? [{ label: 'Brand Id', value: 'brandId' }]
              : [
                { label: '업종코드', value: 'businessCode' },
                { label: '업종명', value: 'businessName' },
                { label: '가맹점명', value: 'cardMerchantName' },
                { label: '가맹점 코드', value: 'cardMerchantId' },
                { label: 'From Id', value: 'fromId' },
              ]
          }
        />
        <BoostTextForm form={form} label="Code" name="code" initial={data.code} require />

        <div className={styles.formButtonContainer}>
          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit" disabled={updating}>
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default BoostExclusionRuleForm;
