import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, Divider, Button, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import DataViewer from 'components/DataViewer';
import { getBoltExclusionRules, setPageSize, setCurrentPage } from 'redux/modules/boltExclusionRule/actions';

import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { columns } from './BoltExclusionRuleMetadata';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.boltExclusionRule;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  addTab,
  push,
  focusTab,
  moveTab,
  getBoltExclusionRules,
  setPageSize,
  setCurrentPage,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoltExclusionRuleList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.objectOf(PropTypes.any),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    getBoltExclusionRules: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,

    openDataTab: PropTypes.func.isRequired,

    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getBoltExclusionRules(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getBoltExclusionRules(newPage, pageSize, filter);
  }

  createNewBoltExclusionRule() {
    const newTab = new TabData({
      key: 'add',
      title: 'New Bolt Exclusion Rule',
      closable: true,
      data: {
        id: 'add',
      },
      componentType: 'BoltExclusionRuleForm',
    });

    this.props.addTab('boltExclusionRule', newTab);
    this.props.focusTab('boltExclusionRule', newTab.key);
    this.props.push('/boost/bolt_exclusion_rule/add');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  handleCellClick(record, extra) {
    const { id, title } = record;
    const { index } = extra;

    if (index === 0) {
      this.props.openDataTab(id, { ...extra, title });
    }
  }

  render() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <>
        <Divider />

        {/* Add User Button */}
        <Row type="flex" gutter={12}>
          <Col>
            <Button icon="plus-circle" type="primary" onClick={this.createNewBoltExclusionRule}>
              New
            </Button>
          </Col>
        </Row>

        <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={this.handleCellClick} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </>
    );
  }
}

export default BoltExclusionRuleList;
