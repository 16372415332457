/**
 * Check inputString has consecutive characters
 * @param {string} input Input String
 * @param {number} number=4 Number of consecution
 * @return {bool} Returns true if input string has consecutive characters
 */
export function hasConsecutiveCharacters(input, length = 4) {
  if (input.length < length || length <= 1) return false;

  const splittedInput = input.split('');
  let consecutionCounter = 1;
  let hasConsecutive = false;

  for (let i = 0; i < splittedInput.length - 1; i += 1) {
    const charCode = splittedInput[i].charCodeAt(0);
    const nextCharCode = splittedInput[i + 1].charCodeAt(0);

    if (charCode + 1 === nextCharCode) {
      consecutionCounter += 1;
    } else {
      consecutionCounter = 1;
    }

    if (consecutionCounter === length) {
      hasConsecutive = true;
      break;
    }
  }

  return hasConsecutive;
}

export default {
  hasConsecutiveCharacters,
};
