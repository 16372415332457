import apolloClient from 'helpers/apolloClient';
import { transactionListQuery } from './queries';

export const FETCH_TRANSACTION = 'transaction/FETCH';
export const FETCH_TRANSACTION_SUCCESS = 'transaction/FETCH_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'transaction/FETCH_FAILURE';

export const SET_FILTER = 'transaction/SET_FILTER';
export const RESET_FILTER = 'transaction/RESET_FILTER';

export const SET_CURRENT_PAGE = 'transaction/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'transaction/SET_PAGE_SIZE';

export function getTransactions(currentPage, pageSize, filter) {
  return {
    types: [FETCH_TRANSACTION, FETCH_TRANSACTION_SUCCESS, FETCH_TRANSACTION_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: transactionListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.transactionList;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
