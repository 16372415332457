import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, Divider, Row, Col, Button } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import DataViewer from 'components/DataViewer';
import {
  getLocationCollectHistoryList,
  setPageSize,
  setCurrentPage,
} from 'redux/modules/locationCollectHistory/actions';

import apolloClient from 'helpers/apolloClient';
import gql from 'graphql-tag';

import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { columns } from './LocationCollectHistoryMetadata';
import LocationCollectHistoryFilterForm from './LocationCollectHistoryFilterForm';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.locationCollectHistory;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  addTab,
  push,
  focusTab,
  moveTab,
  getLocationCollectHistoryList,
  setPageSize,
  setCurrentPage,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class LocationCollectHistoryList extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    getLocationCollectHistoryList: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getLocationCollectHistoryList(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getLocationCollectHistoryList(newPage, pageSize, filter);
  }

  // 테스트용 데이터 추가
  async addTestData() {
    await apolloClient.query({
      query: gql`
        query testAddCollectHistoryList {
          testAddCollectHistoryList
        }
      `,
    });
    const { currentPage, pageSize, filter } = this.props;
    this.props.getLocationCollectHistoryList(currentPage, pageSize, filter);
  }

  render() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <>
        <LocationCollectHistoryFilterForm />
        <Divider />

        {/* Add User Button */}
        <Row type="flex" gutter={12}>
          <Col>
            <Button icon="plus-circle" type="primary" onClick={this.addTestData.bind(this)}>
              Test Add
            </Button>
          </Col>
        </Row>

        <DataViewer columns={columns} data={data} error={error} loading={loading} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </>
    );
  }
}

export default LocationCollectHistoryList;
