import gql from 'graphql-tag';

export const extendSessionMutation = gql`
  mutation extendSession {
    extendSession
  }
`;

export default {
  extendSessionMutation,
};
