import gql from 'graphql-tag';

export const promotionListQuery = gql`
  query promotionList($filter: String, $skip: Int, $pageSize: Int) {
    promotionList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        title
        description
        code
        status
        policyType
        benefitType
        issueType
        startAt
        endAt
        couponStartDate
        couponDueDate
        isSelectable
        ttl
        ttlType
        used
        issued
        discount
        maxDiscountAmount
        priceMin
        priceMax
        maxPerPolicy
        maxPerUser
        pid
        merchants {
          id
          name
          displayName
        }
        subMerchants {
          subMerchant {
            id
            name
          }
        }
      }
      total
    }
  }
`;

export const dummyPromotionListQuery = gql`
  query dummyPromotionList {
    dummyPromotionList {
      list {
        id
        createdAt
        updatedAt
        title
        description
        code
        status
        policyType
        benefitType
        issueType
        startAt
        endAt
        couponStartDate
        couponDueDate
        isSelectable
        ttl
        ttlType
        discount
        priceMin
        priceMax
        maxPerPolicy
        maxPerUser
        merchants {
          id
          name
          displayName
        }
      }
      total
    }
  }
`;

export default {
  promotionListQuery,
  dummyPromotionListQuery,
};
