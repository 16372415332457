import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Card, Divider, Alert, Button, message, Collapse, Badge } from 'antd';
import { removeAndFocusPreviousTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import startCase from 'lodash/startCase';
import { commify } from 'utils/stringUtil';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import 'ant-design-pro/dist/ant-design-pro.css';
import { formatDate, formatPhoneNumber } from 'utils/format';
import CopyableText from 'components/CopyableText';
import { transactionQuery } from './TransactionQueries';
import styles from './Transaction.scss';

const { Description } = DescriptionList;
const { Panel } = Collapse;

const mapStateToProps = state => ({
  tabList: state.tabs.transaction.list,
});

@connect(mapStateToProps, {
  removeAndFocusPreviousTab,
})
@autobind
class TransactionDetail extends Component {
  static propTypes = {
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
  };

  state = {
    data: {},
    error: null,
    loading: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: transactionQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.transaction) {
        throw new Error('Invalid Transaction ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.transaction,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Transaction: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('transaction', id);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  /**
   * Get Status text of User Status for Badge
   * @param {string} status
   * @return {string}
   */
  getUserStatus(status) {
    return {
      activated: 'success',
      identified: 'processing',
      unidentified: 'warning',
    }[status];
  }

  /**
   * Get Status text of Merchant Status for Badge
   * @param {string} status
   * @return {string}
   */
  getMerchantStatus(status) {
    return {
      enabled: 'success',
      disabled: 'error',
    }[status];
  }

  render() {
    const { id } = this.props;
    const { error, loading, data } = this.state;
    const { createdAt, type, currency, delta, balance, data: txData, ledger = {} } = data;
    const { user, merchant } = ledger;

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <div>
        <Card bordered={false}>
          <DescriptionList
            className={styles.leftAlignedDescriptionList}
            size="large"
            title="Transaction Details"
            style={{ marginBottom: 32 }}
          >
            <Description term="ID">
              <CopyableText tooltip={id} value={id}>
                {id.substr(0, 20)}...
              </CopyableText>
            </Description>
            <Description term="Created At">{formatDate(createdAt)}</Description>
            <Description term="Type">{startCase(type)}</Description>
            <Description term="Currency">{currency && currency.toUpperCase()}</Description>
            <Description term="Amount">{delta ? commify(delta) : '-'}</Description>
            <Description term="Balance">{balance ? commify(balance) : '-'}</Description>
          </DescriptionList>

          {type === 'charge_in' && (
            <Fragment>
              <Divider style={{ marginBottom: 32 }} />

              <DescriptionList
                className={styles.leftAlignedDescriptionList}
                size="large"
                title="Charge Details"
                style={{ marginBottom: 32 }}
              >
                <Description term="Payment ID">
                  <CopyableText tooltip={txData.paymentId} value={txData.paymentId}>
                    {txData.paymentId && txData.paymentId.substr(0, 20)}...
                  </CopyableText>
                </Description>
              </DescriptionList>

              <Collapse bordered={false} style={{ border: '1px solid #d8d8d8', borderRadius: 5 }}>
                <Panel header="PG Result (Click to open)">
                  <Card>
                    <pre>{JSON.stringify(txData.pgResult || {}, null, 2)}</pre>
                  </Card>
                </Panel>
              </Collapse>
            </Fragment>
          )}

          {user && (
            <DescriptionList
              className={styles.leftAlignedDescriptionList}
              size="large"
              title="User Details"
              style={{ marginBottom: 32 }}
            >
              <Description term="User ID">
                <CopyableText tooltip={user.id} value={user.id}>
                  {user.id.substr(0, 20)}...
                </CopyableText>
              </Description>
              <Description term="Name">{user.fullname}</Description>
              <Description term="Status">
                <Badge status={this.getUserStatus(user.status)} />
                <span>{startCase(user.status)}</span>
              </Description>
              <Description term="Gender">{startCase(user.gender)}</Description>
              <Description term="Phone">{formatPhoneNumber(user.phone)}</Description>
            </DescriptionList>
          )}

          {merchant && (
            <DescriptionList
              className={styles.leftAlignedDescriptionList}
              size="large"
              title="Merchant Details"
              style={{ marginBottom: 32 }}
            >
              <Description term="Merchant ID">
                <CopyableText tooltip={merchant.id} value={merchant.id}>
                  {merchant.id.substr(0, 20)}...
                </CopyableText>
              </Description>
              <Description term="Name">{merchant.displayName}</Description>
              <Description term="Status">
                <Badge status={this.getMerchantStatus(merchant.status)} />
                <span>{startCase(merchant.status)}</span>
              </Description>
            </DescriptionList>
          )}
        </Card>
      </div>
    );
  }
}

export default TransactionDetail;
