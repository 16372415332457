import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col, Input, DatePicker } from 'antd';
import { rowProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getSettlementTransfer } from 'redux/modules/settlementTable/actions';

const { RangePicker } = DatePicker;

const { Item } = Form;

const mapStateToProps = state => {
  const { filter } = state.settlementTable;

  return {
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getSettlementTransfer,
})
@autobind
class SettlementHistoryFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getSettlementTransfer: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const { period, query } = formFields;
    const newFilter = {
      query,
    };

    if (period?.length > 0) {
      const [fromDate, toDate] = period;
      newFilter.fromDate = fromDate;
      newFilter.toDate = toDate;
    }

    this.props.setFilter(newFilter);
    this.props.getSettlementTransfer(newFilter);
  }

  resetForm() {
    this.props.resetFilter();
    this.props.getSettlementTransfer();
    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { query, period } = filter;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* ID */}
          <Col sm={7} md={7}>
            <Item>
              {getFieldDecorator('query', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: query,
              })(<Input placeholder="ID or Merchant Name" autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>
          {/* Date Range */}
          <Col sm={7} md={7}>
            <Item>
              {getFieldDecorator('period', {
                initialValue: period,
              })(<RangePicker style={{ width: '100%' }} />)}
            </Item>
          </Col>

          <Col xl={3}>
            <Item>
              <SearchButton />
            </Item>
          </Col>
          <Col xl={3}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default SettlementHistoryFilterForm;
