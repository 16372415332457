import React, { PureComponent } from 'react';
import { bool, func, objectOf, any } from 'prop-types';
import { Modal, notification, Form, Input, Select } from 'antd';
import autobind from 'autobind-decorator';
import apolloClient from 'helpers/apolloClient';
import { setAdminAllowedIpMutation } from '../AdminMutations';

@autobind
class EditAdminAllowedIpModal extends PureComponent {
  static propTypes = {
    data: objectOf(any),
    visible: bool.isRequired,
    onSubmit: func.isRequired,
    onClose: func.isRequired,
  };

  static defaultProps = {
    data: null,
  };

  state = {
    autoComplete: '',
    ipList: null,
  };

  closeModal() {
    this.props.onClose();
    this.setState({
      autoComplete: '',
      ipList: null,
    });
  }

  async saveAdmin() {
    const { data } = this.props;
    const { ipList } = this.state;

    await apolloClient.mutate({
      mutation: setAdminAllowedIpMutation,
      variables: {
        id: data.id,
        ipList,
      },
    });

    this.props.onClose();
    this.props.onSubmit();
    this.setState({
      autoComplete: '',
      ipList: null,
    });

    notification.success({
      placement: 'bottomLeft bottomRight',
      message: 'Saved',
    });
  }

  getFormattedData() {
    const { data } = this.props;
    let formattedData = {};

    if (data) {
      const { uid, allowedIp } = data;

      formattedData = {
        uid,
        allowedIp,
      };
    }

    return formattedData;
  }

  selectSearch(e) {
    this.setState({
      autoComplete: e,
    });
  }

  selectChange(e) {
    this.setState({
      autoComplete: '',
      ipList: (e || [])
        .map(d => d.key)
        .filter(ip =>
          ip.match(
            /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
          )),
    });
  }

  render() {
    const { visible } = this.props;
    const { autoComplete, ipList } = this.state;
    const data = this.getFormattedData();
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    return (
      <Modal
        visible={visible}
        cancelText="Close"
        okText="Save"
        title="Edit Allowed IP"
        onCancel={this.closeModal}
        onOk={this.saveAdmin}
        destroyOnClose
      >
        <Form {...formItemLayout}>
          <Form.Item label="Admin ID">
            <Input value={data.uid} disabled />
          </Form.Item>
          <Form.Item label="Allowed IP">
            <Select
              mode="multiple"
              labelInValue
              value={(ipList || data.allowedIp || []).map(ip => ({ key: ip, label: ip }))}
              placeholder="Please enter an IP address"
              onSearch={this.selectSearch}
              onChange={this.selectChange}
              style={{ width: '100%' }}
            >
              {autoComplete && !(ipList || data.allowedIp || []).includes(autoComplete) && (
                <Select.Option key={autoComplete}>{autoComplete}</Select.Option>
              )}
              {(ipList || data.allowedIp || []).map(d => (
                <Select.Option key={d}>{d}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default EditAdminAllowedIpModal;
