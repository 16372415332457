import {
  FETCH_BRAND,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_FAILURE,
  ADD_BRAND,
  ADD_BRAND_SUCCESS,
  ADD_BRAND_FAILURE,
  UPDATE_BRAND,
  UPDATE_BRAND_SUCCESS,
  UPDATE_BRAND_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
} from './actions';

export const defaultFilter = {
  status: 'enabled',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: {
    ...defaultFilter,
  },
  updating: false,
  updateError: null,
  uploading: false,
};

export default function BrandReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADD_BRAND:
    case UPDATE_BRAND:
      return {
        ...state,
        updating: true,
        updateError: null,
      };
    case ADD_BRAND_SUCCESS:
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        updating: false,
        updateError: null,
      };
    case ADD_BRAND_FAILURE:
    case UPDATE_BRAND_FAILURE:
      return {
        ...state,
        updating: false,
        updateError: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: { ...defaultFilter },
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    default:
      return state;
  }
}
