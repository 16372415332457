import React from 'react';
import { Dropdown, Button, Icon } from 'antd';
import { func, number, string, arrayOf } from 'prop-types';
import PageSizeDropdownMenu from './PageSizeDropdownMenu';

const PageSizeDropdown = ({ onPageSizeChange, currentPageSize, pageSizes, icon }) => (
  <Dropdown overlay={<PageSizeDropdownMenu pageSizes={pageSizes} onPageSizeChange={onPageSizeChange} />}>
    <Button style={{ marginLeft: 8 }}>
      <Icon type={icon} />
      <span>{currentPageSize} Rows</span>
    </Button>
  </Dropdown>
);

PageSizeDropdown.propTypes = {
  onPageSizeChange: func.isRequired,
  currentPageSize: number.isRequired,
  pageSizes: arrayOf(number),
  icon: string,
};

PageSizeDropdown.defaultProps = {
  pageSizes: [10, 20, 50, 100],
  icon: 'up',
};

export default PageSizeDropdown;
