import socketClusterClient from 'socketcluster-client';
import debug from 'debug';

const log = debug('socket:log');
const error = debug('socket:error');

class Socket {
  constructor(options) {
    this.socket = socketClusterClient.connect(options);

    this.on('connect', this.onConnect.bind(this));
    this.on('error', this.onError.bind(this));
    this.on('disconnect', this.onDisconnect.bind(this));
    this.on('authenticate', this.onAuthenticate.bind(this));
    this.on('deauthenticate', this.onDeauthenticate.bind(this));
  }

  destroy() {
    socketClusterClient.destroy(this.socket);
  }

  on(...args) {
    this.socket.on(...args);
  }

  emit(...args) {
    this.socket.emit(...args);
  }

  onConnect(status) {
    this.id = status.id;
    log(`${this.id} connected. isAuthenticated: ${status.isAuthenticated}`);
  }

  onAuthenticate() {
    log(`${this.id} authenticated. authToken ${this.socket.getSignedAuthToken()}`);
  }

  onDeauthenticate() {
    log(`${this.id} deauthenticated.`);
  }

  isAuthenticated() {
    return this.socket.authState === this.socket.AUTHENTICATED;
  }

  onError(err) {
    error(`${this.id} error: ${err.message}`);
  }

  onDisconnect() {
    log(`${this.id} disconnected`);
  }

  subscribe(channel, callback) {
    this.socket.subscribe(channel).watch(callback);
  }

  unsubscribe(channel) {
    this.socket.unsubscribe(channel);
    this.socket.unwatch(channel);
  }
}

export default Socket;
