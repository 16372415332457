import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Form, Button } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';

import { queryDefault, graphQueryToState } from 'utils/graphToStateUtil';
import apolloClient from 'helpers/apolloClient';

import { ChaiTextForm, ChaiSelectForm } from 'common';
import { exchangePropertyRuleAdd, exchangePropertyRuleUpdate, exchangePropertyRuleDel } from './gql/mutations';
import { exchangePropertyRuleQuery } from './gql/queries';

import { listPathName } from './ExchangePaymentRuleContainer';

const mapDispatchToProps = {
  push,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class ExchangePaymentRuleList extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    push: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    targetId: PropTypes.string,
  };

  static defaultProps = {
    targetId: null,
  };

  state = {
    confirmModalVisible: false,
    ...queryDefault,
  };

  componentDidMount() {
    const { targetId } = this.props;
    if (targetId) {
      this.getRule(targetId);
    } else {
      this.makeEmptyRule();
    }
  }

  onCancel() {
    this.props.push(listPathName);
  }

  async onSubmit() {
    this.props.form.validateFields(this.handleSubmit);
  }

  async handleSubmit(err) {
    if (err) {
      return;
    }

    const variables = this.props.form.getFieldsValue();
    await apolloClient.mutate({
      mutation: variables.id ? exchangePropertyRuleUpdate : exchangePropertyRuleAdd,
      variables,
    });
    this.props.push(listPathName);
  }

  onConfirmDelete() {
    this.setState({
      confirmModalVisible: true,
    });
  }

  async getRule(id) {
    try {
      await graphQueryToState({
        onSetState: args => this.setState(args),
        key: 'exchangePropertyRule',
        query: exchangePropertyRuleQuery,
        id,
      });
    } catch (e) {
      console.error(e);
    }
  }

  async submitDelete() {
    const id = this.props.form.getFieldValue('id');
    if (!id) {
      return;
    }
    await apolloClient.mutate({
      mutation: exchangePropertyRuleDel,
      variables: { id },
    });
    this.props.push(listPathName);
    this.setState({
      confirmModalVisible: false,
    });
  }

  closeConfirm() {
    this.setState({
      confirmModalVisible: false,
    });
  }

  makeEmptyRule() {
    this.setState({
      ...queryDefault,
      data: {},
    });
  }

  render() {
    const { form } = this.props;
    const { loading, data, confirmModalVisible } = this.state;

    return (
      <Modal
        visible={this.props.visible}
        cancelText="Cancel"
        okText="Save"
        title="Edit exchange payment rule"
        onCancel={this.onCancel}
        onOk={this.onSubmit}
        destroyOnClose
      >
        {!loading && data && (
          <Form>
            <ChaiTextForm form={form} label="ID" name="id" initial={data.id} disabled />
            <ChaiSelectForm
              form={form}
              label="Rule type"
              name="ruleCodeType"
              initial={data.ruleCodeType}
              items={[
                { label: 'Business Code', value: 'businessCode' },
                { label: 'Business Name', value: 'businessName' },
                { label: 'Merchant Name', value: 'merchantName' },
                { label: 'Merchant Id', value: 'merchantId' },
              ]}
              required
            />
            <ChaiTextForm form={form} label="Rule content" name="code" initial={data.code} required />
            <div style={{ textAlign: 'right' }}>
              <Button type="danger" icon="delete" onClick={this.onConfirmDelete}>
                Delete Rule
              </Button>
            </div>
            <Modal
              visible={confirmModalVisible}
              cancelText="Cancel"
              okText="Delete"
              title="Delete confirm"
              onCancel={this.closeConfirm}
              onOk={this.submitDelete.bind(this)}
            >
              Delete this rule?
            </Modal>
          </Form>
        )}
      </Modal>
    );
  }
}

export default ExchangePaymentRuleList;
