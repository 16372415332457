import React, { Component } from 'react';
import { Button, Form, Upload, Icon } from 'antd';
import PropTypes from 'prop-types';
import * as uuid from 'uuid';

import { required } from 'utils/formValidator';

class ContentUploadForm extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    initial: PropTypes.arrayOf(PropTypes.any),
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    require: PropTypes.bool,
  };

  static defaultProps = {
    require: false,
    initial: null,
  };

  async onChange(info) {
    const { fileList } = info;
    if (fileList.length === 0) return;

    const { name, form } = this.props;

    const { file } = info;
    const { name: sourceFileName } = file;

    // Set Field
    form.setFieldsValue({
      [name]: [
        {
          uid: uuid.v4(),
          name: sourceFileName,
          status: 'done',
          file,
          sourceFileName,
        },
      ],
    });
  }

  onRemove() {
    const { name, form } = this.props;
    form.setFieldsValue({
      [name]: [],
    });
  }

  render() {
    const { form, initial, name, label, require } = this.props;

    form.getFieldDecorator(name, { initialValue: initial, rules: require ? [required()] : [] });
    const formFileList = form.getFieldValue(name);
    let hasError = null;
    if (form.getFieldError(name)) {
      hasError = {
        validateStatus: 'error',
        help: `${label}은 필수 값입니다`,
      };
    }

    return (
      <Form.Item label={label} required {...hasError}>
        <Upload
          multiple={false}
          fileList={formFileList}
          onChange={info => this.onChange(info)}
          onRemove={() => this.onRemove()}
          beforeUpload={() => false}
          accept=".jpg,.png,.mp4"
        >
          <Button>
            <Icon type="upload" />
            파일 선택
          </Button>
        </Upload>
      </Form.Item>
    );
  }
}

export default ContentUploadForm;
