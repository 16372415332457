import React, { Component } from 'react';
import { func, any, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import { message } from 'antd';
import { addFaq, updateFaq, removeFaq } from 'redux/modules/faq/actions';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import FaqList from './List/FaqList';
import FaqForm from './Form/FaqForm';
import { FaqMode } from './FaqConstants';
import styles from './Faq.scss';

const mapDispatchToProps = {
  push,
  addFaq,
  updateFaq,
  removeFaq,
};

@connect(null, mapDispatchToProps)
@autobind
class FaqContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    addFaq: func.isRequired,
    updateFaq: func.isRequired,
    removeFaq: func.isRequired,
    history: objectOf(any).isRequired,
    location: objectOf(any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showList: true,
      mode: null,
      id: null,
    };

    this.setChildComponent();
  }

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/operation/faq') {
        this.setState({ showList: true });
      } else {
        const match = pathname.match(/\/operation\/faq\/(.+)/);

        if (match) {
          const id = match[1];

          if (id === 'add') {
            this.createNew();
          } else {
            this.open({ id });
          }
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/operation/faq') {
        this.setState({ showList: true });
      }
    }
  }

  setChildComponent() {
    const { pathname } = this.props.location;

    const match = pathname.match(/\/operation\/faq\/(.+)/);

    if (match) {
      const id = match[1];

      // ESLint doesn't allow changing state but this function invokes from constructor, so it's fine.
      /* eslint-disable */
      this.state = {
        ...this.state,
        showList: false,
        mode: id === 'add' ? FaqMode.ADD : FaqMode.UPDATE,
        id,
      };
      /* eslint-enable */
    } else {
      /* eslint-disable */
      this.state = {
        showList: true,
      };
      /* eslint-enable */
    }
  }

  createNew() {
    this.setState({
      showList: false,
      mode: FaqMode.ADD,
      id: null,
    });
  }

  createNewWithPush() {
    this.createNew();
    this.props.push('/operation/faq/add');
  }

  open(faq) {
    this.setState({
      showList: false,
      mode: FaqMode.UPDATE,
      id: faq.id,
    });
  }

  openWithPush(faq) {
    this.open(faq);
    this.props.push(`/operation/faq/${faq.id}`);
  }

  switchToList() {
    this.setState({
      showList: true,
    });

    this.props.push('/operation/faq');
  }

  async handleSubmit(form) {
    try {
      const { mode } = this.state;

      if (mode === FaqMode.ADD) {
        await this.props.addFaq(form);
      } else {
        await this.props.updateFaq(form);
      }

      this.setState({
        showList: true,
      });

      this.props.push('/operation/faq');
    } catch (err) {
      message.error(`Failed to save faq: ${err.message}`);
      console.log(err);
    }
  }

  async handleRemoveFaq(id) {
    try {
      await this.props.removeFaq(id);
      message.success('Removed.');

      this.setState({ showList: true });
      this.switchToList();
    } catch (err) {
      message.error(`Failed to remove faq: ${err.message}`);
      console.log(err);
    }
  }

  render() {
    const { showList, mode, id } = this.state;

    return (
      <div className={styles.faqContainer}>
        {showList && <FaqList onCreate={this.createNewWithPush} onOpen={this.openWithPush} />}

        {!showList && (
          <FaqForm
            onSubmit={this.handleSubmit}
            onCancel={this.switchToList}
            onRemove={this.handleRemoveFaq}
            mode={mode}
            id={id}
          />
        )}
      </div>
    );
  }
}

export default FaqContainer;
