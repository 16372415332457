export default {
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Username...',
    },
    'ui:title': 'Username',
    'ui:description': '',
  },

  email: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'user@example.com',
    },
    'ui:title': 'Email',
    'ui:description': '',
  },
};
