import gql from 'graphql-tag';

export const agreementListQuery = gql`
  query agreementList {
    agreementList {
      list {
        id
        group
        title
        description
        optional
        isActive
        sortedIndex
        createdAt
        updatedAt
        issueDate
        author
        reagree
      }
      total
    }
  }
`;

export default {
  agreementListQuery,
};
