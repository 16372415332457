import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';

const itemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const PaymentCancelFormItem = ({ children, ...props }) => (
  <Form.Item {...props} {...itemLayout} className={props.className} labelCol={{ span: 7 }}>
    {children}
  </Form.Item>
);

PaymentCancelFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

PaymentCancelFormItem.defaultProps = {
  className: null,
};

export default PaymentCancelFormItem;
