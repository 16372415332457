import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';

const NoContract = props => (
  <DescriptionList size="large" {...(props.showTitle ? { title: 'Active Contract' } : {})} style={{ marginBottom: 32 }}>
    <Alert
      message="Contract not found!"
      description="Press [Create] button to add new contract."
      type="info"
      showIcon
    />
  </DescriptionList>
);

NoContract.propTypes = {
  showTitle: PropTypes.bool,
};

NoContract.defaultProps = {
  showTitle: true,
};

export default NoContract;
