export default {
  registerDateRange: [
    {
      type: 'array',
    },
  ],
  status: [],
  id: [
    {
      type: 'string',
    },
  ],
  fullname: [
    {
      type: 'string',
    },
  ],
};
