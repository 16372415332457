import gql from 'graphql-tag';

const MERCHANT_LIST = gql`
  query merchantList {
    merchantList(pageSize: 99999) {
      list {
        id
        name
        displayName
      }
    }
  }
`;

export default MERCHANT_LIST;
