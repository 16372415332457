import apolloClient from 'helpers/apolloClient';
import { bankListQuery } from './queries';
import { bankAddMutation, bankUpdateMutation } from './mutations';

export const FETCH_BANK = 'bank/FETCH';
export const FETCH_BANK_SUCCESS = 'bank/FETCH_SUCCESS';
export const FETCH_BANK_FAILURE = 'bank/FETCH_FAILURE';

export const ADD_BANK = 'bank/ADD';
export const ADD_BANK_SUCCESS = 'bank/ADD_SUCCESS';
export const ADD_BANK_FAILURE = 'bank/ADD_FAILURE';

export const UPDATE_BANK = 'bank/UPDATE';
export const UPDATE_BANK_SUCCESS = 'bank/UPDATE_SUCCESS';
export const UPDATE_BANK_FAILURE = 'bank/UPDATE_FAILURE';

export const SET_FILTER = 'bank/SET_FILTER';
export const RESET_FILTER = 'bank/RESET_FILTER';

export const SET_CURRENT_PAGE = 'bank/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'bank/SET_PAGE_SIZE';

export function getBankList(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BANK, FETCH_BANK_SUCCESS, FETCH_BANK_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: bankListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.bankList;
    },
  };
}

export function addBank(bank) {
  return {
    types: [ADD_BANK, ADD_BANK_SUCCESS, ADD_BANK_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: bankAddMutation,
        variables: bank,
      });

      return result.data.bankAdd;
    },
  };
}

export function updateBank(bank) {
  return {
    types: [UPDATE_BANK, UPDATE_BANK_SUCCESS, UPDATE_BANK_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: bankUpdateMutation,
        variables: bank,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
