import React, { Component } from 'react';
import { func, bool, arrayOf, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { getSettlementPreview, settlementUpdate, merchantBalanceTransfer } from 'redux/modules/settlementTable/actions';
import { Table, Button, Modal, Input, InputNumber, Form, message, Select } from 'antd';
import { commify } from 'utils/stringUtil';
import LinkText from 'components/LinkText';
import styles from './SettlementTable.scss';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const mapStateToProps = state => {
  const { data, loading } = state.settlementTable;

  return {
    data,
    loading,
  };
};

const mapDispatchToProps = {
  getSettlementPreview,
  settlementUpdate,
  merchantBalanceTransfer,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class SettlementTableContainer extends Component {
  static propTypes = {
    getSettlementPreview: func.isRequired,
    settlementUpdate: func.isRequired,
    merchantBalanceTransfer: func.isRequired,
    form: objectOf(any).isRequired,
    loading: bool.isRequired,
    data: arrayOf(any).isRequired,
  };

  state = {
    adjModalVisible: false,
    confirmModalVisible: false,
    transferModalVisible: false,
    impossibleSettlementShow: false,
    setException: false,
    adjModalRow: {},
    adjustments: {},
    selectedRowKeys: [],
    settlementTargets: [],
    transferModalRow: {},
  };

  componentWillMount() {}

  componentDidMount() {
    this.props.getSettlementPreview();
  }

  componentWillUnmount() {}

  onOpenAdjustmentModal(row) {
    const { form } = this.props;
    const { adjustments } = this.state;

    if (adjustments[row.merchantId]) {
      form.setFieldsValue({
        modalAdjustmentAmount: adjustments[row.merchantId].amount,
        modalAdjustmentMemo: adjustments[row.merchantId].memo,
      });
    } else {
      form.setFieldsValue({
        modalAdjustmentAmount: 0,
        modalAdjustmentMemo: '',
      });
    }
    this.setState({ adjModalVisible: true, adjModalRow: row });
  }

  onOpenConfirmModal(rows, setException) {
    this.setState({ confirmModalVisible: true, settlementTargets: rows, setException });
  }

  onOpenTransferModal(row) {
    this.setState({ transferModalVisible: true, transferModalRow: row });
  }

  onCloseModal() {
    this.setState({ adjModalVisible: false, confirmModalVisible: false, transferModalVisible: false });
  }

  onConfirmAdjustment() {
    const { form } = this.props;
    const { adjustments, adjModalRow } = this.state;

    const amount = form.getFieldValue('modalAdjustmentAmount');
    const memo = form.getFieldValue('modalAdjustmentMemo');

    if (!amount || !memo) {
      message.warning('필수값을 입력해주세요');
      return;
    }

    this.setState({
      adjModalVisible: false,
      adjustments: {
        ...adjustments,
        [adjModalRow.merchantId]: {
          amount,
          memo,
          merchantId: adjModalRow.merchantId,
        },
      },
    });
  }

  async onConfirmTransfer() {
    const { form } = this.props;
    const { transferModalRow } = this.state;

    const toMerchant = form.getFieldValue('toMerchant');
    const transferAmount = Number(form.getFieldValue('transferAmount'));

    if (!Number.isInteger(transferAmount) || transferAmount <= 0) {
      message.warning('숫자만 입력해주세요');
      return;
    }

    if (transferAmount > transferModalRow.balance) {
      message.warning('머천트 잔액보다 큰 금액을 옮길 수 없습니다.');
      return;
    }

    if (!toMerchant) {
      message.warning('받을 머천트를 선택해주세요');
      return;
    }

    await this.props.merchantBalanceTransfer(transferModalRow.merchantId, toMerchant, transferAmount);

    this.onCloseModal();
    this.props.getSettlementPreview();
  }

  async onConfirmSettlement() {
    const { form } = this.props;
    const transferException = form.getFieldValue('transferException');
    const { adjustments, settlementTargets, setException } = this.state;

    if (setException && !transferException.trim()) {
      message.warning('미송금 사유를 입력해주세요');
      return;
    }

    await this.props.settlementUpdate(
      settlementTargets,
      Object.keys(adjustments).reduce((prev, curr) => {
        const { merchantId, amount, memo } = adjustments[curr];
        prev.push({
          merchantId,
          amount,
          memo,
        });
        return prev;
      }, []),
      transferException
    );

    // 정산 완료한 target, adjustment 제거
    this.setState({
      settlementTargets: [],
      confirmModalVisible: false,
      adjustments: Object.keys(adjustments)
        .filter(key => !settlementTargets.includes(key))
        .reduce((obj, key) => {
          obj[key] = adjustments[key];
          return obj;
        }, {}),
    });

    this.props.getSettlementPreview();
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  toggleImpossibleSettlement() {
    this.setState({ impossibleSettlementShow: !this.state.impossibleSettlementShow });
  }

  render() {
    const { data, loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const {
      adjModalVisible,
      confirmModalVisible,
      transferModalVisible,
      impossibleSettlementShow,
      setException,
    } = this.state;
    const { selectedRowKeys, adjModalRow, adjustments, settlementTargets, transferModalRow } = this.state;
    const { onOpenAdjustmentModal, onOpenConfirmModal, onOpenTransferModal } = this;
    const settlementData = data.filter(item => item.amount - item.feeAmount > 0 || impossibleSettlementShow);

    const columns = [
      {
        title: 'Merchant Name',
        dataIndex: 'displayName',
        width: '20%',
        align: 'center',
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: 'Settlement Amount',
        dataIndex: 'amount',
        width: '20%',
        align: 'right',
        render: (value, row) => commify(row.amount - row.feeAmount),
        sorter: (a, b) => a.amount - a.feeAmount - (b.amount - b.feeAmount),
      },
      {
        title: 'Settlement feeAmount',
        dataIndex: 'feeAmount',
        width: '20%',
        align: 'right',
        render: value => commify(value),
        sorter: (a, b) => a.feeAmount - b.feeAmount,
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        align: 'right',
        width: '20%',
        render: value => commify(value),
        sorter: (a, b) => a.balance - b.balance,
      },
      {
        title: 'Adjustment',
        dataIndex: 'adjustmentAmount',
        align: 'right',
        width: '20%',
        render(value, row) {
          const adj = adjustments[row.merchantId];
          return (
            <LinkText onClick={() => onOpenAdjustmentModal(row)}>
              {adj?.amount ? commify(adj?.amount) : '+ Add adjustment'}
            </LinkText>
          );
        },
      },
      {
        title: '',
        dataIndex: 'submit',
        width: '10%',
        render: function renderStatus(value, row) {
          const adjAmount = adjustments[row.merchantId]?.amount || 0;
          return (
            <>
              <Button
                type="primary"
                style={{ marginRight: 10 }}
                ghost
                icon="caret-right"
                size="small"
                disabled={
                  row.balance + adjAmount < row.amount - row.feeAmount || row.amount - row.feeAmount + adjAmount < 0
                }
                onClick={() => onOpenConfirmModal([row.merchantId])}
              />
              <Button type="primary" icon="swap" size="small" onClick={() => onOpenTransferModal(row)} />
            </>
          );
        },
        align: 'center',
      },
    ];

    const confirmColumns = [
      {
        title: 'Merchant Name',
        dataIndex: 'displayName',
        width: '20%',
        align: 'center',
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      },
      {
        title: 'Settlement Amount',
        dataIndex: 'amount',
        width: '20%',
        align: 'right',
        render: (value, row) => commify(row.amount - row.feeAmount),
        sorter: (a, b) => a.amount - a.feeAmount - (b.amount - b.feeAmount),
      },
      {
        title: 'Adjustment',
        dataIndex: 'adjustmentAmount',
        align: 'right',
        width: '20%',
        render(value, row) {
          const adj = adjustments[row.merchantId];
          return adj?.amount ? commify(adj?.amount) : 0;
        },
      },
      {
        title: 'Memo',
        align: 'right',
        width: '20%',
        render(value, row) {
          const adj = adjustments[row.merchantId];
          return adj?.memo;
        },
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        align: 'right',
        width: '20%',
        render: value => commify(value),
        sorter: (a, b) => a.balance - b.balance,
      },
    ];

    return (
      <>
        <div style={{ backgroundColor: '#FFFFFF', padding: 10 }}>
          <Table
            loading={loading}
            rowKey="merchantId"
            rowSelection={{
              selectedRowKeys,
              onChange: this.onSelectChange,
              getCheckboxProps: record => {
                const adjAmount = adjustments[record.merchantId]?.amount || 0;
                return {
                  disabled:
                    record.balance + adjAmount < record.amount - record.feeAmount ||
                    record.amount - record.feeAmount + adjAmount < 0,
                };
              },
            }}
            pagination={false}
            dataSource={settlementData}
            columns={columns}
            scroll={{ x: '100%' }}
          />
          <div style={{ position: 'relative', marginTop: 20, textAlign: 'right' }}>
            <Button style={{ marginRight: 15 }} type="default" onClick={this.toggleImpossibleSettlement}>
              송금불가능한 정산 {impossibleSettlementShow ? '숨기기' : '보기'}
            </Button>
            <Button
              style={{ marginRight: 15 }}
              type="primary"
              ghost
              onClick={() => onOpenConfirmModal(selectedRowKeys, true)}
              disabled={selectedRowKeys.length === 0}
            >
              송금없이 완료처리
            </Button>
            <Button
              type="primary"
              ghost
              onClick={() => onOpenConfirmModal(selectedRowKeys)}
              disabled={selectedRowKeys.length === 0}
            >
              송금 대기 등록
            </Button>
          </div>
        </div>
        <Modal
          title="Add adjustment"
          visible={adjModalVisible}
          onOk={this.onConfirmAdjustment}
          onCancel={this.onCloseModal}
          okText="Confirm"
          cancelText="Cancel"
          width={480}
        >
          <Form className={styles.form}>
            <Form.Item label="Merchant Name" {...formItemLayout} style={{ textAlign: 'right' }}>
              {adjModalRow?.name}
            </Form.Item>
            <Form.Item label="Settlement Amount" {...formItemLayout} style={{ textAlign: 'right' }}>
              {commify(adjModalRow?.amount - adjModalRow?.feeAmount)}
            </Form.Item>
            <Form.Item label="Balance" {...formItemLayout} style={{ textAlign: 'right' }}>
              {commify(adjModalRow?.balance)}
            </Form.Item>
            <Form.Item label="Adjustment" {...formItemLayout}>
              {getFieldDecorator('modalAdjustmentAmount', {
                initialValue: 0,
                rules: [{ type: 'number', required: true, message: 'Please input amount' }],
              })(<InputNumber style={{ width: '100%' }} formatter={value => commify(value)} />)}
            </Form.Item>
            <Form.Item label="Memo" {...formItemLayout}>
              {getFieldDecorator('modalAdjustmentMemo', {
                initialValue: '',
                rules: [{ required: true, message: 'Please input memo' }],
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Confirm Settlements"
          visible={confirmModalVisible}
          onOk={this.onConfirmSettlement}
          onCancel={this.onCloseModal}
          okText="Confirm"
          cancelText="Cancel"
          width={800}
        >
          <Form className={styles.form}>
            <Table
              loading={loading}
              rowKey="merchantId"
              pagination={false}
              dataSource={settlementTargets.map(merchantId => data.find(row => row.merchantId === merchantId))}
              columns={confirmColumns}
            />
            {setException && (
              <Form.Item label="미송금 사유" {...formItemLayout} style={{ marginTop: 10 }}>
                {getFieldDecorator('transferException', {
                  initialValue: '',
                  rules: [{ required: true, message: '사유를 입력해주세요' }],
                })(<Input />)}
              </Form.Item>
            )}
          </Form>
        </Modal>
        <Modal
          title="Transfer Merchant Balance"
          visible={transferModalVisible}
          onOk={this.onConfirmTransfer}
          onCancel={this.onCloseModal}
          okText="Confirm"
          cancelText="Cancel"
          width={480}
        >
          <Form className={styles.form}>
            <Form.Item label="보내는 머천트" {...formItemLayout} style={{ textAlign: 'right' }}>
              {transferModalRow?.name}
            </Form.Item>
            <Form.Item label="미수금" {...formItemLayout} style={{ textAlign: 'right' }}>
              {commify(transferModalRow?.balance)}
            </Form.Item>
            <Form.Item label="받는 머천트" {...formItemLayout}>
              {getFieldDecorator('toMerchant', {
                initialValue: '',
                rules: [{ required: true, message: '머천트를 선택해주세요' }],
              })(
                <Select>
                  {data
                    .filter(merchant => transferModalRow.merchantId !== merchant.merchantId)
                    .map(merchant => (
                      <Select.Option key={merchant.merchantId} value={merchant.merchantId}>
                        {merchant.displayName} ({commify(merchant.balance)})
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="전환 금액" {...formItemLayout} style={{ marginTop: 10 }}>
              {getFieldDecorator('transferAmount', {
                initialValue: '',
                rules: [{ required: true, message: '전환 금액을 입력해주세요' }],
              })(<Input />)}
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default SettlementTableContainer;
