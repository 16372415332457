import { bindActionCreators } from 'redux';
import { routerActions } from 'connected-react-router';
import { requireLogin, logoutWithoutAPI } from 'redux/modules/auth';
import { addAlert } from 'components/AlertContainer';

export default function clientMiddleware(helpers) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    const { promise, types, schema, disableGlobalAlert = false, ...rest } = action;

    if (!promise) {
      if (types) {
        throw new Error('action has types but no promise.');
      }

      return next(action);
    }

    const boundActions = bindActionCreators({ addAlert, requireLogin, logoutWithoutAPI }, dispatch);
    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const actionPromise = promise(helpers, dispatch);

    actionPromise
      .then(
        result =>
          next({
            ...rest,
            result,
            type: SUCCESS,
          }),
        error => {
          // disableGlobalAlert가 배열 일 때는 error.code가 안에 있는지 확인하고 있으면 스킵
          // 배열이 아닐 때는 true 체크하고 스킵
          if (
            (Array.isArray(disableGlobalAlert) && error.code && disableGlobalAlert.indexOf(error.code) === -1) ||
            !disableGlobalAlert
          ) {
            if (typeof error === 'string') {
              boundActions.addAlert(error);
            } else if (+error.status === 401 && +error.code === 301) {
              // NOT_AUTHENTICATED: {status: 401, code: 301,  message: '사용자 인증이 필요 합니다.'},
              boundActions.requireLogin();
            } else if (+error.status === 401 && +error.code === 302) {
              // INVALID_USER_DATA: {status: 401, code: 302,  message: '사용자 정보 오류'},
              boundActions.logoutWithoutAPI();
            } else if (typeof error.message === 'string') {
              if (error.crossDomain === true) {
                // TODO Localization 필요.
                // Request has been terminated
                // Possible causes: the network is offline, Origin is not allowed by Access-Control-Allow-Origin,
                // the page is being unloaded, etc.
                boundActions.addAlert('서버에 접속할 수 없습니다. 인터넷 연결을 확인하세요.');
              } else {
                boundActions.addAlert(error.message);
              }
            } else if (error.status === 403) {
              dispatch(routerActions.push('/exception/403'));
            } else if (error.status <= 504 && error.status >= 500) {
              dispatch(routerActions.push('/exception/500'));
            } else if (error.status <= 404 && error.status >= 422) {
              dispatch(routerActions.push('/exception/404'));
            } else {
              boundActions.addAlert(`API Error: status: ${error.status} url: ${error.url}`);
            }
          }

          if (error instanceof Error) {
            return next({
              ...rest,
              error: { message: error.message, code: error.code },
              type: FAILURE,
            });
          }

          return next({ ...rest, error, type: FAILURE });
        }
      )
      .catch(error => {
        console.error(`MIDDLEWARE ERROR. REQUEST: ${REQUEST},`, error);
        next({ ...rest, error, type: FAILURE });
      });

    return actionPromise;
  };
}
