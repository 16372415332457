import React from 'react';
import { func, bool, string } from 'prop-types';
import { Modal, Input } from 'antd';

const CreateBannerGroupModal = ({ onOk, onCancel, visible, onBannerGroupNameChange, bannerGroupName }) => (
  <Modal title="Create new Banner Group" onOk={onOk} onCancel={onCancel} visible={visible}>
    <Input
      placeholder="Banner Group Name..."
      autoComplete="off"
      value={bannerGroupName}
      onChange={onBannerGroupNameChange}
      autoFocus
    />
  </Modal>
);

CreateBannerGroupModal.propTypes = {
  onOk: func.isRequired,
  onCancel: func.isRequired,
  visible: bool.isRequired,
  onBannerGroupNameChange: func.isRequired,
  bannerGroupName: string,
};

CreateBannerGroupModal.defaultProps = {
  bannerGroupName: null,
};

export default CreateBannerGroupModal;
