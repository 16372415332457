import React from 'react';
import { Badge, Button, Row, Col } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const getStatus = status =>
  ({
    active: 'success',
    ready: 'default',
    expired: 'error',
  }[status]);

export function getColumns(addCopyItem, isAdmin) {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: function renderID(val) {
        return <LinkText>{val}</LinkText>;
      },
      width: '10%',
      align: 'center',
    },
    {
      title: 'Boost Policy Name',
      render: function renderPeriod(others) {
        const { title, subTitle } = others;

        return <span>{`${subTitle} (${title})`}</span>;
      },
      width: '20%',
    },
    {
      title: 'Discount',
      dataIndex: 'promotion',
      render: function renderName(promotion) {
        return <span>{commify(promotion.discount)}</span>;
      },
      width: '20%',
    },
    {
      title: 'Visible',
      render: function renderPeriod(others) {
        const { visibleFrom, visibleTo } = others;

        return (
          <span>
            {formatDate(visibleFrom, 'YYYY-MM-DD')} ~ {visibleTo && formatDate(visibleTo, 'YYYY-MM-DD')}
          </span>
        );
      },
      width: '30%',
    },
    // {
    //   title: 'Taget Brand',
    //   dataIndex: 'brand',
    //   render: function renderPeriod(brand) {
    //     console.log(brand);
    //     return <span>{brand.name}</span>;
    //   },
    //   width: '20%',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: function renderStatus(val) {
        return (
          <span>
            <Badge status={getStatus(val)} />
            <span>{startCase(val)}</span>
          </span>
        );
      },
      width: '15%',
      align: 'center',
    },
    ...(isAdmin
      ? [
        {
          title: 'Copy',
          render: function renderStatus(row) {
            return <Button type="primary" ghost size="small" icon="copy" onClick={() => addCopyItem(row)} />;
          },
          width: '15%',
          align: 'center',
        },
      ]
      : []),
  ];
  return columns;
}

export function getCopyColumns(removeCopyItem) {
  const copyColumns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: function renderID(val) {
        return <LinkText>{val}</LinkText>;
      },
      width: '20%',
      align: 'center',
    },
    {
      title: 'Copy Targets',
      render: function renderPeriod(others) {
        const { brand, promotion } = others;

        return (
          <Row gutter={24}>
            <Col
              span={4}
              style={{
                backgroundColor: brand.color,
                backgroundImage: `url(${brand.logoImageUrl})`,
                backgroundSize: '20px 20px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: 24,
                borderRadius: 6,
                textAlign: 'center',
              }}
            />
            <Col
              span={10}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {brand.name}
            </Col>
            <Col span={10}>{`${commify(promotion.discount)}`}</Col>
          </Row>
        );
      },
      width: '50%',
    },
    {
      title: '',
      render: function renderID(row) {
        return <Button block type="danger" ghost size="small" icon="delete" onClick={() => removeCopyItem(row)} />;
      },
      width: '20%',
      align: 'center',
    },
  ];

  return copyColumns;
}

export default {
  getColumns,
  getCopyColumns,
};
