import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Card, Tabs, Row, Typography, Form, Button, Divider, Input, Alert, message, Select } from 'antd';

import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import apolloClient from 'helpers/apolloClient';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { getTagQuery } from 'redux/modules/tag/queries';
import {
  addTagMutation,
  updateTagMutation,
  makeTagPolicyMappingMutation,
  makeTagUserMappingMutation,
} from 'redux/modules/tag/mutations';
import { getTags } from 'redux/modules/tag/actions';

import PolicyMappingList from '../List/PolicyMappingList';
import TargetMappingList from '../List/TargetMappingList';
import TagPolicyMappingFormModal from '../Modal/TagPolicyMappingFormModal';
import TagUserMappingFormModal from '../Modal/TagUserMappingFormModal';

import styles from '../Tag.scss';
import rules from './TagFromValidataionRules';
import { TagFormItemLayout } from './TagFormItemLayout';
import Item from './TagFormItem';

const { TabPane } = Tabs;
const { Option } = Select;

const { Title } = Typography;

const mapStateToProps = state => {
  const { currentPage, pageSize, filter } = state.tag;

  return {
    currentPage,
    pageSize,
    filter,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  getTags,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class TagForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    push: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    tagName: PropTypes.string.isRequired,
    isNew: PropTypes.bool.isRequired,
    activeTabKey: PropTypes.string.isRequired,

    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    getTags: PropTypes.func.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  state = {
    data: {},
    loading: true,
    error: null,
    tagMappingModalVisible: false,
    tagUserMappingModalVisible: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.tagName && this.props.activeTabKey === this.props.tagName) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }
  query = null;

  showNewTagPolicyMappingFormModal() {
    this.setState({ tagMappingModalVisible: true });
  }

  hideNewTagPolicyMappingFormModal() {
    this.setState({ tagMappingModalVisible: false });
  }

  showNewTagUserMappingFormModal() {
    this.setState({ tagUserMappingModalVisible: true });
  }

  hideNewTagUserMappingFormModal() {
    this.setState({ tagUserMappingModalVisible: false });
  }

  submit() {
    const { currentPage, filter, pageSize } = this.props;
    this.props.getTags(currentPage, pageSize, filter);
  }

  async createNewTagMapping(formData) {
    try {
      await apolloClient.mutate({
        mutation: makeTagPolicyMappingMutation,
        variables: formData,
      });

      message.success('new Tag Mapping Success.');
    } catch (error) {
      message.error('Failed to Create Tag Mapping.\n', error.message);
    }

    this.closeTab();
    this.submit();
  }

  async createNewTagUserMapping(formData) {
    try {
      await apolloClient.mutate({
        mutation: makeTagUserMappingMutation,
        variables: formData,
      });

      message.success('new Tag User Mapping Success.');
    } catch (error) {
      message.error('Failed to Create Tag Mapping.\n', error.message);
    }

    this.closeTab();
    this.submit();
  }

  async updateTag(tagName, status) {
    const { data } = this.state;

    try {
      const result = await apolloClient.mutate({
        mutation: updateTagMutation,
        variables: { tagId: Number(data.id), tagName, status },
      });

      message.success(`${result.data.updateTag.name} Updated.`);
    } catch (error) {
      message.error('Failed to update Tag.\n', error.message);
    }

    this.closeTab();
    this.submit();
  }

  async addTag(tagName, status) {
    try {
      const result = await apolloClient.mutate({
        mutation: addTagMutation,
        variables: { tagName, status },
      });

      message.success(`${result.data.addTag.name} Created.`);
    } catch (error) {
      message.error('Failed to create Tag.\n', error.message);
    }

    this.closeTab();
    this.submit();
  }

  async getData() {
    const { tagName } = this.props;
    if (tagName === 'add') return;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: getTagQuery,
        variables: {
          tagName,
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.getTag) {
        throw new Error('Invalid Tag Name');
      } else {
        this.setState({
          loading: false,
          data: result.data.getTag,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Tag: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  closeTab() {
    const { tagName } = this.props;
    this.props.removeAndFocusPreviousTab('tag', tagName);
    this.props.push('/tag');
  }

  input = {
    tagName: null,
  };

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  /**
   * Handle Form Submit
   * @param {Object} formFields
   */
  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['tagName'];
      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];
        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }
          return this.setState({
            error: err[field].errors[0],
          });
        }
      }
    }

    const { isNew, form } = this.props;
    const formFields = form.getFieldsValue();

    if (isNew) {
      return this.addTag(formFields.tagName, formFields.status);
    }

    return this.updateTag(formFields.tagName, formFields.status);
  }

  renderHistory() {
    const { tagName, isNew } = this.props;

    return (
      <Row style={{ marginTop: 40 }}>
        <Title level={4}>Mapping List </Title>
        {!isNew && (
          <div className={styles.top}>
            <Button icon="plus-circle" type="primary" onClick={this.showNewTagPolicyMappingFormModal}>
              Policy
            </Button>
            <Divider type="vertical" />
            <Button icon="plus-circle" type="primary" onClick={this.showNewTagUserMappingFormModal}>
              Target
            </Button>
          </div>
        )}
        <Tabs animated={false}>
          <TabPane tab="Policy" key="policy">
            <PolicyMappingList tagName={tagName} />
          </TabPane>
          <TabPane tab="Target" key="target">
            <TargetMappingList tagName={tagName} />
          </TabPane>
        </Tabs>
      </Row>
    );
  }

  render() {
    const { error, loading } = this.state;
    if (!loading && error) {
      return this.renderError();
    }

    const { isNew } = this.props;
    const { form, tagName } = this.props;
    const { getFieldDecorator } = form;
    const { data } = this.state;
    const initStatus = isNew ? 'enabled' : data.status;

    return (
      <>
        <Form onSubmit={this.handleSubmit} className={styles.tagForm}>
          <Card bordered={false}>
            <Title level={4}>Tag Info</Title>

            {!isNew && (
              <Item label="ID" {...TagFormItemLayout} className={styles.formControlDisplay}>
                <div>{data?.id}</div>
              </Item>
            )}
            {!isNew && (
              <Item label="Tag Name" {...TagFormItemLayout}>
                {getFieldDecorator('tagName', {
                  initialValue: data.name,
                  rules: rules.tagName,
                })(<Input ref={node => (this.input.tagName = node)} autoComplete="off" />)}
              </Item>
            )}
            {isNew && (
              <Item label="Tag Name" {...TagFormItemLayout}>
                {getFieldDecorator('tagName', {
                  initialValue: '',
                  rules: rules.tagName,
                })(<Input ref={node => (this.input.tagName = node)} autoComplete="off" />)}
              </Item>
            )}
            <Item label="Status" {...TagFormItemLayout}>
              {getFieldDecorator('status', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: initStatus,
              })(
                <Select>
                  <Option value="enabled">Enabled</Option>
                  <Option value="disabled">Disabled</Option>
                </Select>
              )}
            </Item>

            <Divider style={{ backgroundColor: 'white' }} />

            {/* Display Buttons - Create or Update */}
            <div style={{ textAlign: 'right' }}>
              {isNew && (
                <Fragment>
                  <Button ghost type="danger" onClick={this.closeTab}>
                    Cancel
                  </Button>
                  <Divider type="vertical" style={{ backgroundColor: '#fff' }} />
                  <Button icon="plus-circle" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Fragment>
              )}

              {!isNew && (
                <Fragment>
                  <Button icon="close" onClick={this.closeTab}>
                    Cancel
                  </Button>
                  <Divider type="vertical" style={{ backgroundColor: 'white' }} />
                  <Button icon="check" type="primary" htmlType="submit">
                    Save
                  </Button>
                </Fragment>
              )}
            </div>
          </Card>
        </Form>
        <TagPolicyMappingFormModal
          tagName={tagName}
          onOk={this.createNewTagMapping}
          onCancel={this.hideNewTagPolicyMappingFormModal}
          visible={this.state.tagMappingModalVisible}
        />

        <TagUserMappingFormModal
          tagName={tagName}
          onOk={this.createNewTagUserMapping}
          onCancel={this.hideNewTagUserMappingFormModal}
          visible={this.state.tagUserMappingModalVisible}
        />
        <Card bordered={false}>{this.renderHistory()}</Card>
      </>
    );
  }
}

export default TagForm;
