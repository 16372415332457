import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Select, Button, DatePicker, Row, Col, Input } from 'antd';
import { rowProps, col4Props, col2RightProps } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getTransactions } from 'redux/modules/transaction/actions';
import styles from './Transaction.scss';

const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => {
  const { pageSize, filter } = state.transaction;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getTransactions,
})
@autobind
class TransactionFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getTransactions: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getTransactions(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getTransactions(1, pageSize, null);

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { period, type, query } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {/* Period */}
          <Col {...col4Props}>
            <Item label="Period">
              {getFieldDecorator('period', {
                rules: [
                  {
                    type: 'array',
                  },
                ],
                initialValue: period,
              })(<RangePicker style={{ width: '100%' }} />)}
            </Item>
          </Col>

          {/* Type */}
          <Col {...col4Props}>
            <Item label="Type">
              {getFieldDecorator('type', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: type || 'all',
              })(
                <Select>
                  <Option value="all">All</Option>
                  <Option value="charge_in">Charge In</Option>
                  <Option value="transfer_in">Transfer In</Option>
                  <Option value="payback_in">Payback In</Option>
                  <Option value="charge_bonus_in">Charge Bonus In</Option>
                  <Option value="airdrop_in">Airdrop In</Option>
                  <Option value="payment_out">Payment Out</Option>
                  <Option value="revoke_out">Revoke Out</Option>
                  <Option value="withdrawal_out">Withdrawal Out</Option>
                  <Option value="settlement_out">Settlement Out</Option>
                </Select>
              )}
            </Item>
          </Col>

          {/* Query */}
          <Col {...col4Props}>
            <Item label="Query">
              {getFieldDecorator('query', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: query,
              })(<Input placeholder="ID or Keyword" autoComplete="off" />)}
            </Item>
          </Col>
        </Row>

        <Row {...rowProps}>
          <Col {...col2RightProps} xl={{ span: 4, push: 4 }}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col2RightProps} xl={{ span: 4, push: 4 }}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default TransactionFilterForm;
