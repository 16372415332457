const rules = {
  tagName: [
    {
      required: true,
      message: 'Please enter tag name.',
    },
  ],
};

export default rules;
