import gql from 'graphql-tag';

const addInvitationMutation = gql`
  mutation addInvitation($userIds: [ID], $userIdFile: FileUpload, $invitationCount: Int) {
    addInvitation(userIds: $userIds, userIdFile: $userIdFile, invitationCount: $invitationCount) {
      success
      failedUserIds
    }
  }
`;

export default { addInvitationMutation };
