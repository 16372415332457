import gql from 'graphql-tag';

export const contractListQuery = gql`
  query contractList($filter: String, $skip: Int, $pageSize: Int) {
    contractList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        autoExtension
        type
        termStartAt
        termEndAt
        settlementCycle
        settlementProvideCycle
        updatedAt
        fee
        feeType
        includedVat
      }
      total
    }
  }
`;

export default {
  contractListQuery,
};
