export default {
  $id: 'group-edit-schema',
  title: 'group-edit-schema',
  description: 'group-edit-schema.',
  type: 'object',
  required: ['name', 'code'],
  properties: {
    id: {
      type: 'string',
    },
    name: {
      type: 'string',
      title: 'Group Name',
      maxLength: 25,
      minLength: 1,
    },
    code: {
      type: 'string',
      title: 'Group Code',
      maxLength: 25,
      minLength: 1,
    },
    description: {
      type: 'string',
      title: 'Description',
      maxLength: 300,
    },
  },
};
