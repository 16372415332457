import gql from 'graphql-tag';

export const apiKeyAddMutation = gql`
  mutation apiKeyAdd($merchantId: String!, $type: String!, $expireAt: Date!) {
    addApiKey(merchantId: $merchantId, type: $type, expireAt: $expireAt) {
      id
      type
      expireAt
    }
  }
`;

export const apiKeyUpdateMutation = gql`
  mutation apiKeyUpdate($id: ID!, $expireAt: Date!) {
    updateApiKey(id: $id, expireAt: $expireAt) {
      id
      type
      expireAt
    }
  }
`;

export default {
  apiKeyAddMutation,
  apiKeyUpdateMutation,
};
