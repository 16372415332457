import gql from 'graphql-tag';

export const promotionGroupAddMutation = gql`
  mutation promotionGroupAdd(
    $title: String!
    $subTitle: String!
    $detailTitle: String!
    $description: String
    $landingUrl: String
    $merchantId: String!
    $promotions: JSON
    $startAt: Date!
    $endAt: Date!
    $sortedIndex: Int
  ) {
    promotionGroupAdd(
      title: $title
      subTitle: $subTitle
      detailTitle: $detailTitle
      description: $description
      landingUrl: $landingUrl
      merchantId: $merchantId
      promotions: $promotions
      startAt: $startAt
      endAt: $endAt
      sortedIndex: $sortedIndex
    )
  }
`;

export const promotionGroupUpdateMutation = gql`
  mutation promotionGroupUpdate(
    $id: ID!
    $title: String!
    $subTitle: String!
    $detailTitle: String!
    $description: String
    $landingUrl: String
    $merchantId: String!
    $promotions: JSON
    $startAt: Date!
    $endAt: Date!
    $sortedIndex: Int
    $status: String
  ) {
    promotionGroupUpdate(
      id: $id
      title: $title
      subTitle: $subTitle
      detailTitle: $detailTitle
      description: $description
      landingUrl: $landingUrl
      merchantId: $merchantId
      promotions: $promotions
      startAt: $startAt
      endAt: $endAt
      sortedIndex: $sortedIndex
      status: $status
    )
  }
`;

export default {
  promotionGroupAddMutation,
  promotionGroupUpdateMutation,
};
