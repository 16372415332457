import React from 'react';
import { routerActions } from 'connected-react-router';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import { Redirect } from 'react-router-dom';
import store from 'store';
import withPermission from 'helpers/withPermission';
import { isAdmin } from 'utils/permission';
import { App, BasicLayout, Exception403, Exception404, Exception500 } from './containers/Layout';
import Dashboard from './containers/Dashboard';
import UserContainer from './containers/User';
import DFUserContainer from './containers/DFUser';
import Payment from './containers/Payment';
import Transaction from './containers/Transaction';
import Settlement from './containers/Settlement';
import SettlementTable from './containers/SettlementTable';
import SettlementHistory from './containers/SettlementHistory';
import Merchant from './containers/Merchant';
import Policy from './containers/Policy';
import Promotion from './containers/Promotion';
import PromotionAirdropContainer from './containers/Promotion/Airdrop';
import LuckyPromotion from './containers/LuckyPromotion';
import PromotionGroup from './containers/PromotionGroup';
import Login from './containers/Login';
import { Menu, Action, Group, Admin } from './containers/Admin';
import { Agreement, Banner, Notice, Faq } from './containers/Operation';
import Profile from './containers/Profile';
import { PreApplication, CardProduct } from './containers/Card';
import { Invitation } from './containers/Social';
import {
  BoltPolicy,
  BoostPromotion,
  Brand,
  BoltExclusionRule,
  BoostExclusionRule,
  BoostPreview,
  BoostCategory,
  BoltDrawPromotion,
  Monoha,
} from './containers/Boost';
import TagContainer from './containers/Tag';
import BankContainer from './containers/Bank';
import LocationCollectHistoryContainer from './containers/LocationCollectHistory';
import ExchangePaymentRuleContainer from './containers/ExchangePaymentRule';

const isAuthenticated = connectedReduxRedirect({
  redirectPath: (state, props) => {
    const urlParams = new URLSearchParams(props.location.search);
    const redirectPath = urlParams.get('redirect');
    const searchParams = new URLSearchParams();
    if (redirectPath) {
      searchParams.append('redirect', redirectPath);
    }
    const publicKey = urlParams.get('publicKey');
    if (publicKey) {
      searchParams.append('publicKey', publicKey);
    }

    if (searchParams.toString().length > 0) {
      return `/login?${searchParams.toString()}`;
    }
    return '/login';
  },
  authenticatedSelector: () => !!store.get('admin'),
  redirectAction: routerActions.replace,
  wrapperDisplayName: 'UserIsAuthenticated',
});

const exceptions = [
  { path: '/exception/403', component: Exception403 },
  { path: '/exception/500', component: Exception500 },
  { component: Exception404 },
];

const routes = [
  {
    component: App,
    routes: [
      { path: '/login', component: Login },
      {
        path: '/',
        component: isAuthenticated(BasicLayout),
        routes: [
          {
            path: '/',
            exact: true,
            component() {
              const redirectPath = isAdmin() ? '/dashboard' : '/payment';
              return <Redirect to={redirectPath} />;
            },
          },
          { path: '/dashboard', component: withPermission(Dashboard) },
          { path: '/user/:id?', component: withPermission(UserContainer) },
          { path: '/dfuser/:id?', component: withPermission(DFUserContainer) },
          { path: '/payment/:id?', component: withPermission(Payment) },
          { path: '/transaction/:id?', component: withPermission(Transaction) },
          { path: '/settlement/:merchantName?/:date?/:paymentId?', component: withPermission(Settlement) },
          { path: '/settlementTable', component: withPermission(SettlementTable) },
          { path: '/settlementHistory', component: withPermission(SettlementHistory) },
          { path: '/merchant/:id?', component: withPermission(Merchant) },
          { path: '/policy/:id?', component: withPermission(Policy) },
          { path: '/promotion/airdrop/:id?', component: withPermission(PromotionAirdropContainer) },
          { path: '/promotion/policy/:id?', component: withPermission(Promotion) },
          { path: '/promotion/group/:id?', component: withPermission(PromotionGroup) },
          { path: '/promotion/lucky/:id?', component: withPermission(LuckyPromotion) },
          { path: '/admin/menu', component: withPermission(Menu) },
          { path: '/admin/action', component: withPermission(Action) },
          { path: '/admin/group', component: withPermission(Group) },
          { path: '/admin/admins', component: withPermission(Admin) },
          { path: '/admin/locationCollectHistory', component: withPermission(LocationCollectHistoryContainer) },
          { path: '/operation/terms', component: withPermission(Agreement) },
          { path: '/operation/banner', component: withPermission(Banner) },
          { path: '/operation/notice', component: withPermission(Notice) },
          { path: '/operation/faq', component: withPermission(Faq) },
          { path: '/operation/exchangePaymentRule', component: withPermission(ExchangePaymentRuleContainer) },
          { path: '/profile', component: withPermission(Profile) },
          { path: '/card/preapplication', component: withPermission(PreApplication) },
          { path: '/card/product', component: withPermission(CardProduct) },
          { path: '/social/invitation', component: withPermission(Invitation) },
          { path: '/boost/bolt', component: withPermission(BoltPolicy) },
          { path: '/boost/boltDrawPromotion', component: withPermission(BoltDrawPromotion) },
          { path: '/boost/policy', component: withPermission(BoostPromotion) },
          { path: '/boost/bolt_exclusion_rule', component: withPermission(BoltExclusionRule) },
          { path: '/boost/boost_exclusion_rule', component: withPermission(BoostExclusionRule) },
          { path: '/boost/brand', component: withPermission(Brand) },
          { path: '/boost/preview', component: withPermission(BoostPreview) },
          { path: '/boost/category', component: withPermission(BoostCategory) },
          { path: '/boost/monoha', component: withPermission(Monoha) },
          { path: '/tag', component: withPermission(TagContainer) },
          { path: '/bank', component: withPermission(BankContainer) },
          ...exceptions,
        ],
      },
    ],
  },
];

export default routes;
