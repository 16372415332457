import { routerActions } from 'connected-react-router';
import PropTypes from 'prop-types';

const SET_NEXT_PATH = 'auth/SET_NEXT_PATH';

export const userShape = PropTypes.shape({
  email: PropTypes.string,
  level: PropTypes.number,
});

const initialState = {
  loaded: false,
  loading: false,
  loadedInfo: false,
  loggingIn: false,
  updated: false,
  nextPath: '/',
  error: null,
  user: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_NEXT_PATH:
      return {
        ...state,
        nextPath: action.path,
      };

    default:
      return state;
  }
}

export function setNextPath(path) {
  return {
    type: SET_NEXT_PATH,
    path,
  };
}

export function requireLogin(routerAction) {
  return (dispatch, getState) => {
    const state = getState();
    const pathname = state.routing.locationBeforeTransitions && state.routing.locationBeforeTransitions.pathname;

    if (pathname === '/login') return;

    // 완료 후 원래 가려던 페이지로 가는 기능을 위해 NextPath 설정
    if (pathname) {
      dispatch(setNextPath(pathname));
    }

    if (routerAction) {
      routerAction('/login');
    } else {
      dispatch(routerActions.push('/login'));
    }
  };
}

export const authUserSelector = state => state.auth.user;
