/**
 * THIS IS THE ENTRY POINT FOR THE CLIENT, JUST LIKE server.js IS THE ENTRY POINT FOR THE SERVER.
 */
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter, push as routerPush } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import { trigger } from 'redial';
import Loadable from 'react-loadable';
import { AppContainer as HotEnabler } from 'react-hot-loader';
import { PersistGate } from 'redux-persist/integration/react';
import { createBrowserHistory } from 'history';
import createApiClient from 'helpers/apiClient';
import createStore from 'redux/create';
import { ApolloProvider } from 'react-apollo';
import * as Sentry from '@sentry/browser';
import apolloClient from './helpers/apolloClient';
import isOnline from './utils/isOnline';
import asyncMatchRoutes from './utils/asyncMatchRoutes';
import { ReduxAsyncConnect, Provider } from './components';

import './shared/css/editorFix.css';
import './initEditor';

const routes = require('routes');

if (!__DEVELOPMENT__) {
  Sentry.init({
    dsn: 'https://9178910462de41209b2d0fba0cf0c45a@sentry.chai.finance/2',
    environment: process.env.NODE_CONFIG_ENV ? process.env.NODE_CONFIG_ENV : 'Local',
  });
}

const dest = document.getElementById('content');
const apiClient = createApiClient();
const socket = null;

let pushFn = () => {
  throw new Error("The store doesn't initialize yet.");
};

(async () => {
  const online = await (window.__data ? true : isOnline());
  const helpers = { apiClient, socket };
  const history = createBrowserHistory();
  const store = createStore({ history, data: window.__data, helpers });

  pushFn = url => store.dispatch(routerPush(url));

  const hydrate = async _routes => {
    const { components, match, params } = await asyncMatchRoutes(_routes, history.location.pathname);
    const triggerLocals = {
      ...helpers,
      store,
      match,
      params,
      history,
      location: history.location,
    };

    await trigger('fetch', components, triggerLocals);
    await trigger('defer', components, triggerLocals);

    ReactDOM.render(
      <HotEnabler>
        <Provider store={store} {...helpers}>
          <PersistGate loading={null} persistor={store.persistor}>
            <ApolloProvider client={apolloClient}>
              <ConnectedRouter history={history}>
                <ReduxAsyncConnect routes={_routes} store={store} helpers={helpers}>
                  {renderRoutes(_routes)}
                </ReduxAsyncConnect>
              </ConnectedRouter>
            </ApolloProvider>
          </PersistGate>
        </Provider>
      </HotEnabler>,
      dest
    );
  };

  await Loadable.preloadReady();

  await hydrate(routes);

  if (module.hot) {
    module.hot.accept('./routes', () => {
      const nextRoutes = require('./routes');
      hydrate(nextRoutes).catch(err => {
        console.error('Error on routes reload:', err);
      });
    });
  }

  if (process.env.NODE_ENV !== 'production') {
    window.React = React; // enable debugger

    if (!__DISABLE_SSR__) {
      if (!dest || !dest.firstChild || !dest.firstChild.attributes || !dest.firstChild.attributes['data-reactroot']) {
        console.error(
          'Server-side React render was discarded.\n' +
            'Make sure that your initial render does not contain any client-side code.'
        );
      }
    }
  }

  if (__SERVICE_WORKER__ && online && !__DEVELOPMENT__ && 'serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
      try {
        await navigator.serviceWorker.register('/dist/service-worker.js', { scope: '/' });
        console.log('Service worker registered!');
      } catch (error) {
        console.log('Error registering service worker: ', error);
      }

      await navigator.serviceWorker.ready;
      console.log('Service Worker Ready');
    });
  }
})();

/**
 * Push History State
 * @param {string} url
 */
export function push(url) {
  pushFn(url);
}

export default {
  push,
};
