import gql from 'graphql-tag';

export const boostCategoryQuery = gql`
  query boostCategory($id: Int!) {
    boostCategory(id: $id) {
      id
      name
      imageUrl
      priority
    }
  }
`;

export const boostCategoryListQuery = gql`
  query boostCategoryList($filter: String, $skip: Int, $pageSize: Int) {
    boostCategoryList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        createdAt
        updatedAt
        name
        imageUrl
        priority
      }
      total
    }
  }
`;

export const boostCategoryBoostListQuery = gql`
  query boostCategoryBoostList($id: Int!, $filter: String, $skip: Int, $pageSize: Int) {
    boostCategoryBoostList(id: $id, filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        type
        title
        status
        subTitle
        visibleFrom
        visibleTo
        boltPrice
        promotion {
          discount
        }
      }
      total
    }
  }
`;

export default {
  boostCategoryQuery,
  boostCategoryListQuery,
};
