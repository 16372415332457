import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';
import { required as requiredRule } from 'utils/formValidator';

import { formItemLayout } from '../formlayout';

const { Option } = Select;

function ChaiSelectForm(props) {
  const { form, initial, label, name, items, disabled, required } = props;
  const { getFieldDecorator } = form;
  const rules = [];

  if (required) {
    rules.push(requiredRule());
  }

  return (
    <Form.Item label={label} {...formItemLayout}>
      {getFieldDecorator(name, { initialValue: initial, rules })(
        <Select disabled={disabled}>
          {items.map(item => (
            <Option key={item.value} value={item.value} className={item.className ? item.className : null}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
    </Form.Item>
  );
}

ChaiSelectForm.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  initial: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

ChaiSelectForm.defaultProps = {
  disabled: false,
  required: false,
  initial: undefined,
};

export default ChaiSelectForm;
