import {
  FETCH_BANNER,
  FETCH_BANNER_SUCCESS,
  FETCH_BANNER_FAILURE,
  SET_FILTER,
  RESET_FILTER,
  SET_CURRENT_PAGE,
  SET_PAGE_SIZE,
  SET_BANNER_SORTED_INDEX,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: null,
};

export default function BannerGroupReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BANNER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result.list,
        totalCount: action.result.total,
      };
    case FETCH_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload,
        currentPage: 1,
      };
    case RESET_FILTER:
      return {
        ...state,
        filter: null,
        currentPage: 1,
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case SET_BANNER_SORTED_INDEX: {
      const { data } = state;
      const { id, newSortedIndex } = action.payload;

      const newData = [...data];

      for (let i = 0; i < newData.length; i += 1) {
        if (newData[i].id === id) {
          newData[i].sortedIndex = newSortedIndex;
          break;
        }
      }

      return {
        ...state,
        data: newData,
      };
    }
    default:
      return state;
  }
}
