export default {
  $id: 'dfuser-edit-schema',
  title: 'dfuser-edit-schema',
  description: 'dfuser-edit-schema.',
  type: 'object',
  required: [],
  properties: {
    id: {
      type: 'string',
    },
    loginId: {
      type: 'string',
      title: 'User ID',
    },
    password: {
      type: 'string',
      title: 'Password',
      maxLength: 20,
      minLength: 12,
    },
    permittedMerchantUserIds: {
      type: 'string',
      title: 'Merchant User ID',
    },
  },
};
