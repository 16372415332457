import React from 'react';
import LinkText from 'components/LinkText';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';
import styles from './BoostCategory.scss';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '5%',
    align: 'center',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderType(val) {
      const typeMap = {
        standard: 'Standard',
        fcfs: 'FCFS',
        time_attack: 'TimeAttack',
        recurring: '정기결제',
      };

      return <span>{typeMap[val]}</span>;
    },
    width: '15%',
    align: 'center',
  },
  {
    title: 'Boost Policy Name',
    render: function renderName(others) {
      const { title, subTitle } = others;
      return <span>{`${subTitle} (${title})`}</span>;
    },
    width: '20%',
  },
  {
    title: 'Bolt Price',
    dataIndex: 'boltPrice',
    render: function renderBoltPrice(val) {
      return <span>{val}</span>;
    },
    width: '10%',
  },
  {
    title: 'Discount',
    dataIndex: 'promotion',
    render: function renderDiscount(promotion) {
      return <span>{commify(promotion.discount)}</span>;
    },
    width: '10%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: function renderStatus(val) {
      const statusMap = {
        enabled: 'Enabled',
        disabled: 'Disabled',
        sold_out: 'Sold Out',
      };

      return <span>{statusMap[val]}</span>;
    },
    width: '15%',
    align: 'center',
  },
  {
    title: 'Visible',
    render: function renderPeriod(others) {
      const { visibleFrom, visibleTo } = others;

      const from = new Date(visibleFrom);
      const to = new Date(visibleTo);
      const now = new Date();

      let style;
      if (from > now) {
        style = styles.future;
      } else if (to < now) {
        style = styles.past;
      } else {
        style = styles.current;
      }

      return (
        <span className={style}>
          {formatDate(visibleFrom, 'YYYY-MM-DD')} ~ {visibleTo && formatDate(visibleTo, 'YYYY-MM-DD')}
        </span>
      );
    },
    width: '25%',
  },
];

export default {
  columns,
};
