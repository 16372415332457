import React from 'react';
import createClass from 'create-react-class';
import { Form, Input, Row, Col, DatePicker, InputNumber, Checkbox, Radio, Select, Switch, Cascader } from 'antd';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import styles from './SearchForm.scss';

const FormItem = Form.Item;

const JsxGeneratorMap = new Map();

const FormMap = new Map();

const SchemaUtils = {
  getForm(schema, uiSchema, noCache) {
    const id = schema.$id;
    if (FormMap.has(id)) {
      return FormMap.get(id);
    }
    const newForm = this.createForm(id, schema, uiSchema, noCache);
    FormMap.set(id, newForm);
    return newForm;
  },
  createForm(id, schema, uiSchema, noCache) {
    const util = this;

    const tmpComponent = createClass({
      componentWillMount() {
        if (JsxGeneratorMap.has(id)) {
          this.generateJsx = JsxGeneratorMap.get(id);
          return;
        }
        const generateJsx = util.parse(schema, uiSchema);

        if (!noCache) {
          JsxGeneratorMap.set(id, generateJsx);
        }

        this.generateJsx = generateJsx;
      },
      render() {
        const { style } = this.props;
        if (this.props.noCacheSchema) {
          const generateJsx = util.parse(this.props.schema, this.props.uiSchema);
          this.generateJsx = generateJsx;
        }

        return this.generateJsx(this.props.form.getFieldDecorator, style);
      },
    });
    return Form.create()(tmpComponent);
  },
  parse(schema, uiSchema) {
    const cols = [];
    const schemaProperties = schema.properties;
    const util = this;
    Object.keys(uiSchema).forEach(key => {
      const field = uiSchema[key];
      field.key = key;
      const schemaProperty = schemaProperties[key];
      switch (field['ui:widget']) {
        case 'inputNumber':
          cols.push(util.transformInputNumber(field, schemaProperty));
          break;
        case 'checkbox':
          cols.push(util.transformCheckbox(field, schemaProperty));
          break;
        case 'datetime':
          cols.push(util.transformDatetime(field, schemaProperty));
          break;
        case 'radio':
          cols.push(util.transformRadio(field, schemaProperty));
          break;
        case 'select':
          cols.push(util.transformSelect(field, schemaProperty));
          break;
        case 'switch':
          cols.push(util.transformSwitch(field, schemaProperty));
          break;
        case 'cascader':
          cols.push(util.transformCascader(field, schemaProperty));
          break;
        case 'between':
          cols.push(util.transformBetween(field, schemaProperty));
          break;
        default:
          cols.push(util.transformInput(field, schemaProperty));
      }
    });
    function FormResult(getFieldDecorator, style) {
      const formCols = [];
      /* eslint-disable */
      for (const col of cols) {
        /* eslint-enable */
        formCols.push(col(getFieldDecorator));
      }
      return (
        <Form className={styles.filterForm} style={style}>
          <Row {...rowProps}>{formCols}</Row>
        </Form>
      );
    }
    return FormResult;
  },
  transformInput(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(<Input {...field['ui:options']} />),
      field
    );
  },
  transformInputNumber(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(
          <InputNumber {...field['ui:options']} />
        ),
      field
    );
  },
  transformCheckbox(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(
          <Checkbox.Group {...field['ui:options']} />
        ),
      field
    );
  },
  transformDatetime(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(
          <DatePicker {...field['ui:options']} />
        ),
      field
    );
  },
  transformRadio(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(
          <Radio.Group {...field['ui:options']} />
        ),
      field
    );
  },
  transformSelect(field) {
    const dataOptions = field['ui:dataOptions'] || [];
    const options = [];
    /* eslint-disable */
    for (let o of dataOptions) {
      /* eslint-enable */
      options.push(
        <Select.Option key={o.value} value={o.value} disabled={o.disabled}>
          {o.title}
        </Select.Option>
      );
    }
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(
          <Select {...field['ui:options']}>{options}</Select>
        ),
      field
    );
  },
  transformSwitch(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, {
          initialValue: field['ui:defaultValue'],
          valuePropName: 'checked',
        })(<Switch {...field['ui:options']} />),
      field
    );
  },
  transformCascader(field) {
    return this.colWrapper(
      getFieldDecorator =>
        getFieldDecorator(field.key, { initialValue: field['ui:defaultValue'] })(<Cascader {...field['ui:options']} />),
      field
    );
  },
  transformBetween(field) {
    let begin;
    let end;
    switch (field['ui:type']) {
      case 'number':
        begin = getFieldDecorator =>
          getFieldDecorator(`${field.key}Begin`, { initialValue: field['ui:defaultBeginValue'] })(
            <InputNumber {...field['ui:options']} />
          );

        end = getFieldDecorator =>
          getFieldDecorator(`${field.key}End`, { initialValue: field['ui:defaultEndValue'] })(
            <InputNumber {...field['ui:options']} />
          );

        return this.betweenColWrapper(begin, end, field);
      default:
        begin = getFieldDecorator =>
          getFieldDecorator(`${field.key}Begin`, { initialValue: field['ui:defaultBeginValue'] })(
            <DatePicker {...field['ui:options']} />
          );
        end = getFieldDecorator =>
          getFieldDecorator(`${field.key}End`, { initialValue: field['ui:defaultEndValue'] })(
            <DatePicker {...field['ui:options']} />
          );
        return this.betweenColWrapper(begin, end, field);
    }
  },
  colWrapper(formItem, field) {
    // let lgCol = 6;
    // let xlCol = 6;
    // if (field['ui:widget'] === 'checkbox' || field['ui:widget'] === 'radio' || field['ui:widget'] === 'between') {
    //   lgCol = 12;
    //   xlCol = 12;
    // }

    function ColResult(getFieldDecorator) {
      return (
        <Col key={field.key} {...col4Props}>
          <FormItem key={field.key} label={field['ui:title']} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            {formItem(getFieldDecorator)}
          </FormItem>
        </Col>
      );
    }
    return ColResult;
  },
  betweenColWrapper(beginFormItem, endFormItem, field) {
    return function SearchForm(getFieldDecorator) {
      return (
        <Col key={`${field.key}Begin`} xs={24} sm={24} md={12} lg={12} xl={12}>
          <Row>
            <Col xs={24} sm={24} md={16}>
              <FormItem
                key={`${field.key}Begin`}
                label={field['ui:title']}
                labelCol={{ span: 15 }}
                wrapperCol={{ span: 9 }}
              >
                {beginFormItem(getFieldDecorator)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={8}>
              <FormItem key={`${field.key}End`}>{endFormItem(getFieldDecorator)}</FormItem>
            </Col>
          </Row>
        </Col>
      );
    };
  },
};

export default SchemaUtils;
