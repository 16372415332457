import apolloClient from 'helpers/apolloClient';
import { settlementDetailQuery } from './queries';

export const FETCH_SETTLEMENT_DETAIL = 'settlementDetail/FETCH';
export const FETCH_SETTLEMENT_DETAIL_SUCCESS = 'settlementDetail/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_DETAIL_FAILURE = 'settlementDetail/FETCH_FAILURE';

export const SET_MERCHANT_ID = 'settlementDetail/SET_MERCHANT_ID';
export const SET_MERCHANT_NAME = 'settlementDetail/SET_MERCHANT_NAME';
export const SET_DATE = 'settlementDetail/SET_DATE';

/**
 * Get Settlement detail
 * @param {Object} filter
 */
export function getSettlementDetail(filter) {
  return {
    types: [FETCH_SETTLEMENT_DETAIL, FETCH_SETTLEMENT_DETAIL_SUCCESS, FETCH_SETTLEMENT_DETAIL_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: settlementDetailQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
        },
      });

      return result.data.settlementList;
    },
  };
}

/**
 * Set Merchant ID
 * @param {string} id = Merchant ID to set
 */
export function setMerchantId(id) {
  return {
    type: SET_MERCHANT_ID,
    payload: id,
  };
}

/**
 * Set Merchant Name
 * @param {string} name - Merchant Name to set
 */
export function setMerchantName(name) {
  return {
    type: SET_MERCHANT_NAME,
    payload: name,
  };
}

/**
 * Set Date
 * @param {moment} date - moment type date to set
 */
export function setDate(date) {
  return {
    type: SET_DATE,
    payload: date,
  };
}
