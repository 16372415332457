import { commify } from 'utils/stringUtil';

export default {
  loginId: {
    'ui:title': 'ID',
    'ui:description': '',
    'ui:widget': 'text',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  balance: {
    'ui:title': '현재 포인트',
    'ui:description': '',
    'ui:widget': 'text',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  divider1: {
    'ui:title': '',
    'ui:description': '',
    'ui:widget': 'divider',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  type: {
    'ui:title': '입금/회수',
    'ui:description': '',
    'ui:widget': 'select',
    'ui:defaultValue': 'deposit',
    'ui:options': {
      options: [
        { id: 'deposit', code: '입금' },
        { id: 'withdraw', code: '회수' },
      ],
    },
    'ui:rules': [{ required: true, message: '입금/회수 선택해주세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
    'ui:shouldRender': () => true,
  },
  amount: {
    'ui:title': '포인트',
    'ui:description': '',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '포인트 입력',
      onChange: data => {
        const { value } = data.target;
        data.target.value = commify(value);
      },
    },
    'ui:rules': [
      { required: true, message: '포인트를 입력하세요.' },
      { max: 14, message: '1000억 이상 입금할 수 없습니다.' },
    ],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  checkAmount: {
    'ui:title': '한글 확인',
    'ui:description': '한글로 입력해주세요 예) 일천팔백만원',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '포인트 한글 확인',
    },
    'ui:rules': [{ required: true, message: '포인트를 입력하세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  divider2: {
    'ui:title': '',
    'ui:description': '',
    'ui:widget': 'divider',
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  date: {
    'ui:title': '입금 날짜',
    'ui:description': '',
    'ui:widget': 'datetime',
    'ui:rules': [{ required: true, message: '입금 날짜를 선택해주세요.' }],
    'ui:options': {
      showTime: true,
      placeholder: '입금 날짜/시간 선택',
    },
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
    'ui:shouldRender': () => true,
  },
  name: {
    'ui:title': '고객명',
    'ui:description': '',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '고객명 입력',
    },
    'ui:rules': [{ required: true, message: '고객명을 입력하세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  currency: {
    'ui:title': '통화',
    'ui:description': '',
    'ui:widget': 'select',
    'ui:defaultValue': 'USD',
    'ui:options': {
      options: [
        { id: 'USD', code: 'USD' },
        { id: 'CNY', code: 'CNY' },
        { id: 'HKD', code: 'HKD' },
        { id: 'KRW', code: 'KRW' },
      ],
    },
    'ui:rules': [{ required: true, message: '통화를 선택하세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  remittanceAmount: {
    'ui:title': '입금 금액',
    'ui:description': '',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '입금 금액 입력',
      onChange: data => {
        const { value } = data.target;
        data.target.value = commify(value);
      },
    },
    'ui:rules': [{ required: true, message: '입금 금액을 입력하세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
  exchangeRate: {
    'ui:title': '환율',
    'ui:description': '',
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: '환율',
      onChange: data => {
        const { value } = data.target;
        data.target.value = commify(value);
      },
    },
    'ui:rules': [{ required: true, message: '환율을 입력하세요.' }],
    'ui:formItemConfig': {
      labelCol: { span: 6 },
    },
  },
};
