import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { Form, Input, Button } from 'antd';
import autobind from 'autobind-decorator';
import qrcode from 'qrcode';
import store from 'store';
import apolloClient from 'helpers/apolloClient';

import { formItemLayout, tailFormItemLayout } from './ProfileFormLayout';
import styles from '../Profile.scss';
import { getOTPKeyQuery } from '../ProfileQueries';

const { Item } = Form;

@Form.create()
@autobind
class RegisterOTPForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    form: objectOf(any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      otpQR: null,
    };
  }

  componentDidMount() {
    this.getOTPKey();
  }

  input = {
    confirmCode: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;
    this.submit(form.getFieldsValue());
  }

  async submit(formData) {
    try {
      await this.props.onSubmit(formData);

      // Reset fields after updated
      this.props.form.setFieldsValue({
        confirmCode: null,
      });
    } catch (err) {
      this.input.confirmCode.focus();
    }
  }

  async getOTPKey() {
    const admin = store.get('admin');

    const { data } = await apolloClient.query({ query: getOTPKeyQuery });
    const { tempKey } = data.getOTPKey;

    qrcode.toDataURL(`otpauth://totp/chai-admin:${admin.uid}?secret=${tempKey}&issuer=chai-admin`, (err, url) => {
      this.setState({ otpQR: url });
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { otpQR } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.profileForm}>
        <div className={styles.qrCode}>
          <img src={otpQR} alt="QR Code" />
        </div>
        <Item label="OTP Code" {...formItemLayout}>
          {getFieldDecorator('confirmCode')(<Input ref={node => (this.input.confirmCode = node)} type="text" />)}
        </Item>

        <Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            OTP 등록
          </Button>
        </Item>
      </Form>
    );
  }
}

export default RegisterOTPForm;
