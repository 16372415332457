import React, { Fragment } from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { commify } from 'utils/stringUtil';
import { formatDate } from 'utils/format';

const getStatus = status =>
  ({
    enabled: 'success',
    disabled: 'default',
  }[status]);

export const columns = [
  {
    title: 'Name',
    dataIndex: 'title',
    render: function renderTitle(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '20%',
  },
  {
    title: 'Start At',
    dataIndex: 'startAt',
    align: 'center',
    render(val, fullValue) {
      const color = moment(Date.now()).isBetween(fullValue.startAt, fullValue.endAt) ? 'black' : 'red';
      return <span style={{ color }}>{formatDate(val, 'YYYY-MM-DD')}</span>;
    },
  },
  {
    title: 'End At',
    dataIndex: 'endAt',
    align: 'center',
    render(val, fullValue) {
      const color = moment(Date.now()).isBetween(fullValue.startAt, fullValue.endAt) ? 'black' : 'red';
      return <span style={{ color }}>{formatDate(val, 'YYYY-MM-DD')}</span>;
    },
  },
  {
    title: 'Win Benefit',
    dataIndex: 'winPromotion.discount',
    align: 'right',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'Lose Benefit',
    dataIndex: 'losePromotion.discount',
    align: 'right',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'Amount Cap',
    dataIndex: 'amountCap',
    align: 'right',
    render(val) {
      return <span>{commify(val)}</span>;
    },
  },
  {
    title: 'Count Cap',
    dataIndex: 'countCap',
    align: 'right',
    render(val) {
      return <span>{commify(val)}</span>;
    },
  },
  {
    title: 'Merchants',
    dataIndex: 'merchants',
    align: 'center',
    render(val) {
      let result = val ? val.map(merchant => merchant.displayName).join(', ') : '';
      if (val && val.length > 3) {
        val.length = 3;
        result = `${val.map(merchant => merchant.displayName).join(', ')} ...`;
      }
      return <span>{result}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render(val) {
      return (
        <Fragment>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </Fragment>
      );
    },
  },
];

export default {
  columns,
};
