import React, { PureComponent, Fragment } from 'react';
import { Table, Divider, Tag, Button, Icon, Badge, Tooltip } from 'antd';
import autobind from 'autobind-decorator';
import startCase from 'lodash/startCase';
import apolloClient from 'helpers/apolloClient';
import SearchForm from 'schema/SearchForm';
import schema from 'schema/adminGroup';
import { formatDate, formatPhoneNumber } from 'utils/format';
import { ellipsis } from 'utils/text';
import Require from 'components/Permission/Require';
import EditAdminGroupModal from './Modal/EditAdminGroupModal';
import EditAdminModal from './Modal/EditAdminModal';
import EditAdminAllowedIpModal from './Modal/EditAdminAllowedIpModal';
import { getAdminsQuery } from './AdminQueries';
import styles from './styles.scss';

@autobind
class Admin extends PureComponent {
  state = {
    filter: {
      fullname: '',
      phone: '',
    },
    data: [],
    loading: false,
    pagination: {
      current: 1,
      pageSize: 10,
      // showTotal: total => `Total ${total} items`
    },
    sorter: {
      field: '',
      order: '',
    },
    displayGroupModal: false,
    displayAdminModal: false,
    displayAdminAllowedIpModal: false,
    groupModalData: null,
    adminModalData: null,
    adminAllowedIpModalData: null,
  };

  componentDidMount() {
    this.refresh();
  }

  columns = [
    {
      title: 'ID',
      dataIndex: 'uid',
    },
    {
      title: 'Name',
      dataIndex: 'fullname',
      sorter: true,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      sorter: true,
      render: value => formatPhoneNumber(value),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: value => {
        const status = {
          active: 'success',
          inactive: 'error',
        }[value];

        return (
          <div>
            <Badge status={status} />
            <span>{startCase(value)}</span>
          </div>
        );
      },
    },
    {
      title: 'Blocked Reason',
      dataIndex: 'blockedReason',
      render: value => <span>{value ? startCase(value) : '-'}</span>,
    },
    {
      title: 'Blocked Time',
      dataIndex: 'blockedAt',
      render: value => <span>{value ? formatDate(value) : '-'}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
    },
    {
      title: 'Last Login',
      dataIndex: 'lastLoginAt',
      render: value => <span>{value ? formatDate(value) : '-'}</span>,
    },
    {
      title: 'Merchant',
      render: others => {
        const { merchants } = others;
        const text =
          merchants && merchants.length > 0
            ? merchants
              .map(merchant => merchant.displayName)
              .join(', ')
              .toString()
            : '-';
        return <span>{ellipsis(text, 15)}</span>;
      },
    },
    {
      title: 'Allowed IP',
      render: others => {
        const { allowedIp } = others;
        const text = allowedIp && allowedIp.length > 0 ? allowedIp.join(', ').toString() : '-';
        return (
          <Tooltip title={text}>
            <span>{ellipsis(text, 45)}</span>
            <div>
              <a onClick={() => this.openAdminAllowedIpModal(others)}>
                <Icon className={styles.toolIcon} type="edit" />
              </a>
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: 'Tools',
      dataIndex: 'id',
      render: (text, record) => (
        <div>
          <Require groups={['admin']}>
            <Fragment>
              <Tooltip title="Set Group">
                <span>
                  <a onClick={() => this.openGroupModal(record)}>
                    <Icon className={styles.toolIcon} type="team" />
                  </a>
                </span>
              </Tooltip>
              <Divider type="vertical" />
            </Fragment>
          </Require>
          <Tooltip title="Edit Admin">
            <span>
              <a onClick={() => this.openAdminModal(record)}>
                <Icon className={styles.toolIcon} type="edit" />
              </a>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  handleSearch(filter) {
    const { pagination, sorter } = this.state;
    pagination.current = 1;

    this.setState({
      filter,
      pagination,
    });

    const query = {
      skip: 0,
      pageSize: pagination.pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter,
    };

    this.fetch(query);
  }

  handleReset() {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: 1,
        },
        filter: {},
      },
      () => {
        const { current, pageSize } = this.state.pagination;

        this.fetch({
          skip: current - 1,
          pageSize,
          filter: {},
        });
      }
    );
  }

  handleTableChange(newPagination, filters, sorter) {
    this.setState({
      pagination: newPagination,
      sorter: {
        field: sorter.field,
        order: sorter.order,
      },
    });

    const { current, pageSize } = newPagination;
    const { filter } = this.state;

    const query = {
      skip: (current - 1) * pageSize,
      pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter,
    };

    this.fetch(query);
  }

  /**
   * Open AdminGroup Modal
   * @param {AdminEntity} admin
   */
  openGroupModal(admin) {
    this.setState({
      groupModalData: admin,
      displayGroupModal: true,
    });
  }

  /**
   * Open Admin Modal
   * @param {AdminEntity} admin
   */
  openAdminModal(admin) {
    this.setState({
      adminModalData: admin,
      displayAdminModal: true,
    });
  }

  /**
   * Open Admin Allowed IP Modal
   * @param {AdminEntity} admin
   */
  openAdminAllowedIpModal(admin) {
    this.setState({
      adminAllowedIpModalData: admin,
      displayAdminAllowedIpModal: true,
    });
  }

  closeModals() {
    this.setState({
      displayGroupModal: false,
      displayAdminModal: false,
      displayAdminAllowedIpModal: false,
      groupModalData: null,
      adminModalData: null,
      adminAllowedIpModalData: null,
    });
  }

  refresh() {
    const { pagination, sorter, filter } = this.state;
    const { pageSize, current } = pagination;

    const query = {
      skip: (current - 1) * pageSize,
      pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter,
    };

    this.fetch(query);
  }

  async fetch(query = {}) {
    this.setState({ loading: true });

    const { data } = await apolloClient.query({
      query: getAdminsQuery,
      variables: {
        ...query,
        filter: JSON.stringify(query.filter),
      },
    });

    const {
      getAdmins: { list: admins, total },
    } = data;

    const { pagination } = this.state;
    pagination.total = total;

    this.setState({
      loading: false,
      data: admins,
      pagination,
    });
  }

  addAdmin() {
    this.setState({
      displayAdminModal: true,
      adminModalData: null,
    });
  }

  render() {
    const {
      displayGroupModal,
      displayAdminModal,
      displayAdminAllowedIpModal,
      groupModalData,
      adminModalData,
      adminAllowedIpModalData,
    } = this.state;

    return (
      <div style={{ background: '#FFFFFF', padding: '10px' }}>
        <SearchForm
          schema={schema.searchSchema}
          uiSchema={schema.searchUiSchema}
          handleSubmit={this.handleSearch}
          handleReset={this.handleReset}
        />
        <Divider />
        <div style={{ marginBottom: 16 }}>
          <Button type="primary" icon="plus-square-o" onClick={this.addAdmin}>
            Add
          </Button>
        </div>

        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          scroll={{ x: 768 }}
          bordered
        />

        <EditAdminGroupModal
          visible={displayGroupModal}
          title={
            <span>
              Edit Group of&nbsp;&nbsp;<Tag color="#2db7f5">{groupModalData && groupModalData.fullname}</Tag>
            </span>
          }
          onClose={this.closeModals}
          adminId={groupModalData && groupModalData.id}
        />

        <EditAdminModal
          visible={displayAdminModal}
          onSubmit={this.refresh}
          onClose={this.closeModals}
          data={adminModalData}
        />

        <EditAdminAllowedIpModal
          visible={displayAdminAllowedIpModal}
          onSubmit={this.refresh}
          onClose={this.closeModals}
          data={adminAllowedIpModalData}
        />
      </div>
    );
  }
}

export default Admin;
