export default {
  $id: 'user-search-schema',
  title: 'user-search-schema',
  description: 'user-search-schema.',
  type: 'object',
  required: [],
  properties: {
    name: {
      type: 'string',
      title: 'username',
    },

    email: {
      type: 'string',
      title: 'email',
    },
  },
};
