import gql from 'graphql-tag';

export const i18nListQuery = gql`
  query i18nList($tableName: String!, $targetId: String!, $locale: String, $columnName: String) {
    i18nList(tableName: $tableName, targetId: $targetId, locale: $locale, columnName: $columnName) {
      tableName
      targetId
      locale
      columnName
      text
    }
  }
`;

export const translationQuery = gql`
  query translation($query: String!, $locale: String!, $format: String) {
    translation(query: $query, locale: $locale, format: $format)
  }
`;

export default {
  i18nListQuery,
  translationQuery,
};
