import moment from 'moment';
import React from 'react';
import { arrayOf, any, string } from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import startCase from 'lodash/startCase';
import styles from './Charts.scss';

const PaymentCountsChart = ({ title, data, timeFormat }) => (
  <div className={styles.chartWrapper}>
    <div className={styles.chartName}>{title}</div>

    <ResponsiveContainer width="100%" height={200}>
      <BarChart
        className={styles.chart}
        width={400}
        height={200}
        data={data}
        margin={{
          top: 20,
          left: 5,
          bottom: 5,
          right: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" tickFormatter={timeStr => moment(timeStr).format(timeFormat)} />
        <YAxis dateKey="count" type="number" allowDecimals={false} />
        <Tooltip formatter={(value, name) => [value, startCase(name)]} />
        <Bar dataKey="count" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

PaymentCountsChart.propTypes = {
  title: string.isRequired,
  data: arrayOf(any).isRequired,
  timeFormat: string.isRequired,
};

export default PaymentCountsChart;
