import React from 'react';
import { Link } from 'react-router-dom';
import Exception from 'components/Exception';

const Error404 = () => (
  <Exception
    img="https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg"
    title="404"
    desc="존재하지 않는 페이지입니다."
    style={{ minHeight: 500, height: '80%' }}
    linkElement={Link}
  />
);

export default Error404;
