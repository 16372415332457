import React, { Component } from 'react';
import autobind from 'autobind-decorator';
import { Tabs, message } from 'antd';
import store from 'store';
import apolloClient from 'helpers/apolloClient';
import ProfileForm from './Forms/ProfileForm';
import UnRegisterOTPForm from './Forms/UnRegisterOTPForm';
import RegisterOTPForm from './Forms/RegisterOTPForm';
import { updatePasswordMutation, registerOTPMutation, unRegisterOTPMutation } from './ProfileMutations';
import styles from './Profile.scss';

const { TabPane } = Tabs;

@autobind
class ProfileContainer extends Component {
  /**
   * Update Password
   * @async
   * @param {Object} formData
   * @param {string} formData.currentPassword
   * @param {string} formData.newPassword
   */
  async updatePassword(formData) {
    try {
      const { id } = store.get('admin');
      const { currentPassword: password, newPassword } = formData;

      await apolloClient.mutate({
        mutation: updatePasswordMutation,
        variables: {
          id,
          password,
          newPassword,
        },
      });

      message.success('Updated.');
    } catch (err) {
      message.error('Update failed.');
      console.log(err);

      throw err;
    }
  }

  async unRegisterOTPConfirm(formData) {
    const { confirmCode } = formData;

    try {
      await apolloClient.mutate({
        mutation: unRegisterOTPMutation,
        variables: {
          confirmCode,
        },
      });

      message.success('OTP successfully unregistered.');
      setTimeout(() => document.location.reload(), 1000);
    } catch (err) {
      message.error('OTP unregister failed.');
      throw err;
    }
  }

  async registerOTPConfirm(formData) {
    const { confirmCode } = formData;

    try {
      await apolloClient.mutate({
        mutation: registerOTPMutation,
        variables: {
          confirmCode,
        },
      });

      message.success('OTP Registered.');
      setTimeout(() => document.location.reload(), 1000);
    } catch (err) {
      message.error('OTP Register failed.');
      throw err;
    }
  }

  render() {
    const { otp } = store.get('admin');

    return (
      <div className={styles.profileContainer}>
        <Tabs animated={false}>
          <TabPane tab="Update Password" key="update-password">
            <ProfileForm onSubmit={this.updatePassword} />
          </TabPane>
          <TabPane tab="Google OTP" key="google-otp">
            {(otp && <UnRegisterOTPForm onSubmit={this.unRegisterOTPConfirm} />) || (
              <RegisterOTPForm onSubmit={this.registerOTPConfirm} />
            )}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default ProfileContainer;
