import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, Radio } from 'antd';
import autobind from 'autobind-decorator';
import styles from './Policy.scss';
import { PolicyType } from './';

const RadioGroup = Radio.Group;

@autobind
class PolicyTypeModal extends PureComponent {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    onSelectedPolicy: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    currentPolicy: PolicyType.PG_FEE,
  };

  changePolicy(ev) {
    this.setState({
      currentPolicy: ev.target.value,
    });
  }

  handleSelectPolicy() {
    this.props.onSelectedPolicy(this.state.currentPolicy);
  }

  render() {
    const { visible, onClose } = this.props;
    const { currentPolicy } = this.state;

    return (
      <Modal
        title="Create New Policy"
        visible={visible}
        onOk={this.handleSelectPolicy}
        onCancel={onClose}
        okText="Create"
      >
        <RadioGroup name="policy-group" onChange={this.changePolicy} value={currentPolicy}>
          <Radio className={styles.radioStyle} value={PolicyType.PG_FEE}>
            PG Fee
          </Radio>
          <Radio className={styles.radioStyle} value={PolicyType.PAYMENT}>
            Payment
          </Radio>
          <Radio className={styles.radioStyle} value={PolicyType.CHARGE}>
            Charge
          </Radio>
          <Radio className={styles.radioStyle} value={PolicyType.WITHDRAWAL}>
            Withdrawal
          </Radio>
        </RadioGroup>
      </Modal>
    );
  }
}

export default PolicyTypeModal;
