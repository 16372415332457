import React, { Component } from 'react';
import { func, bool, objectOf, any } from 'prop-types';
import { Modal, Form, Row } from 'antd';
import autobind from 'autobind-decorator';
import { commify } from 'utils/stringUtil';
import cx from 'classnames';
import styles from './Merchant.scss';

@Form.create()
@autobind
class PaymentLimitConfirmModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    onClose: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  static defaultProps = {};

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.props.onClose();
  }

  render() {
    const { visible, form } = this.props;
    const formFields = form.getFieldsValue();
    const { newComerHour, newComerAmount, dailyLimit } = formFields;

    return (
      <Modal
        title="설정될 결제 한도 정책"
        onOk={this.handleSubmit}
        onCancel={() => this.props.onCancel()}
        visible={visible}
        destroyOnClose
      >
        <Form onSubmit={this.handleSubmit}>
          {newComerHour && newComerAmount && (
            <Row>
              가입 후 <span className={cx(styles.color1)}>{commify(newComerHour)}</span>시간동안
              <span className={cx(styles.color1)}> {commify(newComerAmount)}</span>원(누적) 이상 결제할 수 없습니다.
            </Row>
          )}
          {dailyLimit && (
            <Row>
              1일 누적 최대 <span className={cx(styles.color1)}>{commify(dailyLimit)}</span>원 이상 결제할 수 없습니다.
            </Row>
          )}
          {!newComerHour && !newComerAmount && !dailyLimit && <Row>설정된 정책이 없습니다.</Row>}
        </Form>
      </Modal>
    );
  }
}

export default PaymentLimitConfirmModal;
