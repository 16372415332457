import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';

import { TabViewer, TabData } from 'containers/Layout';
import { push } from 'connected-react-router';
import { addTab, moveTab, focusTab } from 'redux/modules/tabs';
import { ellipsis } from 'utils/text';

import { getBoostCategories } from 'redux/modules/boostCategory/actions';

import BoostCategoryList from './BoostCategoryList';
import BoostCategoryForm from './BoostCategoryForm';

const mapStateToProps = state => {
  const { loading, data, currentPage, totalCount, pageSize, filter } = state.boostCategory;
  const { activeKey, list } = state.tabs.boostCategory;

  return {
    data,
    loading,
    currentPage,
    totalCount,
    pageSize,
    activeKey,
    list,
    filter,
  };
};

const mapDispatchToProps = {
  addTab,
  moveTab,
  push,
  focusTab,
  getBoostCategories,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoostCategoryContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    addTab: PropTypes.func.isRequired,
    moveTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    getBoostCategories: PropTypes.func.isRequired,
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  componentDidMount() {
    const { list, currentPage, pageSize, filter } = this.props;
    let hasListTab = false;

    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('boostCategory', listTab);
    }

    this.props.moveTab('boostCategory', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/boost\/category\/(.+)/);

    if (match) {
      const id = match[1];
      const hasTab = this.props.list.find(item => item.key === id);

      if (!hasTab) {
        if (id === 'add') {
          this.createNewBoostCategory();
        } else {
          this.openDataTab(id);
        }
      }

      this.props.focusTab('boostCategory', id);
    } else {
      this.props.focusTab('boostCategory', 'ListTab');
      this.props.getBoostCategories(currentPage, pageSize, filter);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/boost/category') {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getBoostCategories(currentPage, pageSize, filter);
        this.props.focusTab('boostCategory', 'ListTab');
      } else {
        const match = pathname.match(/\/boost\/category\/(.+)/);

        if (match) {
          const id = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = this.props.list.find(item => item.key === id);

          if (!hasTab) {
            if (id === 'add') {
              const newTab = new TabData({
                key: 'add',
                title: 'New Boost Category',
                closable: true,
                data: {
                  id: 'add',
                },
                componentType: 'BoostCategoryForm',
              });

              this.props.addTab('boostCategory', newTab);
              this.props.focusTab('boostCategory', newTab.key);
              this.props.push('/boost/category/add');

              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
            } else {
              this.openDataTab(id);
            }
          }

          this.props.focusTab('boostCategory', id);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/boost/category/list' || pathname === '/boost/category') {
        this.props.focusTab('boostCategory', 'ListTab');
      }
    }
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getBoostCategories(currentPage, pageSize, filter);
      this.props.push('/boost/category');
    } else {
      this.props.push(`/boost/category/${activeKey}`);
    }
  }

  /**
   * Opening tab for display Promotion
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openDataTab(id, extra = {}) {
    const title = extra.title ? ellipsis(extra.title, 8) : ellipsis(id, 5);
    const tabData = new TabData({
      key: `${id}`,
      title,
      closable: true,
      data: {
        id: +id,
      },
      componentType: 'BoostCategoryForm',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('boostCategory', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('boostCategory', id.toString());
      this.props.push(`/boost/category/${id}`);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  render() {
    return (
      <TabViewer
        tabKey="boostCategory"
        components={{
          ListTab: <BoostCategoryList openDataTab={this.openDataTab} />,
          BoostCategoryForm,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default BoostCategoryContainer;
