import PropTypes from 'prop-types';

const ALERT_ADD = 'alert/ADD';
const ALERT_REMOVE = 'alert/REMOVE';

export const alertShape = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    autoClearAfter: PropTypes.number,
    html: PropTypes.bool,
    parent: PropTypes.string,
    counter: PropTypes.number,
    message: PropTypes.string,
  })
);

export default function reducer(state = [], action = {}) {
  switch (action.type) {
    case ALERT_ADD: {
      if (state.length !== 0) {
        const lastOne = state[state.length - 1];

        if (lastOne.type === action.params.type && lastOne.message === action.params.message) {
          return [...state.slice(0, state.length - 1), Object.assign({}, lastOne, { counter: lastOne.counter + 1 })];
        }
      }

      return [...state, action.params];
    }
    case ALERT_REMOVE: {
      const index = state.findIndex(item => item.key === action.key);
      return index === -1 ? state : [...state.slice(0, index), ...state.slice(index + 1)];
    }
    default:
      return state;
  }
}

const defaultParams = {
  type: 'error',
  autoCloseAfter: 2000,
  html: false,
  parent: 'global',
  counter: 1,
};
let alertIndex = 1;

export function addAlert(params_) {
  alertIndex += 1;
  const key = alertIndex;
  let params;

  if (typeof params_ === 'string') {
    params = {
      ...defaultParams,
      message: params_,
      key,
    };
  } else if (params_ instanceof Error) {
    params = {
      ...defaultParams,
      message: params_.toString(),
      key,
    };
  } else if (params_ instanceof Object) {
    params = {
      ...defaultParams,
      ...params_,
      key,
    };
  } else {
    params = {
      ...defaultParams,
      message: 'Unknown error occurred.',
      key,
    };
  }

  if (typeof params.message !== 'string') {
    params.message = JSON.stringify(params.message);
  }

  return {
    type: ALERT_ADD,
    params,
  };
}

export function removeAlert(key) {
  return {
    type: ALERT_REMOVE,
    key,
  };
}
