import TagContainer from './TagContainer';

export const TagPromotionType = {
  BOOST: 'boost',
  BOLT: 'bolt',
  PROMOTION: 'promotion',
};

export const TagTargetType = {
  USER: 'user',
  MERCHANT: 'merchant',
};

export default TagContainer;
