import gql from 'graphql-tag';

export const chartDataQuery = gql`
  query chartData($startAt: Date!, $endAt: Date!, $timeIntervals: ChartTimeIntervals!) {
    chartData(startAt: $startAt, endAt: $endAt, timeIntervals: $timeIntervals) {
      transactionVolume
      registeredUsers
      paymentCountByTimes
      payments
      purchasedAmount
      discountAmount
      merchantCouponDiscountAmount
      uniqueCountOfPurchasedUser
      canceledAmount
      top20Products
    }
  }
`;

export default {
  chartDataQuery,
};
