import gql from 'graphql-tag';

export const noticeListQuery = gql`
  query noticeList($filter: String, $skip: Int, $pageSize: Int) {
    noticeList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        content
        author
        status
        fixed
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export default {
  noticeListQuery,
};
