import React from 'react';
import LinkText from 'components/LinkText';
import { Badge } from 'antd';

export const columns = [
  {
    title: 'Bank ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Bank Name',
    render: function renderName(datas) {
      return <span>{`${datas.name} (${datas.shortName})`}</span>;
    },
    width: '20%',
  },
  {
    title: 'Icon',
    render: function renderPreview(datas) {
      return (
        <div>
          <div
            style={{
              backgroundImage: `url(${datas.logoUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left',
              margin: '0 auto',
              height: 20,
            }}
          />
        </div>
      );
    },
    width: '20%',
  },
  {
    title: 'sortIndex',
    dataIndex: 'sortedIndex',
    render: function renderActive(val) {
      return (
        <>
          <span>{val}</span>
        </>
      );
    },
  },
  {
    title: 'isActive',
    dataIndex: 'isActive',
    render: function renderActive(val) {
      return (
        <>
          <Badge status={val ? 'success' : 'error'} />
          <span>{val ? 'active' : 'inactive'}</span>
        </>
      );
    },
  },
];

export default {
  columns,
};
