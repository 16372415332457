import gql from 'graphql-tag';

export const faqAddMutation = gql`
  mutation faqAdd($title: String!, $category: String!, $content: String!, $status: String!) {
    faqAdd(title: $title, category: $category, content: $content, status: $status)
  }
`;

export const faqUpdateMutation = gql`
  mutation faqUpdate($id: ID!, $category: String!, $title: String!, $content: String!, $status: String!) {
    faqUpdate(id: $id, category: $category, title: $title, content: $content, status: $status)
  }
`;

export const faqRemoveMutation = gql`
  mutation faqRemove($id: ID!) {
    faqRemove(id: $id)
  }
`;
