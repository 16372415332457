import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon, Form, Button, Modal, Table } from 'antd';
import PropTypes from 'prop-types';

import BoostPromotionMerchantForm, { MERCHANT_ID_PREFIX } from './BoostPromotionMerchantForm';

const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: 24,
    sm: { offset: 8, span: 16 },
    md: { offset: 6, span: 14 },
    lg: { offset: 6, span: 12 },
    xl: { offset: 6, span: 12 },
    xxl: { offset: 6, span: 14 },
  },
};

const mapStateToProps = state => {
  const { data: groupMerchantData, loading: groupMerchantLoading } = state.groupMerchant;
  const { data: businessCodeData, loading: businessCodeLoading } = state.businessCode;

  return {
    businessCodeData,
    businessCodeLoading,
    groupMerchantData,
    groupMerchantLoading,
  };
};

@connect(mapStateToProps)
@Form.create()
class BoostPromotionMerchantCombinationForm extends Component {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    baseForm: PropTypes.objectOf(PropTypes.any).isRequired,
    initial: PropTypes.arrayOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    businessCodeData: PropTypes.arrayOf(PropTypes.any).isRequired,
    businessCodeLoading: PropTypes.bool.isRequired,
    groupMerchantData: PropTypes.arrayOf(PropTypes.any).isRequired,
    groupMerchantLoading: PropTypes.bool.isRequired,
  };

  state = {
    modalVisible: false,
  };

  formName = 'merchantCombinationIds';

  renderFormItems() {
    const { baseForm, name } = this.props;
    const items = baseForm.getFieldValue(name);
    if (!items) {
      return null;
    }

    return (
      <Form.Item {...formItemLayoutWithoutLabel}>
        <Table
          {...formItemLayoutWithoutLabel}
          columns={[
            {
              title: 'Merchant Combinations',
              dataIndex: 'text',
            },
          ]}
          rowKey={record => record.id}
          dataSource={items.map(this.makeTableItem, this)}
          onRow={record => ({
            onClick: () => this.remove(record),
          })}
          pagination={false}
          style={{
            whiteSpace: 'pre-wrap',
          }}
          bordered
        />
      </Form.Item>
    );
  }

  makeTableItem = (merchantList, idx) => {
    const { groupMerchantData, businessCodeData } = this.props;

    return {
      id: idx,
      merchants: merchantList,
      text: merchantList
        .map(item => {
          if (item.startsWith(MERCHANT_ID_PREFIX.cardGroupMerchant)) {
            const target = groupMerchantData.find(
              merchant => MERCHANT_ID_PREFIX.cardGroupMerchant + merchant.groupMerchantNo === item
            );
            return `GroupMerchant: ${target.groupMerchantName}`;
          }
          if (item.startsWith(MERCHANT_ID_PREFIX.cardBusinessCode)) {
            const target = businessCodeData.find(
              merchant => MERCHANT_ID_PREFIX.cardBusinessCode + merchant.codeId === item
            );
            return `BusinessCode: ${target.codeName} (${target.codeId})`;
          }
          if (item.startsWith(MERCHANT_ID_PREFIX.cardMerchantName)) {
            return `Merchant Name: ${item.replace(MERCHANT_ID_PREFIX.cardMerchantName, '')}`;
          }
          return item;
        })
        .join('\n'),
    };
  };

  add = e => {
    const { form, baseForm, name } = this.props;
    const items = baseForm.getFieldValue(name);
    const targetMerchantIds = form.getFieldValue(this.formName);

    e.preventDefault();

    if (targetMerchantIds?.length) {
      items.push(targetMerchantIds);
    }

    baseForm.setFieldsValue({
      [name]: items,
    });

    this.setState({
      modalVisible: false,
    });
  };

  remove = target => {
    const { baseForm, name } = this.props;
    const items = baseForm.getFieldValue(name);

    baseForm.setFieldsValue({
      [name]: items.filter((item, k) => k !== target.id),
    });
  };

  openModal() {
    const { form } = this.props;
    form.resetFields();
    this.setState({
      modalVisible: true,
    });
  }

  closeModal() {
    this.setState({
      modalVisible: false,
    });
  }

  render() {
    const { form, baseForm } = this.props;
    const { initial, name } = this.props;
    const { modalVisible } = this.state;

    baseForm.getFieldDecorator(name, {
      initialValue: initial.filter(item => typeof item !== 'string' && typeof item !== 'number'),
    });

    return (
      <>
        <Form.Item {...formItemLayoutWithoutLabel}>
          <Button type="dashed" onClick={this.openModal.bind(this)} style={{ width: '100%' }}>
            <Icon type="plus" /> Add
          </Button>
        </Form.Item>
        {this.renderFormItems()}
        <Modal
          width={800}
          visible={modalVisible}
          cancelText="Close"
          okText="Save"
          title="Add Merchant Combination"
          onCancel={this.closeModal.bind(this)}
          onOk={this.add.bind(this)}
          destroyOnClose
        >
          <BoostPromotionMerchantForm form={form} name={this.formName} initial={[]} />
        </Modal>
      </>
    );
  }
}

export default BoostPromotionMerchantCombinationForm;
