import 'shared/css/nprogress.css';
import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import { objectOf, func, any } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { renderRoutes } from 'react-router-config';
import Helmet from 'react-helmet';
import store from 'store';
import autobind from 'autobind-decorator';
import { message } from 'antd';
import apolloClient from 'helpers/apolloClient';
import AlertContainer from 'components/AlertContainer/AlertContainer';
import PopupWrapper from 'popups/PopupWrapper';
import ConfirmationPopup from 'popups/ConfirmationPopup';
import { show as showPopup } from 'redux/modules/popup';
import { addAlert } from 'redux/modules/alert';
import config from 'config';
import { getMyAdminQuery } from './AppQueries';
import styles from './App.scss';

const mapDispatchToProps = {
  push,
  showPopup,
  addAlert,
};

@connect(null, mapDispatchToProps)
@withRouter
@autobind
class App extends Component {
  static propTypes = {
    route: objectOf(any).isRequired,
    showPopup: func.isRequired,
    addAlert: func.isRequired,
    push: func.isRequired,
  };

  state = {
    notSupported: false,
    internetConnected: true,
    mounted: false,
  };

  async componentDidMount() {
    try {
      const {
        data: { getMyAdmin: auth },
      } = await apolloClient.query({
        query: getMyAdminQuery,
      });

      if (!auth) {
        const urlParams = new URLSearchParams(document.location.search);
        const redirectPath = urlParams.get('redirect');
        const searchParams = new URLSearchParams();
        if (redirectPath) {
          searchParams.append('redirect', redirectPath);
        }
        const publicKey = urlParams.get('publicKey');
        if (publicKey) {
          searchParams.append('publicKey', publicKey);
        }

        if (searchParams.toString().length > 0) {
          this.props.push(`/login?${searchParams.toString()}`);
        } else {
          this.props.push('/login');
        }
        store.set('admin', null);
      } else {
        store.set('admin', auth);
      }
    } catch (err) {
      message.info("Can't get data from server. Please refresh the website.", 5);
      console.log('Failed to getMyAdmin');
    }

    this.mountTimerId = setTimeout(() => {
      this.mountTimerId = null;

      // Internet Explorer 11 미만일 경우.
      if (document.querySelector('.lt-ie11') !== null) {
        this.setState({ notSupported: true });
      }

      // 일반 브라우저에서 지원되는 경우가 있음.
      // https://developer.mozilla.org/en-US/docs/Web/API/NavigatorOnLine/onLine
      window.addEventListener('offline', this.onOffline);
      window.addEventListener('online', this.onOnline);

      this.setState({ mounted: true });
    });
  }

  componentDidCatch(error, errorInfo) {
    if (!__DEVELOPMENT__) {
      Sentry.withScope(scope => {
        scope.setExtra(errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  componentWillUnmount() {
    if (this.mountTimerId) {
      clearTimeout(this.mountTimerId);
      this.mountTimerId = null;
    }
  }

  onOnline() {
    this.setState({ internetConnected: true });
  }

  onOffline() {
    this.setState({ internetConnected: false });
  }

  render() {
    if (!this.state.mounted) return null;

    const { route } = this.props;
    const { notSupported, internetConnected } = this.state;

    return (
      <div>
        <Helmet {...config.app.head} />
        {notSupported && (
          <div className={styles.notsupported}>
            <h3>브라우저를 업데이트 해주세요.</h3>
            <p>
              서비스를 원활하게 이용하시려면 브라우저의 업데이트가 필요합니다.
              <br />
              아래 링크 중 하나를 통해 최신 버전 브라우저로 업데이트 해주세요.
            </p>
            <table>
              <tr>
                <td>
                  <img src="/resources/images/chrome.png" width="60" height="60" alt="" />
                  <p className={styles.title}>Chrome</p>
                  <a href="https://www.google.com/chrome/browser/desktop/">DOWNLOAD</a>
                </td>
                <td>
                  <img src="/resources/images/explorer.png" width="60" height="60" alt="" />
                  <p className={styles.title}>Internet Explorer</p>
                  <a href="http://windows.microsoft.com/ie">DOWNLOAD</a>
                </td>
              </tr>
            </table>
          </div>
        )}
        {!notSupported && (
          <div>
            <div id="orient" className={styles.app_wrapper}>
              {!internetConnected && <div className={styles.disconnected}>인터넷 연결 끊김</div>}
              <div className={styles.app_wrapper_inner}>{renderRoutes(route.routes)}</div>
            </div>
            <AlertContainer name="global" />
            <PopupWrapper>
              <ConfirmationPopup />
            </PopupWrapper>
          </div>
        )}
      </div>
    );
  }
}

export default App;
