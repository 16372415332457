import apolloClient from 'helpers/apolloClient';
import { brandListQuery } from './queries';
import { brandAddMutation, brandUpdateMutation } from './mutations';

export const FETCH_BRAND = 'brand/FETCH';
export const FETCH_BRAND_SUCCESS = 'brand/FETCH_SUCCESS';
export const FETCH_BRAND_FAILURE = 'brand/FETCH_FAILURE';

export const ADD_BRAND = 'brand/ADD';
export const ADD_BRAND_SUCCESS = 'brand/ADD_SUCCESS';
export const ADD_BRAND_FAILURE = 'brand/ADD_FAILURE';

export const ADD_BRAND_CONTENT = 'brand/content/ADD';
export const ADD_BRAND_CONTENT_SUCCESS = 'brand/content/ADD_SUCCESS';
export const ADD_BRAND_CONTENT_FAILURE = 'brand/content/ADD_FAILURE';

export const UPDATE_BRAND = 'brand/UPDATE';
export const UPDATE_BRAND_SUCCESS = 'brand/UPDATE_SUCCESS';
export const UPDATE_BRAND_FAILURE = 'brand/UPDATE_FAILURE';

export const SET_FILTER = 'brand/SET_FILTER';
export const RESET_FILTER = 'brand/RESET_FILTER';

export const SET_CURRENT_PAGE = 'brand/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'brand/SET_PAGE_SIZE';

export function getBrandList(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BRAND, FETCH_BRAND_SUCCESS, FETCH_BRAND_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: brandListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.brandList;
    },
  };
}

export function addBrand(brand) {
  return {
    types: [ADD_BRAND, ADD_BRAND_SUCCESS, ADD_BRAND_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: brandAddMutation,
        variables: brand,
      });

      return result.data.brandAdd;
    },
  };
}

export function updateBrand(brand) {
  return {
    types: [UPDATE_BRAND, UPDATE_BRAND_SUCCESS, UPDATE_BRAND_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: brandUpdateMutation,
        variables: brand,
      });

      return result;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
