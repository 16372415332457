import React from 'react';
import { InputNumber, Form, Col } from 'antd';
import PropTypes from 'prop-types';

import { commify } from 'utils/stringUtil';
import { isGreaterThan, required } from 'utils/formValidator';

import { formItemLayout } from './layout';

function BoostTwoNumbersForm(props) {
  const { form, initial, label, placeholder, name, require } = props;
  const { getFieldDecorator } = form;
  const minKey = `${name}Min`;
  const maxKey = `${name}Max`;
  const rule = require ? required() : [];

  return (
    <Form.Item label={label} {...formItemLayout} required={require}>
      <Col span={11}>
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(minKey, {
            initialValue: initial && initial[0] ? +initial[0] : null,
            rules: [rule],
          })(
            <InputNumber placeholder={placeholder[0]} min={0} max={10000000 - 1} formatter={value => commify(value)} />
          )}
        </Form.Item>
      </Col>
      <Col span={2}>
        <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
      </Col>
      <Col span={11}>
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(maxKey, {
            initialValue: initial && initial[1] ? +initial[1] : null,
            rules: [isGreaterThan(form.getFieldValue(minKey)), rule],
          })(<InputNumber placeholder={placeholder[1]} min={0} max={100000000} formatter={value => commify(value)} />)}
        </Form.Item>
      </Col>
    </Form.Item>
  );
}

BoostTwoNumbersForm.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.arrayOf(PropTypes.string).isRequired,
  initial: PropTypes.arrayOf(PropTypes.number).isRequired,
  require: PropTypes.bool,
};

BoostTwoNumbersForm.defaultProps = {
  require: false,
};

export default BoostTwoNumbersForm;
