import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Table, Button, Row } from 'antd';
import moment from 'moment';
import { formatDate } from 'utils/format';
import CopyableText from 'components/CopyableText';
import ApiKeyAddModal from './ApiKeyAddModal';
import ApiKeyEditModal from './ApiKeyEditModal';

const mapDispatchToProps = {};

@connect(null, mapDispatchToProps)
@autobind
class ApiKeys extends Component {
  static propTypes = {
    merchantId: PropTypes.string.isRequired,
    apiKeys: PropTypes.arrayOf(PropTypes.any),
  };

  static defaultProps = {
    apiKeys: [],
  };

  state = {
    selectedApiKey: null,
    loading: false,
    visibleAdd: false,
    visibleEdit: false,
    apiKeys: this.props.apiKeys,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ apiKeys: nextProps.apiKeys });
  }

  columns = [
    {
      title: 'Key',
      dataIndex: 'id',
      render: val => (
        <CopyableText value={val} tooltip={val}>
          {val}
        </CopyableText>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Expire',
      dataIndex: 'expireAt',
      render: value => <span>{value ? formatDate(value) : '-'}</span>,
    },
    {
      title: '',
      align: 'center',
      render: (val, record) => (
        <div>
          <Button
            type="primary"
            icon="edit"
            ghost
            onClick={() => this.setState({ visibleEdit: true, selectedApiKey: record })}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ];

  onAddClose(apiKey) {
    this.setState({ visibleAdd: false });
    if (apiKey) {
      this.setState({ apiKeys: [...this.state.apiKeys, apiKey] });
    }
  }

  onEditClose(apiKey) {
    this.setState({ visibleEdit: false, selectedApiKey: null });
    if (apiKey) {
      const newApiKeys = this.state.apiKeys.filter(item => item.id !== apiKey.id);
      this.setState({ apiKeys: [...newApiKeys, apiKey] });
    }
  }

  render() {
    return (
      <Row>
        <h3 style={{ marginBottom: 20 }}>API Keys</h3>

        <Button
          icon="plus"
          style={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => this.setState({ visibleAdd: true })}
        >
          Add
        </Button>

        <Table
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.apiKeys.sort((a, b) => (a.expireAt < b.expireAt && a.type < b.type ? 1 : -1))}
          loading={this.state.loading}
          pagination={false}
          rowClassName={record => (moment(record.expireAt).isBefore(new Date()) ? 'ant-table-row-selected' : '')}
          bordered
        />

        <ApiKeyAddModal merchantId={this.props.merchantId} visible={this.state.visibleAdd} onClose={this.onAddClose} />
        <ApiKeyEditModal
          apiKey={this.state.selectedApiKey}
          visible={this.state.visibleEdit}
          onClose={this.onEditClose}
        />
      </Row>
    );
  }
}

export default ApiKeys;
