import React from 'react';
import { Link } from 'react-router-dom';
import Exception from 'components/Exception';

const Error403 = () => (
  <Exception
    img="https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg"
    title="403"
    desc="서버가 요청을 거부했습니다."
    style={{ minHeight: 500, height: '80%' }}
    linkElement={Link}
  />
);

export default Error403;
