import React from 'react';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderId(val) {
      return <LinkText>{val.substr(0, 10)}...</LinkText>;
    },
    width: '15%',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: function renderType(val) {
      return <span>{startCase(val)}</span>;
    },
    width: '10%',
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    render: function renderCurrency(val) {
      return <span>{val.toUpperCase()}</span>;
    },
    width: '10%',
  },
  {
    title: 'Amount',
    dataIndex: 'delta',
    render: function renderAmount(val) {
      const symbol = val >= 0 ? '+' : '-';
      const color = symbol === '+' ? '#0eb53d' : '#e02323';
      return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
    },
    width: '15%',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    render: function renderBalance(val) {
      return <span>{commify(val)}</span>;
    },
    width: '15%',
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '30%',
  },
];

export default {
  columns,
};
