import gql from 'graphql-tag';

export const boostExclusionRuleAddMutation = gql`
  mutation boostExclusionRuleAdd($type: String!, $codeType: String!, $code: String!) {
    boostExclusionRuleAdd(type: $type, codeType: $codeType, code: $code)
  }
`;

export const boostExclusionRuleUpdateMutation = gql`
  mutation boostExclusionRuleUpdate($id: ID!, $type: String!, $codeType: String!, $code: String!) {
    boostExclusionRuleUpdate(id: $id, type: $type, codeType: $codeType, code: $code)
  }
`;

export default {
  boostExclusionRuleAddMutation,
  boostExclusionRuleUpdateMutation,
};
