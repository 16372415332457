import gql from 'graphql-tag';

export const brandAddMutation = gql`
  mutation brandAdd($name: String!, $color: String!, $logoImageUrl: String!, $typoImageUrl: String!) {
    brandAdd(name: $name, color: $color, logoImageUrl: $logoImageUrl, typoImageUrl: $typoImageUrl)
  }
`;

export const brandUpdateMutation = gql`
  mutation brandUpdate($id: Int!, $name: String!, $color: String!, $logoImageUrl: String!, $typoImageUrl: String!) {
    brandUpdate(id: $id, name: $name, color: $color, logoImageUrl: $logoImageUrl, typoImageUrl: $typoImageUrl)
  }
`;

export const brandUploadImage = gql`
  mutation brandUploadImage($imageFile: FileUpload!) {
    brandUploadImage(imageFile: $imageFile) {
      filename
      path
    }
  }
`;

export const brandUploadContent = gql`
  mutation brandUploadContent($brandId: Int!, $file: FileUpload!, $sourceFileName: String!, $visible: String!) {
    brandUploadContent(brandId: $brandId, file: $file, sourceFileName: $sourceFileName, visible: $visible) {
      name
      status
      url
    }
  }
`;

export const brandSwapContentPriority = gql`
  mutation brandSwapContentPriority($contentId: Int!, $contentIdToSwap: Int!) {
    brandSwapContentPriority(contentId: $contentId, contentIdToSwap: $contentIdToSwap)
  }
`;

export const brandDeleteContent = gql`
  mutation brandDeleteContent($contentId: Int!) {
    brandDeleteContent(contentId: $contentId)
  }
`;

export const brandChangeContentVisible = gql`
  mutation brandChangeContentVisible($contentId: Int!, $visible: String!) {
    brandChangeContentVisible(contentId: $contentId, visible: $visible)
  }
`;

export default {
  brandAddMutation,
  brandUpdateMutation,
  brandUploadImage,
  brandUploadContent,
  brandSwapContentPriority,
  brandDeleteContent,
  brandChangeContentVisible,
};
