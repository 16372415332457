import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon } from 'antd';

import { addBoostCategory, updateBoostCategory, getBoostCategories } from 'redux/modules/boostCategory/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';

import { boostCategoryQuery } from 'redux/modules/boostCategory/queries';
import styles from './BoostCategory.scss';
import { BoostTextForm, BoostNumberForm } from '../Forms';
import BoostCategoryImageUploadForm from './BoostCategoryImageUploadForm';
import BoostCategoryBoostList from './BoostCategoryBoostList';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.boostCategory;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBoostCategory,
  updateBoostCategory,
  getBoostCategories,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BoostCategoryForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    getBoostCategories: PropTypes.func.isRequired,
    addBoostCategory: PropTypes.func.isRequired,
    updateBoostCategory: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('boostCategory', id ? id.toString() : 'add');
    this.props.push('/boost/category');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: boostCategoryQuery,
        variables: {
          id: +id,
        },
      });

      const result = await this.query;
      const data = result.data.boostCategory;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Boost Category ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Boost Category: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  refreshBoostCategoryList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBoostCategories(currentPage, pageSize, filter);
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = ['name', 'imageUrl', 'priority'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field] && instance) {
          if (typeof instance.input !== 'undefined') {
            instance.input.focus();
          }
          return;
        }

        if (err[field]) {
          message.error(`${field}: ${err[field].errors[0].message}`);
          return;
        }
      }
      return;
    }

    if (form.getFieldValue('imageUrl').length === 0) {
      const instance = form.getFieldInstance('imageUrl');
      instance.focus();
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.name = formFields.name;
    fields.imageUrl = formFields.imageUrl[0].url;
    fields.priority = formFields.priority;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBoostCategory(input);

    this.props.removeAndFocusPreviousTab('boostCategory', 'add');
    this.props.push('/boost/category');
    this.refreshBoostCategoryList();
  }

  async update(input) {
    await this.props.updateBoostCategory(input);

    this.props.removeAndFocusPreviousTab('boostCategory', input.id.toString());
    this.props.push('/boost/category');
    this.refreshBoostCategoryList();
  }

  makeImageUrlInitial(url) {
    if (!url) {
      return [];
    }

    return [
      {
        uid: -1,
        name: url,
        status: 'done',
        url,
      },
    ];
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />
        <Divider />
        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <>
        <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
          <BoostTextForm form={form} name="name" label="Name" initial={data.name} require />
          <BoostCategoryImageUploadForm
            form={form}
            name="imageUrl"
            label="Image"
            initial={this.makeImageUrlInitial(data.imageUrl)}
            require
          />
          <BoostNumberForm form={form} name="priority" label="Priority" initial={data.priority} require />
          <div className={styles.formButtonContainer}>
            <Button type="ghost" onClick={this.closeTab}>
              Cancel
            </Button>
            <Divider type="vertical" style={{ background: '#fff' }} />
            <Button type="primary" htmlType="submit" disabled={updating}>
              {id ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
        {id && (
          <>
            <Divider />
            <BoostCategoryBoostList categoryId={id} />
          </>
        )}
      </>
    );
  }
}

export default BoostCategoryForm;
