import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { Card, Button, message, Skeleton, Alert } from 'antd';
import autobind from 'autobind-decorator';
import ContractDisplay from './ContractDisplay';
import ContractForm from './ContractForm';
import NoContract from './NoContract';
import { contractListQuery } from './ContractQueries';

@autobind
class ContractContainer extends Component {
  static propTypes = {
    activeTabKey: PropTypes.string.isRequired,
    merchantId: PropTypes.string.isRequired,
    createNewContract: PropTypes.bool,
  };

  static defaultProps = {
    createNewContract: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      error: null,
      loading: false,
      isCreatingContract: false,
    };

    this.query = null;

    if (props.createNewContract) {
      this.state.isCreatingContract = true;
    }
  }

  componentDidMount() {
    this.getContracts();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    const id = `=${this.props.merchantId}/contract`;

    if (prevProps.activeTabKey !== id && this.props.activeTabKey === id) {
      this.getContracts().then(contracts => {
        if (contracts.length === 0) {
          this.setState({ isCreatingContract: true });
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  async getContracts() {
    const { merchantId } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: contractListQuery,
        variables: {
          filter: JSON.stringify({ merchantId }),
        },
      });

      const result = await this.query;

      // If data is null, display error
      if (!result.data.contractList) {
        throw new Error('Invalid Merchant ID');
      } else {
        this.setState({
          loading: false,
          data: result.data.contractList.list,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Contracts: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  renderLoading() {
    return (
      <div>
        <Card bordered={false}>
          <Skeleton />
        </Card>
      </div>
    );
  }

  renderError() {
    return (
      <div>
        <Card bordered={false}>
          <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

          <Button icon="redo" onClick={this.getContracts}>
            Try Again
          </Button>
        </Card>
      </div>
    );
  }

  createNewContractForm() {
    this.setState({
      isCreatingContract: true,
    });
  }

  removeNewContractForm() {
    this.setState({
      isCreatingContract: false,
    });
  }

  /**
   * Switch mode of specified contract ID
   * @param {string} contractId
   * @param {string} mode - Mode of Contract. Must be 'DISPLAY' | 'UPDATE'
   */
  switchMode(contractId, mode) {
    if (mode !== 'DISPLAY' && mode !== 'UPDATE') {
      throw new Error(`ContractContainer.switchMode: Invalid Contract mode ${mode}`);
    }

    const { data } = this.state;

    for (let i = 0; i < data.length; i += 1) {
      if (data[i].id === contractId) {
        data[i].mode = mode;
        break;
      }
    }

    this.setState({ data });
  }

  handleSubmit() {
    this.setState({ isCreatingContract: false });
    this.getContracts();
  }

  render() {
    const { merchantId } = this.props;
    const { loading, error, data } = this.state;
    const { isCreatingContract } = this.state;

    if (loading) {
      return this.renderLoading();
    } else if (error) {
      return this.renderError();
    }

    return (
      <div>
        <Card bordered={false}>
          {isCreatingContract && (
            <ContractForm
              key="ADD"
              id="ADD"
              mode="ADD"
              merchantId={merchantId}
              switchMode={this.switchMode}
              showDivider
              idx={0}
              onSubmit={this.handleSubmit}
              onRemoveNewContract={this.removeNewContractForm}
            />
          )}

          {!isCreatingContract && data.length === 0 && <NoContract showTitle={false} />}

          {data.map((contract, idx) => {
            if (contract.mode === 'ADD' || contract.mode === 'UPDATE') {
              return (
                <ContractForm
                  key={contract.id}
                  merchantId={merchantId}
                  switchMode={this.switchMode}
                  {...contract}
                  showDivider
                  idx={idx}
                  onSubmit={this.handleSubmit}
                  onRemoveNewContract={this.removeNewContractForm}
                />
              );
            }

            return (
              <ContractDisplay
                key={contract.id}
                merchantId={merchantId}
                switchMode={this.switchMode}
                {...contract}
                showDivider
                idx={idx}
              />
            );
          })}

          {!isCreatingContract && (
            <div style={{ textAlign: 'right', marginTop: 15 }}>
              <Button type="primary" icon="plus-circle" onClick={this.createNewContractForm}>
                New Contract
              </Button>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default ContractContainer;
