import React from 'react';
import { string, oneOfType, arrayOf, node } from 'prop-types';
import { Form } from 'antd';
import styles from '../Promotion.scss';

const PromotionFormItem = ({ children, ...props }) => (
  <Form.Item {...props} className={props.className || styles.formControl}>
    {children}
  </Form.Item>
);

PromotionFormItem.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
};

PromotionFormItem.defaultProps = {
  className: null,
};

export default PromotionFormItem;
