import React, { Component } from 'react';
import { func, string, objectOf, arrayOf, any } from 'prop-types';
import { routerActions } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';

const buildPath = openAccessMenu => {
  const clone = cloneDeep(openAccessMenu);
  const result = clone.map(menu => {
    if (menu.parentId) {
      const parentPath = `${find(clone, { id: menu.parentId }).path}`;
      menu.path = `${parentPath}/${menu.path}`;
    } else {
      menu.path = `${menu.path}`;
    }

    return menu;
  });

  return result;
};

function withPermission(WrappedComponent) {
  class Wrapper extends Component {
    shouldComponentUpdate(nextProps) {
      return nextProps.openAccessMenu !== this.props.openAccessMenu;
    }

    render() {
      const { path } = this.props.route;
      const { openAccessMenu } = this.props;

      if (openAccessMenu && openAccessMenu.length > 0) {
        const openAccMenuWithPath = buildPath(openAccessMenu);
        const pathWithoutURLParam = path.replace(/\/:.+(?=$)/, '');

        if (!find(openAccMenuWithPath, { path: pathWithoutURLParam.substring(1) })) {
          this.props.replace('/exception/403');
        } else {
          return <WrappedComponent {...this.props} />;
        }
      }
      return null;
    }
  }

  Wrapper.propTypes = {
    openAccessMenu: arrayOf(any).isRequired,
    path: string,
    route: objectOf(any).isRequired,
    replace: func.isRequired,
  };

  Wrapper.defaultProps = {
    path: '',
  };

  return Wrapper;
}

const mapStateToProps = state => ({
  openAccessMenu: state.global.openAccessMenu,
});

const mapDispatchToProps = dispatch => ({
  replace: path => dispatch(routerActions.replace(path)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withPermission);
