import gql from 'graphql-tag';

export const bannerGroupListQuery = gql`
  query bannerGroupList {
    bannerGroupList {
      list {
        id
        name
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export default {
  bannerGroupListQuery,
};
