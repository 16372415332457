import gql from 'graphql-tag';

export const userUpdateMutation = gql`
  mutation userUpdate($id: ID!, $status: String) {
    promotionUpdate(id: $id, status: $status)
  }
`;

export default {
  userUpdateMutation,
};
