import { FETCH_GROUP_MERCHANT, FETCH_GROUP_MERCHANT_SUCCESS, FETCH_GROUP_MERCHANT_FAILURE } from './actions';

export const defaultFilter = {
  status: 'enabled',
};

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10,
  filter: {
    ...defaultFilter,
  },
  updating: false,
  updateError: null,
};

export default function GroupMerchantReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUP_MERCHANT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_GROUP_MERCHANT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result,
      };
    case FETCH_GROUP_MERCHANT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
