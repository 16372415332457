import gql from 'graphql-tag';

export const boltExclusionRuleAddMutation = gql`
  mutation boltExclusionRuleAdd(
    $policyType: String!
    $receiverType: String!
    $codeType: String!
    $code: String!
    $period: String!
    $limit: Int!
    $maxBoltCount: Int
  ) {
    boltExclusionRuleAdd(
      policyType: $policyType
      receiverType: $receiverType
      codeType: $codeType
      code: $code
      period: $period
      limit: $limit
      maxBoltCount: $maxBoltCount
    )
  }
`;

export const boltExclusionRuleUpdateMutation = gql`
  mutation boltExclusionRuleUpdate(
    $id: ID!
    $policyType: String!
    $receiverType: String!
    $codeType: String!
    $code: String!
    $period: String!
    $limit: Int!
    $maxBoltCount: Int
  ) {
    boltExclusionRuleUpdate(
      id: $id
      policyType: $policyType
      receiverType: $receiverType
      codeType: $codeType
      code: $code
      period: $period
      limit: $limit
      maxBoltCount: $maxBoltCount
    )
  }
`;

export default {
  boltExclusionRuleAddMutation,
  boltExclusionRuleUpdateMutation,
};
