import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import DataViewer from 'components/DataViewer';
import { TabData } from 'containers/Layout';
import { push } from 'connected-react-router';
import { addTab, focusTab } from 'redux/modules/tabs';
import { ellipsis } from 'utils/text';
import { Pagination, Divider, Alert, message, Popconfirm, Tooltip, Icon } from 'antd';
import { withRouter } from 'react-router';
import PageSizeDropdown from 'components/PageSizeDropdown';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import apolloClient from 'helpers/apolloClient';

import { getTagPolicyListQuery } from 'redux/modules/tag/queries';
import { deletePolicyMappingMutation } from 'redux/modules/tag/mutations';

import { TagPromotionType } from '..';
import styles from '../Tag.scss';
import policyColumns from './PolicyMappingListMetadata';

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
};
@connect(null, mapDispatchToProps)
@withRouter
@autobind
class PolicyMappingList extends Component {
  static propTypes = {
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,

    tagName: PropTypes.string.isRequired,
  };

  state = {
    loading: false,
    currentPage: 1,
    totalCount: 0,
    pageSize: 10,
    data: [],
    error: null,
  };

  componentDidMount() {
    const { currentPage, pageSize } = this.state;
    const { tagName } = this.props;

    this.getData(currentPage, pageSize, tagName);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }
  query = null;

  /**
   * Inject Cell related callback props into columns
   * This makes your code can seperate handling cell related jobs from own class,   not column definition code.
   * @return {Object} connectedColumns
   */
  getConnectedColumns() {
    return [
      ...policyColumns,
      {
        title: 'Tools',
        fixed: 'right',
        width: 200,
        render: record => (
          <div>
            <Popconfirm title="Are you sure?" onConfirm={() => this.delPolicyMapping(record.id)}>
              <Tooltip title="Delete policy Mapping">
                <Icon className={styles.toolIcon} type="delete" />
              </Tooltip>
            </Popconfirm>
          </div>
        ),
      },
    ];
  }

  /**
   * Handle cell click to open tab only in first cell.
   * @param {Object} record
   */
  handleCellClick(record, extra) {
    const { index } = extra;
    if (index === 1) {
      this.openDataTab(record);
    }
  }

  openDataTab(record) {
    let componentType;
    let activeTab;
    let activePath;
    const id = record.policyId;
    if (record.type === TagPromotionType.PROMOTION) {
      componentType = 'PromotionForm';
      activeTab = 'promotion';
      activePath = `/${activeTab}/policy/${id}`;
    } else if (record.type === TagPromotionType.BOLT) {
      componentType = 'BoltPolicyForm';
      activeTab = 'boltPolicy';
      activePath = `/boost/bolt/policy/${id}`;
    } else if (record.type === TagPromotionType.BOOST) {
      componentType = 'BoostPromotionForm';
      activeTab = 'boostPromotion';
      activePath = `/boost/policy/${id}`;
    }

    const tabData = new TabData({
      key: id,
      title: ellipsis(id, 5),
      closable: true,
      data: {
        id,
      },
      componentType,
    });
    this.props.addTab(activeTab, tabData);
    this.props.focusTab(activeTab, id);
    this.props.push(activePath);
  }

  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;
    this.setState({ pageSize: newPageSize });

    const { tagName } = this.props;
    const { currentPage } = this.state;

    this.getData(currentPage, newPageSize, tagName);
  }

  changePage(newPage) {
    this.setState({ currentPage: newPage });
    const { tagName } = this.props;
    const { pageSize } = this.state;

    this.getData(newPage, pageSize, tagName);
  }

  async getData(currentPage, pageSize, tagName) {
    try {
      this.setState({ loading: true, error: null });
      const queryOptions = {
        query: getTagPolicyListQuery,
        variables: {
          tagName,
          skip: (currentPage - 1) * pageSize,
          pageSize,
        },
      };

      this.query = cancelableQuery(queryOptions);

      const result = await this.query;
      const data = result.data.getTagPolicyList.list;
      const totalCount = result.data.getTagPolicyList.total;

      this.setState({
        loading: false,
        data: [...data],
        totalCount,
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get policy mapping List');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  async delPolicyMapping(id) {
    try {
      await apolloClient.mutate({
        mutation: deletePolicyMappingMutation,
        variables: { id: Number(id) },
      });

      message.success(`Id: ${id} deleted.`);
    } catch (error) {
      message.error('Failed to delete Policy Mapping.\n', error.message);
    }

    const { tagName } = this.props;
    const { currentPage, pageSize } = this.state;

    this.getData(currentPage, pageSize, tagName);
  }

  renderError() {
    const { error } = this.state;
    const errorMessage = error ? error.message : 'Unknown Error';

    return (
      <Fragment>
        <Divider style={{ background: 'none', margin: '10px 0' }} />
        <Alert type="warning" closable message={`Failed to load  policy mapping list: ${errorMessage}.`} />
        <Divider style={{ background: 'none', margin: '10px 0' }} />
      </Fragment>
    );
  }

  render() {
    const connectedColumns = this.getConnectedColumns();

    const { error, currentPage, pageSize, totalCount, data, loading } = this.state;

    return (
      <div>
        <div>
          <div style={{ position: 'relative', marginBottom: 15 }}>
            <div>
              <span style={{ fontSize: 14, marginRight: 15 }}>Total {totalCount}</span>
              <PageSizeDropdown icon="down" currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
            </div>
          </div>
        </div>

        {error && this.renderError()}

        <DataViewer columns={connectedColumns} data={data} loading={loading} onCellClick={this.handleCellClick} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            textAlign: 'center',
          }}
        />
      </div>
    );
  }
}

export default PolicyMappingList;
