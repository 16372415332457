import React, { Fragment } from 'react';
import startCase from 'lodash/startCase';
import { Badge } from 'antd';
import LinkText from 'components/LinkText';

/**
 * Get Badge element with corresponding settings
 * @param {string} statusText
 * @return {Node} React Element
 */
const getStatus = status =>
  ({
    enabled: 'success',
    disabled: 'error',
    expired: 'default',
    ready: 'processing',
    hold: 'warning',
  }[status]);

export const columns = [
  {
    title: 'Display Name',
    dataIndex: 'name',
    render(val) {
      return <LinkText>{val}</LinkText>;
    },
  },
  {
    title: 'Admin Name',
    dataIndex: 'displayName',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'CS',
    dataIndex: 'customerServicePhone',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'WebSite',
    dataIndex: 'website',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'PG Code',
    dataIndex: 'pgMerchantCode',
    align: 'center',
    render(val) {
      if (!val || typeof val !== 'string' || val.length < 1) {
        return <span style={{ color: 'red' }}>Need PG Code</span>;
      }
      return <span>{val}</span>;
    },
  },
  {
    title: 'Contract',
    dataIndex: 'contracts',
    align: 'center',
    render(val) {
      if (!Array.isArray(val) || val.length < 1) {
        return <span style={{ color: 'red' }}>Need Contract</span>;
      }
      return <span>{val[0].fee}</span>;
    },
  },
  {
    title: 'LinkMethod',
    dataIndex: 'linkMethod',
    align: 'center',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'Funnel',
    dataIndex: 'funnel',
    align: 'center',
    render(val) {
      return <span>{val}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: function renderStatus(val) {
      return (
        <Fragment>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </Fragment>
      );
    },
  },
];

export default {
  columns,
};
