/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import autobind from 'autobind-decorator';

const styles = require('./AlertContainer.scss');

export const SUCCESS = 'success';
export const INFO = 'info';
export const WARNING = 'warning';
export const ERROR = 'error';

export default class AlertItem extends Component {
  static propTypes = {
    type: PropTypes.oneOf([SUCCESS, INFO, WARNING, ERROR]).isRequired,
    autoCloseAfter: PropTypes.number,
    html: PropTypes.bool,
    message: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    counter: PropTypes.number.isRequired,
  };

  static defaultProps = {
    autoCloseAfter: 2000,
    html: false,
  };

  componentDidMount() {
    if (this.props.autoCloseAfter) {
      this.timer = setTimeout(this.handleClose, this.props.autoCloseAfter);
    }
  }

  componentDidUpdate() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.handleClose, this.props.autoCloseAfter);
    }
  }

  @autobind
  handleClose() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.props.onClose(this.props.id);
  }

  render() {
    let message;

    if (this.props.html) {
      message = <span dangerouslySetInnerHTML={{ __html: this.props.message }} />;
    } else {
      message = <span>{`${this.props.message}${this.props.counter > 1 ? ` x ${this.props.counter}` : ''}`}</span>;
    }

    return (
      <div className={styles.alert} role="alert">
        <div className={cx(styles.inner, styles[this.props.type])}>
          <button type="button" className={styles.close} aria-label="Close" onClick={this.handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          {message}
        </div>
      </div>
    );
  }
}
