import { stopBodyScroll } from 'utils/browserUtil';

const SHOW = 'popup/SHOW';
const CONFIRM = 'popup/CONFIRM';
const CANCEL = 'popup/CANCEL';

const initialState = {
  isShowing: false,
  name: '',
  payload: null,
};

const defaultCallback = () => {};
let handleConfirm = () => {};
let handleCancel = () => {};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW:
      return {
        ...state,
        isShowing: true,
        name: action.name,
        payload: action.payload,
      };

    case CONFIRM:
      setTimeout(handleConfirm, 0);
      return {
        ...state,
        name: '',
        isShowing: false,
      };

    case CANCEL:
      setTimeout(handleCancel, 0);
      return {
        ...state,
        name: '',
        isShowing: false,
      };

    default:
      return state;
  }
}

export function show(params) {
  handleConfirm = params.callback || params.confirm || defaultCallback;
  handleCancel = params.cancel || defaultCallback;

  const payload = { ...params };

  delete payload.name;
  delete payload.callback;
  delete payload.confirm;
  delete payload.cancel;

  stopBodyScroll(true);

  return {
    type: SHOW,
    name: params.name,
    payload: Object.keys(payload).length > 0 ? payload : null,
  };
}

export function confirm() {
  stopBodyScroll(false);
  return { type: CONFIRM };
}

export function cancel() {
  stopBodyScroll(false);
  return { type: CANCEL };
}
