import React from 'react';
import { arrayOf, string, func } from 'prop-types';
import { Button, Popconfirm } from 'antd';
import styles from './ImageList.scss';

const ImageList = ({ dataSource, onDelete }) => (
  <div className={styles.imageList}>
    {dataSource.length === 0 && <div className={styles.noImage}>No Image</div>}
    {dataSource.map(filePath => (
      <div key={filePath} className={styles.image}>
        <img alt={filePath} src={`https://static.chai.finance/${filePath}`} />
        <Popconfirm
          title="Are you sure?"
          placement="bottomLeft"
          okText="Delete"
          cancelText="No"
          onConfirm={onDelete.bind(null, filePath)}
        >
          <Button className={styles.imageDeleteButton} type="danger" icon="delete" ghost />
        </Popconfirm>
      </div>
    ))}
  </div>
);

ImageList.propTypes = {
  dataSource: arrayOf(string),
  onDelete: func.isRequired,
};

ImageList.defaultProps = {
  dataSource: [],
};

export default ImageList;
