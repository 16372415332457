import React from 'react';
import { Link } from 'react-router-dom';
import Exception from 'components/Exception';

const Error500 = () => (
  <Exception
    img="https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg"
    title="500"
    desc="서버에 오류가 발생했습니다."
    style={{ minHeight: 500, height: '80%' }}
    linkElement={Link}
  />
);

export default Error500;
