import searchSchema from './searchSchema';
import searchUiSchema from './searchUiSchema';
import editSchema from './editSchema';
import editUiSchema from './editUiSchema';

export default {
  searchSchema,
  searchUiSchema,
  editSchema,
  editUiSchema,
};
