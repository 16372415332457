import apolloClient from 'helpers/apolloClient';
import { b2bUserListQuery } from './queries';
import { b2bUserUpdateMutation, b2bUserAddMutation, b2bUserBalanceUpdateMutation } from './mutations';

export const FETCH_B2B_USER = 'b2bUser/FETCH';
export const FETCH_B2B_USER_SUCCESS = 'b2bUser/FETCH_SUCCESS';
export const FETCH_B2B_USER_FAILURE = 'b2bUser/FETCH_FAILURE';

export const ADD_B2B_USER = 'b2bUser/ADD';
export const ADD_B2B_USER_SUCCESS = 'b2bUser/ADD_SUCCESS';
export const ADD_B2B_USER_FAILURE = 'b2bUser/ADD_FAILURE';

export const UPDATE_B2B_USER = 'b2bUser/UPDATE';
export const UPDATE_B2B_USER_SUCCESS = 'b2bUser/UPDATE_SUCCESS';
export const UPDATE_B2B_USER_FAILURE = 'b2bUser/UPDATE_FAILURE';

export const SET_FILTER = 'b2bUser/SET_FILTER';
export const RESET_FILTER = 'b2bUser/RESET_FILTER';

export const SET_CURRENT_PAGE = 'b2bUser/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'b2bUser/SET_PAGE_SIZE';

export const RESET = 'b2bUser/RESET';

export function getB2BUsers(currentPage, pageSize, filter) {
  return {
    types: [FETCH_B2B_USER, FETCH_B2B_USER_SUCCESS, FETCH_B2B_USER_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: b2bUserListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.b2bUserList;
    },
  };
}

export function addB2BUser(b2bUser) {
  return {
    types: [ADD_B2B_USER, ADD_B2B_USER_SUCCESS, ADD_B2B_USER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: b2bUserAddMutation,
        variables: b2bUser,
      });

      return b2bUser;
    },
  };
}

export function updateB2BUser(b2bUser) {
  return {
    types: [UPDATE_B2B_USER, UPDATE_B2B_USER_SUCCESS, UPDATE_B2B_USER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: b2bUserUpdateMutation,
        variables: b2bUser,
      });

      return b2bUser;
    },
  };
}

export function updateB2BUserBalance(b2bUser) {
  return {
    types: [UPDATE_B2B_USER, UPDATE_B2B_USER_SUCCESS, UPDATE_B2B_USER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: b2bUserBalanceUpdateMutation,
        variables: b2bUser,
      });

      return b2bUser;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function reset() {
  return {
    type: RESET,
  };
}
