import React from 'react';
import PropTypes from 'prop-types';
import styles from './Contract.scss';

const ContractDivider = ({ isActiveContract, number }) => {
  const dividerTextStyle = [styles.contractDividerText, isActiveContract ? styles.contractDividerTextActive : ''];

  return (
    <div className={styles.contractDivider}>
      <div className={dividerTextStyle.join(' ')}>{number}</div>

      <div className={styles.contractDividerLine} />
    </div>
  );
};

ContractDivider.propTypes = {
  isActiveContract: PropTypes.bool.isRequired,
  number: PropTypes.number.isRequired,
};

export default ContractDivider;
