import React from 'react';
import { Table, Popconfirm, Divider, Button, Modal, Icon, notification, message } from 'antd';
import gql from 'graphql-tag';
import uuidv4 from 'uuid/v4';
import store from 'store';
import apolloClient from 'helpers/apolloClient';
import SearchForm from 'schema/SearchForm';
import CommonForm from 'schema/CommonForm';
import schema from 'schema/action';
import Require from 'components/Permission/Require';
import formRemoteDataUtil from 'schema/FormRemoteDataUtil';
import { openTreeData, getTreeEleWithParent, getTreeEleByPropertyValue } from 'utils/permission';
import { getAdminActionsQuery } from './ActionQueries';
import { saveActionMutation, removeActionsMutation } from './ActionMutations';
import styles from './styles.scss';

class Action extends React.PureComponent {
  state = {
    filter: {
      menu: '',
      name: '',
      code: '',
    },
    selectedRowKeys: [],
    pagedList: [],
    pagination: {
      current: 1,
      pageSize: 10,
      showTotal: total => `Total ${total} item(s)`,
    },
    sorter: {
      field: '',
      order: '',
    },
    loading: false,
    editModalVisible: false,
  };

  componentDidMount() {
    this.fetch({
      skip: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      filter: this.state.filter,
    });
  }

  columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Granted Groups',
      render: record => record.groups.map(g => g.code).toString(),
      sorter: true,
    },
    {
      title: 'Menu Name',
      dataIndex: 'menu.name',
      sorter: true,
    },
    {
      title: 'Tools',
      dataIndex: 'id',
      fixed: 'right',
      width: 120,
      render: (text, record) => (
        <div>
          <a className={styles.toolIcon} onClick={() => this.editAction(record)}>
            Edit
          </a>
          <Divider type="vertical" />
          <Popconfirm title="Are you sure?" onConfirm={() => this.delAction(record.id)}>
            <Icon type="delete" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  editFormData = {};
  fetch = async query => {
    this.setState({ loading: true });

    const { filter } = query;
    const { data } = await apolloClient.query({
      query: gql`
        query getActions($filter: String) {
          getActions(filter: $filter) {
            id
            name
            code
            description
            menu {
              id
              name
            }
            groups {
              id
              code
            }
          }
        }
      `,
      variables: {
        filter: JSON.stringify(filter),
      },
    });
    const { getActions: actions } = data;
    const pagination = { ...this.state.pagination };
    pagination.total = data.totalCount;
    this.setState({
      loading: false,
      pagedList: actions,
      pagination,
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    pager.pageSize = pagination.pageSize;
    this.setState({
      pagination: pager,
      sorter: {
        field: sorter.field,
        order: sorter.order,
      },
    });
    const query = {
      skip: pager.current,
      pageSize: pager.pageSize,
      sortBy: sorter.field,
      descending: sorter.order === 'descend',
      filter: this.state.filter,
    };
    this.fetch(query);
  };
  handleSearch = filter => {
    const pager = { ...this.state.pagination };
    pager.current = 1;
    this.setState({
      filter,
      pagination: pager,
    });
    const query = {
      skip: 0,
      pageSize: this.state.pagination.pageSize,
      sortBy: this.state.sorter.field,
      descending: this.state.sorter.order === 'descend',
      filter,
    };
    this.fetch(query);
  };
  handleReset = () => {
    this.setState({
      filter: {},
    });

    this.fetch({
      skip: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      filter: {},
    });
  };
  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };
  delAction = async id => {
    try {
      await apolloClient.mutate({
        mutation: removeActionsMutation,
        variables: { ids: [id] },
      });

      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Delete Success',
      });

      this.refresh();
      this.refreshAdminData();
    } catch (e) {
      console.log(e);
    }
  };
  batchDelAction = async () => {
    try {
      const ids = this.state.selectedRowKeys.map(s => s);

      await apolloClient.mutate({
        mutation: removeActionsMutation,
        variables: { ids },
      });

      this.setState({
        selectedRowKeys: [],
      });
      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Delete Success',
      });

      this.refresh();
      this.refreshAdminData();
    } catch (e) {
      console.log(e);
    }
  };
  addAction = () => {
    this.editFormData = {};
    this.setState({
      editModalVisible: true,
    });
  };
  editAction = record => {
    let menuId;
    if (record.menu) {
      const menuList = formRemoteDataUtil.getData(`${schema.editSchema.$id}_menuId`);
      const openMenuList = openTreeData(menuList);
      const menuWithParent = getTreeEleWithParent(record.menu.id, openMenuList);
      menuId = menuWithParent.map(s => s.id);
    }

    this.editFormData = { ...record, menuId };
    this.setState({
      editModalVisible: true,
    });
  };
  saveAction = async data => {
    const formData = { ...this.editFormData, ...data };

    const menuList = formRemoteDataUtil.getData(`${schema.editSchema.$id}_menuId`);
    if (formData.menuId) {
      formData.menuId = formData.menuId[formData.menuId.length - 1];
    }
    const menu = getTreeEleByPropertyValue(formData.menuId, 'id', menuList);
    formData.menu = menu.title;

    const { id = uuidv4(), code, menuId = '', description = '', name, groupIds } = formData;

    try {
      await apolloClient.mutate({
        mutation: saveActionMutation,
        variables: {
          groupIds,
          id,
          name,
          code,
          menuId,
          description,
        },
      });

      this.setState({
        editModalVisible: false,
      });
      notification.success({
        placement: 'bottomLeft bottomRight',
        message: 'Save success',
      });

      this.refresh();
      this.refreshAdminData();
    } catch (e) {
      console.log(e);
    }
  };
  editModalOnOk = () => {
    this.editActionForm.handleSubmit();
  };
  editModalOnCancel = () => {
    this.setState({
      editModalVisible: false,
    });
  };
  refresh = () => {
    const query = {
      skip: this.state.pagination.current,
      pageSize: this.state.pagination.pageSize,
      sortBy: this.state.sorter.field,
      descending: this.state.sorter.order === 'descend',
      filter: this.state.filter,
    };
    this.fetch(query);
  };

  /**
   * Re-fetch Admin Data to update user's actions
   */
  async refreshAdminData() {
    try {
      const result = await apolloClient.query({
        query: getAdminActionsQuery,
      });

      const { getMyAdmin: data } = result.data;
      const adminInfo = store.get('admin');

      store.set('admin', {
        ...adminInfo,
        groups: data.groups,
      });

      this.forceUpdate();
    } catch (err) {
      message.warning('Failed to load Admim Data.');
      console.log(err);
    }
  }

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    return (
      <div style={{ background: '#FFFFFF', padding: '10px' }}>
        <SearchForm
          schema={schema.searchSchema}
          uiSchema={schema.searchUiSchema}
          handleSubmit={this.handleSearch}
          handleReset={this.handleReset}
        />
        <Divider />
        <div style={{ marginBottom: 16 }}>
          <Require permissions={['action_edit']}>
            <Button type="primary" icon="plus-square-o" onClick={this.addAction}>
              Add
            </Button>
          </Require>
          <Divider type="vertical" />
          <Require permissions={['action_del']}>
            <Popconfirm title="Are you sure?" onConfirm={this.batchDelAction}>
              <Button type="danger" disabled={!hasSelected} icon="delete">
                Delete
              </Button>
            </Popconfirm>
          </Require>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={this.columns}
          rowKey={record => record.id}
          dataSource={this.state.pagedList}
          pagination={this.state.pagination}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          scroll={{ x: 768 }}
          bordered
        />
        <Modal
          visible={this.state.editModalVisible}
          cancelText="Close"
          okText="Save"
          title={this.editFormData.id ? 'Edit Action' : 'Add Action'}
          onCancel={this.editModalOnCancel}
          onOk={this.editModalOnOk}
          destroyOnClose
        >
          <CommonForm
            ref={instance => {
              this.editActionForm = instance;
            }}
            schema={schema.editSchema}
            uiSchema={schema.editUiSchema}
            formData={this.editFormData}
            handleSubmit={this.saveAction}
          />
        </Modal>
        {/* Hide components, after data init, every time the parent component render will follow the render */}
        <CommonForm schema={schema.editSchema} uiSchema={schema.editUiSchema} style={{ display: 'none' }} />
      </div>
    );
  }
}

export default Action;
