import apolloClient from 'helpers/apolloClient';
import { settlementPaymentListQuery } from './queries';

export const FETCH_SETTLEMENT_PAYMENT = 'settlementPayment/FETCH';
export const FETCH_SETTLEMENT_PAYMENT_SUCCESS = 'settlementPayment/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_PAYMENT_FAILURE = 'settlementPayment/FETCH_FAILURE';

export const SET_FILTER = 'settlementPayment/SET_FILTER';
export const RESET_FILTER = 'settlementPayment/RESET_FILTER';

export const SET_CURRENT_PAGE = 'settlementPayment/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'settlementPayment/SET_PAGE_SIZE';

export function getSettlementPayments(currentPage, pageSize, filter) {
  return {
    types: [FETCH_SETTLEMENT_PAYMENT, FETCH_SETTLEMENT_PAYMENT_SUCCESS, FETCH_SETTLEMENT_PAYMENT_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: settlementPaymentListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.settlementPaymentList;
    },
  };
}

/*
 * Set filter for getting Settlement Payment
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
