import gql from 'graphql-tag';

export const makeTagPolicyMappingMutation = gql`
  mutation makeTagPolicyMapping($tagName: String!, $policyType: String!, $policyId: String!) {
    makeTagPolicyMapping(tagName: $tagName, policyType: $policyType, policyId: $policyId)
  }
`;

export const makeTagUserMappingMutation = gql`
  mutation makeTagUserMapping($tagName: String!, $targetType: String!, $targetIdFile: FileUpload!) {
    makeTagUserMapping(tagName: $tagName, targetType: $targetType, targetIdFile: $targetIdFile)
  }
`;

export const updateTagMutation = gql`
  mutation updateTag($tagId: Int!, $tagName: String!, $status: String!) {
    updateTag(tagId: $tagId, tagName: $tagName, status: $status) {
      id
      name
      status
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteTagMutation = gql`
  mutation deleteTag($tagId: Int!) {
    deleteTag(tagId: $tagId)
  }
`;

export const addTagMutation = gql`
  mutation addTag($tagName: String!, $status: String!) {
    addTag(tagName: $tagName, status: $status) {
      id
      name
      status
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteTargetMappingMutation = gql`
  mutation deleteTargetMapping($id: Int!) {
    deleteTargetMapping(id: $id)
  }
`;

export const deleteTargetMappingsMutation = gql`
  mutation deleteTargetMappings($ids: [Int]) {
    deleteTargetMappings(ids: $ids)
  }
`;

export const deletePolicyMappingMutation = gql`
  mutation deletePolicyMapping($id: Int!) {
    deletePolicyMapping(id: $id)
  }
`;

export default {
  makeTagPolicyMappingMutation,
  updateTagMutation,
  addTagMutation,
  deleteTagMutation,
  deleteTargetMappingMutation,
  deleteTargetMappingsMutation,
  deletePolicyMappingMutation,
};
