import gql from 'graphql-tag';

export const agreementQuery = gql`
  query agreement($id: ID!) {
    agreement(id: $id) {
      id
      group
      previousId
      title
      description
      optional
      isActive
      sortedIndex
      createdAt
      updatedAt
      issueDate
      author
      issuedCount
      reagree
      reagreeText
    }
  }
`;

export default {
  agreementQuery,
};
