export const SHOW_I18N_MODAL = 'i18n/SHOW_MODAL';
export const HIDE_I18N_MODAL = 'i18n/HIDE_MODAL';

export function showI18nModal(payload) {
  return {
    type: SHOW_I18N_MODAL,
    payload,
  };
}

export function hideI18nModal() {
  return {
    type: HIDE_I18N_MODAL,
  };
}
