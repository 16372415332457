import apolloClient from 'helpers/apolloClient';
import { bannerListQuery } from './queries';
import { bannerAddMutation, bannerUpdateMutation, bannerSortedIndexUpdate, bannerRemoveMutation } from './mutations';

export const FETCH_BANNER = 'banner/FETCH';
export const FETCH_BANNER_SUCCESS = 'banner/FETCH_SUCCESS';
export const FETCH_BANNER_FAILURE = 'banner/FETCH_FAILURE';

export const ADD_BANNER = 'banner/ADD';
export const ADD_BANNER_SUCCESS = 'banner/ADD_SUCCESS';
export const ADD_BANNER_FAILURE = 'banner/ADD_FAILURE';

export const UPDATE_BANNER = 'banner/UPDATE';
export const UPDATE_BANNER_SUCCESS = 'banner/UPDATE_SUCCESS';
export const UPDATE_BANNER_FAILURE = 'banner/UPDATE_FAILURE';

export const UPDATE_BANNER_SORTED_INDEX = 'banner/UPDATE_SORTED_INDEX';
export const UPDATE_BANNER_SORTED_INDEX_SUCCESS = 'banner/UPDATE_SORTED_INDEX_SUCCESS';
export const UPDATE_BANNER_SORTED_INDEX_FAILURE = 'banner/UPDATE_SORTED_INDEX_FAILURE';

export const REMOVE_BANNER = 'banner/REMOVE';
export const REMOVE_BANNER_SUCCESS = 'banner/REMOVE_SUCCESS';
export const REMOVE_BANNER_FAILURE = 'banner/REMOVE_FAILURE';

export const SET_FILTER = 'banner/SET_FILTER';
export const RESET_FILTER = 'banner/RESET_FILTER';

export const SET_CURRENT_PAGE = 'banner/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'banner/SET_PAGE_SIZE';

export const SET_BANNER_SORTED_INDEX = 'banner/SET_SORTED_INDEX';

export function getBanners(currentPage, pageSize, filter) {
  return {
    types: [FETCH_BANNER, FETCH_BANNER_SUCCESS, FETCH_BANNER_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: bannerListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.bannerList;
    },
  };
}

export function addBanner(banner) {
  return {
    types: [ADD_BANNER, ADD_BANNER_SUCCESS, ADD_BANNER_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: bannerAddMutation,
        variables: banner,
      });

      return result.data.bannerAdd;
    },
  };
}

export function updateBanner(banner) {
  return {
    types: [UPDATE_BANNER, UPDATE_BANNER_SUCCESS, UPDATE_BANNER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: bannerUpdateMutation,
        variables: banner,
      });

      return banner;
    },
  };
}

export function updateBannerSortedIndex(sortingList) {
  return {
    types: [UPDATE_BANNER, UPDATE_BANNER_SUCCESS, UPDATE_BANNER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: bannerSortedIndexUpdate,
        variables: {
          sortingList,
        },
      });

      return sortingList;
    },
  };
}

export function removeBanner(banner) {
  return {
    types: [REMOVE_BANNER, REMOVE_BANNER_SUCCESS, REMOVE_BANNER_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: bannerRemoveMutation,
        variables: banner,
      });

      return banner;
    },
  };
}

/*
 * Set filter for getting Merchants
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function setSortedIndex(id, newSortedIndex) {
  return {
    type: SET_BANNER_SORTED_INDEX,
    payload: {
      id,
      newSortedIndex,
    },
  };
}
