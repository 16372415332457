import Agreement from './Agreement';
import Banner from './Banner';
import Notice from './Notice';
import Faq from './Faq';

export default {
  Agreement,
  Banner,
  Notice,
  Faq,
};
