import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon } from 'antd';

import { addBrand, updateBrand, getBrandList } from 'redux/modules/brand/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';

import { brandQuery } from 'redux/modules/brand/queries';
import styles from './Brand.scss';
import { BoostTextForm } from '../Forms';
import BrandImageUploadForm from './BrandImageUploadForm';
import { formItemLayout } from '../Forms/layout';
import Contents from './Content/Contents';

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.brand;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addBrand,
  updateBrand,
  getBrandList,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class BrandForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    addBrand: PropTypes.func.isRequired,
    updateBrand: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    getBrandList: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    // Brand XHR Status
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('brand', id ? id.toString() : 'add');
    this.props.push('/boost/brand');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: brandQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.brand;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Brand');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Brand: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  refreshBrandList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getBrandList(currentPage, pageSize, filter);
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = ['name', 'color', 'logoImageUrl', 'typoImageUrl'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field] && instance) {
          if (typeof instance.input !== 'undefined') {
            instance.input.focus();
          }
          return;
        }

        if (err[field]) {
          message.error(`${field}: ${err[field].errors[0].message}`);
          return;
        }
      }
      return;
    }

    if (form.getFieldValue('logoImageUrl').length === 0) {
      const instance = form.getFieldInstance('logoImageUrl');
      instance.focus();
      return;
    }

    if (form.getFieldValue('typoImageUrl').length === 0) {
      const instance = form.getFieldInstance('typoImageUrl');
      instance.focus();
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.name = formFields.name;
    fields.color = formFields.color;
    fields.logoImageUrl = formFields.logoImageUrl[0].url;
    fields.typoImageUrl = formFields.typoImageUrl[0].url;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addBrand(input);

    this.props.removeAndFocusPreviousTab('brand', 'add');
    this.props.push('/boost/brand');
    this.refreshBrandList();
  }

  async update(input) {
    await this.props.updateBrand(input);

    // this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('brand', input.id.toString());
    this.props.push('/boost/brand');
    this.refreshBrandList();
  }

  makeImageUrlInitial(url) {
    if (!url) {
      return [];
    }

    return [
      {
        uid: -1,
        name: url,
        status: 'done',
        url,
      },
    ];
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  renderPreview() {
    const { form } = this.props;
    const data = this.state.data || {};

    const fields = form.getFieldsValue();
    let { color, logoImageUrl, typoImageUrl } = fields;

    if (!color && data.color) {
      color = data.color;
    }

    if (logoImageUrl && logoImageUrl[0]) {
      logoImageUrl = logoImageUrl[0].url;
    } else if (data.logoImageUrl) {
      logoImageUrl = data.logoImageUrl;
    }

    if (typoImageUrl && typoImageUrl[0]) {
      typoImageUrl = typoImageUrl[0].url;
    } else if (data.typoImageUrl) {
      typoImageUrl = data.typoImageUrl;
    }

    return (
      <Form.Item label="Preview" {...formItemLayout} style={{ marginBottom: 32 }}>
        <div style={{ height: 400, width: 300, padding: 40, backgroundColor: color }}>
          <div
            style={{
              backgroundImage: `url(${logoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              height: 150,
            }}
          />
          <div
            style={{
              backgroundImage: `url(${typoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: '80%',
              margin: '0 auto',
              marginTop: 20,
              height: 150,
            }}
          />{' '}
        </div>
      </Form.Item>
    );
  }

  render() {
    const { updating, form, id: brandId } = this.props;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} colon={false}>
        <BoostTextForm form={form} name="name" label="Brand name" initial={data.name} require />
        <BoostTextForm
          form={form}
          name="color"
          label="Brand color (#hex)"
          initial={data.color}
          require
          onChange={e => {
            e.target.value = `${e.target.value}`.toLowerCase();
          }}
          rule={{ pattern: /\#[0-9a-f]{6}$/, message: 'Invalid input (#hex)' }}
        />
        <BrandImageUploadForm
          form={form}
          name="logoImageUrl"
          label="Logo Image"
          initial={this.makeImageUrlInitial(data.logoImageUrl)}
          require
        />
        <BrandImageUploadForm
          form={form}
          name="typoImageUrl"
          label="Typo Image"
          initial={this.makeImageUrlInitial(data.typoImageUrl)}
          require
        />

        {this.renderPreview()}

        <Divider style={{ marginBottom: 32, marginLeft: 32, marginRight: 32 }} />

        <Contents brandId={brandId} />

        <Divider style={{ marginBottom: 32, marginLeft: 32, marginRight: 32 }} />

        <div className={styles.formButtonContainer} style={{ textAlign: 'right', margin: '0 0 0 0' }}>
          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit" disabled={updating}>
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default BrandForm;
