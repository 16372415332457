import React, { Fragment } from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import { commify } from 'utils/stringUtil';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { ellipsis } from 'utils/text';

/**
 * Get Status text for Badge Component
 * @param {string} status - Payment Status
 * @return string
 */
function getStatus(status) {
  return {
    waiting: 'default',
    prepared: 'processing',
    approved: 'processing',
    canceled: 'error',
    confirmed: 'success',
    partial_canceled: 'success',
    failed: 'error',
    timeout: 'warning',
    churn: 'warning',
    user_canceled: 'error',
  }[status];
}

/**
 * Get columns with custom options
 * @param {Object} options Options when getting columns.
 * @param {bool} options.renderUserLink Sets true, user renders as LinkText.

 * @param {Function} options.onClickID Callback function that invokes when clicked ID.
 * @param {Function} options.onClickUser Callback function that invokes when clicked User.
 * @return {Array<PaymentEntity>}
 */

export function getColumns(options) {
  const columns = [
    {
      title: (
        <Fragment>
          <span>Idempotency Key</span>
          <br />
          <span>ID</span>
        </Fragment>
      ),
      dataIndex: 'id',
      render(val, others) {
        const { onClickID = () => {} } = options;
        return (
          <Fragment>
            <LinkText onClick={onClickID.bind(null, val)}>{ellipsis(others.idempotencyKey, 25)}</LinkText>
            <br />
            <LinkText onClick={onClickID.bind(null, val)}>{ellipsis(val, 25)}</LinkText>
          </Fragment>
        );
      },
    },
    // {
    //   title: 'Idempotency Key',
    //   dataIndex: 'idempotencyKey',
    //   render(val) {
    //     return <span>{ellipsis(val, 25)}</span>;
    //   }
    // },
    {
      title: 'Description',
      dataIndex: 'description',
      render(val) {
        return <span>{ellipsis(val, 40)}</span>;
      },
    },
    {
      title: (
        <Fragment>
          <span>Checkout</span>
          <br />
          <span>Billing Amt.</span>
        </Fragment>
      ),
      dataIndex: 'checkoutAmount',
      align: 'right',
      render(val, others) {
        return (
          <Fragment>
            <span>{commify(others.checkoutAmount)}</span>
            <br />
            <span>{commify(others.billingAmount)}</span>
          </Fragment>
        );
      },
      // mark to display a total number
      needTotal: false,
    },
    // {
    //   title: 'Billing Amt.',
    //   dataIndex: 'billingAmount',
    //   align: 'right',
    //   render(val) {
    //     return <span>{commify(val)}</span>;
    //   },
    //   // mark to display a total number
    //   needTotal: false
    // },
    {
      key: 'source',
      title: 'Source',
      dataIndex: 'merchant',
      render(merchant) {
        return <span>{merchant ? merchant.displayName : '-'}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      render(val, others) {
        const status = val === 'confirmed' && others.canceledAmount > 0 ? 'partial_canceled' : val;

        return (
          <Fragment>
            <Badge status={getStatus(status)} />
            <span>{startCase(val)}</span>
          </Fragment>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      render(val) {
        return <span>{formatDate(val)}</span>;
      },
    },
  ];

  return columns;
}

export default {
  getColumns,
};
