import gql from 'graphql-tag';

export const getMenuMapRouterQuery = gql`
  query getMenuMapToRouter {
    getMenuMapToRouter
  }
`;

export default {
  getMenuMapRouterQuery,
};
