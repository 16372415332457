import { Alert, Button, Divider, Form } from 'antd';
import autobind from 'autobind-decorator';
import { ChaiDividerForm, ChaiNumberForm, ChaiTextForm } from 'common';
import { formItemLayout, formItemLayoutWithoutLabel } from 'common/formlayout';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addPromotion, updatePromotion } from 'redux/modules/promotion/actions';
import { formatDate } from 'utils/format';
import { graphQueryToState, queryDefault } from 'utils/graphToStateUtil';
import { scrollToTop } from 'utils/scrollUtil';
import { CashbackPolicyType, PromotionBenefitType, UserCashbackIssueType } from '../';
import { promotionQuery } from '../PromotionQueries';
import { listPathName } from './PromotionAirdropContainer';
import PromotionAirdropUsers from './PromotionAirdropUsers';

const mapDispatchToProps = {
  push,
  addPromotion,
  updatePromotion,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class PromotionAirdropItem extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    setRef: PropTypes.func.isRequired,
    targetId: PropTypes.string,
    addPromotion: PropTypes.func.isRequired,
    updatePromotion: PropTypes.func.isRequired,
  };

  static defaultProps = {
    targetId: undefined,
  };

  state = {
    ...queryDefault,
  };

  componentDidMount() {
    this.props.setRef(this);
    this.refresh();
  }

  componentWillUnmount() {
    this.props.setRef(undefined);
  }

  filterForm = null;

  async refresh() {
    const { targetId } = this.props;
    if (targetId === 'add') {
      this.setState({
        data: {},
      });
      this.props.form.resetFields();
      return;
    }
    this.getAirdropItem(targetId);
  }

  async getAirdropItem(targetId) {
    if (!targetId) {
      return;
    }
    try {
      await graphQueryToState({
        onSetState: args => this.setState(args),
        key: 'promotion',
        query: promotionQuery,
        id: targetId,
      });
      scrollToTop();
    } catch (e) {
      console.error(e);
    }
  }

  cancel() {
    this.props.push(listPathName);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields(this.handleSubmit);
  }

  async handleSubmit(error) {
    if (error) {
      return;
    }
    const { form, targetId } = this.props;
    const { data } = this.state;
    const values = form.getFieldsValue();
    const inputs = {
      title: values.title,
      description: values.description,
      status: 'enabled',
      policyType: CashbackPolicyType.USER_CASHBACK,
      benefitType: PromotionBenefitType.CASHBACK,
      issueType: UserCashbackIssueType.AIRDROP,
      discount: `${values.discount}`,
      startAt: data.startAt || new Date(),
      endAt: data.endAt || new Date(Date.now() + 1),
      merchantIds: [],
    };

    if (targetId === 'add') {
      await this.props.addPromotion(inputs);
    } else {
      await this.props.updatePromotion({
        id: targetId,
        ...inputs,
      });
    }

    this.props.push(listPathName);
  }

  render() {
    const { data, error, loading } = this.state;
    const { form, targetId } = this.props;

    if (error) {
      return <Alert type="error" message={error.message} />;
    }

    if (!loading && !data) {
      return <Alert type="error" message="No Data" />;
    }

    return (
      !loading && (
        <Form onSubmit={this.onSubmit}>
          <div style={{ padding: '12px' }} />
          <ChaiTextForm form={form} label="Airdrop ID" name="id" initial={data.id} disabled />
          <ChaiTextForm form={form} label="Created At" name="createdAt" initial={formatDate(data.createdAt)} disabled />
          <ChaiTextForm form={form} label="Name" name="title" initial={data.title} required />
          <ChaiTextForm
            form={form}
            label="Description"
            name="description"
            initial={data.description}
            multiline
            required
          />
          <ChaiNumberForm
            form={form}
            label="Airdrop Amount"
            name="discount"
            initial={Number(data.discount) || 0}
            required
          />
          <ChaiDividerForm label="Airdrop option" />
          <Form.Item label="Airdrop List" {...formItemLayout}>
            <PromotionAirdropUsers targetId={targetId} />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }} {...formItemLayoutWithoutLabel}>
            <Button type="ghost" onClick={this.cancel}>
              Cancel
            </Button>

            <Divider type="vertical" style={{ background: '#fff' }} />

            <Button type="primary" htmlType="submit">
              {data.id ? 'Save' : 'Create'}
            </Button>
          </Form.Item>
        </Form>
      )
    );
  }
}

export default PromotionAirdropItem;
