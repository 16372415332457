import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';

const getStatus = status =>
  ({
    enabled: 'success',
    disabled: 'error',
  }[status]);

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val}</LinkText>;
    },
    width: '10%',
    align: 'center',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    render: function renderName(val) {
      return <span>{val}</span>;
    },
    width: '25%',
  },
  {
    title: '타입',
    dataIndex: 'type',
    width: '10%',
  },
  {
    title: '발급 가능 유저',
    dataIndex: 'issueTarget',
    width: '10%',
  },
  {
    title: '순서',
    dataIndex: 'sortedIndex',
    width: '10%',
  },
  {
    title: '상태',
    dataIndex: 'status',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} />
          <span>{startCase(val)}</span>
        </span>
      );
    },
    width: '10%',
    align: 'center',
  },
];

export default {
  columns,
};
