import gql from 'graphql-tag';

export const monohaBoostPromotionListQuery = gql`
  query monohaBoostPromotionListQuery($filter: String, $skip: Int, $pageSize: Int, $order: String) {
    monohaBoostPromotionList(filter: $filter, skip: $skip, pageSize: $pageSize, order: $order) {
      list {
        id
        title
        subTitle
        visibleFrom
        visibleTo
        boltPrice
        promotion {
          discount
          maxDiscountAmount
        }
        brand {
          name
          color
          logoImageUrl
        }
        status
        type
        amountCap
        countCap
        tagList {
          id
          name
        }
        categoryName
      }
      total
    }
  }
`;

export const boostPromotionQuery = gql`
  query boostPromotion($id: Int!) {
    boostPromotion(id: $id) {
      id
      type
      title
      subTitle
      taglines
      description
      benefitDescription
      tutorialType
      important
      usableLocationDescription
      status
      buyableFrom
      buyableTo
      usableFrom
      usableTo
      visibleFrom
      visibleTo
      boltPrice
      amountCap
      countCap
      countCapPerUser
      landingUrl
      fallbackUrl
      iosAppId
      androidPackage
      targetMerchantIds
      promotionId
      promotion {
        discount
        priceMin
        priceMax
        maxDiscountAmount
      }
      brandId
      usedBoostAmountSum
      promotionType
      discountAmountMax
      discountAmountMin
      subPromotions {
        promotionId
        discount
        maxDiscountAmount
        boltPrice
      }
      boostMerchants {
        id
        merchantId
        boostPromotionId
      }
      boostCategories {
        id
      }
    }
  }
`;

export default {
  boostPromotionQuery,
  monohaBoostPromotionListQuery,
};
