import React, { Component } from 'react';
import { any, objectOf } from 'prop-types';
import { Form, Upload, Button, Icon, message, InputNumber, Input, Alert, Typography, Divider } from 'antd';
import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import autobind from 'autobind-decorator';
import { required } from 'utils/formValidator';
import apolloClient from 'helpers/apolloClient';
import { selectPreApplicationMutation } from './CardMutation';

const { Item } = Form;
const { TextArea } = Input;
const { Title, Text } = Typography;

const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 8,
    md: 6,
  },
  wrapperCol: {
    xs: 26,
    sm: 18,
    md: 16,
  },
};

@Form.create()
@autobind
class PreApplication extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
  };

  state = {
    userFile: null,
    isFailed: false,
    failedUserIds: [],
  };

  input = {
    userIds: null,
  };

  async selectPreApplication(formFields) {
    try {
      const result = await apolloClient.mutate({
        mutation: selectPreApplicationMutation,
        variables: formFields,
      });

      const { success, failedUserIds } = result.data.selectPreApplication;

      if (success) {
        message.success('Success');
      } else {
        message.success('Partially failed');
        this.setState({ isFailed: true, failedUserIds });
      }
    } catch (error) {
      message.error(error.message);
      this.setState({ isFailed: true });
    }
  }

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = [];

      fieldsToCheck.push('userIds');
      fieldsToCheck.push('invitationCount');

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const { form } = this.props;
    const { userFile } = this.state;
    const formFields = form.getFieldsValue();

    if (!userFile && !formFields.userIds) {
      message.warning('Please enter the User IDs(CSV).');
      return;
    }

    if (formFields.userIds) {
      formFields.userIds = formFields.userIds.split(/[\s]*,[\s]*|[\s]+/).filter(Boolean);
    }

    formFields.userIdFile = userFile;

    this.selectPreApplication(formFields);
  }

  cleanup() {
    this.props.form.resetFields();
    this.setState({ userFile: null, isFailed: false, failedUserIds: [] });
  }

  checkFileValid(file) {
    const { type, size } = file;

    if (type !== 'text/csv' && type !== 'text/plain') {
      throw new Error('Only CSV/TEXT file can be uploaded.');
    }

    if (size / 1024 / 1024 > 100) {
      throw new Error('Max file size is 100MB.');
    }
  }

  setUserFile(e) {
    try {
      this.checkFileValid(e.file);

      this.setState({ userFile: e.file });
    } catch (err) {
      message.warning(err.message);
    }
  }

  resetUserFile() {
    this.setState({ userFile: null });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { userFile, isFailed, failedUserIds } = this.state;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Title level={3}>카드 사전신청자 선정</Title>
        <Text strong mark>
          유의 사항
          <br />
        </Text>
        <Text>
          1. 한번에 최대 500명까지 가능합니다.
          <br />
          2. &quot;차이카드 초대장이 도착했습니다&quot; 문자 발송
          <br />
          3. Invitation Count를 넣으면 초대장 지급
          <br />
          4. 사전신청하지 않은 유저는 실패
          <br />
          5. 이미 선정된 유저는 성공 처리 (문자 발송X 초대장 지급 X)
          <br />
          6. 그 외 오류로 실패할 수 있습니다.
        </Text>
        <Divider />
        <Item label="User Ids File" {...formItemLayout}>
          <Upload
            onChange={this.setUserFile}
            onRemove={this.resetUserFile}
            beforeUpload={() => false}
            fileList={userFile && [userFile]}
            accept=".csv,.txt"
          >
            <Button>
              <Icon type="upload" /> Select File
            </Button>
          </Upload>
        </Item>
        <Item label="User Ids" {...formItemLayout}>
          {getFieldDecorator('userIds')(
            <TextArea
              placeholder="User Ids"
              ref={node => (this.input.userIds = node)}
              autoComplete="off"
              style={{ width: 500 }}
            />
          )}
        </Item>
        <Item label="Invitation Count" {...formItemLayout}>
          {getFieldDecorator('invitationCount', { rules: [required()] })(
            <InputNumber placeholder="5" autoComplete="off" min={0} />
          )}
        </Item>
        <div style={{ margin: '30px 0', textAlign: 'center' }}>
          <Button type="ghost" onClick={this.cleanup} style={{ marginRight: '15px' }}>
            Refresh
          </Button>
          <Button type="primary" htmlType="submit">
            Select
          </Button>
        </div>
        <DescriptionList size="large" title="Result" style={{ padding: '30px', display: !isFailed && 'none' }}>
          <Alert message="Failed List" description={`${failedUserIds}`} type="error" showIcon />
        </DescriptionList>
      </Form>
    );
  }
}

export default PreApplication;
