import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

const PageSizeDropdownMenu = ({ onPageSizeChange, pageSizes }) => (
  <Menu onClick={onPageSizeChange}>
    {pageSizes.map(pageSize => (
      <Menu.Item key={pageSize}>{pageSize}</Menu.Item>
    ))}
  </Menu>
);

PageSizeDropdownMenu.propTypes = {
  onPageSizeChange: PropTypes.func.isRequired,
  pageSizes: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default PageSizeDropdownMenu;
