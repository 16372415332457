import gql from 'graphql-tag';

export const userListQuery = gql`
  query userList($id: ID, $sequenceId: String, $fullname: String, $phone: String) {
    userList(id: $id, sequenceId: $sequenceId, fullname: $fullname, phone: $phone) {
      list {
        id
        status
        fullname
        createdAt
        updatedAt
        deletedAt
        birthday
        phone
      }
      total
    }
  }
`;

export const dummyUserListQuery = gql`
  query dummyUserList {
    dummyUserList {
      list {
        id
        status
        fullname
        birthday
        createdAt
      }
      total
    }
  }
`;

export default {
  userListQuery,
  dummyUserListQuery,
};
