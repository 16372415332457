import React, { Component } from 'react';
import { format as formatDate, startOfMonth, endOfMonth } from 'date-fns';
import { bool, func, string } from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Alert, message, Button } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';
import { TabData } from 'containers/Layout';
import { addTab, focusTab } from 'redux/modules/tabs';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { getColumns } from './MonthlyCashbackListMetadata';
import { paymentListQuery } from '../UserQueries';

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
};

@connect(null, mapDispatchToProps)
@autobind
class MonthlyCashbackListModal extends Component {
  static propTypes = {
    userId: string.isRequired,
    visible: bool.isRequired,
    onCloseModal: func.isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    push: func.isRequired,
  };

  state = {
    data: [],
    error: null,
    loading: false,
    page: 1,
    pageSize: 10,
  };

  componentDidMount() {
    const { page, pageSize } = this.state;
    this.getData(page, pageSize);
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   * @param {Number} page - Page to load
   * @param {Number} pageSize - Page size
   */
  async getData(page, pageSize) {
    try {
      this.setState({ loading: true, error: null });

      const { page: oldPage } = this.state;
      const { userId } = this.props;

      // monthly cashback - paymentListQuery 사용
      // period: 이번달, hasCashbackAmount: true
      const startAt = formatDate(startOfMonth(new Date()), 'YYYY-MM-DD HH:mm:ss');
      const endAt = formatDate(endOfMonth(new Date()), 'YYYY-MM-DD HH:mm:ss');

      this.query = cancelableQuery({
        query: paymentListQuery,
        variables: {
          filter: JSON.stringify({
            userId: userId.replace('CS-', ''),
            period: [startAt, endAt],
            userCIByUserId: true,
            hasCashbackAmount: true,
          }),
          skip: (page - 1) * pageSize,
          pageSize,
        },
      });

      const result = await this.query;
      const data = result.data.paymentList.list;

      this.setState({
        loading: false,
        data: [...this.state.data, ...data],
        page: data.length === 0 ? oldPage : page, // Don't update page if there's no data
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Monthly Cashback List: ${error}`);
      this.setState({ error, loading: false });

      throw error;
    }
  }

  getNextPage() {
    const { page, pageSize } = this.state;
    this.getData(page + 1, pageSize);
  }

  renderError() {
    return (
      <div style={{ marginBottom: 10 }}>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <div style={{ height: 20 }} />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  openPaymentTab(id) {
    const tabData = new TabData({
      key: `${id}`,
      title: `Payment (${id.substr(0, 8)}...)`,
      closable: true,
      data: {
        id,
      },
      componentType: 'PaymentDetail',
    });

    this.props.addTab('payment', tabData);
    this.props.focusTab('payment', id.toString());
    this.props.push(`/payment/${id}`);
  }

  openUserTab(id) {
    const tabData = new TabData({
      key: `CS-${id}`,
      title: `CS-${id.substr(0, 5)}...`,
      closable: true,
      data: {
        id,
      },
      componentType: 'UserDetail',
    });

    this.props.addTab('user', tabData);
    this.props.focusTab('user', `CS-${id.toString()}`);
    this.props.push(`/user/CS-${id}`);
  }

  render() {
    const { userId, visible, onCloseModal } = this.props;
    const { data, error, loading } = this.state;

    const columns = getColumns({
      userId: userId.replace('CS-', ''),
      onPaymentIdClick: this.openPaymentTab,
      onUserIdClick: this.openUserTab,
    });

    return (
      <Modal
        title="Monthly Cashback List"
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okText="Close"
        width={800}
      >
        {error && this.renderError()}

        <InfiniteScrollableTable
          id="monthly-cashback-list-table"
          columns={columns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </Modal>
    );
  }
}

export default MonthlyCashbackListModal;
