import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Pagination, Divider } from 'antd';
import autobind from 'autobind-decorator';
import find from 'lodash/find';
import values from 'lodash/values';
import { addTab, focusTab, moveTab } from 'redux/modules/tabs';
import { setPageSize, setCurrentPage, getTransactions } from 'redux/modules/transaction/actions';

import DataViewer from 'components/DataViewer';
import { TabViewer, TabData } from 'containers/Layout';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import csvExport from 'utils/csvExporter';

import { columns } from './TransactionContainerMetadata';
import TransactionDetail from './TransactionDetail';
import TransactionFilterForm from './TransactionFilterForm';

const mapStateToProps = state => {
  const { loading, error, data, currentPage, totalCount, pageSize, filter } = state.transaction;
  const { activeKey, list } = state.tabs.transaction;

  return {
    data,
    error,
    loading,
    currentPage,
    totalCount,
    pageSize,
    filter,
    activeKey,
    list,
  };
};

const mapDispatchToProps = {
  push,
  setPageSize,
  setCurrentPage,
  getTransactions,
  addTab,
  focusTab,
  moveTab,
};

@connect(mapStateToProps, mapDispatchToProps)
@autobind
class TransactionContainer extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    setPageSize: PropTypes.func.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getTransactions: PropTypes.func.isRequired,
    addTab: PropTypes.func.isRequired,
    focusTab: PropTypes.func.isRequired,
    moveTab: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    error: PropTypes.instanceOf(Error),
    loading: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    activeKey: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    error: null,
    filter: null,
  };

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    // Put Tab Data for DataViewer only it doesn't have
    const { list } = this.props;
    let hasListTab = false;

    for (let i = 0; i < list.length; i += 1) {
      if (list[i].key === 'ListTab') {
        hasListTab = true;
        break;
      }
    }

    if (!hasListTab) {
      const listTab = new TabData({
        key: 'ListTab',
        componentType: 'ListTab',
        icon: 'ordered-list',
      });

      this.props.addTab('transaction', listTab);
    }

    this.props.moveTab('transaction', 'ListTab', 0);

    const { pathname } = this.props.location;
    const match = pathname.match(/\/transaction\/(.+)/);

    if (match) {
      const id = match[1];
      const hasTab = find(this.props.list, { key: id });

      if (!hasTab) {
        this.openDataTab(id);
      }

      this.props.focusTab('transaction', id);
    } else {
      this.props.focusTab('transaction', 'ListTab');

      const { currentPage, pageSize, filter } = this.props;
      this.props.getTransactions(currentPage, pageSize, filter);
    }
  }

  shouldComponentUpdate(nextProps) {
    return (
      this.props.currentPage !== nextProps.currentPage ||
      this.props.totalCount !== nextProps.totalCount ||
      this.props.pageSize !== nextProps.pageSize ||
      this.props.filter !== nextProps.filter ||
      this.props.loading !== nextProps.loading ||
      this.props.activeKey !== nextProps.activeKey
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  /* Class Properties */
  unsubscribe = null; // Unsubscribe listener for history change

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const { history } = this.props;
    const { pathname } = history.location;

    if (history.action === 'POP') {
      if (pathname === '/transaction') {
        const { currentPage, pageSize, filter } = this.props;
        this.props.getTransactions(currentPage, pageSize, filter);
        this.props.focusTab('transaction', 'ListTab');
      } else {
        const match = pathname.match(/\/transaction\/(.+)/);

        if (match) {
          const id = match[1];

          // Check tab exists. If user closed tab, make new one
          const hasTab = find(this.props.list, { key: id });

          if (!hasTab) {
            this.openDataTab(id);
          }

          this.props.focusTab('transaction', id);
        }
      }
    } else if (history.action === 'PUSH') {
      if (pathname === '/transaction') {
        this.props.focusTab('transaction', 'ListTab');
      }
    }
  }

  /**
   * Download the Transactions
   */
  download() {
    const { data } = this.props;
    const fields = columns.map(column => column.title);

    if (data.length === 0) {
      return;
    }

    const extractedData = data.map(transaction => values(transaction));
    const { error } = csvExport('Transaction', fields, extractedData);

    if (error) {
      alert(error);
    }
  }

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.props.setPageSize(newPageSize);

    const { currentPage, filter } = this.props;

    this.props.getTransactions(currentPage, newPageSize, filter);
  }

  handleCellClick(record, extra) {
    const { index } = extra;

    if (index === 0) {
      this.openDataTab(record.id, extra);
    }
  }

  /**
   * Opening tab for display Transaction
   * @param {string} id
   * @param {Object} extra - Extra informations. Includes: pressedCmd and pressedCtrl
   */
  openDataTab(id, extra = {}) {
    const tabData = new TabData({
      key: id,
      title: `${id.substr(0, 5)}...`,
      closable: true,
      data: {
        id,
      },
      componentType: 'TransactionDetail',
    });

    const { pressedCmd, pressedCtrl } = extra;

    this.props.addTab('transaction', tabData);

    if (!pressedCmd && !pressedCtrl) {
      this.props.focusTab('transaction', id);
      this.props.push(`/transaction/${id}`);
    }
  }

  changePage(newPage) {
    this.props.setCurrentPage(newPage);

    const { pageSize, filter } = this.props;

    this.props.getTransactions(newPage, pageSize, filter);
  }

  getListTab() {
    const { totalCount, pageSize, currentPage, data, error, loading } = this.props;

    return (
      <Fragment>
        <TransactionFilterForm />
        <Divider />

        <DataViewer columns={columns} data={data} error={error} loading={loading} onCellClick={this.handleCellClick} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 0, textAlign: 'right' }}>
          {/* <Button icon="download" type="primary" onClick={this.download}>
            <span className={styles.onlyVisibleInWidescreen}>Download</span>
          </Button> */}

          <Divider type="vertical" style={{ background: 'none' }} />

          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </Fragment>
    );
  }

  handleTabChange(activeKey) {
    if (activeKey === 'ListTab') {
      const { currentPage, pageSize, filter } = this.props;
      this.props.getTransactions(currentPage, pageSize, filter);
      this.props.push('/transaction');
    } else {
      this.props.push(`/transaction/${activeKey}`);
    }
  }

  render() {
    return (
      <TabViewer
        tabKey="transaction"
        components={{
          ListTab: this.getListTab(),
          TransactionDetail,
        }}
        onTabChanged={this.handleTabChange}
      />
    );
  }
}

export default TransactionContainer;
