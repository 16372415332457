import gql from 'graphql-tag';

export const getOTPKeyQuery = gql`
  query getOTPKey {
    getOTPKey {
      tempKey
    }
  }
`;

export default {
  getOTPKeyQuery,
};
