import gql from 'graphql-tag';

export const cancelPGPaymentMutation = gql`
  mutation cancelPGPayment($id: ID!) {
    cancelPGPayment(id: $id)
  }
`;

export const cancelPaymentMutation = gql`
  mutation cancelPayment($id: ID!, $cancelAmount: Int!, $publicAPIKey: String!) {
    cancelPayment(id: $id, cancelAmount: $cancelAmount, publicAPIKey: $publicAPIKey)
  }
`;

export const updateCashReceiptMutation = gql`
  mutation updateCashReceipt($id: ID!, $isCancel: Boolean, $receiptType: String, $receiptNumber: String) {
    updateCashReceipt(id: $id, isCancel: $isCancel, receiptType: $receiptType, receiptNumber: $receiptNumber)
  }
`;

export const applyBoostReportMutation = gql`
  mutation applyBoostReport($id: ID!, $isBoostReportApprove: Boolean, $rejectReason: String) {
    applyBoostReport(id: $id, isBoostReportApprove: $isBoostReportApprove, rejectReason: $rejectReason)
  }
`;
