import React, { Component } from 'react';
import { bool, func, string } from 'prop-types';
import { Modal } from 'antd';
import autobind from 'autobind-decorator';
import UserPaymentList from '../PaymentList/UserPaymentList';

@autobind
class UserCIPaymentsModal extends Component {
  static propTypes = {
    userId: string.isRequired,
    visible: bool.isRequired,
    onCloseModal: func.isRequired,
  };

  render() {
    const { visible, onCloseModal, userId } = this.props;

    return (
      <Modal
        title="UserCI Payments"
        visible={visible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
        okText="Close"
        width={980}
      >
        {userId && <UserPaymentList userId={userId} userCIByUserId />}
      </Modal>
    );
  }
}

export default UserCIPaymentsModal;
