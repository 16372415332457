import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { Form, Input, Button } from 'antd';
import apolloClient from 'helpers/apolloClient';
import autobind from 'autobind-decorator';
import { formItemLayout, tailFormItemLayout } from './ProfileFormLayout';
import styles from '../Profile.scss';
import { requestPhoneOTPMutation } from '../ProfileMutations';

const { Item } = Form;

@Form.create()
@autobind
class UnRegisterOTPForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    form: objectOf(any).isRequired,
  };

  input = {
    confirmCode: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;
    this.submit(form.getFieldsValue());
  }

  async submit(formData) {
    try {
      await this.props.onSubmit(formData);

      // Reset fields after updated
      this.props.form.setFieldsValue({
        confirmCode: null,
      });
    } catch (err) {
      this.input.confirmCode.focus();
    }
  }

  async requestOTP() {
    try {
      const {
        data: { requestPhoneOTP: confirmCode },
      } = await apolloClient.mutate({ mutation: requestPhoneOTPMutation });

      if (confirmCode) {
        this.props.form.setFieldsValue({ confirmCode });
      }
    } catch (err) {
      throw err;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className={styles.profileForm}>
        <h2>OTP를 이용중입니다.</h2>
        <Item label="OTP Code" {...formItemLayout}>
          {getFieldDecorator('confirmCode')(<Input ref={node => (this.input.confirmCode = node)} type="text" />)}
        </Item>
        <Item label="OTP가 없으신가요?" {...formItemLayout}>
          <Button onClick={this.requestOTP}>문자로 코드 받기</Button>
        </Item>
        <Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            OTP 해지
          </Button>
        </Item>
      </Form>
    );
  }
}

export default UnRegisterOTPForm;
