import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Row, Col, Input } from 'antd';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import { setFilter, resetFilter, getTags } from 'redux/modules/tag/actions';
import { defaultFilter } from 'redux/modules/tag/reducer';

import styles from '../Tag.scss';

const { Item } = Form;

const mapStateToProps = state => {
  const { pageSize, filter } = state.tag;

  return {
    pageSize,
    filter,
  };
};

@Form.create()
@connect(mapStateToProps, {
  setFilter,
  resetFilter,
  getTags,
})
@autobind
class TagFilterForm extends PureComponent {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    resetFilter: PropTypes.func.isRequired,
    getTags: PropTypes.func.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
  };

  static defaultProps = {
    filter: null,
  };

  handleSubmit(ev) {
    ev.preventDefault();

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    const { pageSize } = this.props;

    this.props.setFilter(formFields);
    this.props.getTags(1, pageSize, formFields);
  }

  resetForm() {
    const { pageSize } = this.props;

    this.props.resetFilter();
    this.props.getTags(1, pageSize, {
      ...defaultFilter,
    });

    this.props.form.resetFields();
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const filter = this.props.filter ? this.props.filter : {};
    const { tagName } = filter;

    return (
      <Form className={styles.filterForm} onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          <Col {...col4Props} sm={24} md={24}>
            <Item>
              {getFieldDecorator('tagName', {
                rules: [
                  {
                    type: 'string',
                  },
                ],
                initialValue: tagName,
              })(<Input placeholder="Tag Name" autoComplete="off" style={{ width: '100%' }} />)}
            </Item>
          </Col>
          <Col {...col4Props} xl={3}>
            <Item>
              <SearchButton />
            </Item>
          </Col>

          <Col {...col4Props} xl={3}>
            <Item>
              <Button type="default" icon="undo" onClick={this.resetForm} style={{ width: '100%' }}>
                Reset
              </Button>
            </Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default TagFilterForm;
