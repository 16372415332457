import apolloClient from 'helpers/apolloClient';
import { promotionGroupListQuery } from './queries';
import { promotionGroupAddMutation, promotionGroupUpdateMutation } from './mutations';

export const FETCH_PROMOTION_GROUP = 'promotionGroup/FETCH';
export const FETCH_PROMOTION_GROUP_SUCCESS = 'promotionGroup/FETCH_SUCCESS';
export const FETCH_PROMOTION_GROUP_FAILURE = 'promotionGroup/FETCH_FAILURE';

export const ADD_PROMOTION_GROUP = 'promotionGroup/ADD';
export const ADD_PROMOTION_GROUP_SUCCESS = 'promotionGroup/ADD_SUCCESS';
export const ADD_PROMOTION_GROUP_FAILURE = 'promotionGroup/ADD_FAILURE';

export const UPDATE_PROMOTION_GROUP = 'promotionGroup/UPDATE';
export const UPDATE_PROMOTION_GROUP_SUCCESS = 'promotionGroup/UPDATE_SUCCESS';
export const UPDATE_PROMOTION_GROUP_FAILURE = 'promotionGroup/UPDATE_FAILURE';

export const SET_FILTER = 'promotionGroup/SET_FILTER';
export const RESET_FILTER = 'promotionGroup/RESET_FILTER';

export const SET_CURRENT_PAGE = 'promotionGroup/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'promotionGroup/SET_PAGE_SIZE';

export function getPromotionGroups(currentPage, pageSize, filter) {
  return {
    types: [FETCH_PROMOTION_GROUP, FETCH_PROMOTION_GROUP_SUCCESS, FETCH_PROMOTION_GROUP_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: promotionGroupListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.promotionGroupList;
    },
  };
}

export function addPromotionGroup(promotionGroup) {
  return {
    types: [ADD_PROMOTION_GROUP, ADD_PROMOTION_GROUP_SUCCESS, ADD_PROMOTION_GROUP_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: promotionGroupAddMutation,
        variables: promotionGroup,
      });

      return result.data.promotionAdd;
    },
  };
}

export function updatePromotionGroup(promotionGroup) {
  return {
    types: [UPDATE_PROMOTION_GROUP, UPDATE_PROMOTION_GROUP_SUCCESS, UPDATE_PROMOTION_GROUP_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: promotionGroupUpdateMutation,
        variables: promotionGroup,
      });

      return promotionGroup;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}
