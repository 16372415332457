import React, { Component, Fragment } from 'react';
import { func, string, number, objectOf, any, bool } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import moment from 'moment';
import { Form, Button, Divider, Select, Input, InputNumber, Alert, DatePicker, message, Col, Icon, Table } from 'antd';
import { addPromotionGroup, updatePromotionGroup, getPromotionGroups } from 'redux/modules/promotionGroup/actions';
import { showI18nModal } from 'redux/modules/i18n/actions';
import { focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import I18nModal from 'containers/I18n/Modal/I18nModal';
import { promotionGroupQuery, merchantListQuery } from './PromotionGroupQueries';
import { promotionGroupFormItemLayout as formItemLayout } from './PromotionGroupFormItemLayout';
import PromotionFormModal from './Modal/PromotionFormModal';
import Item from './PromotionGroupFormItem';
import rules from './PromotionGroupFormValidationRules';
import styles from './PromotionGroup.scss';
import { getColumns } from './PromotionMetadata';

const { TextArea } = Input;
const { Option } = Select;

const mapStateToProps = state => {
  const { currentPage, pageSize, filter, updating } = state.promotionGroup;

  return {
    tabList: state.tabs.promotionGroup.list,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  getPromotionGroups,
  addPromotionGroup,
  updatePromotionGroup,
  showI18nModal,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class PromotionGroupForm extends Component {
  static propTypes = {
    push: func.isRequired,
    activeTabKey: string.isRequired,
    currentPage: number.isRequired,
    pageSize: number.isRequired,
    filter: objectOf(any),
    updating: bool.isRequired,
    form: objectOf(any).isRequired,
    focusTab: func.isRequired,
    removeTab: func.isRequired,
    removeAndFocusPreviousTab: func.isRequired,
    getPromotionGroups: func.isRequired,
    addPromotionGroup: func.isRequired,
    updatePromotionGroup: func.isRequired,
    showI18nModal: func.isRequired,
    id: string,
    onSubmit: func,
  };

  static defaultProps = {
    filter: null,
    id: null,
    onSubmit: () => {},
  };

  state = {
    // Promotion Group
    data: null,
    error: null,
    loading: false,
    // Merchant
    merchants: [],
    merchantError: null,
    merchantLoading: false,
    selectedMerchant: '',
    // Promotions
    promotions: {},
    // Others
    promotionModalVisible: false,
    selectedStatus: '',
    validationErrors: [],
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }

    // Load Merchants
    this.getMerchants();
  }

  // On Focus Tab, refresh data
  componentDidUpdate(prevProps) {
    if (prevProps.activeTabKey !== this.props.id && this.props.activeTabKey === this.props.id) {
      this.getData();
      this.getMerchants();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
    if (this.promotionListQuery) {
      this.promotionListQuery.cancel();
    }
    if (this.merchantQuery) {
      this.merchantQuery.cancel();
    }
  }

  input = {
    title: null,
    subTitle: null,
    detailTitle: null,
    description: null,
    sortedIndex: null,
    startAt: null,
    endAt: null,
  };

  query = null;
  promotionListQuery = null;
  merchantQuery = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: promotionGroupQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.promotionGroup;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Promotion Group ID');
      } else {
        const { merchant, status, promotions } = data;
        this.setState({
          loading: false,
          data,
          promotions,
          selectedMerchant: merchant ? merchant.id : '',
          selectedStatus: status,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Promotion Group: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  async getMerchants() {
    this.setState({ merchantLoading: true });

    try {
      this.merchantQuery = cancelableQuery({
        query: merchantListQuery,
        variables: {
          skip: 0,
          pageSize: 9999999,
        },
      });

      const result = await this.merchantQuery;

      this.setState({ merchantLoading: false, merchants: result.data.merchantList.list });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Merchants');
      this.setState({ merchantError: error, merchantLoading: false });
      throw error;
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('promotionGroup', id ? id.toString() : 'add');
    this.props.push('/promotion/group');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    this.setState({
      validationErrors: [],
    });

    const validationErrors = [];

    if (err) {
      const fieldsToCheck = ['title', 'subTitle', 'detailTitle', 'startAt', 'endAt'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    if (!this.state.selectedMerchant) {
      validationErrors.push({
        field: 'merchant',
        error: new Error('Merchant must be selected.'),
      });

      this.setState({ validationErrors });
      return;
    }

    this.submitPromotionGroup();
  }

  submitPromotionGroup() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();

    formFields.id = this.props.id;
    formFields.merchantId = this.state.selectedMerchant;
    formFields.status = this.state.selectedStatus;
    formFields.promotions = this.state.promotions;

    if (formFields.id === 'add') {
      return this.addPromotionGroup(formFields);
    }

    return this.updatePromotionGroup(formFields);
  }

  async addPromotionGroup(promotionGroup) {
    delete promotionGroup.id;
    await this.props.addPromotionGroup(promotionGroup);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('promotionGroup', 'add');
    this.props.push('/promotion/group');
    this.refreshPromotionGroups();
  }

  async updatePromotionGroup(promotionGroup) {
    await this.props.updatePromotionGroup(promotionGroup);

    this.props.onSubmit();
    this.props.removeAndFocusPreviousTab('promotionGroup', promotionGroup.id.toString());
    this.props.push('/promotion/group');
    this.refreshPromotionGroups();
  }

  refreshPromotionGroups() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getPromotionGroups(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  handleSelectMerchant(selectedMerchant) {
    this.setState({ selectedMerchant });
  }

  handleSelectPromotions(promotions) {
    this.setState({ promotions });
  }

  handleSelectStatus(selectedStatus) {
    this.setState({ selectedStatus });
  }

  getValidationError(targetField) {
    const { validationErrors } = this.state;
    const result = validationErrors.filter(val => val.field === targetField);

    return result.length === 0 ? null : result[0].error;
  }

  showPromotionModal() {
    this.setState({ promotionModalVisible: true });
  }

  hidePromotionModal() {
    this.setState({ promotionModalVisible: false });
  }

  showI18nModal({ type, title, columnName, formFieldName, value }) {
    const formFields = this.props.form.getFieldsValue();

    this.props.showI18nModal({
      type: type || 'input',
      title,
      tableName: 'promotion_group',
      targetId: this.props.id,
      columnName,
      currentValue: value || formFields[formFieldName || columnName],
    });
  }

  convertPromotions(promotions) {
    return Object.keys(promotions).map(id => ({
      ...promotions[id],
      id,
      key: id,
      isUsableOnFirstPurchase: promotions[id].isUsableOnFirstPurchase ? 'Y' : 'N',
    }));
  }

  sortPromotions(promotions) {
    const convertedPromotions = this.convertPromotions(promotions);
    return Object.values(convertedPromotions).sort((a, b) => a.sortedIndex - b.sortedIndex);
  }

  addPromotion(promotion) {
    const { promotions } = this.state;
    promotions[promotion.id] = promotion;

    this.setState({ promotions, promotionModalVisible: false });
  }

  deletePromotion(promotionId) {
    const { promotions } = this.state;
    delete promotions[promotionId];

    this.setState({ promotions });
  }

  render() {
    const { updating, form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading, selectedStatus, promotions, promotionModalVisible } = this.state;
    const { merchants, merchantLoading, merchantError, selectedMerchant } = this.state;
    const data = this.state.data || {};
    const { id, title, subTitle, detailTitle, description, landingUrl, sortedIndex, startAt, endAt } = data;

    const sortedPromotions = this.sortPromotions(promotions);
    const promotionColumns = getColumns({
      deletePromotion: this.deletePromotion,
      showI18nModal: this.showI18nModal,
    });

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit} className={styles.promotionGroupForm}>
          <Item label="Title" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('title', {
              initialValue: title,
              rules: rules.title,
            })(
              <Input
                placeholder="Promotion Group Title"
                ref={node => (this.input.title = node)}
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() => this.showI18nModal({ title: 'Title', columnName: 'title' })}
              >
                Translate
              </Button>
            )}
          </Item>

          <Item label="Subtitle" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('subTitle', {
              initialValue: subTitle,
              rules: rules.subTitle,
            })(
              <Input
                placeholder="Promotion Group Subtitle"
                ref={node => (this.input.subTitle = node)}
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() =>
                  this.showI18nModal({ title: 'Subtitle', columnName: 'sub_title', formFieldName: 'subTitle' })
                }
              >
                Translate
              </Button>
            )}
          </Item>

          <Item label="Detail Page Title" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('detailTitle', {
              initialValue: detailTitle,
              rules: rules.detailTitle,
            })(
              <TextArea
                placeholder="Promotion Group detail page title"
                ref={node => (this.input.detailTitle = node)}
                autoComplete="off"
                style={{ width: 500 }}
                autoSize={{
                  minRows: 2,
                  maxRows: 6,
                }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() =>
                  this.showI18nModal({
                    type: 'textarea',
                    title: 'Detail Page Title',
                    columnName: 'detail_title',
                    formFieldName: 'detailTitle',
                  })
                }
              >
                Translate
              </Button>
            )}
          </Item>

          <Item label="Description" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('description', {
              initialValue: description,
              rules: rules.description,
            })(
              <TextArea
                placeholder="Brief description about promotion group"
                ref={node => (this.input.description = node)}
                autoComplete="off"
                style={{ width: 500 }}
                autoSize={{
                  minRows: 2,
                  maxRows: 6,
                }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() =>
                  this.showI18nModal({ type: 'textarea', title: 'Description', columnName: 'description' })
                }
              >
                Translate
              </Button>
            )}
          </Item>

          <Item label="Landing Url" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('landingUrl', {
              initialValue: landingUrl,
            })(
              <Input
                placeholder="Landing URL"
                ref={node => (this.input.landingUrl = node)}
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>

          <Item label="Sorting Order" {...formItemLayout} labelCol={{ span: 6 }}>
            {getFieldDecorator('sortedIndex', {
              initialValue: sortedIndex || 1,
              rules: rules.sortedIndex,
            })(<InputNumber placeholder="Sorting Order" min={1} style={{ width: 150 }} />)}
          </Item>

          <Item label="Period" {...formItemLayout} help="" labelCol={{ span: 6 }} required>
            <Col span={9}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('startAt', {
                  initialValue: startAt && moment(startAt),
                  rules: rules.startAt,
                })(
                  <DatePicker
                    ref={node => (this.input.startAt = node)}
                    style={{ width: '100%' }}
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={1}>
              <span style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>~</span>
            </Col>
            <Col span={9}>
              <Form.Item style={{ marginBottom: 0 }}>
                {getFieldDecorator('endAt', {
                  initialValue: endAt && moment(endAt),
                  rules: rules.endAt,
                })(
                  <DatePicker
                    ref={node => (this.input.endAt = node)}
                    style={{ width: '100%' }}
                    showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                    format="YYYY-MM-DD HH:mm:ss"
                  />
                )}
              </Form.Item>
            </Col>
          </Item>

          <Item
            label="Target Merchant"
            {...formItemLayout}
            style={{ paddingBottom: 0, marginBottom: 0 }}
            help=""
            labelCol={{ span: 6 }}
            required
          >
            {merchantError && (
              <Fragment>
                <Alert message="Oops!" description="Failed to load Merchants." type="warning" showIcon />

                <Divider />

                <Button icon="redo" onClick={this.getMerchants}>
                  Try Again
                </Button>
              </Fragment>
            )}

            {!merchantError && (
              <Fragment>
                <Item {...formItemLayout}>
                  {merchantLoading && <Icon className={styles.loadingSpinner} type="loading" spin />}
                  {getFieldDecorator('merchant', {
                    initialValue: selectedMerchant,
                    rules: rules.merchant,
                  })(
                    <Select placeholder="Select Merchant" onSelect={this.handleSelectMerchant}>
                      {merchants.map(m => (
                        <Option key={m.id} value={m.id}>
                          {m.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Fragment>
            )}
          </Item>

          <Item label="Promotions" {...formItemLayout} style={{ marginBottom: 20 }} labelCol={{ span: 6 }} required>
            <Button type="primary" ghost icon="plus-circle" onClick={this.showPromotionModal}>
              Add
            </Button>
            <Fragment>
              <Table size="middle" dataSource={sortedPromotions} columns={promotionColumns} pagination={false} />
            </Fragment>
          </Item>

          <Item label="Status" {...formItemLayout} style={{ marginBottom: 20 }} labelCol={{ span: 6 }} required>
            <Fragment>
              <Item {...formItemLayout}>
                <Select placeholder="Status" onSelect={this.handleSelectStatus} value={selectedStatus || 'active'}>
                  <Option key="active" value="active">
                    Active
                  </Option>
                  <Option key="inactive" value="inactive">
                    Inactive
                  </Option>
                </Select>
              </Item>
            </Fragment>
          </Item>

          {/* Modals */}
          <PromotionFormModal
            onOk={this.addPromotion}
            onCancel={this.hidePromotionModal}
            visible={promotionModalVisible}
          />

          <I18nModal />

          <div className={styles.formButtonContainer}>
            <Button type="ghost" onClick={this.closeTab}>
              Cancel
            </Button>

            <Divider type="vertical" style={{ background: '#fff' }} />

            <Button type="primary" htmlType="submit" disabled={updating}>
              {id ? 'Save' : 'Create'}
            </Button>
          </div>
        </Form>
      </Fragment>
    );
  }
}

export default PromotionGroupForm;
