import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Table } from 'antd';

import apolloClient from 'helpers/apolloClient';
import { boltDrawPromotionImageListQuery } from 'redux/modules/boltDrawPromotion/queries';
import {
  boltDrawPromotionDeleteImageMutation,
  boltDrawPromotionSwapImagePriorityMutation,
} from 'redux/modules/boltDrawPromotion/mutations';

import SquareImageAddModal from './SquareImageAddModal';
import { getColumns } from './SquareImageListMetadata';
import { BoltDrawPromotionImageType } from './const';

const propTypes = {
  boltDrawPromotionId: PropTypes.number.isRequired,
};

const SquareImages = ({ boltDrawPromotionId }) => {
  const [visibleAdd, setVisibleAdd] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getSquareImageList() {
    setLoading(true);
    const type = BoltDrawPromotionImageType.DETAIL_SQUARE;

    try {
      const result = await apolloClient.query({
        query: boltDrawPromotionImageListQuery,
        variables: { boltDrawPromotionId, type },
      });

      setList(result.data.boltDrawPromotionImageList.list);
    } finally {
      setLoading(false);
    }
  }

  function swapList(index, indexToSwap) {
    setList(array => {
      const data = [...array];
      const temp = data[index];
      data[index] = data[indexToSwap];
      data[indexToSwap] = temp;

      return data;
    });
  }

  async function swapSquareImagePriority(index, indexToSwap) {
    setLoading(true);

    try {
      const imageId = list[index].id;
      const imageIdToSwap = list[indexToSwap].id;

      await apolloClient.mutate({
        mutation: boltDrawPromotionSwapImagePriorityMutation,
        variables: { imageId, imageIdToSwap },
      });

      swapList(index, indexToSwap);
    } finally {
      setLoading(false);
    }
  }

  async function deleteSquareImage(index) {
    setLoading(true);

    try {
      const { id } = list[index];

      await apolloClient.mutate({
        mutation: boltDrawPromotionDeleteImageMutation,
        variables: { id },
      });

      await getSquareImageList();
    } finally {
      setLoading(false);
    }
  }

  async function onAddClose(isAdded) {
    setVisibleAdd(false);

    if (isAdded) {
      await getSquareImageList();
    }
  }

  async function onDownClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);
    const size = list.length;

    if (index >= 0 && index < size - 1) {
      const indexToSwap = index + 1;
      await swapSquareImagePriority(index, indexToSwap);
    }
  }

  async function onUpClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);

    if (index > 0) {
      const indexToSwap = index - 1;
      await swapSquareImagePriority(index, indexToSwap);
    }
  }

  async function onDeleteClicked(row) {
    const { id } = row;
    const index = list.findIndex(element => element.id === id);

    if (index >= 0) {
      await deleteSquareImage(index);
    }
  }

  useEffect(() => {
    if (visibleAdd) return;

    (async () => {
      await getSquareImageList();
    })();
  }, [boltDrawPromotionId, visibleAdd]);

  return (
    <Row style={{ marginLeft: 32, marginRight: 32 }}>
      <h3 style={{ marginBottom: 20 }}>정방형 이미지</h3>

      <Button icon="plus" style={{ position: 'absolute', top: 0, right: 0 }} onClick={() => setVisibleAdd(true)}>
        추가
      </Button>

      <Table
        rowKey={record => record.id}
        columns={getColumns(onDownClicked, onUpClicked, onDeleteClicked)}
        dataSource={list}
        loading={loading}
      />

      <SquareImageAddModal boltDrawPromotionId={boltDrawPromotionId} visibleAdd={visibleAdd} onClose={onAddClose} />
    </Row>
  );
};

SquareImages.propTypes = propTypes;

export default SquareImages;
