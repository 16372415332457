import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import CommonForm from 'schema/CommonForm';

class EditGroupModal extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    handFormSubmit: PropTypes.func.isRequired,
    schema: PropTypes.objectOf(PropTypes.any).isRequired,
    uiSchema: PropTypes.objectOf(PropTypes.any).isRequired,
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
  };
  onCancel = () => {
    this.props.onCancel();
  };
  onOk = () => {
    this.editForm.handleSubmit();
  };
  render() {
    return (
      <Modal
        visible={this.props.visible}
        cancelText="Close"
        okText="Save"
        title={this.props.title}
        onCancel={this.onCancel}
        onOk={this.onOk}
        destroyOnClose
      >
        <CommonForm
          ref={instance => {
            this.editForm = instance;
          }}
          schema={this.props.schema}
          uiSchema={this.props.uiSchema}
          formData={this.props.formData}
          handleSubmit={this.props.handFormSubmit}
        />
      </Modal>
    );
  }
}

export default EditGroupModal;
