import moment from 'moment';
import React, { Component } from 'react';
import { func, string, any, bool, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Radio,
  Upload,
  Button,
  Icon,
  message,
  Alert,
  Divider,
  DatePicker,
  Checkbox,
  Popover,
} from 'antd';
import autobind from 'autobind-decorator';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { showI18nModal } from 'redux/modules/i18n/actions';
import I18nModal from 'containers/I18n/Modal/I18nModal';
import { bannerQuery } from '../BannerQueries';
import Item from './BannerFormItem';
import rules from './BannerFormValidationRules';

const { RangePicker } = DatePicker;
const { Group: RadioGroup } = Radio;

const DateType = {
  ALWAYS: 'always',
  PERIOD: 'period',
};

const mapDispatchToProps = {
  showI18nModal,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class BannerFormModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    id: string,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
    showI18nModal: func.isRequired,
  };

  static defaultProps = {
    id: null,
  };

  state = {
    // XHR Status
    data: null,
    error: null,
    loading: false,
    // Others
    dateType: DateType.ALWAYS,
    // Form
    androidImageFile: null,
    androidImageFileError: null,
    iosImageFile: null,
    iosImageFileError: null,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true && this.props.id) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  input = {
    title: null,
    subTitle: null,
    landingUrl: null,
    sortedIndex: null,
  };

  query = null;

  async getData() {
    const { id } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: bannerQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.banner;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Banner ID');
      } else {
        this.setState({
          loading: false,
          data,
          dateType: data.startAt ? DateType.PERIOD : DateType.ALWAYS,
        });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Banner: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  showI18nModal({ type, title, columnName, formFieldName, value }) {
    const formFields = this.props.form.getFieldsValue();

    this.props.showI18nModal({
      type: type || 'input',
      title,
      tableName: 'banner',
      targetId: this.props.id,
      columnName,
      currentValue: value || formFields[formFieldName || columnName],
    });
  }

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async validateFormFields(err) {
    // Check image files
    this.setState({
      androidImageFileError: null,
      iosImageFileError: null,
    });

    const { androidImageFile, iosImageFile } = this.state;

    // Validate image files only in adding banner
    if (!this.props.id) {
      if (!androidImageFile) {
        this.setState({
          androidImageFileError: new Error('Please select the image.'),
        });
      }

      if (!iosImageFile) {
        this.setState({
          iosImageFileError: new Error('Please select the image.'),
        });
      }
    }

    if (err) {
      const fieldsToCheck = ['title', 'landingUrl', 'sortedIndex'];

      if (this.state.dateType === DateType.PERIOD) {
        fieldsToCheck.push('period');
      }

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    // Don't pass validation if there's any missing file in create mode!
    if (!this.props.id && (!androidImageFile || !iosImageFile)) return;

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    formFields.id = this.props.id;
    formFields.androidImageFile = androidImageFile;
    formFields.iosImageFile = iosImageFile;

    if (formFields.period) {
      const [startAt, endAt] = formFields.period;
      formFields.startAt = startAt;
      formFields.endAt = endAt;

      delete formFields.period;
    }

    delete formFields.dateType;

    await this.props.onOk(formFields);
    this.cleanupBeforeClose();
  }

  /**
   * Set Date State
   */
  setDateType(e) {
    this.setState({ dateType: e.target.value });
  }

  /**
   * Set Image file if it's valid
   * @param {string} stateName Name of the state
   * @param {File} file File object
   */
  setImageFile(stateName, file) {
    try {
      this.checkFileValid(file);

      this.setState({
        [stateName]: file,
        [`${stateName}Error`]: null,
      });
    } catch (err) {
      message.warning(err.message);
    }
  }

  setAndroidImageFile(e) {
    const { file } = e;
    this.setImageFile('androidImageFile', file);
  }

  setIOSImageFile(e) {
    const { file } = e;
    this.setImageFile('iosImageFile', file);
  }

  /**
   * Check specified file is valid for upload
   * @param {File} file
   @ @throws {Error} Throws an error if type is not PNG/JPEG or size exceeds 1000KB.
   */
  checkFileValid(file) {
    const { type, size } = file;

    if (type !== 'image/png' && type !== 'image/jpeg' && type !== 'image/gif' && type !== 'application/json') {
      throw new Error('Only PNG, JPEG, GIF, JSON file can be uploaded.');
    }

    if (size / 1024 > 1000) {
      throw new Error('Max file size is 1000KB.');
    }
  }

  resetAndroidImageFile() {
    this.setState({ androidImageFile: null });
    return false;
  }

  resetIOSImageFile() {
    this.setState({ iosImageFile: null });
    return false;
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();

    this.setState({
      dateType: DateType.ALWAYS,
      data: null,
      androidImageFile: null,
      androidImageFileError: null,
      iosImageFile: null,
      iosImageFileError: null,
    });
  }

  renderImage(url) {
    return (
      <Popover content={<img src={`https://static.chai.finance/${url}`} style={{ maxHeight: 400 }} alt="" />}>
        <img alt="" src={`https://static.chai.finance/${url}`} style={{ maxWidth: 100, maxHeight: 100 }} />
      </Popover>
    );
  }

  renderJsonLottie(url) {
    return (
      <Popover
        content={
          <lottie-player
            autoplay
            loop
            mode="normal"
            src={`https://static.chai.finance/${url}?t=${Date.now()}`}
            style={{ maxWidth: '200px' }}
          />
        }
      >
        <lottie-player
          loop
          mode="normal"
          src={`https://static.chai.finance/${url}?t=${Date.now()}`}
          style={{ maxHeight: 100, maxWidth: 100, display: 'block', margin: '0 auto' }}
        />
      </Popover>
    );
  }

  renderError() {
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { id, visible } = this.props;
    const { error, loading, dateType } = this.state;
    const { androidImageFile, iosImageFile, androidImageFileError, iosImageFileError } = this.state;
    const data = this.state.data || {};
    const { title, subTitle, landingUrl, sortedIndex, startAt, endAt, isActive, androidImageUrl, iosImageUrl } = data;
    const formTitle = id ? 'Update Banner' : 'Create new Banner';

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Modal title={formTitle} onOk={this.handleSubmit} onCancel={this.cleanupBeforeClose} visible={visible}>
        <Form onSubmit={this.handleSubmit}>
          {/* Title */}
          <Item label="Title" style={{ marginBottom: 10 }}>
            {getFieldDecorator('title', {
              initialValue: title,
              rules: rules.title,
            })(
              <Input.TextArea
                ref={node => (this.input.title = node)}
                placeholder="Title"
                rows={2}
                autoComplete="off"
                style={{ width: 220, resize: 'none' }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() => this.showI18nModal({ title: 'Title', columnName: 'title' })}
              >
                Translate
              </Button>
            )}
          </Item>
          {/* Sub Title */}
          <Item label="Sub Title" style={{ marginBottom: 10 }}>
            {getFieldDecorator('subTitle', {
              initialValue: subTitle,
              rules: rules.subTitle,
            })(
              <Input
                ref={node => (this.input.subTitle = node)}
                placeholder="SubTitle"
                autoComplete="off"
                style={{ width: 220 }}
              />
            )}
            {id && (
              <Button
                style={{ marginLeft: 4, verticalAlign: 'middle' }}
                onClick={() => this.showI18nModal({ title: 'Sub Title', columnName: 'sub_title' })}
              >
                Translate
              </Button>
            )}
          </Item>

          {/* Android Image */}
          <Item
            label="Android Image"
            required
            style={{ marginBottom: 0 }}
            help={androidImageFileError && androidImageFileError.message}
            validateStatus={androidImageFileError && 'error'}
          >
            {androidImageUrl && androidImageUrl.includes('.json')
              ? this.renderJsonLottie(androidImageUrl)
              : this.renderImage(androidImageUrl)}
            {id && (
              <>
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() =>
                    this.showI18nModal({
                      type: 'file',
                      title: 'Android Image',
                      columnName: 'android_image_url',
                      value: androidImageUrl,
                    })
                  }
                >
                  Translate
                </Button>
                <br />
              </>
            )}
            <Upload
              onChange={this.setAndroidImageFile}
              onRemove={this.resetAndroidImageFile}
              beforeUpload={() => false}
              fileList={androidImageFile && [androidImageFile]}
              accept=".jpg,.png,.json,.gif"
            >
              <Button>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </Item>

          {/* Second Image */}
          <Item
            label="iOS Image"
            required
            style={{ marginBottom: 0 }}
            help={iosImageFileError && iosImageFileError.message}
            validateStatus={iosImageFileError && 'error'}
          >
            {iosImageUrl && iosImageUrl.includes('.json')
              ? this.renderJsonLottie(iosImageUrl)
              : this.renderImage(iosImageUrl)}
            {id && (
              <>
                <Button
                  style={{ marginLeft: 4 }}
                  onClick={() =>
                    this.showI18nModal({
                      type: 'file',
                      title: 'iOS Image',
                      columnName: 'ios_image_url',
                      value: iosImageUrl,
                    })
                  }
                >
                  Translate
                </Button>
                <br />
              </>
            )}
            <Upload
              onChange={this.setIOSImageFile}
              onRemove={this.resetIOSImageFile}
              beforeUpload={() => false}
              fileList={iosImageFile && [iosImageFile]}
              accept=".jpg,.png,.json,.gif"
            >
              <Button>
                <Icon type="upload" /> Select File
              </Button>
            </Upload>
          </Item>

          {/* Landing URL */}
          <Item label="Landing URL" style={{ marginBottom: 10 }}>
            {getFieldDecorator('landingUrl', {
              initialValue: landingUrl,
              rules: rules.landingUrl,
            })(
              <Input
                ref={node => (this.input.landingUrl = node)}
                placeholder="Landing URL"
                type="url"
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>

          {/* Sorted Index */}
          <Item label="Priority" style={{ marginBottom: 10 }}>
            {getFieldDecorator('sortedIndex', {
              initialValue: sortedIndex,
              rules: rules.sortedIndex,
            })(
              <InputNumber
                ref={node => (this.input.sortedIndex = node)}
                placeholder="Priority"
                min={1}
                style={{ width: 100 }}
              />
            )}
          </Item>

          {/* Is Active */}
          <Item label="Active" style={{ marginBottom: 10 }}>
            {getFieldDecorator('isActive', {
              initialValue: typeof isActive !== 'undefined' ? isActive : true,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </Item>

          {/* Date */}
          <Item label="Date" style={{ marginBottom: 10 }}>
            <RadioGroup value={dateType} onChange={this.setDateType}>
              <Radio value={DateType.ALWAYS}>Always</Radio>
              <Radio value={DateType.PERIOD}>Period</Radio>
            </RadioGroup>
          </Item>

          {/* Period */}
          {dateType === DateType.PERIOD && (
            <Item label=" " style={{ marginBottom: 10 }} colon={false} required={false}>
              {getFieldDecorator('period', {
                initialValue: startAt ? [moment(startAt), moment(endAt)] : [],
                rules: rules.period,
              })(<RangePicker format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} />)}
            </Item>
          )}
        </Form>

        <I18nModal />
      </Modal>
    );
  }
}

export default BannerFormModal;
