export default {
  $id: 'group-search-schema',
  title: 'group-search-schema',
  description: 'group-search-schema.',
  type: 'object',
  required: [],
  properties: {
    name: {
      type: 'string',
      title: 'Group Name',
    },
    code: {
      type: 'string',
      title: 'Group Code',
    },
  },
};
