import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import { Table, Alert } from 'antd';
import styles from './StandardTable.less';

function initTotalList(columns) {
  const totalList = [];

  columns.forEach(column => {
    if (column.needTotal) {
      totalList.push({ ...column, total: 0 });
    }
  });

  return totalList;
}

@autobind
class StandardTable extends PureComponent {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any).isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      list: PropTypes.arrayOf(PropTypes.any),
      pagination: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.bool]),
    }),
    rowKey: PropTypes.string.isRequired,
    onSelectRow: PropTypes.func,
    onChange: PropTypes.func,
    hasAlert: PropTypes.bool,
    onRowClick: PropTypes.func,
    onHeaderRowClick: PropTypes.func,
    selectRows: PropTypes.bool,
    selectedRowHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  };

  static defaultProps = {
    onSelectRow: undefined,
    onChange: undefined,
    hasAlert: true,
    data: {
      list: [],
      pagination: {},
    },
    onRowClick: undefined,
    onHeaderRowClick: undefined,
    selectRows: true,
    selectedRowHandler: undefined,
  };

  constructor(props) {
    super(props);
    const { columns } = props;
    const needTotalList = initTotalList(columns);

    this.state = {
      selectedRowKeys: [],
      needTotalList,
    };
  }

  static getDerivedStateFromProps(nextProps) {
    // clean state
    if (nextProps.selectedRows.length === 0) {
      const needTotalList = initTotalList(nextProps.columns);
      return {
        selectedRowKeys: [],
        needTotalList,
      };
    }
    return null;
  }

  handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    let { needTotalList } = this.state;
    needTotalList = needTotalList.map(item => ({
      ...item,
      total: selectedRows.reduce((sum, val) => sum + parseFloat(val[item.dataIndex], 10), 0),
    }));
    const { onSelectRow } = this.props;
    if (onSelectRow) {
      onSelectRow(selectedRows);
    }

    this.setState({ selectedRowKeys, needTotalList });
  };

  handleTableChange = (pagination, filter, sorter) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(pagination, filter, sorter);
    }
  };

  cleanSelectedKeys = () => {
    this.handleRowSelectChange([], []);
  };

  /**
   * Generate prop for onRow props in <Table />
   */
  generateOnRowProp() {
    const { onRowClick } = this.props;

    if (typeof onRowClick === 'function') {
      return value => ({
        onClick: () => {
          onRowClick(value);
        },
      });
    }

    return undefined;
  }

  /**
   * Generate prop for onHeaderRow props in <Table />
   */
  generateOnHeaderRowProp() {
    const { onHeaderRowClick } = this.props;

    if (typeof onHeaderRowClick === 'function') {
      return value => ({
        onClick: () => {
          onHeaderRowClick(value);
        },
      });
    }

    return undefined;
  }

  /**
   * Handler Component to handle selected rows
   */
  selectedRowHandler() {
    const { selectedRowKeys, needTotalList } = this.state;
    const { selectedRowHandler } = this.props;

    if (typeof selectedRowHandler !== 'undefined') {
      return selectedRowHandler(selectedRowKeys, needTotalList, this.cleanSelectedKeys);
    }

    return (
      <Fragment>
        Selected <a style={{ fontWeight: 600 }}>{selectedRowKeys.length}</a> Item(s)&nbsp;&nbsp;
        {needTotalList.map(item => (
          <span style={{ marginLeft: 8 }} key={item.dataIndex}>
            {item.title}
            Total&nbsp;
            <span style={{ fontWeight: 600 }}>{item.render ? item.render(item.total) : item.total}</span>
          </span>
        ))}
        <a onClick={this.cleanSelectedKeys} style={{ marginLeft: 24 }}>
          Clear
        </a>
      </Fragment>
    );
  }

  render() {
    const { selectRows } = this.props;
    const { selectedRowKeys } = this.state;
    const {
      data: { list, pagination },
      columns,
      loading,
      rowKey,
      hasAlert,
    } = this.props;

    // If Pagination is empty or false, remove from the table
    let paginationProps;

    if (pagination) {
      paginationProps = {
        showSizeChanger: false,
        showQuickJumper: false,
        ...pagination,
      };
    } else {
      paginationProps = false;
    }

    let rowSelection;

    if (selectRows === true) {
      rowSelection = {
        selectedRowKeys,
        onChange: this.handleRowSelectChange,
        getCheckboxProps: record => ({
          disabled: record.disabled,
        }),
      };
    }

    const onRowProps = this.generateOnRowProp();
    const onHeaderRowProps = this.generateOnHeaderRowProp();

    return (
      <div className={styles.standardTable}>
        <div className={styles.tableAlert}>
          {selectRows && hasAlert && <Alert message={this.selectedRowHandler()} type="info" showIcon />}
        </div>

        <Table
          loading={loading}
          rowKey={rowKey || 'key'}
          rowSelection={rowSelection}
          dataSource={list}
          columns={columns}
          pagination={paginationProps}
          onChange={this.handleTableChange}
          onRow={onRowProps}
          onHeaderRow={onHeaderRowProps}
          scroll={{ x: '100%' }}
        />
      </div>
    );
  }
}

export default StandardTable;
