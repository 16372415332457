import { Card } from 'antd';
import autobind from 'autobind-decorator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { formatDate } from 'utils/format';
import invertColor from 'utils/invertColor';
import { Link } from 'react-router-dom';
import { commify } from 'utils/stringUtil';

const BoostTypeName = {
  standard: '일반',
  fcfs: '선착순',
  time_attack: '타임어택',
  recurring: '정기결제',
};

@autobind
class BoostPreviewItem extends Component {
  static propTypes = {
    item: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  render() {
    const {
      id,
      brand,
      title,
      subTitle,
      boltPrice,
      visibleFrom,
      visibleTo,
      type,
      tagList,
      promotion,
      status,
    } = this.props.item;

    return (
      <Link to={`/boost/policy/${id}`}>
        <Card
          style={{
            width: '200px',
            height: '280px',
            display: 'inline-block',
            marginRight: '10px',
            backgroundColor: brand.color,
            borderRadius: '25px',
            position: 'relative',
            cursor: 'pointer',
            opacity: status === 'enabled' ? 1 : 0.4,
          }}
        >
          <div
            style={{
              height: '35px',
              backgroundImage: `url(${brand.logoImageUrl})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              marginBottom: '8px',
              textAlign: 'right',
            }}
          >
            {' '}
            Tags: <span style={{ color: invertColor(brand.color) }}>{tagList.map(tag => tag.name).join(',')}</span>
          </div>
          <div
            style={{
              fontSize: '18px',
              lineHeight: 1.1,
              color: 'white',
              position: 'absolute',
            }}
          >
            <p>{subTitle}</p>
            <p>- {title}</p>
            <p>{BoostTypeName[type]}</p>
          </div>
          <div
            style={{
              fontSize: '14px',
              color: 'white',
              position: 'absolute',
              bottom: '60px',
            }}
          >
            {formatDate(visibleFrom, 'YYYY-MM-DD HH:mm')} ~<br />
            {formatDate(visibleTo, 'YYYY-MM-DD HH:mm')}
          </div>
          <div
            style={{
              fontSize: '16px',
              color: 'white',
              position: 'absolute',
              bottom: '20px',
            }}
          >
            <span role="img" aria-label={`볼트${boltPrice}`}>
              ⚡{boltPrice} (⚡ ≒ {commify(Math.floor(promotion.maxDiscountAmount / boltPrice))})
            </span>
          </div>
        </Card>
      </Link>
    );
  }
}

export default BoostPreviewItem;
