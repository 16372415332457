import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form, Button, Row, Col, DatePicker } from 'antd';
import { rowProps, col4Props } from 'ui/ResponsiveProps';
import SearchButton from 'components/Forms/SearchButton';
import styles from './BoostCategory.scss';

const { Item } = Form;

const BoostCategoryBoostFilterForm = ({ setFilter, resetFilter, form }) => {
  const { getFieldDecorator } = form;

  const handleSubmit = ev => {
    ev.preventDefault();

    const formFields = form.getFieldsValue();
    setFilter(formFields);
  };

  const handleReset = () => {
    form.resetFields();
    resetFilter();
  };

  return (
    <Form className={styles.filterForm} onSubmit={handleSubmit}>
      <Row {...rowProps}>
        <Col {...col4Props}>
          <Item label="VisibleFrom">
            {getFieldDecorator('visibleFrom')(
              <DatePicker
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '100%' }}
              />
            )}
          </Item>
        </Col>
        <Col {...col4Props}>
          <Item label="VisibleTo">
            {getFieldDecorator('visibleTo')(
              <DatePicker
                showTime={{ defaultValue: moment('23:59:59', 'HH:mm:ss') }}
                format="YYYY-MM-DD HH:mm:ss"
                style={{ width: '100%' }}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...col4Props}>
          <Item>
            <SearchButton />
          </Item>
        </Col>
        <Col {...col4Props}>
          <Item>
            <Button type="default" icon="undo" onClick={handleReset} style={{ width: '100%' }}>
              Reset
            </Button>
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

BoostCategoryBoostFilterForm.propTypes = {
  setFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Form.create()(BoostCategoryBoostFilterForm);
