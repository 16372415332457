export const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 8,
    md: 6,
  },
  wrapperCol: {
    xs: 24,
    sm: 16,
    md: 14,
    lg: 12,
    xl: 12,
    xxl: 14,
  },
};

export const formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: 24,
    sm: { offset: 8, span: 16 },
    md: { offset: 6, span: 14 },
    lg: { offset: 6, span: 12 },
    xl: { offset: 6, span: 12 },
    xxl: { offset: 6, span: 14 },
  },
};

export const formItemDividerLayout = {
  ...formItemLayoutWithoutLabel,
  style: {
    margin: '50px 0 0 0',
  },
};

export default {
  formItemLayout,
  formItemLayoutWithoutLabel,
  formItemDividerLayout,
};
