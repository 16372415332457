import apolloClient from 'helpers/apolloClient';
import { bannerGroupListQuery } from './queries';
import { bannerGroupAddMutation, bannerGroupUpdateMutation, bannerGroupRemoveMutation } from './mutations';

export const FETCH_BANNER_GROUP = 'bannerGroup/FETCH';
export const FETCH_BANNER_GROUP_SUCCESS = 'bannerGroup/FETCH_SUCCESS';
export const FETCH_BANNER_GROUP_FAILURE = 'bannerGroup/FETCH_FAILURE';

export const ADD_BANNER_GROUP = 'bannerGroup/ADD';
export const ADD_BANNER_GROUP_SUCCESS = 'bannerGroup/ADD_SUCCESS';
export const ADD_BANNER_GROUP_FAILURE = 'bannerGroup/ADD_FAILURE';

export const UPDATE_BANNER_GROUP = 'bannerGroup/UPDATE';
export const UPDATE_BANNER_GROUP_SUCCESS = 'bannerGroup/UPDATE_SUCCESS';
export const UPDATE_BANNER_GROUP_FAILURE = 'bannerGroup/UPDATE_FAILURE';

export const REMOVE_BANNER_GROUP = 'bannerGroup/REMOVE';
export const REMOVE_BANNER_GROUP_SUCCESS = 'bannerGroup/REMOVE_SUCCESS';
export const REMOVE_BANNER_GROUP_FAILURE = 'bannerGroup/REMOVE_FAILURE';

export function getBannerGroups() {
  return {
    types: [FETCH_BANNER_GROUP, FETCH_BANNER_GROUP_SUCCESS, FETCH_BANNER_GROUP_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: bannerGroupListQuery,
      });

      return result.data.bannerGroupList;
    },
  };
}

export function addBannerGroup(bannerGroup) {
  return {
    types: [ADD_BANNER_GROUP, ADD_BANNER_GROUP_SUCCESS, ADD_BANNER_GROUP_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: bannerGroupAddMutation,
        variables: bannerGroup,
      });

      return result.data.bannerGroupAdd;
    },
  };
}

export function updateBannerGroup(bannerGroup) {
  return {
    types: [UPDATE_BANNER_GROUP, UPDATE_BANNER_GROUP_SUCCESS, UPDATE_BANNER_GROUP_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: bannerGroupUpdateMutation,
        variables: bannerGroup,
      });

      return bannerGroup;
    },
  };
}

export function removeBannerGroup(bannerGroup) {
  return {
    types: [REMOVE_BANNER_GROUP, REMOVE_BANNER_GROUP_SUCCESS, REMOVE_BANNER_GROUP_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: bannerGroupRemoveMutation,
        variables: bannerGroup,
      });

      return bannerGroup;
    },
  };
}
