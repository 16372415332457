import React from 'react';
import { arrayOf, bool, any } from 'prop-types';
import { Table } from 'antd';
import { getColumns } from './TransactionLogMetadata';

const TransactionLog = ({ dataSource, renderOwner }) => (
  <Table
    style={{ marginBottom: 24 }}
    pagination={false}
    loading={false}
    dataSource={dataSource}
    columns={getColumns({ renderOwner })}
    rowKey="id"
    scroll={{ x: true }}
  />
);

TransactionLog.propTypes = {
  dataSource: arrayOf(any),
  renderOwner: bool,
};

TransactionLog.defaultProps = {
  dataSource: [],
  renderOwner: false,
};

export default TransactionLog;
