import gql from 'graphql-tag';

export const promotionGroupListQuery = gql`
  query promotionGroupList($filter: String, $skip: Int, $pageSize: Int) {
    promotionGroupList(filter: $filter, skip: $skip, pageSize: $pageSize) {
      list {
        id
        title
        startAt
        endAt
        sortedIndex
        status
        merchant {
          id
          name
        }
      }
      total
    }
  }
`;

export default {
  promotionGroupListQuery,
};
