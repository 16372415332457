import React from 'react';
import { func } from 'prop-types';
import { Menu, Icon } from 'antd';
import styles from './ChaiHeader.less';

const { Item } = Menu;

const DropdownOverlay = ({ onMenuClick }) => (
  <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
    <Item key="edit-profile">
      <Icon type="setting" />
      <span>Profile</span>
    </Item>
    <Item key="logout">
      <Icon type="logout" />
      <span>Logout</span>
    </Item>
  </Menu>
);
DropdownOverlay.propTypes = {
  onMenuClick: func.isRequired,
};

export default DropdownOverlay;
