import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const getStatus = status =>
  ({
    active: 'success',
    ready: 'default',
    expired: 'error',
  }[status]);

export function getColumns() {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: function renderID(val) {
        return <LinkText>{val}</LinkText>;
      },
      width: '10%',
      align: 'center',
    },
    {
      title: 'Boost Policy Name',
      render: function renderPeriod(others) {
        const { title, subTitle } = others;

        return <span>{`${subTitle} (${title})`}</span>;
      },
      width: '20%',
    },
    {
      title: 'Category Name',
      render: function renderPeriod(others) {
        const { categoryName } = others;

        return <span>{`${categoryName}`}</span>;
      },
      width: '20%',
    },
    {
      title: 'Discount',
      dataIndex: 'promotion',
      render: function renderName(promotion) {
        return <span>{commify(promotion.discount)}</span>;
      },
      width: '20%',
    },
    {
      title: 'Visible',
      render: function renderPeriod(others) {
        const { visibleFrom, visibleTo } = others;

        return (
          <span>
            {formatDate(visibleFrom, 'YYYY-MM-DD')} ~ {visibleTo && formatDate(visibleTo, 'YYYY-MM-DD')}
          </span>
        );
      },
      width: '30%',
    },
    // {
    //   title: 'Taget Brand',
    //   dataIndex: 'brand',
    //   render: function renderPeriod(brand) {
    //     console.log(brand);
    //     return <span>{brand.name}</span>;
    //   },
    //   width: '20%',
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      render: function renderStatus(val) {
        return (
          <span>
            <Badge status={getStatus(val)} />
            <span>{startCase(val)}</span>
          </span>
        );
      },
      width: '15%',
      align: 'center',
    },
  ];
  return columns;
}

export default {
  getColumns,
};
