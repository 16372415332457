import React, { Component } from 'react';
import { func, string, any, bool, objectOf } from 'prop-types';
import { Modal, Form, Input, InputNumber, Checkbox } from 'antd';
import uuidv4 from 'uuid/v4';
import autobind from 'autobind-decorator';
import rules from './PromotionFormValidationRules';
import Item from '../PromotionGroupFormItem';

@Form.create()
@autobind
class PromotionFormModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    id: string,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  static defaultProps = {
    id: null,
  };

  state = {
    data: null,
  };

  input = {
    mainText: null,
    subText: null,
    sortedIndex: null,
  };

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }

    const { form } = this.props;
    form.validateFields(this.validateFormFields);
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['mainText', 'subText', 'sortedIndex'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();
    formFields.id = this.props.id || uuidv4();

    if (formFields.period) {
      const [startAt, endAt] = formFields.period;
      formFields.startAt = startAt;
      formFields.endAt = endAt;

      delete formFields.period;
    }

    delete formFields.dateType;

    await this.props.onOk(formFields);
    this.cleanupBeforeClose();
  }

  /**
   * Check specified file is valid for upload
   * @param {File} file
   @ @throws {Error} Throws an error if type is not PNG/JPEG or size exceeds 500KB.
   */
  checkFileValid(file) {
    const { type, size } = file;

    if (type !== 'image/png' && type !== 'image/jpeg') {
      throw new Error('Only PNG/JPEG file can be uploaded.');
    }

    if (size / 1024 > 500) {
      throw new Error('Max file size is 500KB.');
    }
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();

    this.setState({
      data: null,
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { id, visible } = this.props;
    const data = this.state.data || {};
    const { mainText, subText, sortedIndex, isUsableOnFirstPurchase } = data;
    const formTitle = id ? 'Update Promotion' : 'Create new Promotion';

    return (
      <Modal title={formTitle} onOk={this.handleSubmit} onCancel={this.cleanupBeforeClose} visible={visible}>
        <Form onSubmit={this.handleSubmit}>
          {/* Main Text */}
          <Item label="Main Text" style={{ marginBottom: 10 }}>
            {getFieldDecorator('mainText', {
              initialValue: mainText,
              rules: rules.mainText,
            })(
              <Input
                ref={node => (this.input.mainText = node)}
                placeholder="Main Text"
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>

          {/* Sub Text */}
          <Item label="Sub Text" style={{ marginBottom: 10 }}>
            {getFieldDecorator('subText', {
              initialValue: subText,
              rules: rules.subText,
            })(
              <Input
                ref={node => (this.input.subText = node)}
                placeholder="Sub Text"
                autoComplete="off"
                style={{ width: 300 }}
              />
            )}
          </Item>

          {/* Sorted Index */}
          <Item label="Priority" style={{ marginBottom: 10 }}>
            {getFieldDecorator('sortedIndex', {
              initialValue: sortedIndex,
              rules: rules.sortedIndex,
            })(
              <InputNumber
                ref={node => (this.input.sortedIndex = node)}
                placeholder="Priority"
                min={1}
                style={{ width: 100 }}
              />
            )}
          </Item>

          {/* Is First Purchase */}
          <Item label="First Purchase" style={{ marginBottom: 10 }}>
            {getFieldDecorator('isUsableOnFirstPurchase', {
              initialValue: !!isUsableOnFirstPurchase,
              valuePropName: 'checked',
            })(<Checkbox />)}
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default PromotionFormModal;
