import apolloClient from 'helpers/apolloClient';
import { promotionListQuery } from './queries';
import { promotionAddMutation, promotionUpdateMutation } from './mutations';

export const FETCH_PROMOTION = 'promotion/FETCH';
export const FETCH_PROMOTION_SUCCESS = 'promotion/FETCH_SUCCESS';
export const FETCH_PROMOTION_FAILURE = 'promotion/FETCH_FAILURE';

export const ADD_PROMOTION = 'promotion/ADD';
export const ADD_PROMOTION_SUCCESS = 'promotion/ADD_SUCCESS';
export const ADD_PROMOTION_FAILURE = 'promotion/ADD_FAILURE';

export const UPDATE_PROMOTION = 'promotion/UPDATE';
export const UPDATE_PROMOTION_SUCCESS = 'promotion/UPDATE_SUCCESS';
export const UPDATE_PROMOTION_FAILURE = 'promotion/UPDATE_FAILURE';

export const SET_FILTER = 'promotion/SET_FILTER';
export const RESET_FILTER = 'promotion/RESET_FILTER';

export const SET_CURRENT_PAGE = 'promotion/SET_CURRENT_PAGE';
export const SET_PAGE_SIZE = 'promotion/SET_PAGE_SIZE';

export const TOGGLE_COPY_ITEM = 'promotion/TOGGLE_COPY_ITEM';
export const CLEAR_COPY_ITEM = 'promotion/CLEAR_COPY_ITEM';

export function getPromotions(currentPage, pageSize, filter) {
  return {
    types: [FETCH_PROMOTION, FETCH_PROMOTION_SUCCESS, FETCH_PROMOTION_FAILURE],
    promise: async () => {
      const skip = (currentPage - 1) * pageSize;

      const result = await apolloClient.query({
        query: promotionListQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
          skip,
          pageSize,
        },
      });

      return result.data.promotionList;
    },
  };
}

export function addPromotion(promotion) {
  return {
    types: [ADD_PROMOTION, ADD_PROMOTION_SUCCESS, ADD_PROMOTION_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: promotionAddMutation,
        variables: promotion,
      });

      return result.data.promotionAdd;
    },
  };
}

export function updatePromotion(promotion) {
  return {
    types: [UPDATE_PROMOTION, UPDATE_PROMOTION_SUCCESS, UPDATE_PROMOTION_FAILURE],
    promise: async () => {
      await apolloClient.mutate({
        mutation: promotionUpdateMutation,
        variables: promotion,
      });

      return promotion;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  };
}

export function setCurrentPage(page) {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
}

export function setPageSize(pageSize) {
  return {
    type: SET_PAGE_SIZE,
    payload: pageSize,
  };
}

export function toggleCopyItem(row) {
  return {
    type: TOGGLE_COPY_ITEM,
    payload: row,
  };
}

export function clearCopyItems() {
  return {
    type: CLEAR_COPY_ITEM,
  };
}
