import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import isEqual from 'lodash/isEqual';
import cx from 'classnames';
import { removeAlert, alertShape } from 'redux/modules/alert';
import AlertItem from './AlertItem';

const styles = require('./AlertContainer.scss');

@connect(
  (state, props) => ({
    alert: state.alert.filter(item => item.parent === props.name),
  }),
  { removeAlert }
)
class AlertContainer extends Component {
  static propTypes = {
    alert: alertShape,
    inline: PropTypes.bool,
    name: PropTypes.string,
    removeAlert: PropTypes.func.isRequired,
  };

  static defaultProps = {
    alert: [],
    inline: false,
    name: '',
  };

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.alert, nextProps.alert);
  }

  @autobind
  handleClose(key) {
    this.props.removeAlert(key);
  }

  render() {
    const classNames = {
      enter: styles.animate_enter,
      enterActive: styles.animate_enter_active,
      exit: styles.animate_leave,
      exitActive: styles.animate_leave_active,
    };

    return (
      <div className={cx(styles.container, { [styles.container_inline]: this.props.inline })}>
        <TransitionGroup>
          {this.props.alert.map(item => (
            <CSSTransition key={item.key} classNames={classNames} timeout={300}>
              <AlertItem {...item} id={item.key} onClose={this.handleClose} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

export default AlertContainer;
