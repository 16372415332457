import DescriptionList from 'ant-design-pro/lib/DescriptionList';
import Text from 'antd/lib/typography/Text';

import React, { useEffect, useState } from 'react';
import { func, bool, objectOf, any } from 'prop-types';
import { Modal, Form, Radio, Badge } from 'antd';
import CopyableText from '../../../../components/CopyableText';

import styles from '../../../Merchant/Merchant.scss';
import apolloClient from '../../../../helpers/apolloClient';
import { brandChangeContentVisible } from '../../../../redux/modules/brand/mutations';

const propTypes = {
  onClose: func.isRequired,
  visibleEdit: bool.isRequired,
  clickedRow: objectOf(any),
};

const defaultProps = {
  clickedRow: undefined,
};

const ContentEditModal = ({ onClose, visibleEdit, clickedRow = undefined }) => {
  const [isEditted, setIsEditted] = useState(false);
  const [visible, setVisible] = useState('visible');
  const [contentId, setContentId] = useState(0);
  const [sourceFileName, setSourceFileName] = useState('');
  const [bucketFileName, setBucketFileName] = useState('');
  const [mp4Url, setMP4Url] = useState('-');
  const [dashUrl, setDashUrl] = useState('-');
  const [hlsUrl, setHLSUrl] = useState('-');
  const [imageUrl, setImageUrl] = useState('-');
  const [errorMessage, setErrorMessage] = useState('-');
  const [status, setStatus] = useState('ingest');
  const [contentType, setContentType] = useState('image');

  useEffect(() => {
    if (clickedRow) {
      setContentId(clickedRow.id);
      setVisible(clickedRow.visible);
      setSourceFileName(clickedRow.sourceFileName);
      // noinspection JSUnresolvedVariable
      setBucketFileName(clickedRow.bucketFileName);
      setStatus(clickedRow.status);
      setErrorMessage(clickedRow.errorMessage ? clickedRow.errorMessage : '-');
      setContentType(clickedRow.type === 'image' ? '이미지' : '동영상');

      clickedRow.urls.forEach(element => {
        const { type: urlType, url } = element;
        if (urlType === 'image') {
          setImageUrl(url);
        } else if (urlType === 'mp4') {
          setMP4Url(url);
        } else if (urlType === 'dash') {
          setDashUrl(url);
        } else if (urlType === 'hls') {
          setHLSUrl(url);
        }
      });
    }
  }, [clickedRow]);

  function getStatus() {
    return {
      publish: 'success',
      ingest: 'default',
      error: 'error',
      canceled: 'error',
      deleted: 'error',
    }[status];
  }

  function getStatusMessage() {
    if (status === 'publish') {
      return '처리 완료';
    } else if (status === 'canceled') {
      return '취소됨';
    } else if (status === 'error') {
      return '오류';
    } else if (status === 'deleted') {
      return '삭제됨';
    }

    return '처리중';
  }

  async function onVisibleChange(ev) {
    if (ev) ev.preventDefault();

    const changedVisible = ev.target.value;

    try {
      await apolloClient.mutate({
        mutation: brandChangeContentVisible,
        variables: {
          contentId,
          visible: changedVisible,
        },
      });
    } finally {
      setVisible(changedVisible);
      setIsEditted(true);
    }
  }

  function setDefaultState() {
    setVisible('visible');
    setContentId(0);
    setSourceFileName('');
    setBucketFileName('');
    setMP4Url('-');
    setDashUrl('-');
    setHLSUrl('-');
    setImageUrl('-');
    setErrorMessage('-');
    setStatus('ingest');
    setContentType('image');
  }

  function onCloseClicked(ev) {
    if (ev) {
      ev.preventDefault();
    }

    setDefaultState();

    onClose(isEditted);
  }

  return (
    <Modal
      title="브랜드 미디어 수정"
      onOk={onCloseClicked}
      onCancel={onCloseClicked}
      visible={visibleEdit}
      maskClosable={false}
      destroyOnClose
    >
      <DescriptionList className={styles.leftAlignedDescriptionList} size="large">
        <DescriptionList.Description term="ID" style={{ width: '100%' }}>
          <Text>{contentId}</Text>
        </DescriptionList.Description>
        <DescriptionList.Description term="타입" style={{ width: '100%' }}>
          <Text>{contentType}</Text>
        </DescriptionList.Description>
        <DescriptionList.Description term="공개여부" style={{ width: '100%' }}>
          <Radio.Group value={visible} onChange={onVisibleChange}>
            <Radio.Button value="visible">공개</Radio.Button>
            <Radio.Button value="invisible">숨김</Radio.Button>
          </Radio.Group>
        </DescriptionList.Description>
        <DescriptionList.Description term="원본 파일 이름" style={{ width: '100%' }}>
          <Text>{sourceFileName}</Text>
        </DescriptionList.Description>
        <DescriptionList.Description term="저장된 파일 이름" style={{ width: '100%' }}>
          <Text>{bucketFileName}</Text>
        </DescriptionList.Description>
        <DescriptionList.Description term="상태" style={{ width: '100%' }}>
          <span>
            <Badge status={getStatus()} />
            <span>{getStatusMessage()}</span>
          </span>
        </DescriptionList.Description>
        <DescriptionList.Description term="MP4" style={{ width: '100%' }}>
          <CopyableText value={mp4Url} tooltip="URL 복사">
            {mp4Url === '-' ? mp4Url : '복사하려면 클릭'}
          </CopyableText>
        </DescriptionList.Description>
        <DescriptionList.Description term="Dash" style={{ width: '100%' }}>
          <CopyableText value={dashUrl} tooltip="URL 복사">
            {dashUrl === '-' ? dashUrl : '복사하려면 클릭'}
          </CopyableText>
        </DescriptionList.Description>
        <DescriptionList.Description term="HLS" style={{ width: '100%' }}>
          <CopyableText value={hlsUrl} tooltip="URL 복사">
            {hlsUrl === '-' ? hlsUrl : '복사하려면 클릭'}
          </CopyableText>
        </DescriptionList.Description>

        <DescriptionList.Description term="Image" style={{ width: '100%' }}>
          <CopyableText value={imageUrl} tooltip="URL 복사">
            {imageUrl === '-' ? imageUrl : '복사하려면 클릭'}
          </CopyableText>
        </DescriptionList.Description>

        <DescriptionList.Description term="에러 메세지" style={{ width: '100%' }}>
          <CopyableText value={errorMessage} tooltip="에러메세지 복사">
            {errorMessage}
          </CopyableText>
        </DescriptionList.Description>
      </DescriptionList>
    </Modal>
  );
};

ContentEditModal.propTypes = propTypes;
ContentEditModal.defaultProps = defaultProps;

export default Form.create()(ContentEditModal);
