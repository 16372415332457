export default {
  $id: 'userRole-search-schema',
  title: 'userRole-search-schema',
  description: 'userRole-search-schema.',
  type: 'object',
  required: [],
  properties: {
    loginId: {
      type: 'string',
      title: 'Login ID',
    },
    fullname: {
      type: 'string',
      title: 'User name',
    },
    phone: {
      type: 'string',
      title: 'User phone',
    },
    merchantName: {
      type: 'string',
      title: 'Merchant Name',
    },
  },
};
