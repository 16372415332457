const rules = {
  title: [
    {
      required: true,
      message: 'Please enter the name.',
    },
  ],
  landingUrl: [
    {
      required: true,
      message: 'Please enter the landing url.',
    },
    {
      type: 'url',
      message: 'Landing URL must be valid URL format.',
    },
  ],
  sortedIndex: [
    {
      required: true,
      message: 'Please enter the priority.',
    },
  ],
  period: [
    {
      required: true,
      message: 'Please select the period.',
    },
  ],
};

export default rules;
