import React, { Component } from 'react';
import { bool, func, any, objectOf, string } from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';
import autobind from 'autobind-decorator';
import { TagPromotionType } from '../';
import styles from '../Tag.scss';
import rules from './TagMappingValidataionRules';

const { Item } = Form;
const { Option } = Select;

@Form.create()
@autobind
class TagPolicyMappingFormModal extends Component {
  static propTypes = {
    form: objectOf(any).isRequired,
    tagName: string.isRequired,
    onOk: func.isRequired,
    onCancel: func.isRequired,
    visible: bool.isRequired,
  };

  state = {
    loading: false,
  };

  handleSubmit(ev) {
    if (ev) {
      ev.preventDefault();
    }
    this.setState({ loading: true });
    const { form } = this.props;
    form.validateFields(this.validateFormFields);
    this.setState({ loading: false });
  }

  async validateFormFields(err) {
    if (err) {
      const fieldsToCheck = ['policyId'];
      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];
        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }
          return;
        }
      }
    }

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    await this.props.onOk(formFields);
    this.cleanupBeforeClose();
  }

  cleanupBeforeClose() {
    this.props.form.resetFields();
    this.props.onCancel();
  }

  render() {
    const { visible, tagName } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    return (
      <Modal
        title="Tag Mapping"
        onOk={!loading && this.handleSubmit}
        onCancel={this.cleanupBeforeClose}
        visible={visible}
      >
        <Form onSubmit={this.handleSubmit}>
          <Item label="Tag Name" required>
            {getFieldDecorator('tagName', { initialValue: tagName })(<Input readOnly autoComplete="off" />)}
          </Item>
          <Item label="Promotion Policy Id" required>
            {getFieldDecorator('policyId', { initialValue: '', rules: rules.policyId })(<Input autoComplete="off" />)}
          </Item>
          <Item label="Tag Promotion Type" style={{ marginBottom: 10 }} className={styles.formControlDisplay} required>
            {getFieldDecorator('policyType', { initialValue: TagPromotionType.PROMOTION })(
              <Select>
                <Option value={TagPromotionType.PROMOTION}>{TagPromotionType.PROMOTION}</Option>
                <Option value={TagPromotionType.BOLT}>{TagPromotionType.BOLT}</Option>
                <Option value={TagPromotionType.BOOST}>{TagPromotionType.BOOST}</Option>
              </Select>
            )}
          </Item>
        </Form>
      </Modal>
    );
  }
}

export default TagPolicyMappingFormModal;
