import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Pagination, Button, Row, Col } from 'antd';
import autobind from 'autobind-decorator';
import { push } from 'connected-react-router';

import DataViewer from 'components/DataViewer';
import PageSizeDropdown from 'components/PageSizeDropdown';
import PageDisplay from 'components/PageDisplay';

import { scrollToTop } from 'utils/scrollUtil';
import { listQueryDefault, graphListQueryToState } from 'utils/graphToStateUtil';
import ChaiListFilterContainer from 'common/containers/ChaiListFilterContainer';
import { promotionListQuery } from 'redux/modules/promotion/queries';

import { columns } from './metadata';
import styles from './Airdrop.scss';
import { UserCashbackIssueType } from '../';

const mapDispatchToProps = {
  push,
};

@connect(null, mapDispatchToProps)
@autobind
class PromotionAirdropList extends Component {
  static propTypes = {
    onCreateNew: PropTypes.func.isRequired,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {
    ...listQueryDefault,
  };

  componentDidMount() {
    const { data, currentPage } = this.state;
    if (!data) {
      this.changePage(currentPage);
    }
    this.props.setRef(this);
  }

  componentWillUnmount() {
    this.props.setRef(undefined);
  }

  filterForm = null;

  /**
   * Update Page Size
   * @param {Object} menuInfo
   */
  updatePageSize(menuInfo) {
    const newPageSize = +menuInfo.key;

    this.setState({
      pageSize: newPageSize,
    });

    const { currentPage } = this.state;
    const filter = this.filterForm.getFilter();

    this.getAirdropList(currentPage, newPageSize, filter);
  }

  changePage(newPage) {
    this.setState({
      currentPage: newPage,
    });

    const { pageSize } = this.state;
    const filter = this.filterForm.getFilter();

    this.getAirdropList(newPage, pageSize, filter);
  }

  async refresh() {
    const { currentPage, pageSize } = this.state;
    const filter = this.filterForm.getFilter();
    this.getAirdropList(currentPage, pageSize, filter);
  }

  async resetFilter() {
    this.filterForm.resetFilter();
    this.refresh();
  }

  async getAirdropList(currentPage, pageSize, filter) {
    try {
      await graphListQueryToState({
        onSetState: args => this.setState(args),
        key: 'promotionList',
        query: promotionListQuery,
        currentPage,
        pageSize,
        filter: filter && {
          ...filter,
          // 에어드랍 프리셋
          benefitType: 'cashback',
          issueType: UserCashbackIssueType.AIRDROP,
        },
      });
      scrollToTop();
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { pageSize, currentPage, totalCount, data, error, loading } = this.state;

    return (
      <>
        <Row style={{ paddingTop: 24, lineHeight: '40px' }}>
          <Col xs={14}>
            <ChaiListFilterContainer
              setRef={ref => (this.filterForm = ref)}
              onSubmit={this.refresh}
              items={[
                {
                  type: 'text',
                  name: 'query',
                  label: 'Airdrop Name',
                },
              ]}
            />
          </Col>
          <Col xs={10}>
            <Button type="primary" icon="search" onClick={this.refresh} className={styles.button}>
              Search
            </Button>
            <Button type="default" icon="undo" onClick={this.resetFilter} className={styles.button}>
              Reset
            </Button>
            <Button icon="plus-circle" type="primary" onClick={this.props.onCreateNew} className={styles.button}>
              New
            </Button>
          </Col>
        </Row>

        <DataViewer columns={columns} data={data} error={error} loading={loading} />

        <PageDisplay currentPage={currentPage} totalCount={totalCount} pageSize={pageSize} />

        <Pagination
          total={totalCount}
          pageSize={pageSize}
          defaultCurrent={1}
          current={currentPage}
          onChange={this.changePage}
          style={{
            width: '100%',
            textAlign: 'center',
            marginBottom: 20,
          }}
        />

        <div style={{ position: 'absolute', bottom: 20, right: 20, textAlign: 'right' }}>
          {/* Dropdown for change page size */}
          <PageSizeDropdown currentPageSize={pageSize} onPageSizeChange={this.updatePageSize} />
        </div>
      </>
    );
  }
}

export default PromotionAirdropList;
