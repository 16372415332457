/**
 * CAUTION:
 * 이 파일은 클라이언트에서 볼 수 있는 파일 입니다.
 * Password, App Secret 같은 정보는 넣지 마세요.
 */
const baseConfig = {
  host: process.env.HOST || 'local.dev.chaicloud.io',
  port: 'PORT' in process.env ? process.env.PORT : 3000,
  apiHost:
    process.env.APIHOST || (process.env.NODE_CONFIG_ENV !== 'prod' ? 'api-dev.chai.finance' : 'api.chai.finance'),
  apiPort: process.env.APIPORT,
  graphUrl: process.env.GRAPH_URL || 'graph.dev.chaicloud.io',
  cardGraphUrl: process.env.CARD_GRAPH_URL || 'card-graph.chai.finance/',
  apiScheme: 'https',
  graphqlWSScheme: 'wss',
  app: {
    title: 'Chai Admin',
    description: 'Chai admin',
    head: {
      titleTemplate: 'Chai Admin - %s',
      defaultTitle: 'Chai Admin',
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Chai Admin' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
        { name: 'apple-mobile-web-app-title', content: 'Chai Admin' },
        // theme-color changes Chrome Browser address bar background color.
        // { name: 'theme-color', content: '#3BBEE0' },
        { property: 'og:locale', content: 'ko_KR' },
        { property: 'og:site_name', content: 'Chai web' },
        { property: 'og:title', content: 'Chai web' },
        { property: 'og:type', content: 'website' },
        { property: 'og:description', content: 'Chai Admin' },
        { property: 'og:image', content: 'https://chai.finance/logo.png' },
        { property: 'og:url', content: 'https://chai.finance/' },
      ],
    },
  },
  supportLocales: ['en-US', 'ko-KR'],
};

const environment = {
  dev: {},
  prod: {},
}[process.env.NODE_CONFIG_ENV || 'dev'];

const config = Object.assign({}, baseConfig, environment);

// manipulate api base uri
config.apiBaseUri = `${config.apiScheme}://${config.apiHost}${config.apiPort ? `:${config.apiPort}` : ''}`;
config.apiBaseUri += '/v1';

module.exports = config;
