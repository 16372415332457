import moment from 'moment';

export function required() {
  return {
    required: true,
    message: 'Please input value.',
  };
}

export function isAfter(dateToCompare) {
  return {
    message: `must be after than ${moment(dateToCompare).format('YYYY-MM-DD HH:mm:ss')}.`,
    validator: (rule, value, callback) => {
      if (value && dateToCompare && moment(dateToCompare).isAfter(moment(value))) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isSameOrAfter(dateToCompare) {
  return {
    message: `must be same of after than ${moment(dateToCompare).format('YYYY-MM-DD HH:mm:ss')}.`,
    validator: (rule, value, callback) => {
      if (value && dateToCompare && moment(dateToCompare).isSameOrAfter(moment(value))) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isGreaterThan(valueToCompare) {
  return {
    message: `must be greater than ${valueToCompare}.`,
    validator: (rule, value, callback) => {
      if (value !== '' && +value <= +valueToCompare) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isDiscountAmount() {
  return {
    message: 'must be discount amount value.',
    validator: (rule, value, callback) => {
      if (value && value.length >= 2 && value.slice(-1) === '%' && Number.isNaN(Number(value.slice(0, -1)))) {
        return callback(true);
      }
      if (value && value !== '' && value.slice(-1) !== '%' && Number.isNaN(Number(value))) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isGreaterDiscountAmountThan(valueToCompare) {
  return {
    message: `must be greater discount amount than ${valueToCompare}.`,
    validator: (rule, value, callback) => {
      // %인 경우
      if (
        value &&
        value.length >= 2 &&
        value.slice(-1) === '%' &&
        valueToCompare &&
        valueToCompare.length >= 2 &&
        valueToCompare.slice(-1) === '%'
      ) {
        if (+value.slice(0, -1) <= +valueToCompare.slice(0, -1)) {
          return callback(true);
        }
      }

      if (value && valueToCompare && value !== '' && valueToCompare !== '' && +value <= +valueToCompare) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isLessDiscountAmountThan(valueToCompare) {
  return {
    message: `must be less discount amount than ${valueToCompare}.`,
    validator: (rule, value, callback) => {
      // %인 경우
      if (
        value &&
        value.length >= 2 &&
        value.slice(-1) === '%' &&
        valueToCompare &&
        valueToCompare.length >= 2 &&
        valueToCompare.slice(-1) === '%'
      ) {
        if (+value.slice(0, -1) >= +valueToCompare.slice(0, -1)) {
          return callback(true);
        }
      }

      if (value && valueToCompare && value !== '' && valueToCompare !== '' && +value >= +valueToCompare) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isPercentDiscountType() {
  return {
    message: 'must be percent discount type',
    validator: (rule, value, callback) => {
      if (
        value.trim().length >= 2 &&
        value.trim().slice(-1) === '%' &&
        !Number.isNaN(Number(value.trim().slice(0, -1)))
      ) {
        return callback();
      }

      return callback(true);
    },
  };
}

export function isSameDiscountAmountType(valueToCompare) {
  return {
    message: 'must be same discount type',
    validator: (rule, value, callback) => {
      if (!value || !valueToCompare) {
        return callback();
      }

      if (
        value.length >= 2 &&
        value.slice(-1) === '%' &&
        valueToCompare.length >= 2 &&
        valueToCompare.slice(-1) === '%'
      ) {
        return callback();
      }
      if (!Number.isNaN(Number(value)) && !Number.isNaN(Number(valueToCompare))) {
        return callback();
      }

      return callback(true);
    },
  };
}

export function isInteger() {
  return {
    message: 'must be integer value.',
    validator: (rule, value, callback) => {
      // if (!/^\d+$/.test(value)) {
      if (value !== '' && !Number.isInteger(value)) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isBetween(min, max) {
  return {
    message: `must be between ${min} ~ ${max}.`,
    validator: (rule, value, callback) => {
      if (value !== '' && min && max && (+value > +max || +value < +min)) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isFloat() {
  return {
    message: 'must be float value.',
    validator: (rule, value, callback) => {
      if (value !== '' && !/^\d+$|^\d+(\.\d+)$/.test(value)) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function isNumber() {
  return {
    message: 'must be a number(integer or float is fine).',
    validator: (rule, value, callback) => {
      if (value !== '' && Number.isNaN(Number.parseFloat(value))) {
        return callback(true);
      }

      return callback();
    },
  };
}

export function maximumDecimal(input) {
  return {
    message: `exceeded maximum decimal points(${input}).`,
    validator: (rule, value, callback) => {
      const decimal = new RegExp(`^[-+]?\\d*\.?\\d{1,${input}}$`);

      if (value !== '' && !decimal.test(value)) {
        return callback(true);
      }

      return callback();
    },
  };
}
