import { Col, Form, Row } from 'antd';
import autobind from 'autobind-decorator';
import { ChaiSelectForm, ChaiTextForm } from 'common';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { rowProps } from 'ui/ResponsiveProps';

@Form.create()
@autobind
class ChaiListFilterContainer extends PureComponent {
  static propTypes = {
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    setRef: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        data: PropTypes.any,
      })
    ).isRequired,
  };

  static defaultProps = {};

  componentDidMount() {
    this.props.setRef(this);
  }

  componentWillUnmount() {
    this.props.setRef(undefined);
  }

  getFilter() {
    return this.props.form.getFieldsValue();
  }

  resetFilter() {
    this.props.form.resetFields();
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit();
  }

  renderFilterItem(form, col) {
    switch (col.type) {
      case 'text':
        return <ChaiTextForm form={form} label={col.label} name={col.name} />;
      case 'select':
        return <ChaiSelectForm form={form} label={col.label} name={col.name} items={col.data} />;
      default:
        throw new Error('ChaiListFilterContainer: Unknown filterItem');
    }
  }

  render() {
    const { form, items } = this.props;
    const colSize = Math.max(Math.floor(24 / items.length), 4);
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row {...rowProps}>
          {items.map(col => (
            <Col xs={colSize} key={col.name}>
              {this.renderFilterItem(form, col)}
            </Col>
          ))}
        </Row>
      </Form>
    );
  }
}

export default ChaiListFilterContainer;
