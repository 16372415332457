export const ADD_TAB = 'tab/ADD';
export const REMOVE_TAB = 'tab/REMOVE';
export const FOCUS_TAB = 'tab/FOCUS';
export const UPDATE_TAB = 'tab/UPDATE';
export const MOVE_TAB = 'tab/MOVE';
export const SET_TABS = 'tabs/SET';
export const REMOVE_AND_FOCUS_PREVIOUS_TAB = 'tab/REMOVE_AND_FOCUS';
export const SET_DATA = 'tab/SET_DATE';
export const REMOVE_ALL = 'tab/REMOVE_ALL';
export const CLEAR = 'tabs/CLEAR';

const initialTabState = {
  activeKey: 'default',
  list: [], // List of TabData
};

const initialState = {
  user: { ...initialTabState },
  dfUser: { ...initialTabState },
  merchant: { ...initialTabState },
  payment: { ...initialTabState },
  transaction: { ...initialTabState },
  settlement: { ...initialTabState },
  policy: { ...initialTabState },
  promotion: { ...initialTabState },
  promotionGroup: { ...initialTabState },
  luckyPromotion: { ...initialTabState },
  boltPolicy: { ...initialTabState },
  boostPromotion: { ...initialTabState },
  boltExclusionRule: { ...initialTabState },
  boostExclusionRule: { ...initialTabState },
  monohaBoostPromotion: { ...initialTabState },
  brand: { ...initialTabState },
  cardProduct: { ...initialTabState },
  tag: { ...initialTabState },
  bank: { ...initialTabState },
  boostCategory: { ...initialTabState },
  boltDrawPromotion: { ...initialTabState },
};

/**
 * Add new Tab
 * @param {string} key - Key of store
 * @param {Pane<any>} tab
 * @return ReduxAction
 */
export const addTab = (key, tab) => ({
  type: ADD_TAB,
  payload: {
    key,
    tab,
  },
});

/**
 * Remove specified Tab
 * @param {string} key - Key of store
 * @param {string} tabKey - Key of tab to remove
 * @return ReduxAction
 */
export const removeTab = (key, tabKey) => ({
  type: REMOVE_TAB,
  payload: {
    key,
    tabKey,
  },
});

/**
 * Focus specified Tab
 * @param {string} key - Key of store
 * @param {string} tabKey - Key of tab to focus
 * @return ReduxAction
 */
export const focusTab = (key, tabKey) => ({
  type: FOCUS_TAB,
  payload: {
    key,
    tabKey,
  },
});

/**
 * Update specified tab
 * @param {string} key - Key of store
 * @param {string} tabKey - Key of tab to update
 * @param {TabData} tab - Tab Object
 */
export const updateTab = (key, tabKey, tab) => ({
  type: UPDATE_TAB,
  payload: {
    key,
    tabKey,
    tab,
  },
});

/**
 * Move tab to specific position index
 * @param {string} key - Key of store
 * @param {string} tabKey - Key of tab to move
 * @param {number} posIdx - Position Index
 */
export const moveTab = (key, tabKey, posIdx) => ({
  type: MOVE_TAB,
  payload: {
    key,
    tabKey,
    posIdx,
  },
});

/**
 * Replace all tabs
 * @param {string} key - Key of store
 * @param {TabData[]} tabList - List of Object
 */
export const setTabs = (key, tabList) => ({
  type: SET_TABS,
  payload: {
    key,
    tabList,
  },
});

/**
 * Remove and Focus Previous Tab
 * @param {string} key - Key of store
 * @param {string} tabKey - Key of tab to update
 */
export const removeAndFocusPreviousTab = (key, tabKey) => ({
  type: REMOVE_AND_FOCUS_PREVIOUS_TAB,
  payload: {
    key,
    tabKey,
  },
});

/**
 * Find and update TabData of specified tab key
 * @param {*} key
 * @param {*} tabKey
 * @param {*} data
 */
export const setData = (key, tabKey, data) => ({
  type: SET_DATA,
  payload: {
    key,
    tabKey,
    data,
  },
});

/**
 * Empty all tabs
 */
export const removeAll = () => ({
  type: REMOVE_ALL,
  payload: {},
});

/**
 * Empty all tabs (excepts first tab)
 */
export const clearTabs = key => ({
  type: CLEAR,
  payload: {
    key,
  },
});

export default function tabsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TAB: {
      const { key, tab } = action.payload;
      const currentTab = state[key];
      let newTabList = [];

      // Skip if already has same Tab
      for (let i = 0; i < currentTab.list.length; i += 1) {
        if (currentTab.list[i].key === tab.key) {
          return state;
        }
      }

      // Deep clone old list to new list
      newTabList = [...currentTab.list];
      newTabList.push(tab);

      return {
        ...state,
        [key]: {
          activeKey: currentTab.activeKey,
          list: newTabList,
        },
      };
    }

    case REMOVE_TAB: {
      const { key, tabKey } = action.payload;
      const currentTab = state[key];

      // If there's no tab, just finish
      if (currentTab.list.length === 0) {
        return state;
      }

      const newTabList = [...currentTab.list];

      for (let i = 0; i < newTabList.length; i += 1) {
        if (newTabList[i].key === tabKey) {
          newTabList.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        [key]: {
          activeKey: currentTab.activeKey,
          list: newTabList,
        },
      };
    }

    case FOCUS_TAB: {
      const { key, tabKey } = action.payload;
      const currentTab = state[key];

      return {
        ...state,
        [key]: {
          activeKey: tabKey,
          list: currentTab.list,
        },
      };
    }

    case UPDATE_TAB: {
      const { key, tabKey, tab } = action.payload;
      const currentTab = state[key];
      const copiedTabList = [...currentTab.list];

      for (let i = 0; i < copiedTabList.length; i += 1) {
        if (copiedTabList[i].key === tabKey) {
          copiedTabList[i] = tab;
          break;
        }
      }

      return {
        ...state,
        [key]: {
          activeKey: currentTab.activeKey,
          list: copiedTabList,
        },
      };
    }

    case MOVE_TAB: {
      const { key, tabKey, posIdx } = action.payload;
      const currentTab = state[key];

      if (currentTab.list.length < 2) {
        return state;
      } else if (posIdx >= currentTab.list.length || posIdx < 0) {
        console.warn(`tabsReducer.moveTab: Invalid Position Index: ${posIdx}`);
        return state;
      }

      const copiedTabList = [...currentTab.list];
      let currentTabPos = 0;

      for (let i = 0; i < copiedTabList.length; i += 1) {
        if (copiedTabList[i].key === tabKey) {
          currentTabPos = i;
          break;
        }
      }

      const oldTab = copiedTabList[posIdx];
      copiedTabList[posIdx] = copiedTabList[currentTabPos];
      copiedTabList[currentTabPos] = oldTab;

      return {
        ...state,
        [key]: {
          activeKey: currentTab.activeKey,
          list: copiedTabList,
        },
      };
    }

    case SET_TABS: {
      const { key, tabList } = action.payload;
      const currentTab = state[key];

      return {
        ...state,
        [key]: {
          activeKey: currentTab.activeKey,
          list: tabList,
        },
      };
    }

    case REMOVE_AND_FOCUS_PREVIOUS_TAB: {
      let newActiveKey = 'default';

      const { key, tabKey } = action.payload;
      const currentTab = state[key];

      // If there's no tab, just finish
      if (currentTab.list.length === 0) {
        return state;
      }

      const newTabList = [...currentTab.list];

      for (let i = 0; i < newTabList.length; i += 1) {
        if (newTabList[i].key === tabKey) {
          newTabList.splice(i, 1);
          break;
        }

        newActiveKey = newTabList[i].key;
      }

      return {
        ...state,
        [key]: {
          activeKey: newActiveKey,
          list: newTabList,
        },
      };
    }

    case SET_DATA: {
      const { key, tabKey, data } = action.payload;
      const currentTab = state[key];

      const newTabList = [...currentTab.list];

      for (let i = 0; i < newTabList.length; i += 1) {
        if (newTabList[i].key === tabKey) {
          newTabList[i].data = data;
          break;
        }
      }

      return {
        ...state,
        [key]: {
          activeKey: state[key].activeKey,
          list: newTabList,
        },
      };
    }

    case CLEAR: {
      const { key } = action.payload;
      const currentTab = state[key];

      console.log(key, state, currentTab);

      return {
        ...state,
        [key]: {
          activeKey: currentTab.list[0].key,
          list: [currentTab.list[0]],
        },
      };
    }

    case REMOVE_ALL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
