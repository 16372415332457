export default {
  id: [
    {
      type: 'string',
    },
  ],
  title: [
    {
      type: 'string',
    },
  ],
};
