import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, message, Alert, Icon } from 'antd';

import { addCardProduct, updateCardProduct, getCardProducts } from 'redux/modules/cardProduct/actions';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab, updateTab, removeTab, removeAndFocusPreviousTab } from 'redux/modules/tabs';
import { cardProductQuery } from 'redux/modules/cardProduct/queries';

import styles from './CardProduct.scss';
import { BoostNumberForm, BoostSelectForm, BoostTextForm } from '../../Boost/Forms';

const dividerStyle = { margin: '50px 30px' };

const mapStateToProps = state => {
  const { data, currentPage, pageSize, filter, updating } = state.cardProduct;

  return {
    data,
    currentPage,
    pageSize,
    filter,
    updating,
  };
};

const mapDispatchToProps = {
  push,
  addTab,
  focusTab,
  updateTab,
  removeTab,
  removeAndFocusPreviousTab,
  addCardProduct,
  updateCardProduct,
  getCardProducts,
};

@Form.create()
@connect(mapStateToProps, mapDispatchToProps)
@autobind
class CardProductForm extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    updating: PropTypes.bool.isRequired,
    currentPage: PropTypes.number.isRequired,
    filter: PropTypes.objectOf(PropTypes.any),
    pageSize: PropTypes.number.isRequired,
    form: PropTypes.objectOf(PropTypes.any).isRequired,
    addCardProduct: PropTypes.func.isRequired,
    updateCardProduct: PropTypes.func.isRequired,
    getCardProducts: PropTypes.func.isRequired,
    removeAndFocusPreviousTab: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    id: null,
    filter: null,
  };

  state = {
    data: null,
    error: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.id !== 'add') {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  closeTab() {
    const { id } = this.props;
    this.props.removeAndFocusPreviousTab('cardProduct', id ? id.toString() : 'add');
    this.props.push('/card/product');
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  async getData() {
    const { id, form } = this.props;

    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: cardProductQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.cardProduct;

      form.setFieldsValue({
        type: data.type,
      });

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Promotion ID');
      } else {
        this.setState({ loading: false, data });
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Card Product: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  validateFormFields(err) {
    const { form } = this.props;

    if (err) {
      const fieldsToCheck = [
        'issuer',
        'resourceType',
        'title',
        'subTitle',
        'description',
        'titleDescription',
        'emphasizeText',
        'emphasizeColor1',
        'emphasizeColor2',
        'annualFee',
      ];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        const instance = form.getFieldInstance(field);
        if (err[field]) {
          if (instance && typeof instance.input !== 'undefined') {
            instance.input.focus();
          } else if (instance && typeof instance.picker !== 'undefined') {
            instance.picker.input.focus();
          } else if (instance && typeof instance.inputNumberRef !== 'undefined') {
            instance.inputNumberRef.input.focus();
          }
          return;
        }
      }
      return;
    }

    this.submit();
  }

  async submit() {
    const { form } = this.props;
    const formFields = form.getFieldsValue();
    const fields = {};

    fields.id = this.props.id;
    fields.issuer = formFields.issuer;
    fields.resourceType = formFields.resourceType;
    fields.status = formFields.status;
    fields.title = formFields.title;
    fields.subTitle = formFields.subTitle;
    fields.titleDescription = formFields.titleDescription;
    fields.emphasizeText = formFields.emphasizeText;
    fields.emphasizeColor1 = formFields.emphasizeColor1;
    fields.emphasizeColor2 = formFields.emphasizeColor2;
    fields.description = formFields.description;
    fields.maxMonthlyBenefitAmount = formFields.maxMonthlyBenefitAmount;
    fields.issueBoltPrice = formFields.issueBoltPrice;
    fields.reissueBoltPrice = formFields.reissueBoltPrice;
    fields.annualFee = formFields.annualFee;
    fields.isDomestic = formFields.isDomestic === 'true';
    fields.logoImageUrl = formFields.logoImageUrl || null;
    fields.type = formFields.type;
    fields.issueTarget = formFields.issueTarget;
    fields.shareableUrl = formFields.shareableUrl || null;
    fields.externalIssueUrl = formFields.externalIssueUrl || null;
    fields.sortedIndex = formFields.sortedIndex;
    fields.maxEarlyBirdCount = formFields.maxEarlyBirdCount;
    fields.descriptionImageUrls = (formFields.descriptionImageUrls || '')
      .replace(/['"]+/g, '')
      .split(/[\s]*,[\s]*|[\s]+/)
      .filter(Boolean);
    fields.benefitImageUrls = (formFields.benefitImageUrls || '')
      .replace(/['"]+/g, '')
      .split(/[\s]*,[\s]*|[\s]+/)
      .filter(Boolean);
    fields.productCode = formFields.productCode;
    fields.brandCode = formFields.brandCode;

    if (fields.id === 'add') {
      return this.add(fields);
    }

    return this.update(fields);
  }

  async add(input) {
    delete input.id;

    await this.props.addCardProduct(input);

    this.props.removeAndFocusPreviousTab('cardProduct', 'add');
    this.props.push('/card/product');
    this.refreshCardProductList();
  }

  async update(input) {
    await this.props.updateCardProduct(input);

    this.props.removeAndFocusPreviousTab('cardProduct', input.id.toString());
    this.props.push('/card/product');
    this.refreshCardProductList();
  }

  refreshCardProductList() {
    const { currentPage, pageSize, filter } = this.props;
    this.props.getCardProducts(currentPage, pageSize, filter);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { updating, form } = this.props;
    // const { getFieldDecorator } = form;
    const { error, loading } = this.state;
    const data = this.state.data || {};
    const { id } = data;

    if (loading) {
      return <Icon className={styles.loadingSpinner} type="loading" spin />;
    }

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles.form} colon={false}>
        <BoostSelectForm
          form={form}
          label="타입"
          name="type"
          initial={data.type ? data.type : 'normal'}
          items={[
            { label: 'Normal', value: 'normal' },
            { label: 'Early Bird', value: 'early_bird' },
          ]}
        />
        <BoostSelectForm
          form={form}
          label="상태"
          name="status"
          initial={data.status ? data.status : 'enabled'}
          items={[
            { label: 'Enabled', value: 'enabled' },
            { label: 'Disabled', value: 'disabled' },
            { label: 'Sold Out', value: 'sold_out' },
            { label: 'Coming Soon', value: 'coming_soon' },
          ]}
        />
        <BoostNumberForm
          label="순서"
          form={form}
          name="sortedIndex"
          initial={data.sortedIndex ? data.sortedIndex : 0}
        />
        <BoostSelectForm
          form={form}
          label="발급 가능 유저"
          name="issueTarget"
          initial={data.issueTarget ? data.issueTarget : 'all_user'}
          items={[
            { label: 'All User', value: 'all_user' },
            { label: 'Early Bird User', value: 'early_bird_user' },
          ]}
        />

        <Divider style={dividerStyle}>Card Info</Divider>
        <BoostSelectForm
          form={form}
          label="카드사"
          name="issuer"
          initial={data.issuer ? data.issuer : 'bc'}
          items={[
            { label: 'BC', value: 'bc' },
            { label: '하나', value: 'hana' },
          ]}
        />
        <BoostSelectForm
          form={form}
          label="Resource Type"
          name="resourceType"
          initial={data.resourceType ? data.resourceType : 'red'}
          items={[
            { label: 'Red', value: 'red' },
            { label: 'Metal', value: 'metal' },
            { label: 'Credit', value: 'credit' },
          ]}
        />
        <BoostTextForm form={form} label="Product Code" name="productCode" initial={data.productCode || ''} />
        <BoostTextForm form={form} label="Brand Code" name="brandCode" initial={data.brandCode || ''} />
        <BoostSelectForm
          form={form}
          label="국내/해외 구분"
          name="isDomestic"
          initial={data.isDomestic !== undefined ? `${data.isDomestic}` : 'true'}
          items={[
            { label: '국내전용', value: 'true' },
            { label: '국내외겸용', value: 'false' },
          ]}
        />

        <Divider style={dividerStyle}>Contents</Divider>
        <BoostTextForm form={form} label="Title" name="title" initial={data.title} require />
        <BoostTextForm form={form} label="Subtitle" name="subTitle" initial={data.subTitle} require />
        <BoostTextForm
          form={form}
          label="타이틀 설명"
          name="titleDescription"
          initial={data.titleDescription || ''}
          require
          maxLength={10}
        />
        <BoostTextForm
          form={form}
          label="강조 문구"
          name="emphasizeText"
          initial={data.emphasizeText || ''}
          require
          maxLength={10}
        />
        <BoostTextForm
          form={form}
          label="강조 문구 색상(좌)"
          name="emphasizeColor1"
          initial={data.emphasizeColor1 || ''}
          require
        />
        <BoostTextForm
          form={form}
          label="강조 문구 색상(우)"
          name="emphasizeColor2"
          initial={data.emphasizeColor2 || ''}
          require
        />
        <BoostTextForm form={form} name="description" label="설명" initial={data.description} multiline />
        <BoostTextForm
          form={form}
          label="설명 이미지 Urls"
          name="descriptionImageUrls"
          initial={(data.descriptionImageUrls || []).join('\n')}
          multiline
        />
        <BoostNumberForm
          label="월 최대 혜택 금액"
          form={form}
          name="maxMonthlyBenefitAmount"
          initial={data.maxMonthlyBenefitAmount ? data.maxMonthlyBenefitAmount : 0}
        />
        <BoostNumberForm label="연회비" form={form} name="annualFee" initial={data.annualFee ? data.annualFee : 0} />
        <BoostTextForm form={form} label="로고 이미지 Url" name="logoImageUrl" initial={data.logoImageUrl || ''} />
        <BoostTextForm
          form={form}
          label="혜택 이미지 Urls"
          name="benefitImageUrls"
          initial={(data.benefitImageUrls || []).join('\n')}
          multiline
        />

        <Divider style={dividerStyle}>Bolt Setting</Divider>
        <BoostNumberForm
          label="발급 번개 비용"
          form={form}
          name="issueBoltPrice"
          initial={data.issueBoltPrice ? data.issueBoltPrice : 0}
        />
        <BoostNumberForm
          label="재발급 번개 비용"
          form={form}
          name="reissueBoltPrice"
          initial={data.reissueBoltPrice ? data.reissueBoltPrice : 0}
        />

        <Divider style={dividerStyle}>Optional</Divider>
        <BoostTextForm form={form} label="공유 Url" name="shareableUrl" initial={data.shareableUrl || ''} />
        <BoostTextForm
          form={form}
          label="외부 발급 Url"
          name="externalIssueUrl"
          initial={data.externalIssueUrl || ''}
        />
        <BoostNumberForm
          label="얼리버드 인원 제한"
          form={form}
          name="maxEarlyBirdCount"
          initial={data.maxEarlyBirdCount ? data.maxEarlyBirdCount : 0}
        />

        <div className={styles.formButtonContainer}>
          <Button type="ghost" onClick={this.closeTab}>
            Cancel
          </Button>

          <Divider type="vertical" style={{ background: '#fff' }} />

          <Button type="primary" htmlType="submit" disabled={updating}>
            {id ? 'Save' : 'Create'}
          </Button>
        </div>
      </Form>
    );
  }
}

export default CardProductForm;
