import 'tui-color-picker/dist/tui-color-picker.css';
import React, { Component, createRef } from 'react';
import { func, string, objectOf, oneOf, any } from 'prop-types';
import { connect } from 'react-redux';
import autobind from 'autobind-decorator';
import { Form, Button, Divider, Input, Alert, message, Row, Col, Checkbox, Popconfirm, Select } from 'antd';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { showI18nModal } from 'redux/modules/i18n/actions';
import I18nModal from 'containers/I18n/Modal/I18nModal';
import store from 'store';
import startCase from 'lodash/startCase';
import { Editor } from '@toast-ui/react-editor';
import { FaqMode } from '../FaqConstants';
import { faqQuery } from '../FaqQueries';
import Item from './FaqFormItem';
import rules from './FaqFormValidationRules';
import editorConfig from './FaqFormEditorConfig';
import styles from './FaqForm.scss';

const { Option } = Select;

const mapDispatchToProps = {
  showI18nModal,
};

@Form.create()
@connect(null, mapDispatchToProps)
@autobind
class FaqForm extends Component {
  static propTypes = {
    onSubmit: func.isRequired,
    onCancel: func.isRequired,
    onRemove: func.isRequired,
    form: objectOf(any).isRequired,
    id: string,
    mode: oneOf([FaqMode.ADD, FaqMode.UPDATE]).isRequired,
    showI18nModal: func.isRequired,
  };

  static defaultProps = {
    id: null,
  };

  state = {
    // XHR Status
    data: null,
    error: null,
    loading: false,
    // Internal Form States
    contentError: null,
  };

  componentDidMount() {
    if (this.props.mode !== FaqMode.ADD) {
      this.getData();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  input = {
    title: null,
  };

  query = null;
  contentRef = createRef();

  async getData() {
    const { id } = this.props;
    this.setState({ loading: true, error: null });

    try {
      this.query = cancelableQuery({
        query: faqQuery,
        variables: {
          id,
        },
      });

      const result = await this.query;
      const data = result.data.faq;

      // If data is null, display error
      if (!data) {
        throw new Error('Invalid Faq ID');
      } else {
        this.setState({
          loading: false,
          data,
        });

        if (this.contentRef.current) {
          this.contentRef.current.getInstance().setHtml(data.content);
        }
      }
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get faq: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  showI18nModal({ type, title, columnName, formFieldName, value }) {
    const formFields = this.props.form.getFieldsValue();

    this.props.showI18nModal({
      type: type || 'input',
      title,
      tableName: 'faq',
      targetId: this.props.id,
      columnName,
      currentValue: value || formFields[formFieldName || columnName],
      editorConfig: editorConfig.content,
    });
  }

  handleSubmit(ev) {
    ev.preventDefault();
    const { form } = this.props;

    form.validateFields(this.validateFormFields);
  }

  validateFormFields(err) {
    this.setState({ contentError: null });

    if (err) {
      const fieldsToCheck = ['title'];

      for (let i = 0; i < fieldsToCheck.length; i += 1) {
        const field = fieldsToCheck[i];

        if (err[field]) {
          if (typeof this.input[field] !== 'undefined') {
            this.input[field].focus();
          }

          return;
        }
      }
    }

    const instance = this.contentRef.current.getInstance();
    let content = instance.getHtml();

    if (!content) {
      // Currently using instance.focus() throws an error. Might be issue of Toast UI Editor.
      this.setState({
        contentError: new Error('Please enter the content.'),
      });

      return;
    }

    this.setState({
      contentError: null,
    });

    const { form } = this.props;
    const formFields = form.getFieldsValue();

    formFields.id = this.props.id;
    formFields.status = formFields.display ? 'enabled' : 'disabled';
    delete formFields.display;

    // Fix "data-tomark-pass" issue
    content = content.replace(/(\s+)?data-tomark-pass(\s+)?/gm, '');

    // Fix <br=""> issue
    content = content.replace(/<br="">/gm, '<br>');

    formFields.content = content;
    this.props.onSubmit(formFields);
  }

  handleRemoveFaq() {
    const { id, onRemove } = this.props;
    onRemove(id);
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { id, mode, form } = this.props;
    const { getFieldDecorator } = form;
    const { error, loading, contentError } = this.state;
    const data = this.state.data || {};
    const { title, status, category } = data;

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <Form onSubmit={this.handleSubmit} className={styles.faqForm}>
        <div className={styles.title}>
          <h1>{startCase(mode.toLowerCase())} FAQ</h1>
        </div>

        {/* Title */}
        <Item label="Title">
          {getFieldDecorator('title', {
            initialValue: title,
            rules: rules.title,
          })(
            <Input
              placeholder="Title"
              ref={node => (this.input.title = node)}
              autoComplete="off"
              style={{ width: 300 }}
            />
          )}
          {id && (
            <Button
              style={{ marginLeft: 4, verticalAlign: 'middle' }}
              onClick={() => this.showI18nModal({ title: 'Title', columnName: 'title' })}
            >
              Translate
            </Button>
          )}
        </Item>

        <Item label="Category">
          {getFieldDecorator('category', {
            initialValue: category,
          })(
            <Select>
              <Option value="customer_service">CS</Option>
              <Option value="card">CARD</Option>
            </Select>
          )}
        </Item>

        {/* Status */}
        <Item label="Display">
          {getFieldDecorator('display', {
            initialValue: typeof status !== 'undefined' ? status === 'enabled' : true,
            valuePropName: 'checked',
          })(<Checkbox />)}
        </Item>

        {/* Author */}
        <Item label="Author">
          <Input value={store.get('admin').fullname} disabled autoComplete="off" style={{ width: 150 }} />
        </Item>

        <Item label="Content" required help={contentError && contentError.message} validateStatus="error">
          <div className={contentError && styles.contentError} style={{ marginBottom: 5 }}>
            {id && (
              <Button
                onClick={() =>
                  this.showI18nModal({
                    type: 'editor',
                    title: 'Content',
                    columnName: 'content',
                    value: this.contentRef.current.getInstance().getHtml(),
                  })
                }
              >
                Translate
              </Button>
            )}
            <Editor {...editorConfig.content} ref={this.contentRef} plugins={[colorSyntax]} />
          </div>
        </Item>

        <Row>
          <Col span={2} />
          <Col span={2}>
            {mode === FaqMode.UPDATE && (
              <Popconfirm
                placement="bottomLeft"
                title="Are you sure?"
                okText="Delete"
                cancelText="No"
                onConfirm={this.handleRemoveFaq}
              >
                <Button type="danger" ghost icon="delete" />
              </Popconfirm>
            )}
          </Col>
          <Col span={4} />
          <Col xs={24} sm={10} style={{ textAlign: 'right' }}>
            <Button style={{ marginRight: 10 }} onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button type="primary" icon="save" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>

        <I18nModal />
      </Form>
    );
  }
}

export default FaqForm;
