import { FETCH_BUSINESS_CODE, FETCH_BUSINESS_CODE_SUCCESS, FETCH_BUSINESS_CODE_FAILURE } from './actions';

const initialState = {
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  totalCount: 1,
  pageSize: 10000,
  filter: {},
};

export default function BusinessCodeReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BUSINESS_CODE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_BUSINESS_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.result,
      };
    case FETCH_BUSINESS_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
