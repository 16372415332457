const rules = {
  uid: [
    {
      required: true,
      message: 'Please enter the Login ID',
    },
  ],
  carrier: [
    {
      required: true,
      message: 'Please select the carrier',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Please enter the phone number.',
    },
  ],
  birth: [
    {
      required: true,
      message: 'Please enter the birthday 8 digit.',
    },
  ],
  name: [
    {
      required: true,
      message: 'Please enter the your name',
    },
  ],
  sex: [
    {
      required: true,
    },
  ],
  foreigner: [
    {
      required: true,
    },
  ],
  code: [
    {
      required: true,
      message: 'Please enter the code',
    },
  ],
};

export default rules;
