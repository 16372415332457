import React, { PureComponent, Fragment } from 'react';
import { func, bool } from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Spin, Dropdown, Avatar, Divider } from 'antd';
import { push } from 'connected-react-router';
import Debounce from 'lodash-decorators/debounce';
import autobind from 'autobind-decorator';
import store from 'store';
import logo from 'shared/logo.png';
import apolloClient from 'helpers/apolloClient';
import LogoutTimer from 'components/LogoutTimer';
import DropdownOverlay from './ChaiHeaderDropdownOverlay';
import { logoutMutation } from './ChaiHeaderMutations.js';
import styles from './ChaiHeader.less';

@connect(null, {
  push,
})
@autobind
class ChaiHeader extends PureComponent {
  static propTypes = {
    push: func.isRequired,
    onCollapse: func.isRequired,
    collapsed: bool.isRequired,
    isMobile: bool,
  };

  static defaultProps = {
    isMobile: false,
  };

  componentWillUnmount() {
    this.triggerResizeEvent.cancel();
  }

  toggle() {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  }

  @Debounce(600)
  triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }

  handleMenuClick({ key }) {
    if (key === 'logout') {
      this.logout();
    } else if (key === 'edit-profile') {
      this.props.push('/profile');
    }
  }

  async logout() {
    const {
      data: { error },
    } = await apolloClient.mutate({
      mutation: logoutMutation,
    });

    if (!error) {
      store.remove('admin');
      window.location.href = '/';
    }
  }

  render() {
    const { collapsed, isMobile } = this.props;
    const loggedInAdmin = store.get('admin');
    const displayUserControl = !isMobile || (isMobile && collapsed);

    return (
      <div className={styles.header}>
        {/* Icon for Mobile */}
        {isMobile && (
          <Fragment>
            <Link to="/" className={styles.logo} key="logo">
              <img src={logo} alt="logo" width="32" />
            </Link>
            <Divider type="vertical" key="line" />
          </Fragment>
        )}

        {/* Menu Toggle Button */}
        <Icon className={styles.trigger} type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={this.toggle} />

        {/* User Control */}
        {displayUserControl && (
          <div className={styles.right}>
            <LogoutTimer />

            {loggedInAdmin ? (
              <Dropdown overlay={<DropdownOverlay onMenuClick={this.handleMenuClick} />}>
                <span className={`${styles.action} ${styles.account}`}>
                  <Avatar
                    size="small"
                    className={styles.avatar}
                    src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png"
                  />
                  <span className={styles.name}>{loggedInAdmin.fullname}</span>
                </span>
              </Dropdown>
            ) : (
              <Spin size="small" style={{ marginLeft: 8 }} />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ChaiHeader;
