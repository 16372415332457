import PropTypes from 'prop-types';
import { Provider as ReduxProvider } from 'react-redux';
import { withContext } from 'recompose';

const Provider = withContext(
  {
    apiClient: PropTypes.any,
    socket: PropTypes.any,
  },
  ({ apiClient, socket }) => ({ apiClient, socket })
)(ReduxProvider);

export default Provider;
