import moment from 'moment';
import React, { Component } from 'react';
import { func, objectOf, any } from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon, Row, Col, Alert, Divider, Button, message } from 'antd';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import TextChart from 'components/TextChart';
import { commify } from 'utils/stringUtil';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { chartDataQuery } from './DashboardQueries';
import AutoRefreshControl from './AutoRefreshControl';
import TimeRangeControl from './TimeRangeControl';
import { AutoRefreshInterval, TimeRangeQuickMode, TimeRangeMode, TimeFormat } from './DashboardConstants';
import { chartTimeInterval } from './DashboardMetadata';
import RegisteredUsersChart from './Charts/RegisteredUsersChart';
import PaymentCountsChart from './Charts/PaymentCountsChart';
import PaymentsChart from './Charts/PaymentsChart';
import Top20ProductsChart from './Charts/Top20ProductsChart';
import styles from './styles.scss';

@connect(null, {
  push,
})
@autobind
class DashboardContainer extends Component {
  static propTypes = {
    push: func.isRequired,
    location: objectOf(any).isRequired,
    history: objectOf(any).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      autoRefreshInterval: null,
      timeRangeMode: TimeRangeMode.QUICK,
      timeRangeQuickMode: TimeRangeQuickMode.TODAY,
      timeRange: [],
      startDate: null,
      endDate: null,
      openStartDatePicker: false,
      openEndDatePicker: false,
      showAutoRefreshControl: false,
      showTimeRangeControl: false,
      // XHR Status
      data: null,
      error: null,
      loading: false,
    };

    const params = new URLSearchParams(this.props.location.search);
    const autoRefreshInterval = params.get('autoRefreshInterval');
    const timeRangeMode = params.get('timeRangeMode');
    const timeRangeQuickMode = params.get('timeRangeQuickMode');
    const timeRange = params.get('timeRange');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    this.state.autoRefreshInterval = autoRefreshInterval !== 'null' ? autoRefreshInterval : null;
    this.state.timeRangeMode = timeRangeMode || TimeRangeMode.QUICK;
    this.state.timeRangeQuickMode = timeRangeQuickMode || TimeRangeQuickMode.TODAY;
    this.state.timeRange = timeRange ? [...timeRange.split(',').map(t => moment.utc(+t))] : [];
    this.state.startDate = startDate ? moment.utc(+startDate) : null;
    this.state.endDate = endDate ? moment.utc(+endDate) : null;
  }

  componentWillMount() {
    const { listen } = this.props.history;
    this.unsubscribe = listen(this.handleUrlChange);
  }

  componentDidMount() {
    this.getData();

    if (this.state.autoRefreshInterval !== null) {
      this.startAutoRefresh();
    }
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }

    clearInterval(this.autoRefreshTimer);
    this.unsubscribe();
  }

  unsubscribe = null;
  query = null;
  autoRefreshTimer = null;

  /**
   * Open or Focus to correct tab depends on URL
   */
  handleUrlChange() {
    const params = new URLSearchParams(this.props.history.location.search);
    const autoRefreshInterval = params.get('autoRefreshInterval');
    const timeRangeMode = params.get('timeRangeMode');
    const timeRangeQuickMode = params.get('timeRangeQuickMode');
    const timeRange = params.get('timeRange');
    const startDate = params.get('startDate');
    const endDate = params.get('endDate');

    this.setState(
      {
        autoRefreshInterval: autoRefreshInterval !== 'null' ? autoRefreshInterval : null,
        timeRangeMode: timeRangeMode || TimeRangeMode.QUICK,
        timeRangeQuickMode: timeRangeQuickMode || TimeRangeQuickMode.TODAY,
        timeRange: timeRange ? [...timeRange.split(',').map(t => moment.utc(+t))] : [],
        startDate: startDate ? moment.utc(+startDate) : null,
        endDate: endDate ? moment.utc(+endDate) : null,
      },
      this.getData
    );
  }

  async getData() {
    try {
      this.setState({ loading: true, error: null });
      const [startAt, endAt] = this.getTimeRange();
      const { timeRangeMode, timeRangeQuickMode } = this.state;

      let registeredUsersInterval;
      let paymentCountsInterval;
      let paymentsInterval;

      if (timeRangeMode === TimeRangeMode.QUICK) {
        registeredUsersInterval = chartTimeInterval.registeredUsers[timeRangeQuickMode];
        paymentCountsInterval = chartTimeInterval.paymentCountByTimes[timeRangeQuickMode];
        paymentsInterval = chartTimeInterval.paymentCountByTimes[timeRangeQuickMode];
      } else {
        const interval = this.calculateIntervalBetween(startAt, endAt);

        registeredUsersInterval = interval;
        paymentCountsInterval = interval;
        paymentsInterval = interval;
      }

      const timeIntervals = {
        registeredUsers: registeredUsersInterval,
        paymentCountByTimes: paymentCountsInterval,
        payments: paymentsInterval,
      };

      this.query = cancelableQuery({
        query: chartDataQuery,
        variables: {
          startAt,
          endAt,
          timeIntervals,
        },
      });

      const result = await this.query;
      const data = result.data.chartData;

      this.setState({
        data,
        loading: false,
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error(`Failed to get Chart Data: ${error.message}`);
      this.setState({ error, loading: false });
      throw error;
    }
  }

  calculateIntervalBetween(startAt, endAt) {
    const seconds = Math.abs(startAt - endAt) / 1000;

    if (seconds >= 60 * 60 * 24 * 30 * 12 * 3) {
      // 3 years
      return '1w';
    } else if (seconds >= 60 * 60 * 24 * 30 * 12 * 2) {
      // 2 years
      return '1w';
    } else if (seconds >= 60 * 60 * 24 * 30 * 12) {
      // 12 months (=1 year)
      return '1d';
    } else if (seconds >= 60 * 60 * 24 * 30 * 6) {
      // 6 months
      return '1d';
    } else if (seconds >= 60 * 60 * 24 * 30 * 3) {
      // 3 momths
      return '12h';
    } else if (seconds >= 60 * 60 * 24 * 30 * 2) {
      // 2 months
      return '12h';
    } else if (seconds >= 60 * 60 * 24 * 30) {
      // 1 momth
      return '3h';
    } else if (seconds >= 60 * 60 * 24 * 7) {
      // 1 week
      return '1h';
    } else if (seconds >= 60 * 60 * 24) {
      // 24 hours (=1 day)
      return '30m';
    } else if (seconds >= 60 * 60 * 12) {
      // 12 hours
      return '30m';
    } else if (seconds >= 60 * 60 * 4) {
      // 4 hours
      return '10m';
    } else if (seconds >= 60 * 60) {
      // 1 hour
      return '5m';
    } else if (seconds >= 60 * 30) {
      // 30 minutes
      return '1m';
    } else if (seconds >= 60 * 15) {
      // 15 minutes
      return '30s';
    }

    return '30s';
  }

  startAutoRefresh() {
    if (this.autoRefreshTimer) {
      clearInterval(this.autoRefreshTimer);
    }

    this.getData();

    const { autoRefreshInterval } = this.state;

    if (!autoRefreshInterval) return;

    this.autoRefreshTimer = setInterval(this.getData, this.getIntervalToTime());
  }

  getIntervalToTime() {
    const { autoRefreshInterval } = this.state;

    if (autoRefreshInterval === AutoRefreshInterval.SECONDS_5) {
      return 1000 * 5;
    } else if (autoRefreshInterval === AutoRefreshInterval.SECONDS_10) {
      return 1000 * 10;
    } else if (autoRefreshInterval === AutoRefreshInterval.SECONDS_30) {
      return 1000 * 30;
    } else if (autoRefreshInterval === AutoRefreshInterval.SECONDS_45) {
      return 1000 * 45;
    } else if (autoRefreshInterval === AutoRefreshInterval.MINUTE_1) {
      return 1000 * 60 * 1;
    } else if (autoRefreshInterval === AutoRefreshInterval.MINUTES_5) {
      return 1000 * 60 * 5;
    } else if (autoRefreshInterval === AutoRefreshInterval.MINUTES_15) {
      return 1000 * 60 * 15;
    } else if (autoRefreshInterval === AutoRefreshInterval.MINUTES_30) {
      return 1000 * 60 * 30;
    } else if (autoRefreshInterval === AutoRefreshInterval.HOUR_1) {
      return 1000 * 60 * 60 * 1;
    } else if (autoRefreshInterval === AutoRefreshInterval.HOURS_2) {
      return 1000 * 60 * 60 * 2;
    } else if (autoRefreshInterval === AutoRefreshInterval.HOURS_12) {
      return 1000 * 60 * 60 * 12;
    } else if (autoRefreshInterval === AutoRefreshInterval.DAY1) {
      return 1000 * 60 * 60 * 24;
    }

    throw new Error(`Invalid Auto Refresh Interval: ${autoRefreshInterval}`);
  }

  getTimeRange() {
    const { timeRangeMode } = this.state;
    let startDate;
    let endDate;

    if (timeRangeMode === TimeRangeMode.QUICK) {
      const { timeRangeQuickMode } = this.state;

      if (timeRangeQuickMode === TimeRangeQuickMode.TODAY) {
        startDate = moment().startOf('day');
        endDate = moment().endOf('day');
      } else if (timeRangeQuickMode === TimeRangeQuickMode.THIS_WEEK) {
        startDate = moment().startOf('week');
        endDate = moment().endOf('week');
      } else if (timeRangeQuickMode === TimeRangeQuickMode.THIS_MONTH) {
        startDate = moment().startOf('month');
        endDate = moment().endOf('month');
      } else if (timeRangeQuickMode === TimeRangeQuickMode.THIS_YEAR) {
        startDate = moment().startOf('year');
        endDate = moment().endOf('year');
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_15_MINUTES) {
        startDate = moment().subtract(15, 'minutes');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_30_MINUTES) {
        startDate = moment().subtract(30, 'minutes');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_1_HOUR) {
        startDate = moment().subtract(1, 'hour');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_4_HOURS) {
        startDate = moment().subtract(4, 'hours');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_12_HOURS) {
        startDate = moment().subtract(12, 'hours');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_24_HOURS) {
        startDate = moment().subtract(24, 'hours');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_7_DAYS) {
        startDate = moment().subtract(7, 'days');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_30_DAYS) {
        startDate = moment().subtract(30, 'days');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_60_DAYS) {
        startDate = moment().subtract(60, 'days');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_90_DAYS) {
        startDate = moment().subtract(90, 'days');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_6_MONTHS) {
        startDate = moment().subtract(6, 'months');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_1_YEAR) {
        startDate = moment().subtract(1, 'year');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_2_YEARS) {
        startDate = moment().subtract(2, 'years');
        endDate = moment();
      } else if (timeRangeQuickMode === TimeRangeQuickMode.LAST_3_YEARS) {
        startDate = moment().subtract(3, 'years');
        endDate = moment();
      } else {
        throw new Error(`Invalid Time Range Quick Mode: ${timeRangeQuickMode}`);
      }
    } else {
      const [start, end] = this.state.timeRange;
      startDate = start;
      endDate = end;
    }

    // Convert to UTC timestamp
    return [startDate, endDate].map(m => m.toDate().getTime());
  }

  toggleTimeRangeControl() {
    const { showTimeRangeControl } = this.state;
    this.setState({
      showAutoRefreshControl: false,
      showTimeRangeControl: !showTimeRangeControl,
    });
  }

  toggleAutoRefreshControl() {
    const { showAutoRefreshControl } = this.state;
    this.setState({
      showAutoRefreshControl: !showAutoRefreshControl,
      showTimeRangeControl: false,
    });
  }

  updateUrl() {
    const { autoRefreshInterval, timeRangeMode, timeRangeQuickMode, timeRange, startDate, endDate } = this.state;
    const qs = {
      autoRefreshInterval,
      timeRangeMode,
      timeRangeQuickMode,
    };

    if (timeRange.length > 0) {
      qs.timeRange = timeRange.map(t => t.toDate().getTime());
    }

    if (startDate && endDate) {
      qs.startDate = startDate.toDate().getTime();
      qs.endDate = endDate && endDate.toDate().getTime();
    }

    this.props.push(
      `/dashboard?${Object.keys(qs)
        .map(key => `${key}=${qs[key]}`)
        .join('&')}`
    );
  }

  setAutoRefreshInterval(interval) {
    this.setState(
      {
        autoRefreshInterval: interval,
        showAutoRefreshControl: false,
      },
      () => {
        this.startAutoRefresh();
        this.updateUrl();
      }
    );
  }

  setTimeRangeQuickMode(mode) {
    this.setState(
      {
        timeRangeMode: TimeRangeMode.QUICK,
        timeRangeQuickMode: mode,
        showTimeRangeControl: false,
        timeRange: [],
        startDate: null,
        endDate: null,
      },
      () => {
        this.getData();
        this.updateUrl();
      }
    );
  }

  toggleStartDatePicker(status) {
    this.setState({ openStartDatePicker: status });
  }

  toggleEndDatePicker(status) {
    this.setState({ openEndDatePicker: status });
  }

  setStartDate(date) {
    this.setState({ startDate: date, openStartDatePicker: false, openEndDatePicker: true });
  }

  setEndDate(date) {
    this.setState({ endDate: date, openEndDatePicker: false });
  }

  setTimeRange() {
    const { startDate, endDate } = this.state;

    if (!startDate || !endDate) {
      message.warn('Both date must be specified');
      return;
    }

    if (startDate.isSameOrAfter(endDate, 'seconds')) {
      message.warn('Start date must be before then end date.');
      return;
    }

    this.setState(
      {
        timeRange: [startDate, endDate],
        timeRangeMode: TimeRangeMode.ABSOLUTE,
        showTimeRangeControl: false,
      },
      () => {
        this.getData();
        this.updateUrl();
      }
    );
  }

  getTimeRangeText() {
    const { startDate, endDate } = this.state;

    if (startDate && endDate) {
      return `${startDate.local().format('YYYY-MM-DD HH:mm:ss')} ~ ${endDate.local().format('YYYY-MM-DD HH:mm:ss')}`;
    }

    return '';
  }

  renderError() {
    return (
      <div>
        <Alert message="Oops!" description="There is problem with load data." type="warning" showIcon />

        <Divider />

        <Button icon="redo" onClick={this.getData}>
          Try Again
        </Button>
      </div>
    );
  }

  render() {
    const { error, loading } = this.state;
    const data = get(this.state, 'data', {}) || {};
    const { showAutoRefreshControl, showTimeRangeControl, openStartDatePicker, openEndDatePicker } = this.state;
    const { autoRefreshInterval, timeRangeMode, timeRange, timeRangeQuickMode } = this.state;
    const autoRefreshText = autoRefreshInterval ? startCase(autoRefreshInterval) : 'Auto Refresh';
    const timeRangeText =
      timeRangeMode === TimeRangeMode.QUICK ? startCase(timeRangeQuickMode) : this.getTimeRangeText();
    let registeredUsersTimeFormat;
    let paymentsTimeFormat;
    let paymentCountsTimeFormat;

    if (timeRangeMode === TimeRangeMode.QUICK) {
      registeredUsersTimeFormat = TimeFormat[chartTimeInterval.registeredUsers[timeRangeQuickMode]];
      paymentsTimeFormat = TimeFormat[chartTimeInterval.payments[timeRangeQuickMode]];
      paymentCountsTimeFormat = TimeFormat[chartTimeInterval.paymentCountByTimes[timeRangeQuickMode]];
    } else {
      const interval = this.calculateIntervalBetween(timeRange[0], timeRange[1]);
      registeredUsersTimeFormat = TimeFormat[interval];
      paymentsTimeFormat = TimeFormat[interval];
      paymentCountsTimeFormat = TimeFormat[interval];
    }

    if (!loading && error) {
      return this.renderError();
    }

    return (
      <div className={styles.dashboardContainer}>
        <div className={styles.dashboardTopControl}>
          <div className={styles.refreshButton}>
            <Button
              type="primary"
              onClick={this.getData}
              ghost
              disabled={loading}
              className={styles.refreshButton}
              icon={loading ? 'loading' : 'reload'}
            >
              Refresh
            </Button>
          </div>

          <div className={styles.dashboardControls}>
            <div
              className={cx([styles.control, showAutoRefreshControl && styles.active])}
              onClick={this.toggleAutoRefreshControl}
            >
              <Icon type="reload" style={{ marginRight: 10 }} />
              <span>{autoRefreshText}</span>
            </div>

            <div
              className={cx([styles.control, showTimeRangeControl && styles.active])}
              onClick={this.toggleTimeRangeControl}
            >
              <Icon type="clock-circle" style={{ marginRight: 10 }} />
              <span>{timeRangeText}</span>
            </div>
          </div>
        </div>

        {showAutoRefreshControl && (
          <div className={styles.controlContainer}>
            <div className={styles.controlAligner}>
              <AutoRefreshControl onSetRefreshInterval={this.setAutoRefreshInterval} />
            </div>
          </div>
        )}

        {showTimeRangeControl && (
          <div className={styles.controlContainer}>
            <div className={styles.controlAligner}>
              <TimeRangeControl
                onSetTimeRangeQuickMode={this.setTimeRangeQuickMode}
                onSetTimeRange={this.setTimeRange}
                onSetStartDate={this.setStartDate}
                onSetEndDate={this.setEndDate}
                toggleStartDatePicker={this.toggleStartDatePicker}
                toggleEndDatePicker={this.toggleEndDatePicker}
                openStartDatePicker={openStartDatePicker}
                openEndDatePicker={openEndDatePicker}
                timeRangeMode={timeRangeMode}
                timeRange={timeRange}
              />
            </div>
          </div>
        )}

        <div className={styles.dashboardContent}>
          <Row>
            <Col span={24}>
              <div className={styles.chartContainer}>
                <TextChart label="Transaction Volume" value={commify(data.transactionVolume || 0)} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12} sm={12} xs={24}>
              <div className={styles.chartContainer}>
                <RegisteredUsersChart
                  title="Registered Users"
                  data={data.registeredUsers || []}
                  timeFormat={registeredUsersTimeFormat}
                  domain={this.getTimeRange()}
                />
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={24}>
              <div className={styles.chartContainer}>
                <PaymentsChart title="Payments" data={data.payments || []} timeFormat={paymentsTimeFormat} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={10} lg={10} sm={10} xs={24}>
              <div className={styles.chartContainer}>
                <PaymentCountsChart
                  title="Payment Counts"
                  data={data.paymentCountByTimes || []}
                  timeFormat={paymentCountsTimeFormat}
                />
              </div>

              <div className={styles.chartContainer}>
                <Top20ProductsChart
                  title="Top 20 Products"
                  data={data.top20Products || []}
                  transactionVolume={data.transactionVolume || 0}
                />
              </div>
            </Col>
            <Col xl={14} lg={14} sm={14} xs={24}>
              <Row>
                <Col span={24}>
                  <div className={styles.chartContainer}>
                    <TextChart label="Purchased Amount" value={commify(data.purchasedAmount || 0)} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} sm={24} xs={24}>
                  <div className={styles.chartContainer}>
                    <TextChart label="Discount Amount" value={commify(data.discountAmount || 0)} />
                  </div>
                </Col>
                <Col xl={12} lg={12} sm={24} xs={24}>
                  <div className={styles.chartContainer}>
                    <TextChart
                      label="Merchant Coupon Discount Amount"
                      value={commify(data.merchantCouponDiscountAmount || 0)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} sm={24} xs={24}>
                  <div className={styles.chartContainer}>
                    <TextChart
                      label="Unique Count for Purchased User"
                      value={commify(data.uniqueCountOfPurchasedUser || 0)}
                    />
                  </div>
                </Col>
                <Col xl={12} lg={12} sm={24} xs={24}>
                  <div className={styles.chartContainer}>
                    <TextChart label="Canceled Amount" value={commify(data.canceledAmount || 0)} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DashboardContainer;
