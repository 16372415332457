import apolloClient from 'helpers/apolloClient';
import { settlementUpdateMutation, merchantBalanceTransferMutation } from './mutations';
import { settlementPreviewQuery, settlementTransferQuery } from './queries';

export const FETCH_SETTLEMENT_PREVIEW = 'settlementPreview/FETCH';
export const FETCH_SETTLEMENT_PREVIEW_SUCCESS = 'settlementPreview/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_PREVIEW_FAILURE = 'settlementPreview/FETCH_FAILURE';

export const FETCH_SETTLEMENT_TRANSFER = 'settlementTransfer/FETCH';
export const FETCH_SETTLEMENT_TRANSFER_SUCCESS = 'settlementTransfer/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_TRANSFER_FAILURE = 'settlementTransfer/FETCH_FAILURE';
export const SETTLEMENT_TRANSFER_SET_FILTER = 'settlementTransfer/SET_FILTER';
export const SETTLEMENT_TRANSFER_RESET_FILTER = 'settlementTransfer/RESET_FILTER';

export const FETCH_SETTLEMENT_UPDATE = 'settlementUpdate/FETCH';
export const FETCH_SETTLEMENT_UPDATE_SUCCESS = 'settlementUpdate/FETCH_SUCCESS';
export const FETCH_SETTLEMENT_UPDATE_FAILURE = 'settlementUpdate/FETCH_FAILURE';

export function getSettlementPreview() {
  return {
    types: [FETCH_SETTLEMENT_PREVIEW, FETCH_SETTLEMENT_PREVIEW_SUCCESS, FETCH_SETTLEMENT_PREVIEW_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: settlementPreviewQuery,
        variables: {},
      });

      return result.data.settlementPreview;
    },
  };
}

export function getSettlementTransfer(filter) {
  return {
    types: [FETCH_SETTLEMENT_TRANSFER, FETCH_SETTLEMENT_TRANSFER_SUCCESS, FETCH_SETTLEMENT_TRANSFER_FAILURE],
    promise: async () => {
      const result = await apolloClient.query({
        query: settlementTransferQuery,
        variables: {
          filter: JSON.stringify(filter || {}),
        },
      });

      return result.data.settlementTransfer;
    },
  };
}

export function settlementUpdate(targetMerchants, adjustments, transferException) {
  return {
    types: [FETCH_SETTLEMENT_UPDATE, FETCH_SETTLEMENT_UPDATE_SUCCESS, FETCH_SETTLEMENT_UPDATE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: settlementUpdateMutation,
        variables: { merchantIds: targetMerchants, adjustmentList: adjustments, transferException },
      });

      return result.data;
    },
  };
}

export function merchantBalanceTransfer(fromMerchantId, toMerchantId, amount) {
  return {
    types: [FETCH_SETTLEMENT_UPDATE, FETCH_SETTLEMENT_UPDATE_SUCCESS, FETCH_SETTLEMENT_UPDATE_FAILURE],
    promise: async () => {
      const result = await apolloClient.mutate({
        mutation: merchantBalanceTransferMutation,
        variables: { fromMerchantId, toMerchantId, amount },
      });

      return result.data;
    },
  };
}

/*
 * Set filter for getting Transaction
 * @param {Object} filter
 */
export function setFilter(filter) {
  return {
    type: SETTLEMENT_TRANSFER_SET_FILTER,
    payload: filter,
  };
}

export function resetFilter() {
  return {
    type: SETTLEMENT_TRANSFER_RESET_FILTER,
  };
}
