import React from 'react';
import { Checkbox, InputNumber, Popover, Badge } from 'antd';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';
import '@lottiefiles/lottie-player';

/**
 * Get columns with custom options
 * @param {Object} options
 * @param {Function} onTitleClick Callback function that invokes when clicking title
 * @param {Function} onCheckboxChange Callback function that invokes when clicking checkbox
 * @param {Function} onSortedIndexChange Callback function that invokes when sorted index changed
 * @param {number[]} selectedBannerIdList List of Banner ID that selected
 */

export function getColumns(options) {
  const { onTitleClick, onCheckboxChange, onSortedIndexChange, selectedBannerIdList } = options;

  const previewImage = url => {
    const isJson = url.includes('.json');

    if (isJson) {
      return (
        <Popover
          content={
            <lottie-player
              autoplay
              loop
              mode="normal"
              src={`https://static.chai.finance/${url}?t=${Date.now()}`}
              style={{ maxWidth: '200px' }}
            />
          }
        >
          <lottie-player
            loop
            mode="normal"
            src={`https://static.chai.finance/${url}?t=${Date.now()}`}
            style={{ maxHeight: 100, maxWidth: 100, display: 'block', margin: '0 auto' }}
          />
        </Popover>
      );
    }

    return (
      <Popover
        content={
          <img
            src={`https://static.chai.finance/${url}?t=${Date.now()}`}
            style={{ maxHeight: 400, maxWidth: 400 }}
            alt={`logo_${url}`}
          />
        }
      >
        <img
          alt={`logo_${url}`}
          src={`https://static.chai.finance/${url}?t=${Date.now()}`}
          style={{ maxHeight: 100, maxWidth: 150, display: 'block', margin: '0 auto' }}
        />
      </Popover>
    );
  };

  const columns = [
    {
      title: '',
      width: 45,
      dataIndex: 'id',
      render(id) {
        const isInList = selectedBannerIdList.indexOf(id) >= 0;
        return <Checkbox onChange={onCheckboxChange.bind(null, id)} checked={isInList} />;
      },
    },
    {
      title: 'Image',
      dataIndex: 'androidImageUrl',
      width: 200,
      render(val) {
        return previewImage(val);
      },
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: 200,
      render(val, others) {
        return <LinkText onClick={onTitleClick.bind(null, others)}>{val}</LinkText>;
      },
    },
    {
      title: 'Sub Title',
      dataIndex: 'subTitle',
      width: 200,
      render(val, others) {
        return <LinkText onClick={onTitleClick.bind(null, others)}>{val}</LinkText>;
      },
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      width: 100,
      render(val) {
        return <Badge status={val ? 'success' : 'error'} text={val ? 'Active' : 'Inactive'} />;
      },
    },
    {
      title: 'Date',
      dataIndex: '',
      width: 200,
      render(banner) {
        const { startAt, endAt } = banner;
        const format = 'YYYY-MM-DD';
        return <span>{startAt ? `${formatDate(startAt, format)} ~ ${formatDate(endAt, format)}` : '-'}</span>;
      },
    },
    {
      title: 'Priority',
      dataIndex: 'sortedIndex',
      width: 75,
      render(val, banner) {
        return (
          <InputNumber
            min={1}
            value={val}
            onChange={onSortedIndexChange.bind(null, banner.id)}
            style={{ width: '100%' }}
          />
        );
      },
    },
  ];

  return columns;
}

export default {
  getColumns,
};
