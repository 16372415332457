import gql from 'graphql-tag';

export const getTagListQuery = gql`
  query getTagList($skip: Int, $pageSize: Int, $filter: String) {
    getTagList(skip: $skip, pageSize: $pageSize, filter: $filter) {
      list {
        id
        name
        status
        dateHistory {
          createdAt
          updatedAt
        }
      }
      total
    }
  }
`;

export const getTagQuery = gql`
  query getTag($tagName: String!) {
    getTag(tagName: $tagName) {
      id
      name
      status
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const getTagListByPolicyMappingQuery = gql`
  query getTagListByPolicyMapping($mappingId: String!, $mappingType: String!) {
    getTagListByPolicyMapping(mappingId: $mappingId, mappingType: $mappingType) {
      id
      name
      status
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const getTagListByTargetMappingQuery = gql`
  query getTagListByTargetMapping($mappingId: String!, $mappingType: String!) {
    getTagListByTargetMapping(mappingId: $mappingId, mappingType: $mappingType) {
      id
      name
      status
      dateHistory {
        createdAt
        updatedAt
      }
    }
  }
`;

export const getTagPolicyListQuery = gql`
  query getTagPolicyList($tagName: String!, $skip: Int, $pageSize: Int) {
    getTagPolicyList(tagName: $tagName, skip: $skip, pageSize: $pageSize) {
      list {
        id
        policyId
        type
        dateHistory {
          createdAt
          updatedAt
        }
        tag {
          name
        }
      }
      total
    }
  }
`;

export const getTagTargetListQuery = gql`
  query getTagTargetList($tagName: String!, $skip: Int, $pageSize: Int) {
    getTagTargetList(tagName: $tagName, skip: $skip, pageSize: $pageSize) {
      list {
        id
        targetId
        type
        dateHistory {
          createdAt
          updatedAt
        }
        tag {
          name
        }
      }
      total
    }
  }
`;

export default {
  getTagListQuery,
  getTagListByPolicyMappingQuery,
  getTagListByTargetMappingQuery,
  getTagPolicyListQuery,
  getTagTargetListQuery,
};
