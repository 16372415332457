import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

export const locationPropType = PropTypes.shape({
  pathname: PropTypes.string.isRequired,
  action: PropTypes.string,
});

export const historyPropType = PropTypes.objectOf(PropTypes.any);

export default withRouter;
