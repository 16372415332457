export default {
  menu: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Dashboard...',
    }, // Component property config
    'ui:title': 'Menu', // Override the title defined in the schema
    'ui:description': '',
  },
  name: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Action Name...',
    },
    'ui:title': 'Action Name',
    'ui:description': '',
  },
  code: {
    'ui:widget': 'input',
    'ui:options': {
      type: 'text',
      placeholder: 'Action Code...',
    },
    'ui:title': 'Action Code',
    'ui:description': '',
  },
};
