import React from 'react';
import { Badge } from 'antd';
import startCase from 'lodash/startCase';
import LinkText from 'components/LinkText';
import { formatDate } from 'utils/format';

const getStatus = status =>
  ({
    active: 'success',
    inactive: 'default',
    suspend: 'default',
    identified: 'processing',
    unidentified: 'warning',
    phone_changed: 'default',
    deleted: 'error',
  }[status]);

export const columns = [
  {
    title: 'User ID',
    dataIndex: 'id',
    render: function renderID(val) {
      return <LinkText>{val.substr(0, 20)}...</LinkText>;
    },
  },
  {
    title: 'Name',
    dataIndex: 'fullname',
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
  },
  {
    title: 'DOB',
    dataIndex: 'birthday',
  },
  {
    title: 'Register Date',
    dataIndex: 'createdAt',
    render: function renderRegisterDate(val) {
      return <span>{formatDate(val)}</span>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: function renderStatus(val) {
      return (
        <span>
          <Badge status={getStatus(val)} text={startCase(val)} />
        </span>
      );
    },
  },
];

export default {
  columns,
};
