import { SHOW_I18N_MODAL, HIDE_I18N_MODAL } from './actions';

const initialState = {
  visibleModal: false,
  type: null,
  title: null,
  tableName: null,
  targetId: null,
  columnName: null,
  currentValue: null,
  editorConfig: null,
};

export default function I18nReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_I18N_MODAL:
      return {
        ...state,
        visibleModal: true,
        type: action.payload.type,
        title: action.payload.title,
        tableName: action.payload.tableName,
        targetId: action.payload.targetId,
        columnName: action.payload.columnName,
        currentValue: action.payload.currentValue,
        editorConfig: action.payload.editorConfig,
      };
    case HIDE_I18N_MODAL:
      return {
        ...state,
        visibleModal: false,
        type: null,
        title: null,
        tableName: null,
        targetId: null,
        columnName: null,
        currentValue: null,
        editorConfig: null,
      };
    default:
      return state;
  }
}
