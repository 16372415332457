import moment from 'moment';
import React from 'react';
import { Badge, Select } from 'antd';
import startCase from 'lodash/startCase';
import { formatDate } from 'utils/format';
import { commify } from 'utils/stringUtil';

const { Option } = Select;

/**
 * Get Status text for Badge Component
 * @param {string} status - Coupon Status
 * @return string
 */
function getStatus(status) {
  return {
    enabled: 'success',
    used: 'success',
    disabled: 'error',
  }[status];
}

/**
 * Get columns with custom options
 * @param {Object} options
 * @param {Function} onStatusChanged - Callback function that invokes when status changed
 */

export function getColumns(options = {}) {
  const { onStatusChanged } = options;
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '10%',
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      align: 'right',
      render(val) {
        return <span>{val.match(/%/) ? val : `${commify(+val || 0)} KRW`}</span>;
      },
      width: '20%',
    },
    {
      title: 'Benefit Type',
      dataIndex: 'benefitType',
      render(val) {
        return <span>{startCase(val)}</span>;
      },
      width: '10%',
    },
    {
      title: 'Issue Type',
      dataIndex: 'issueType',
      render(val) {
        return <span>{startCase(val)}</span>;
      },
      width: '10%',
    },
    {
      title: 'Date',
      dataIndex: '',
      render: function renderDate(coupon) {
        return (
          <span>
            {formatDate(coupon.startAt, 'YYYY-MM-DD')} ~ {formatDate(coupon.endAt, 'YYYY-MM-DD')}
          </span>
        );
      },
      width: '25%',
    },
    {
      title: 'Expired',
      render(others) {
        const { endAt } = others;
        const today = moment();

        return <span>{today.isSameOrBefore(endAt, 'days') ? '-' : 'Expired'}</span>;
      },
      width: '10%',
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: function renderStatus(val, others) {
        if (val === 'used') {
          return <Badge status={getStatus(val)} text={startCase(val)} />;
        }

        return (
          <Select value={val} onChange={onStatusChanged.bind(null, others)}>
            <Option value="enabled">Enabled</Option>
            <Option value="disabled">Disabled</Option>
          </Select>
        );
      },
      width: '15%',
    },
  ];

  return columns;
}

export default {
  getColumns,
};
