import React from 'react';
import { formatDate } from 'utils/format';
import LinkText from 'components/LinkText';
import { commify } from 'utils/stringUtil';

function convertType(type) {
  return (
    {
      payment_confirm: '결제',
      payment_cancel: '취소',
      payment_cancel_after_cashback: '취소',
      carry_over: '이월',
    }[type] || type
  );
}

function convertStatus(status) {
  return (
    {
      confirmed: '완료',
      prepared: '예정',
    }[status] || status
  );
}

export const columns = [
  {
    title: 'Payment ID',
    dataIndex: 'paymentId',
    render: function renderId(val) {
      return val ? <LinkText>{val.substr(0, 10)}...</LinkText> : <span>-</span>;
    },
    width: '15%',
  },
  {
    title: 'Payment Description',
    dataIndex: 'payment.description',
    render: function renderDescription(val) {
      return <span>{val ?? '-'}</span>;
    },
    width: '15%',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '20%',
  },
  {
    title: 'Type',
    dataIndex: 'actionType',
    align: 'center',
    render: function renderType(val) {
      return <span>{convertType(val)}</span>;
    },
    width: '10%',
  },
  {
    title: '캐시백 금액',
    dataIndex: 'cashbackDelta',
    align: 'center',
    render: function renderAmount(val) {
      const symbol = val >= 0 ? '+' : '-';
      const color = symbol === '+' ? '#0eb53d' : '#e02323';
      return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
    },
    width: '10%',
  },
  {
    title: '결제 금액',
    dataIndex: 'billingDelta',
    align: 'center',
    render: function renderAmount(val) {
      const symbol = val >= 0 ? '+' : '-';
      const color = symbol === '+' ? '#0eb53d' : '#e02323';
      return <span style={{ color }}>{symbol + commify(Math.abs(val))}</span>;
    },
    width: '10%',
  },
  {
    title: '지급',
    dataIndex: 'status',
    align: 'center',
    render: function renderStatus(val) {
      return <span>{convertStatus(val)}</span>;
    },
    width: '10%',
  },
  {
    title: '지급일',
    dataIndex: 'targetDate',
    render: function renderDate(val) {
      return <span>{formatDate(val)}</span>;
    },
    width: '20%',
  },
];

export default {
  columns,
};
