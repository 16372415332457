import React, { PureComponent } from 'react';
import { func, string } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { message, Alert } from 'antd';
import autobind from 'autobind-decorator';
import cancelableQuery from 'helpers/apolloClient/cancelableQuery';
import { addTab, focusTab } from 'redux/modules/tabs';
import { TabData } from 'containers/Layout';
import InfiniteScrollableTable from 'components/InfiniteScrollableTable';

import { getTagListByPolicyMappingQuery, getTagListByTargetMappingQuery } from 'redux/modules/tag/queries';
import { columns } from './TagListMetadata';
import { TagPromotionType } from '../';

const mapDispatchToProps = {
  addTab,
  focusTab,
  push,
};

@connect(null, mapDispatchToProps)
@withRouter
@autobind
class TagList extends PureComponent {
  static propTypes = {
    id: string.isRequired,
    type: string.isRequired,
    addTab: func.isRequired,
    focusTab: func.isRequired,
    push: func.isRequired,
  };

  state = {
    currentPage: 0,
    pageSize: 10,
    data: [],
    error: null,
    loading: false,
  };

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
    if (this.query) {
      this.query.cancel();
    }
  }

  query = null;

  /**
   * Get Data and append to current data list
   */
  async getData(currentPage) {
    try {
      this.setState({ loading: true, error: null });
      const { id, type } = this.props;

      const filter = { mappingId: id, mappingType: type };

      const isPolicyType = this.isPolicyType(type);

      const queryOptions = {
        query: isPolicyType ? getTagListByPolicyMappingQuery : getTagListByTargetMappingQuery,
        variables: {
          ...filter,
        },
      };

      this.query = cancelableQuery(queryOptions);

      const result = await this.query;
      const data = isPolicyType ? result.data.getTagListByPolicyMapping : result.data.getTagListByTargetMapping;

      this.setState({
        loading: false,
        currentPage,

        data: [...this.state.data, ...data],
      });
    } catch (error) {
      if (error.isCanceled) return;

      message.error('Failed to get Tag List');
      this.setState({ error, loading: false });

      throw error;
    }
  }

  isPolicyType(type) {
    const promotionTypes = Object.values(TagPromotionType);
    return promotionTypes.includes(type);
  }

  /**
   * Inject Cell related callback props into columns
   * This makes your code can seperate handling cell related jobs from own class, not column definition code.
   * @return {Object} connectedColumns
   */
  getConnectedColumns() {
    const connectedColumns = [...columns];

    for (let i = 0; i < connectedColumns.length; i += 1) {
      const column = connectedColumns[i];
      column.onCell = record => ({
        onClick: () => {
          this.handleCellClick(i, record);
        },
      });
    }

    return connectedColumns;
  }

  /**
   * Handle cell click to open tab only in first cell.
   * @param {number} index
   * @param {Object} record
   */
  handleCellClick(index, record) {
    if (index === 1) {
      this.openTagDataTab(record);
    }
  }

  openTagDataTab(record) {
    const tabData = new TabData({
      key: record.name,
      title: record.name,
      closable: true,
      data: {
        tagName: record.name,
        isNew: false,
      },
      componentType: 'TagForm',
    });

    this.props.addTab('tag', tabData);
    this.props.focusTab('tag', record.name);
    this.props.push(`/tag/${record.name}`);
  }

  getNextPage() {
    const { currentPage, pageSize } = this.state;
    this.getData(currentPage + 1, pageSize);
  }

  render() {
    const connectedColumns = this.getConnectedColumns();
    const { data, error, loading } = this.state;

    return (
      <div>
        {error && (
          <Alert
            description="Failed to load tag Data."
            type="warning"
            showIcon
            closable
            style={{
              marginBottom: 10,
            }}
          />
        )}

        <InfiniteScrollableTable
          id="user-tag-list-table"
          columns={connectedColumns}
          dataSource={data}
          loading={loading}
          onFetch={this.getNextPage}
        />
      </div>
    );
  }
}

export default TagList;
